<template>
	<div class="container mb-10vh">
		<div v-if="yhfApplyUserList.length > 0">
			<table>
				<tr>
					<td>지원날짜</td>
					<td>지원기업</td>
					<td>채용직무</td>
					<td>지원자명</td>
					<td>구분</td>
					<td>이메일</td>
					<td>전화번호</td>
					<td>전형상태</td>
				</tr>
				<tr v-for="item in applyUserList">
					<td>{{ item.date | dateFormat }}</td>
					<td>{{ item.company_name }}</td>
					<td>{{ item.company_job }}</td>
					<td>{{ item.name }}</td>
					<td>{{ item.user_type == 1 ? '일반지원' : '자동지원' }}</td>
					<td>{{ item.email }}</td>
					<td>{{ changeMobile(item.mobile) }}</td>
					<td>{{ changeProgress(item.state, item.interview_count) }}</td>
				</tr>
			</table>
			<div class="paging center">
				<div class="box-page deselected prev pointer" @click="goPrev()">
					<img src="/images/paging_left_1.svg" />
				</div>
				<div
					v-for="num in allNo"
					class="box-page pointer"
					:class="selectPage === num ? 'point' : ''"
					@click="go(num)"
				>
					{{ num }}
				</div>
				<div class="box-page deselected next pointer" @click="goNext()">
					<img src="/images/paging_right_1.svg" />
				</div>
			</div>
		</div>

		<div v-else>
			<div class="border text-center pd-50">
				<img class="sorry-logo" src="/images/co_sorry.svg" />
				<div class="sorry-font">지원자 성과 정보가 없습니다.</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['yhfApplyUserList'],
	data() {
		return {
			pages: Math.ceil(this.yhfApplyUserList.length / 10), //전체 페이지
			nowPages: Math.ceil(Math.ceil(this.yhfApplyUserList.length / 10) / 5), //현재 페이지 전체 쪽
			nowPage: 1, //현재 페이지 쪽
			allNo: {}, //현재 페이지 쪽 숫자들
			selectPage: 1, //선택한 페이지 숫자
			applyUserList: {},
		};
	},
	mounted() {
		this.showPage();
		this.showTable();
	},
	methods: {
		showPage() {
			this.allNo = {};
			let cnt = 0;
			for (let i = (this.nowPage - 1) * 5; i < (this.nowPage - 1) * 5 + 5; i++) {
				if (i < this.pages) {
					this.allNo[cnt] = i + 1;
					cnt++;
				}
			}
		},
		showTable() {
			this.applyUserList = {};
			for (let i = (this.selectPage - 1) * 10; i <= (this.selectPage - 1) * 10 + 9; i++) {
				if (i < this.yhfApplyUserList.length) {
					this.applyUserList[i] = this.yhfApplyUserList[i];
				}
			}
		},
		goPrev() {
			if (this.nowPage > 1) {
				this.nowPage -= 1;
				this.selectPage = (this.nowPage - 1) * 5 + 1;
				this.showPage();
				this.showTable();
			}
		},
		goNext() {
			if (this.nowPage < this.nowPages) {
				this.nowPage += 1;
				this.selectPage = (this.nowPage - 1) * 5 + 1;
				this.showPage();
				this.showTable();
			} else {
				this.nowPage = this.nowPages;
				this.selectPage = (this.nowPage - 1) * 5 + 1;
				this.showPage();
				this.showTable();
			}
		},
		go(page) {
			this.selectPage = page;
			this.showTable();
		},
		changeMobile(mobile) {
			mobile = mobile.replace('연락처', '');
			mobile = mobile.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
			mobile = mobile.trim();

			return mobile;
		},
		changeProgress(state, interview_count) {
			if (state === 8) return '채용종료';
			else if (state === 7) return '최종합격';
			else if (state === 6) return '면접불합격';
			else if (state === 5 || state === 12) {
				if (interview_count > 0) return '면접예정';
				return '서류합격';
			} else if (state === 4) return '서류불합격';
			else return '서류평가';
		},
	},
};
</script>

<style lang="less" scoped>
@import '../../../../less/base/variables';

.mb-10vh {
	margin-bottom: 10vh;
}

table {
	width: 100%;
	border-collapse: collapse;
	text-align: center;
}

table tr {
	height: 48px;
}

table tr:nth-child(1) {
	background-color: #f1f1f1;
	font-weight: @semi-bold;
}

table tr td {
	border: 1px solid #eaeaea;
}

.point {
	background-color: #2a7de1;
	color: white;
}
.sorry {
	&-logo {
		width: 120px;
		@media (max-width: 480px) {
			width: 100px;
		}
	}

	&-font {
		font-size: 20px;
		color: #949494;
		@media (max-width: 480px) {
			font-size: 16px;
		}
	}
}
</style>
