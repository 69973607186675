<template>
	<!--contents-body-->
	<dl class="contents_body_component" :class="[computedAlign]">
		<!--dt 내용은 안보입니다.-->
		<dt>{{ bodyDefinition }}</dt>
		<dd v-linkified:options="{ className: 'text-link' }" v-html="$options.filters.nl2br(contentsBody)"></dd>
	</dl>
</template>

<script>
export default {
	name: 'ContentsBody',
	props: {
		bodyDefinition: {
			type: String,
			default: 'definition(설명,내용)',
		},
		//contents body 내용
		contentsBody: {
			type: String,
			default: null,
		},
		//좌, 우 방향 설정
		bodyAlign: {
			type: String,
			default: 'none',
		},
	},
	computed: {
		computedAlign() {
			return `contents_body_align_${this.bodyAlign}`;
		},
	},
};
</script>
<style scoped lang="less">
@import '../../../../../less/base/index';
.contents_body_component {
	position: relative;
	margin-bottom: 16px;
	&.contents_body_align_left {
		float: left;
	}
	&.contents_body_align_right {
		float: right;
	}
	&.contents_body_align_none {
		float: none;
	}
	dt {
		display: none;
	}
}
</style>
