<template>
	<div class="couponTicket" :class="computedCursor" @click="downloadCoupon(coupon.id, coupon.coupon_apply)">
		<dl class="couponTicket_top">
			<dt>쿠폰타입</dt>
			<c-typography type="caption1" color="gray500" element="dd" class="f-semi-bold">
				{{ couponApply }}
			</c-typography>

			<dt>쿠폰할인</dt>
			<c-typography type="headline4" color="gray900" element="dd" style="margin: 2px 0 4px 0">
				{{ coupon.coupon_type_amount | numberFormat }}{{ coupon.coupon_type === 1 ? '원' : '%' }} 할인
			</c-typography>

			<dt>쿠폰이름</dt>
			<c-typography type="body1" color="gray800" element="dd" class="text-truncate">
				{{ coupon.coupon_name }}
			</c-typography>

			<template v-if="category === 'book'">
				<dt>쿠폰 다운로드</dt>
				<dd class="couponTicket_download">
					<span>
						{{ coupon.user_downloaded === 0 ? '다운로드 가능' : '다운로드 완료' }}
						<c-icon
							:name="coupon.user_downloaded === 0 ? 'IconDownloadLargeLine' : 'IconCheckLargeLine'"
							color="white"
							:class="computedCursor"
						/>
					</span>
				</dd>
			</template>
		</dl>
		<div class="couponTicket_divider"><c-divider type="dash" :vertical="true" /></div>
		<dl class="couponTicket_bottom">
			<dt>쿠폰 사용가능한 기한</dt>
			<c-typography type="body2" color="gray500" element="dd" class="f-light">
				<template v-if="isTicketAlways">상시 사용 가능</template>
				<template v-else-if="timeCouponList.includes(coupon.id) && couponInfo.coupon_table === 'book'">
					{{ couponInfo.coupon_start_at | dateFormatWithTime }} ~
					{{ couponInfo.coupon_end_at | dateFormatWithTime }}
				</template>
				<template v-else>
					{{ coupon.coupon_start_at | dateFormat }} ~
					{{ couponInfo.coupon_end_at | dateFormat }}
				</template>
			</c-typography>

			<dt>쿠폰사용조건</dt>
			<c-typography type="caption1" color="red400" element="dd">
				{{ coupon.coupon_special_condition }}
			</c-typography>
		</dl>

		<c-permission-modal :show.sync="showModal" :successCallback="login">
			<template v-slot:title>
				<strong>{{ modalTitle }}</strong>
			</template>
			<template v-slot:content>
				<c-typography type="body2" color="gray700" element="p" align="center" v-html="modalContent" />
			</template>
		</c-permission-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'CouponTicket',
	props: {
		couponInfo: Object,
		category: String,
	},
	data: () => ({
		showModal: false,
		modalTitle: '',
		modalContent: '',
	}),
	computed: {
		...mapGetters(['sessionInfo']),
		isTicketAlways() {
			if (
				this.coupon.coupon_end_at.indexOf('9999') !== -1 &&
				this.couponInfo.coupon_end_at.indexOf('9999') !== -1
			) {
				return true;
			} else {
				return false;
			}
		},
		couponApply() {
			const couponType = {
				1: '모든상품',
				2: '직무부트캠프',
				3: '열람권',
				4: 'VOD',
				5: '온에어-직무',
				6: '온에어-일반',
				7: '실무PT',
				8: '실무VOD',
			};
			return couponType[this.coupon.coupon_apply];
		},
		computedCursor() {
			const isDownloaded = this.coupon?.user_downloaded === 1;
			return {
				disabled: isDownloaded,
				'c-pointer': this.category === 'mine' || !isDownloaded,
			};
		},
		coupon() {
			if (this.category === 'mine') {
				return this.couponInfo.info;
			} else {
				return this.couponInfo;
			}
		},
	},
	created() {
		this.timeCouponList = [26, 27, 28, 29];
	},
	methods: {
		downloadCoupon(id, apply) {
			if (this.coupon.user_downloaded !== 1 && this.category === 'book') {
				const args = {
					id,
					apply,
					where: 'couponBook',
				};
				this.$emit('download-coupon', args);
			}
			this.clickCouponType();
		},
		login() {
			/*비회원의 경우 로그인 화면으로 */
			if (this.sessionInfo === null) {
				window.location.href = '/logout';
			}
		},
		clickCouponType() {
			const isMine = this.category === 'mine';
			const isContent = this.couponApply === '열람권';
			if (isMine && isContent) {
				window.location.href = '/price/list/contents';
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';
.couponTicket {
	min-height: 182px;
	margin-bottom: 20px;
	position: relative;
	pointer-events: auto !important;
	&:hover {
		.couponTicket_download {
			background: $blue800;
		}
		&.disabled {
			.couponTicket_download {
				background: $blue100;
			}
		}
	}
	&.disabled {
		.couponTicket_download {
			background: $blue100;
		}
	}
	@include clearfix();
	dl {
		position: relative;
		display: block;
		float: left;
		width: 100%;
		dt {
			display: none;
		}
		dd {
			display: block;
		}
	}

	display: block;
	width: 100%;
	padding: 24px 20px;
	@include border-radius(12px);
	@include shadow3();
	border: 1px solid $gray100;

	&_top {
		margin-bottom: 16.5px;
	}
	&_bottom {
		margin-top: 12.5px;
	}
	&_divider {
		position: relative;
		margin: 0 -20px;
		float: left;
		width: calc(100% + 40px);
	}
	&_download {
		width: 46px;
		height: 46px;
		@include border-radius(46px);
		position: absolute;
		top: -4px;
		right: 0;
		background: $blue600;
		span {
			font-size: 0;
			line-height: 0;
			display: inline-block;
			position: relative;
			vertical-align: top;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
			width: 24px;
		}
	}

	@include pc() {
		margin-bottom: 32px;
	}
}
</style>
