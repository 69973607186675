<template>
	<modal-component :show="show" @click="close">
		<div class="modal-body">
			<div class="text-heading mb-20 text-center">
				<div class="title f-semi-bold">{{ title }}</div>
				<div class="text-caption font text-left" v-html="message"></div>
			</div>
			<div class="text-right">
				<button class="btn" @click="close">확인</button>
			</div>
		</div>
	</modal-component>
</template>

<script>
export default {
	props: ['show', 'title', 'message'],
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
.title {
	font-size: 24px;
}
</style>
