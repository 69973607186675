import { ANSWER_PATH, CASE_PATH, CLASS_VOD_LIST_PATH, EDU_ALUMNI_PATH, EXTERNSHIP_PATH } from '../../constants/paths';
import { uniqBy as _uniqBy } from 'lodash';
import { getRoutePath } from '../../utils/util';
import { CASE_CATEGORY_WORKING_LEVEL_SKILL } from '../../constants';
import appCommandMixin from '@/mixins/appCommandMixin';
import { APP_USER_AGENT_REGEX } from '@/constants/app';

// states
export const IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT = 'IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT';

// getters
export const IS_APP = 'IS_APP';
export const SUB_HEADER_INDEX = 'SUB_HEADER_INDEX';
export const IS_LOADED_SUB_HEADER_LIST = 'IS_LOADED_SUB_HEADER_LIST';
export const SUB_HEADER_LIST = 'SUB_HEADER_LIST';
export const IS_TRANSPARENT_TYPE = 'IS_TRANSPARENT_TYPE';
export const IS_SHOW_GLOBAL_NOTICE_ALERT = 'IS_SHOW_GLOBAL_NOTICE_ALERT';
export const IS_SHOW_ALERT_LAYOUT = 'IS_SHOW_ALERT_LAYOUT';
export const GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT = 'GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT';

// mutations
export const SET_IS_LOADED_SUB_HEADER_LIST = 'SET_IS_LOADED_SUB_HEADER_LIST';
export const SET_SUB_HEADER_LIST = 'SET_SUB_HEADER_LIST';
export const SET_SUB_HEADER_INDEX = 'SET_SUB_HEADER_INDEX';
export const SET_IS_TRANSPARENT_TYPE = 'SET_IS_TRANSPARENT_TYPE';
export const SET_IS_SHOW_GLOBAL_NOTICE_ALERT = 'SET_IS_SHOW_GLOBAL_NOTICE_ALERT';
export const SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT = 'SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT';

// actions
export const LOAD_CASE_SUB_HEADER = 'LOAD_CASE_SUB_HEADER';
export const LOAD_SUB_HEADER = 'LOAD_SUB_HEADER';

const layoutStore = {
	namespaced: true,
	state: {
		title: '', // 모바일 타이틀
		views: {
			isDetail: false, // mainPage 여부(header, bottomNav에 영향)
			isLogoTopBar: false, // logoTopBarHeader
			isShowFooter: true,
			backwardButtonMarginBottom: '16px',
			isShowHeader: true,
			isShowSubHeader: false,
			isShowBottomNav: true,
			bgColor: null, // main grid background color
			fluid: false, // main grid fluid
			[SUB_HEADER_LIST]: [],
			[IS_TRANSPARENT_TYPE]: false,
			[IS_SHOW_GLOBAL_NOTICE_ALERT]: false,
			[IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]: false,
		},
		[SUB_HEADER_INDEX]: 0,
		[IS_LOADED_SUB_HEADER_LIST]: false,
	},
	mutations: {
		setMeta(state, payload) {
			state.title = payload.title ?? state.title;
			state.views = {
				...state.views,
				...payload,
			};

			const {
				backwardButtonMarginBottom,
				isDetail,
				isShowHeader,
				isShowSubHeader,
				isShowFooter,
				isLogoTopBar,
				bgColor,
			} = state.views;
			const isApp = window.navigator.userAgent.match(APP_USER_AGENT_REGEX) !== null;
			if (isApp) {
				appCommandMixin.methods.$_app_setMeta({
					backwardButtonMarginBottom,
					isDetail,
					isShowHeader,
					isShowSubHeader,
					isShowFooter,
					isLogoTopBar,
					title: state.title,
					bgColor,
				});
			}
		},
		[SET_SUB_HEADER_INDEX](state, payload) {
			state[SUB_HEADER_INDEX] = payload;
		},
		[SET_SUB_HEADER_LIST](state, payload) {
			state.views[SUB_HEADER_LIST] = payload;
		},
		[SET_IS_LOADED_SUB_HEADER_LIST]: (state, payload) => {
			state[IS_LOADED_SUB_HEADER_LIST] = payload;
		},
		[SET_IS_TRANSPARENT_TYPE]: (state, payload) => {
			state.views[IS_TRANSPARENT_TYPE] = payload;
		},
		[SET_IS_SHOW_GLOBAL_NOTICE_ALERT]: (state, payload) => {
			state.views[IS_SHOW_GLOBAL_NOTICE_ALERT] = payload;
		},
		[SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]: (state, payload) => {
			state.views[IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT] = payload;
		},
	},
	getters: {
		backwardButtonMarginBottom: state => {
			return state.views.backwardButtonMarginBottom;
		},
		isDetail: state => {
			return state.views.isDetail;
		},
		showHeader: state => {
			return state.views.isShowHeader;
		},
		showSubHeader: state => {
			return state.views.isShowSubHeader;
		},
		showFooter: state => {
			return state.views.isShowFooter;
		},
		showBottomNav: state => {
			return state.views.isShowBottomNav;
		},
		isLogoTopBar: state => {
			return state.views.isLogoTopBar;
		},
		headerTitle: state => {
			return state.title;
		},
		bgColor: state => {
			return state.views.bgColor;
		},
		fluid: state => {
			return state.views.fluid;
		},
		[SUB_HEADER_INDEX]: state => {
			return state[SUB_HEADER_INDEX];
		},
		[SUB_HEADER_LIST]: state => {
			return state.views[SUB_HEADER_LIST];
		},
		[IS_TRANSPARENT_TYPE]: state => {
			return state.views[IS_TRANSPARENT_TYPE];
		},
		[IS_LOADED_SUB_HEADER_LIST]: state => {
			return state[IS_LOADED_SUB_HEADER_LIST];
		},
		[IS_SHOW_ALERT_LAYOUT]: (_state, getters, _rootState, _rootGetters) => {
			return (
				!getters[IS_APP] &&
				(getters[IS_SHOW_GLOBAL_NOTICE_ALERT] ||
					_rootGetters.isKPC ||
					getters[GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT])
			);
		},
		[IS_SHOW_GLOBAL_NOTICE_ALERT]: state => {
			return state.views[IS_SHOW_GLOBAL_NOTICE_ALERT];
		},
		[GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]: state => {
			return state.views[IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT];
		},
	},
	actions: {
		async [LOAD_CASE_SUB_HEADER]({ commit }) {
			try {
				const { data } = await axios.get(`/api/case/header`).then(response => {
					return response;
				});
				const caseMenu = data.map(menu => {
					menu.url = getRoutePath(`${CASE_PATH}?category=${menu.name}`, 'nuxt');
					menu.new = menu.name === CASE_CATEGORY_WORKING_LEVEL_SKILL;

					if (menu.name === '실무스킬') {
						const menuInfo = menu;
						menuInfo.name = '실무고민';
						return menuInfo;
					}
					if (menu.name === '랜선 사수') {
						const menuInfo = menu;
						menuInfo.name = '직장인고민';
						return menuInfo;
					}
					return menu;
				});

				const tempCaseSubHeader = _uniqBy(
					[
						{ name: '타임라인', url: getRoutePath(CASE_PATH, 'nuxt') },
						...caseMenu,
						{ name: '답변하기', url: ANSWER_PATH },
					],
					'name',
				);
				commit(SET_SUB_HEADER_LIST, tempCaseSubHeader);
				commit(SET_IS_LOADED_SUB_HEADER_LIST, true);
			} catch (error) {
				console.log(error);
			}
		},
		async [LOAD_SUB_HEADER]({ dispatch, commit }, params) {
			commit(SET_IS_LOADED_SUB_HEADER_LIST, false);
			if (params === 'qna') {
				await dispatch(LOAD_CASE_SUB_HEADER);
			} else if (params === 'edu') {
				commit(SET_SUB_HEADER_LIST, [
					{ name: '홈', url: '/edu' },
					{ name: this.isMobile ? '모집 캠프' : '모집 중인 캠프', url: getRoutePath('/edu/list', 'nuxt') },
					// { name: '실시간 후기', url: '/edu/review', caption: true },
					{ name: '채용연계과정', url: getRoutePath(EXTERNSHIP_PATH, 'nuxt') },
					{ name: '직무 VOD', url: getRoutePath(CLASS_VOD_LIST_PATH, 'nuxt') },
					{ name: '수료생 공간', url: getRoutePath(EDU_ALUMNI_PATH, 'nuxt') },
				]);
				// dispatch(LOAD_EDU_COUNT);
				commit(SET_IS_LOADED_SUB_HEADER_LIST, true);
			} else if (params === 'class') {
				commit(SET_IS_LOADED_SUB_HEADER_LIST, false);
			}
		},
	},
};

export default layoutStore;
