<template>
	<c-new-col id="section" :style="isUser" style="padding-bottom: 0">
		<div class="resume-builder">
			<div>
				<div class="resume-landing-section">
					<div class="resume-landing-item">
						<div class="display1 mb-10">자기소개서 튜토리얼</div>
						<div class="mb-30" style="word-break: keep-all">
							기업 채용 인재상과 평가기준에 맞춰<br />
							설계된 <strong>무료</strong> 자기소개서 튜토리얼입니다.<br />
							<br />
							본 튜토리얼은 정식 자기소개서를 작성하기 전에 <br v-show="!isMobile" />
							직무가 요구하는 역량에 맞게 경험을 정리하는 것을 <br v-show="!isMobile" />
							도와드립니다.
						</div>
						<router-link to="/essay/tutorial/1">
							<c-button size="large" :class="{ 'mx-auto': isMobile }">
								시작하기
								<template v-slot:right-icon>
									<c-icon
										name="IconBackwardLargeLine"
										color="white"
										:rotate="180"
										class="c-pointer"
									/>
								</template>
							</c-button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</c-new-col>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	props: ['userNo', 'userType', 'tutorialPage'],
	computed: {
		isUser() {
			//로그인 안했을 때 서브 메뉴 관련 style
			if (this.userType !== undefined && this.userType >= 1) {
				if (this.isMobile) {
					return 'padding-top: 42px !important;';
				} else {
					return '';
				}
			} else {
				return 'padding-bottom: 0';
			}
		},
	},
	created() {
		if (parseInt(this.tutorialPage) > 0) {
			this.$router
				.push({
					path: `/essay/tutorial/${this.tutorialPage}`,
				})
				.catch(() => {});
		} else {
			// GTM - beginTutorial
			beginTutorial(this.userNo, this.userType);
			// GTM - GAVirtualPageview
			GAVirtualPageview(this.$route.path);
		}
	},
	mounted() {
		this.setMeta({
			isDetail: true,
			title: '자기소개서 튜토리얼',
			fluid: true,
			isShowFooter: true,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
	},
};
</script>
