const preventCopyAndPasteMixin = {
	props: {
		userType: {
			type: Number,
		},
	},
	data() {
		return {
			// TODO: 문구 의논해서 변경
			copyAndPasteAlertModalInfo: {
				title: '',
				message: '멘토님만의 지식과 경험을 나누기 위해<br/>복사/붙여넣기는 제한하고 있어요',
			},
			showCopyAndPasteAlertModal: false,
		};
	},
	computed: {
		$_preventCopyAndPasteMixin_isMentor() {
			return this.userType === 2;
		},
	},
	methods: {
		// laravel의 protect와 동일
		$_preventCopyAndPasteMixin_preventPaste(event) {
			event.preventDefault();
		},
		$_preventCopyAndPasteMixin_openModal() {
			this.showCopyAndPasteAlertModal = true;
		},
		$_preventCopyAndPasteMixin_closeModal() {
			this.showCopyAndPasteAlertModal = false;
		},
		$_preventCopyAndPasteMixin_handlePreventPaste(event) {
			if (this.$_preventCopyAndPasteMixin_isMentor) {
				this.$_preventCopyAndPasteMixin_preventPaste(event);
				this.$_preventCopyAndPasteMixin_openModal();
			}
		},
	},
};

export default preventCopyAndPasteMixin;
