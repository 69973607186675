import Vue from 'vue';

Vue.directive('autoresize', {
	inserted: function (el) {
		el.style.height = el.scrollHeight + 'px';
		el.style.overflowY = 'hidden';
		el.style.resize = 'none';

		function OnInput() {
			this.style.height = 'auto';
			this.style.height = this.scrollHeight + 'px';
			// this.scrollTop = this.scrollHeight;
			// window.scrollTo(window.scrollLeft,(this.scrollTop+this.scrollHeight));
		}
		el.addEventListener('input', OnInput, false);
	},
});
