<template>
	<div class="badge" :class="[computedVariant, computedSize]" v-html="text">
		<!--		<i class="icon icon-crown"></i>얼리버드-->
	</div>
</template>

<script>
export default {
	name: 'Badge',
	props: {
		text: String,
		variant: {
			type: String,
			default: 'sub',
		},
		size: String,
	},
	computed: {
		computedVariant() {
			return `badge-${this.variant}`;
		},
		computedSize() {
			return this.size ? `badge-${this.size}` : '';
		},
	},
};
</script>

<style lang="less" scoped>
@import '../../../less/base/index';

.badge {
	font-size: 13px;
	line-height: 24px;
	display: inline-block;
	padding: 0 8px;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 5px;

	&-sm {
		font-size: 10px;
		line-height: 18px;
		padding: 0 6px;
	}

	&-sub {
		background: @sub-color;
		color: @white;
	}
	&-heading {
		background: @heading-color;
		color: @white;
	}
	&-green {
		background: #29b263;
		color: @white;
	}
	&-skyblue {
		// early-discount
		background: #68d5fd;
		color: @white;
	}
	&-link {
		// oneday
		background: @link-color;
		color: @white;
	}
	&-indigo {
		background: midnightblue;
		color: @white;
	}
	&-purple {
		// new-camp
		background: #845fad;
		color: @white;
	}
	&-red {
		background: @error-color;
		color: @white;
	}
	&-orange {
		background: #ff7600;
		color: @white;
	}
	&-normal {
		background: @normal-color;
		color: @white;
	}
	&-yellow {
		// encore
		background: #ffee7a;
		color: @heading-color;
	}
	&-rainbow {
		//background: #845fad;
		background: linear-gradient(-45deg, #f424e8, #eda400, #03d2a0, #6e00ee);
		background-size: 400% 400%;
		color: @white;
		animation: gradient 3s ease infinite;

		@keyframes gradient {
			0% {
				background-position: 0% 50%;
			}
			50% {
				background-position: 100% 50%;
			}
			100% {
				background-position: 0% 50%;
			}
		}
	}
	&.encore {
		img {
			//앵콜 아이콘(왕관)
			margin-right: 5px;
			@media (max-width: @phone) {
				margin-right: 0;
			}
		}
	}
}
</style>
