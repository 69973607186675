<template>
	<div v-if="showCampTwoWeeks" :class="isMobile ? 'mt-40' : 'mt-60'">
		<c-typography
			:type="isMobile ? 'headline5' : 'headline4'"
			color="gray900"
			element="p"
			:font-weight="600"
			class="mb-4"
		>
			바로 참여할 수 있는 캠프
		</c-typography>
		<c-typography :type="isMobile ? 'body1' : 'headline7'" color="gray500" :class="isMobile ? 'mb-20' : 'mb-32'">
			15일 이내에 시작해요.
		</c-typography>
		<template v-if="showCampTwoWeeks">
			<c-swiper-dynamic
				:with-controls="!isMobile"
				controls-position="top"
				controls-color="dark"
				:with-indicator="!isMobile"
				indicator-color="dark"
				indicator-position="outside"
				:spacing="isMobile ? 12 : 24"
				:slides-per-view="isMobile ? 'auto' : 4"
				:slides-per-group="isMobile ? 1 : 4"
			>
				<template v-for="(item, index) in campInTwoWeeksList" :slot="`item${index}`">
					<a :key="`camp-in-two-weeks-item-${index}`" :href="item.url" class="d-block">
						<c-edu-card
							:background-image="campTitleImage(item.title_img)"
							:category="item.mid_category"
							:caption-left="`${item.running}개의 스케쥴`"
							:caption-right="`${$_momentFormat(item.start_date, 'M월 D일')} 부터`"
							:name="index"
							:additional-subtitle="item.round > 1 ? `${item.round}차 앵콜` : ''"
							with-swiper
						>
							<template v-slot:title>
								{{ item.title }}
							</template>
						</c-edu-card>
					</a>
				</template>
			</c-swiper-dynamic>
		</template>
		<template v-else>
			<c-row>
				<c-style-col v-for="index in isMobile ? 1 : 3" :key="`skeleton-${index}`" :col-sm="12" :col-lg="4">
					<base-skeleton width="100%" :height="isMobile ? '220px' : '250px'" style="border-radius: 4px" />
				</c-style-col>
			</c-row>
		</template>
	</div>
</template>

<script>
import { imageResize } from '../../utils/imageResize';

export default {
	name: 'EduStartsInTwoWeeksList',
	data() {
		return {
			campInTwoWeeksList: [],
			page: 1,
			showCampTwoWeeks: false,
		};
	},
	watch: {
		page: 'getCampListInTwoWeeks',
	},
	async mounted() {
		this.campInTwoWeeksList = _.shuffle([...this.campInTwoWeeksList, ...(await this.getCampListInTwoWeeks())]);
	},
	methods: {
		async getCampListInTwoWeeks() {
			try {
				const params = {
					keyword: this.keyword,
					page: this.page,
					limit: 10,
					partner: 'comento',
				};
				const { data } = await axios.get('/api/edu/search', { params });

				if (data.code === 200) {
					this.showCampTwoWeeks = true;
					return data.list.list;
				}
				return [];
			} catch (error) {
				console.error(error);
				return [];
			}
		},
		campTitleImage(image) {
			return `${image}${imageResize(1920 / 4, 1004 / 4)}`;
		},
	},
};
</script>

<style scoped></style>
