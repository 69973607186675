<template>
	<div>
		<section id="chatBox" class="chat-box">
			<ul class="chat-balloon-group clearfix">
				<infinite-loading ref="infiniteLoading" direction="top" @infinite="infiniteHandler">
					<span slot="no-more"></span>
					<span slot="no-results"></span>
				</infinite-loading>
				<li v-for="(chat, index) in chatList" :key="`chat-list-${index}`">
					<div v-if="isVisibleDate(index, chat.published_at)" class="date-line">
						<div class="date" v-text="dateLine(chat.published_at)"></div>
					</div>
					<div class="chat-balloon-item mb-20" :class="dynamicChatMessage(index, chat.type, chat.merge)">
						<div v-if="!chat.merge" class="chat-user-item mb-5 flex flex-row flex-v-middle flex-between">
							<div class="flex flex-row flex-v-middle">
								<img :src="chatProfile(chat.type)" class="profile-img" />
								<p class="h7" v-text="dynamicUserName(chat.type)"></p>
							</div>
							<div v-if="chat.type === 2" class="date" v-text="chat.published_at"></div>
							<!-- v-text="publishedDateFormat(chat.published_at)" -->
						</div>
						<div :class="changeShapeMessage(chat.type)">
							<div v-if="chat.type === 2">
								<span v-html="$options.filters.nl2br(chat.message)"></span>
							</div>
							<div v-else class="user-chat flex flex-row">
								<span class="user-chat-message" v-html="$options.filters.nl2br(chat.message)"></span>
								<div
									v-if="chat.type < 2 && mergeDate(index)"
									class="date"
									v-text="chat.published_at"
								></div>
								<!-- v-text="publishedDateFormat(chat.published_at)" -->
							</div>
						</div>
					</div>
				</li>
			</ul>
		</section>
		<div v-if="applyStatus !== '전형종료' || (display === 'biz' && parseInt(guest) === 0)">
			<section class="mt-10 only-pc">
				<textarea v-model="message" class="chat-write mb-10" :placeholder="chatPlaceholder"></textarea>
				<div class="text-right">
					<button class="btn" :class="isEmptyMessage" @click="doSubmitMessage()">전송</button>
				</div>
			</section>
			<section class="only-mobile">
				<!-- 댓글 입력창 -->
				<div class="reply-all-box mypage-left reply-all-box-feedback clearfix">
					<textarea
						id="input-text"
						v-model="message"
						class="textbox text-maxh"
						rows="4"
						type="text"
						:placeholder="chatPlaceholder"
						@keyup="dynamicHeight($event, '.reply-all-box')"
					></textarea>
					<div
						id="lounge-reply-btn"
						class="button btn float-right"
						:class="isEmptyMessage"
						@click="doSubmitMessage()"
					>
						전송
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'display',
		'applyNo',
		'applyType',
		'companyName',
		'userName',
		'randomNo',
		'companyLogo',
		'applyStatus',
		'guest',
		'baseUrl',
	],
	data() {
		return {
			message: '',
			chatList: [],
			page: 1,
			tmp: [],
		};
	},
	computed: {
		chatPlaceholder() {
			// if (this.display === 'mypage') { //유저
			//     return '기업에게 메세지를 보낼 수 있습니다.';
			// } else if (this.display === 'biz') { //기업
			//     return '구직자에게 메세지를 보낼 수 있습니다.';
			// }
			return '메시지를 입력하세요.';
		},
		isEmptyMessage() {
			if (this.message === null || this.message === '') {
				return 'disabled';
			}
			return '';
		},
	},
	mounted() {
		if (this.display === 'biz') {
			const headerHeight = document.querySelector('.header-bar').offsetHeight;
			const chat = document.querySelector('.chat-balloon-group');
			const replyHeight = document.querySelector('.reply-all-box').offsetHeight;
			chat.style.maxHeight = window.innerHeight - (headerHeight + replyHeight + 15) + 'px';
		}
	},
	methods: {
		imgP(num) {
			return '/images/my_anonymous_' + num + '.svg';
		},
		chatProfile(type) {
			if (type === 0) {
				//유저
				return this.imgP(this.randomNo);
			} else if (type === 1) {
				//기업
				return this.baseUrl + 'conect/' + this.companyLogo;
			} else {
				//AI취업비서
				return '/images/cobiseo.svg';
			}
		},
		infiniteHandler($state) {
			setTimeout(() => {
				axios({
					method: 'GET',
					url: '/api/apply/message/list',
					params: {
						apply_no: this.applyNo,
						page: this.page,
						limit: 10,
						display: this.display,
					},
				})
					.then(response => {
						// console.log(response.data.list.data);
						try {
							if (response.data.code === 200) {
								if (response.data.list.data.length >= 1) {
									this.page += 1;
									let list = [];
									response.data.list.data.forEach(element => {
										if (element.type === 2) {
											//AI취업비서
											//취업비서 메시지는 기업용,유저용이 다름
											if (this.display === 'mypage' && element.to === 0) {
												list.push(element);
											}
											if (this.display === 'biz' && element.to === 1) {
												list.push(element);
											}
										} else {
											list.push(element);
										}
									}, this);
									this.chatList.unshift(...list.reverse()); //배열에 담기
									this.doMergeMessage(); //메시지 합치기
									$state.loaded();
								} else {
									$state.complete();
								}
							}
						} catch (error) {
							console.log(error);
							$state.complete();
						}
					})
					.catch(error => {
						console.log(error);
						$state.complete();
					});
			}, 1000);
		},
		doSubmitMessage() {
			//메시지 전송
			showLoading();
			axios({
				method: 'POST',
				url: '/api/apply/message/write',
				data: {
					apply_no: this.applyNo,
					message: this.message,
					type: this.display === 'mypage' ? 0 : 1,
				},
			})
				.then(response => {
					if (response.data.code === 200) {
						// console.log(response.data);
						let type = 0;
						if (this.display === 'biz') type = 1;

						this.chatList.push({
							//배열 마지막에 추가
							apply_no: this.applyNo,
							type: type,
							message: this.message,
							published_at: moment().format('YYYY-MM-DD HH:mm:SS'),
							received_at: '',
							no: this.chatList.length + 1,
						});
						this.message = '';
						this.doMergeMessage();
						this.scrollTo(); //스크롤 맨밑으로 이동

						if (this.isMobile) {
							//모바일 답변창 css 초기화
							document.querySelector('.reply-all-box textarea').style.height = '40px';
							document.querySelector('.reply-all-box .btn').style.height = 'auto';
							document.querySelector('.reply-all-box .btn').style.lineHeight = '38px';
							document.querySelector('.reply-all-box').style.height = 'auto';
						}
						closeLoading();
					}
				})
				.catch(error => {
					console.log(error);
					closeLoading();
				});
		},
		doMergeMessage() {
			//메시지 합치기
			moment.defaultFormat = 'YYYY-MM-DD HH:mm';
			let beforeDate = ''; //이전 메시지의 보낸 날짜
			for (let index in this.chatList) {
				this.tmp = [this.chatList[index].type, this.chatList[index].published_at];
				beforeDate = moment(this.tmp[1], moment.defaultFormat);
				this.chatList[index].merge = false;
				if (parseInt(index) > 0) {
					//타입이 같으면서 이전 메시지와 현재 메시지의 'YYYY-MM-DD HH:mm'가 같으면 merge
					if (this.tmp[0] === this.chatList[index].type) {
						if (
							beforeDate.isSame(
								moment(this.chatList[parseInt(index) - 1].published_at, moment.defaultFormat),
							)
						) {
							this.chatList[index].merge = true;
						}
					}
				}
			}
		},
		dynamicHeight(event, className) {
			if (className === '.feedback-reply') {
				//enter key를 누르면 사이즈가 커진다.
				if (event.currentTarget.which === 13) $('#input-text').height($('#input-text').height() + 36);

				//line number 가져오기
				let line = $('#input-text').attr('value').split(/r*\n/).length;

				if (line <= 4) $('#input-text').height(106 - (4 - line) * 24);

				$('#submit').height($('#input-text').outerHeight() - 2);
				$('#submit').css('line-height', $('#input-text').outerHeight() - 2 + 'px');
			} else {
				let filter = window.innerWidth;

				if (filter < 480) {
					let textarea = document.activeElement;
					let button = $(textarea).siblings('.button');

					$(textarea)
						.css('height', '1px')
						.css('height', $(textarea).prop('scrollHeight') + 'px');
					$(button).height($(textarea).innerHeight());
					$(button).css('line-height', $(textarea).innerHeight() + 'px');
				}
			}
		},
		dateLine(date) {
			//년월일
			return moment(date).format('YYYY년 M월 D일');
		},
		isVisibleDate(index, date) {
			//메시지 전송 시간
			if (index === 0) return true;
			if (index > 0) {
				let beforeDate = moment(date, 'YYYY-MM-DD');
				if (!beforeDate.isSame(moment(this.chatList[parseInt(index) - 1].published_at, 'YYYY-MM-DD'))) {
					return true;
				}
			}
		},
		dynamicChatMessage(index, type, merge) {
			let resultClass = '';
			if (type === 0 && this.display === 'mypage') {
				resultClass = ' text-right';
			} else if (type === 1 && this.display === 'biz') {
				resultClass = ' text-right';
			} else if (type === 2) {
				//max-width: 93%
				resultClass += ' ai-chat';
			}
			if (merge) {
				//메시지 합치기
				resultClass += ' merge-chat';
			}
			return resultClass;
		},
		mergeDate(index) {
			//날짜 합치기
			if (index + 1 < this.chatList.length) {
				if (!this.chatList[parseInt(index) + 1].merge) return true;
			} else {
				return true;
			}
		},
		scrollTo() {
			//스크롤 채팅창 맨밑으로 이동
			this.$nextTick(() => {
				let chatWindow = document.getElementById('chatBox');
				chatWindow.scrollTop = chatWindow.scrollHeight;
			});
		},
		dynamicUserName(type) {
			switch (type) {
				case 0: //유저
					return this.userName;
				case 1: //기업
					return this.companyName;
				case 2: //AI취업비서
					return 'AI취업비서';
			}
		},
		changeShapeMessage(type) {
			let messageClass = 'chat-message-balloon h7';
			if (type === 0 && this.display === 'mypage') {
				//내 메시지
				return messageClass + ' my-chat';
			}
			if (type === 1 && this.display === 'biz') {
				//내 메시지
				return messageClass + ' my-chat';
			}
			if (type === 0 || type === 1) {
				return messageClass;
			} else {
				return 'well';
			}
		},
		// publishedDateFormat(date) {
		// 	// let beforeDate = moment(date);
		// 	// if(beforeDate.isBefore(moment(), 'date')) { //이전에 보낸 메시지는 'YY.MM.DD'
		// 	moment.updateLocale('ko', {
		// 		relativeTime: { A: '오전', P: '오후' },
		// 	});
		//
		// 	let regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
		// 	if (regExp.test(date)) {
		// 		date = date.split(' ');
		// 		date = moment(date[1], 'hh:mm A').format('A hh:mm');
		// 	}
		// 	// } else { //오늘 보낸 메시지
		// 	//     moment.updateLocale('ko', {
		// 	//         relativeTime : {
		// 	//             s: "방금",
		// 	//             m: "%d분 전",
		// 	//             mm: "%d분 전",
		// 	//             h:  "%d시간 전",
		// 	//             hh: "%d시간 전",
		// 	//         }
		// 	//     });
		// 	//     date = moment(date).fromNow('m');
		// 	// }
		// 	return date;
		// },
	},
};
</script>

<style lang="less" scoped>
@border-color: #dee2e6;

.chat-box {
	border: 1px solid @border-color;
	padding: 0 0 20px 20px;
	height: 530px;
	overflow-x: hidden;
	overflow-y: auto;
}

.date {
	font-size: 11px;
}

.date-line {
	border-top: 1px solid @border-color;
	position: relative;
	margin: 20px 20px 20px 0;
	.date {
		position: absolute;
		background: #fff;
		top: -10px;
		left: 50%;
		padding: 0 10px;
		transform: translateX(-50%);
	}
}

.chat-balloon-group {
	max-height: 510px;

	li:last-child {
		margin-bottom: 20px;
	}

	.chat-balloon-item {
		&:first-child {
			margin-top: 20px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.ai-chat {
			max-width: 93%;
		}

		&.merge-chat {
			margin-top: 0;
			.chat-message-balloon {
				margin-top: 5px;
			}
		}

		.profile-img {
			width: 20px;
			height: 20px;
			margin-right: 5px;
		}

		&.text-right {
			.chat-user-item {
				justify-content: flex-end;
				margin-right: 15px;
			}
			.date {
				left: -55px;
			}
		}

		.chat-message-balloon {
			border: 1px solid @border-color;
			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 10px;
			padding: 10px 15px;
			display: inline-block;
			position: relative;
			max-width: 250px;
			text-align: left;
			&.my-chat {
				border-color: #2a7de1;
				margin-right: 15px;
			}
			.chat-user-group {
				position: absolute;
				right: -55px;
				bottom: 0;
			}
			.user-chat {
				&-message {
					word-break: break-all;
				}
				.date {
					position: absolute;
					right: -55px;
					bottom: 0;
				}
			}
		}

		.well {
			padding: 15px;
			text-align: left;
			font-size: 14px;
			position: relative;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 5px;
			}
			.chat-user-group {
				width: 100%;
				position: absolute;
				top: -30px;
				margin-left: -15px;
				bottom: 65px;
				font-weight: normal;
			}
		}
	}
}

.chat-write {
	min-height: 115px;
}

@media (max-width: 480px) {
	.chat-box {
		height: auto;
		border: none;
	}
}
</style>
