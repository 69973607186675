<template>
	<modal-component :show="show" :type="type" @close="close">
		<div id="confirm-component" class="modal-body text-center">
			<h5
				class="popup-title mb-10 text-center f-semi-bold"
				:style="type === 'simple' ? '' : 'margin-left:25px'"
				v-html="$options.filters.nl2br(title)"
			></h5>
			<div class="text-heading mb-20 cleafix" :class="align === 'center' ? 'text-center' : 'text-left'">
				<p class="modal-message" v-html="$options.filters.nl2br(message)"></p>
			</div>
			<div class="mt-20 btn-group" :class="align === 'center' ? 'text-center' : 'text-right'">
				<button class="btn btn-empty text-caption" @click="close">취소</button>
				<button class="btn" :class="saveStyle === 'erro' ? 'btn-erro' : ''" @click="save">{{ button }}</button>
			</div>
		</div>
	</modal-component>
</template>

<script>
export default {
	props: {
		show: Boolean,
		title: String,
		message: String,
		buttonName: {
			type: String,
			default: '확인',
		},
		successCallback: String,
		type: String,
		align: String,
		saveStyle: String,
		mask: Boolean,
		function: Function,
	},
	data() {
		return {
			button: '',
		};
	},
	created() {
		if (this.buttonName === '' || this.buttonName === undefined) {
			this.button = '확인';
		} else {
			this.button = this.buttonName;
		}
	},
	updated() {
		if (this.buttonName === '' || this.buttonName === undefined) {
			this.button = '확인';
		} else {
			this.button = this.buttonName;
		}
	},
	methods: {
		close() {
			if (this.mask !== 'false') {
				this.$emit('close');
			}
		},
		save() {
			if (this.successCallback !== '' && typeof this.successCallback !== 'undefined') {
				location.href = this.successCallback;
			} else if (this.function !== '' && typeof this.function !== 'undefined') {
				this.function();
			}
			this.$emit('save');
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
.text-heading {
	overflow: hidden;
}
.btn-group {
	.btn {
		width: auto;
		min-width: 120px;
	}
}
@media (max-width: 480px) {
	.btn-group {
		.btn {
			min-width: 30%;
		}
	}
}
</style>
