<template>
	<c-alert
		type="notice"
		closable
		class="global-notice-alert"
		:class="{ 'c-pointer': hasUrl }"
		@click.native="goTargetPage"
		@close="emitClose"
	>
		{{ globalNoticeInfo.content }}
	</c-alert>
</template>

<script>
export default {
	name: 'GlobalNoticeAlert',
	props: {
		globalNoticeInfo: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		hasUrl() {
			return !this.isEmptyData(this.globalNoticeInfo.url);
		},
	},
	methods: {
		emitClose() {
			this.$emit('close');
		},
		goTargetPage() {
			if (!this.hasUrl) {
				return false;
			}
			window.open(this.globalNoticeInfo.url);
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.global-notice-alert {
	z-index: 8100 !important;
	@include pc {
		&::v-deep .c-alert--col {
			padding: 0 !important;
			.c-alert--wrapper {
				margin-left: 4px;
			}
		}
	}
}
</style>
