<template>
	<div style="overflow-x: hidden">
		<BlogHeader />
		<section class="blog-banner-container">
			<h2 class="main-copy display2">
				<span class="f-light">코멘토만이 말할 수 있는</span><br class="only-mobile" />
				취업이야기
			</h2>
			<img src="/images/bg/bg_blog.jpg" class="only-pc" alt />
			<img src="/images/bg/bg_blog-m.jpg?date=191021" class="only-mobile" alt />
		</section>
		<section class="content-container">
			<div id="blog-list-container" class="container blog-container">
				<div class="row">
					<BlogMobileMenu :menus="blogMagazines" />
					<BlogCard :blog-list="blogList" />

					<infinite-loading
						ref="infiniteLoading"
						:identifier="infiniteId"
						force-use-infinite-wrapper="true"
						@infinite="infiniteHandler"
					>
						<span slot="no-more" />
						<span slot="no-results" />
					</infinite-loading>
					<CustomLink v-if="isComentoUser()" class="float-btn write" to="/blog/write" />
				</div>
			</div>
		</section>
		<BlogFooter />
	</div>
</template>

<script>
import BlogHeader from '../../components/blog/HeaderComponent.vue';
import BlogCard from '../../components/blog/CardComponent.vue';
import BlogMobileMenu from '../../components/blog/MobileMenuComponent.vue';
import BlogFooter from '../../components/blog/FooterComponent.vue';
import { blog } from '../../mixins/blog';
import { mapMutations } from 'vuex';
import CustomLink from '../../components/common/CustomLink.vue';

export default {
	mixins: [blog],
	props: {
		userEmail: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		page: 1,
		paramsCategory: [],
		blogList: [],
		infiniteId: +new Date(),
	}),
	watch: {
		$route: 'init',
	},
	mounted() {
		this.setMeta({
			isShowFooter: false,
			isShowHeader: false,
			isDetail: true,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		infiniteHandler($state) {
			setTimeout(() => {
				axios({
					method: 'GET',
					url: '/api/blog/list',
					params: {
						page: this.page,
					},
				})
					.then(response => {
						const data = response.data;
						// console.log(data);
						try {
							if (data.code === 200) {
								if (Object.keys(data.list.data).length > 0) {
									this.blogList.push(...data.list.data);
									this.page += 1;
									$state.loaded();
								} else {
									$state.complete();
								}
							}
						} catch (error) {
							$state.complete();
						}
					})
					.then(() => {
						// if (this.campList.length === 0 && this.isLoaded) {
						//     this.isCampListEmpty = true;
						// }
					})
					.catch(error => {
						console.log(error);
						$state.complete();
					});
			}, 500);
		},
		init() {
			this.blogList = [];
			this.page = 1;
			this.infiniteId += 1;
		},
		isComentoUser() {
			return (
				this.$store.state.sessionInfo !== null &&
				this.$store.state.sessionInfo.id.split('@')[1] === 'comento.kr'
			);
		},
	},
	components: {
		CustomLink,
		BlogHeader,
		BlogCard,
		BlogMobileMenu,
		BlogFooter,
	},
};
</script>

<style lang="less" scoped></style>
