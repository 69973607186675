<!-- 이력서 튜토리얼 이전단계 리다이렉트용 -->
<template>
	<div></div>
</template>

<script>
export default {
	beforeCreate() {
		const isStep = ~location.href.indexOf('step');
		const step = parseInt(location.href.split('#')[0].substring(location.href.split('#')[0].length - 1));

		if (isStep) {
			this.$router
				.replace({
					path: `/resume/builder/tutorial/${step}`,
				})
				.catch(() => {});
		} else {
			this.$router
				.replace({
					path: `/resume/builder/tutorial/${1}`,
				})
				.catch(() => {});
		}
	},
};
</script>
