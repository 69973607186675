<template>
	<div
		v-show="showHeader"
		v-scroll="headerScrollHandler"
		class="header-container"
		:class="{ 'transparent-type': isTransparentTypePage }"
	>
		<ComentoHeader
			:is-appear="false"
			:is-scroll-top="false"
			base-url="https://comento.kr"
			:no-sub-header="noSubHeader"
			:user-info="headerUserInfo"
			:search-keyword.sync="searchKeyword"
			:popular-keyword="popularKeywords"
			:recent-keyword="recentKeywords"
			:autocomplete-list="autocompleteList"
			:notification-count="notiCount"
			:bookmark-count="bookmarkCount"
			:top-bar-type="topBarType"
			:top-bar-title="headerTitle"
			:with-alert="IS_SHOW_ALERT_LAYOUT && !isApp"
			@search="searchEvent"
			@click-noti="openNoti"
			@remove-recent-keyword="removeRecentKeyword"
			@open-search="openSearchFullscreenModal"
			@click-search-input-for-mobile="openSearchFullscreenModal"
			@logout="signOut"
		/>
		<JrNaverEmailChangeModal :show="showEmailChange" :email="userEmail" @close="showEmailChange = false" />
		<NotiWrapper
			:show.sync="showNoti"
			:noti-count="notiCount"
			:user-info="{
				user_no: userNo,
				user_type: userType,
				email: userEmail,
			}"
			@reset-noti-count="resetNotiCount"
			@close="closeNoti"
		/>
		<SearchFullscreenModal
			:show.sync="showSearchFullscreenModal"
			:recent-keywords="recentKeywords"
			:popular-keywords="popularKeywords"
			:autocomplete-list="autocompleteList"
			@remove-recent-keyword="removeRecentKeyword"
			@debounced-autocomplete-list="debouncedAutocompleteList"
			@search="searchAtFullscreenModal"
			@close="closeSearchFullscreenModal"
		/>
		<alert-modal-component
			:show="showAlert"
			:title="alertModalTitle"
			:message="alertModalMessage"
			:button-name="alertModalButtonName"
			:success-callback="alertSuccessCallback"
			type="simple"
			mask="false"
			@close="showAlert = false"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { ComentoHeader, TOP_BAR_TYPES, SearchFullscreenModal } from '@comento/common-components';
import JrNaverEmailChangeModal from '../components/_modal/JrNaverEmailChangeModal.vue';
import alertModalMixin from '../mixins/alertModalMixin';
import navigationMixin from '../mixins/navigationMixin';
import myPageMixin from '../mixins/myPageMixin';
import {
	IS_TRANSPARENT_TYPE,
	SET_IS_TRANSPARENT_TYPE,
	SUB_HEADER_LIST,
	IS_SHOW_ALERT_LAYOUT,
} from '../store/modules/layoutStore';
import NotiWrapper from '../components/_common/noti/NotiWrapper.vue';
import appCommandMixin from '../mixins/appCommandMixin';
import layoutMixin from '../mixins/layoutMixin';
import searchMixin from '../mixins/searchMixin';
import { debounce } from '../utils/util';
// import { IS_PRODUCTION } from '@/constants';
import { datadogRum } from '@datadog/browser-rum';

// const instance = createInstance(
// 	IS_PRODUCTION ? import.meta.env.VITE_HACKLE_API_KEY_PROD : import.meta.env.VITE_HACKLE_API_KEY_DEV,
// 	{ debug: !IS_PRODUCTION },
// );

export default {
	name: 'HeaderLayout',
	mixins: [alertModalMixin, navigationMixin, myPageMixin, appCommandMixin, layoutMixin, searchMixin],
	props: {
		univ: String,
		major: String,
		belong: String,
		buyMentoringPaid: String,
		buyMentoringTierPaid: String,
		buyMentoringInfo: String,
	},
	data() {
		return {
			showEmailChange: false, //이메일 변경 팝업
			userEmail: null,
			userNo: null,
			userType: null,
			showNoti: false,
			notiCount: 0,
			bookmarkCount: 0,
			realHeaderHeight: null,
			popularKeywords: [],
			recentKeywords: [],
			autocompleteList: [],
			showSearchFullscreenModal: false,
		};
	},
	computed: {
		...mapGetters(['isKPC', 'userNickname', 'userProfileImage', 'isUser', 'userId']),
		...mapGetters('layoutStore', [
			'showHeader',
			'showSubHeader',
			SUB_HEADER_LIST,
			IS_TRANSPARENT_TYPE,
			IS_SHOW_ALERT_LAYOUT,
			'isDetail',
			'headerTitle',
			'isLogoTopBar',
		]),
		...mapGetters(['sessionInfo', 'buyMentoring']),
		headerUserInfo() {
			return {
				id: this.userId,
				name: this.userNickname,
				profileImage: this.userProfileImage,
			};
		},
		computedHandleRealHeaderHeight() {
			return { height: `${this.realHeaderHeight}px` };
		},
		isHideDivider() {
			if (this.isMobile && this.$_isMyPage && this.isDetail) {
				// 모바일 마이페이지 디테일에서 divider 없애기
				return true;
			}

			/**
			 * MOBILE: 서브 헤더가 있고 transparent가 아닐 때, 서브 헤더가 없고 transparent일 때
			 * PC 세브 헤더가 없고 transparent일 때
			 */
			const isMobile =
				(this.showSubHeader && !this[IS_TRANSPARENT_TYPE]) ||
				(!this.showSubHeader && this[IS_TRANSPARENT_TYPE]);
			const isPc = !this.showSubHeader && this[IS_TRANSPARENT_TYPE];
			return this.isMobile ? isMobile : isPc;
		},
		isTransparentTypePage() {
			return this.$_isIndex || this.$_isPt;
		},
		topBarType() {
			return this.isDetail
				? TOP_BAR_TYPES.BACKWARD
				: this.isLogoTopBar
				? TOP_BAR_TYPES.LOGO
				: TOP_BAR_TYPES.DEFAULT;
		},
		noSubHeader() {
			// EID CB101
			return this.$route.path.startsWith('/class/pt') || this.isDetail || (this.isMobile && this.isLogoTopBar);
		},
		// instance() {
		// 	return instance;
		// },
		hackleUserInfo() {
			return {
				userId: this.userNo,
			};
		},
	},
	watch: {
		'$store.state.sessionInfo'() {
			const { no, type, id } = this.sessionInfo || {};
			if (!this.isEmptyData(id)) {
				this.userEmail = id;
				this.userNo = no;
				this.userType = type;

				this.setBuyMentoring({
					paid: parseInt(this.buyMentoringPaid),
					tierPaid: parseInt(this.buyMentoringTierPaid),
					info: JSON.parse(
						this.buyMentoringInfo === 'undefined' || this.buyMentoringInfo === undefined
							? 'null'
							: this.buyMentoringInfo,
					),
				});

				//주니어네이버 메일이면 강제 팝업 띄우기
				if (this.isJrEmail(id)) {
					this.showEmailChange = true;
				}

				this.getNotiCount();
			}
		},
		[SUB_HEADER_LIST]() {
			this.handleRealHeaderHeight();
		},
		searchKeyword() {
			this.debouncedAutocompleteList(this.searchKeyword);
		},
	},
	async created() {
		await this.$store.dispatch('getSessionInfo');
		this.initDatadogUserSession();
		this.toggleTransparentType(this.isTransparentTypePage);
		this.realHeaderHeight = this.isMobile ? 49 : 70;
		await Promise.allSettled([
			this.getPopularKeywords(),
			this.getRecentKeywords(),
			this.getNotiCount(),
			this.getBookmarkCount(),
			this.getUserProfileImage(),
		]);
	},
	async mounted() {
		await this.handleRealHeaderHeight();
	},
	updated() {
		this.handleRealHeaderHeight();
	},
	methods: {
		...mapMutations(['setBuyMentoring']),
		...mapMutations('layoutStore', [SET_IS_TRANSPARENT_TYPE]),
		...mapActions(['getUserProfileImage']),
		async getPopularKeywords() {
			try {
				const { data } = await axios.get('/api/log/popular');
				const popularKeywordList = data;
				this.popularKeywords = popularKeywordList.map(obj => obj.keyword);
			} catch (error) {
				console.error(error);
				this.popularKeywords = [];
			}
		},
		async getRecentKeywords() {
			try {
				const { data } = await axios.get('/api/log/latest');
				this.recentKeywords = data;
			} catch (error) {
				console.error(error);
			}
		},
		async removeRecentKeyword({ index }) {
			const originalKeywords = this.recentKeywords;
			try {
				// UI에서 일단 바로 삭제하고 나중에 서버랑 동기화
				this.recentKeywords = this.recentKeywords.filter((_, i) => index !== i);
				const { data } = await axios.post('/api/log/keyword-delete', { index });
				this.recentKeywords = data;
			} catch (error) {
				this.recentKeywords = originalKeywords;
			}
		},
		async getNotiCount() {
			try {
				const params = { page: 1 };
				const { data } = await axios
					.get('/api/noti/list', { params })
					.then(response => {
						return response;
					})
					.catch(error => {
						console.error(error);
						return { data: { noti_count: 0 } };
					});

				this.notiCount = data.noti_count;
			} catch (error) {
				console.error(error);
			}
		},
		async getBookmarkCount() {
			try {
				const { data } = await axios.get('/api/user/bookmark/total-count');
				this.bookmarkCount = data.count;
			} catch (error) {
				console.error(error);
			}
		},
		async searchEvent({ keyword, searchType }) {
			await this.$_triggerSearch(keyword, searchType);
			this.getRecentKeywords();
		},
		isJrEmail(email) {
			return email !== '' && email.split('@')[1] === 'jr.naver.com';
		},
		saveEmailChange() {
			this.$_handleShowAlertModal({
				title: '변경 완료',
				message: '성공적으로 변경되었습니다.',
				buttonName: '',
				successCallback: '',
			});
		},
		openNoti() {
			if (!this.isUser) {
				return;
			}

			if (this.isApp && !this.isDetail) {
				this.$_app_setUI({ all: false });
			}
			// GTM - clickNotification
			clickNotification(this.userNo, this.userType, this.userEmail);
			this.showNoti = true;
		},
		closeNoti() {
			if (this.isApp && !this.isDetail) {
				this.$_app_setUI({ all: true });
			}
			this.showNoti = false;
		},
		toggleTransparentType(transparentType = false) {
			this[SET_IS_TRANSPARENT_TYPE](this.isTransparentTypePage && transparentType);
		},
		headerScrollHandler(evt, el) {
			const className = 'transparent-type';
			if (this.isTransparentTypePage) {
				if (window.pageYOffset > 5) {
					this.toggleTransparentType(false);
					el.classList.remove(className);
				} else {
					this.toggleTransparentType(true);
					el.classList.add(className);
				}
			}
		},
		// 앱에서 헤더를 숨긴 자리에 그대로 앱 헤더가 노출되어야 해서 영역을 남겨둠.
		handleRealHeaderHeight() {
			if (this.isHideHeader) {
				return this.$nextTick(() => {
					this.realHeaderHeight = 52 + (this.showSubHeader ? 44 : 0);
				});
			}
			this.$nextTick(() => {
				this.realHeaderHeight = this.$refs.headerWrapper && this.$refs.headerWrapper.clientHeight;
			});
		},
		resetNotiCount() {
			this.notiCount = 0;
		},
		async getAutocompleteList(autocompleteKeyword) {
			try {
				if (!autocompleteKeyword) {
					return [];
				}
				const { data } = await axios.get(`/api/search/auto?keyword=${encodeURI(autocompleteKeyword)}`);
				return data.list || [];
			} catch (error) {
				console.error(error);
				return [];
			}
		},
		debouncedAutocompleteList: debounce(async function (autocompleteKeyword) {
			this.autocompleteList = await this.getAutocompleteList(autocompleteKeyword);
		}, 200),

		openSearchFullscreenModal() {
			this.showSearchFullscreenModal = true;
		},
		closeSearchFullscreenModal() {
			this.showSearchFullscreenModal = false;
			this.$_app_setUI({ all: true });
		},
		async searchAtFullscreenModal({ keyword, searchType }) {
			await this.searchEvent({ keyword, searchType });
			this.closeSearchFullscreenModal();
			this.autocompleteList = [];
			if (['typing', 'popular'].includes(searchType)) this.$_goSearchPage(keyword);
		},
		signOut() {
			window.location.href = '/logout';
		},
		/**
		 * 데이터독 RUM 유저정보 세팅
		 */
		initDatadogUserSession() {
			if (this.isEmptyData(datadogRum.getUser())) {
				datadogRum.setUser({
					id: this.userNo,
					email: this.userId,
					type: this.userType,
				});
			}
		},
	},
	components: {
		NotiWrapper,
		ComentoHeader,
		JrNaverEmailChangeModal,
		SearchFullscreenModal,
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.header {
	&-container {
		// 투명 타입
		&.transparent-type {
			header,
			.header-wrapper,
			.sub-header,
			.top-bar,
			::v-deep.c-tabs--menu-container {
				background: transparent !important;
			}
		}
	}
	&-wrapper {
		background-color: $white;
	}
}
</style>
