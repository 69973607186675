import { HorizontalBar } from 'vue-chartjs';

export default {
	extends: HorizontalBar,
	data() {
		return {
			distribution: '',
		};
	},
	mounted() {
		axios({
			method: 'POST',
			url: '/api/analytics/result',
			data: {},
		}).then(response => {
			this.distribution = response.data.distribution;

			this.renderChart(
				{
					labels: [
						this.distribution[0]['name'] + ' ' + this.distribution[0]['per'] + '%',
						this.distribution[1]['name'] + ' ' + this.distribution[1]['per'] + '%',
					],
					datasets: [
						{
							label: '111',
							backgroundColor: 'rgb(212, 229, 249, 0.5)',
							borderColor: 'rgb(42, 125, 225)',
							data: [this.distribution[0]['per'], this.distribution[1]['per']],
						},
						{
							label: '222',
							backgroundColor: 'rgb(207, 212, 215)',
							data: [this.distribution[0]['non-per'], this.distribution[1]['non-per']],
						},
					],
				},
				{
					responsive: false,
					title: {
						display: true,
						text: ['우수 지원자', '성향 비율'],
						position: 'left',
						padding: 28,
					},
					tooltips: {
						enabled: false,
						mode: 'index',
						intersect: false,
					},
					hover: {
						mode: 'nearest',
						intersect: true,
					},
					scales: {
						xAxes: [
							{
								display: true,
								gridLines: {
									display: true,
									color: 'rgb(255, 255, 255)',
									lineWidth: 0,
									drawBorder: false,
									drawOnChartArea: true,
									drawTicks: false,
									zeroLineWidth: 1,
									zeroLineColor: 'rgb(220, 220, 220)',
									zeroLineBorderDashOffset: 1,
								},
								ticks: {
									display: false,
									suggestedMin: 0,
									suggestedMax: 100,
								},
								stacked: true,
							},
						],
						yAxes: [
							{
								display: true,
								categoryPercentage: 0.8,
								barPercentage: 0.5,
								position: 'right',
								gridLines: {
									display: false,
									drawBorder: false,
								},
								ticks: {
									maintainAspectRatio: true,
								},
								stacked: true,
							},
						],
					},
					legend: {
						display: false,
					},
				},
			);
		});
	},
};
