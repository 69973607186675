<template>
	<c-user-information :type="type">
		<template v-slot:avatar>
			<c-avatar v-if="isQuestioner || isDormancy" type="profile" :size="avatarSize" />
			<CaseProfileAvatar
				v-else
				:user-no="avatarUserNo"
				:user-nickname="computedNickname"
				:size="avatarSize"
				:user-profile-image="userProfileImage"
			/>
		</template>
		<template v-slot:name>
			<c-user-information-name :type="type">
				{{ avatarNickname }}
			</c-user-information-name>
		</template>
		<template v-slot:user-info>
			<c-user-information-company v-if="isMentorAndNotQuestioner" :type="type">
				{{ company }}
			</c-user-information-company>
		</template>
		<template v-slot:other>
			<c-user-information-caption class="flex flex-row align-center">
				{{ gradeName }} <template v-if="choiceRate !== 999">∙ 채택률 {{ choiceRate }}%</template>
				<template v-if="hasMatchedItem">
					∙
					<c-chip-group size="small" class="mx-4">
						<template v-for="(value, key, index) in matching">
							<c-chip v-if="value" :key="`chip${index}`" type="outline" color="primary" size="small">
								{{ getMatchedLabel(key) }}
							</c-chip>
						</template>
					</c-chip-group>
					일치
				</template>
			</c-user-information-caption>
		</template>
	</c-user-information>
</template>

<script>
import CaseProfileAvatar from '../atoms/CaseProfileAvatar.vue';

export default {
	name: 'CommonUserInformation',
	props: {
		type: {
			type: String,
			default: 'normal',
		},
		nickname: {
			type: String,
		},
		company: {
			type: String,
		},
		gradeName: {
			type: String,
		},
		choiceRate: {
			type: Number,
		},
		avatarUserNo: {
			type: Number,
		},
		avatarUserType: {
			type: Number,
		},
		matching: {
			type: Object,
			default() {
				return {
					company: false,
					industry: false,
					job: false,
					univ: false,
				};
			},
		},
		isQuestioner: {
			type: Boolean,
			default: false,
		},
		isDormancy: {
			type: Boolean,
			default: false,
		},
		userProfileImage: {
			type: String,
		},
	},
	data() {
		return {
			matchedItems: [
				{
					value: 'company',
					label: '회사',
				},
				{
					value: 'industry',
					label: '산업',
				},
				{
					value: 'job',
					label: '직무',
				},
				{
					value: 'univ',
					label: '학교',
				},
			],
		};
	},
	computed: {
		computedNickname() {
			return this.nickname || this.defaultNickname;
		},
		defaultNickname() {
			return `${this.avatarUserType === 1 ? '멘티' : '멘토'}${this.avatarUserNo}`;
		},
		avatarNickname() {
			if (this.isQuestioner) {
				return '질문자';
			} else if (this.isDormancy) {
				return '휴면계정';
			}
			return this.computedNickname;
		},
		isMentor() {
			return this.avatarUserType === 2;
		},
		hasMatchedItem() {
			return Object.values(this.matching).includes(true);
		},
		isMentorAndNotQuestioner() {
			return !this.isQuestioner && this.isMentor;
		},
		avatarSize() {
			const sizes = {
				full: 'medium',
				normal: 'small',
				simple: 'xsmall',
			};
			return sizes[this.type];
		},
	},
	methods: {
		getMatchedLabel(key) {
			return this.matchedItems.find(matchedItem => matchedItem.value === key).label;
		},
	},
	components: { CaseProfileAvatar },
};
</script>

<style lang="scss" scoped>
.c-user-information::v-deep {
	.information-wrapper {
		.name-company-wrapper {
			overflow: inherit !important;
		}
	}
}
</style>
