<template>
	<i
		class="avatar"
		:class="[computedType, computedSize]"
		:style="{ 'background-color': avatarColor, backgroundImage: 'url(' + avatarSrc + ')' }"
	>
		{{ avatarText }}
	</i>
</template>

<script>
export default {
	name: 'Avatar',
	props: {
		//user, img
		avatarType: {
			type: String,
			default: 'user',
		},
		//사이즈
		avatarSize: {
			type: String,
			default: 'medium',
		},
		//type : user일 경우 색상
		avatarColor: {
			type: String,
			default: null,
		},
		//type : user일 경우 문구
		avatarText: {
			type: String,
			default: null,
		},
		//type : img 일 경우 이미지
		avatarSrc: {
			type: String,
			default: null,
		},
	},
	data: () => ({}),
	computed: {
		computedType() {
			return `avatar_type_${this.avatarType}`;
		},
		computedSize() {
			return `avatar_${this.avatarSize}`;
		},
	},
};
</script>

<style scoped lang="less">
@import '../../../../less/base/index';
.avatar {
	display: inline-block;
	font-style: normal;
	.border-radius(100%);
	text-align: center;
	/*사이즈*/
	&_medium {
		width: 40px;
		height: 40px;
		line-height: 40px;
		margin-right: 6px;
	}
	&_large {
		width: 48px;
		height: 48px;
		line-height: 48px;
		margin-right: 8px;
	}
	&_xlarge {
		width: 68px;
		height: 68px;
		line-height: 68px;
	}
	&_type_user {
		border: 1px solid @bg-btn-color-gray;
	}
	&_type_img {
		background-size: contain;
	}
}
</style>
