//이력서 튜토리얼용 라우터
import Vue from 'vue';
import VueRouter from 'vue-router';
import layoutStore from '../store/modules/layoutStore';
import appCommandMixin from '../mixins/appCommandMixin';
import common from '../mixins/common';

Vue.use(VueRouter);

import ResumeIndexView from '../views/resume/resumeTutorial/ResumeIndexView.vue';
import ResumeIntroView from '../views/resume/resumeTutorial/ResumeIntroView.vue';
import ResumeBuilderIndexView from '../views/resume/resumeTutorial/ResumeBuilderIndexView.vue';
import ResumeBuilderView from '../views/resume/resumeTutorial/ResumeBuilderView.vue';

import EssayTutorialIndexView from '../views/resume/essayTutorial/EssayTutorialIndexView.vue';
import EssayTutorialIntroView from '../views/resume/essayTutorial/EssayTutorialIntroView.vue';
import EssayTutorialBuilderIndexView from '../views/resume/essayTutorial/EssayTutorialBuilderIndexView.vue';
import EssayTutorialBuilderView from '../views/resume/essayTutorial/EssayTutorialBuilderView.vue';

// import AnswerTutorialBuilderView from '../views/answer/tutorial/AnswerTutorialBuilderView.vue.bak';
// import AnswerListView from '../views/answer/AnswerListView.vue.bak';
import AnswerDetailView from '../views/answer/detail/AnswerDetailView.vue';

import BridgeMatchView from '../views/bridge/BridgeMatchView.vue';

import BlogIndexView from '../views/blog/BlogIndexView.vue';
import BlogListView from '../views/blog/BlogListView.vue';
import BlogCreateView from '../views/blog/BlogCreateView.vue';
import BlogDetailView from '../views/blog/BlogDetailView.vue';

import CouponIndexView from '../views/coupon/CouponIndexView.vue';

const router = new VueRouter({
	mode: 'history',
	scrollBehavior(_to, _from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			// 라우트 이동될 때마다 스크롤 맨위로 이동
			return { x: 0, y: 0 };
		}
	},
	routes: [
		{
			path: '/resume/tutorial/',
			component: ResumeIndexView,
			meta: {
				title: '이력서 튜토리얼',
				metaTags: [
					{
						name: 'description',
						content:
							'누구나 직무에 적합한 이력서를 작성할 수 있도록 전문가 1:1 가이드에 준하는 완성도 높은 이력서 튜토리얼 및 이력서 양식을 제공합니다.',
					},
					{
						property: 'meta-keyword',
						content:
							'대기업, 공기업, 은행, 금융권, 외국계, 이력서, 이력서양식, 이력서 양식, 튜토리얼, 대졸, 중소기업, 스타트업, 맞춤 이력서',
					},
				],
			},
		},
		{
			path: '/resume/tutorial/:no',
			component: ResumeIntroView,
			meta: {
				title: '이력서 튜토리얼',
				metaTags: [
					{
						name: 'description',
						content:
							'누구나 직무에 적합한 이력서를 작성할 수 있도록 전문가 1:1 가이드에 준하는 완성도 높은 이력서 튜토리얼 및 이력서 양식을 제공합니다.',
					},
					{
						property: 'meta-keyword',
						content:
							'대기업, 공기업, 은행, 금융권, 외국계, 이력서, 이력서양식, 이력서 양식, 튜토리얼, 대졸, 중소기업, 스타트업, 맞춤 이력서',
					},
				],
			},
		},
		{
			path: '/resume/builder',
			component: ResumeBuilderIndexView,
			meta: { title: '이력서 튜토리얼' },
		},
		{
			path: '/resume/builder/tutorial/:no',
			component: ResumeBuilderView,
			name: 'resume-tutorial-builder',
			meta: { title: '이력서 튜토리얼' },
		},
		{
			path: '/essay/tutorial/',
			component: EssayTutorialIndexView,
			meta: {
				title: '자기소개서 튜토리얼',
				metaTags: [
					{
						name: 'description',
						content:
							'누구나 직무에 적합한 이력서를 작성할 수 있도록 전문가 1:1 가이드에 준하는 완성도 높은 이력서 튜토리얼 및 이력서 양식을 제공합니다.',
					},
					{
						property: 'meta-keyword',
						content:
							'대기업, 공기업, 은행, 금융권, 외국계, 이력서, 이력서양식, 이력서 양식, 튜토리얼, 대졸, 중소기업, 스타트업, 맞춤 이력서',
					},
				],
			},
		},
		{
			path: '/essay/tutorial/:no',
			component: EssayTutorialIntroView,
			meta: {
				title: '자기소개서 튜토리얼',
				metaTags: [
					{
						name: 'description',
						content:
							'누구나 직무에 적합한 이력서를 작성할 수 있도록 전문가 1:1 가이드에 준하는 완성도 높은 이력서 튜토리얼 및 이력서 양식을 제공합니다.',
					},
					{
						property: 'meta-keyword',
						content:
							'대기업, 공기업, 은행, 금융권, 외국계, 이력서, 이력서양식, 이력서 양식, 튜토리얼, 대졸, 중소기업, 스타트업, 맞춤 이력서',
					},
				],
			},
		},
		{
			path: '/essay/builder',
			component: EssayTutorialBuilderIndexView,
			meta: { title: '자기소개서 튜토리얼' },
		},
		{
			path: '/essay/builder/tutorial/:no',
			component: EssayTutorialBuilderView,
			name: 'essay-tutorial-builder',
			meta: { title: '자기소개서 튜토리얼' },
		},
		// {
		// 	path: '/answer/tutorial/:no',
		// 	component: AnswerTutorialBuilderView,
		// 	name: 'answer-tutorial',
		// 	meta: { title: '현직자 튜토리얼 | 코멘토' },
		// },
		// {
		// 	path: '/answer',
		// 	component: AnswerListView,
		// 	name: 'answer',
		// 	meta: { title: '멘토링 | 코멘토' },
		// },
		// {
		// 	path: '/answer/search/:keyword',
		// 	component: AnswerListView,
		// 	name: 'answer-search',
		// 	meta: { title: '멘토링 | 코멘토' },
		// },
		{
			path: '/answer/:type',
			component: AnswerDetailView,
			name: 'answer-detail',
			meta: { title: '멘토링 | 코멘토' },
		},
		{
			path: '/invite/match/:matchCode',
			component: BridgeMatchView,
			meta: {
				title: '로딩중 | 코멘토',
			},
		},
		{
			path: '/blog',
			component: BlogIndexView,
			name: 'blog-index',
			meta: {
				title: '코멘터리 | 코멘토',
			},
		},
		{
			path: '/blog/list/:magazine/:category?',
			name: 'blog-list',
			component: BlogListView,
			meta: {
				title: '코멘터리 | 코멘토',
			},
		},
		{
			path: '/blog/write/:postNo?',
			component: BlogCreateView,
			meta: {
				title: '코멘터리 | 코멘토',
			},
		},
		{
			path: '/blog/view/:magazine/:category-:postNo',
			component: BlogDetailView,
			meta: {
				title: '코멘터리 | 코멘토',
			},
		},
		{
			path: '/coupon/:category',
			component: CouponIndexView,
			name: 'coupon',
		},
	],
});

router.afterEach(to => {
	Vue.nextTick(() => {
		setTimeout(() => {
			if (common.computed.isApp()) {
				appCommandMixin.methods.$_app_setMeta({
					path: to.fullPath,
					isDetail: layoutStore.getters.isDetail(layoutStore.state),
					bgColor: layoutStore.getters.bgColor(layoutStore.state),
					title: layoutStore.getters.headerTitle(layoutStore.state),
					backwardButtonMarginBottom: layoutStore.getters.backwardButtonMarginBottom(layoutStore.state),
					isLogoTopBar: layoutStore.getters.isLogoTopBar(layoutStore.state),
					// 기존 meta isShowSubHeader를 다음 조건식으로 변경
					isShowSubHeader: !(
						layoutStore.getters.isDetail(layoutStore.state) ||
						layoutStore.getters.isLogoTopBar(layoutStore.state)
					),
					isShowFooter: layoutStore.getters.showFooter(layoutStore.state),
					isShowHeader: layoutStore.getters.showHeader(layoutStore.state),
				});
			}
		}, 500);
	});
});

export default router;
