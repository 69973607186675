<template>
	<c-toast
		:show.sync="showToast"
		:type="type"
		:position="position"
		:timeout="timeout"
		:icon="icon"
		:message="message"
		class="toast-template"
		:class="{ 'has-click-event': hasClickEvent }"
		@click="toastClickEvent"
	>
		<template v-if="hasClickEvent" v-slot:button>
			<c-typography type="body2" :font-weight="500" color="blue400" class="c-pointer">
				적용 클래스 보기
			</c-typography>
		</template>
	</c-toast>
</template>

<script>
export default {
	data() {
		return {
			showToast: false,
			message: '',
			type: 'basic',
			position: 'bottom',
			timeout: 3000,
			icon: null,
			showIcon: false,
			clickEvent: null,
		};
	},
	computed: {
		hasClickEvent() {
			return !this.isEmptyData(this.clickEvent);
		},
	},
	methods: {
		/**
		 * @param message: Toast 내용
		 * @param type: Toast type (basic, error, success)
		 * @param position: 위치 (top, bottom)
		 * @param timeout: 시간
		 * @param icon: 아이콘 이름
		 * @param clickEvent: 클릭 이벤트
		 **/
		show(message, type, position, timeout, icon, clickEvent) {
			this.message = message;
			this.type = type;
			this.position = position;
			this.timeout = timeout;
			if (icon !== null) {
				this.icon = icon;
				this.showIcon = true;
			}
			if (clickEvent !== null) {
				this.clickEvent = clickEvent;
			}
			this.showToast = true;
		},
		toastClickEvent() {
			if (this.clickEvent !== null) this.clickEvent();
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.toast-template::v-deep {
	&.has-click-event {
		cursor: pointer;
		@include flexbox();
		@include justify-content(space-between);

		@include mobile {
			width: 100%;
		}
		@include pc {
			width: 343px;
		}
	}
}
</style>
