<template>
	<section>
		<BlogHeader v-if="!isCbcPromotionLandingPost" :is-detail="true" />
		<section class="section section-editor">
			<div class="container blog-container">
				<div class="blog-container">
					<div v-if="isComentoUser()" class="update-btn-wrapper">
						<router-link class="btn btn-sm btn-empty mr-5" :to="`/blog/write/${blogDetail.no}`" tag="a">
							수정
						</router-link>
						<button class="btn btn-sm btn-empty" @click="deletePost()">삭제</button>
					</div>
					<div v-if="isFetching" class="flex flex-column align-center">
						<base-skeleton width="130px" height="26px" border-radius="50px" class="mb-38" />
						<base-skeleton
							:width="isMobile ? '70%' : '20%'"
							:height="isMobile ? '25px' : '38px'"
							class="mb-8"
						/>
						<base-skeleton
							:width="isMobile ? '80%' : '40%'"
							:height="isMobile ? '24px' : '38px'"
							:class="isMobile ? 'mb-8' : 'mb-14'"
						/>
						<base-skeleton v-if="isMobile" width="100%" height="25px" class="mb-14" />
						<base-skeleton :width="isMobile ? '80%' : '300px'" height="25px" />
						<c-divider class="mt-64 mb-60" style="width: 100%" />
						<base-skeleton width="100%" height="400px" class="mb-60" />
					</div>
					<template v-else>
						<div class="text-center">
							<span v-if="!isCbcPromotionLandingPost" class="badge blog-category">
								{{ blogCategory }}
							</span>
							<h2 class="blog-title mb-10" v-html="$options.filters.nl2br(blogDetail.title)" />
							<p class="blog-sub-title" v-html="$options.filters.nl2br(blogDetail.sub_title)" />
							<hr class="my-60" />
						</div>
						<section class="blog-content-wrapper ql-snow">
							<CbcPromotionLandingPost v-if="isCbcPromotionLandingPost" />
							<div
								v-else
								class="trumbowyg-editor-group ql-editor"
								v-html="$options.filters.nl2br(blogDetail.contents)"
							/>
						</section>
						<section class="blog-hashtag-wrapper">
							<ul>
								<li
									v-for="(hashtag, index) in blogDetail.blog_hash_tag"
									:key="`hash-tag-${index}`"
									class="badge blog-hashtag"
								>
									# {{ hashtag.hash_tag.tag }}
								</li>
							</ul>
						</section>
					</template>
				</div>
			</div>
		</section>
		<BlogFooter />
	</section>
</template>

<script>
import BlogHeader from '../../components/blog/HeaderComponent.vue';
import BlogFooter from '../../components/blog/FooterComponent.vue';
import { mapMutations } from 'vuex';
import CbcPromotionLandingPost from '../../components/blog/CbcPromotionLandingPost.vue';

export default {
	data() {
		return {
			blogDetail: {},
			blogCategory: '',
			isFetching: true,
		};
	},
	computed: {
		isCbcPromotionLandingPost() {
			return this.$route.params.postNo === '134';
		},
	},
	async created() {
		await this.getBlogDetail();
	},
	mounted() {
		this.setMeta({
			isShowFooter: false,
			isShowHeader: false,
			isDetail: true,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		getBlogDetail() {
			axios({
				method: 'GET',
				url: '/api/blog/read',
				params: {
					post_no: this.$route.params.postNo,
				},
			})
				.then(response => {
					// console.log(response);
					const data = response.data;

					if (data.code === 200) {
						this.blogDetail = data.post;
						this.blogCategory = data.post.blog_category.name;
						this.isFetching = false;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		deletePost() {
			const deleteConfirm = confirm('정말 삭제하실 건가요?');
			if (deleteConfirm) {
				showLoading();
				axios({
					method: 'DELETE',
					url: '/api/blog/delete',
					params: {
						post_no: this.$route.params.postNo,
					},
				})
					.then(response => {
						// console.log(response);
						const data = response.data;
						if (data.code === 200) {
							closeLoading();
							this.$router.replace('/blog');
							alert('삭제 완료');
						} else {
							alert('[삭제 오류] 성실님께 문의하세요.');
							closeLoading();
						}
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		isComentoUser() {
			return (
				this.$store.state.sessionInfo !== null &&
				this.$store.state.sessionInfo.id.split('@')[1] === 'comento.kr'
			);
		},
	},
	components: {
		CbcPromotionLandingPost,
		BlogHeader,
		BlogFooter,
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.update-btn-wrapper {
	position: absolute;
	right: 15px;
	top: -50px;
}

.section-editor {
	@include mobile {
		padding-top: 70px;
	}
}
</style>
