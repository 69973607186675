// pt, onair, vod 관련 store

// getters

export const CLASS_INFO = 'CLASS_INFO';
export const IS_B2B_CLASS = 'IS_B2B_CLASS';
// mutations
export const SET_CLASS_INFO = 'SET_CLASS_INFO';

export const FETCH_CLASS_INFO = 'FETCH_CLASS_INFO';
// actions
const classStore = {
	namespaced: true,
	state: {
		[CLASS_INFO]: null,
	},
	getters: {
		[CLASS_INFO]: state => {
			return state[CLASS_INFO];
		},
		[IS_B2B_CLASS]: (_, getters) => getters[CLASS_INFO].type === 'B',
	},
	mutations: {
		[SET_CLASS_INFO](state, payload) {
			state[CLASS_INFO] = payload;
		},
	},
	actions: {
		async [FETCH_CLASS_INFO]({ commit }, data) {
			try {
				const response = await axios.post(`/api/class/pt/detail/info`, data);
				commit(SET_CLASS_INFO, response.data);
			} catch (error) {
				console.error(error);
			}
		},
	},
};

export default classStore;
