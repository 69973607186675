<template>
	<!--contents-overline-->
	<dl class="contents_overline_component" :class="[computedAlign]">
		<!--dt 내용은 안보입니다.-->
		<dt>{{ overlineDefinition }}</dt>
		<dd v-html="$options.filters.nl2br(contentsOverline)"></dd>
	</dl>
</template>

<script>
export default {
	name: 'ContentsOverline',
	props: {
		overlineDefinition: {
			type: String,
			default: 'definition(설명,내용)',
		},
		//contents overline 내용
		contentsOverline: {
			type: String,
			default: null,
		},
		//좌, 우 방향 설정
		overlineAlign: {
			type: String,
			default: 'none',
		},
	},
	computed: {
		computedAlign() {
			return `contents_overline_align_${this.overlineAlign}`;
		},
	},
};
</script>
<style scoped lang="less">
@import '../../../../../less/base/index';
.contents_overline_component {
	position: relative;
	margin-bottom: 8px;
	&.contents_overline_align_left {
		float: left;
	}
	&.contents_overline_align_right {
		float: right;
	}
	&.contents_overline_align_none {
		float: none;
	}
	dt {
		display: none;
	}
}
</style>
