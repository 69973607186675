import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import _object from 'lodash/object';
import layoutStore from './modules/layoutStore';
import authStore from './modules/authStore';
import paymentStore from './modules/paymentStore';
import caseStore from './modules/caseStore';
import classStore from './modules/classStore';
import pullToRefreshStore from './modules/pullToRefreshStore';
import appContextStore from './modules/appContextStore';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		layoutStore: layoutStore,
		authStore: authStore,
		paymentStore: paymentStore,
		caseStore: caseStore,
		classStore: classStore,
		pullToRefreshStore: pullToRefreshStore,
		appContextStore: appContextStore,
	},
	state: {
		searchOption: '통합', //검색조건
		keyword: '', //통합검색 키워드
		popularKeyword: [], //인기검색어
		userInfo: {},
		productName: '', //제휴대학
		buyMentoring: '', //이용권 정보
		passInfo: {}, // 제휴 정보
		page: 0,
		items: [],
		case: {
			//멘토링 사례
			requestNo: '',
			selectedFilter: '',
		},
		answer: {
			//답변하기
			requestNo: '',
			type: '',
			// selectedFilter: '',
			sort: '',
			filterType: [1, 1, 1],
			filterView: '',
			mentoringType: '',
		},
		sessionInfo: null,
		sessionInfoExist: false,
		userProfileImage: null,
		gqlToken: null,
		constants: {},
	},
	mutations: {
		putProductName(state, data) {
			//제휴대학
			state.productName = data;
		},
		putPopularKeyword(state, data) {
			state.popularKeyword = data;
		},
		setSessionInfo(state, data) {
			state.sessionInfo = data;
		},
		setKeyword(state, data) {
			state.keyword = data;
		},
		setSessionInfoExist(state, data) {
			state.sessionInfoExist = data;
		},
		setUserProfileImage(state, data) {
			state.userProfileImage = data;
		},
		setBuyMentoring(state, data) {
			state.buyMentoring = data;
		},
		setPassInfo(state, data) {
			state.passInfo = data;
		},
		setConstants(state, data) {
			state.constants = data;
		},
	},
	getters: {
		sessionInfo: state => {
			return state.sessionInfo;
		},
		sessionInfoExist: state => {
			return state.sessionInfoExist;
		},
		keyword: state => {
			return state.keyword;
		},
		buyMentoring: state => {
			return state.buyMentoring;
		},
		passInfo: state => {
			return state.passInfo;
		},
		isUser: (_, getters) => {
			return getters.userType > 0;
		},
		userNo: (state, getters) => {
			return _object.get(getters, ['sessionInfo', 'no'], -1);
		},
		userId: (state, getters) => {
			return _object.get(getters, ['sessionInfo', 'id'], '');
		},
		userType: (state, getters) => {
			return _object.get(getters, ['sessionInfo', 'type'], -1);
		},
		userNickname: (state, getters) => {
			if (getters.isUser) {
				const defaultNickname = `${getters.userType === 1 ? '멘티' : '멘토'}${getters.userNo}`;
				return (getters.sessionInfo && state.sessionInfo.nickname) || defaultNickname;
			}
			return null;
		},
		userProfileImage: state => {
			return state.userProfileImage;
		},
		isKPC: (_, getters) => {
			return getters.sessionInfo?.api_partner_no === 151;
		},
		constants: state => state.constants,
	},
	actions: {
		async getSessionInfo({ commit }) {
			try {
				const { data } = await axios.get('/api/user/jwt/me');
				commit('setSessionInfo', data);
			} catch (error) {
				if (error.response?.status === 403) {
					return;
				}
				console.error(error);
			} finally {
				commit('setSessionInfoExist', true);
			}
		},
		async getUserProfileImage({ commit }) {
			try {
				const { data } = await axios.get('/api/mypage/getProfile');
				if (data.msg !== 'NoData' && data.profile_img) {
					commit('setUserProfileImage', data.profile_url);
				}
			} catch (error) {
				console.log(error);
			}
		},
		async fetchConstants({ commit }) {
			try {
				const { data } = await axios.get(
					'https://dd2kkximjkla27g2hyukunqmyy0flidf.lambda-url.ap-northeast-2.on.aws/',
				);
				commit('setConstants', Object.freeze(JSON.parse(data.message)));
			} catch (error) {
				console.error(error);
			}
		},
	},
});
