<template>
	<div class="resume-navigator">
		<div class="line" />
		<ul>
			<li v-for="item in 7" :key="`navigator-item-${item}`" :class="circleClass(item)">
				<div class="circle" />
				<p v-html="navigatorTitle(item)" />
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'ResumeTutorialNavigator',
	props: {
		step: {
			type: Number,
			default: 1,
		},
		descriptionStep: {
			type: Boolean,
			default: false,
		},
		finalStep: {
			type: Number,
			default: 1,
		},
		isCompletion: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		sync_step: {
			get() {
				return this.step;
			},
			set(val) {
				this.$emit('update:step', val);
			},
		},
		sync_descriptionStep: {
			get() {
				return this.descriptionStep;
			},
			set(val) {
				this.$emit('update:descriptionStep', val);
			},
		},
	},
	created() {
		this.sync_step = parseInt(this.$route.params.no);
	},
	methods: {
		circleClass(no) {
			if (no === parseInt(this.step)) {
				if (no === 7 && this.isCompletion) {
					return 'circle-check-big';
				}
				return 'circle-outline-big';
			} else if (no <= parseInt(this.finalStep)) {
				return 'circle-check';
			} else {
				return 'circle-disabled';
			}
		},
		navigatorTitle(no) {
			const title = {
				1: '직무 입력',
				2: '직무 이해',
				3: '우수자 역량',
				4: '내 강점',
				5: '강점 정의',
				6: `에피소드${this.step !== 6 ? '<br />' : ' '}입력`,
				// 7: `에피소드${this.step !== 7 ? '<br />' : ' '}분류`,
				7: '자가진단',
			};

			return title[no];
		},
		// goStep(step) {
		//     if(step > this.finalStep) {
		//         return false;
		//     } else {
		//         this.$router.push({ params: { no: step } });
		//         this.sync_step = step;
		//         this.sync_descriptionStep = false; //내비게이터로 이동할 때는 설명페이지 생략
		//     }
		// }
	},
};
</script>
