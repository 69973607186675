const comentoLocalStorageIdMixin = {
	data: () => ({
		comentoLocalStorageId: null,
	}),
	created() {
		this.$_handleComentoLocalStorageId();
	},
	methods: {
		$_randomTokenGenerator(length) {
			return [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
		},
		$_handleComentoLocalStorageId() {
			const eduLocalStorageId = this.$_getLocalStorage('edu_local_storage_id');
			const comentoLocalStorageId = this.$_getLocalStorage('comento_local_storage_id');

			if (eduLocalStorageId) {
				this.$_setLocalStorage('comento_local_storage_id', eduLocalStorageId);
				this.comentoLocalStorageId = eduLocalStorageId;
				this.$_removeLocalStorage('edu_local_storage_id');
			} else if (comentoLocalStorageId) {
				this.comentoLocalStorageId = comentoLocalStorageId;
			} else {
				this.comentoLocalStorageId = this.$_randomTokenGenerator(14); //랜덤 토큰 생성
				this.$_setLocalStorage('comento_local_storage_id', this.comentoLocalStorageId);
			}
		},
	},
};

export default comentoLocalStorageIdMixin;
