<template>
	<div>
		<biz-side-component num="1" />
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<biz-company-header-component
						main="기업정보 미리보기"
						sub1="기업정보 등록이 완료되었습니다. 채용정보를 입력하면 본 기업정보와 결합하여 채용공고로 완성되며, 기업정보는 여러 개의 채용공고에 공통으로 적용됩니다."
						sub2="* 아래 수정하기 버튼을 통해 언제든지 수정할 수 있습니다."
					/>
					<div class="text-center">
						<carousel
							v-if="comInfo.img_back_size > 1"
							:per-page="1"
							:navigation-prev-label="' '"
							:navigation-next-label="' '"
							:scroll-per-page="false"
							:paginationEnabled="false"
							:navigationEnabled="true"
							:loop="true"
							:autoplayLoop="true"
							:autoplay="true"
							:autoplayTimeout="3000"
							:autoplayHoverPause="true"
						>
							<slide v-for="(list, index) in comInfo.img_back" :key="index">
								<img :src="comInfo.img_base_path + list" class="title_img com-images" />
							</slide>
						</carousel>
						<img
							v-else-if="comInfo.img_back_size === 1"
							:src="comInfo.img_base_path + comInfo.img_back"
							class="title_img com-images bg-white"
						/>
						<img v-else src="/images/noimage_large.svg" class="title_img com-images bg-white" />
					</div>
					<div class="bg-white border mt-20">
						<div class="border-bottom pd-30">
							<div class="inline-block mr-10">
								<img
									v-if="comInfo.img_logo"
									class="com-logo"
									:src="comInfo.img_base_path + comInfo.img_logo"
								/>
								<img v-else class="com-logo" src="/images/noimage.svg" />
							</div>
							<div class="inline-block">
								<a class="font-title f-semi-bold" :href="comInfo.url">{{ comInfo.name }}</a
								><br />
								<!-- <img class="com-icon-small mr-10" src="/images/detail_home.svg" /><span
									class="font f-light"
								>{{ comInfo.addr }}</span
								> -->
							</div>
						</div>
						<div class="pd-30">
							<div class="font-title f-semi-bold">{{ comInfo.hookpoint }}</div>
							<div class="font pt-20" v-html="$options.filters.nl2br(comInfo.about)"></div>
						</div>
					</div>

					<div class="bg-white border pd-30 mt-20">
						<div class="border-bottom h4 f-brand pb-20">기업 소개</div>
						<div>
							<div class="border-bottom pt-20 pb-20">
								<div class="font-title f-semi-bold">성장 정보</div>
								<p class="font pt-20" v-html="$options.filters.nl2br(comInfo.growth_info)"></p>
							</div>
							<div v-show="show.corporateCulture" class="border-bottom pt-20 pb-20">
								<div class="font-title f-semi-bold">조직 문화</div>
								<p class="font pt-20" v-html="$options.filters.nl2br(comInfo.corporate_culture)"></p>
							</div>
							<div class="border-bottom pt-20 pb-20">
								<div class="font-title f-semi-bold">상세 정보</div>
								<p class="font pt-20" v-html="$options.filters.nl2br(comInfo.detail)"></p>
							</div>
							<div class="pt-20 pb-20">
								<div class="pb-20">
									<div class="mb-20">
										<img class="com-icon" src="/images/view-company-info.svg" />
										<strong>기업정보 요약</strong>
									</div>
									<div v-if="comInfo.com_type" class="inline-block com-box font">
										{{ comInfo.com_type }}
									</div>
									<div v-if="comInfo.addr" class="inline-block com-box font">{{ comInfo.addr }}</div>
									<div v-if="comInfo.employee_num" class="inline-block com-box font">
										{{ comInfo.employee_num }}
									</div>
								</div>
								<div>
									<div class="mb-20">
										<img class="com-icon" src="/images/view-salary-info.svg" />
										<strong>임금 및 복리후생</strong>
									</div>
									<div
										v-for="(list, index) in time"
										v-if="list !== ''"
										class="inline-block com-box font"
									>
										<span v-if="index === 0" class="f-semi-bold">근무시간 </span>{{ list }}
									</div>
									<div v-for="list in ins" v-if="list !== ''" class="inline-block com-box font">
										{{ list }}
									</div>
									<div v-for="list in holiday" v-if="list !== ''" class="inline-block com-box font">
										{{ list }}
									</div>
									<div v-for="list in meal" v-if="list !== ''" class="inline-block com-box font">
										{{ list }}
									</div>
									<div v-for="list in trans" v-if="list !== ''" class="inline-block com-box font">
										{{ list }}
									</div>
									<div v-for="list in edu" v-if="list !== ''" class="inline-block com-box font">
										{{ list }}
									</div>
									<div v-for="list in equip" v-if="list !== ''" class="inline-block com-box font">
										{{ list }}
									</div>
									<div v-for="list in welfare" v-if="list !== ''" class="inline-block com-box font">
										{{ list }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-right mt-20">
						<button class="btn btn-close cursor-pointer mr-10" onclick="location.href='/company/info/1'">
							수정하기
						</button>
						<button class="btn cursor-pointer" onclick="location.href='/company/recruit/1'">
							채용공고작성
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
	props: ['comInfo', 'pmCompanyUserType'],
	data() {
		return {
			time: [],
			ins: [],
			holiday: [],
			meal: [],
			trans: [],
			edu: [],
			equip: [],
			welfare: [],
			show: {
				corporateCulture: true,
			},
		};
	},
	mounted() {
		if (this.comInfo) {
			this.time = this.comInfo.officehour;
			this.ins = this.comInfo.ins;
			this.holiday = this.comInfo.holiday;
			this.meal = this.comInfo.meal;
			this.trans = this.comInfo.trans;
			this.edu = this.comInfo.edu;
			this.equip = this.comInfo.equip;
			this.welfare = this.comInfo.welfare;

			for (let i = 0; i < this.comInfo.officehour.length; i++) {
				if (this.time[i] === '기타') {
					this.time.splice(i, 1);
				}
			}
			for (let i = 0; i < this.comInfo.ins.length; i++) {
				if (this.ins[i] === '기타') {
					this.ins.splice(i, 1);
				}
			}
			for (let i = 0; i < this.comInfo.holiday.length; i++) {
				if (this.holiday[i] === '기타') {
					this.holiday.splice(i, 1);
				}
			}
			for (let i = 0; i < this.comInfo.meal.length; i++) {
				if (this.meal[i] === '기타') {
					this.meal.splice(i, 1);
				}
			}
			for (let i = 0; i < this.comInfo.trans.length; i++) {
				if (this.trans[i] === '기타') {
					this.trans.splice(i, 1);
				}
			}
		}

		// 열린 채용은 조직문화가 보이지 않는다.
		if (this.pmCompanyUserType === 8) {
			this.show.corporateCulture = false;
		}
	},
	components: {
		Carousel,
		Slide,
	},
};
</script>
<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
