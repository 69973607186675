<template>
	<c-new-col v-if="isLoaded" :col-sm="4" :col-lg="12" class="answer-detail-question-container" :no-gutters="isMobile">
		<GridLayout grid-color="white" :grid-fluid="isMobile">
			<c-new-col :col-lg="colLg" :offset-lg="offsetLg">
				<c-box :paddings="boxPaddings">
					<AnswerDetailQuestionSection :question-info="questionInfo" />
				</c-box>
			</c-new-col>
		</GridLayout>

		<GridLayout
			grid-fluid
			row-gutters
			:grid-color="isMobile ? 'gray100' : 'gray000'"
			style="min-height: 50vh"
			class="pb-100 answer-detail-question-feedback"
		>
			<GridLayout :grid-fluid="isMobile">
				<c-new-col :col-lg="colLg" :offset-lg="offsetLg" :class="{ 'pt-32': !isMobile }">
					<template v-if="!isMobile">
						<AnswerTipBox
							v-if="!isMobile"
							:show="isShowAnswerTip"
							:title="answerTipTitle"
							:item="answerTip"
							border-radius="2px"
							class="mb-14"
							@close="isShowAnswerTip = false"
						/>
					</template>
					<MineFeedbackQuestion
						:no="no"
						:user-type="userType"
						:user-no="userNo"
						:user-email="email"
						:parent-user-no="parentUserNo"
						:user-id="userId"
						:user-level="userLevel"
						:info-display="infoDisplay"
						:user-belong="userBelong"
						request="qa"
						display="feedback"
						:question="questionInfo"
						:answer-tip-info="{ title: answerTipTitle, item: answerTip }"
						:class="{ 'mt-32': isMobile }"
						:is-re-pull="isRePull"
						:answer-correspondence="answerCorrespondence"
						@hide-answer-tip="hideAnswerTip"
						@submit-question-feedback="handleSubmitQuestionFeedback"
					/>
				</c-new-col>
			</GridLayout>
		</GridLayout>

		<!--		<LinkShareModal-->
		<!--			:show="showLinkShareModal"-->
		<!--			:sid="sid"-->
		<!--			:menu="'answer'"-->
		<!--			:inviteCode="inviteCode"-->
		<!--			:requestNo="no"-->
		<!--			@close="showLinkShareModal = false"-->
		<!--		/>-->

		<AnswerDetailThanksModal
			:show.sync="answerShowThanksModal"
			@success-callback="$_goRequestList"
			@close="$_closeThanksModal"
		/>
	</c-new-col>
</template>

<script>
import { mapMutations } from 'vuex';
import GridLayout from '../../../components/common/GridLayout.vue';
import AnswerDetailQuestionSection from '../../../components/answer/AnswerDetailQuestionSection.vue';
import AnswerTipBox from '../../../components/answer/AnswerTipBox.vue';
import MineFeedbackQuestion from '../../../components/mine/MineFeedbackQuestion.vue';

import answerMixin from '@/mixins/answerMixin';
import AnswerDetailThanksModal from '@/components/answer/AnswerDetailThanksModal.vue';

export default {
	mixins: [answerMixin],
	props: ['no', 'type', 'userNo', 'email', 'userType', 'userBelong', 'userId', 'userLevel', 'sid'],
	data() {
		return {
			questionInfo: [],
			feedbackCount: 0,
			// showLinkShareModal: this.showLinkShareModal,
			parentUserNo: '',
			infoDisplay: 0,
			colLg: 8,
			offsetLg: 2,
			isLoaded: false,
			isShowAnswerTip: true,
			answerTipTitle: '채택률이 올라가는 답변 작성 Tip!',
			answerTip: [
				'멘토님만의 경험과 지식, 의견을 담아주세요.',
				'답변의 이유와 배경, 맥락을 함께 알려주시면 더욱 좋습니다.',
				'질문에는 없어도 멘티가 알면 좋을 것 같은 정보가 있다면 함께 알려주세요.',
			],
			detailUrl: '/',
			isRePull: false, //답변 못받은 질문 중 공개수배되어 있는지 확인
			answerCorrespondence: false,
		};
	},
	computed: {
		isDummyQuestion() {
			return this.questionInfo.original_type === 'qa_newbie';
		},
		boxPaddings() {
			return this.isMobile ? [0, 0, 0, 0] : [42, 0, 0, 0];
		},
	},
	created() {
		window.scrollTo(0, 0);
	},
	mounted() {
		this.setMeta({
			title: '답변하기',
			isShowFooter: false,
			isDetail: true,
		});
		this.$nextTick(() => {
			this.getContents();
		});
	},

	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		getContents() {
			axios({
				method: 'POST',
				url: '/api/answer/getContents',
				data: {
					request_no: this.no,
				},
			})
				.then(response => {
					if (response.data.code === 200) {
						this.parentUserNo = response.data.result.user_no;
						this.infoDisplay = parseInt(response.data.result.info_display);
						this.questionInfo = {
							...response.data.result,
							mentoring_category_group_id: response.data.mentoring_category_group_id,
						};
						this.isRePull = response.data.isRePull;

						let cor = response.data.cor;
						let info = {
							user_no: this.userNo,
							user_type: this.userType,
							email: this.userId,
							user_level: this.userLevel,
							req_no: this.no,
							req_type: this.type,
							cor_com: cor.cor_com,
							cor_in: cor.cor_in,
							cor_job: cor.cor_job,
							cor_univ: cor.cor_univ,
							where: response.data.gtm_view_request_where,
							re_mentoring: this.isRePull,
						};

						// GTM - viewRequest
						viewRequest(info);

						// GTM - GAVirtualPageview
						GAVirtualPageview(this.$route.path);

						this.getMatchingInfo();
					}
					this.isLoaded = true;
				})
				.catch(error => {
					console.log(error);
					this.isLoaded = true;
				});
		},
		hideAnswerTip() {
			this.isShowAnswerTip = false;
		},
		handleSubmitQuestionFeedback(detailUrl) {
			this.answerShowThanksModal = true;
			this.$emit('submit-question-feedback', detailUrl);
		},
		getMatchingInfo() {
			this.answerCorrespondence = this.$cookies.get('correspondence');
			if (typeof this.answerCorrespondence === 'string' && this.answerCorrespondence.toLowerCase() === 'true') {
				// GTM - viewRequestWithCorrespondence
				viewRequestWithCorrespondence();
			}
		},
	},
	components: {
		AnswerDetailThanksModal,
		MineFeedbackQuestion,
		AnswerTipBox,
		AnswerDetailQuestionSection,
		GridLayout,
		// LinkShareModal,
	},
};
</script>

<style lang="scss">
@import 'shepherd.js/dist/css/shepherd.css';
@import 'resources/assets/scss/base/main';

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
	opacity: 0;
}
.answer-detail-question-feedback {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: -100%;
		width: 1000%;
		height: 100%;
		background: rgb(252, 252, 252);
	}
}

.shepherd-target-highlight {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	border-radius: 10px !important;

	& > .c-box,
	&.answer-mentoring-keyword {
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	}
}

.shepherd-title {
	color: $gray200;
	@include headline7();
}

.shepherd-content {
	padding: 20px;
	background: $gray850;
}

.shepherd-has-title .shepherd-content .shepherd-header {
	background: none;
	padding: 0;
	margin-bottom: 8px;
}

.shepherd-text {
	color: $gray200;
	padding: 0;
	margin-bottom: 8px;
	word-break: keep-all;
	@include body2();
}

.shepherd-footer {
	padding: 0;
}

.shepherd-button {
	background: $gray100;
	border-radius: 6px;
	padding: 7px 12px;
	color: $gray600;
	@include body2();

	&:not(:disabled):hover {
		background: $gray250;
		color: $gray600;
	}
}

.shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow:before {
	background: $gray850;
}

.shepherd-arrow:before {
	background: $gray850;
}

.shepherd-element[data-shepherd-step-id='step1'] {
	max-width: 316px;
	@include pc {
		max-width: 328px;
	}
}

.shepherd-element[data-shepherd-step-id='step2'] {
	max-width: 351px;
	@include pc {
		max-width: 382px;
	}

	.shepherd-arrow {
		@include pc {
			left: -340px !important;
		}
	}
}
</style>
