import { MY_PAGE_CREDIT_SHOP_PATH, MY_PAGE_PATH, RESUME_INDEX_PATH } from '../constants/paths';

const myPageMixin = {
	computed: {
		$_isMyPage() {
			const { fullPath } = this.$route;
			return (
				fullPath.startsWith(MY_PAGE_PATH) ||
				fullPath.startsWith(MY_PAGE_CREDIT_SHOP_PATH) ||
				fullPath.startsWith(RESUME_INDEX_PATH)
			);
		},
	},
};

export default myPageMixin;
