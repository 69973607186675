import TweenMax from 'gsap';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { APP_USER_AGENT_REGEX } from '../constants/app';

dayjs.locale('ko');

const common = {
	data: () => ({
		isMobile: false,
		s3BucketBaseUrl: 'https://s3.ap-northeast-2.amazonaws.com/comento-s3-bucket',
	}),
	computed: {
		isApp() {
			const userAgent = window.navigator.userAgent;
			const regex = new RegExp(APP_USER_AGENT_REGEX);
			return userAgent.match(regex) !== null;
		},
		isPc() {
			return !this.isMobile;
		},
	},
	methods: {
		/**
		 * 숫자가 증가하는 애니메이션
		 * @param selector: id | class
		 * @param countNo: 최종값
		 * @param speed: 속도(숫자가 높을수록 천천히 증가)
		 **/
		countUp(selector, countNo, speed) {
			let defaultCount = { val: 0 };
			let countSpeed = speed || 1; //생략했으면 1(빠름)
			TweenMax.to(defaultCount, countSpeed, {
				val: parseInt(countNo, 10),
				roundProps: 'val',
				onUpdate: () => {
					if (this.$route.name === 'answer-detail') return false;

					document.querySelector(`${selector}`).innerHTML = this.$options.filters.numberFormat(
						defaultCount.val,
					);
				},
			});
		},
		/**
		 * 특정 위치로 스크롤되는 애니메이션
		 * @param id: HTML ID명
		 * @param duration: 속도
		 * @param offset: 스크롤 위치 조정 (음수면 위, 양수면 아래)
		 * https://github.com/rigor789/vue-scrollto
		 **/
		scrollTo(id, duration, offset) {
			if (!this.isEmptyData(id)) {
				this.$nextTick(() => {
					let scrollDuration = duration || 500;
					let scrollOffset = offset || 0;
					this.$scrollTo(`#${id}`, scrollDuration, { offset: scrollOffset });
				});
			}
		},
		groupBy(list, keyGetter) {
			const map = new Map();
			list.forEach(item => {
				const key = keyGetter(item);
				const collection = map.get(key);
				if (!collection) {
					map.set(key, [item]);
				} else {
					collection.push(item);
				}
			});
			return map;
		},
		$_phoneNumberFormat(value) {
			return value
				.replace(/[^0-9]/g, '')
				.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, '$1-$2-$3')
				.replace('--', '-');
		},
		removeWhiteSpace(value) {
			return value.replace(/\s/gi, '');
		},
		$_removeHTMLTag(value) {
			return value.replace(/(<([^>]+)>)/gi, '');
		},
		/**
		 * 빈값인지 체크
		 * @param value
		 * @return Boolean
		 **/
		isEmptyData(value) {
			// https://sanghaklee.tistory.com/58 [이상학의 개발블로그]
			return (
				value === null ||
				typeof value === 'undefined' ||
				(typeof value === 'string' && value === '') ||
				(Array.isArray(value) && value.length < 1) ||
				(typeof value === 'object' &&
					value.constructor.name === 'Object' &&
					Object.keys(value).length < 1 &&
					Object.getOwnPropertyNames(value) < 1) ||
				(typeof value === 'object' && value.constructor.name === 'String' && Object.keys(value).length < 1)
			);
		},
		/*
		 * 한글 10자, 영문∙숫자∙특수문자 20자
		 * @param nickname
		 **/
		checkNickname(nickname) {
			let nickLength = 0;

			for (let i = 0; i < nickname.length; i++) {
				//한글은 2, 영문은 1로 치환
				let nick = nickname.charAt(i);
				if (escape(nick).length > 4) {
					nickLength += 2;
				} else {
					nickLength += 1;
				}
			}
			return !(nickLength < 2 || nickLength > 20);
		},
		$_getUrlParams() {
			let params = {};
			window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
				params[key.toLowerCase()] = decodeURIComponent(value);
			});
			return params;
		},
		detectmob() {
			if (
				navigator.userAgent.match(/Android/i) ||
				navigator.userAgent.match(/webOS/i) ||
				navigator.userAgent.match(/iPhone/i) ||
				navigator.userAgent.match(/iPad/i) ||
				navigator.userAgent.match(/iPod/i) ||
				navigator.userAgent.match(/BlackBerry/i) ||
				navigator.userAgent.match(/Windows Phone/i)
			) {
				return true;
			} else {
				return false;
			}
		},
		json2array(json) {
			let result = [];
			let keys = Object.keys(json);
			keys.forEach(function (key) {
				result.push(json[key]);
			});
			return result;
		},
		getIEVersion() {
			let sAgent = window.navigator.userAgent;
			let Idx = sAgent.indexOf('MSIE');

			// If IE, return version number.
			if (Idx > 0) return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));
			// If IE 11 then look for Updated user agent string.
			else if (navigator.userAgent.match(/Trident\/7\./)) return 11;
			else return 0; //It is not IE
		},
		strpos(haystack, needle, offset) {
			let i = (haystack + '').indexOf(needle, offset || 0);
			return i === -1 ? false : i;
		},
		post_to_url(path, params, method) {
			method = method || 'post'; // Set method to post by default, if not specified.
			// The rest of this code assumes you are not using a library.
			// It can be made less wordy if you use one.
			let form = document.createElement('form');
			form.setAttribute('method', method);
			form.setAttribute('action', path);
			for (let key in params) {
				let hiddenField = document.createElement('input');
				hiddenField.setAttribute('type', 'hidden');
				hiddenField.setAttribute('name', key);
				hiddenField.setAttribute('value', params[key]);
				form.appendChild(hiddenField);
			}
			document.body.appendChild(form);
			form.submit();
		},
		/**
		 * 객체에 키값이 있는지 판별
		 * @param obj: Object
		 * @param propertyName: String
		 * @return Boolean
		 **/
		$_checkOwnProperty(obj, propertyName) {
			return !!(
				obj &&
				(typeof obj == 'object' || typeof obj == 'function') &&
				Object.prototype.hasOwnProperty.call(obj, propertyName)
			);
		},
		$_momentFormat(dateTime, format) {
			return moment(dateTime).format(format);
		},
		$_dayjsFormat(dateTime, format) {
			return dayjs(dateTime).format(format);
		},
		$_handleResize() {
			this.isMobile = window.innerWidth <= 480;
		},
		$_openURL(url) {
			window.open(url);
		},
		$_getLocalStorage(key) {
			try {
				return JSON.parse(localStorage.getItem(key));
			} catch (e) {
				return null;
			}
		},
		$_setLocalStorage(key, value) {
			try {
				return localStorage.setItem(key, JSON.stringify(value));
			} catch (e) {
				return null;
			}
		},
		$_removeLocalStorage(key) {
			try {
				return localStorage.removeItem(key);
			} catch (e) {
				return null;
			}
		},
		$_getSessionStorage(key) {
			try {
				return JSON.parse(sessionStorage.getItem(key));
			} catch (e) {
				return null;
			}
		},
		$_setSessionStorage(key, value) {
			try {
				return sessionStorage.setItem(key, JSON.stringify(value));
			} catch (e) {
				return null;
			}
		},
		$_removeSessionStorage(key) {
			try {
				return sessionStorage.removeItem(key);
			} catch (e) {
				return null;
			}
		},
		$_notionHrefFormat(url) {
			// IE11이고 Windows 10이상이면 Edge 새창으로 열기
			let urlPrefix = '';
			if (document.documentMode === 11 && navigator.userAgent.indexOf('Windows NT 10.0') > -1) {
				urlPrefix = 'microsoft-edge:';
			}
			return urlPrefix + url;
		},
		$_cloneDeep(array) {
			return JSON.parse(JSON.stringify(array));
		},
		$_moveIndex(array, from, to) {
			return array.splice(to, 0, ...array.splice(from, 1));
		},
		$_getAppVersion(userAgent) {
			const result = userAgent.match(APP_USER_AGENT_REGEX);
			return result[result.length - 1];
		},
		$_getAppOS(userAgent) {
			const result = userAgent.match(APP_USER_AGENT_REGEX);
			return result[1].toLowerCase();
		},
		$_nextTickAfterMounted(callback) {
			requestAnimationFrame(() => {
				requestAnimationFrame(callback);
			});
		},
	},
	created() {
		window.addEventListener('resize', this.$_handleResize);
		this.$_handleResize();
	},
	destroyed() {
		window.removeEventListener('resize', this.$_handleResize);
	},
};

export default common;
