<template>
	<c-new-grid v-bind="$attrs" :fluid="gridFluid" :grid-color="gridColor">
		<c-new-row :no-gutters="rowGutters">
			<slot />
		</c-new-row>
	</c-new-grid>
</template>

<script>
export default {
	name: 'GridLayout',
	props: {
		gridFluid: {
			type: Boolean,
			default: false,
		},
		gridColor: {
			type: String,
			default: '',
		},
		rowGutters: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';

@include mobile {
	.c-new-grid.container {
		max-width: 100vw;
	}
}
</style>
