<template>
	<div class="reply_component" :class="[computedDefinition]">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'Reply',
	props: {
		/* 연하게 보여져야 하는 경우
		 * definition false :  gray600 (연함),
		 * definition true : gray800 (진함) */
		replyDefinition: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({}),
	computed: {
		computedDefinition() {
			if (this.replyDefinition) {
				return `reply_definition`;
			} else {
				return `reply_definition_caption`;
			}
		},
	},
};
</script>

<style scoped lang="less">
@import '../../../../less/base/index';
.reply_component {
	position: relative;
	background: @gray100;
	overflow: hidden;
	.border-radius(20px);
	padding: 12px 16px;
	&.reply_type_comment {
		border: 0;
	}
	&.reply_type_answer {
		border: 1px solid @gray200;
	}
}
</style>
