<template>
	<div>
		<biz-side-component v-if="parseInt(guest) !== 1" num="4"></biz-side-component>
		<section class="section section-left" :class="parseInt(guest) !== 1 ? '' : 'guestLayout'">
			<div class="container text-left">
				<div class="col-8">
					<ApplyProgressComponent
						v-if="applyStatus !== ''"
						display="biz"
						class="only-mobile"
						:apply-type="applyType"
						:apply-status="applyStatus"
					></ApplyProgressComponent>
					<CommonApplyDetailComponent
						:apply-no="applyNo"
						display="biz"
						:apply-type="applyType"
						:random-no="randomNo"
						:base-url="baseUrl"
						:pm-company-user-type="pmCompanyUserType"
					></CommonApplyDetailComponent>
				</div>
				<div class="col-4">
					<ApplyProgressComponent
						v-if="applyStatus !== ''"
						display="biz"
						class="only-pc"
						:apply-type="applyType"
						:apply-status="applyStatus"
					></ApplyProgressComponent>
					<ApplyChatComponent
						class="only-pc"
						display="biz"
						:base-url="baseUrl"
						:apply-no="applyNo"
						:apply-type="applyType"
						:company-name="companyName"
						:user-name="userName"
						:random-no="randomNo"
						:company-logo="companyLogo"
						:apply-status="applyStatus"
						:guest="guest"
					></ApplyChatComponent>
					<div class="float-btn message only-mobile" @click="showChatMessage"></div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import ApplyChatComponent from '../../../../js/components/_common/apply/ApplyChatComponent.vue';
import CommonApplyDetailComponent from '../../../../js/components/_common/apply/CommonApplyDetailComponent.vue';
import ApplyProgressComponent from '../../../../js/components/_common/apply/ApplyProgressComponent.vue';

export default {
	props: ['applyNo', 'baseUrl', 'guest', 'pmCompanyUserType'],
	data() {
		return {
			companyName: '', //회사명
			userName: '', //지원자명
			applyType: 1, //직접지원, 자동지원
			randomNo: 1, //anonymous 프로필 이미지
			companyLogo: '', //회사 로고이미지
			applyStatus: '', //상태명(한글)
			applyAutoInterest: null,
		};
	},
	beforeCreate() {
		this.$store.state.keyword = 'applicant';
	},
	created() {
		this.getApplyDetailInfo();
		this.randomNo = this.getRandomArbitrary(1, 30);
	},
	methods: {
		getApplyDetailInfo() {
			axios({
				method: 'GET',
				url: '/api/apply/detail',
				params: {
					apply_no: this.applyNo,
					location: 'main',
				},
			})
				.then(response => {
					const data = response.data;
					if (data.code === 200) {
						const list = data.list;

						const apply = list.apply;
						this.companyName = apply.com_name;
						this.applyType = apply.user_type;
						this.companyLogo = apply.img_logo;
						this.applyStatus = list.status;

						if (apply.user_type === 5) {
							//자동지원
							this.applyAutoInterest = list.auto_interest;
						}
						const gtm = list.gtm;
						let type = 'auto';
						if (this.applyType === 1) {
							type = 'manual';
						}
						const company_no = gtm.company_no;
						const apply_user_no = gtm.apply_user_no;

						// GTM - viewResume
						viewResume(apply_user_no, company_no, type);

						// 이름 노출 조건 계산
						this.userName = list.apply.name;
						let userNameHidden = false;

						// 자동지원자 유저
						if (apply.user_type === 5) {
							// 자동지원자 유저는 면접상태 이전에는 이름이 가려진다.
							if (this.applyStatus < 5) {
								userNameHidden = true;
							}
						}

						// 열린채용 기업
						if (parseInt(this.pmCompanyUserType) === 8) {
							userNameHidden = false;

							// 면접 이전에만 조건을 계산한다. 면접상태 이후에는 이름이 노출된다.
							if (this.applyState < 5) {
								// 관심도를 보냈으면 확인하기전에는 이름이 노출되지 않는다.
								if (this.applyAutoInterest) {
									if (this.applyAutoInterest.interest !== 1) {
										userNameHidden = true;
									}
								}
								// 관심도를 보내지 않았으면 이름이 노출되지 않는다.
								else {
									userNameHidden = true;
								}
							}
						}

						if (userNameHidden) {
							this.userName = this.userName.substring(0, 1) + '00';
						}
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		getRandomArbitrary(min, max) {
			return Math.floor(Math.random() * (max - min) + min);
		},
		showChatMessage() {
			if (this.display === 'mypage') {
				window.location.href =
					'/applicant/detail/message' +
					'?apply_no=' +
					this.applyNo +
					'&apply_type=' +
					this.applyType +
					'&company_name=' +
					this.companyName +
					'&user_name=' +
					this.userName +
					'&random_no=' +
					this.randomNo +
					'&company_logo=' +
					this.companyLogo;
			} else {
				window.location.href =
					'/applicant/detail/' +
					this.applyNo +
					'/message?' +
					'apply_type=' +
					this.applyType +
					'&company_name=' +
					this.companyName +
					'&user_name=' +
					this.userName +
					'&random_no=' +
					this.randomNo +
					'&company_logo=' +
					this.companyLogo;
			}
		},
	},
	components: {
		ApplyChatComponent,
		CommonApplyDetailComponent,
		ApplyProgressComponent,
	},
};
</script>

<style lang="less" scoped>
.float-btn.message {
	display: none;
}
@media (max-width: 480px) {
	.col-8,
	.col-4 {
		padding: 0;
	}
	.float-btn.message {
		display: block;
	}
}
</style>
