<template>
	<!-- pc는 56px, mobile은 64px -->
	<aside class="alert-layout">
		<GlobalNoticeAlert
			v-if="IS_SHOW_GLOBAL_NOTICE_ALERT"
			:global-notice-info="globalNoticeInfo"
			@close="closeGlobalNoticeAlert"
		/>
		<PtFirstAnniversaryAlert
			v-else-if="GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT"
			@close="closePtFirstAnniversaryAlert"
		/>
		<KPCHeader v-if="isKPC" />
	</aside>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import KPCHeader from '../components/navigation/molecules/alert/KPCHeader.vue';
import GlobalNoticeAlert from '../components/navigation/molecules/alert/GlobalNoticeAlert.vue';
import {
	IS_SHOW_GLOBAL_NOTICE_ALERT,
	SET_IS_SHOW_GLOBAL_NOTICE_ALERT,
	GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT,
	SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT,
} from '../store/modules/layoutStore';
import { GLOBAL_NOTICE_ALERT, PT_FIRST_ANNIVERSARY_ALERT_KEY } from './HeaderWrapper.vue';
import PtFirstAnniversaryAlert from '../components/navigation/molecules/alert/PtFirstAnniversaryAlert.vue';

export default {
	name: 'AlertLayout',
	mixins: [],
	props: {
		globalNoticeInfo: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		...mapGetters(['isKPC']),
		...mapGetters('layoutStore', [IS_SHOW_GLOBAL_NOTICE_ALERT, GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]),
		isClosedGlobalNoticeBanner() {
			return this.$cookies.get(`${GLOBAL_NOTICE_ALERT}${this.globalNoticeInfo.id}`);
		},
		isClosedPtFirstAnniversaryAlert() {
			return this.$cookies.get(PT_FIRST_ANNIVERSARY_ALERT_KEY);
		},
	},
	methods: {
		...mapMutations('layoutStore', [SET_IS_SHOW_GLOBAL_NOTICE_ALERT, SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]),
		closeGlobalNoticeAlert() {
			this[SET_IS_SHOW_GLOBAL_NOTICE_ALERT](false);
			if (!this.isClosedGlobalNoticeBanner) {
				const max = 60 * 60 * 24;
				this.$cookies.set(`${GLOBAL_NOTICE_ALERT}${this.globalNoticeInfo.id}`, true, max);
			}
		},
		closePtFirstAnniversaryAlert() {
			this[SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT](false);
			if (!this.isClosedPtFirstAnniversaryAlert) {
				const max = 60 * 60 * 24;
				this.$cookies.set(PT_FIRST_ANNIVERSARY_ALERT_KEY, true, max);
			}
		},
	},
	components: {
		PtFirstAnniversaryAlert,
		GlobalNoticeAlert,
		KPCHeader,
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.alert-layout {
	display: block;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	height: $alert-height-mobile;
	@include pc {
		height: $alert-height-pc;
	}

	> div {
		position: fixed;
		top: 0;
		width: 100%;
		height: $alert-height-mobile;
		@include pc {
			height: $alert-height-pc;
		}

		&.kpc-header {
			z-index: 8001 !important;
		}
	}
}
</style>
