const reportModalMixin = {
	data() {
		return {
			showReportModal: false,
			reportModalLoading: false,
		};
	},
	methods: {
		$_openReportModal() {
			this.showReportModal = true;
		},
		$_closeReportModal() {
			this.showReportModal = false;
		},
	},
};

export default reportModalMixin;
