<template>
	<c-new-col>
		<GridLayout>
			<c-new-col :col-lg="8" :col-sm="4">
				<p>리다이렉팅 중...</p>
			</c-new-col>
		</GridLayout>

		<alert-modal-component
			:show="showAlert"
			:title="title"
			:message="message"
			:buttonName="buttonName"
			:successCallback="successCallback"
			@close="showAlert = false"
		/>
	</c-new-col>
</template>

<script>
import { mapMutations } from 'vuex';
import GridLayout from '../../components/common/GridLayout.vue';

export default {
	name: 'BridgeMatchView',
	props: {
		userNo: {
			type: Number,
		},
		requestNo: {
			type: Number,
		},
		requestType: {
			type: String,
		},
		feedbackFlag: {
			type: Number,
		},
	},
	data: () => ({
		showAlert: false,
		title: '',
		message: '',
		buttonName: '',
		successCallback: '',
	}),
	created() {
		if (this.feedbackFlag > 0) {
			showLoading();
			let answerType = `question`;
			switch (this.requestType) {
				case 'ce':
					answerType = `essay`;
					break;
				case 'in':
					answerType = `interview`;
					break;
			}

			let formData = new FormData();
			formData.append('request_no', this.requestNo);

			axios
				.post(`/answer/${answerType}`, formData, {
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				})
				// eslint-disable-next-line no-unused-vars
				.then(({ data }) => {
					// console.log(data);
					this.$store.state.answer.requestNo = this.requestNo;
					this.$store.state.answer.type = this.requestType;

					this.$router
						.replace({
							name: 'answer-detail',
							params: { type: answerType },
						})
						.catch(() => {});
					// window.location.replace(`/answer/${answerType}`);
					closeLoading();
				})
				.catch(error => {
					console.log(error);
					closeLoading();
				});
		} else {
			this.title = '마감된 요청';
			this.message = '요청이 종료되었습니다.<br>' + '멘티님들의 다른 요청을 도와주세요!';
			this.buttonName = '';
			this.successCallback = '/answer';
			this.showAlert = true;
		}
	},
	mounted() {
		this.setMeta({
			fluid: true,
			isShowFooter: false,
			isShowHeader: false,
			isDetail: true,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
	},
	components: { GridLayout },
};
</script>
