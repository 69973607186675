<template>
	<c-box :paddings="[66, 66, 120, 66]" background-color="white" class="ie-alert-banner">
		<c-typography color="gray850" class="ie-alert-title">
			다른 브라우저를 사용해보세<br />
			요.
		</c-typography>
		<c-typography type="headline5" color="gray850" class="mb-28">
			사용하고 계신 버전에서는 일부 기능이 제한될 수 있습니다.<br />
			아래의 브라우저를 이용해주시길 바랍니다.
		</c-typography>
		<ul class="browser-list-container">
			<li v-for="({ name, img, url }, index) in browserList" :key="`browser-${index}`">
				<a :href="url" target="_blank">
					<img :src="`${cdnBaseUrl}/images/logo/logo-${img}.png`" alt="" />
					<c-typography type="body1" color="gray900">{{ name }}</c-typography>
				</a>
			</li>
		</ul>
		<c-typography type="headline5" color="gray700" class="c-pointer" @click.native="emitClose()">
			나중에 하기
		</c-typography>
	</c-box>
</template>

<script>
import { cdnBaseUrl } from '../../../../constants';

export default {
	name: 'IEAlert',
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		browserList() {
			return [
				{
					name: 'Chrome',
					img: 'chrome',
					url: 'https://www.google.com/intl/ko/chrome/',
				},
				{
					name: 'Edge',
					img: 'edge',
					url: 'https://www.microsoft.com/ko-kr/edge',
				},
				{
					name: 'Firefox',
					img: 'firefox',
					url: 'https://www.mozilla.org/ko/firefox/new/',
				},
			];
		},
	},
	methods: {
		emitClose() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.ie-alert {
	&-banner {
		border-bottom: 1px solid black;
		z-index: 8005;
		position: absolute;
		width: 100%;
	}

	&-title {
		font-size: 90px;
		line-height: 120%;
		font-weight: $semi-bold;
		margin-bottom: 28px;
	}
}

.browser-list-container {
	margin-bottom: 28px;
	@include flexbox();
	@include flex-direction(row);

	li {
		margin-right: 26px;
		&:last-child {
			margin-right: 0;
		}

		> a {
			@include flexbox();
			@include flex-direction(column);
			@include align-items(center);
			background: $gray100;
			padding: 32px 50px;
			cursor: pointer;

			img {
				margin-bottom: 12px;
				width: 100px;
				height: 100px;
			}
		}
	}
}
</style>
