<template>
	<c-alarm-list>
		<template v-for="({ noti_no, url, confirm_style, icon, msg, tp, date, alarm_url }, index) in notiList">
			<c-alarm-list-item
				:key="`noti-item-${index}`"
				:class="getConfirmStyle(confirm_style)"
				@click="emitGoNotiDetail({ noti_no, tp, url, confirm_style, index, alarm_url })"
			>
				<template #icon>
					<span v-html="icon" />
				</template>
				<template #message>
					<span class="text" v-html="msg" />
				</template>
				<template v-if="tp !== 'edu' || tp !== 'fixed-match-alarm'" #date>
					{{ dateFormat(date) }}
				</template>
			</c-alarm-list-item>
		</template>

		<infinite-loading ref="infiniteLoading" :identifier="infiniteId" @infinite="infiniteHandler">
			<span slot="no-results" />
			<span slot="no-more" />
			<span slot="error">
				<c-typography class="mt-10">{{ infiniteErrorMessage }}</c-typography>
				<c-button
					v-show="isErrorInfiniteLoading"
					type="outline"
					size="small"
					class="mt-10"
					@click="goLoginPage"
				>
					로그인 하러가기
				</c-button>
			</span>
		</infinite-loading>
	</c-alarm-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'NotiContent',
	props: {
		notiList: {
			type: Array,
			default: () => [],
		},
		infiniteId: {
			type: Number,
			default: 0,
		},
		isErrorInfiniteLoading: {
			type: Boolean,
			default: false,
		},
		infiniteErrorMessage: {
			type: String,
			default: '오류가 발생했습니다.',
		},
	},
	data() {
		return {
			baseUrl: import.meta.env.baseUrl,
		};
	},
	computed: {
		...mapGetters(['keyword', 'userNickname']),
	},
	methods: {
		goLoginPage() {
			location.href = `/logout`;
		},
		emitGoNotiDetail(args) {
			this.$emit('go-noti-detail', args);
		},
		infiniteHandler($state) {
			this.$emit('infinite-handler', $state);
		},
		dateFormat(dateTime) {
			return dateTime && dateTime.split(' ')[0];
		},
		getConfirmStyle(confirm_style) {
			return confirm_style !== '' ? 'alarm-unread' : '';
		},
	},
};
</script>

<style lang="scss" scoped>
.c-noti-list-item {
	background: white;
}
</style>
