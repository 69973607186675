<template>
	<div class="container mb-10vh">
		<div v-for="(list, key) in yhfAccountList" class="flex flex-column">
			<div class="flex flex-row flex-between closing-card">
				<div class="mr-20">
					<h5 v-if="key === 0">해당 월</h5>
					<div class="closing-box">{{ list.year }}년 {{ list.month }}월</div>
				</div>
				<div class="mr-20">
					<h5 v-if="key === 0">공고 수</h5>
					<div class="closing-box">{{ list.list_count }} 개</div>
				</div>
				<div class="mr-20">
					<h5 v-if="key === 0">청구 금액</h5>
					<div class="closing-box">{{ (list.list_count * 50000) | numberFormat }} 원</div>
				</div>
				<div>
					<h5 v-if="key === 0">상세 이력</h5>
					<div
						class="closing-box point cursor-pointer"
						:class="showDetailClass[key] ? point : ''"
						@click="showDetail(key)"
					>
						자세히보기
					</div>
				</div>
			</div>
			<transition name="fade">
				<div v-if="showDetailList && showDetailClass[key]" class="width-100 mt-30 mb-30">
					<table v-if="detailList.length > 0">
						<tr>
							<td>공고 시작일</td>
							<td>지원기업</td>
							<td>채용직무</td>
							<td>상태</td>
							<td>공고 등록일</td>
						</tr>
						<tr v-for="item in detailList">
							<td>{{ item.changed_date == null ? '없음' : item.changed_date | dateFormat }}</td>
							<td>{{ item.name }}</td>
							<td>{{ item.job }}</td>
							<td>{{ changeProgress(item.progress) }}</td>
							<td>{{ item.date | dateFormat }}</td>
						</tr>
					</table>
					<div v-else>
						<div class="border text-center pd-50">
							<img class="sorry-logo" src="/images/co_sorry.svg" />
							<div class="sorry-font">결산 정보가 없습니다.</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	props: ['yhfAccountList'],
	data() {
		return {
			thisNo: -1,
			showDetailList: false,
			showDetailClass: [],
			detailList: [],
			point: 'closing-point-box',
		};
	},
	mounted() {
		this.initShowDetailClass();
	},
	methods: {
		initShowDetailClass() {
			for (let i = 0; i < this.yhfAccountList.length; i++) {
				this.showDetailClass[i] = false;
			}
		},
		changeProgress(progress) {
			if (progress === '-1') return '작성중';
			else if (progress === '0') return '진행';
			else if (progress === '1') return '종료';
			else if (progress === '3') return '승인대기';
			else if (progress === '4') return '결제대기';
		},
		showDetail(no) {
			if (this.thisNo === no) {
				this.showDetailList = !this.showDetailList;

				// Class
				this.showDetailClass[no] = this.showDetailList;
			} else {
				this.detailList = this.yhfAccountList[no].list;

				this.showDetailList = true;

				// Class
				this.initShowDetailClass();
				this.showDetailClass[no] = this.showDetailList;
			}

			this.thisNo = no;
		},
	},
};
</script>

<style lang="less" scoped>
@import '../../../../less/base/variables';

.mb-10vh {
	margin-bottom: 10vh;
}

.closing {
	&-card {
		flex-wrap: wrap;
		@media (max-width: 480px) {
			margin: 0;
			overflow-x: auto;
			overflow-y: hidden;
			white-space: nowrap;
			padding-bottom: 20px;
			flex-wrap: nowrap;
		}
	}

	&-box {
		width: 230px;
		height: 100px;
		margin-top: 10px;
		padding: 20px;
		text-align: center;
		font-size: 20px;
		line-height: 60px;
		background-color: #f1f1f1;
		border: 3px solid #eaeaea;
		border-radius: 5px;
		&.point {
			color: #2a7de1;
			background-color: white;
			border: 3px solid #2a7de1;
			&:hover {
				background-color: #1b5192 !important;
				border: 3px solid #2a7de1 !important;
			}
		}
		@media (max-width: 480px) {
			width: 150px;
			height: 100px;
			line-height: 50px;
		}
	}
	&-point-box {
		background-color: #f7fafe !important;
		border: 3px solid #2a7de1 !important;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

table {
	width: 100%;
	border-collapse: collapse;
	text-align: center;
}

table tr {
	height: 48px;
}

table tr:nth-child(1) {
	background-color: #f1f1f1;
	font-weight: @semi-bold;
}

table tr td {
	border: 1px solid #eaeaea;
}

.sorry {
	&-logo {
		width: 120px;
		@media (max-width: 480px) {
			width: 100px;
		}
	}

	&-font {
		font-size: 20px;
		color: #949494;
		@media (max-width: 480px) {
			font-size: 16px;
		}
	}
}
</style>
