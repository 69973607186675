<template>
	<section class="section text-center position-relative bg-gray">
		<div class="container">
			<biz-login-header-component msg="AI가 찾아주는 인재매칭 서비스"></biz-login-header-component>
			<div class="inline-block login-box bg-white">
				<div class="font-login-title f-semi-bold mb-10">비밀번호 변경</div>
				<input
					v-model="newPW"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="password"
					placeholder="새로운 비밀번호"
				/>
				<input
					v-model="checkPW"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="password"
					placeholder="비밀번호 확인"
					@keydown.enter="change"
				/>
				<div v-if="isWarn" class="text-left font-caption f-red mb-10">{{ warn }}</div>
				<button class="btn btn-full font" @click="change">변경하기</button>
			</div>
		</div>
		<biz-footer-component class="position-absolute"></biz-footer-component>
	</section>
</template>

<script>
export default {
	props: ['code'],
	data() {
		return {
			newPW: '',
			checkPW: '',
			isWarn: false,
			warn: '',
		};
	},
	methods: {
		change() {
			let pwText1 = /[A-Za-z]+[0-9]/;
			let pwText2 = /[0-9]+[A-Za-z]/;

			if ((pwText1.test(this.newPW) || pwText2.test(this.newPW)) === false || this.newPW.length < 6) {
				this.isWarn = true;
				this.warn = '비밀번호 숫자와 영문자 조합으로 6자리 이상으로 사용해야 합니다';
			} else if (this.newPW !== this.checkPW) {
				this.isWarn = true;
				this.warn = '비밀번호를 확인해주세요!';
			} else {
				showLoading();
				axios({
					method: 'POST',
					url: '/api/user/pw/change',
					data: {
						code: this.code,
						pw: this.checkPW,
					},
				})
					.then(response => {
						closeLoading();
						//console.log(response.data);
						let code = parseInt(response.data.code);
						if (code === 200) {
							//alert('변경완료!');
							this.isWarn = false;
							location.href = '/logout';
						} else if (code === 201) {
							this.isWarn = true;
							this.warn =
								'만료된 링크이거나 올바르지 않은 접근입니다. 최신 메일을 확인하거나 재신청해주세요.';
							location.href = '/logout';
						} else {
							this.isWarn = true;
							this.warn = '다시 확인해주세요!';
						}
					})
					.catch(error => {
						closeLoading();
						// console.log(error);
					});
			}
		},
	},
};
</script>
