import { Radar } from 'vue-chartjs';

export default {
	extends: Radar,
	data() {
		return {
			value: '',
			avg: '',
		};
	},
	mounted() {
		axios({
			method: 'POST',
			url: '/api/analytics/result',
			data: {},
		}).then(response => {
			this.value = response.data.norm;
			this.avg = response.data.norm_avg;

			this.renderChart(
				{
					labels: [
						['성과', '(이끌이)'],
						['도전', '(이끌이)'],
						['능동', '(이끌이)'],
						['해결', '(이끌이)'],
						['소통', '(소통이)'],
						['대인', '(소통이)'],
						['열정', '(성실이)'],
						['책임', '(성실이)'],
						['팀워크', '(성실이)'],
						['계획', '(신중이)'],
						['꼼꼼', '(신중이)'],
						['분석', '(신중이)'],
						['학습', '(신중이)'],
					],
					datasets: [
						{
							backgroundColor: 'rgb(212, 229, 249, 0.5)',
							borderWidth: 2,
							borderColor: 'rgb(42, 125, 225)',
							data: [
								this.value['성과'],
								this.value['도전'],
								this.value['능동'],
								this.value['해결'],
								this.value['소통'],
								this.value['대인'],
								this.value['열정'],
								this.value['책임'],
								this.value['팀워크'],
								this.value['계획'],
								this.value['꼼꼼'],
								this.value['분석'],
								this.value['학습'],
							],
							label: '값',
							fill: 'origin',
						},
						{
							backgroundColor: 'rgb(255, 255, 255)',
							borderColor: 'rgb(207, 212, 215)',
							data: [
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
								this.avg,
							],
							label: '내 강점의 평균',
							fill: false,
							borderDash: [10, 5],
						},
					],
				},
				{
					responsive: false,
					maintainAspectRatio: false,
					spanGaps: false,
					title: {
						display: true,
						text: '강점 그래프',
						padding: 16,
					},
					tooltips: {
						enabled: false,
					},
					elements: {
						line: {
							tension: 0.000001,
						},
					},
					plugins: {
						filler: {
							propagate: false,
						},
						'samples-filler-analyser': {
							target: 'chart-analyser',
						},
					},
					scale: {
						angleLines: {
							display: false,
						},
						ticks: {
							beginAtZero: true,
							min: 0,
							fontSize: 0,
						},
					},
					legend: {
						position: 'bottom',
						labels: {
							fontSize: 10,
							padding: 15,
						},
					},
				},
			);
		});
	},
};
