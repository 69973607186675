<template>
	<div>
		<biz-side-component num="4"></biz-side-component>
		<section class="section">
			<ApplyChatComponent
				display="biz"
				:apply-no="applyNo"
				:apply-type="applyType"
				:company-name="companyName"
				:user-name="userName"
				:random-no="randomNo"
				:company-logo="companyLogo"
				:guest="guest"
				:base-url="baseUrl"
			></ApplyChatComponent>
		</section>
	</div>
</template>

<script>
import ApplyChatComponent from '../../../../js/components/_common/apply/ApplyChatComponent.vue';

export default {
	props: ['applyNo', 'applyType', 'companyName', 'userName', 'randomNo', 'companyLogo', 'guest', 'baseUrl'],
	beforeCreate() {
		this.$store.state.keyword = 'chat';
	},
	created() {
		this.$nextTick(() => {});
	},
	components: {
		ApplyChatComponent,
	},
};
</script>
