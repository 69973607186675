<template>
	<div class="register-button c-pointer" :class="[computedFull, computedType]" v-on="$listeners">
		<div class="register-button-icon">
			<img :src="`${cdnBaseUrl}/icon/icon-${type}-medium.svg`" alt="" />
		</div>
		<div v-if="full" class="ml-6">
			<c-typography v-if="recentLogin" type="body1" font-weight="regular"> 최근 계정으로 시작하기 </c-typography>
			<c-typography v-else type="body1" font-weight="regular">
				<template v-if="type === 'kakao'">
					<template v-if="forLiveClass"> 카카오로 시작하기 </template>
					<template v-else> 카카오로 1초만에 시작하기 </template>
				</template>
				<template v-else-if="type === 'facebook'"> 페이스북으로 시작하기 </template>
				<template v-else-if="type === 'naver'"> 네이버로 시작하기 </template>
				<template v-else-if="type === 'email'"> 이메일로 로그인하기 </template>
				<template v-else-if="type === 'google'"> 구글로 시작하기 </template>
			</c-typography>
		</div>
	</div>
</template>

<script>
import { cdnBaseUrl } from '../../../constants';

export default {
	name: 'RegisterButtons',
	props: {
		full: {
			type: Boolean,
			default: false,
		},
		recentLogin: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: '',
		},
		forLiveClass: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		computedFull() {
			if (this.full) {
				return 'full';
			}
		},
		computedType() {
			return this.type;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../scss/base/main';
.register-button {
	@include flexbox();
	@include justify-content(center);
	@include align-items(center);
	border-radius: 21px;
	margin: 0 12px;
	width: 42px;
	height: 42px;
	color: white;
	&-icon {
		width: 24px;
		height: 24px;

		img {
			display: block;
			width: 100% !important;
			height: 100% !important;
		}
	}
	&.full {
		border-radius: 4px;
		height: 48px;
		width: 100%;
		margin: 0 0 12px 0;
		padding-left: 16px;
		@include justify-content(flex-start);
		.register-button-icon {
			margin-right: 2px;
		}
	}
	&.kakao {
		background: #fee500;
		color: #181600;
		&:hover {
			background: #f2ca00;
		}
	}
	&.naver {
		background: #1ec800;
		&:hover {
			background: #1aad00;
		}
	}
	&.google {
		background: #dc4e41;
		&:hover {
			background: #c24539;
		}
	}
	&.facebook {
		background: #1877f2;
		&:hover {
			background: #156bd9;
		}
	}
	&.email {
		background: $gray200;
		color: $gray500;
		&:hover {
			background: $gray300;
		}
	}

	::v-deep .c-button--icon {
		margin: 0 !important;
	}
}
</style>
