<template>
	<div>
		<biz-side-component num="2"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<biz-company-header-component
						main="인재상 정보 입력"
						sub1="로보리크루터가 더 정확한 인재를 찾아내기 위해 찾고있는 인재에 대해서 더 자세히 알려주세요."
					/>
					<div class="f-red font-caption mt-50">* 필수항목</div>
					<div class="bg-white border pd-30">
						<div>
							<span class="font-title f-semi-bold">평가의 우선순위 </span>
							<span v-if="parseInt(pmCompanyUserType) !== 8" class="f-red">*</span>
							<div class="text-caption f-light font-caption">
								우리 회사는 인재 평가시 어떤 부분을 중요하게 생각하나요? 이력, 관심사, 역량 중에서 더
								중요하다고 생각되는 것을 직관적으로 선택하세요.
							</div>
						</div>
						<div class="mt-20">
							<div class="font f-semi-bold">| 1순위</div>
							<div>
								<input
									id="firstPriority1"
									v-model="first"
									class="com-radio"
									type="radio"
									value="0"
								/><label class="font" for="firstPriority1">업무와 연관된 이력을 갖추었는가?</label>
							</div>
							<div>
								<input
									id="firstPriority2"
									v-model="first"
									class="com-radio"
									type="radio"
									value="1"
								/><label class="font" for="firstPriority2"
									>우리 산업과 직무에 대한 관심도가 높은가?</label
								>
							</div>
							<div>
								<input
									id="firstPriority3"
									v-model="first"
									class="com-radio"
									type="radio"
									value="2"
								/><label class="font" for="firstPriority3">업무가 요구하는 역량이 기대되는가?</label>
							</div>
						</div>
						<div class="mt-20">
							<div class="font f-semi-bold">| 2순위</div>
							<div>
								<input
									id="secondPriority1"
									v-model="second"
									class="com-radio"
									type="radio"
									value="0"
								/><label class="font" for="secondPriority1">업무와 연관된 이력을 갖추었는가?</label>
							</div>
							<div>
								<input
									id="secondPriority2"
									v-model="second"
									class="com-radio"
									type="radio"
									value="1"
								/><label class="font" for="secondPriority2"
									>우리 산업과 직무에 대한 관심도가 높은가?</label
								>
							</div>
							<div>
								<input
									id="secondPriority3"
									v-model="second"
									class="com-radio"
									type="radio"
									value="2"
								/><label class="font" for="secondPriority3">업무가 요구하는 역량이 기대되는가?</label>
							</div>
						</div>

						<div class="mt-50">
							<span class="font-title f-semi-bold">자유롭게 기술해주세요.</span>
							<div class="text-caption f-light font-caption">
								지금까지의 정보 이외에 좋은 인재를 추천받기 위한 꼭 필요한 정보가 있나요?<br />
								지원자의 성격, 성향, 역량 등 어떠한 것도 좋으니 편하게 말씀해주세요.<br />
								(본 내용은 채용공고에 공개되지 않는 비공개 정보입니다)
							</div>
						</div>
						<div class="mb-50">
							<textarea
								v-model="free"
								class="com-textbox font f-light pd-10 mt-20"
								placeholder="예)
우리 회사에는 주어진 일이나 목표에 만족하기보다는
능동적인 자세로 스스로 과제를 찾아내고 목표를 세워서 일하는 것을 즐기는 사람들이 많은 편입니다.
따라서 도전을 즐기고 자기학습에 재미를 느끼는 사람이 좋겠고 기본적으로 긍정적인 마인드를 가진 분을 만나뵙고 싶습니다."
							></textarea>
						</div>
					</div>

					<div class="mt-20">
						<div class="float-left mt-20 mb-20 position-relative inline-block">
							<div class="progress-bar"></div>
							<div :class="progressBar" style="width: 180px"></div>
						</div>
						<div class="inline-block"><span class="h7 ml-10">2/3페이지</span></div>
						<div class="float-right text-left">
							<button class="cursor-pointer btn btn-close font mr-10" @click="tempSave">임시저장</button>
							<button class="cursor-pointer btn btn-outline font mr-10" @click="previous">이전</button>
							<button class="cursor-pointer btn font" @click="next">다음</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	props: ['metaInfo', 'recruitInfo', 'pmCompanyUserType'],
	data() {
		return {
			first: '',
			second: '',
			free: '',
			isWarn: false,
			//warn:'',
			progressBar: '',
		};
	},
	mounted() {
		let agent = navigator.userAgent.toLowerCase();
		if ((navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || agent.indexOf('msie') !== -1) {
			this.progressBar = 'ie-bar';
		} else {
			this.progressBar = 'point-bar';
		}

		if (this.metaInfo) {
			let talentInfo = [];
			for (let i = 0; i < this.metaInfo.length; i++) {
				if (this.metaInfo[i].meta === 'talent') {
					talentInfo = this.metaInfo[i].content.split(',');
				}
				this.first = talentInfo[0];
				this.second = talentInfo[1];

				if (this.metaInfo[i].meta === 'robo_other') {
					this.free = this.metaInfo[i].content;
				}
			}
		}
	},
	methods: {
		api(progress) {
			showLoading();
			axios({
				method: 'POST',
				url: '/api/company/recruit/two',
				data: {
					talent: this.first,
					talent2: this.second,
					robo_other: this.free,
				},
			})
				.then(response => {
					closeLoading();
					if (response.data.code === 200) {
						if (progress === 'go') {
							location.href = '/company/recruit/3?com_no=' + this.recruitInfo.no;
						} else if (progress === 'stay') {
							alert('임시저장 완료!');
							return false;
						}
					} else {
						alert('다시 확인해주세요!');
						return false;
					}
				})
				.catch(error => {
					closeLoading();
				});
		},
		tempSave() {
			this.api('stay');
		},
		previous() {
			location.href = '/company/recruit/1?com_no=' + this.recruitInfo.no;
		},
		next() {
			if (parseInt(this.pmCompanyUserType) !== 8) {
				if (this.first === '') {
					alert('평가의 우선순위를 확인해주세요!');
					return false;
				} else if (this.second === '') {
					alert('평가의 우선순위를 확인해주세요!');
					return false;
				} else {
					this.api('go');
				}
			} else {
				this.api('go');
			}
		},
	},
};
</script>

<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
