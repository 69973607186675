import axios from 'axios';
import store from '../store/index';
import { issueJwtToken } from '../api/auth';
import { datadogLogs } from '@datadog/browser-logs';
/*
 * 400 : Bad request
 * 401 : no token or token expired
 * 403 : access denied (권한없음)
 */

// for multiple requests
let isRefreshing = false;
// fail requests를 담는 Queue
let failedQueue = [];

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});
	failedQueue = [];
};

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		const originalRequest = error.config;
		const status = error.response && error.response.status;
		const message = error.response && error.response.data && error.response.data.message;

		// 400이면 재로그인
		if (status === 400) {
			datadogLogs.logger.log(
				`laravel front axios ${status} error`,
				{
					err: message,
					config: JSON.stringify(originalRequest),
					server: error.response.headers?.server,
				},
				'debug',
			);
			window.location.href = '/logout';
		}

		// jwt token이 없거나 만료되었을 때 401 && 리트라이 한적 없을 때
		if (status === 401 && !originalRequest._retry) {
			if (isRefreshing) {
				// failedQueue에 넣는다.
				// originalRequest에서 token을 받는데 성공하면, 그 token을 이용해 api call을 재시도한다.
				return new Promise(function (resolve, reject) {
					failedQueue.push({ resolve, reject });
				})
					.then(token => {
						store.commit('authStore/setJwt', token);
						originalRequest.headers['Authorization'] = 'Bearer ' + token;
						return axios(originalRequest);
					})
					.catch(err => {
						return Promise.reject(err);
					});
			}

			originalRequest._retry = true;
			isRefreshing = true;

			return refreshToken(originalRequest);
		}

		datadogLogs.logger.log(
			`laravel front axios unknown error`,
			{
				err: JSON.stringify(error),
			},
			'debug',
		);

		return Promise.reject(error);
	},
);

async function refreshToken(originalRequest) {
	try {
		store.commit('authStore/setJwt', null);
		const { data, headers } = await issueJwtToken();
		store.commit('authStore/setJwt', data.token);
		const parsedSetHeader = parseAndSetCookies(headers['set-cookie']);
		parsedSetHeader.forEach(options => {
			this.$cookies.set(options.name, options.value, options);
		});
		originalRequest.headers.Authorization = 'Bearer ' + data.token;
		processQueue(null, data.token);
		return axios(originalRequest);
	} catch (error) {
		processQueue(error, null);
		throw error;
	} finally {
		isRefreshing = false;
	}
}

function parseAndSetCookies(setCookieHeader) {
	if (!setCookieHeader) {
		return [];
	}
	return setCookieHeader.map(parseCookieString);
}

function parseCookieString(cookieString) {
	const parts = cookieString.split(';');
	const [nameValue, ...attributes] = parts;
	const [name, value] = nameValue.split('=');

	const cookieAttributes = attributes.reduce((acc, attr) => {
		const [key, val] = attr.trim().split('=');
		acc[key.toLowerCase()] = val || true;
		return acc;
	}, {});

	return {
		name,
		value,
		path: cookieAttributes.path,
		domain: cookieAttributes.domain,
		maxAge: cookieAttributes['max-age'] ? parseInt(cookieAttributes['max-age'], 10) : undefined,
		expires: cookieAttributes.expires ? new Date(cookieAttributes.expires) : undefined,
		secure: !!cookieAttributes.secure,
		httpOnly: !!cookieAttributes.httponly,
		sameSite: cookieAttributes.samesite,
	};
}
