import { mapGetters } from 'vuex';
import { getRoutePath } from '../utils/util';
import { MY_PAGE_ESSAY_PATH } from '../constants/paths';

const resumePathMixin = {
	computed: {
		...mapGetters(['isKPC']),
		$_resumeIndexPath() {
			return this.isKPC ? '/resume/begin' : getRoutePath(MY_PAGE_ESSAY_PATH, 'nuxt');
		},
	},
};

export default resumePathMixin;
