<template>
	<div class="bg-gray pb-10vh mb-10vh">
		<div class="container flex flex-column flex-v-middle">
			<div class="mt-5vh">
				<img
					class="header-logo cursor-pointer"
					src="/images/logo_green.svg"
					onclick="location.href='/yhf/admin'"
				/>
			</div>
			<div class="header-title mt-5vh">{{ headerTitle }}</div>
			<div class="header-subTitle" v-html="subTitle"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['routeUri'],
	data() {
		return {
			headerTitle: '',
			subTitle: '',
		};
	},
	created() {
		this.headerTitle = '청년재단 관리자';
		this.subTitle =
			'<span class="header-pointTitle">청년재단</span> 로보리크루팅 운영관리자 화면입니다.<br>' +
			'관리자 화면 이용과 관련된 문의는 코멘토로 연락주세요.';

		if (this.routeUri === 'company') {
			this.headerTitle = '참여기업 리스트';
			this.subTitle = '';
		} else if (this.routeUri === 'applicant') {
			this.headerTitle = '지원자 성과';
			this.subTitle = '';
		} else if (this.routeUri === 'account') {
			this.headerTitle = '결산관리';
			this.subTitle = '';
		}
	},
};
</script>

<style lang="less" scoped>
@import '../../../../less/base/variables';

.bg-gray {
	background-color: #f1f1f1;
}

.mb-10vh {
	margin-bottom: 10vh;
	@media (max-width: 480px) {
		margin-bottom: 5vh !important;
	}
}

.mt-5vh {
	margin-top: 5vh;
}

.pb-10vh {
	padding-bottom: 10vh;
	@media (max-width: 480px) {
		padding-bottom: 5vh !important;
	}
}

.header {
	&-logo {
		width: 180px;
		@media (max-width: 480px) {
			width: 150px;
		}
	}

	&-title {
		color: #212529;
		line-height: 85px;
		font-size: 60px;
		@media (max-width: 480px) {
			line-height: 65px;
			font-size: 45px;
		}
	}

	&-subTitle {
		font-size: 18px;
		@media (max-width: 480px) {
			font-size: 14px;
		}
	}

	&-pointTitle {
		color: #212529;
		font-weight: @regular;
		font-size: 20px;
		@media (max-width: 480px) {
			font-size: 16px;
		}
	}
}
</style>
