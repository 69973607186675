<template>
	<div class="bg-gray min-height">
		<div class="share-popup margin-auto bg-white">
			<div class="border-bottom pt-10 pb-10">
				<img class="title-logo" src="/images/logo/logo-comento-typo-type.png" />
				<div class="text-caption font-caption">시작하는 사람들의 커리어 커뮤니티</div>
			</div>
			<div class="pt-20">
				<div class="font-login-title f-semi-bold">면접일정 입력</div>
				<div class="font">
					기업에서 직접 연락하여 면접을 진행하기로 한 지원자입니다. 지원자와 협의한 면접 일정을 코멘토
					관리자에게 알려주세요.
				</div>
				<div class="font-caption text-caption">
					* 아직 결정되지 않은 지원자 일정은 입력하지 않으시면 됩니다.
				</div>
			</div>
			<div class="pt-20">
				<span class="font">{{ info.name }}님</span>
				<date-picker
					v-model="interviewDateTime"
					class="datepicker font"
					type="datetime"
					placeholder="면접 일자 선택"
					:format="datePickerFormat"
					:lang="datePickerLang"
					:time-picker-options="timePickerOption"
					:disabled-date="$_isBeforeToday"
					base
				></date-picker>
			</div>
			<div class="pt-20 text-center">
				<button class="btn font" @click="save">저장하기</button>
			</div>
		</div>
		<alert-modal-component
			:show="show"
			:title="title"
			:message="message"
			type="simple"
			:buttonName="buttonName"
			:successCallback="successCallback"
			@close="show = false"
		></alert-modal-component>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import datepickerMixin from '../../../../../js/mixins/datepickerMixin';

export default {
	mixins: [datepickerMixin],
	props: ['info'],
	data() {
		return {
			show: false,
			title: '',
			message: '',
			buttonName: '',
			successCallback: '',
			datePickerLang: {
				days: ['일', '월', '화', '수', '목', '금', '토'],
				months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
				placeholder: {
					date: '날짜',
					dateRange: '시간',
				},
			},
			datePickerFormat: 'YYYY-MM-DD HH:mm',
			timePickerOption: { start: '08:00', step: '00:10', end: '24:00' },
			interviewDateTime: '',
		};
	},
	methods: {
		api() {
			let date = moment(this.interviewDateTime).format('YYYY-MM-DD HH:mm:ss');

			showLoading();
			axios({
				method: 'PATCH',
				url: '/api/interview/manual/confirm',
				data: {
					apply_no: this.info.no,
					interview_date: date,
				},
			})
				.then(response => {
					closeLoading();
					const data = response.data;
					if (data.code === 200) {
						const item = data.result;
						// GTM - interviewSchedule
						interviewSchedule(item.user_no, item.company_no);
						// GTM - confirmSchedule
						confirmSchedule(item.user_no, item.company_no);
						this.show = true;
						this.title = '저장 완료';
						this.message = '면접 일정이 저장되었습니다.<br>코멘토를 이용해주셔서 감사합니다.';
						this.successCallback = '/applicant';
					} else {
						this.show = true;
						this.title = '일정을 입력하세요!';
						this.message = '면접 일정이 입력되지 않았습니다.<br>확정된 일정을 입력해주세요.';
					}
				})
				.catch(error => {
					closeLoading();
				});
		},
		save() {
			if (this.interviewDateTime === '') {
				this.show = true;
				this.title = '일정을 입력하세요!';
				this.message = '면접 일정이 입력되지 않았습니다.<br>확정된 일정을 입력해주세요.';
			} else {
				this.api();
			}
		},
	},
	components: {
		DatePicker,
	},
};
</script>

<style lang="less" scoped>
@border-color: #e1e4e7;

.margin-auto {
	margin: 0 auto;
}

.min-height {
	min-height: 100vh;
}

.title-logo {
	width: 150px;
}

.datepicker.between {
	position: relative;
	top: 15px;
	margin: 0 10px;
}

.mx-datepicker {
	.mx-input-wrapper {
		border: 0 !important;
		border-bottom: 1px solid @border-color !important;
	}

	.mx-input-append {
		display: none;
	}

	input[type='text'] {
		border: 0 !important;
		padding: 10px !important;
		margin-top: 10px !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.mx-input-append {
		top: 5px !important;
	}

	.mx-shortcuts-wrapper {
		display: none !important;
	}

	@media (max-width: 480px) {
		width: 100% !important;
		.mx-range-wrapper {
			width: auto !important;

			.mx-calendar {
				float: initial !important;
			}
		}
	}
}
</style>
