import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

if (import.meta.env.PROD) {
	// datadog RUM
	datadogRum.init({
		applicationId: 'a7e9645e-cbd9-4437-bf94-6db641ee3ce8',
		clientToken: 'pub3723c89808499ee466912f58d4183911',
		service: 'comento-laravel-front',
		env: 'production',
		version: '1.0.0',
		sampleRate: 10,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		actionNameAttribute: 'data-dd-action-name',
	});
	// datadog Logs
	datadogLogs.init({
		clientToken: 'pub03a8f747f1462db698bff8b91aadd920',
		site: 'datadoghq.com',
		service: 'comento-laravel-front',
		env: 'production',
		version: '1.0.0',
		forwardErrorsToLogs: true,
		sampleRate: 100,
	});
}
