<template>
	<div>
		<biz-side-component num="2"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<biz-company-header-component
						main="채용정보 등록"
						sub1="채용정보를 등록하면 Robo-recruiter를 통해 인재를 매칭할 수 있습니다. 상세하게 기록할 수록 딱 맞는 인재를 만날 가능성이 높아집니다."
					></biz-company-header-component>
					<div class="pd-20 bg-dark-gray mb-30 mt-20">
						<span class="font-title f-semi-bold mb-10">주의사항</span>
						<div class="font">
							<div class="mb-5 precaution">
								채용 공고가 아래와 같은 경우 코멘토 채용운영 규정 또는 관련법령에 따라 승인되지 않을 수
								있습니다.<br />
								승인이 되더라도 관련법에 따라 처벌 받을 수 있으며, 승인 후 모니터링에 따라 공고가 삭제
								처리될 수 있으며 결제된 금액은 환불되지 않습니다.
							</div>
							<div class="precaution">1. 연령에 제한을 두거나 성별을 분리하여 모집하는 경우</div>
							<div class="precaution">
								2. 최저임금 미만이거나 채용공고 내용이 기타 법령을 위반하는 경우
							</div>
							<div class="precaution">3. 불량정보 유형에 속하는 채용정보인 경우</div>
							<div class="precaution">
								4. 하나의 공고에 여러 개의 채용직무 정보를 동시에 입력하는 등 AI 매칭이 불가능한 경우
							</div>
							<div class="precaution">5. 계약직, 비정규직 등의 채용공고</div>
							<div class="precaution">6. 기타 코멘토 채용 운영 정책상 적합하지 않다고 판단되는 공고</div>
						</div>
						<div class="text-right">
							<a
								class="font text-link cursor-pointer"
								href="https://docs.google.com/document/d/1ICZ7-E_8LApCmhsxZ2_BIaO5zVUyLA2JelE-3pMdxvo/edit"
								target="_blank"
							>
								자세히 보기
							</a>
						</div>
					</div>
					<div class="bg-white border pd-30">
						<form enctype="multipart/form-data">
							<!-- 채용직군 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">채용직군</h6>
								<div>
									<input
										id="marketing"
										v-model="position"
										class="com-radio mt-10 mb-30"
										type="radio"
										value="마케팅"
									/><label class="font" for="marketing">마케팅</label>
								</div>
								<div>
									<input
										id="sales"
										v-model="position"
										class="com-radio mt-10 mb-30"
										type="radio"
										value="영업"
									/>
									<label class="font" for="sales">영업</label>
								</div>
								<div>
									<input
										id="planning"
										v-model="position"
										class="com-radio mt-10 mb-30"
										type="radio"
										value="기획"
									/>
									<label class="font" for="planning">기획</label>
								</div>
								<div>
									<input
										id="cs"
										v-model="position"
										class="com-radio mt-10 mb-30"
										type="radio"
										value="서비스운영"
									/>
									<label class="font" for="cs">서비스운영</label>
								</div>
								<div>
									<input
										id="admin"
										v-model="position"
										class="com-radio mt-10 mb-30"
										type="radio"
										value="경영지원"
									/>
									<label class="font" for="admin">경영지원</label>
								</div>
								<div>
									<input
										id="develop"
										v-model="position"
										class="com-radio mt-10 mb-30"
										type="radio"
										value="IT/SW"
									/>
									<label class="font" for="develop">IT/SW</label>
								</div>
								<div>
									<input
										id="design"
										v-model="position"
										class="com-radio mt-10 mb-30"
										type="radio"
										value="디자인"
									/>
									<label class="font" for="design">디자인</label>
								</div>
								<div>
									<input
										id="etc"
										v-model="position"
										class="com-radio mt-10 mb-30"
										type="radio"
										value="기타"
									/>
									<label class="font" for="etc">기타</label>
								</div>
							</article>
							<!-- 직무명 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">직무명</h6>
								<input
									v-model="jobTitle"
									class="com-inputbox font f-light pd-10"
									type="text"
									placeholder="채용 직무명을 입력하세요. (예: 콘텐츠 디자이너, 사업개발 운영 )"
								/>
							</article>
							<!-- 채용형태 -->
							<article class="mb-30">
								<h6 class="font-title">채용형태</h6>
								<p class="f-light font-caption mb-10">
									코멘토의 로보리크루팅 서비스는 정규직 신입 및 인턴을 매칭해 드립니다.
								</p>
								<div>
									<input
										id="newComer"
										v-model="employmentType"
										class="com-radio"
										type="radio"
										value="신입"
									/><label class="font" for="newComer">신입</label>
								</div>
								<div>
									<input
										id="Intern"
										v-model="employmentType"
										class="com-radio"
										type="radio"
										value="인턴"
									/><label class="font" for="Intern">인턴</label>
								</div>
							</article>
							<!-- 특이사항 - 신입 -->
							<div v-if="employmentType === '신입'">
								<article class="mb-30">
									<h6 class="font-title">특이사항</h6>
									<p class="f-light font-caption mb-10">
										수습 여부 및 수습 사원 근무기간을 알려주세요.
									</p>
									<div class="mt-10">
										<select v-model="isProbation" class="customSelect cursor-pointer">
											<option value="" disabled>수습직 여부</option>
											<option value="수습기간 있음">수습기간 있음</option>
											<option value="수습기간 없음">수습기간 없음</option>
										</select>
									</div>
									<div v-if="isProbation === '수습기간 있음'" class="mt-10">
										<select v-model="probationDate" class="customSelect cursor-pointer">
											<option value="" disabled>수습기간</option>
											<option v-for="date in 12">{{ date }}개월</option>
										</select>
									</div>
								</article>
							</div>
							<!-- 특이사항 - 인턴 -->
							<div v-if="employmentType === '인턴'">
								<article class="mb-30">
									<h6 class="font-title">특이사항</h6>
									<p class="f-light font-caption mb-10">
										정규직 전환 가능 여부와 근무기간을 알려주세요.
									</p>
									<div class="mt-10">
										<select v-model="isPermanent" class="customSelect cursor-pointer">
											<option value="" disabled>정규직 전환가능 여부</option>
											<option value="정규직 전환형">정규직 전환형</option>
											<option value="정규직 비전환형">정규직 비전환형</option>
										</select>
									</div>
									<div v-if="isPermanent !== ''" class="mt-10">
										<select v-model="permanentDate" class="customSelect cursor-pointer">
											<option value="" disabled>근무기간</option>
											<option v-for="date in 12">{{ date }}개월</option>
										</select>
									</div>
								</article>
							</div>
							<!-- 지원마감일 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">지원마감일</h6>
								<div>
									<date-picker
										v-model="recruitExpireDate"
										class="datepicker font"
										type="date"
										placeholder="지원마감 일자 선택"
										:format="datePickerFormat"
										:lang="datePickerLang"
										:disabled-date="$_isBeforeToday"
										base
									></date-picker>
								</div>
							</article>
							<!-- 사수멘토의 이름 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">사수멘토의 이름</h6>
								<input
									v-model="mentorName"
									class="com-inputbox font f-light pb-10"
									type="text"
									placeholder="사수멘토님의 이름을 입력하세요. (예: 김멘토 )"
								/>
							</article>
							<!-- 성별 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">성별</h6>
								<div>
									<input id="male" v-model="mentorGender" class="com-radio" type="radio" value="1" />
									<label class="font" for="male">남자</label>
								</div>
								<div>
									<input
										id="female"
										v-model="mentorGender"
										class="com-radio"
										type="radio"
										value="2"
									/>
									<label class="font" for="female">여자</label>
								</div>
							</article>
							<!-- 회사에서 맡고 있는 직책 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">회사에서 맡고 있는 직책</h6>
								<div>
									<input
										v-model="mentorPosition"
										class="com-inputbox font f-light pb-10"
										type="text"
										placeholder="사수멘토님의 직책을 입력하세요. (예: 매니저, 팀장 )"
									/>
								</div>
							</article>
							<!-- 회사에서 속해있는 부서 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">회사에서 속해있는 부서</h6>
								<div>
									<input
										v-model="mentorDep"
										class="com-inputbox font f-light pb-10"
										type="text"
										placeholder="채용 부서명을 입력하세요. (예: 콘텐츠 팀 )"
									/>
								</div>
							</article>
							<!-- 이 회사에서 일하게 된 계기가 무엇인가요? -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">이 회사에서 일하게 된 계기가 무엇인가요?</h6>
								<textarea v-model="interviewWhyWork" class="font f-light" placeholder=""></textarea>
							</article>
							<!-- 본인이 생각하는 좋은 직무 담당자란? -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">본인이 생각하는 좋은 직무 담당자란?</h6>
								<textarea v-model="interviewGoodManager" class="font f-light" placeholder=""></textarea>
							</article>
							<!-- 같이 일하게될 신입에게 어떠한 일을 맡길 예정인가요? -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">같이 일하게될 신입에게 어떠한 일을 맡길 예정인가요?</h6>
								<textarea v-model="interviewAssign" class="font f-light" placeholder=""></textarea>
							</article>
							<!-- 평소 업무전달은 어떻게 하시나요? -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">평소 업무전달은 어떻게 하시나요?</h6>
								<textarea
									v-model="interviewTransferWork"
									class="font f-light"
									placeholder=""
								></textarea>
							</article>
							<!-- 부족한 부분에 대해서는 어떻게 피드백 하시나요? -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">부족한 부분에 대해서는 어떻게 피드백 하시나요?</h6>
								<textarea
									v-model="interviewGiveFeedback"
									class="font f-light"
									placeholder=""
								></textarea>
							</article>
							<!-- 어떠한 성장기회가 있을까요? -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">어떠한 성장기회가 있을까요?</h6>
								<textarea
									v-model="interviewGrowthOpportunity"
									class="font f-light"
									placeholder=""
								></textarea>
							</article>
							<!-- 지원서양식 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">지원서양식</h6>
								<div class="mt-10 mb-30">
									<div>
										<input
											id="none"
											v-model="applicationForm"
											class="com-radio"
											type="radio"
											value="0"
										/>
										<label class="font" for="none">지정된 양식 없음</label>
									</div>
									<div>
										<input
											id="attach"
											v-model="applicationForm"
											class="com-radio"
											type="radio"
											value="1"
										/>
										<label class="font" for="attach">지정된 지원서 양식 첨부하기</label>
									</div>
								</div>
							</article>
							<!-- 필수제출서류 -->
							<article v-if="parseInt(applicationForm) === 0" class="mb-30">
								<h6 class="mb-10 font-title">필수제출서류</h6>
								<div>
									<div>
										<input
											id="resume"
											v-model="submission"
											class="com-checkbox"
											type="checkbox"
											disabled
											value="이력서"
										/>
										<label class="font cursor-pointer" for="resume">이력서</label>
									</div>
									<div>
										<input
											id="applyReason"
											v-model="submission"
											class="com-checkbox"
											type="checkbox"
											value="지원동기"
										/>
										<label class="font cursor-pointer" for="applyReason">지원동기</label>
									</div>
									<div>
										<input
											id="selfIntro"
											v-model="submission"
											class="com-checkbox"
											type="checkbox"
											value="자기소개서"
											@change="essayCheck"
										/>
										<label class="font cursor-pointer" for="selfIntro"
											>자기소개서 또는 에세이</label
										>
									</div>
									<div>
										<textarea
											v-if="isEssay"
											v-model="essay"
											class="com-textbox font f-light pd-10 mt-10 mb-10"
											:placeholder="placeholderTxt(5)"
										></textarea>
									</div>
									<div>
										<input
											id="portfolio"
											v-model="submission"
											class="com-checkbox"
											type="checkbox"
											value="포트폴리오"
											@change="portfolioCheck"
										/>
										<label class="font cursor-pointer" for="portfolio"
											>포트폴리오 또는 사전과제</label
										>
									</div>
									<div>
										<textarea
											v-if="isPortfolio"
											v-model="portfolio"
											class="com-textbox font f-light pd-10 mt-10 mb-10"
											:placeholder="placeholderTxt(6)"
										></textarea>
									</div>
								</div>
							</article>
							<!-- 지원서양식 -->
							<div v-if="parseInt(applicationForm) === 1">
								<h6 class="mb-10 font-title">지정 지원서양식 첨부하기</h6>
								<div class="mt-10 mb-30">
									<div>
										<label class="cursor-pointer btn com-img-btn">
											<img class="com-icon" src="/images/upload_cloud.svg" />
											<div class="font-caption text-caption">파일첨부</div>
											<input
												ref="file"
												class="display-none"
												type="file"
												maxlength="1"
												@change="fileUpload"
											/>
										</label>
									</div>
									<div
										v-if="fileName !== ''"
										class="cursor-pointer bg-dark-gray pl-10 pr-10 file-block"
									>
										<img class="file-icon float-left" src="/images/file.svg" />
										<span class="font-caption text-caption f-light file-text">{{ fileName }}</span>
										<img
											class="file-icon float-right"
											src="/images/gray_remove.svg"
											@click="fileDel"
										/>
									</div>
								</div>
							</div>
							<!-- 급여 -->
							<article class="mb-30">
								<h6 class="mb-10 font-title">급여</h6>
								<div>
									<input v-model="pay" class="com-inputbox font f-light pb-10" type="text" />
								</div>
							</article>
						</form>
					</div>
					<div class="mt-20">
						<div class="float-right text-left">
							<button class="cursor-pointer btn font" @click="next">작성완료</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import datepickerMixin from '../../../../js/mixins/datepickerMixin';

export default {
	mixins: [datepickerMixin],
	props: ['recruitInfo', 'metaInfo', 'mentorInfo', 'pmCompanyUserType'],
	data() {
		return {
			position: '',
			jobTitle: '',
			employmentType: '',
			isProbation: '',
			probationDate: '',
			isPermanent: '',
			permanentDate: '',
			mentorName: '',
			mentorGender: '',
			mentorPosition: '',
			mentorDep: '',
			interviewWhyWork: '',
			interviewGoodManager: '',
			interviewAssign: '',
			interviewTransferWork: '',
			interviewGiveFeedback: '',
			interviewGrowthOpportunity: '',
			pay: '',

			employeeNum: '',
			businessInfo: '',
			growthInfo: '',
			requiredInfo: '',
			preferentialInfo: '',
			applicationForm: 0,
			submission: ['이력서'],
			essay: '',
			isEssay: false,
			portfolio: '',
			isPortfolio: false,
			file: '',
			fileName: '',
			isWarn: false,
			progressBar: '',
			tempWarn: false,
			required: {
				growthInfo: true,
			},
			recruitExpireDate: '',
			datePickerLang: {
				days: ['일', '월', '화', '수', '목', '금', '토'],
				months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
				placeholder: {
					date: '날짜',
				},
			},
			datePickerFormat: 'YYYY-MM-DD',
		};
	},
	watch: {
		warn() {
			this.isWarn = true;
		},
		employmentType(type) {
			if (type === '신입') {
				this.isPermanent = '';
				this.permanentDate = '';
			} else {
				this.isProbation = '';
				this.probationDate = '';
			}
		},
		applicationForm(type) {
			if (parseInt(type) === 0) {
				this.file = '';
				this.fileName = '';
			} else {
				this.submission = ['이력서'];
				if (this.recruitInfo) {
					this.essay = '';
					this.isEssay = false;
					this.portfolio = '';
					this.isPortfolio = false;
				}
			}
		},
	},
	mounted() {
		// let agent = navigator.userAgent.toLowerCase();
		// if ((navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || (agent.indexOf("msie") !== -1)) {
		//     this.progressBar = 'ie-bar'
		// } else {
		//     this.progressBar = 'point-bar';
		// }

		if (this.recruitInfo) {
			this.position = this.recruitInfo.jobsector ? this.recruitInfo.jobsector : '';
			this.jobTitle = this.recruitInfo.job ? this.recruitInfo.job : '';
			this.employmentType = this.recruitInfo.position ? this.recruitInfo.position : '';
			this.employeeNum = this.recruitInfo.headcount ? this.recruitInfo.headcount : '';
			this.businessInfo = this.recruitInfo.job_detail ? this.recruitInfo.job_detail : '';
			this.growthInfo = this.recruitInfo.growth_oppor ? this.recruitInfo.growth_oppor : '';
			this.requiredInfo = this.recruitInfo.job_detail_require ? this.recruitInfo.job_detail_require : '';
			this.preferentialInfo = this.recruitInfo.job_detail_prefer ? this.recruitInfo.job_detail_prefer : '';
			this.applicationForm = parseInt(this.recruitInfo.application_form);

			if (this.employmentType === '신입') {
				this.isProbation = this.recruitInfo.position_type ? this.recruitInfo.position_type : '';
				this.probationDate = this.recruitInfo.position_term ? this.recruitInfo.position_term : '';
			} else if (this.employmentType === '인턴') {
				this.isPermanent = this.recruitInfo.position_type ? this.recruitInfo.position_type : '';
				this.permanentDate = this.recruitInfo.position_term ? this.recruitInfo.position_term : '';
			}
			this.recruitExpireDate = this.recruitInfo.finished_date;

			if (this.applicationForm === 0) {
				let info = this.recruitInfo;
				// if (info.resume_flag === 1) {
				//     this.submission.push('이력서');
				// }
				if (info.motive_flag === 1) {
					this.submission.push('지원동기');
				}
				if (info.self_flag === 1) {
					this.submission.push('자기소개서');
				}
				if (info.additional_flag === 1) {
					this.submission.push('포트폴리오');
				}
				for (let i = 0; i < this.recruitInfo.files.length; i++) {
					let doc_name = this.recruitInfo.files[i].doc_name;
					let doc_desc = this.recruitInfo.files[i].doc_desc;
					if (doc_name === '자기소개서') {
						this.essay = doc_desc;
						this.isEssay = true;
					}
					if (doc_name === '포트폴리오') {
						this.portfolio = doc_desc;
						this.isPortfolio = true;
					}
				}
			} else if (this.applicationForm === 1) {
				let fileInfo;
				for (let i = 0; i < this.recruitInfo.files.length; i++) {
					if (this.recruitInfo.files[i].doc_name === '지정지원서') {
						fileInfo = this.recruitInfo.files[i].doc_desc.split(',');
						this.file = fileInfo[0];
						this.fileName = fileInfo[1];
					}
				}
			}

			this.mentorName = this.mentorInfo.mentor_name;
			this.mentorGender = this.mentorInfo.mentor_gender;
			this.mentorPosition = this.mentorInfo.mentor_position;
			this.mentorDep = this.mentorInfo.mentor_dep;
			this.interviewWhyWork = this.mentorInfo.interview_why_work;
			this.interviewGoodManager = this.mentorInfo.interview_good_manager;
			this.interviewAssign = this.mentorInfo.interview_assign;
			this.interviewTransferWork = this.mentorInfo.interview_transfer_work;
			this.interviewGiveFeedback = this.mentorInfo.interview_give_feedback;
			this.interviewGrowthOpportunity = this.mentorInfo.interview_growth_opportunity;
		}

		if (this.metaInfo) {
			let benefitInfo = [];
			for (let i = 0; i < this.metaInfo.length; i++) {
				if (this.metaInfo[i].meta === 'pay_type') {
					if (this.metaInfo[i].content === '연봉제' || this.metaInfo[i].content === '월 급여제') {
						this.companySalary = this.metaInfo[i].content;
					} else if (this.metaInfo[i].content !== '') {
						this.companySalary = '기타';
						this.salary = this.metaInfo[i].content;
					}
				} else if (this.metaInfo[i].meta === 'pay') {
					if (
						this.metaInfo[i].content === '면접 후 협의' ||
						this.metaInfo[i].content === '회사 내규에 따름'
					) {
						this.pay = this.metaInfo[i].content;
					} else if (this.metaInfo[i].content !== '') {
						// this.pay = '지정';
						// this.pay_etc = this.metaInfo[i].content;
						this.pay = this.metaInfo[i].content;
					}
				} else if (this.metaInfo[i].meta === 'benefit') {
					benefitInfo = this.metaInfo[i].content.split(',');

					for (let j = 0; j < benefitInfo.length; j++) {
						this.benefit.push(benefitInfo[j]);
						if (benefitInfo[j] === '기타') {
							this.benefit_etc = benefitInfo[j + 1];
							break;
						}
					}
				}
			}
		}

		// 열린 채용은 조직문화가 선택옵션으로 변경한다.
		// if (this.pmCompanyUserType === 8) {
		//     this.required.growthInfo = false;
		// }
	},
	methods: {
		placeholderTxt(idx) {
			if (idx === 1) {
				return (
					'예)' +
					'\n' +
					'- 마케팅 목표 달성을 위한 마케팅 계획 및 해당 계획 수행에 필요한 예산안 수립' +
					'\n' +
					'- 잠재 고객을 만날 수 있는 다양한 현장에 방문하여 잠재 고개의 네트워크 확보' +
					'\n' +
					'- 경쟁사 분석을 통한 벤치마킹 수행 및 해당 결과를 서비스/ 제품 기획에 반영' +
					'\n' +
					'- 애플리케이션 버그 수정 및 고객의 기술적인 이슈 해결' +
					'\n' +
					'- GA 분석 결과를 바탕으로 모바일 Web/App UX/UI디자인 및 사용성 개선'
				);
			} else if (idx === 2) {
				return (
					'예)' +
					'\n' +
					'1. 올바른 개발 실무의 기초를 배울 수 있습니다.' +
					'\n' +
					'CTO가 1:1로 밀착하여 개발 작업에 대한 코칭과 피드백을 제공합니다. 이 과정을 통해서 주니어 실무자로써 팀과 함께 개발하는 기초 역량을 갖추어 나갈수 있습니다.' +
					'\n\n' +
					'2. 프로젝트를 리드해볼 기회가 있습니다.' +
					'\n' +
					'당사가 진행하는 주요 프로젝트의 Back-end 파트 부분을 스스로 리드하고 완결 지을 수 있는 기회를 부여받게 됩니다.'
				);
			} else if (idx === 3) {
				return (
					'예)' +
					'\n' +
					'- 문제를 포착하는 관찰력과 그 문제를 정확히 이해하기 위해 노력하는 몰입력' +
					'\n' +
					'- 다양한 정보들간의 관계를 이해하고, 그 관계사이에서 인사이트를 뽑아낼 수 있는 분석력' +
					'\n' +
					'- 전달하고자 하는 바를 명확히 전달할 수 있는 PPT / 자료 제작 능력' +
					'\n' +
					'- 숫자/데이터를 보고 인사이트를 뽑아낼 수 있는 분석 능력'
				);
			} else if (idx === 4) {
				return (
					'예)' +
					'\n' +
					'- 스타트업 창업, 근무 경험' +
					'\n' +
					'- 공모전, 팀프로젝트 성과물 도출 경험' +
					'\n' +
					'- 디자인 툴, 프레젠테이션 툴, 데이터 툴 활용 능력'
				);
			} else if (idx === 5) {
				return (
					'예)' +
					'\n' +
					'자기소개서 문항 (각 500자 작성)' +
					'\n' +
					'Q1) 지원동기는 무엇인가요?' +
					'\n' +
					'Q2) 살면서 가장 도전적인 일은 무엇이었나요?' +
					'\n' +
					'Q3) 지원 직무와 관련해서 현재까지 어떤 노력을 기울여 왔나요?'
				);
			} else if (idx === 6) {
				return (
					'예)' +
					'\n' +
					'아래 내용을 참고하여 포트폴리오를 제출해주세요.' +
					'\n' +
					'- PDF파일 10장 이내로 지원 업무와 관련된 포트폴리오를 제작하여 첨부' +
					'\n' +
					'- GIT, 블로그 등의 링크 첨부' +
					'\n' +
					'- 자유형식의 포트폴리오 첨부'
				);
			}
		},
		essayCheck() {
			let flag = 0;
			for (let i = 0; i < this.submission.length; i++) {
				if (this.submission[i] === '자기소개서') {
					flag = 1;
					break;
				}
			}
			if (flag === 1) {
				this.isEssay = true;
			} else {
				this.isEssay = false;
				this.essay = '';
			}
		},
		portfolioCheck() {
			let flag = 0;
			for (let i = 0; i < this.submission.length; i++) {
				if (this.submission[i] === '포트폴리오') {
					flag = 1;
				}
			}
			if (flag === 1) {
				this.isPortfolio = true;
			} else {
				this.isPortfolio = false;
				this.portfolio = '';
			}
		},
		fileUpload(event) {
			let reader = new FileReader();
			reader.onload = e => {
				this.fileName = e.target.result;
			};
			this.file = event.target.files[0];
			this.fileName = event.target.files[0].name;
		},
		fileDel() {
			this.fileName = '';
			this.file = '';
		},
		api(progress) {
			// this.position = (this.recruitInfo.jobsector) ? this.recruitInfo.jobsector : '';
			// this.jobTitle = (this.recruitInfo.job) ? this.recruitInfo.job : '';

			const formData = new FormData();

			formData.append('pm_com_no', this.recruitInfo.no);
			formData.append('jobsector', this.position);
			formData.append('job', this.jobTitle);
			formData.append('position', this.employmentType);
			formData.append('position_type_1', this.isProbation);
			formData.append('position_term_1', this.probationDate);
			formData.append('position_type_2', this.isPermanent);
			formData.append('position_term_2', this.permanentDate);
			formData.append('finished_date', moment(this.recruitExpireDate).format('YYYY-MM-DD'));
			formData.append('mentor_name', this.mentorName);
			formData.append('mentor_gender', this.mentorGender);
			formData.append('mentor_position', this.mentorPosition);
			formData.append('mentor_dep', this.mentorDep);
			formData.append('interview_why_work', this.interviewWhyWork);
			formData.append('interview_good_manager', this.interviewGoodManager);
			formData.append('interview_assign', this.interviewAssign);
			formData.append('interview_transfer_work', this.interviewTransferWork);
			formData.append('interview_give_feedback', this.interviewGiveFeedback);
			formData.append('interview_growth_opportunity', this.interviewGrowthOpportunity);
			formData.append('application_form', this.applicationForm);
			for (let i = 0; i < this.submission.length; i++) {
				formData.append('application[]', this.submission[i]);
			}
			formData.append('doc_desc', this.essay);
			formData.append('pf_desc', this.portfolio);
			formData.append('pay', this.pay);

			showLoading();

			axios({
				method: 'POST',
				url: '/api/with/company/recruit',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then(response => {
					console.log(response.data);
					closeLoading();

					if (response.data.code === 200) {
						if (progress === 'go') {
							// console.log(response);

							location.href = '/company/recruit/?com_no=' + response.data.com_no;
						} else if (progress === 'stay') {
							alert('임시저장 완료!');
							return false;
						}
					} else {
						console.log(response.data);
						closeLoading();

						alert('다시 확인해주세요!');
						return false;
					}
				})
				.catch(error => {
					closeLoading();
				});
		},
		tempSave() {
			this.statusCheck();
			if (!this.tempWarn) {
				this.api('stay');
			}
		},
		statusCheck() {
			let status = parseInt(this.recruitInfo.progress);
			if (status === 0 || status === 1 || status === 2) {
				if (this.recruitInfo.jobsector !== this.position) {
					this.tempWarn = true;
					alert(
						'새로운 직군으로 채용을 진행하시려면 새 공고를 등록해 주세요. 부득이 변경이 필요한 경우 1:1문의를 통해 연락해 주세요.',
					);
					return false;
				} else if (this.recruitInfo.job !== this.jobTitle) {
					this.tempWarn = true;
					alert(
						'새로운 직무로 채용을 진행하시려면 새 공고를 등록해 주세요. 부득이 변경이 필요한 경우 1:1문의를 통해 연락해 주세요.',
					);
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}
		},
		validator(value, msg) {
			if ((value === '' || typeof value === 'undefined' || value === null) && !this.isWarn) {
				this.isWarn = true;
				alert(msg + ' 확인해주세요!');
				return false;
			}
		},
		next() {
			// this.isWarn = false;
			//
			// if (!this.isWarn) {
			//     if (!this.statusCheck()) {
			//         return false;
			//     }
			// }
			// this.validator(this.position, '채용직군을');
			// this.validator(this.jobTitle, '직무명을');
			// this.validator(this.employmentType, '채용형태를');
			//
			// if (!this.isWarn) {
			//     if (this.employmentType === '신입') {
			//         if (this.isProbation === '수습기간 있음') {
			//             if (this.probationDate === '') {
			//                 this.isWarn = true;
			//                 alert('특이사항을 확인해주세요!');
			//                 return false;
			//             }
			//         } else if (this.isProbation !== '수습기간 없음') {
			//             this.isWarn = true;
			//             alert('특이사항을 확인해주세요!');
			//             return false;
			//         }
			//     } else {
			//         if (this.permanentDate === '') {
			//             this.isWarn = true;
			//             alert('특이사항을 확인해주세요!');
			//             return false;
			//         }
			//     }
			//
			//     if (this.recruitExpireDate === '' && parseInt(this.pmCompanyUserType) === 8) {
			//         this.isWarn = true;
			//         alert('공고 운영 기한을 확인해주세요!.');
			//         return false;
			//     }
			// }
			//
			// this.validator(this.employeeNum, '채용인원을');
			// this.validator(this.businessInfo, '업무내용을');
			// if (this.required.growthInfo) this.validator(this.growthInfo, '이 업무가 제공하는 성장기회를');
			// this.validator(this.requiredInfo, '필요역량을');
			//
			// if (!this.isWarn) {
			//
			//     if (parseInt(this.applicationForm) === 0) {
			//         if (this.submission.length < 1) {
			//             this.isWarn = true;
			//             alert('지원서 양식을 확인해주세요!');
			//             return false;
			//         }
			//     } else {
			//         if (this.fileName === '') {
			//             this.isWarn = true;
			//             alert('지원서 양식을 확인해주세요!');
			//             return false;
			//         }
			//     }
			// }
			// if (!this.isWarn) {
			//
			//     this.api('go');
			// }

			this.api('go');
		},
	},
	components: {
		DatePicker,
	},
};
</script>
<style lang="less" scoped>
.font-title {
	color: #495057 !important;
}
textarea {
	outline: none;
	&:focus {
		border-color: #2a7de1;
	}
}
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
