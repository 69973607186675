<template>
	<div>
		<biz-side-component num="1"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<biz-company-header-component
						main="기업정보 등록"
						sub1="매칭된 지원자가 회사에 대해 잘 알수 있도록 기업을 소개해 주세요. 초대된 지원자가 채용공고에서 회사에 대한 충분한 정보를 얻을 수 있다면 매칭 성사율이 30%이상 높아집니다. 기업 정보는 한 번만 등록하면 채용공고에 공통적으로 적용됩니다."
					/>
					<div class="f-red font-caption pt-50">* 필수항목</div>
					<div class="bg-white border pd-30">
						<div>
							<span class="font-title f-semi-bold">기업명</span>
							<span class="f-red">*</span>
						</div>
						<div>
							<input
								v-model="companyName"
								class="com-inputbox font f-light pd-10 mt-10 mb-50"
								type="text"
								placeholder="예) 주식회사 코멘토"
							/>
						</div>

						<div>
							<span class="font-title f-semi-bold">한 줄 소개</span>
							<span class="f-red">*</span>
							<div class="text-caption f-light font-caption">
								구직자가 기업에 대해 쉽게 알 수 있도록 기업을 한 줄로 소개해 주세요.
							</div>
						</div>
						<div>
							<input
								v-model="simpleIntro"
								class="com-inputbox font f-light pd-10 mt-10 mb-50"
								type="text"
								placeholder="예) 코멘토는 구직자가 현직자에게 질문, 자기소개서 피드백을 받을 수 있는 온라인 멘토링 서비스입니다."
							/>
						</div>

						<div>
							<span class="font-title f-semi-bold">상세 소개</span>
							<span class="f-red">*</span>
							<div class="text-caption f-light font-caption">
								기업의 철학, 제품/서비스 등 기업에 대해 상세한 소개를 입력하세요. 구직자가 기업에 대해
								쉽게 이해할 수 있다면 매칭 가능성이 높아집니다.
							</div>
						</div>
						<div>
							<textarea
								v-model="detailIntro"
								class="com-textbox font f-light pd-10 mt-10 mb-50"
								:placeholder="placeholderTxt(1)"
							></textarea>
						</div>

						<div>
							<span class="font-title f-semi-bold">Hooking Point</span>
							<span class="f-red">*</span>
							<div class="text-caption f-light font-caption">
								우리 회사에 구직자가 관심을 가져야할 만한 이유를 한 문장으로 작성해주세요.
							</div>
						</div>
						<div>
							<input
								v-model="hookingPoint"
								class="com-inputbox font f-light pd-10 mt-10 mb-50"
								type="text"
								placeholder="예) 1년 4개월 만에 1,000만장이 판매될 만큼 빠른 성장을 경험하고 있습니다."
							/>
						</div>

						<div>
							<span class="font-title f-semi-bold">홈페이지 주소</span>
						</div>
						<div>
							<input
								v-model="companyUrl"
								class="com-inputbox font f-light pd-10 mt-10 mb-50"
								type="text"
								placeholder="예) https://comento.kr"
							/>
						</div>

						<div>
							<span class="font-title f-semi-bold">기업 블로그 또는 페이지</span>
						</div>
						<div>
							<input
								v-model="companySns"
								class="com-inputbox font f-light pd-10 mt-10 mb-50"
								type="text"
								placeholder="예) https://brunch.co.kr/@comento"
							/>
						</div>

						<div>
							<span class="font-title f-semi-bold">성장 정보</span>
							<span class="f-red">*</span>
							<div class="text-caption f-light font-caption">
								최근 기업은 어떻게 성장하고 있나요? 구직자들이 매력적으로 느낄만한 기업의 성장 정보를
								알려주세요.
							</div>
						</div>
						<div>
							<textarea
								v-model="growthInfo"
								class="com-textbox font f-light pd-10 mt-10 mb-50"
								:placeholder="placeholderTxt(2)"
							></textarea>
						</div>

						<div>
							<span class="font-title f-semi-bold">조직 문화</span>
							<span v-show="required.corporateCulture" class="f-red">*</span>
							<div class="text-caption f-light font-caption">
								우리회사는 어떤 조직문화를 갖고 있나요? 구직자들이 나와 잘 맞는 문화를 가진 기업인지
								판단할 수 있는 기업 문화를 소개해주세요.
							</div>
						</div>
						<div>
							<textarea
								v-model="corporateCulture"
								class="com-textbox font f-light pd-10 mt-10 mb-50"
								:placeholder="placeholderTxt(3)"
							></textarea>
						</div>
						<div>
							<span class="font-title f-semi-bold">기업 소재지</span>
							<span class="f-red">*</span>
						</div>
						<div class="mt-10 mb-50">
							<div>
								<input
									id="seoul"
									v-model="companyCity"
									class="com-radio"
									type="radio"
									value="서울"
								/><label class="font cursor-pointer" for="seoul">서울</label>
							</div>
							<div>
								<input
									id="otherCity"
									v-model="companyCity"
									class="com-radio"
									type="radio"
									value="기타"
								/>
								<label class="font cursor-pointer" for="otherCity">
									기타
									<input
										v-model="city"
										class="com-inputbox-short font f-light pd-10 ml-20"
										type="text"
										placeholder="기업 소재 도시를 입력하세요."
									/>
								</label>
							</div>
						</div>
						<div>
							<span class="font-title f-semi-bold">직원 수</span>
							<span class="f-red">*</span>
						</div>
						<div class="mt-10 mb-50">
							<input id="1" v-model="employeeNum" class="com-radio" type="radio" value="0" /><label
								class="font cursor-pointer"
								for="1"
								>10명 미만</label
							>
							<input id="10" v-model="employeeNum" class="com-radio" type="radio" value="1" /><label
								class="font cursor-pointer"
								for="10"
								>10~30명</label
							>
							<input id="30" v-model="employeeNum" class="com-radio" type="radio" value="2" /><label
								class="font cursor-pointer"
								for="30"
								>30~50명</label
							>
							<input id="50" v-model="employeeNum" class="com-radio" type="radio" value="3" /><label
								class="font cursor-pointer"
								for="50"
								>50~100명</label
							><br />
							<input id="100" v-model="employeeNum" class="com-radio" type="radio" value="4" /><label
								class="font cursor-pointer"
								for="100"
								>100~500명</label
							>
							<input id="500" v-model="employeeNum" class="com-radio" type="radio" value="5" /><label
								class="font cursor-pointer"
								for="500"
								>500~1000명</label
							>
							<input id="1000" v-model="employeeNum" class="com-radio" type="radio" value="6" /><label
								class="font cursor-pointer"
								for="1000"
								>1000명 이상</label
							>
						</div>
						<div>
							<span class="font-title f-semi-bold">기업 구분</span>
							<span class="f-red">*</span>
						</div>
						<div class="mt-10 mb-50">
							<input id="big" v-model="companyType" class="com-radio" type="radio" value="0" /><label
								class="font cursor-pointer"
								for="big"
								>대기업</label
							>
							<input id="mid" v-model="companyType" class="com-radio" type="radio" value="1" /><label
								class="font cursor-pointer"
								for="mid"
								>중견기업</label
							>
							<input id="small" v-model="companyType" class="com-radio" type="radio" value="2" /><label
								class="font cursor-pointer"
								for="small"
								>중소기업</label
							>
							<input id="startup" v-model="companyType" class="com-radio" type="radio" value="3" /><label
								class="font cursor-pointer"
								for="startup"
								>스타트업</label
							><br />
							<input
								id="social_venture"
								v-model="companyType"
								class="com-radio"
								type="radio"
								value="4"
							/><label class="font cursor-pointer" for="social_venture">소셜벤처</label>
							<input id="ngo" v-model="companyType" class="com-radio" type="radio" value="5" /><label
								class="font cursor-pointer"
								for="ngo"
								>비영리 단체</label
							>
							<input id="incorp" v-model="companyType" class="com-radio" type="radio" value="6" /><label
								class="font cursor-pointer"
								for="incorp"
								>사단법인</label
							>
							<input id="etc" v-model="companyType" class="com-radio" type="radio" value="7" /><label
								class="font cursor-pointer"
								for="etc"
								>기타</label
							>
						</div>
					</div>

					<div class="mt-20">
						<div class="float-left mt-20 mb-20 position-relative inline-block">
							<div class="progress-bar"></div>
							<div :class="progressBar" style="width: 90px"></div>
						</div>
						<div class="inline-block"><span class="h7 ml-10">1/3페이지</span></div>
						<div class="float-right text-left">
							<button class="cursor-pointer btn btn-close font mr-10" @click="tempSave">임시저장</button>
							<button class="cursor-pointer btn font" @click="next">다음</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	props: ['comInfo', 'pmCompanyUserType'],
	data() {
		return {
			companyName: '',
			simpleIntro: '',
			detailIntro: '',
			hookingPoint: '',
			companyUrl: '',
			companySns: '',
			growthInfo: '',
			corporateCulture: '',
			companyCity: '',
			city: '',
			employeeNum: '',
			employee: ['10명 미만', '10~30명', '30~50명', '50~100명', '100~500명', '500~1000명', '1000명 이상'],
			companyType: '',
			company: ['대기업', '중견기업', '중소기업', '스타트업', '소셜벤처', '비영리 단체', '사단법인', '기타'],
			isWarn: false,
			required: {
				corporateCulture: true,
			},
			progressBar: '',
		};
	},
	watch: {
		warn() {
			this.isWarn = true;
		},
		city(type) {
			if (type !== '') {
				this.companyCity = '기타';
			}
		},
	},
	mounted() {
		/*IE 일때*/
		let agent = navigator.userAgent.toLowerCase();
		if ((navigator.appName == 'Netscape' && agent.indexOf('trident') != -1) || agent.indexOf('msie') != -1) {
			this.progressBar = 'ie-bar';
		} else {
			this.progressBar = 'point-bar';
		}

		if (this.comInfo) {
			this.companyName = this.comInfo.name;
			this.simpleIntro = this.comInfo.about;
			this.detailIntro = this.comInfo.detail;
			this.hookingPoint = this.comInfo.hookpoint;
			this.companyUrl = this.comInfo.url;
			this.companySns = this.comInfo.sns;
			this.growthInfo = this.comInfo.growth_info;
			this.corporateCulture = this.comInfo.corporate_culture;
			if (this.comInfo.addr === '서울') {
				this.companyCity = '서울';
			} else if (this.comInfo.addr !== '서울' && this.comInfo.addr !== '') {
				this.companyCity = '기타';
			} else {
				this.companyCity = '';
			}
			this.city = this.comInfo.addr !== '서울' && this.comInfo.addr !== '' ? this.comInfo.addr : '';
			this.employeeNum = this.comInfo.employee_num ? this.comInfo.employee_num : '';
			this.companyType = this.comInfo.com_type ? this.comInfo.com_type : '';
		}
		/*직원수 value를 데이터베이스에 넣을값(index)으로 바꾸기*/
		for (let i = 0; i < this.employee.length; i++) {
			if (this.comInfo.employee_num === this.employee[i]) {
				this.employeeNum = i;
			}
		}
		/*기업 구분 value를 데이터베이스에 넣을값(index)로 바꾸기*/
		for (let i = 0; i < this.company.length; i++) {
			if (this.comInfo.com_type === this.company[i]) {
				this.companyType = i;
			}
		}

		// 열린 채용은 조직문화가 선택옵션으로 변경한다.
		if (this.pmCompanyUserType === 8) {
			this.required.corporateCulture = false;
		}
	},
	methods: {
		placeholderTxt(idx) {
			if (idx === 1) {
				return (
					'예)' +
					'\n' +
					"'시작하는 사람들의 커리어 커뮤니티'" +
					'\n' +
					'코멘토가 판매하는 것은 ‘취업’이 아닙니다. 코멘토가 하는 일은 원하는 일자리를 얻는 방법을 알려주고, 일자리에 인재를 소개해주는 것이 아닙니다.' +
					'\n\n' +
					'우리가 하는 일은 ‘꿈을 연결하는 일입니다.' +
					'\n' +
					'한 개인은 직업을 통해서 꿈을 꾸고, 꿈을 완성합니다. 기업은 인재를 통해서 꿈을 꾸고 인재를 통해서 꿈을 완성합니다. 코멘토가 하는 일은 개인과 기업이 꿈을 꿀 수 있고, 꿈을 완성할 수 있도록 개인과 개인, 개인과 기업을 연결하는 일입니다.' +
					'\n\n' +
					' 우리는 우리의 사명을 달성하기 위해 멘토링 플랫폼 운영 및 Robo-recruiting 기술을 개발합니다.' +
					'\n\n' +
					'(1) 멘토링 플랫폼 운영: 현직자 취업 멘토링 플랫폼 운영을 통해 구직자의 사소한 고민부터 깊이있는 진로 고민, 자기소개서 멘토링까지 취업과정의 고민을 현직자가 함께 풀어갑니다.' +
					'\n\n' +
					'(2) Robo-recruiting 기술 개발 및 인재매칭 서비스: 멘토링 과정에서 수집되는 구직자의 빅데이터를 통해 이력, 관심사에 대한 정보를 분석하고 역량을 예측하여 적합한 인재와 기업을 인공지능이 매칭하는 Robo-recruiting 기술을 개발하고 있습니다.'
				);
			} else if (idx === 2) {
				return (
					'예)' +
					'\n' +
					'코멘토는 2015년 8월에 서비스를 시작한 이래, 국내 최대의 온라인 취업멘토링 서비스로 성장하고 있습니다. 누적 15만 건 이상의 멘토링이 제공되었으며, 연세대, 서강대, 성균관대, 이화여대, 숙명여대 등 전국 10개 이상의 대학/ 기관에서 코멘토를 이용하고 있습니다.' +
					'\n\n' +
					'코멘토는 멘토링 데이터를 활용해 Robo-recruiting 기술을 개발하고 있습니다. Airbnb, Wework 등 글로벌 스타트업과 에이프릴스킨, 샌드박스네트워크, 미팩토리, 다노, 8 Percent, 렌딧 등 국내 유수의 스타트업들이 코멘토의 Robo-recruiting을 통해 우수한 인재를 채용하고 있습니다.' +
					'\n\n' +
					'코멘토는 지금까지 Seed Round 투자 유치 이후 멘토링과 채용서비스 등 자체적인 수익모델을 통해 월 손익분기점을 돌파하는 등 안정적인 성장기반을 갖추어감과 동시에 취업 및 채용시장을 소셜네트워크와 기술로 혁신해 나가고 있습니다.'
				);
			} else if (idx === 3) {
				return (
					'예)' +
					'\n' +
					'코멘토 팀은 세 가지 핵심 가치를 실천하기 위해 노력하고 있습니다. 핵심가치는 코멘토가 중요하게 생각하는 본질적인 신조이자 코멘토 구성원의 의사결정 기준이며, 항상 실천하기 위해 노력합니다.' +
					'\n\n' +
					'- DREAM: 코멘토에서 가장 중요한 것은 코멘토 구성원 개개인의 꿈입니다. 조직의 꿈도 개인의 꿈보다 우선할 수 없으며, 개인의 꿈과 조직의 꿈은 항상 양립합니다. 따라서 코멘토의 구성원은 서로가 서로의 꿈을 이해하고 조직과 구성은 모두는 나의 꿈과 다른 사람의 꿈을 성취하는 것을 돕습니다.' +
					'\n\n' +
					'- LEAN: 코멘토의 구성원이 일하는 방식을 규정합니다. LEAN하다는 것은 업무 수행 및 협업의 과정에서 불필요한 비효율이 최소화된 상태를 뜻합니다. 우리는 문제의 본질에 도달하기 위해 모든 것을 실험하고 그 결과에 따라 행동함으로써 전체의 효율성을 달성하고 비효율적인 요소는 제거합니다.' +
					'\n\n' +
					'- ENTERTAIN: 코멘토에서 동료들과 함께 일하는 것뿐 아니라 조직의 모든 생활이 즐거움이 될 수 있도록 조직의 문화를 만들고 발전시켜 나갑니다.'
				);
			}
		},
		api(progress) {
			showLoading();
			let addr = '';
			if (this.companyCity === '기타') {
				addr = this.city;
			} else {
				addr = this.companyCity;
			}

			axios({
				method: 'POST',
				url: '/api/company/info/one',
				data: {
					name: this.companyName,
					about: this.simpleIntro,
					detail: this.detailIntro,
					hookpoint: this.hookingPoint,
					url: this.companyUrl,
					sns: this.companySns,
					growth_info: this.growthInfo,
					corporate_culture: this.corporateCulture,
					addr: addr,
					employee_num: this.employeeNum,
					com_type: this.companyType,
				},
			})
				.then(response => {
					closeLoading();
					if (response.data.code === 200) {
						if (progress === 'go') {
							location.href = '/company/info/2';
						} else if (progress === 'stay') {
							alert('임시저장 완료!');
							return false;
						}
					} else {
						alert('다시 확인해주세요!');
						return false;
					}
				})
				.catch(error => {
					closeLoading();
				});
		},
		tempSave() {
			this.api('stay');
		},
		validator(value, msg) {
			if ((value === '' || typeof value === 'undefined') && !this.isWarn) {
				this.isWarn = true;
				alert(msg + ' 확인해주세요!');
				return false;
			}
		},
		next() {
			this.isWarn = false;
			this.validator(this.companyName, '기업명을');
			this.validator(this.simpleIntro, '한 줄 소개를');
			this.validator(this.detailIntro, '상세 소개를');
			this.validator(this.hookingPoint, 'hooking point를');
			this.validator(this.growthInfo, '성장 정보를');
			if (this.required.corporateCulture) this.validator(this.corporateCulture, '조직 문화를');
			this.validator(this.companyCity, '기업 소재지를');
			this.validator(this.employeeNum, '직원 수를');
			this.validator(this.companyType, '기업 구분을');

			if (!this.isWarn) {
				this.api('go');
			}
		},
	},
};
</script>

<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
