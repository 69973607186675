<template>
	<c-box id="answer-detail-question" element="article" :paddings="boxPaddings" background-color="white">
		<div id="answer-detail-question-wrapper">
			<div>
				<div class="flex justify-between">
					<c-content type="overline" class="flex align-items-center">
						<div>
							{{ questionInfo.company || '모든회사' }} ·
							{{ questionInfo.job || '모든직무' }}
						</div>
					</c-content>
					<ContentActionButton
						v-if="isUser"
						:content-no="questionInfo.no"
						:content-type="CONTENT_TYPE.CASE_QUESTION"
						:content-author-no="questionInfo.user_no"
						:can-report="canReport(questionInfo)"
						@block-user-toggled="$_emitBlockUserToggled"
					/>
				</div>
			</div>
			<c-content type="title">
				<c-typography color="primary" element="span">Q.</c-typography>
				<c-typography element="span" type="headline7" font-weight="medium" v-html="questionInfo.other_inf" />
			</c-content>
			<c-content type="body">
				<p
					v-linkified:options="{ className: 'text-link' }"
					v-html="$options.filters.nl2br(questionInfo.question)"
				/>
			</c-content>
			<div class="flex flex-row align-center justify-between mb-8">
				<div class="flex flex-row align-items-center">
					<c-avatar type="profile" size="xsmall" class="mr-4" />
					<c-typography type="body2" :font-weight="400" color="gray500">
						{{ menteeSchoolInfo }}
					</c-typography>
				</div>
				<c-content type="caption" class="mb-0">{{ questionInfo.time | dateFormat }}</c-content>
			</div>
		</div>
	</c-box>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentActionButton from '@/components/_common/ContentActionButton.vue';

export const CONTENT_TYPE = Object.freeze({
	// 질문
	CASE_QUESTION: 'CASE_QUESTION',
});

export default {
	name: 'AnswerDetailQuestionSection',
	props: {
		questionInfo: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		...mapGetters(['userType', 'isUser', 'userNo']),
		boxPaddings() {
			return this.isMobile ? [32, 16, 16, 16] : [20, 16, 20, 16];
		},
		menteeSchoolInfo() {
			return this.questionInfo.info_display === 1
				? `${this.questionInfo.univ} ${this.questionInfo.major}`
				: `비공개 학교 ${this.questionInfo.major}`;
		},
		isSSAFY() {
			return this.questionInfo.api_partner_no === 69;
		},
	},
	created() {
		this.CONTENT_TYPE = CONTENT_TYPE;
	},
	methods: {
		canReport(item) {
			return item.user_no !== this.userNo;
		},
		$_emitBlockUserToggled(blockedUserNo) {
			this.$emit('block-user-toggled', blockedUserNo);
		},
	},
	components: { ContentActionButton },
};
</script>

<style lang="scss" scoped>
// 멘토 튜토리얼 box-shadow 가려지는 이슈 해결
.shepherd-target-highlight#answer-detail-question {
	position: relative;
	z-index: 1;
}
::v-deep {
	.c-dropdown--list-wrapper {
		width: max-content !important;
	}
}
</style>
