export const WHERE_ON_AIR = 'onAirClass';

export const GTM_COMMUNITY_AD_PARAMETERS = {
	JOB_POSTING_ID: {
		EDU: '직무부트캠프',
		PT: '실무PT',
		BRAZE: 'brazeCRM',
		PACER: '용산2기',
	},
	PARTNER_NAME: {
		EDU: '코멘토_직무부트캠프',
		PT: '코멘토_실무PT',
		VOD: '코멘토_VOD',
		PACER: '페이서',
	},
	WHERE: {
		ON_BOARD_CASE_LIST: 'onboardCaseList',
		VIEW_REQUEST_LIST: 'viewRequestList',
		TIMELINE: 'timeline',
		INDEX: 'index',
		VIEW_SEARCH_PRODUCT: 'viewSearchProduct',
		NOTI_LIST: 'notiList',
	},
	TYPE: {
		BASIC: 'basic',
		UNDER_PROFILE: 'underProfile',
		NATIVE: 'native',
		GRAND: 'grand',
	},
	CATEGORY: {
		COMMUNITY: 'community',
	},
};
