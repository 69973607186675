<template>
	<div class="box_component" :class="[computedType, computedBorderColor, computedBg]">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'Box',
	props: {
		/* border
		 * 'default' : 선이 없는것
		 * 'border' : 선이 있는거
		 * */
		border: {
			type: Boolean,
			default: false,
		},
		boxBg: {
			type: String,
			default: 'white',
		},
		/* boxBorderColor
		 * '기본색상' : gray100
		 * 기타 생성되면 추가 예정
		 * */
		boxBorderColor: {
			type: String,
			default: 'gray100',
		},
	},
	computed: {
		computedType() {
			if (this.border) {
				return `box_border`;
			} else {
				return null;
			}
		},
		computedBg() {
			if (this.boxBg !== 'white') {
				return `box_bg_${this.boxBg}`;
			} else {
				return null;
			}
		},
		computedBorderColor() {
			if (this.border) {
				return `box_border_${this.boxBorderColor}`;
			} else {
				return null;
			}
		},
	},
};
</script>

<style scoped lang="less">
@import '../../../../less/base/index';
.box_component {
	position: relative;
	padding: 16px 20px;
	background: @white;
	overflow: hidden;
	&.box_border {
		border: 1px solid;
		.border-radius(2px);
	}
	/*배경색상 있을 경우 추가*/
	&.box_border_gray100 {
		border-color: @gray100;
	}
	&.box_border_gray200 {
		border-color: @gray200;
	}
	&.box_border_primary {
		border-color: @primary;
	}
}
</style>
