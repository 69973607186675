import Vue from 'vue';

import apiMixin from './api';
import common from './common';
import appCommandReceiveMixin from './appCommandReceiveMixin';
import date from './date';
import comentoLocalStorageIdMixin from './comentoLocalStorageId';

Vue.mixin(apiMixin);
Vue.mixin(common);
Vue.mixin(appCommandReceiveMixin);
Vue.mixin(date);
Vue.mixin(comentoLocalStorageIdMixin);
