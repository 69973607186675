<template>
	<c-new-col class="answer-detail-view" :no-gutters="isMobile">
		<template v-if="loaded">
			<AnswerDetailQuestionView
				v-if="isQuestion"
				type="qa"
				:no="requestNo"
				:userNo="userNo"
				:userType="userType"
				:userBelong="userBelong"
				:userId="userId"
				:userLevel="userLevel"
				:sid="sid"
				@submit-question-feedback="handleSubmitFeedback"
			/>
			<AnswerDetailEssayView
				v-if="isEssay"
				type="ce"
				:no="requestNo"
				:userNo="userNo"
				:userType="userType"
				:email="userId"
				:userBelong="userBelong"
				:userLevel="userLevel"
				@submit-essay-feedback="handleSubmitFeedback"
			/>
			<AnswerDetailInterviewView
				v-if="isInterview"
				type="in"
				:no="requestNo"
				:userNo="userNo"
				:userType="userType"
				:email="userId"
				:userBelong="userBelong"
				:userLevel="userLevel"
				@submit-interview-feedback="handleSubmitFeedback"
			/>
			<AnswerDetailThanksModal
				:show.sync="showThanksModal"
				@success-callback="goRequestList"
				@close="handleThanksModalClose"
			/>
		</template>
		<CommunityDetailSkeleton v-else />
	</c-new-col>
</template>

<script>
import AnswerDetailEssayView from './AnswerDetailEssayView.vue';
import AnswerDetailInterviewView from './AnswerDetailInterviewView.vue';
import AnswerDetailQuestionView from './AnswerDetailQuestionView.vue';

import { mapMutations } from 'vuex';
import CommunityDetailSkeleton from '../../../components/skeleton/CommunityDetailSkeleton.vue';
import AnswerDetailThanksModal from '@/components/answer/AnswerDetailThanksModal.vue';

export default {
	props: ['userType', 'userNo', 'userId', 'userLevel', 'userBelong', 'sid'],
	data: () => ({
		requestNo: null,
		loaded: false,
		showThanksModal: false,
		detailUrl: '',
	}),
	computed: {
		isQuestion() {
			return this.$route.path.includes('question');
		},
		isInterview() {
			return this.$route.path.includes('interview');
		},
		isEssay() {
			return this.$route.path.includes('essay');
		},
	},
	created() {
		this.getRequestInfo();
	},
	mounted() {
		const headerTitleList = {
			qa: '질문 멘토링',
			ce: '자기소개서 멘토링',
			in: '면접 멘토링',
		};
		const type = this.requestType;
		this.setMeta({
			title: headerTitleList[type] || '답변하기',
			isShowSubHeader: false,
			isShowFooter: false,
			isDetail: true,
		});

		// update upgrade-mentoring
		if (this.$store.state.answer.mentoringType === 'upgradeMentoring') {
			axios({
				method: 'POST',
				url: '/api/answer/enter/mentoringforupgrade',
				data: {
					user_no: this.userNo,
					request_no: this.requestNo,
					request_type: this.requestType,
				},
			}).then(response => {
				console.log(response);
			});
		}
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		getRequestInfo() {
			this.requestNo = this.$route.query.request_no;
			this.loaded = true;
		},
		openThanksModal() {
			this.showThanksModal = true;
		},
		closeThanksModal() {
			this.showThanksModal = false;
		},
		goRequestList() {
			window.location.href = '/answer';
		},
		thanksModalCallback() {
			this.goRequestList();
		},
		handleThanksModalClose() {
			if (this.isDummyQuestion) {
				return this.goRequestList();
			}
			window.location.href = this.detailUrl;
		},
		handleSubmitFeedback(detailUrl) {
			this.detailUrl = detailUrl;
			this.openThanksModal();
		},
	},
	components: {
		AnswerDetailThanksModal,
		CommunityDetailSkeleton,
		AnswerDetailInterviewView,
		AnswerDetailEssayView,
		AnswerDetailQuestionView,
	},
};
</script>
