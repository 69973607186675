const gtmCommunityAdMixin = (gtmParams = {}) => {
	return {
		props: {
			userInfo: {
				type: Object,
				default: () => ({}),
			},
		},
		mounted() {
			this.$_gtmCommunityAdMixin_triggerGTMImpression();
		},
		computed: {
			gtmCommonParameters() {
				return {
					...this.userInfo,
					...gtmParams,
				};
			},
		},
		methods: {
			$_gtmCommunityAdMixin_triggerGTMImpression() {
				// GTM - impression
				impression({
					...this.gtmCommonParameters,
				});
			},
			$_gtmCommunityAdMixin_triggerGTMClickAdBanner() {
				// GTM - clickAdBanner
				clickAdBanner({
					...this.gtmCommonParameters,
				});
			},
		},
	};
};

export default gtmCommunityAdMixin;
