<template>
	<!--  xmlns:v-clipboard="http://www.w3.org/1999/xhtml" -->
	<modal-component :show="show" :type="type" @close="close">
		<div id="confirm-component" class="modal-body">
			<div class="text-left">
				<h4 class="popup-title mb-10">공동 평가자에게 보내기</h4>
				<p class="font-caption text-caption mb-20" style="word-break: break-word">
					이 직무의 지원자들을 함께 검토할 평가자에게 보낼 수 있습니다. 설정한 비밀번호로 접근이 가능하며,
					공유된 링크로는 7일간만 열람할 수 있습니다.
				</p>
			</div>
			<div class="mb-10">
				<div class="font inline-block text-caption pin-font">PINCODE 입력</div>
				<div class="inline-block">
					<input
						ref="pin0"
						v-model="pin0"
						class="border pin"
						type="text"
						maxlength="1"
						:disabled="isDisabled"
						@keydown.enter="createLink"
					/>
					<input
						ref="pin1"
						v-model="pin1"
						class="border pin"
						type="text"
						maxlength="1"
						:disabled="isDisabled"
						@keydown.enter="createLink"
					/>
					<input
						ref="pin2"
						v-model="pin2"
						class="border pin"
						type="text"
						maxlength="1"
						:disabled="isDisabled"
						@keydown.enter="createLink"
					/>
					<input
						ref="pin3"
						v-model="pin3"
						class="border pin"
						type="text"
						maxlength="1"
						:disabled="isDisabled"
						@keydown.enter="createLink"
					/>
				</div>
				<button class="btn pinBtn" @click="createLink">링크생성</button>
			</div>
			<div v-if="pin.length === 4" class="mb-10">
				<div class="font">링크</div>
				<div class="font-caption text-caption mb-10" style="word-break: break-word">
					아래 링크를 공동 평가자(들)에게 PINCODE와 함께 공유하세요.
				</div>
				<input v-model="link" class="com-inputbox font" type="text" />
			</div>
			<div v-if="isWarn" class="font-caption f-red text-right mb-10">{{ warn }}</div>
			<div class="text-right">
				<button class="btn btn-empty text-caption" @click="close">취소</button>
				<button class="btn" @click="copyClipboard('link')">복사하기</button>
				<!-- v-clipboard:copy="link" v-clipboard:success="success" -->
			</div>
		</div>
	</modal-component>
</template>

<script>
export default {
	props: {
		show: { type: Boolean },
		type: { type: String },
		companyNo: { type: Number },
	},
	data() {
		return {
			pin0: '',
			pin1: '',
			pin2: '',
			pin3: '',
			isDisabled: false,
			pin: '',
			link: '',
			isWarn: false,
			warn: '',
		};
	},
	watch: {
		pin0() {
			this.$refs.pin1.focus();
		},
		pin1() {
			this.$refs.pin2.focus();
		},
		pin2() {
			this.$refs.pin3.focus();
		},
	},
	methods: {
		close() {
			this.$emit('close');
		},
		createLink() {
			if (this.pin0 !== '' && this.pin1 !== '' && this.pin2 !== '' && this.pin3 !== '') {
				this.isDisabled = true;
				this.pin = `${this.pin0}${this.pin1}${this.pin2}${this.pin3}`;

				let data = {
					pin_code: this.pin,
					com_no: this.companyNo,
				};

				showLoading();
				axios({
					method: 'POST',
					url: '/api/apply/share',
					data: data,
				})
					.then(response => {
						const data = response.data;
						if (data.code === 200) {
							this.link = data.url;
						}
						//console.log(data);
						closeLoading();
					})
					.catch(error => {
						closeLoading();
						console.log(error);
					});
			} else {
				this.isWarn = true;
				this.warn = 'PIN CODE 모두 입력해주세요!';
			}
		},
		copyClipboard(ref) {
			this.$refs[ref].select();
			document.execCommand('copy');
			alert('복사 완료');
		},
		// success(){
		//     alert('복사완료!');
		//     //this.$emit('close');
		// }
	},
};
</script>
<style lang="less" scoped>
@border-color: #e1e4e7;

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.pin-font {
	position: relative;
	top: -5px;
}

.pin {
	margin-right: auto;
	margin-left: auto;
	border: 1px solid @border-color;
	width: 45px;
	height: 45px;
	line-height: 48px;
	text-align: center;
	font-size: 28px;
	margin-right: 4.5px;
}

.pinBtn {
	height: 45px;
	position: relative;
	top: -5px;
}

@media (max-width: 480px) {
	.pin-font {
		display: block;
	}

	.pin {
		width: 42px;
		margin-right: 0;
	}
}
</style>
