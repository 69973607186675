<template>
	<section class="section position-relative bg-gray">
		<div class="container text-center mb-30">
			<biz-login-header-component msg="로보리크루터가 딱 맞는 인재를 찾아드려요."></biz-login-header-component>
			<div class="inline-block login-box bg-white">
				<div class="font-login-title f-semi-bold mb-10">비밀번호 찾기</div>
				<div class="text-caption text-left font-caption f-light">
					* 이메일 주소로 비밀번호 변경 링크를 보내드려요
				</div>
				<input
					v-model="email"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="text"
					placeholder="채용관리자 이메일 주소"
					name="email"
					@keydown.enter="send"
				/>
				<div v-if="isWarn" class="text-left f-red mb-10">{{ warn }}</div>
				<button class="btn btn-full font" @click="send">전송</button>
			</div>
		</div>
		<biz-footer-component class="position-absolute"></biz-footer-component>
	</section>
</template>

<script>
export default {
	data() {
		return {
			email: '',
			isWarn: false,
			warn: '',
		};
	},
	methods: {
		//입력하신 이메일로 변경 안내 메일이 전송되었습니다
		send() {
			let emailText = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

			if (emailText.test(this.email) === false) {
				this.isWarn = true;
				this.warn = '이메일을 다시 확인해주세요';
			} else {
				showLoading();
				axios({
					method: 'POST',
					url: '/api/user/pw/find',
					data: {
						email: this.email,
					},
				})
					.then(response => {
						//console.log(response.data);
						closeLoading();
						let code = parseInt(response.data.code);
						if (code === 200) {
							alert('입력하신 이메일로 변경 안내 메일이 전송되었습니다.');
							this.isWarn = false;
							location.href = '/logout';
						} else {
							if (response.data.errors.email) {
								this.isWarn = true;
								this.warn = '존재하지 않는 이메일입니다.';
							} else {
								this.isWarn = true;
								this.warn = '회원가입 에러!';
							}
						}
					})
					.catch(error => {
						closeLoading();
						//console.log(error);
					});
			}
		},
	},
};
</script>

<style lang="less" scoped></style>
