import Lottie from 'vue-lottie/src/lottie.vue';

const loadingSpinnerMixin = {
	data() {
		return {
			loadingSpinner: null,
		};
	},
	computed: {
		lottie() {
			return this.$refs.lottie;
		},
		lottieOptions() {
			return {
				animationData: this.loadingSpinner,
			};
		},
	},
	created() {
		this.$_fetchLoadingSpinner();
	},
	methods: {
		async $_fetchLoadingSpinner() {
			// cdn은 cors 오류가 발생해서 s3주소를 사용함
			const { data } = await axios.get(
				'https://comento-s3-bucket.s3.ap-northeast-2.amazonaws.com/images/loading-spinner.json',
			);
			this.loadingSpinner = data;
		},
	},
	components: {
		Lottie,
	},
};

export default loadingSpinnerMixin;
