<template>
	<transition name="slide-fade">
		<c-box
			v-if="!confirmRemoveAnswerTip && show"
			:paddings="[16, 20, 16, 20]"
			background-color="gray000"
			:style="styles"
			class="answer-tip-box"
		>
			<c-typography type="body2" :font-weight="500" color="primary" align="left" class="mb-12">
				{{ title }}
			</c-typography>
			<c-icon-button
				icon-name="IconCloseSmallLine"
				color="gray300"
				style="position: absolute; top: 10px; right: 10px"
				@click="emitClose"
			/>

			<ul>
				<li v-for="(tip, index) in item" :key="`feedback-tip-${index}`" class="flex flex-row mb-4">
					<c-icon name="IconCheckXSmallLine" color="primary" class="mr-4" />
					<c-typography type="caption1" :font-weight="400" color="gray700" align="left">
						{{ tip }}
					</c-typography>
				</li>
			</ul>
		</c-box>
	</transition>
</template>

<script>
import common from '../../mixins/common';

export default {
	name: 'AnswerTipBox',
	mixins: [common],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: 'Tip',
		},
		item: {
			type: Array,
			default: () => [],
		},
		borderRadius: {
			type: String,
			default: '2px',
		},
	},
	computed: {
		confirmRemoveAnswerTip() {
			return this.$_getLocalStorage('confirmRemoveAnswerTip');
		},
		styles() {
			return { 'border-radius': this.borderRadius };
		},
	},
	methods: {
		emitClose() {
			this.$_setLocalStorage('confirmRemoveAnswerTip', true);
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.answer-tip-box {
	position: relative;
	border: 1px solid $gray100;
	overflow: hidden;
	min-height: 138px;
	@include pc {
		min-height: 123px;
	}
}

.slide-fade {
	&-enter-active,
	&-leave-active {
		transition: all 0.2s ease-out;
	}

	&-enter,
	&-leave-active {
		max-height: 0;
		opacity: 0;
	}
}
</style>
