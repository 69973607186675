<template>
	<div class="help help-form">
		<div class="clearfix">
			<h2 class="h2 help-form-title">1:1 문의하기</h2>
			<p class="h7 text-caption m-0">
				문제가 해결되지 않으셨다면 1:1 로 문의하세요.<br />
				문의 주신 내용은 48시간 내로 최대한 빠르게 답변드리도록 하겠습니다. 남겨주신 이메일을 확인해주세요.
			</p>
		</div>
		<div class="help-form-box">
			<c-select
				v-model="selectedCategory"
				placeholder="문의 유형 선택"
				:options="helpCategories"
				:full="isMobile"
			/>
		</div>
		<section class="clearfix">
			<input v-model="title" type="text" class="inputbox inputbox-title" placeholder="제목" />
			<textarea
				v-model="comment"
				rows="5"
				type="textarea"
				class="textbox mb-20"
				:placeholder="commentText"
				@click="focusForm"
			></textarea>
			<div class="clearfix email-wrap">
				<input
					v-model="userEmail"
					type="text"
					class="inputbox inputbox-addr"
					placeholder="답변 받을 이메일 주소"
				/>
				<div class="login-setting">
					<input id="agree" v-model="agree" type="checkbox" name="agree" />
					<label for="agree" class="h7">
						개인정보 수집 및 이용에 동의합니다. 1:1 문의를 위한 최소한의 개인정보만을 수집하고 있습니다.<br />
						개인정보는 ‘개인정보 처리방침’에 근거하여 관리됩니다.
					</label>
				</div>
			</div>
			<template v-if="selectedCategory === 1">
				<input
					v-model="userMobile"
					type="tel"
					maxlength="11"
					class="inputbox inputbox-addr mt-10"
					placeholder="휴대폰번호 - 을 제거 하여 입력해 주세요."
				/>
			</template>
			<div class="btn-wrapper">
				<button class="btn" :class="isFullText" data-dd-action-name="Contact us Button" @click="submitHelp()">
					문의하기
				</button>
			</div>
		</section>

		<alert-modal-component
			:show="showAlert"
			type="simple"
			:title="alertTitle"
			:message="alertMessage"
			:success-callback="successCallback"
			@close="showAlert = false"
		></alert-modal-component>
	</div>
</template>

<script>
export default {
	props: ['email', 'page', 'mobile', 'alertType'],
	data() {
		return {
			selectedCategory: '',
			helpCategories: [
				{
					label: '결제문의',
					value: 1,
				},
				{
					label: '계정문의',
					value: 2,
				},
				{
					label: '오류신고(버그/장애)',
					value: 3,
				},
				{
					label: '건의사항',
					value: 4,
				},
				{
					label: '직무부트캠프 환불',
					value: 5,
					icon: 'IconExportSmallLine',
					iconColor: 'gray700',
				},
				{
					label: '실무PT 환불',
					value: 6,
				},
				{
					label: 'VOD 환불',
					value: 7,
				},
				{
					label: '기타문의',
					value: 8,
				},
			],
			userEmail: '',
			userMobile: '',
			userMobileLength: [],
			title: '',
			comment: '',
			agree: false,
			showAlert: false,
			alertTitle: '',
			alertMessage: '',
			successCallback: '',
			clickHelpTab: false,
			classPtRefundScript:
				'환불을 신청하고 싶은 경우, 수강을 신청하신 클래스 명과 환불 사유에 대해 작성해주세요.\n',
			classPtInfoScript: '- 클래스 명 :\n' + '- 환불 사유 : ',
			vodRefundScript: '환불을 신청하고 싶은 경우, 수강을 신청한 VOD 명과 환불 사유에 대해 작성해주세요.\n',
			vodInfoScript: '- 클래스 명 :\n' + '- 환불 사유 : ',
		};
	},
	computed: {
		isFullText() {
			if (this.selectedCategory === null) {
				return 'disabled';
			} else if (this.selectedCategory === 1) {
				if (
					!this.agree ||
					this.userEmail === '' ||
					this.title === '' ||
					this.comment === '' ||
					this.userMobile === '' ||
					this.userMobile.length < 11
				) {
					return 'disabled';
				}
			} else {
				if (!this.agree || this.userEmail === '' || this.title === '' || this.comment === '') {
					return 'disabled';
				}
			}
			return false;
		},
		commentText() {
			// 각 서비스에 따라 다른 script를 출력
			switch (this.selectedCategory) {
				case 1:
					return (
						'환불 신청을 원하실 경우, 환불 사유에 대해 자세히 설명해주세요.\n' +
						'컨텐츠 열람권의 경우, 결제와 동시에 바로 열람하여 사용하실 수 있어 환불이 어려움을 사전에 공지해드리고 있습니다. 남은 열람 기간에 대해서 중도 환불해 드리기가 어려우니 참고 부탁드립니다.'
					);
				case 6:
					return `${this.classPtRefundScript}${this.classPtInfoScript}`;
				case 7:
					return `${this.vodRefundScript}${this.vodInfoScript}`;
				default:
					return '해결이 필요한 문제에 대해 자세히 설명해주세요.';
			}
		},
	},
	watch: {
		selectedCategory() {
			// 탭 이동시 내용 초기화 & 실무PT/VOD항목을 선택 할 경우, 제목을 자동 입력
			this.comment = '';
			switch (this.selectedCategory) {
				case 5:
					return window.open('/refund/camp/list', '_blank');
				case 6:
					return (this.title = '[환불신청 - 실무PT]');
				case 7:
					return (this.title = '[환불신청 - 실무 VOD]');
				default:
					return (this.title = '');
			}
		},
	},
	created() {
		this.userEmail = this.email;
		this.userMobile = this.mobile;

		if (~location.href.indexOf('category=1')) {
			this.selectedCategory = 1;
		}

		const index = this.helpCategories.findIndex(element => element.label === '오류신고(버그/장애)');

		if (localStorage.getItem('from_refund')) {
			this.selectedCategory = this.helpCategories[index].label;
		}
	},
	methods: {
		submitHelp() {
			if (this.selectedCategory === null) {
				this.alertMessage = '문의 유형을 선택해 주세요.';
				this.showAlert = true;
			}

			if (this.isFullText !== 'disabled') {
				showLoading();

				let priority = 'normal';
				if (this.selectedCategory === 1) priority = 'urgent'; //결제는 긴급

				axios({
					method: 'POST',
					url: '/api/zendesk/submit',
					data: {
						category: this.selectedCategory,
						title: this.title,
						body: this.comment,
						requester: this.userEmail,
						priority: priority,
						phone: this.userMobile,
					},
				})
					.then(response => {
						// console.log(response);
						if (response.data.code === 200) {
							this.alertTitle = '접수 완료';
							this.alertMessage =
								'문의가 접수되었습니다. 남겨주신 이메일을 확인해주세요.<span>(주말 및 휴일에는 답변이 늦어질 수 있습니다.)</span>';
							this.alertType = 'simple';
							this.showAlert = true;
							this.successCallback = 'reload';
							closeLoading();
						} else {
							this.alertTitle = '접수 에러';
							this.alertMessage = '에러가 발생했습니다. 고객센터로 문의해주세요.';
							this.successCallback = '';
							this.alertType = 'simple';
							this.showAlert = true;
							closeLoading();
						}
					})
					.catch(error => {
						console.log(error);
						closeLoading();
					});
			}
		},
		focusForm() {
			// 실무PT, VOD 탭 선택시 해당 내용을 value로서 입력
			if (this.comment) return;
			switch (this.selectedCategory) {
				case 6:
					return (this.comment = this.classPtInfoScript);
				case 7:
					return (this.comment = this.vodInfoScript);
				default:
					return;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@media (max-width: 480px) {
	.col-8 {
		padding: 0 0 50px;
	}

	.btn-wrapper {
		text-align: right;
	}
}
</style>
