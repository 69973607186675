<template>
	<div>
		<nav class="nav-bar float-left">
			<div class="text-caption h7">
				<div class="cursor-pointer border-bottom side" onclick="location.href='/dashboard'">
					<img class="dashboard-logo mr-10" :src="side.img[0]" />
					<span :class="side.color[0]">파트너홈</span>
				</div>

				<div class="cursor-pointer side" onclick="location.href='/with/company/info'">
					<img class="dashboard-logo mr-10" :src="side.img[1]" />
					<span :class="side.color[1]">기업정보관리</span>
					<img class="arrow" src="/images/left-triangle-arrow.svg" style="display: none" />
				</div>

				<div class="cursor-pointer side" onclick="location.href='/with/company/recruit'">
					<img class="dashboard-logo mr-10" :src="side.img[2]" />
					<span :class="side.color[2]">신규채용정보등록</span>
					<img class="arrow" src="/images/left-triangle-arrow.svg" style="display: none" />
				</div>

				<div class="cursor-pointer border-bottom side" onclick="location.href='/company/recruit'">
					<img class="dashboard-logo mr-10" :src="side.img[3]" />
					<span :class="side.color[3]">채용공고관리</span>
					<img class="arrow" src="/images/left-triangle-arrow.svg" style="display: none" />
				</div>

				<div class="cursor-pointer border-bottom side" onclick="location.href='/applicant'">
					<img class="dashboard-logo mr-10" :src="side.img[4]" />
					<span :class="side.color[4]">지원자관리</span>
					<img class="arrow" src="/images/left-triangle-arrow.svg" style="display: none" />
				</div>

				<div class="cursor-pointer side" onclick="location.href='/faq'">
					<img class="dashboard-logo mr-10" :src="side.img[5]" />
					<span :class="side.color[5]">자주하는질문</span>
					<img class="arrow" src="/images/left-triangle-arrow.svg" style="display: none" />
				</div>

				<div class="cursor-pointer side" onclick="location.href='/help'">
					<img class="dashboard-logo mr-10" :src="side.img[6]" />
					<span :class="side.color[6]">1:1문의</span>
				</div>
			</div>
			<div
				class="cursor-pointer text-caption font-caption side tutorial flex flex-center"
				@click="isTutorial = true"
			>
				<img class="dashboard-logo mr-10" src="/images/replay.svg" />
				<span>튜토리얼 다시보기</span>
			</div>
		</nav>

		<biz-tutorial-component :show="isTutorial" @close="isTutorial = false" />
	</div>
</template>

<script>
export default {
	props: ['num'],
	data() {
		return {
			img: [
				'/images/home_gray.svg',
				'/images/info_gray.svg',
				'/images/add_gray.svg',
				'/images/setting_gray.svg',
				'/images/applier_gray.svg',
				'/images/faq_gray.svg',
				'/images/chat_gray.svg',
			],
			color: [
				'text-caption',
				'text-caption',
				'text-caption',
				'text-caption',
				'text-caption',
				'text-caption',
				'text-caption',
			],
			effectImg: [
				'/images/home_green.svg',
				'/images/info_green.svg',
				'/images/add_green.svg',
				'/images/setting_green.svg',
				'/images/applier_green.svg',
				'/images/faq_green.svg',
				'/images/chat_green.svg',
			],
			side: {
				img: [],
				color: [],
			},
			isTutorial: false,
		};
	},
	mounted() {
		let no = parseInt(this.num);

		this.side.img = this.img;
		this.side.color = this.color;

		this.side.img[no] = this.effectImg[no];
		this.side.color[no] = 'f-brand f-semi-bold';
	},
};
</script>
<style lang="less" scoped>
.tutorial {
	position: absolute;
	bottom: 0;
	width: 100%;
}
.arrow {
	float: right;
	position: relative;
	top: 13px;
	width: 18px;
	height: 18px;
	transform: rotate(0deg);
	-webkit-animation: twinkle 1s infinite; /* Safari 4.0 - 8.0 */
	animation: twinkle 1s infinite;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes twinkle {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* Standard syntax */
@keyframes twinkle {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
