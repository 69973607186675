//filter/htmlDecode.js

function htmlDecode(value = '') {
	value = '' + value;

	let e = document.createElement('div');
	e.innerHTML = value;

	/* 작성한 글에 <,> 가 있으면 tag로 인식하기 때문에 변환함 */
	value = value.replace(/</g, '&lt;');
	value = value.replace(/>/g, '&gt;');

	return value;
}

export default htmlDecode;
