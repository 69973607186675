// caseList, readCase 관련 store
import { CASE_CATEGORY_CAREER_CHANGE } from '../../constants';

// state
export const COMMUNITY_BOOKMARK_COUNT = 'COMMUNITY_BOOKMARK_COUNT';

// getters
export const CASE_CATEGORY = 'CASE_CATEGORY';
export const GET_COMMUNITY_BOOKMARK_COUNT = 'GET_COMMUNITY_BOOKMARK_COUNT';

// mutations
export const SET_CASE_CATEGORY = 'SET_CASE_CATEGORY';
export const SET_COMMUNITY_BOOKMARK_COUNT = 'SET_COMMUNITY_BOOKMARK_COUNT';

// actions
export const LOAD_COMMUNITY_BOOKMARK_COUNT = 'LOAD_COMMUNITY_BOOKMARK_COUNT';

const caseStore = {
	namespaced: true,
	state: {
		[CASE_CATEGORY]: null,
		userFeedInfo: null,
		[COMMUNITY_BOOKMARK_COUNT]: 0,
	},
	getters: {
		[CASE_CATEGORY]: state => {
			return state[CASE_CATEGORY];
		},
		isCareerChange: state => {
			// caseCategory가 이직 고민인지
			return state.caseCategory === CASE_CATEGORY_CAREER_CHANGE;
		},
		userFeedInfo: state => {
			return state.userFeedInfo;
		},
		[GET_COMMUNITY_BOOKMARK_COUNT]: state => {
			return state[COMMUNITY_BOOKMARK_COUNT];
		},
	},
	mutations: {
		[SET_CASE_CATEGORY](state, payload) {
			state[CASE_CATEGORY] = payload;
		},
		setUserFeedInfo(state, payload) {
			state.userFeedInfo = payload;
		},
		[SET_COMMUNITY_BOOKMARK_COUNT](state, payload) {
			state[COMMUNITY_BOOKMARK_COUNT] = payload;
		},
	},
	actions: {
		async [LOAD_COMMUNITY_BOOKMARK_COUNT]({ commit }) {
			try {
				const {
					data: { cnt },
				} = await axios.get(`/api/job-questions/getBookMarkTotalCnt`);
				commit(SET_COMMUNITY_BOOKMARK_COUNT, cnt);
			} catch (error) {
				console.error(error);
			}
		},
	},
};

export default caseStore;
