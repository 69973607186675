import { Line } from 'vue-chartjs';

export default {
	extends: Line,
	data() {
		return {
			value: '',
			job_value: '',
			avg: '',
		};
	},
	mounted() {
		axios({
			method: 'POST',
			url: '/api/analytics/result',
			data: {},
		}).then(response => {
			this.value = response.data.tendency_value;
			this.job_value = response.data.job_tendency_value;
			this.avg = response.data.tendency_avg;

			this.renderChart(
				{
					labels: [
						['이끌이', '(주도/성취)'],
						['소통이', '(사교/표현)'],
						['성실이', '(책임/팀워크)'],
						['신중이', '(꼼꼼/분석)'],
					],
					datasets: [
						{
							label: '값',
							backgroundColor: 'rgb(255, 255, 255)',
							borderWidth: 2,
							borderColor: 'rgb(42, 125, 225)',
							data: [this.value[1], this.value[2], this.value[3], this.value[4]],
							fill: false,
						},
						{
							label: '우수한 지원자의 값',
							backgroundColor: 'rgb(255, 255, 255)',
							borderWidth: 2,
							borderColor: 'rgb(238, 126, 65)',
							data: [this.job_value[1], this.job_value[2], this.job_value[3], this.job_value[4]],
							fill: false,
						},
						{
							label: '내 성향의 평균',
							backgroundColor: 'rgb(255, 255, 255)',
							borderColor: 'rgb(207, 212, 215)',
							data: [this.avg, this.avg, this.avg, this.avg],
							fill: false,
							borderDash: [10, 5],
						},
					],
				},
				{
					responsive: false,
					elements: {
						line: {
							tension: 0,
						},
					},
					layout: {},
					title: {
						display: true,
						text: '성향 그래프',
						padding: 16,
					},
					tooltips: {
						enabled: false,
						mode: 'index',
						intersect: false,
					},
					hover: {
						mode: 'nearest',
						intersect: true,
					},
					scales: {
						xAxes: [
							{
								display: true,
								gridLines: {
									display: false,
								},
							},
						],
						yAxes: [
							{
								display: false,
								ticks: {
									padding: 10,
								},
							},
						],
					},
					legend: {
						position: 'bottom',
						labels: {
							fontSize: 10,
							padding: 20,
						},
					},
				},
			);
		});
	},
};
