<template>
	<div>
		<biz-side-component num="1"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<biz-company-header-component
						main="기업 이미지 등록"
						sub1="기업을 대표하는 사진/이미지, CI/BI(로고)를 등록합니다. 좋은 이미지와 언론 정보는 구직자가 기업을 더 신뢰할만 하고 매력적인 기업이라고 생각합니다."
					/>
					<div class="f-red font-caption pt-50">* 필수항목</div>
					<div class="bg-white border pd-30">
						<form enctype="multipart/form-data">
							<div>
								<span class="font-title f-semi-bold">CI/BI 로고이미지 등록</span>
								<span class="f-red">*</span>
								<div class="text-caption f-light font-caption">
									최적 사이즈: 180 x 180 px | 사용 가능 이미지 형식: jpg, png, svg, gif
								</div>
							</div>
							<div class="mt-10 mb-50">
								<label class="cursor-pointer btn com-img-btn mb-20 font">
									+ 파일첨부
									<input
										ref="image"
										class="display-none"
										type="file"
										accept="image/jpeg,image/png,image/svg,image/gif"
										maxlength="1"
										@change="imageUpload"
										@click="imageCheck"
									/>
								</label>
								<br />
								<div v-if="check(logoSrc)" class="inline-block">
									<img ref="logo" class="main-img" :src="logoSrc" />
									<img class="sub-img cursor-pointer" src="/images/delete.svg" @click="imageDel" />
								</div>
							</div>

							<div>
								<span class="font-title f-semi-bold">대표 이미지 등록</span>
								<span class="f-red">*</span>
								<div class="text-caption f-light font-caption">
									최적 사이즈: 900 x 720 px | 사용 가능 이미지 형식: jpg, png, svg, gif | 최대 3장
								</div>
							</div>
							<div class="mt-10 mb-50">
								<label class="cursor-pointer btn com-img-btn mb-20 font">
									+ 파일첨부
									<input
										ref="images"
										class="display-none"
										type="file"
										accept="image/jpeg,image/png,image/svg,image/gif"
										maxlength="3"
										multiple
										@change="imagesUpload"
										@click="imagesCheck"
									/>
								</label>
								<br />
								<div v-for="(list, index) in mainSrc" v-if="check(list)" class="inline-block">
									<img ref="main" class="main-img" :src="list" />
									<img
										class="sub-img cursor-pointer"
										src="/images/delete.svg"
										@click="imagesDel(index)"
									/>
								</div>
							</div>
						</form>
					</div>

					<div class="mt-20">
						<div class="float-left mt-20 mb-20 position-relative inline-block">
							<div class="progress-bar"></div>
							<div :class="progressBar" style="width: 270px"></div>
						</div>
						<div class="inline-block"><span class="h7 ml-10">3/3페이지</span></div>
						<div class="float-right text-left">
							<button class="cursor-pointer btn btn-close font mr-10" @click="tempSave">임시저장</button>
							<button
								class="cursor-pointer btn btn-outline font mr-10"
								onclick="location.href='/company/info/2'"
							>
								이전
							</button>
							<button class="cursor-pointer btn-submit font" @click="submit">완료</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	props: ['comInfo'],
	data() {
		return {
			logoSrc: '',
			selectLogo: '',
			mainSrc: [], //이미지
			selectMain: [], //새로 추가한 이미지
			originMain: [], //기존 이미지
			imgCnt: 0,
			progressBar: '',
		};
	},
	watch: {},
	mounted() {
		let agent = navigator.userAgent.toLowerCase();
		if ((navigator.appName == 'Netscape' && agent.indexOf('trident') != -1) || agent.indexOf('msie') != -1) {
			this.progressBar = 'ie-bar';
		} else {
			this.progressBar = 'point-bar';
		}

		if (this.comInfo) {
			if (this.comInfo.img_logo) {
				this.logoSrc = this.comInfo.img_base_path + this.comInfo.img_logo;
			}
			for (let i = 0; i < this.comInfo.img_back_size; i++) {
				this.originMain.push(this.comInfo.img_back[i]);
				this.mainSrc.push(this.comInfo.img_base_path + this.comInfo.img_back[i]);
			}
		}

		this.imgCnt = this.mainSrc.length;
	},
	methods: {
		check(src) {
			let baseURL = this.comInfo.img_base_path;
			return src.replace(baseURL, '') !== '';
		},
		imageCheck() {
			if (this.logoSrc !== '') {
				alert('로고는 한개만 등록할 수 있습니다');
				return false;
			}
		},
		imagesCheck() {
			this.imgCnt = this.mainSrc.length;
			if (this.mainSrc.length >= 3) {
				alert('대표 이미지는 최대 3장까지 등록 가능합니다');
				return false;
			}
		},
		imageUpload(event) {
			if (this.logoSrc === '') {
				let reader = new FileReader();
				reader.onload = e => {
					this.logoSrc = e.target.result;
					//console.log(this.logoSrc);
				};
				reader.readAsDataURL(event.target.files[0]);
				this.selectLogo = event.target.files[0];
			}
		},
		imagesUpload(event) {
			this.imgCnt = this.mainSrc.length;
			for (let i = 0; i < event.target.files.length; i++) {
				if (this.imgCnt === 3) break;
				let reader = new FileReader();
				reader.readAsDataURL(event.target.files[i]);
				reader.onload = e => {
					this.mainSrc.push(e.target.result);
				};
				this.selectMain.push(event.target.files[i]);
				this.imgCnt++;
			}
		},
		imageDel() {
			this.$refs.image.value = '';
			this.logoSrc = '';
			this.selectLogo = '';
		},
		imagesDel(index) {
			this.$refs.images.value = '';
			this.mainSrc.splice(index, 1);
			this.selectMain.splice(index, 1);
			this.originMain.splice(index, 1);
			this.imgCnt = this.mainSrc.length;
		},
		api(progress) {
			const formData = new FormData();
			// 기존 이미지
			for (let i = 0; i < this.originMain.length; i++) {
				formData.append('l_main[]', this.originMain[i]);
			}
			// 로고
			if (this.selectLogo) {
				formData.append('logo', this.selectLogo);
			}
			// 실제 추가 이미지
			for (let i = 0; i < this.selectMain.length; i++) {
				formData.append('main[]', this.selectMain[i]);
			}
			// 총 이미지 사이즈
			formData.append('main_size', this.mainSrc.length);

			if (this.logoSrc === '') {
				formData.append('l_logo', 'empty');
			} else {
				formData.append('l_logo', this.logoSrc);
			}

			showLoading();
			axios({
				method: 'POST',
				url: '/api/company/info/final',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then(response => {
					closeLoading();
					//console.log(response);
					if (response.data.code === 200) {
						if (progress === 'go') {
							location.href = '/company';
						} else if (progress === 'stay') {
							alert('임시저장 완료!');
							return false;
						}
					} else {
						alert('다시 확인해주세요!');
						return false;
					}
				})
				.catch(error => {
					closeLoading();
				});
		},
		tempSave() {
			this.api('stay');
		},
		submit() {
			if (this.logoSrc === '') {
				alert('로고 이미지를 등록해주세요!');
				return false;
			} else if (this.mainSrc.length < 1) {
				alert('대표 이미지를 등록해주세요!');
				return false;
			} else {
				this.api('go');
			}
		},
	},
};
</script>

<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
