<template>
	<div>
		<div
			v-for="(card, index) in applyCardListTrans"
			:key="index"
			class="content analytics"
			:class="doneApply"
			@click="goApplyDetail(card.apply_no)"
		>
			<div class="mb-10 status-wrapper">
				<div class="h7 text-caption">{{ card.status }}</div>
				<div class="h7 only-mobile">ㅣ</div>
				<div class="h7 text-caption">{{ card.date | dateFormat }} <span class="only-mobile">지원</span></div>
			</div>
			<div class="table-row flex">
				<div>
					<img
						class="com-logo"
						:src="
							display === 'biz'
								? $options.filters.randomImg(1, 30, 'profile')
								: bizBaseUrl + card.img_logo
						"
					/>
					<div class="text inline-block">
						<div v-if="display === 'mypage'" class="name h6" v-text="card.job"></div>

						<!--관심도 확인 이후 이름 신상 공개-->
						<template v-if="display !== 'mypage'">
							<div v-if="display === 'mypage'" class="name h6">{{ card.user_name }}</div>
							<div v-else-if="card.user_type === 1" class="name h6">{{ card.user_name }}</div>
							<div
								v-else
								class="name h6"
								v-text="
									card.isShowInfo || card.isFinishInterview
										? card.user_name
										: card.user_name.substring(0, 1) + '00'
								"
							></div>
						</template>

						<div class="position-column" v-text="card.name"></div>
					</div>
				</div>
				<div>
					<div class="message" :class="mobileClass">
						<div>{{ message(card) }}</div>
						<div v-if="display === 'mypage' && card.message_status > 0" class="flex flex-row">
							<img src="/images/email.svg" class="icon-mail" />
							기업에게 메시지가 도착했습니다.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['applyCardList', 'display', 'baseUrl', 'pmCompanyUserType'],
	data() {
		return {
			bizBaseUrl: 'https://d3oho2by66df6p.cloudfront.net/conect/',
			applyAutoInterest: null,
			userType: 1,
			isFinishInterview: false,
			isShowInfo: false,
			applyCardListTrans: [],
		};
	},
	computed: {
		doneApply() {
			if (
				this.applyCardList.state === 3 &&
				moment(this.applyCardList.date).add(7, 'days').isAfter(moment(this.applyCardList.date))
			) {
				return ' c-disabled';
			}
		},
		mobileClass() {
			if (this.isMobile) {
				return ' status-wrapper';
			}
		},
	},
	created() {
		this.applyCardList.forEach(item => {
			let isShowInfo = false;
			let isFinishInterview = false;

			//열린 채용일때
			if (this.pmCompanyUserType === 8 && item.state >= 3) {
				if (item.auto_interest) {
					if (item.auto_interest.interest === 1) isShowInfo = true;
				}
				if (item.interview) {
					if (item.interview.confirm > 0) isShowInfo = true;
				}
			}

			//일반 비즈일때 면접확정 이후
			if (this.pmCompanyUserType !== 8 && item.interview) {
				if (item.state >= 5 && item.interview.confirm > 0) {
					isFinishInterview = true;
				}
			}

			item.isShowInfo = isShowInfo;
			item.isFinishInterview = isFinishInterview;

			this.applyCardListTrans.push(item);
		});
	},
	mounted() {},
	methods: {
		message(card) {
			let title = '';
			this.userType = card.user_type;
			if (this.display === 'mypage') {
				//지원자
				//지원자 - 일반지원
				if (card.user_type === 1) {
					//일반지원
					if (card.state === 3) {
						//지원완료
						title = '기업에서 서류평가를 진행하고 있어요.';
					} else if (card.state === 3 && moment(card.date).add(7, 'days').isAfter(moment(card.date))) {
						//서류평가기한 초과
						title = '평가 기한초과로 전형이 종료되었어요.';
					} else if (card.state === 3 && card.view_resume > 0) {
						//기업담당자 지원서열람
						title = '기업에서 서류평가를 진행하고 있어요.';
					} else if (card.state === 5 && !card.interview) {
						//서류합격
						title = '서류합격';
					} else if (card.state === 4) {
						//서류불합격
						title = '전형이 종료되었어요.';
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview.confirm === 0
					) {
						//면접일정제안
						title = '면접일정을 확정하세요.';
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview.confirm === 1 &&
						!moment().isAfter(moment(card.interview.startdate))
					) {
						//면접일정확정
						title = '면접이 확정되었어요.';
					} else if (card.state === 13 && card.interview.manual === 1) {
						//면접 일정 - 기업이 직접 연락
						title = '면접일정 협의가 예정되어있어요.';
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview.confirm === 1 &&
						moment().isAfter(moment(card.interview.startdate))
					) {
						//면접결과입력
						title = '면접 결과를 기다리고 있어요.';
					} else if (card.state === 10) {
						//면접불합격
						title = '전형이 종료되었어요';
					} else if (card.state === 12) {
						//추가면접
						title = '기업이 면접일정 확정을 요청했어요.';
					} else if (card.state === 7) {
						//최종합격
						title = '최종합격을 축하드려요!';
					} else if (card.state === 8 || card.state === 11) {
						title = '전형이 종료되었어요.';
					}
				} else {
					//자동지원
					//지원자 - 자동지원
					if (card.state === 3 && (!card.auto_interest === null || card.auto_interest === undefined)) {
						//매칭완료
						//없음
					} else if (card.state === 3 && card.auto_interest.interest === 0) {
						//관심도확인 발송
						title = '기업이 전형진행 의사가 있는지 궁금해해요.';
					} else if (card.state === 3 && card.auto_interest.interest === 1) {
						//관심도확인 승낙
						title = '기업에게 후속 전형의사를 표시했어요.';
					} else if (card.state === 3 && card.auto_interest.interest === -1) {
						//관심도확인 거절
						title = '기업에게 후속 전형의사를 표시했어요.';
					} else if (card.state === 5) {
						//바로면접제안
						title = '기업이 면접을 진행하고 싶어해요.';
					}
				}
			}
			//기업(biz)
			else {
				//기업 - 일반지원
				if (card.user_type === 1) {
					//일반지원
					if (card.state === 3) {
						//지원완료
						title = '서류평가 후 결과를 입력하세요.';
					} else if (card.state === 3 && moment(card.date).add(7, 'days').isAfter(moment(card.date))) {
						//서류평가기한 초과
						//없음
						if (this.pmCompanyUserType === 8) {
							title = '서류평가 후 결과를 입력하세요.';
						}
					} else if (card.state === 3 && card.view_resume > 0) {
						//기업담당자 지원서열람
						title = '서류평가 후 결과를 입력하세요.';
					} else if (card.state === 5 && (!card.interview || card.interview.confirm === -1)) {
						//서류합격
						title = '면접을 제안하세요.';
					} else if (card.state === 4) {
						//서류불합격
						//없음
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview.confirm === 0
					) {
						//면접일정제안
						title = '지원자가 면접일정 검토중이에요.';
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview.confirm === 1 &&
						!moment().isAfter(moment(card.interview.startdate))
					) {
						//면접일정확정
						title = '면접이 확정되었어요.';
					} else if (card.state === 13 && card.interview.manual === 1) {
						//면접 일정 - 기업이 직접 연락
						title = '면접을 확정하세요.';
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview.confirm === 1 &&
						moment().isAfter(moment(card.interview.startdate))
					) {
						//면접결과입력
						title = '면접 결과를 입력하세요.';
					} else if (card.state === 10) {
						//면접불합격
						//없음
					} else if (card.state === 12) {
						//추가면접
						title = '지원자가 면접일정을 확정중이에요.';
					} else if (card.state === 7 && !card.pass_date) {
						//최종합격
						title = '입사일을 확정하세요.';
					} else if (card.state === 8 || card.state === 11 || (card.state === 7 && card.pass_date)) {
						title = '전형이 종료되었어요.';
					}
				} else {
					//기업 - 자동지원
					if (card.state === 3 && !card.auto_interest) {
						//매칭완료
						title = '검색된 인재에게 면접을 제안할 수 있어요.';
					} else if (card.state === 3 && card.auto_interest && card.auto_interest.interest === 0) {
						//관심도확인 발송
						title = '관심도 확인이 요청되었어요.';
					} else if (card.state === 3 && card.auto_interest && card.auto_interest.interest === 1) {
						//관심도확인 승낙
						title = '지원자가 기업에 관심을 표현했어요.';
					} else if (card.state === 3 && card.auto_interest && card.auto_interest.interest === 2) {
						//관심도확인 거절
						//없음
					} else if (
						card.state === 5 &&
						card.auto_interest &&
						card.auto_interest.interest === 1 &&
						!card.interview
					) {
						//서류합격
						title = '면접을 제안하세요.';
					} else if (card.state === 4) {
						//서류불합격
						//없음
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview &&
						card.interview.confirm <= 0 &&
						card.interview.confirm > -2
					) {
						//면접일정제안
						title = '지원자가 면접일정 검토중이에요.';
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview.confirm === 1 &&
						!moment().isAfter(moment(card.interview.startdate))
					) {
						//면접일정확정
						title = '면접이 확정되었어요.';
					} else if (card.state === 13 && card.interview.manual === 1) {
						//면접 일정 - 기업이 직접 연락
						title = '면접을 확정하세요.';
					} else if (
						(card.state === 5 || card.state === 12 || card.state === 13) &&
						card.interview.confirm === 1 &&
						moment().isAfter(moment(card.interview.startdate))
					) {
						//면접결과입력
						title = '면접 결과를 입력하세요.';
					} else if (card.state === 10) {
						//면접불합격
						//없음
					} else if (card.state === 12) {
						//추가면접
						title = '지원자가 면접일정을 확정중이에요.';
					} else if (card.state === 7 && !card.pass_date) {
						//최종합격
						title = '입사일을 확정하세요.';
					} else if (card.state === 8 || card.state === 11 || (card.state === 7 && card.pass_date)) {
						title = '전형이 종료되었어요.';
					}
				}
			}
			return title;
		},

		goApplyDetail(applyNo) {
			let data = { applyCardList: this.applyCardList, userType: this.userType };
			if (this.display === 'mypage') {
				history.replaceState(data, this.display, '/mypage/apply' + '#!' + applyNo);
				window.location.href = '/mypage/apply/detail?apply_no=' + applyNo;
			} else {
				if (
					this.applyCardList.state === 3 &&
					moment(this.applyCardList.date).add(7, 'days').isAfter(moment(this.applyCardList.date))
				) {
					return false;
				} else {
					// history.replaceState(this.applyCardList, this.display, '/applicant/' + "#!" + applyNo);
					window.location.href = '/applicant/detail/' + applyNo;
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
@border-color: #dee2e6;
@normal-color: #495057;
@h7-color: #9ea5ab;
@primary-color: #2a7de1;

.content {
	margin-bottom: 10px;
	&:hover,
	&:focus {
		border-color: @primary-color;
	}
}

.table-row {
	padding: 0;
	border: 0;
	justify-content: space-between;

	.position-column {
		width: auto !important;
	}

	.message {
		position: relative;
		right: 0;
		text-align: right;

		div {
			display: block;
		}
	}
}

.status-wrapper {
	margin-left: 60px;
	display: flex;
	justify-content: space-between;
}

.icon-mail {
	width: 15px;
	height: 10px;
	margin-top: 8px;
	margin-right: 5px;
}

@media (max-width: 480px) {
	.border-box-wrapper {
		margin-bottom: 20px;
	}

	.border-box {
		&:first-child {
			margin-right: 10px;
		}
	}

	.content.analytics {
		padding: 20px 0;
		border-top-color: @normal-color;
	}

	.status-wrapper {
		margin-left: 45px;
		justify-content: normal;
	}

	.table-row {
		flex-direction: column;

		div {
			width: 100%;
			display: block;
			justify-content: normal;
		}

		.position-column {
			padding-top: 0;
		}

		.com-logo {
			width: 32px;
			height: 32px;
			float: left;
		}

		.message {
			text-align: left;
			font-size: 13px;
		}
	}
}
</style>
