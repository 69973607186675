<template>
	<modal-component :show="show" @close="close">
		<div class="hidden-box-remove"></div>
		<div id="confirm-component" class="modal-body text-center">
			<div class="mb-15">
				<span class="popup-title text-center h4" style="margin-left: 25px">이력서 초안 생성</span>
			</div>
			<div class="text-heading mb-20">
				<p style="color: #212529; word-break: break-word">
					튜토리얼을 종료하고 이력서 초안을 생성합니다.<br />
					제출 후에는 다시 튜토리얼로 돌아올 수 없습니다.<br />
					계속 진행하시겠습니까?
				</p>
			</div>
			<div class="mt-20 flex flex-row" style="display: inline-block">
				<button class="btn mr-5" @click="save">확인</button>
				<button class="btn btn-empty" @click="close">취소</button>
			</div>
		</div>
	</modal-component>
</template>

<script>
export default {
	props: ['show', 'title', 'message', 'saveButtonName', 'closeButtonName'],
	methods: {
		close() {
			this.$emit('close');
		},
		save() {
			this.$emit('save');
			this.$emit('close');
		},
	},
};
</script>
