const answerMixin = {
	data() {
		return {
			isShowAnswerSuccessModal: false,
			answerShowThanksModal: false,
			userInfo: null,
		};
	},
	methods: {
		$_goAfterAnswerPost() {
			this.isShowAnswerSuccessModal = true;
		},
		$_openThanksModal() {
			this.showThanksModal = true;
		},
		$_closeThanksModal() {
			this.showThanksModal = false;
		},
		$_goRequestList() {
			window.location.href = '/answer';
		},
		$_thanksModalCallback() {
			this.goRequestList();
		},
		async $_getUserInfo() {
			await axios({
				method: 'GET',
				url: '/api/answer/userInfo',
			})
				.then(
					({
						data: {
							credit,
							mentee_count,
							mentoring_count,
							relation_tags,
							relation_tags_open,
							relation_user,
						},
					}) => {
						this.userInfo = {
							credit,
							mentee_count,
							mentoring_count,
							relation_tags,
							relation_tags_open,
							relation_user,
						};
					},
				)
				.catch(error => {
					console.log(error);
				});
		},
		async $_hasMatchingKeyword(keyword) {
			try {
				await this.$_getUserInfo();

				const matchKeywords = this.userInfo.relation_tags;
				if (matchKeywords.length > 0) {
					return matchKeywords.includes(keyword);
				} else {
					return false;
				}
			} catch (error) {
				return false;
			}
		},
	},
};

export default answerMixin;
