<template>
	<div>
		<biz-side-component v-if="parseInt(guest) !== 1" num="4"></biz-side-component>
		<section class="section section-left" :class="parseInt(guest) !== 1 ? '' : 'guestLayout'">
			<div class="container text-left">
				<section class="mb-20">
					<div class="flex flex-row flex-between">
						<div class="font-title-big f-semi-bold mb-10">지원자 관리</div>
						<!--<div class="btn share-btn" @click="share" v-if="selected!=='none' && parseInt(guest) !== 1">공유</div>-->
					</div>
					<div class="h6 f-regular mb-10">채용중인 직무를 선택하고, 지원자에 대한 결과를 입력해주세요.</div>
					<div class="h7 text-sub">
						*현재 1명 이상의 지원자가 있는 직무만 표시됩니다.
						<br class="only-mobile" />(지원자 0명인 직무는 표시되지 않음)
					</div>
				</section>
				<section>
					<div class="well">
						<div class="body mr-20">채용중인 직무</div>
						<select
							v-model="selected"
							class="category float-left hasCustomSelect"
							:disabled="parseInt(guest) !== 1 ? false : true"
						>
							<option class="jobsector" value="none">직무 선택</option>
							<option
								v-for="(option, index) in comInfo"
								:key="option.no"
								class="jobsector"
								:value="option.no"
							>
								{{ option.job }}
							</option>
						</select>
					</div>
				</section>
				<template v-if="selected !== 'none'">
					<section class="mb-30">
						<template v-if="applyLog.length > 0">
							<div class="h4 mb-10">지원자 : {{ applyLog.length }}명</div>
							<ApplyCardComponent
								class="cursor-pointer"
								:apply-card-list.sync="applyLog"
								display="biz"
								:base-url="baseUrl"
								:pm-company-user-type="pmCompanyUserType"
							></ApplyCardComponent>
						</template>
						<template v-else>
							<div class="h4 mb-10">지원자 : {{ applyLog.length }}명</div>
							<div class="bg-white border pd-30 text-center">
								<img class="img-logo" src="/images/robo_recruiter.svg" />
								<div class="text-caption font">매칭을 진행 중입니다.</div>
								<div class="text-caption f-light font-caption">
									<div>기업이 찾고있는 딱 맞는 인재를</div>
									<div>곧 만나실 수 있습니다.</div>
								</div>
							</div>
						</template>
					</section>

					<section>
						<template v-if="aiLog.length > 0">
							<div class="h4 mb-10">AI 인재검색 : {{ aiLog.length }}명</div>
							<ApplyCardComponent
								class="cursor-pointer"
								:apply-card-list.sync="aiLog"
								display="biz"
								:base-url="baseUrl"
								:pm-company-user-type="pmCompanyUserType"
							></ApplyCardComponent>
						</template>
						<template v-else>
							<div class="h4 mb-10">AI 인재검색 : {{ aiLog.length }}명</div>
							<div class="bg-white border pd-30 text-center">
								<img class="img-logo" src="/images/robo_recruiter.svg" />
								<div class="text-caption">매칭을 진행 중입니다.</div>
								<div class="text-caption f-light font-caption">
									<div>기업이 찾고있는 딱 맞는 인재를</div>
									<div>곧 만나실 수 있습니다.</div>
								</div>
							</div>
						</template>
					</section>
				</template>
				<section v-else>
					<div class="h4 mb-10">지원자 : 0명</div>
					<div class="bg-white border pd-30 text-center">
						<img class="img-logo" src="/images/empty.svg" />
						<div class="text-caption font">조회하려는 직무를 선택하세요.</div>
					</div>
				</section>
			</div>
		</section>
		<biz-pincode-component
			v-if="comInfo.length > 0"
			:show="show"
			type=""
			:companyNo="comInfo[0].no"
			@close="show = false"
		></biz-pincode-component>
	</div>
</template>

<script>
import ApplyCardComponent from '../../../../js/components/_common/apply/ApplyCardComponent.vue';

export default {
	props: ['comInfo', 'comNo', 'baseUrl', 'guest', 'pmCompanyUserType'],
	data() {
		return {
			show: false,
			selected: 'none',
			companyNo: '',
			applyLog: [],
			aiLog: [],
		};
	},
	watch: {
		selected() {
			this.applyLog = [];
			this.aiLog = [];
			this.getApplyList();
		},
	},
	created() {
		this.$nextTick(() => {
			if (this.comInfo.length > 0 && this.comNo > 0) {
				this.selected = this.comNo;
			}
		});
	},
	methods: {
		// selectJob(){
		//     if(this.selected!=='none') {
		//         if(this.selected!==this.com_no) {
		//             location.href = '/applicant/' + this.selected;
		//         }
		//     }
		// },
		share() {
			//핀코드
			this.show = true;
		},
		getApplyList() {
			//지원자 관리
			axios.interceptors.request.use(
				config => {
					// Do something before request is sent
					$('.blinder2').css('display', 'block');
					$('.loading').css('display', 'block');
					return config;
				},
				error => {
					// Do something with request error
					return Promise.reject(error);
				},
			);

			// Add a response interceptor
			axios.interceptors.response.use(
				response => {
					// Do something with response data
					$('.blinder2').css('display', 'none');
					$('.loading').css('display', 'none');
					return response;
				},
				error => {
					// Do something with response error
					return Promise.reject(error);
				},
			);

			axios({
				method: 'GET',
				url: '/api/apply/list',
				params: {
					com_no: this.selected,
				},
			})
				.then(response => {
					closeLoading();
					//console.log(response);
					if (response.data.code === 200) {
						response.data.list.forEach(element => {
							if (element.user_type === 1) {
								this.applyLog.push(element);
								// location.reload();
							} else if (element.user_type === 5) {
								this.aiLog.push(element);
							}
						}, this);
						this.applyLog = _.sortBy(this.applyLog, 'status_key').reverse();
						this.aiLog = _.sortBy(this.aiLog, 'status_key').reverse();
						// change url for location (backward)
						history.replaceState(null, null, '/applicant/' + this.selected);
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
	},
	components: {
		ApplyCardComponent,
	},
};
</script>

<style lang="less" scoped>
.hasCustomSelect {
	visibility: visible;
	color: rgb(73, 80, 87);
	width: 100%;
}
.well {
	display: flex;
	flex-direction: row;
	align-items: center;
	.body {
		width: 105px;
	}
	@media (max-width: 480px) {
		padding: 0;
		flex-direction: column;
		background: #fff;
		align-items: flex-start;
		.body {
			margin-bottom: 5px;
		}
	}
}
.share-btn {
	background: #adb5bd;
	border: none;
	width: 75px;
	line-height: 36px;
	height: 36px;
	position: relative;
	top: 80px;
}
@media (max-width: 480px) {
	.share-btn {
		top: 5px;
	}
}
.img-logo {
	width: 64px;
	margin-bottom: 5px;
	vertical-align: middle;
}
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
