<template>
	<c-fullscreen-modal class="noti-fullscreen-modal" v-bind="$attrs" v-on="$listeners">
		<template v-slot:title>알림</template>
		<template v-slot:action>
			<NotiReadAllButton @read-all="emitReadAll" />
		</template>
		<template v-slot:content>
			<slot />
		</template>
	</c-fullscreen-modal>
</template>

<script>
import NotiReadAllButton from './NotiReadAllButton.vue';

export default {
	name: 'NotiFullscreenModal',
	methods: {
		emitReadAll() {
			this.$emit('read-all');
		},
	},
	components: { NotiReadAllButton },
};
</script>

<style lang="scss" scoped>
.noti-fullscreen-modal {
	z-index: 100;
}
</style>
