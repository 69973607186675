<template>
	<GridLayout :grid-fluid="isMobile" grid-color="white">
		<c-new-col>
			<section :class="{ 'pt-50': !isMobile }">
				<GridLayout :grid-fluid="!isMobile">
					<c-new-col
						:col-lg="questionColumn"
						class="question-container answer-interaction"
						:class="{ 'bg-white': isMobile }"
					>
						<div
							v-for="(detail, index) in detailList"
							:key="`deatil-${index}`"
							class="box essay-box body"
							:class="{ 'px-8': isMobile }"
						>
							<div class="feedback-info content c-default essay">
								<div class="info-wrapper border text-truncate">
									<span
										class="text-primary border-right two-line-option"
										v-html="detail.company"
									></span>
									<span v-html="detail.job"></span>
								</div>
								<!--  end .info-wrapper -->
								<div class="body-wrapper mb-10">
									<p
										v-if="detail.other_inf !== ''"
										class="mb-10"
										v-html="$options.filters.nl2br(detail.other_inf)"
									></p>
									<div class="flex flex-row flex-v-middle mt-10">
										<div class="flex flex-row flex-v-middle border-right two-line-option">
											<img
												class="anonymous profile answer-profile"
												alt="코멘토 프로필 이미지"
												src="/images/my_anonymous_20.svg"
											/>
											<!-- 0: 비공개, 1: 공개 -->
											<p v-if="parseInt(detail.info_display) === 1" class="indent-profile h7">
												<span>{{ detail.univ }} {{ detail.major }}</span>
											</p>
											<p v-else class="indent-profile h7">
												<span>비공개 학교 {{ detail.major }}</span>
											</p>
										</div>
										<div class="h7 text-caption ml-5">{{ detail.time | dateFormat }}</div>
									</div>
								</div>
							</div>
							<!-- end .feedback-info -->
							<div
								class="content c-default pd-30 feedback-content essay"
								oncopy="return false;"
								@contextmenu="protect"
								@copy="protect"
							>
								<div class="watermark">{{ email }}</div>
								<div class="info-wrapper border mb-10">
									<h6>{{ detail.question }}</h6>
								</div>
								<div
									v-linkified:options="{ className: 'text-link' }"
									class="answer mb-20"
									v-html="$options.filters.nl2br(detail.answer)"
								></div>
								<p class="small">{{ detail.length | numberFormat }}자</p>
							</div>
						</div>
					</c-new-col>

					<c-new-col :col-lg="answerColumn" class="answer-interaction">
						<div class="default-box text-center mb-20 py-36">
							<c-typography type="headline7" :font-weight="500" color="gray900" class="mb-12">
								1. 첫인상은 몇점인가요?
							</c-typography>
							<div style="display: block">
								<c-icon
									v-for="(star, index) in stars"
									:key="`star-${index}`"
									:name="`IconStar4XLarge${star}`"
									color="yellow"
									@click="changeScore(index, 'click')"
									@mouseover="changeScore(index, 'over')"
									@mouseout="changeScore(index, 'out')"
								/>
								<div class="score">
									<c-typography type="body1" :font-weight="500" color="gray800">
										{{ score }}점
									</c-typography>
								</div>
							</div>
						</div>
						<div class="default-box text-center mb-20">
							<c-typography type="headline7" :font-weight="500" color="gray900" class="mb-12">
								2. 어떤 역량이 엿보이나요?
							</c-typography>
							<div>
								<c-select
									v-model="selectedStrength"
									placeholder="역량 선택"
									:options="strength.map(item => item.strength)"
									size="medium"
									max-height="140px"
									full
									class="mb-12"
								/>
								<div class="text-right">
									<c-narrow-button
										v-if="!selectedStrength"
										size="small"
										class="ml-auto"
										@click="showStrengthModal = true"
									>
										<template v-slot:left-icon>
											<c-icon name="IconSearchXSmallLine" color="primary" />
										</template>
										역량 설명보기
									</c-narrow-button>
									<div v-else class="flex flex-row justify-between">
										<c-typography type="caption1" color="gray800" element="span">
											<strong>{{ strengthKeyword.join(', ') }}</strong> 역량
										</c-typography>
										<c-narrow-button size="small" @click="showStrengthModal = true">
											자세히
										</c-narrow-button>
									</div>
								</div>
							</div>
						</div>
						<div id="feedback" class="default-box text-center mb-8">
							<c-typography type="headline7" :font-weight="500" color="gray900" class="mb-8">
								3. 어떤 부분을 보완하면 좋을까요?
							</c-typography>
							<c-typography type="caption1" :font-weight="400" color="gray600" class="mb-12">
								* 맞춤법 교정은 인공지능이 자동으로 제공합니다.
							</c-typography>
							<c-select
								v-model="selectedFeedbackPoint"
								placeholder="피드백 포인트 선택"
								:options="feedbackPointOptions"
								size="medium"
								max-height="140px"
								full
								class="mb-12"
							/>
							<!-- tip box -->
							<AnswerTipBox
								v-if="detailList.length > 0"
								:show="isShowFeedbackTip"
								title="채택률이 올라가는 첨삭 작성 Tip!"
								border-radius="10px"
								:item="feedbackTip"
								@close="isShowFeedbackTip = false"
							/>

							<textarea
								ref="feedback"
								v-model="answer"
								v-autoresize
								class="textbox essay-text body-margin flex"
								rows="4"
								placeholder="멘티님이 보완해야 할 부분을 구체적으로 알려주세요. 구체적인 멘토링 포인트가 없는 단순/칭찬 응원글은 오히려 멘티님의 구직 준비에 방해가 될 수 있습니다."
								style="border: 0"
								:style="{ 'min-height': feedbackHeight }"
								@focus="focusEssay"
								@blur="blurEssay"
								@copy="protect"
								@paste="protect"
							/>
							<c-divider type="dash" class="mb-12" />
							<AnswerCountSection :count="answer.length" />
						</div>
						<!--						<div class="p-relative flex flex-row align-center justify-between py-10 mb-8">-->
						<!--							<c-checkbox :value.sync="greeting">-->
						<!--								<c-typography type="body1" :font-weight="400" color="gray500">-->
						<!--									응원 메시지 추가-->
						<!--								</c-typography>-->
						<!--							</c-checkbox>-->
						<!--							<c-icon-button icon-name="IconSettingXSmallFill" color="gray600" @click="viewModal" />-->
						<!--						</div>-->

						<c-button
							size="large"
							full
							:style="{ 'margin-bottom': isMobile ? '20px' : '110px' }"
							@click="saveFeedback"
						>
							답변하기
						</c-button>
					</c-new-col>
				</GridLayout>

				<!--				<AnswerGreetingModal-->
				<!--					:show="showGreetingModal"-->
				<!--					:preface.sync="preface"-->
				<!--					:footer.sync="footer"-->
				<!--					@close="showGreetingModal = false"-->
				<!--					@save="saveGreeting"-->
				<!--				/>-->
				<alert-modal-component
					:show="showAlert"
					:title="title"
					:message="message"
					:buttonName="buttonName"
					@close="showAlert = false"
				/>
				<EssayStrengthComponent
					class="only-pc"
					:show="showStrengthModal"
					:selected-strength="selectedStrength"
					:strength-description-list="strengthDescriptionList"
					@close="showStrengthModal = false"
				/>
				<MobileEssayStrengthComponent
					class="only-mobile"
					:show="showStrengthModal"
					:selected-strength="selectedStrength"
					:strength-description-list="strengthDescriptionList"
					@close="showStrengthModal = false"
				/>
				<AnswerDetailThanksModal
					:show.sync="answerShowThanksModal"
					@success-callback="$_goRequestList"
					@close="$_closeThanksModal"
				/>
			</section>
		</c-new-col>
	</GridLayout>
</template>

<script>
import EssayStrengthComponent from '@/components/_modal/essay/EssayStrengthComponent.vue';
import MobileEssayStrengthComponent from '@/components/_modal/essay/MobileEssayStrengthComponent.vue';
// import AnswerGreetingModal from '@/components/_modal/answer/AnswerGreetingModal.vue';
import caseMixin from '@/mixins/case/caseMixin';
import { CASE_CATEGORY_GTM_MAP } from '@/constants';

import answerMixin from '@/mixins/answerMixin';
import GridLayout from '@/components/common/GridLayout.vue';
import AnswerTipBox from '@/components/answer/AnswerTipBox.vue';
import AnswerCountSection from '@/components/answer/AnswerCountSection.vue';
import { mapGetters, mapMutations } from 'vuex';
import AnswerDetailThanksModal from '@/components/answer/AnswerDetailThanksModal.vue';

export default {
	mixins: [caseMixin, answerMixin],
	props: ['no', 'type', 'userNo', 'userType', 'email', 'userBelong', 'sid', 'userLevel'],
	data: () => ({
		// showGreetingModal: false,
		detailList: [],
		stars: ['Line', 'Line', 'Line', 'Line', 'Line'],
		score: 0, //별점
		selectedStrength: null,
		selectedFeedbackPoint: null,
		feedbackPoint: [
			{ value: '[글쓰기 스킬] 글 자체를 매끄럽게 수정할 필요가 있습니다', text: '글을 매끄럽게 다듬어보세요' },
			{ value: '[주제/소재] 주제/소재를 적절하게 수정이 필요합니다', text: '주제/소재를 수정해보세요' },
			{
				value: '[회사/직무 연계성] 회사/직무를 고려하여 수정이 필요합니다',
				text: '회사/직무 연계성을 높여보세요',
			},
			{ value: '[기타] 아래를 참고하여 수정이 필요합니다', text: '기타 피드백' },
		],
		answer: '', //피드백
		greeting: false, //응원 메시지 추가,
		preface: '', //응원메시지 머릿말
		footer: '', //응원메시지 꼬리말
		finishFlag: false,
		feedbackCount: 0,
		showAlert: false,
		title: '',
		message: '',
		buttonName: '',
		// successCallback: '',
		feedbackHeight: '320px',
		strength: [],
		strengthDescriptionList: [],
		strengthKeyword: [],
		showStrengthModal: false,
		questionColumn: 8,
		answerColumn: 4,
		isShowFeedbackTip: true,
		feedbackTip: [
			'칭찬과 격려 외에, 아쉬운 점도 꼭 짚어주세요.',
			'구체적인 개선 방향과 예시, 이유나 맥락을 함께 짚어주세요.',
			'가독성과 구성, 정합성, 어휘 등 여러 방면으로 살펴봐주세요. ',
		],
		answerCorrespondence: false,
	}),
	computed: {
		...mapGetters(['userNickname']),
		essayDetailUrl() {
			return `/mine/essay?request_no=${this.no}`;
		},
		feedbackPointOptions() {
			return this.feedbackPoint.map(item => {
				return { label: item.text, value: item.value };
			});
		},
	},
	watch: {
		selectedStrength() {
			if (this.selectedStrength) {
				for (let i = 0; i < this.strengthDescriptionList.length; i++) {
					if (this.selectedStrength === this.strengthDescriptionList[i].strength) {
						this.strengthKeyword = this.strengthDescriptionList[i].keyword;
					}
				}
			} else {
				this.strengthKeyword = [];
			}
		},
	},
	created() {
		this.$nextTick(() => {
			window.scrollTo(0, 0);
			this.getStrength();
			this.getFeedback();
			this.getContents();
			// this.getGreeting();
			this.setStrengthDescription();
		});
	},
	mounted() {
		this.setMeta({
			fluid: true,
			bgColor: ['white', 'white'],
			isShowBottomNav: false,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		getStrength() {
			axios({
				method: 'GET',
				url: '/api/answer/getStrength',
			})
				.then(({ data }) => {
					// console.log(data);
					this.strength.push(...data.strength);
				})
				.catch(error => {
					console.log(error);
				});
		},
		changeScore(index, action) {
			//별점
			this.stars.splice(0, this.stars.length); //초기화
			let score = index;
			if (action === 'out' && this.score > 0) score = this.score - 1;

			if (action === 'out' && this.score === 0) {
				this.stars.push('Line', 'Line', 'Line', 'Line', 'Line');
				return;
			}
			for (let i = 0; i < 5; i++) {
				if (i <= score) {
					this.stars.push('Fill');
				} else {
					this.stars.push('Line');
				}
			}
			if (action === 'click') this.score = index + 1; //점수
		},
		// viewModal() {
		// 	//응원메시지 팝업
		// 	this.showGreetingModal = true;
		// },
		protect(event) {
			event.preventDefault();
			this.title = '';
			this.message = '복사/붙여넣기 방지를 위해 Ctrl Key 사용을 제한합니다.';
			this.buttonName = '';
			this.successCallback = '';
			this.showAlert = true;
		},
		focusEssay() {
			this.$nextTick(() => {
				if (!this.isMobile) {
					this.questionColumn = 4;
					this.answerColumn = 8;
					this.feedbackHeight = '410px';
				}
			});
		},
		blurEssay() {
			this.$nextTick(() => {
				if (!this.isMobile) {
					this.questionColumn = 8;
					this.answerColumn = 4;
					this.feedbackHeight = '260px';
				}
			});
		},
		focusTo(ref) {
			this.$refs[ref].focus();
		},
		// saveGreeting() {
		// 	axios({
		// 		method: 'POST',
		// 		url: '/api/mypage/postGreeting',
		// 		data: {
		// 			msg_preface: this.preface,
		// 			msg_footer: this.footer,
		// 		},
		// 	})
		// 		.then(({ data }) => {
		// 			if (data.code === 8019200) {
		// 				//저장완료
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 		});
		// },
		async saveFeedback() {
			if (this.score === 0) {
				this.title = '';
				this.message = '첫인상 점수를 별점으로 표시해주세요.';
				this.buttonName = '';
				// this.successCallback = '';
				this.showAlert = true;
				return false;
			}
			if (this.isEmptyData(this.selectedFeedbackPoint)) {
				this.title = '';
				this.message = '피드백 포인트를 선택해주세요.';
				this.buttonName = '';
				// this.successCallback = '';
				this.showAlert = true;
				return false;
			}
			if (this.answer === '') {
				this.title = '';
				this.message = '피드백을 작성해주세요.';
				this.buttonName = '';
				// this.successCallback = '';
				this.showAlert = true;

				return false;
			}

			showLoading();

			try {
				const { data } = await axios({
					method: 'POST',
					url: '/api/answer/essayFeedBack',
					data: {
						request_no: this.no,
						mentor_no: this.userNo,
						score: this.score,
						choice: this.selectedFeedbackPoint,
						strength: this.selectedStrength,
						answer: this.answer,
						greeting: this.greeting,
						preface: this.preface,
						footer: this.footer,
					},
				});

				// 커뮤니티 차단
				if (data.code === 403) {
					Toast.show({
						message: data.msg,
						type: 'error',
						position: this.isMobile ? 'bottom' : 'top',
					});
					return;
				}
				const isPostSuccess = data.code === 200;
				if (isPostSuccess) {
					let cor = data.cor;
					const category = CASE_CATEGORY_GTM_MAP[this.detailList[0].mentoring_category_group_id];
					let info = {
						user_no: this.userNo,
						user_id: this.email,
						user_type: this.userType,
						user_level: this.userLevel,
						req_no: this.no,
						req_type: 'ce',
						cor_com: cor.cor_com,
						cor_in: cor.cor_in,
						cor_job: cor.cor_job,
						cor_univ: cor.cor_univ,
						cor_interest: this.$_hasMatchingKeyword(this.detailList[0].job) ? 'Y' : 'N',
						where: '',
						category: this.$_getCaseGTMCategory(category),
					};

					// GTM - leaveAnswer
					leaveAnswer(info);
					if (
						typeof this.answerCorrespondence === 'string' &&
						this.answerCorrespondence.toLowerCase() === 'true'
					) {
						// GTM - leaveAnswerWithCorrespondence
						leaveAnswerWithCorrespondence();
					}
					this.answerShowThanksModal = true;
					this.$emit('submit-essay-feedback', this.goDetailUrl());
				} else {
					this.title = '오류';
					this.message = '에러가 발생했습니다.';
					this.buttonName = '';
					this.successCallback = '';
					this.showAlert = true;
				}
			} catch (error) {
				console.log(error);
				this.title = '오류';
				this.message = '에러가 발생했습니다.';
				this.buttonName = '';
				this.successCallback = '';
				this.showAlert = true;
			} finally {
				closeLoading();
			}
		},

		getFeedback() {
			axios({
				method: 'POST',
				url: '/api/answer/getFeedback',
				data: {
					request_no: this.no,
					request_type: 'ce',
					display_type: 'feedback',
				},
			})
				.then(({ data }) => {
					// console.log(data);
					if (data.code === 200) {
						this.feedbackCount = data.result.length;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		getContents() {
			axios({
				//자소서 내용
				method: 'POST',
				url: '/api/answer/getContents',
				data: {
					request_no: this.no,
					rj_no: '', //직무소개
				},
			})
				.then(({ data }) => {
					// console.log(data);
					if (data.code === 200) {
						this.detailList.push({
							...data.result,
							mentoring_category_group_id: data.mentoring_category_group_id,
						});

						if (data.result.free_flag === 0) {
							//회사명 일치여부와 상관없이 무료 면접은 답변 1개 이상이면 답변 불가
							if (this.feedbackCount >= 1) this.finishFlag = true;
						} else if (data.result.free_flag === 1) {
							//회사명과 지원회사가 다르고 이미 답변이 3개 이상이면 못하게
							if (this.userBelong !== data.result.company) {
								if (this.feedbackCount >= 3) this.finishFlag = true;
							} else {
								if (this.feedbackCount >= 4) this.finishFlag = true;
							}
						}
						if (!this.finishFlag) {
							let cor = data.cor;
							let info = {
								user_no: this.userNo,
								user_type: this.userType,
								email: this.email,
								user_level: this.userLevel,
								req_no: this.no,
								req_type: this.type,
								cor_com: cor.cor_com,
								cor_in: cor.cor_in,
								cor_job: cor.cor_job,
								cor_univ: cor.cor_univ,
								where: '',
							};
							// GTM - viewRequest
							viewRequest(info);
							// GTM - GAVirtualPageview
							GAVirtualPageview(this.$route.path);
							this.getMatchingInfo();
						}
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		// getGreeting() {
		// 	axios({
		// 		//응원메시지 가져오기
		// 		method: 'GET',
		// 		url: '/api/mypage/getGreeting',
		// 	})
		// 		.then(({ data }) => {
		// 			if (data.code === 200) {
		// 				if (typeof data.result[0] !== 'undefined') {
		// 					this.preface = data.result[0].msg_preface;
		// 					this.footer = data.result[0].msg_footer;
		// 				}
		//
		// 				//응원 메시지가 있으면 체크
		// 				if (this.preface || this.footer) this.greeting = true;
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 		});
		// },
		// laterFeedback() { //이따하기
		//     axios({
		//         method: 'POST',
		//         url: '/api/answer/postLater',
		//         data: {
		//             request_no: this.no
		//         }
		//     }).then(({data}) => {
		//         if(data.code === 200){
		//             window.location.href = '/answer';
		//         } else {
		//             alert('에러가 발생하였습니다.');
		//         }
		//     }).catch(error => {
		//         //console.log(error);
		//     });
		// },
		setStrengthDescription() {
			this.strengthDescriptionList = [
				{
					strength: '주인의식',
					keyword: ['소속감', '책임감', '완수'],
					description: '조직 구성원으로서 소속감을 갖고 자신에게 주어진 업무를 충실하게 완수해 내는 역량',
				},
				{
					strength: '도전정신',
					keyword: ['새로운 시도', '기회 추구'],
					description:
						'실패를 두려워하지 않고 새로운 시도를 통해 기회를 추구하면서 적극적으로 일을 추진하는 역량',
				},
				{
					strength: '성취지향',
					keyword: ['높은 목표', '목표 달성'],
					description: '높은 목표를 설정하고 끈기 있게 추진하여 목표를 달성하는 역량',
				},
				{
					strength: '주도성',
					keyword: ['자발성', '확장성'],
					description:
						'주어진 상황에서 자발적으로 해야 할 업무를 찾아 수행하고, 주어진 업무를 확장하여 수행하는 역량',
				},
				{
					strength: '팀워크',
					keyword: ['공동 목표 추구', '협력'],
					description:
						'팀의 구성원으로서 팀 공동의 목표를 달성하기 위해 자신의 역할 및 책임에 따라 협력하는 행동 역량',
				},
				{
					strength: '문제해결',
					keyword: ['문제인식', '대안탐색', '문제처리'],
					description:
						'업무에서 발생하는 문제를 인식하고, 원인 분석과 대안 탐색을 통해 최적의 방안으로 문제를 처리하는 역량',
				},
				{
					strength: '분석적사고',
					keyword: ['문제분석', '개념화'],
					description:
						'어떤 상황이나 문제를 세부화하여 이해하거나, 함축하고 있는 의미를 단계적, 인과론적으로 접근하여 이해하는 역량',
				},
				{
					strength: '전문성',
					keyword: ['필요한 지식 인식', '학습', '자기능력개발'],
					description:
						'업무의 전문성을 높이기 위해 필요한 지식을 인식하고 학습하여 지속적으로 자기개발을 하는 역량',
				},
				{
					strength: '치밀성',
					keyword: ['정확성', '치밀함'],
					description: '자신의 업무를 철저하게 확인하고 수행하여 정확하게 완수해내는 역량',
				},
				{
					strength: '의사소통',
					keyword: ['의사표현', '경청'],
					description:
						'다양한 상황에서 자신의 의사를 구두 또는 문서를 통해 효과적으로 표현하고, 다른 사람의 의견을 경청하여 내용을 종합할 수 있는 역량',
				},
				{
					strength: '대인관계',
					keyword: ['타인이해', '참여와 협조 유도'],
					description:
						'다른 사람을 잘 이해하는 능력을 바탕으로 업무에 도움이 되는 참여와 협조를 이끌어내기 위해 우호적인 관계를 형성하는 역량',
				},
				{
					strength: '자원 계획, 관리',
					keyword: ['자원 분석', '계획 수립'],
					description: '업무를 수행하는데 필요한 자원을 확인 및 분석하여, 계획을 수립하고 조정하는 역량',
				},
				{
					strength: '열정',
					keyword: ['일에 대한 애정, 몰입'],
					description: '자신의 일에 대해 애정을 가지고 몰입하는 역량',
				},
			];
		},
		goDetailUrl() {
			let company, position, req_no, o;

			company = this.detailList[0].company;
			position = this.detailList[0].job;
			req_no = this.detailList[0].no;
			o = this.detailList[0].other_inf;

			this.makeUrl(company, position, req_no, o);

			return `https://comento.kr/mine/essay?request_no=${req_no}`;
		},
		makeUrl(company, position) {
			company = company.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}]|\s+/gi, '');
			position = position.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}]|\s+/gi, '');

			company = company.replace('\n', '');
			company = company.replace('‘', '');
			company = company.replace('㈜', '');
			company = company.replace('’', '');

			position = position.replace('\n', '');
			position = position.replace('‘', '');
			position = position.replace('㈜', '');
			position = position.replace('’', '');

			if (company === '') company = '-';
			if (position === '') position = '-';
		},
		getMatchingInfo() {
			this.answerCorrespondence = this.$cookies.get('correspondence');
			if (typeof this.answerCorrespondence === 'string' && this.answerCorrespondence.toLowerCase() === 'true') {
				// GTM - viewRequestWithCorrespondence
				viewRequestWithCorrespondence();
			}
		},
	},
	components: {
		AnswerDetailThanksModal,
		AnswerCountSection,
		AnswerTipBox,
		GridLayout,
		// AnswerGreetingModal,
		EssayStrengthComponent,
		MobileEssayStrengthComponent,
	},
};
</script>

<style lang="scss" scoped>
@import '@/../scss/base/main';

.answer {
	word-wrap: break-word;
	word-break: break-word;
}

.question-container {
	@include mobile {
		background: white;
		padding-top: 20px;
		width: 100vw !important;
		margin-left: -8px !important;
		margin-right: -8px !important;
		margin-bottom: 40px !important;
	}
}

.answer-interaction {
	@include transition(all 0.3s ease);
}

.width-30 {
	width: 33.33333%;
}
.bg-gray .text-primary {
	margin: 0;
}
.content {
	background: #fff;
}

.icon-search {
	width: 9px;
}

.default-box {
	border: 1px solid $gray250;
	@include border-radius(2px);
	padding: 20px;
}

@include mobile {
	.width-30 {
		width: 100%;
		padding: 0 15px;
		float: none;
	}
	.col-8 {
		background: #fff;
		top: -15px;
		padding: 15px;
		margin-bottom: 20px;
	}
}
.pd-30 {
	padding: 30px;
	@include mobile {
		padding: 20px;
	}
}
</style>
