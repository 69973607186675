<template>
	<div class="mb-30 login-header">
		<div>
			<img
				class="lounge-icon cursor-pointer"
				src="/images/logo/logo-comento-typo-type.png"
				onclick="location.href='/'"
			/>
		</div>
		<div>
			<span class="f-light text-caption" v-html="msg"></span>
		</div>
	</div>
</template>
<script>
export default {
	props: ['msg'],
};
</script>
