<template>
	<biz-modal-component :show="show" @close="close">
		<div v-for="interview in interviewList" class="modal-body text-center">
			<div class="mb-20">
				<img class="co-surprised" src="/images/co_surprised.svg" />
				<div class="font">
					<b>후보자</b>님과 <b>{{ interview.company }}</b
					>의<br />면접 일정이 잡혔습니다!
				</div>
			</div>
			<div class="bg-dark-gray pd-20 text-left font mb-20">
				<ul>
					<li class="ml-20 mb-10">
						<span class="f-semi-bold">담당자</span>: {{ interview.hrName }} ({{ interview.hrEmail }})
					</li>
					<li class="ml-20 mb-10"><span class="f-semi-bold">면접 장소</span>: {{ interview.hrPlace }}</li>
					<li class="ml-20 mb-10"><span class="f-semi-bold">면접 일정</span>: (예시) 2019-02-15 12:00:00</li>
					<li class="ml-20 mb-10"><span class="f-semi-bold">면접 복장 안내</span>: {{ interview.cloth }}</li>
					<li class="ml-20">
						<span class="f-semi-bold">메모</span>
						<p class="font" v-html="$options.filters.nl2br(interview.memo)"></p>
					</li>
				</ul>
			</div>
			<div class="font">
				날짜 변경 및 문의사항은
				<a href="http://plus.kakao.com/home/k2iyu02l" target="_blank" class="text-link">코멘토 고객센터</a>
				로 연락주세요.
			</div>
		</div>
	</biz-modal-component>
</template>

<script>
export default {
	props: ['show', 'interviewList'],
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
ul {
	list-style: initial;
}
.co-surprised {
	width: 100px;
	margin-left: 20px;
}
</style>
