<template>
	<div v-if="existAvailableButton" :class="{ 'content-action-dropdown-show': sync_showDropdownList }">
		<c-dropdown v-click-outside="handleClickOutside" :show-dropdown.sync="sync_showDropdownList">
			<template #item>
				<c-icon-button :icon-name="computedIconName" size="medium" color="gray500" @click="toggleActionList" />
			</template>
			<template #list>
				<c-list class="content-action-dropdown-list" spacing>
					<template v-for="(button, i) in buttons">
						<template v-if="button.available">
							<c-list-item
								:key="`content-action-dropdown-button-${i}`"
								size="small"
								@click="button.action"
							>
								<c-typography type="body2" color="gray800" class="flex align-items-center">
									<c-icon color="gray700" :name="button.icon" class="c-pointer mr-10" />
									{{ button.name }}
								</c-typography>
							</c-list-item>
							<c-divider :key="`content-action-dropdown-button-divider-${i}`" color="gray100" />
						</template>
					</template>
				</c-list>
			</template>
		</c-dropdown>
		<c-bottom-drawer
			v-if="isMobile"
			:show-drawer="isShowBottomSheet"
			class="content-action-dropdown-bottom-sheet"
			success-message="test"
			@close="closeActionList"
		>
			<c-list>
				<template v-for="(button, i) in buttons">
					<c-list-item
						v-if="button.available"
						:key="`content-action-bottom-sheet-button-${i}`"
						class="mb-6"
						size="large"
						@click="button.action"
					>
						<c-typography color="gray800" class="content-action-dropdown-bottom-sheet-text">
							<c-icon color="gray700" :name="button.icon" class="mr-10" />
							{{ button.name }}
						</c-typography>
					</c-list-item>
				</template>
				<c-divider color="gray100" class="mb-6" />
				<c-list-item size="large" @click="closeActionList">
					<c-typography color="gray800" class="content-action-dropdown-bottom-sheet-text">
						<c-icon color="gray700" name="IconCloseSmallLine" class="mr-10" />
						취소
					</c-typography>
				</c-list-item>
			</c-list>
		</c-bottom-drawer>
		<ReportModal
			:show="showReportModal"
			:is-loading="reportModalLoading"
			:is-mentoring="isMentoring"
			@submitReport="handleSubmitReport"
			@close="$_closeReportModal"
		/>
		<c-permission-modal
			class="c-permission-modal"
			:show.sync="showPermissionModal"
			:success-callback="permissionCallback"
			:success-message="permissionButton"
			:confirm-button-color="permissionButtonColor"
		>
			<template slot="title">{{ permissionTitle }}</template>
			<template slot="content">{{ permissionContent }}</template>
		</c-permission-modal>
		<c-alert-modal :show="showAlertModal" @close="showAlertModal = false">
			<template #content>
				<div v-html="alertMessage" />
			</template>
		</c-alert-modal>
	</div>
</template>

<script>
import ReportModal from '@/components/_modal/ReportModal.vue';

import alertModalMixin from '@/mixins/alertModalMixin';
import layoutMixin from '@/mixins/layoutMixin';
import permissionModalMixin from '@/mixins/permissionModalMixin';
import reportModalMixin from '@/mixins/reportModalMixin';

export const CONTENT_TYPE = Object.freeze({
	// 질문
	CASE_QUESTION: 'CASE_QUESTION',
	// 답변
	CASE_ANSWER: 'CASE_ANSWER',
	// 댓글
	CASE_COMMENT: 'CASE_COMMENT',
	// 대댓글
	CASE_REPLY: 'CASE_REPLY',
	LOUNGE_POST: 'LOUNGE_POST',
	LOUNGE_COMMENT: 'LOUNGE_COMMENT',
	NOTICE_POST: 'NOTICE_POST',
	NOTICE_COMMENT: 'NOTICE_COMMENT',
	INSIGHT_POST: 'INSIGHT_POST',
	INSIGHT_COMMENT: 'INSIGHT_COMMENT',
});

export default {
	name: 'ContentActionButton',
	mixins: [layoutMixin, permissionModalMixin, alertModalMixin, reportModalMixin],
	props: {
		contentNo: {
			type: Number,
			default: null,
		},
		contentType: {
			type: String,
			default: null,
		},
		contentAuthorNo: {
			type: Number,
			default: null,
		},
		reportContentNo: {
			type: Number,
			default() {
				return this.contentNo;
			},
		},
		canEdit: {
			type: Boolean,
			default: false,
		},
		canReport: {
			type: Boolean,
			default: false,
		},
		isBlocked: {
			type: Boolean,
			default: false,
		},
		threeDotIconSize: {
			type: String,
			default: 'large',
		},
	},
	data() {
		return {
			isShowDropdownList: false,
			isShowBottomSheet: false,
			showDropdown: false,
			reportModalLoading: false,
			showAlertModal: false,
			buttonName: '확인',
			alertMessage: '',
		};
	},
	computed: {
		existAvailableButton() {
			return this.buttons.some(button => button.available);
		},
		buttons() {
			return [
				{
					name: '신고하기',
					action: this.openReportUserModal,
					icon: 'IconSirenSmallLine',
					available: this.canReport,
				},
			];
		},
		isMentoring() {
			switch (this.contentType) {
				case CONTENT_TYPE.CASE_QUESTION:
				case CONTENT_TYPE.CASE_ANSWER:
				case CONTENT_TYPE.CASE_COMMENT:
				case CONTENT_TYPE.CASE_REPLY:
					return true;
				default:
					return false;
			}
		},
		computedIconName() {
			const iconSize =
				{
					small: 'Small',
					large: 'Large',
				}[this.threeDotIconSize] || 'Large';
			return `IconThreeDot${iconSize}Fill`;
		},
		sync_showDropdownList: {
			get() {
				return this.isShowDropdownList;
			},
			set(newValue) {
				return (this.isShowDropdownList = newValue);
			},
		},
	},
	methods: {
		toggleActionList() {
			if (this.isMobile) {
				this.isShowBottomSheet = true;
			} else {
				this.sync_showDropdownList = !this.sync_showDropdownList;
			}
		},
		closeActionList() {
			this.sync_showDropdownList = false;
			this.isShowBottomSheet = false;
		},
		emitEditContent() {
			this.$emit('edit-content');
		},
		emitRemoveContent() {
			this.$emit('remove-content');
		},
		openBlockUserModal() {
			this.closeActionList();
			this.$_openPermissionModal({
				title: '사용자를 차단합니다',
				content: '이 사용자의 콘텐츠가 나에게 노출되지 않으며,\n차단은 나중에 다시 해제할 수 있습니다.',
				buttonColor: 'gray500',
				callback: async () => {
					this.$_closePermissionModal();
					await this.toggleBlockUser();
					this.$emit('block-user-toggled', this.contentAuthorNo);
				},
			});
		},
		openUnblockUserModal() {
			this.closeActionList();
			this.$_openPermissionModal({
				title: '사용자 차단을 해제합니다',
				content: '차단을 풀고 이 사용자의 콘텐츠를 열람합니다.',
				buttonColor: 'gray500',
				callback: async () => {
					this.$_closePermissionModal();
					await this.toggleBlockUser();
					this.$emit('block-user-toggled', this.contentAuthorNo);
				},
			});
		},
		async toggleBlockUser() {
			try {
				showLoading();
				return await this.$api.user.toggleBlockUser(this.contentAuthorNo);
			} catch (error) {
				console.error(error);
				this.openAlertModalWithMessage('문제가 발생했습니다.<br/>잠시후에 다시 시도해주시기 바랍니다.');
			} finally {
				closeLoading();
			}
		},
		openReportUserModal() {
			this.closeActionList();
			this.$_openReportModal();
		},
		handleSubmitReport({ content }) {
			this.$_openPermissionModal({
				title: '',
				content: '정말 신고하시겠습니까?',
				callback: () => {
					this.$_closePermissionModal();
					this.submitReport(content);
				},
			});
		},
		async submitReport(content) {
			try {
				this.reportModalLoading = true;
				const data = {
					message: content,
				};
				await this.submitReportByContentType(data);
				this.$_closeReportModal();
				this.openAlertModalWithMessage('신고가 접수되었습니다.');
			} catch (error) {
				console.error(error);
				this.openAlertModalWithMessage(
					'신고를 접수하는데 문제가 발생했습니다.<br/>잠시후에 다시 시도해주시기 바랍니다.',
				);
			} finally {
				this.reportModalLoading = false;
			}
		},
		submitReportByContentType(data) {
			switch (this.contentType) {
				case CONTENT_TYPE.CASE_QUESTION:
					return axios.post(`/api/case/detail/questionReport`, {
						question_no: this.reportContentNo,
						report_reason: data.message,
					});
				default:
					throw new Error('신고 유형이 없습니다');
			}
		},
		handleClickOutside() {
			if (!this.isMobile) {
				return this.closeActionList();
			}
			return false;
		},
		openAlertModalWithMessage(message) {
			this.alertMessage = message;
			this.showAlertModal = true;
		},
	},
	components: {
		ReportModal,
	},
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';

.content-action-dropdown {
	&-show {
		opacity: 1 !important;
	}
	&-bottom-sheet {
		z-index: 20;
		&-text {
			@include flexbox();
			@include align-items(center);
			height: 25px;
		}
	}
}
::v-deep {
	.c-dropdown--list-wrapper {
		width: max-content;
		border-radius: 10px;
	}
	.c-bottom-drawer {
		padding: 0;
		&--title-wrapper {
			padding: 0;
		}
		&--content-wrapper {
			padding: 20px;
		}
	}
}
</style>
