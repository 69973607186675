//filter/numberFormat.js

function numberFormat(value = '') {
	value = '' + value;

	// 소수점은 제외
	if (value.indexOf('.') >= 0) value = value.split('.')[0];

	// 숫자를 세 자리 마다 쉼표를 넣은 문자로 변환한다 (1000 -> '1,000')
	// return value.split('').reverse().reduce((acc, digit, i) => {
	//     if (i > 0 && i % 3 === 0) acc.push(',')
	//     return [...acc, digit]
	// }, []).reverse().join('')

	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default numberFormat;
