<template>
	<modal-component :show="show" :type="type" @close="close">
		<div class="modal-body text-center">
			<h4 class="popup-title mb-20 text-center">
				{{ title }}
			</h4>
			<div class="text-heading mb-20">
				<p style="color: #212529; word-break: break-word" v-html="$options.filters.nl2br(message)"></p>
			</div>
			<div class="mt-20">
				<button class="btn" @click="save">{{ button }}</button>
				<button class="btn btn-empty" @click="close">취소</button>
			</div>
		</div>
	</modal-component>
</template>

<script>
export default {
	props: ['show', 'title', 'message', 'buttonName', 'successCallback'],
	data() {
		return {
			button: '',
		};
	},
	created() {
		if (this.buttonName === '' || this.buttonName === undefined) {
			this.button = '확인';
		} else {
			this.button = this.buttonName;
		}
	},
	updated() {
		if (this.buttonName === '' || this.buttonName === undefined) {
			this.button = '확인';
		} else {
			this.button = this.buttonName;
		}
	},
	methods: {
		close() {
			if (this.mask !== 'false') {
				this.$emit('close');
			}
		},
		save() {
			if (this.successCallback !== '' && this.successCallback !== undefined) {
				location.href = this.successCallback;
			}
			this.$emit('save');
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
.popup-title {
	margin-left: 25px;
}
</style>
