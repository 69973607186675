// states
export const BOTTOM_NAVIGATION = 'BOTTOM_NAVIGATION';
export const SUB_HEADER = 'SUB_HEADER';
// getters
export const GET_BOTTOM_NAVIGATION = 'GET_BOTTOM_NAVIGATION';
export const GET_SUB_HEADER = 'GET_SUB_HEADER';
// mutations
export const SET_BOTTOM_NAVIGATION = 'SET_BOTTOM_NAVIGATION';
export const SET_SUB_HEADER = 'SET_SUB_HEADER';
// actions

const appContextStore = {
	namespaced: true,
	state: {
		[BOTTOM_NAVIGATION]: '',
		[SUB_HEADER]: '',
	},
	getters: {
		[GET_BOTTOM_NAVIGATION]: state => state[BOTTOM_NAVIGATION],
		[GET_SUB_HEADER]: state => state[SUB_HEADER],
	},
	mutations: {
		[SET_BOTTOM_NAVIGATION]: (state, payload) => {
			state[BOTTOM_NAVIGATION] = payload;
		},
		[SET_SUB_HEADER]: (state, payload) => {
			state[SUB_HEADER] = payload;
		},
	},
	actions: {},
};

export default appContextStore;
