<template>
	<div :class="mobileStyle">
		<section v-if="userInfo !== ''" class="content apply text-center" :class="!isViewButtonBox ? 'mb-30' : 'mb-10'">
			<img class="profile-img" :src="display === 'biz' ? imgP(randomNo) : bizBaseUrl + companyLogo" />
			<div :class="jobsJob">{{ userInfo.headTitle }}</div>
			<!--<div :class="jobsJob" v-html="display==='mypage' || (isFinishInterview && applyType === 5) || applyType===1 || !isShowInfo ? userInfo.headTitle : userInfo.headTitle.substring(0,1)+'00'"></div>-->
			<div :class="jobsName" v-html="userInfo.subTitle"></div>
		</section>
		<ApplyStatusComponent
			v-if="isViewButtonBox && applyDate !== ''"
			:display="display"
			:apply-type="applyType"
			:apply-no="applyNo"
			:apply-state="applyState"
			:interview="interview"
			:apply-date="applyDate"
			:view-resume="viewResume"
			:expire-date="expireDate"
			:apply-info="applyInfo"
			:auto-expire-date="autoExpireDate"
			:apply-auto-interest="applyAutoInterest"
			:state-changed-date="stateChangedDate"
			:pm-company-user-type="pmCompanyUserType"
		></ApplyStatusComponent>
		<section>
			<h6 class="mb-10">지원 서류</h6>
			<div v-if="applyType === 5">
				<div v-if="pmCompanyUserType === 8">
					<div
						v-if="!isShowInfo"
						class="well text-center f-semi-bold h7"
						style="margin-bottom: 10px"
						v-html="aiInfoText"
					></div>
				</div>
				<div v-else>
					<div
						v-if="!isFinishInterview"
						class="well text-center f-semi-bold h7"
						style="margin-bottom: 10px"
						v-html="aiInfoText"
					></div>
				</div>
			</div>

			<!--<div class="well text-center f-semi-bold h7" v-if="applyType === 5 && !isFinishInterview"-->
			<!--v-text="aiInfoText">-->
			<!--</div>-->
			<div v-if="motive !== '' && applyType === 1" class="content apply">
				<div class="content-info">지원동기</div>
				<div v-html="$options.filters.nl2br(motive)"></div>
			</div>
			<div class="content apply">
				<div class="content-info">
					이력서
					<div
						v-if="display === 'biz' && isResume"
						class="float-btn print pointer only-pc"
						@click="printResume()"
					></div>
				</div>
				<div>
					<template v-if="isResume">
						<section id="resume">
							<!-- 기존이력서 -->
							<ResumePrintComponent
								v-if="resumeVersion <= 1"
								pmNo="-1"
								:apply-no="applyNo"
								:apply-type="applyType"
								:display="display"
								:is-finish-interview="isFinishInterview"
								:is-show-info="isShowInfo"
								:pm-company-user-type="pmCompanyUserType"
								:user-info="userInfo"
							></ResumePrintComponent>
							<!-- 신규이력서 -->
							<ResumePrintNewComponent
								v-else
								:apply-no="applyNo"
								:apply-type="applyType"
								:display="display"
								:is-finish-interview="isFinishInterview"
								:is-show-info="isShowInfo"
								:pm-company-user-type="pmCompanyUserType"
								:user-info="userInfo"
							></ResumePrintNewComponent>
						</section>
					</template>
					<template v-else>
						<div class="apply-additional">
							<CustomLink :to="baseUrl + 'portfolio/' + resumeFile.filePath">
								<img :src="'/images/file_' + resumeFile.ext + '.svg'" />
								<p class="text-truncate" v-html="resumeFile.fileName"></p>
							</CustomLink>
						</div>
					</template>
				</div>
			</div>
			<div v-if="additional.length > 0" class="content apply">
				<div class="content-info">추가 제출자료</div>
				<ul>
					<li v-for="(additional, index) in additional" class="apply-additional files">
						<CustomLink
							v-if="additional.type !== 'link'"
							:to="baseUrl + 'portfolio/' + additional.file_path"
						>
							<img :src="'/images/file_' + additional.ext + '.svg'" />
							<p class="text-truncate" v-html="additional.file_name"></p>
						</CustomLink>
						<CustomLink v-else :to="additional.file_path" target="_blank">
							<img :src="'/images/file_link.svg'" />
							<p class="text-truncate" v-html="additional.file_name"></p>
						</CustomLink>
					</li>
				</ul>
			</div>
		</section>
		<section>
			<h6 class="mb-10">지원자 정보</h6>
			<div class="content apply apply-user-info">
				<div v-if="applyInfo.name !== ''" class="content-info">
					<img src="/images/icon_name.svg" />
					<div class="name">이름</div>
					<div v-if="display === 'mypage'">
						{{ applyInfo.name }}
					</div>
					<div v-else>
						{{ userInfo.headTitle }}
					</div>
				</div>
				<div v-if="applyInfo.email !== ''" class="content-info">
					<img src="/images/email.svg" />
					<div class="name">이메일</div>

					<div v-if="display === 'mypage'">
						{{ applyInfo.email }}
					</div>
					<div v-else-if="applyType === 1">
						{{ applyInfo.email }}
					</div>
					<div v-else-if="pmCompanyUserType !== 8">
						<div v-if="!isFinishInterview">
							<img src="/images/locked.svg" class="lock-img" />면접 확정 후 공개됩니다.
						</div>
						<div v-else>
							{{ applyInfo.email }}
						</div>
					</div>
					<div v-else-if="pmCompanyUserType === 8">
						<div v-if="!isShowInfo">
							<img src="/images/locked.svg" class="lock-img" />관심도 확인 후 공개됩니다.
						</div>
						<div v-else>
							{{ applyInfo.email }}
						</div>
					</div>
				</div>
				<div v-if="applyInfo.mobile !== ''" class="content-info">
					<img src="/images/tel.svg" />
					<div class="name">전화번호</div>
					<div v-if="display === 'mypage'">
						{{ applyInfo.mobile | phoneNumberFormat }}
					</div>
					<div v-else-if="applyType === 1">
						{{ applyInfo.mobile | phoneNumberFormat }}
					</div>
					<div v-else-if="pmCompanyUserType !== 8">
						<div v-if="!isFinishInterview">
							<img src="/images/locked.svg" class="lock-img" />면접 확정 후 공개됩니다.
						</div>
						<div v-else>
							{{ applyInfo.mobile | phoneNumberFormat }}
						</div>
					</div>
					<div v-else-if="pmCompanyUserType === 8">
						<div v-if="!isShowInfo">
							<img src="/images/locked.svg" class="lock-img" />관심도 확인 후 공개됩니다.
						</div>
						<div v-else>
							{{ applyInfo.mobile | phoneNumberFormat }}
						</div>
					</div>
				</div>
				<div>
					<img src="/images/clock.svg" />
					<div class="name">지원일시</div>
					<div>{{ applyInfo.date }}</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import ResumePrintNewComponent from '../../resume/ResumePrintNewComponent.vue';
import ResumePrintComponent from '../../resume/ResumePrintComponent.vue';
import ApplyStatusComponent from './ApplyStatusComponent.vue';
import CustomLink from '../../common/CustomLink.vue';

export default {
	props: ['applyNo', 'randomNo', 'applyType', 'display', 'baseUrl', 'pmCompanyUserType', 'companyLogo'],
	data() {
		return {
			isViewButtonBox: true,
			motive: '', //지원동기
			applyInfo: [],
			additional: [], //첨부자료
			resumeVersion: 0, //1:기존 2:신규
			isResume: false,
			resumeFile: { ext: 'file' }, //파일로 제출한 이력서
			extensionArr: [
				'jpg',
				'jpeg',
				'png',
				'gif',
				'doc',
				'docx',
				'pdf',
				'odt',
				'hwp',
				'ppt',
				'pptx',
				'zip',
				'rar',
			],
			interview: [],
			isFinishInterview: false,
			applyState: '', //상태코드(숫자)
			viewResume: '',
			applyDate: '', //지원날짜
			applyAutoInterest: null,
			userInfo: {},
			expireDate: '',
			autoExpireDate: '',
			comNo: '',
			stateChangedDate: '',
			isShowInfo: false,
			bizBaseUrl: 'https://d3oho2by66df6p.cloudfront.net/conect/',
			isIE: false,
		};
	},
	computed: {
		jobsJob() {
			if (!this.isMobile) {
				return 'h3';
			} else {
				return 'h6';
			}
		},
		jobsName() {
			if (!this.isMobile) {
				return 'h6 f-regular';
			} else {
				return '';
			}
		},
		mobileStyle() {
			if (this.isMobile) return 'mt-20';
		},
		aiInfoText() {
			if (this.display === 'mypage') {
				return 'AI 취업비서가 지원한 공고로<br>면접 확정 전까지 이름과 연락처가 공개되지 않습니다.';
			} else {
				if (this.pmCompanyUserType === 8) {
					return 'AI 인재검색으로 추천된 인재로<br>관심도 확인 후 이름과 연락처가 공개됩니다.';
				} else if (this.pmCompanyUserType !== 8) {
					return 'AI 인재검색으로 추천된 인재로<br>면접 확정 후 이름과 연락처가 공개됩니다.';
				}
			}
		},
	},
	created() {
		this.getApplyDetail();

		//IE는 인쇄 오류가 있어서 버튼 숨기기
		this.$nextTick(() => {
			const userAgent = navigator.userAgent.toLowerCase();
			if (~userAgent.indexOf('msie') || ~userAgent.indexOf('trident') || ~userAgent.indexOf('rv:11.0')) {
				this.isIE = true;
			}
		});
	},
	methods: {
		imgP(num) {
			return '/images/my_anonymous_' + num + '.svg';
		},
		getApplyDetail() {
			axios({
				method: 'GET',
				url: '/api/apply/detail',
				params: {
					apply_no: this.applyNo,
					location: 'common',
				},
			})
				.then(response => {
					//console.log(response.data);
					if (response.data.code === 200) {
						let list = response.data.list;

						this.applyInfo = list.apply;
						if (response.data.list.motive !== null) {
							this.motive = list.motive.motive; //지원동기
						}
						this.additional = list.additional;
						this.applyState = this.applyInfo.state;
						this.viewResume = this.applyInfo.view_resume;
						this.interview = list.interview;
						this.applyDate = list.apply.date;
						this.expireDate = list.expire_date;
						this.stateChangedDate = list.state_changed_date;

						if (this.applyInfo.user_type === 5) {
							//자동지원
							this.applyAutoInterest = list.auto_interest;
						}
						this.autoExpireDate = list.auto_expire_date;

						//자동지원 면접확정
						//todo: 청년재단일 경우는 예외

						if (this.applyInfo.user_type === 5) {
							//열린 채용 (관심도 확인 확정)
							if (this.applyInfo.state >= 3) {
								if (this.applyAutoInterest) {
									if (parseInt(this.applyAutoInterest.interest) === 1) this.isShowInfo = true;
								}

								if (this.interview) {
									if (this.interview.confirm > 0) this.isShowInfo = true;
								}
							}

							if (parseInt(this.pmCompanyUserType) !== 8 && this.interview) {
								if (this.applyInfo.state >= 5 && this.interview.confirm > 0) {
									//this.isShowInfo = true;
									this.isFinishInterview = true;
								}
							}

							// 이름 노출 조건 계산
							let userName = this.applyInfo.name;
							let userNameHidden = false;

							// 자동지원자 유저
							if (this.applyInfo.user_type === 5) {
								// 자동지원자 유저는 면접상태 이전에는 이름이 가려진다.
								if (this.applyState < 5) {
									userNameHidden = true;
								}
							}

							// 열린채용 기업
							if (parseInt(this.pmCompanyUserType) === 8) {
								userNameHidden = false;

								// 면접 이전에만 조건을 계산한다. 면접상태 이후에는 이름이 노출된다.
								if (this.applyState < 5) {
									// 관심도를 보냈으면 확인하기전에는 이름이 노출되지 않는다.
									if (this.applyAutoInterest) {
										if (this.applyAutoInterest.interest !== 1) {
											userNameHidden = true;
										}
									}
									// 관심도를 보내지 않았으면 이름이 노출되지 않는다.
									else {
										userNameHidden = true;
									}
								}
							}

							if (userNameHidden) {
								userName = userName.substring(0, 1) + '00';
							}

							if (this.display === 'mypage') {
								this.userInfo = {
									headTitle: this.applyInfo.job,
									subTitle: this.applyInfo.com_name,
									userName: userName,
								};
							} else {
								const apply = this.applyInfo;

								const univ = apply.univ;

								const major = apply.major;

								let subTitle = '';

								this.userInfo = {
									headTitle: userName,
									userName: userName,
								};
								//

								if (univ && major) {
									subTitle = univ + ' ' + major;
								}

								this.userInfo.subTitle = subTitle;
							}
						} else {
							this.userInfo = {
								headTitle: this.applyInfo.name,
								subTitle: this.applyInfo.com_name,
								userName: this.applyInfo.name,
							};
						}

						//이력서를 코멘토 양식으로 제출(기존양식, 신규양식)
						if (
							response.data.list.apply.file_name === null ||
							(response.data.list.apply.file_name === '' &&
								response.data.list.apply.file_path === null) ||
							response.data.list.apply.file_path === ''
						) {
							this.isResume = true;

							if (response.data.list.resume.user_info.length !== undefined) {
								//ver 1(user_info[0]{ver})
								for (let index in response.data.list.resume.user_info) {
									this.resumeVersion = parseInt(response.data.list.resume.user_info[index].ver);
								}
							} else {
								//ver 2(user_info{ver})
								this.resumeVersion = parseInt(response.data.list.resume.user_info.ver);
							}
						} else {
							//이력서를 file로 제출
							this.resumeFile.fileName = this.applyInfo.file_name;
							this.resumeFile.filePath = this.applyInfo.file_path;

							this.resumeFile.ext = this.resumeFile.fileName.substring(
								this.resumeFile.fileName.lastIndexOf('.') + 1,
								this.resumeFile.fileName.length,
							);
							if (!this.extensionArr.includes(this.resumeFile.ext)) {
								this.resumeFile.ext = 'file';
							}
						}

						if (list.additional.length > 0) {
							this.getAdditional();
						}
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		getAdditional() {
			for (let i = 0; i < this.additional.length; i++) {
				if (this.additional[i].type !== 'link') {
					this.additional[i].ext = this.additional[i].file_name.substring(
						this.additional[i].file_name.lastIndexOf('.') + 1,
						this.additional[i].file_name.length,
					);
					if (this.extensionArr.includes(this.additional[i].ext)) {
						continue;
					} else {
						this.additional[i].ext = 'file';
					}
				}
			}
		},
		printResume() {
			//이력서 인쇄
			const html = document.querySelector('html');
			const printContents = document.getElementById('resume').innerHTML;
			const printDiv = document.createElement('DIV');
			printDiv.className = 'print-div';
			let prints;

			if (this.isIE) {
				//IE
				function beforePrint() {
					prints = document.body.innerHTML;
					document.body.innerHTML = printContents;
				}
				function afterPrint() {
					document.body.innerHTML = prints;
				}
				window.onbeforeprint = beforePrint;
				window.onafterprint = afterPrint;
				window.print();
			} else {
				html.appendChild(printDiv);
				printDiv.innerHTML = printContents;
				document.body.style.display = 'none';
				window.print();
				document.body.style.display = 'block';
				printDiv.style.display = 'none';
			}
		},
	},
	components: {
		CustomLink,
		ResumePrintComponent,
		ResumePrintNewComponent,
		ApplyStatusComponent,
	},
};
</script>

<style lang="less" scoped>
@caption-color: #9ea5ab;
@border-color: #dee2e6;
@bg-btn-color-gray: #f4f5f7;
@primary-color: #2a7de1;

.profile-img {
	width: 100px;
	height: 100px;
	border: 1px solid @border-color;
}

.resume-box {
	padding: 0;
	border: none;

	.resume-section {
		.date {
			width: 23% !important;
		}

		.detail {
			width: 70% !important;
		}
	}
}

.apply-user-info {
	div {
		> * {
			display: inline-block;
			vertical-align: middle;
		}

		img {
			width: 17px;
			margin-right: 10px;
			vertical-align: middle;
			position: relative;
			/*bottom: 2px;*/

			&.lock-img {
				width: 10px;
				bottom: 3px;
				margin-right: 5px;
			}
		}

		.name {
			color: @caption-color;
			margin-right: 100px;
			width: 60px;
		}
	}
}

.apply-additional {
	height: 60px;
	background-color: @bg-btn-color-gray;
	padding: 15px;
	cursor: pointer;
	background-image: url(/public/images/icon_download.svg);
	background-repeat: no-repeat;
	background-position: 97% center;
	background-size: initial;

	a {
		display: block;

		> * {
			display: inline-block;
		}
		p {
			width: 75%;
		}
	}

	img {
		width: 25px;
		height: 30px;
		margin-right: 10px;
	}

	&.files {
		display: inline-block;
		background-position: 95% center;
		width: 329px;
		margin-bottom: 10px;

		&:nth-child(odd) {
			margin-right: 10px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.float-btn.print {
	position: absolute;
	top: 25px;
	bottom: 60px;
	right: 30px;
	-webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
	width: 65px !important;
	height: 65px !important;
	-webkit-background-size: 35px;
	background-size: 35px;
}

@media (max-width: 480px) {
	.profile-img {
		width: 70px;
		height: 70px;
	}

	.apply-user-info {
		div {
			.name {
				margin-right: 30px;
			}
		}
	}

	.apply-additional {
		background-position: 95% center;

		&.files {
			display: block;
			width: 100%;
			margin-right: 0;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
</style>
