<template>
	<section id="section" class="section section-top section-detail pb-0" :class="{ 'pt-50': !isMobile }">
		<div class="resume-builder">
			<div class="resume-tutorial-section">
				<div class="container">
					<div class="underline-black" />
					<p class="tutorial-main-message">좋은 자기소개서란?</p>
					<p class="tutorial-title inline-block">요구역량과 내 경험이<br />잘 연결된 자기소개서</p>
					<div :style="isMobile ? '' : 'margin-top: 120px; float: right'">
						<div v-html="tutorialContent" />
						<div class="mobile-item" :style="mobileStyle">
							<img id="tutorial-flow" src="/images/resume/resume-tutorial-flow.png" alt />
						</div>
						<div class="flex tutorial-step only-pc">
							<div v-for="(item, index) in tutorialItem" :key="`tutorial-item-${index}`" class="flex">
								<div
									class="flex flex-column flex-v-middle"
									:style="index === 3 ? 'margin-left: -10px' : ''"
								>
									<div class="flex flex-row flex-v-middle">
										<div class="text-center feature-item">
											<img :src="`/images/${item.image}.svg`" alt />
										</div>
										<img v-if="index < 3" src="/images/arrow-right-gray.svg" class="arrow" alt />
									</div>
									<span :style="`margin-left: ${item.marginLeft}`">{{ item.message }}</span>
								</div>
							</div>
						</div>
						<c-button
							size="large"
							:full="isMobile"
							:style="isMobile ? 'margin: 30px 0 12px' : 'margin: 50px 20px 180px 0'"
							@click="nextTutorialStep"
						>
							시작하기
						</c-button>
					</div>
				</div>
			</div>
		</div>

		<JoinAdPopupComponent v-if="showJoinAdPopup" page="resume" />
		<alert-modal-component
			:show="showAlert"
			type="simple"
			:title="title"
			:message="message"
			:buttonName="buttonName"
			:successCallback="successCallback"
			@close="showAlert = false"
		></alert-modal-component>
	</section>
</template>

<script>
import { mapMutations } from 'vuex';
import JoinAdPopupComponent from '../../../components/_common/JoinAdPopupComponent.vue';

export default {
	name: 'EssayIntroView',
	props: {
		userType: {
			type: Number,
		},
		userNo: {
			type: Number,
		},
	},
	data() {
		return {
			tutorialStep: 1,
			tutorialItem: [
				{ image: 'suitcase', message: '직무가 하는 일', marginLeft: '-80px' },
				{ image: 'diamond', message: '직무 우수자의 모습', marginLeft: '-80px' },
				{ image: 'mine', message: '나의 강점', marginLeft: '-80px' },
				{ image: 'mentoring', message: '강점관련 경험', marginLeft: '0' },
			],
			showAlert: false,
			title: '',
			message: '',
			buttonName: '',
			successCallback: '',
			showJoinAdPopup: false,
		};
	},
	computed: {
		tutorialContent() {
			return `좋은 자기소개서는 좋은 경험에서 시작합니다.<br>
				대부분의 구직자들은 본인이 좋은 경험이 없다고 <br class="only-mobile">생각하지만, <br class="only-pc">
				<span class="only-pc">사실은 </span>이미 좋은 경험을 가지고 있습니다.<br>
				그렇다면 그 경험들을 자기소개서에 옮겨 담지 못하는 <br class="only-mobile">이유는 무엇일까요?<br><br>
				첫째, 본인의 훌륭한 경험을 과소 평가하여 자기소개서에 <br class="only-mobile">적을 수 없는 사소한 내용으로 생각하거나<br>
				둘째, 본인의 경험이 직무와 어떻게 연결되는지를 <br class="only-mobile">찾지 못하여 제외시키기 때문입니다.<br><br>
				따라서 좋은 자기소개서를 쓰기 위해서는 지원하고자 <br class="only-mobile">하는 <span class="highlight-text">
				직무의 업무를 이해하고, <br class="only-pc">그 업무가 요구하는 <br class="only-mobile">역량과 나의 강점.
				그리고 그 강점을 뒷받침하는 <br class="only-mobile">경험을 일관성 있게 <br class="only-pc">
				연결</span>할 수 있어야 합니다.<br><br>
				본 튜토리얼을 마치면 직무 업무와 역량, 나의 강점과 <br class="only-mobile">경험의 연결 고리를 발견하고
				<br class="only-pc">
				좋은 자기소개서를 작성하기 <br class="only-mobile">위한 초안을 완성하게 될 것입니다.`;
		},
		mobileStyle() {
			if (this.isMobile) {
				return 'width: 100vw;' + 'margin-left: calc(-50vw + 50%);';
			} else {
				return 'display: none';
			}
		},
	},
	created() {
		this.tutorialStep = parseInt(this.$route.params.no);
	},
	mounted() {
		this.setMeta({
			isShowFooter: false,
			title: '자기소개서 튜토리얼',
			isDetail: true,
			fluid: !this.isMobile,
		});

		this.$nextTick(() => {
			if (this.isMobile) {
				if (document.querySelector('#tutorial-flow').clientHeight > 0) {
					document.querySelector('.mobile-item').style.height =
						document.querySelector('#answer_tutorial-flow').clientHeight;
				}
			}
		});
	},
	beforeRouteUpdate(to, from, next) {
		this.tutorialStep = parseInt(to.params.no);
		next();
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		nextTutorialStep() {
			//GTM - finishTutorial
			finishTutorial(this.userNo, this.userType);
			// GTM - GAVirtualPageview
			GAVirtualPageview(this.$route.path);

			if (this.userType !== undefined && this.userType >= 1) {
				if (window.innerWidth < 800) {
					this.title = 'PC에서만 가능';
					this.message = '자기소개서 튜토리얼은<br>PC에서만 진행하실 수 있습니다.';
					this.buttonName = '';
					this.successCallback = '';
					this.showAlert = true;
					return false;
				} else {
					window.location.href = '/essay/builder';
				}
			} else {
				this.showJoinAdPopup = true;
				return false;
			}
		},
	},
	components: { JoinAdPopupComponent },
};
</script>
