import Vue from 'vue';
import { mapMutations } from 'vuex';
import { SET_BOTTOM_NAVIGATION, SET_SUB_HEADER } from '../store/modules/appContextStore';

// 이 mixin은 app에서 호출됩니다.
const appCommandReceiveMixin = {
	methods: {
		...mapMutations('appContextStore', [SET_BOTTOM_NAVIGATION, SET_SUB_HEADER]),
		/**
		 * @deprecated
		 * 앱에서 부르는 method, 앱이 지금 어느 메뉴인지 송신
		 * 웹뷰 페이지 로드 완료 후 불러서 lifecycle과 빈번하게 충돌하여 deprecated
		 * @param {Object} menuInfo
		 * @param {string} menuInfo.bottomNavigation: 커뮤니티 | 직무부트캠프 | 실무PT | VOD | 마이페이지
		 * @param {string} menuInfo.subHeader: 홈 | 취업 고민 | 이직 고민 | 랜선 사수 | 대학생 고민 | 답변하기 | 인사이트 | 자유게시판
		 */
		$_setAppCurrentMenu({ bottomNavigation, subHeader }) {
			try {
				this[SET_BOTTOM_NAVIGATION](bottomNavigation);
				this[SET_SUB_HEADER](subHeader);
				return true;
			} catch (e) {
				console.error(e);
				return false;
			}
		},
	},
};

export default appCommandReceiveMixin;

if (!Vue.__app_command_receive_mixin__) {
	Vue.__app_command_receive_mixin__ = true;
	Vue.mixin(appCommandReceiveMixin);
}
