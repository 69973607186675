<!--https://adamwathan.me/2016/01/04/composing-reusable-modal-dialogs-with-vuejs/-->
<template>
	<transition name="modal">
		<div v-show="show" class="modal-mask" @click="close">
			<div
				:id="id"
				ref="modalCont"
				class="modal-container"
				:class="{ 'mobile-action round-8': mobileMotion }"
				@click.stop
			>
				<!--pc, mo 형태가 다른 모달일 경우 type 을 footerMotion 준다 (요청완료) -->
				<div v-if="type !== 'simple' && type === 'footerMotion'">
					<i
						v-if="isMobile"
						class="icon icon-close icon-close-black box-remove pointer"
						style="top: -6px; right: -6px; z-index: 2"
						@click="clickClose()"
					></i>
					<img
						v-else
						class="box-remove icon-sm-md pointer"
						style="top: -8px; right: -28px"
						alt=""
						src="/images/gray_remove.svg"
						@click="clickClose()"
					/>
				</div>
				<img
					v-if="type !== 'simple' && type !== 'footerMotion'"
					class="box-remove icon-sm-md pointer"
					src="/images/gray_remove.svg"
					alt=""
					@click="clickClose()"
				/>
				<slot></slot>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	props: ['show', 'type', 'mask', 'id'],
	data() {
		return {
			mobileMotion: false,
		};
	},
	created() {
		if (this.type === 'footerMotion') {
			this.mobileMotion = true;
		}
	},
	mounted() {
		document.addEventListener('keydown', e => {
			if (this.show && e.keyCode == 27) {
				this.close();
			}
		});
	},
	methods: {
		close() {
			if (this.type === 'footerMotion' && this.isMobile) {
				this.$emit('close');
			}

			if (typeof this.mask === undefined || !this.mask) {
				this.$emit('close');
			}
		},
		clickClose() {
			//닫기 버튼
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
* {
	box-sizing: border-box;
}
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.3s ease;
}
.modal-container {
	min-width: 460px;
	width: auto;
	max-width: 520px;
	margin: 0 auto;
	padding: 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	/*font-family: Helvetica, Arial, sans-serif;*/
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	&.round-8 {
		background-color: #fafafa;
		border-radius: 8px;
		padding: 32px 48px 36px 48px;
		min-width: 360px;
		width: 360px;
	}
}
@media (max-width: 480px) {
	.modal-container {
		width: 90% !important;
		min-width: 90%;
		/*모바일 & 액션이 있을 경우 (하단에서 올라오는 모션) type=footerMotion 넘기면 됨*/
		&.mobile-action {
			padding: 28px 26px 32px 26px;
			border-radius: 16px 16px 0 0;
			width: 100% !important;
			min-width: 100% !important;
			bottom: 0;
			top: auto;
			left: 0;
			transform: translate(0, 0);
			height: auto;
		}
	}
}

.box-remove {
	width: 20px;
	float: right;
	position: relative;
	top: -15px;
	right: -15px;
}

.modal-header h4 {
	margin-top: 0;
	color: #42b983;
}

/*.modal-body {*/
/*margin: 20px 0;*/
/*}*/

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	/*-webkit-transform: scale(1.1);*/
	/*transform: scale(1.1);*/
}
/*ai 분석기 결과 화면 css*/
.analytics-send-container {
	.modal-container {
		max-width: 880px !important;
		padding: 0 !important;
		width: 100%;
		.box-remove {
			display: none;
		}
	}
}
</style>
