<!--
    기존 이력서 보기 화면
-->

<template>
	<div id="print-resume" class="resume-box" style="margin-top: -1px; margin-bottom: 12px">
		<div v-if="resume">
			<div class="info-section">
				<div class="mb-20">
					<div v-if="display === 'mypage'" class="headline">{{ name }}</div>
					<div v-else class="headline" v-text="bizApplyUserName"></div>
				</div>
				<div class="private-info mb-20">
					<div v-if="display === 'mypage'">
						<p>{{ email }}</p>
						<p>{{ mobile | phoneNumberFormat }}</p>
					</div>
					<template v-else>
						<div v-if="applyType === 1">
							<p>{{ email }}</p>
							<p>{{ mobile | phoneNumberFormat }}</p>
						</div>
						<div v-else-if="pmCompanyUserType !== 8">
							<div v-if="!isFinishInterview">
								<p>*****@*****.***</p>
								<p>010-****-****</p>
							</div>
							<div v-else>
								<p>{{ email }}</p>
								<p>{{ mobile | phoneNumberFormat }}</p>
							</div>
						</div>
						<div v-else-if="pmCompanyUserType === 8">
							<div v-if="!isShowInfo">
								<p>*****@*****.***</p>
								<p>010-****-****</p>
							</div>
							<div v-else>
								<p>{{ email }}</p>
								<p>{{ mobile | phoneNumberFormat }}</p>
							</div>
						</div>
					</template>

					<!--<div v-if="display==='biz'&&isFinishInterview">-->
					<!--<p>지원자 정보는 면접 확정 후 공개됩니다.</p>-->
					<!--</div>-->
					<!--<div v-else>-->
					<!--<div v-if="parseInt(applyType)===1">-->
					<!--<p>{{email}}</p>-->
					<!--<p>{{mobile | phoneNumberFormat}}</p>-->
					<!--</div>-->
					<!--<div v-else>-->
					<!--<div v-if="!isFinishInterview">-->
					<!--<p>지원자 정보는 면접 확정 후 공개됩니다.</p>-->
					<!--</div>-->
					<!--<div v-else-if="!isShowInfo">-->
					<!--<p>*****@*****.***</p>-->
					<!--<p>010-****-****</p>-->
					<!--</div>-->
					<!--<div v-else>-->
					<!--<p>{{email}}</p>-->
					<!--<p>{{mobile | phoneNumberFormat}}</p>-->
					<!--</div>-->
					<!--</div>-->
					<!--</div>-->
				</div>
			</div>
			<div v-if="sentence || strength || vision" class="synthesis-box">
				<p v-if="sentence" v-html="$options.filters.nl2br('•&nbsp' + sentence)"></p>
				<p v-if="strength" v-html="$options.filters.nl2br('•&nbsp' + strength)"></p>
				<p v-if="vision" v-html="$options.filters.nl2br('•&nbsp' + vision)"></p>
			</div>
		</div>
		<div v-if="career.length !== 0" class="resume-section clearfix">
			<div class="sub-heading">경력사항</div>
			<div v-for="(car, index) in career" :key="`career-${index}`">
				<div class="date">
					<div>
						{{ car.startDate !== 'none' ? car.startDate : '' }} ~
						{{ car.endDate !== 'none' ? car.endDate : '' }}
					</div>
				</div>
				<div class="detail">
					<div class="resume-input-group mb-20">
						<div class="item-title">{{ car.company }} {{ car.dep }}</div>
						<div class="item-sub-title" v-html="$options.filters.nl2br(car.position)"></div>
						<div
							v-if="car.role !== '' && car.role !== null"
							class="item-content"
							v-html="$options.filters.nl2br('•&nbsp' + car.role)"
						></div>
						<div
							v-if="car.cont !== '' && car.cont !== null"
							class="item-content"
							v-html="$options.filters.nl2br('•&nbsp' + car.cont)"
						></div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="award.length !== 0" class="resume-section clearfix">
			<div class="sub-heading">수상내역</div>
			<div v-for="(awd, index) in award" :key="`award-${index}`">
				<div class="caption">{{ awd.prizeDate !== 'none' ? awd.prizeDate : '' }}</div>
				<div class="detail mb-20">
					<div class="item-title">{{ awd.company }}</div>
					<div class="item-sub-title" v-html="$options.filters.nl2br(awd.prize)"></div>
					<div
						v-if="awd.cont !== '' && awd.cont !== null"
						class="item-content"
						v-html="$options.filters.nl2br('•&nbsp' + awd.cont)"
					></div>
					<div
						v-if="awd.role !== '' && awd.role !== null"
						class="item-content"
						v-html="$options.filters.nl2br('•&nbsp' + awd.role)"
					></div>
				</div>
			</div>
		</div>
		<div v-if="education.length !== 0" class="resume-section clearfix">
			<div class="sub-heading">학력사항</div>
			<div v-for="(edu, index) in education" :key="`education-${index}`">
				<div class="date">
					<div>
						{{ edu.enter !== 'none' ? edu.enter : '' }} ~
						{{ edu.graduate !== 'none' ? edu.graduate : '' }}
					</div>
					<!--<div class="end-date">{{edu.graduate}}</div>-->
				</div>
				<div class="detail mb-20">
					<div>
						<span class="item-title">{{ edu.belong }}</span>
					</div>
					<div class="item-sub-title">
						{{ edu.major }}
						<span v-if="edu.degree !== '고졸'">{{ edu.degree }}</span>
						<span v-if="edu.grade !== null && edu.grade !== '' && edu.degree !== '고졸'">
							({{ edu.grade }} / 4.5)
						</span>
					</div>
					<div class="item-content" v-html="$options.filters.nl2br(edu.eduDesc)"></div>
				</div>
			</div>
		</div>
		<div v-if="lang.length !== 0 || cert.length !== 0 || tool.length !== 0" class="resume-section clearfix">
			<div class="sub-heading">기타</div>
			<div>
				<template v-for="(lang, index) in lang">
					<div v-if="lang.length !== 0" :key="`lang-${index}`">
						<div class="date">
							<div v-html="lang.date !== '' ? lang.date : '<div>&nbsp</div>'"></div>
						</div>
						<div class="detail other">
							<p class="resume-input-group">
								<span class="item-title item-ect">{{ lang.exam }}<span class="row-line"></span></span>
								<span v-show="lang.grade !== null && lang.grade !== ''" class="item-sub-title item-ect">
									>
									{{ lang.grade }}
								</span>
							</p>
						</div>
					</div>
				</template>
				<template v-for="(cert, index) in cert">
					<div v-if="cert.length !== 0" :key="`cert-${index}`">
						<div class="date">
							<div v-html="cert.date !== '' ? cert.date : '<div>&nbsp</div>'"></div>
						</div>
						<div class="detail other">
							<p class="resume-input-group">
								<span class="item-title item-ect">{{ cert.cert }}<span class="row-line"></span></span>
								<span class="item-sub-title item-ect">
									{{ cert.company }}
									<span v-show="cert.pass !== null && cert.pass !== ''">({{ cert.pass }})</span>
								</span>
							</p>
						</div>
					</div>
				</template>

				<template v-for="(tool, index) in tool">
					<div v-if="tool.length !== 0" :key="`tool-${index}`">
						<div class="date" v-html="'<div>&nbsp</div>'"></div>
						<div class="detail other">
							<p class="resume-input-group">
								<span class="item-title item-ect">{{ tool.name }}<span class="row-line"></span></span>
								<span class="item-sub-title item-ect">
									{{ tool.cont }}
									<span v-show="tool.level !== null && tool.level !== ''">({{ tool.level }})</span>
								</span>
							</p>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div class="empty-box" v-bind:style="{ display: resumeDisplay }">
			<img src="/images/empty.svg" alt="" />
			<div class="body_caption">
				<span class="pointer text-link bold" @click="goResumeIndex">여기</span>를 눌러 간편하게 이력서를 생성해
				보세요.
			</div>
		</div>
	</div>
</template>

<script>
// eslint-disable-next-line import/default
import resumePathMixin from '../../mixins/resumePathMixin';

export default {
	name: 'ResumePrintComponent',
	mixins: [resumePathMixin],
	props: [
		'pmNo',
		'applyNo',
		'isFinishInterview',
		'display',
		'isShowInfo',
		'applyType',
		'pmCompanyUserType',
		'userInfo',
	],
	data() {
		return {
			resume: false,
			resumeDisplay: 'none',
			name: '',
			email: '',
			mobile: '',
			sentence: '',
			strength: '',
			vision: '',
			education: [],
			career: [],
			award: [],
			lang: [],
			cert: [],
			tool: [],
		};
	},
	computed: {
		bizApplyUserName() {
			return this.userInfo.headTitle;
		},
	},
	created() {
		this.getResumeInfo();
	},
	methods: {
		goResumeIndex() {
			window.location.href = this.$_resumeIndexPath;
		},
		getResumeInfo() {
			if (parseInt(this.pmNo) >= 0) {
				//이력서 보기-기존이력서
				axios({
					method: 'GET',
					url: '/api/resume/legacy/' + this.pmNo,
				})
					.then(response => {
						//console.log(response.data);
						if (response.data.code === 501) return false;
						if (response.data.resume_info.code === 200) {
							this.resume = true;

							const user_info = response.data.resume_info.user_info;

							if (user_info.name) {
								this.name = user_info.name;
							}

							if (user_info.mobile) {
								this.mobile = user_info.mobile;

								if (this.mobile.length === 11) {
									this.mobile =
										this.mobile.substr(0, 3) +
										'-' +
										this.mobile.substr(3, 4) +
										'-' +
										this.mobile.substr(7, 4);
								} else if (this.mobile.length === 10) {
									this.mobile =
										this.mobile.substr(0, 3) +
										'-' +
										this.mobile.substr(3, 3) +
										'-' +
										this.mobile.substr(6, 4);
								}
							}

							if (user_info.u_email) {
								this.email = user_info.email;
							}

							this.doPutResume('resume', response.data.resume_info);
						} else {
							this.resumeDisplay = 'block';
						}
					})
					.catch(error => {
						console.log(error);
					});
			} else {
				//지원이력 - 기존이력서
				axios({
					method: 'GET',
					url: '/api/apply/detail',
					params: {
						apply_no: this.applyNo,
						location: 'resume',
					},
				})
					.then(response => {
						// console.log(response.data.list.resume);
						if (response.data.code === 200) {
							this.resume = true;
							this.doPutResume('apply', response.data.list.resume);
						}
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		doPutResume(type, resume) {
			if (type === 'resume') {
				//이력서 view
				resume = resume.resume_info;
				if (resume.abstract != null) {
					this.sentence = resume.abstract.pr_sentence;
					if (resume.abstract.pr_strength != null) {
						this.strength = resume.abstract.pr_strength;
					}
					if (resume.abstract.pr_vision != null) {
						this.vision = resume.abstract.pr_vision;
					}
				}

				if (resume.education.length >= 1) {
					for (let i = 0; i < resume.education.length; i++) {
						this.education.push({
							degree: resume.education[i].degree,
							enter: resume.education[i].entrance,
							graduate: resume.education[i].graduate,
							belong: resume.education[i].univ,
							major: resume.education[i].major,
							grade: resume.education[i].grade,
							eduDesc: resume.education[i].descrip,
						});
					}
				}

				if (resume.career.length >= 1) {
					for (let i = 0; i < resume.career.length; i++) {
						this.career.push({
							company: resume.career[i].company,
							dep: resume.career[i].dep,
							position: resume.career[i].position,
							startDate: resume.career[i].s_date,
							endDate: resume.career[i].e_date,
							cont: resume.career[i].cont,
							role: resume.career[i].role,
						});
					}
				}

				if (resume.foreign.length >= 1) {
					for (let i = 0; i < resume.foreign.length; i++) {
						this.lang.push({
							lang: resume.foreign[i].lang,
							exam: resume.foreign[i].exam,
							grade: resume.foreign[i].grade,
							date: resume.foreign[i].acquisition,
						});
					}
				}

				if (resume.qualification.length >= 1) {
					for (let i = 0; i < resume.qualification.length; i++) {
						this.cert.push({
							cert: resume.qualification[i].certification,
							company: resume.qualification[i].publisher,
							date: resume.qualification[i].acquisition,
							pass: resume.qualification[i].pass,
						});
					}
				}
			} else if (type === 'apply') {
				let user_info = resume.user_info;

				if (user_info.name) {
					this.name = user_info.name;
				}

				if (user_info.mobile) {
					this.mobile = user_info.mobile;

					if (this.mobile.length === 11) {
						this.mobile =
							this.mobile.substr(0, 3) + '-' + this.mobile.substr(3, 4) + '-' + this.mobile.substr(7, 4);
					} else if (this.mobile.length === 10) {
						this.mobile =
							this.mobile.substr(0, 3) + '-' + this.mobile.substr(3, 3) + '-' + this.mobile.substr(6, 4);
					}
				}

				if (user_info.email) {
					this.email = user_info.email;
				}

				if (resume.abstract != null) {
					this.sentence = resume.abstract.pr_sentence;
					this.strength = resume.abstract.pr_strength;
					this.vision = resume.abstract.pr_vision;
				}

				if (resume.edu && resume.edu.length >= 1) {
					for (let i = 0; i < resume.edu.length; i++) {
						this.education.push({
							degree: resume.edu[i].degree,
							enter: resume.edu[i].entrance,
							graduate: resume.edu[i].graduate,
							belong: resume.edu[i].univ,
							major: resume.edu[i].major,
							grade: resume.edu[i].grade,
							eduDesc: resume.edu[i].descrip,
						});
					}
				}

				if (resume.career && resume.career.length >= 1) {
					for (let i = 0; i < resume.career.length; i++) {
						this.career.push({
							company: resume.career[i].company,
							dep: resume.career[i].dep,
							position: resume.career[i].position,
							startDate: resume.career[i].s_date,
							endDate: resume.career[i].e_date,
							cont: resume.career[i].cont,
							role: resume.career[i].role,
						});
					}
				}

				if (resume.lang && resume.lang.length >= 1) {
					for (let i = 0; i < resume.lang.length; i++) {
						this.lang.push({
							lang: resume.lang[i].lang,
							exam: resume.lang[i].exam,
							grade: resume.lang[i].grade,
							date: resume.lang[i].acquisition,
						});
					}
				}

				if (resume.cert && resume.cert.length >= 1) {
					for (let i = 0; i < resume.cert.length; i++) {
						this.cert.push({
							cert: resume.cert[i].certification,
							company: resume.cert[i].publisher,
							date: resume.cert[i].acquisition,
							pass: resume.cert[i].pass,
						});
					}
				}
			}

			if (resume.tool && resume.tool.length >= 1) {
				for (let i = 0; i < resume.tool.length; i++) {
					let level = '';
					if (parseInt(resume.tool[i].level) === 1) level = '상';
					else if (parseInt(resume.tool[i].level) === 2) level = '중';
					else if (parseInt(resume.tool[i].level) === 3) level = '하';
					this.tool.push({
						name: resume.tool[i].name,
						level: level,
						cont: resume.tool[i].detail,
					});
				}
			}

			if (resume.award && resume.award.length >= 1) {
				for (let i = 0; i < resume.award.length; i++) {
					this.award.push({
						prizeDate: resume.award[i].p_date,
						company: resume.award[i].company,
						prize: resume.award[i].prize,
						cont: resume.award[i].cont,
						role: resume.award[i].descrip,
					});
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
.synthesis-box {
	margin-bottom: 60px;
}
</style>
