<template>
	<div>
		<biz-side-component num="5"></biz-side-component>
		<section class="section section-left bg-gray pb-20">
			<div class="container text-left">
				<biz-company-header-component
					main="자주하는 질문"
					sub1="자주하는 질문을 통해 궁금증을 해결하세요. 해결되지 않는 문의사항은 1:1 문의를 통해 연락주시면 최대한 빠르게 답변해 드리겠습니다."
				></biz-company-header-component>
				<div class="bg-white mt-40">
					<div
						v-for="(item, index) in faq"
						class="cursor-pointer border-top border-bottom pd-20"
						@click="viewAnswer(index)"
					>
						<img class="dashboard-logo f-brand faq-bullet" src="/images/green_arrow_right.svg" />
						<span class="h7 faq-title" v-text="item.question"></span>
						<p class="h7 f-regular faq-detail" v-html="$options.filters.nl2br(item.answer)"></p>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	props: ['faq'],
	data() {
		return {
			answer: '',
			animationClass: '',
			flag: 0,
			beforeIndex: '',
		};
	},
	methods: {
		viewAnswer(index) {
			let faq_detail = $('.faq-detail').eq(index);
			let faq_title = $('.faq-title').eq(index);
			let faq_bullet = $('.faq-bullet').eq(index);

			if (faq_detail.is(':hidden')) {
				faq_detail.slideDown('200', function () {});
				faq_title.addClass('f-semi-bold');
				faq_bullet.animate(
					{ borderSpacing: 90 },
					{
						step: function (now, fx) {
							$(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
							$(this).css('-moz-transform', 'rotate(' + now + 'deg)');
							$(this).css('transform', 'rotate(' + now + 'deg)');
						},
						duration: '200',
					},
					'linear',
				);
			} else {
				faq_detail.slideUp('200', function () {});
				faq_title.removeClass('f-semi-bold');
				faq_bullet.animate(
					{ borderSpacing: 0 },
					{
						step: function (now, fx) {
							$(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
							$(this).css('-moz-transform', 'rotate(' + now + 'deg)');
							$(this).css('transform', 'rotate(' + now + 'deg)');
						},
						duration: '200',
					},
					'linear',
				);
			}
		},
	},
};
</script>

<style lang="less" scoped>
.faq-detail {
	display: none;
	padding: 10px 10px 24px 27px;
}
.faq-bullet {
	padding-right: 5px;
}
</style>
