<template>
	<Typography type="body2" color="gray800" :style="computedLine">
		{{ contents }}
	</Typography>
</template>

<script>
export default {
	name: 'ListBody',
	props: {
		//list에 들어가는 contents
		contents: {
			type: String,
			default: null,
		},
		// 몇줄까지 보여줄것인가
		truncateLine: {
			type: Number,
			default: 1,
		},
	},
	computed: {
		computedLine() {
			return `-webkit-line-clamp:` + this.truncateLine;
		},
	},
};
</script>

<style scoped lang="less">
* {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	word-break: keep-all;
}
</style>
