<template>
	<a :href="getToURL" :target="target" v-bind="$attrs" v-on="$listeners" @click="openAppBrowser">
		<slot />
	</a>
</template>

<script>
import appCommandMixin from '../../mixins/appCommandMixin';

export default {
	name: 'CustomLink',
	mixins: [appCommandMixin],
	props: {
		to: {
			type: String,
			default: '/',
		},
		target: {
			type: String,
			default: '_self',
		},
	},
	computed: {
		getToURL() {
			if (this.isExternalLinkInApp) {
				return 'javascript:void(0)';
			}
			return this.to;
		},
		isExternalLinkInApp() {
			return this.isApp && this.target === '_blank';
		},
	},
	methods: {
		openAppBrowser() {
			if (this.isExternalLinkInApp && !this.isEmptyData(this.to)) {
				this.$_app_openExternalBrowser(this.to);
			}
		},
	},
};
</script>

<style scoped></style>
