<template>
	<!--contents-caption-->
	<dl class="contents_caption_component" :class="[computedAlign]">
		<!--dt 내용은 안보입니다.-->
		<dt>{{ captionDefinition }}</dt>
		<dd>{{ contentsCaption | dateFormat }}</dd>
	</dl>
</template>

<script>
export default {
	name: 'ContentsCaption',
	props: {
		captionDefinition: {
			type: String,
			default: 'definition(설명,내용)',
		},
		//contents caption 내용
		contentsCaption: {
			type: String,
			default: null,
		},
		//좌, 우 방향 설정
		captionAlign: {
			type: String,
			default: 'none',
		},
	},
	computed: {
		computedAlign() {
			return `contents_caption_align_${this.captionAlign}`;
		},
	},
};
</script>
<style scoped lang="less">
@import '../../../../../less/base/index';
.contents_caption_component {
	position: relative;
	margin-bottom: 8px;
	&.contents_caption_align_left {
		float: left;
	}
	&.contents_caption_align_right {
		float: right;
	}
	&.contents_caption_align_none {
		float: none;
	}
	dt {
		display: none;
	}
}
</style>
