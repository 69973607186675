<template>
	<div>
		<div v-for="(data, index) in tutorial">
			<transition v-if="idx === index" name="modal">
				<div v-show="show" class="tutorial-mask" @click="close">
					<div class="tutorial-container" @click.stop>
						<img class="box-remove icon-sm-md pointer" src="/images/gray_remove.svg" @click="close" />
						<div class="tutorial-body">
							<div class="inline-block float-left content-one">
								<img src="/images/co_smile.svg" class="co" />
							</div>
							<div class="mb-10 float-right content-two">
								<span class="font-caption text-caption">코대리</span>
								<p class="font" v-html="data.message"></p>
							</div>
						</div>
						<div class="clear-both text-right">
							<button v-if="data.btnName.length > 1" class="btn btn-close" @click="close">
								{{ data.btnName[1] }}
							</button>
							<button class="btn btn-outline" @click="go(index)">{{ data.btnName[0] }}</button>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	props: ['show'],
	data() {
		return {
			idx: 0,
			tutorial: [
				{
					message:
						'코멘토 로보리크루팅센터에 오신 것을 환영합니다.<br>코멘토는 <b>딱 맞는 신입, 인턴 채용</b>을 도와드립니다.',
					btnName: ['다음'],
				},
				{
					message: '로보리크루팅센터는 기업 채용담당가 채용정보를 등록하고 지원자를 관리하는 공간입니다.',
					btnName: ['다음'],
				},
				{
					message:
						'채용공고를 등록하면 딱 맞는 인재를 채용할 때까지 AI를 통해 지원자 탐색을 도와드립니다.<br><br>1. 기업 인재상에 맞는 잠재 지원자에게 타겟광고<br>2. AI 인재검색을 통한 잠재후보자 추천',
					btnName: ['다음'],
				},
				{
					message: '인재 채용을 시작하려면 기업정보와 채용정보 <b>두 가지 정보 등록</b>이 필요합니다.',
					btnName: ['다음'],
				},
				{
					//사이드
					message:
						'먼저 기업정보관리 메뉴에서 기업정보를 등록합니다. 기업 정보는 <b>한 번만 등록하면 모든 채용에 공통적으로 적용</b>됩니다.',
					btnName: ['다음'],
				},
				{
					//사이드
					message:
						'그 다음으로는 신규채용정보등록 메뉴에서 채용 정보를 등록할 수 있습니다. 채용정보는 <b>채용직무별로 등록</b>하며, 등록된 공고는 채용정보관리 메뉴에서 수정할 수 있습니다.',
					btnName: ['다음'],
				},
				{
					//사이드
					message:
						'로보리크루터가 매칭한 인재가 지원하면 지원자관리 메뉴에서 해당 지원자의 서류검토 및 면접일정잡기, 불합격 통보 등 모든 전형 진행 과정을 <b>시스템을 통해 자동으로 관리</b>할 수 있습니다.',
					btnName: ['다음'],
				},
				{
					//사이드
					message: '인재채용과 관련된 궁금한 사항은 자주묻는질문이나 1:1실시간문의 메뉴를 이용해주세요.',
					btnName: ['다음'],
				},
				{
					message: '첫 번째 공고는 30일간 무료로 체험할 수 있습니다.<br>지금 무료로 채용공고를 등록하겠어요?',
					btnName: ['무료로 시작', '나중에'],
				},
			],
		};
	},
	methods: {
		close() {
			this.idx = 0;
			$('.arrow').css({ display: 'none' });
			$('nav.nav-bar').css({ 'z-index': '9' });
			this.$emit('close');
		},
		go(idx) {
			this.idx += 1;
			if (idx === 3) {
				$($('.arrow')[0]).css({ display: 'block' });
				$('nav.nav-bar').css({ 'z-index': '11' });
			} else if (idx === 4) {
				$('.arrow').css({ display: 'none' });
				$($('.arrow')[1]).css({ display: 'block' });
				$($('.arrow')[2]).css({ display: 'block' });
				$('nav.nav-bar').css({ 'z-index': '11' });
			} else if (idx === 5) {
				$('.arrow').css({ display: 'none' });
				$($('.arrow')[3]).css({ display: 'block' });
				$('nav.nav-bar').css({ 'z-index': '11' });
			} else if (idx === 6) {
				$('.arrow').css({ display: 'none' });
				$($('.arrow')[4]).css({ display: 'block' });
				$('nav.nav-bar').css({ 'z-index': '11' });
			} else if (idx === 7) {
				$('.arrow').css({ display: 'none' });
				$('nav.nav-bar').css({ 'z-index': '9' });
			} else if (idx === 8) {
				location.href = 'http://biz.localhost/company/info/1';
			}
		},
	},
};
</script>

<style lang="less" scoped>
* {
	box-sizing: border-box;
}

.tutorial-mask {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.3s ease;
}

.tutorial-container {
	position: relative;
	top: 83%;
	width: 485px;
	background-color: #fff;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	transform: translate(-50%, -50%);
	border: 3px solid #00c853;
	padding: 25px;
	float: left;
	border-radius: 3px;
	left: 500px;
}
@media (max-width: 480px) {
	.tutorial-container {
		left: 185px;
		top: 75%;
		width: 90% !important;
	}
}

.box-remove {
	width: 20px;
	float: right;
	position: relative;
	top: -15px;
	right: -15px;
}

.modal-header h4 {
	margin-top: 0;
	color: #42b983;
}
.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}
.content {
	&-one {
		width: 25%;
	}
	&-two {
		width: 70%;
	}
}
.co {
	width: 80px;
	height: 80px;
}
</style>
