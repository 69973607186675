<template>
	<div>
		<template v-if="$_isOfficialMentor(userNo)">
			<div class="mentor-official clearfix">
				<c-avatar
					:id="userNo"
					type="image"
					:src="`${cdnBaseUrl}/images/partner/logo-mentorOfficialAc.svg`"
					:size="size"
				/>
			</div>
		</template>
		<c-avatar v-else-if="isDormancy" type="profile" :size="avatarSize" />
		<template v-else>
			<c-avatar v-if="userProfileImage" :id="userNo" type="image" :src="getUserProfileImageUrl" :size="size" />
			<c-avatar v-else :id="userNo" type="nickname" :text="avatarText" :size="size" />
		</template>
	</div>
</template>

<script>
import caseMixin from '../../../../mixins/case/caseMixin';
import { cdnBaseUrl } from '../../../../constants';

export default {
	name: 'CaseProfileAvatar',
	mixins: [caseMixin],
	props: {
		userNo: {
			type: Number,
		},
		userNickname: {
			type: String,
		},
		userType: {
			type: Number,
		},
		isDormancy: {
			type: Boolean,
			default: false,
		},
		userProfileImage: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: 'small',
		},
	},
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		defaultNickname() {
			return `${this.userType === 1 ? '멘티' : '멘토'}${this.userNo}`;
		},
		dormancyText() {
			return '휴면계정';
		},
		avatarText() {
			const text = this.isDormancy ? this.dormancyText : this.userNickname || this.defaultNickname;
			return text.substr(0, 1);
		},
		getUserProfileImageUrl() {
			const sizeParam = `?s=60x60`;
			const hasBaseUrl = this.userProfileImage.includes(cdnBaseUrl);
			if (hasBaseUrl) {
				return `${this.userProfileImage}${sizeParam}`;
			}
			return `${cdnBaseUrl}/images/user/profile/${this.userProfileImage}${sizeParam}`;
		},
	},
};
</script>

<style scoped></style>
