<template>
	<div>
		<biz-side-component num="2"></biz-side-component>
		<section class="section section-left bg-gray text-center">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<div class="bg-white border pd-30">
						<div class="h4 mb-10">결제수단</div>
						<div class="border pd-20">
							<input
								id="pmt4"
								v-model="selectPayment"
								type="radio"
								:value="isPayapp ? 'payapp' : 'inicis'"
								checked
							/>
							<label for="pmt4" class="h6 f-regular mb-10">신용/체크카드</label>
							<div v-if="selectPayment === 'inicis' || selectPayment === 'payapp'" id="guide4">
								<span class="f-red">* </span>결제용 휴대폰 번호
								<input v-model="mobile" class="inputbox inputbox-lg" type="text" name="m" />
								<div class="date text-heading only-mobile">
									- 입력한 번호로 PAYAPP 결제 링크가 발송되며, 별도의 앱 설치 없이 결제가 가능합니다.
								</div>
							</div>
						</div>

						<div class="h4 mt-30 mb-10">주문정보</div>
						<div class="border">
							<div class="bg-gray pd-20 border-bottom">
								<span class="h6 f-regular">{{ itemInfo.name }}</span>
							</div>
							<div class="pd-20">
								<div class="h5">
									<span>결제금액</span>
									<span class="float-right">{{ itemInfo.sales_price | numberFormat }} 원</span>
								</div>
							</div>
						</div>

						<div class="f-regular h7 text-left mt-30 mb-10">
							<input id="agree" v-model="agree" class="pay-btn" type="checkbox" />
							<label class="cursor-pointer h4 pay-label" for="agree"
								><span class="f-red">* </span>상품 구매 주의사항에 동의합니다.</label
							>
						</div>
						<div class="border pd-20 f-regular">
							<h6 class="f-red">[구매 주의사항]</h6>
							<p>
								결제 진행 후 채용공고에 대한 관리자 확인이 진행되며, 관리자 승인 후 매칭이
								시작됩니다.<br />
								관리자
								<u class="f-red"
									>승인 전까지 청약 철회가 가능하나, 승인 후 매칭 광고가 시작되면 청약 철회가
									불가능</u
								>합니다.
							</p>
							<span
								>매칭 광고운영은 <strong>{{ dt }} 까지</strong> 진행되며 자동 연장되지 않습니다.</span
							>
						</div>
						<div class="mt-20">
							<button v-if="agree" class="cursor-pointer btn btn-full btn btn-lg" @click="goPayment">
								결제하기
							</button>
							<button v-else class="pay-btn-disabled">결제하기</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Vue from 'vue';

export default {
	props: ['itemInfo', 'isApp', 'dt', 'userMobile', 'userEmail', 'pmComNo', 'userNo'],
	data() {
		return {
			mobile: this.userMobile,
			agree: false,
			date: '2019-01-20',
			selectPayment: 'inicis',
			isPayapp: false,
			orderNo: '',
			orderId: '',
		};
	},
	created() {
		//콘텐츠PC: 이니시스, 콘텐츠Mobile/분석기: 페이앱(19.01.13)
		if (this.isApp === 1 || this.detectmob() || this.isMobile) {
			this.selectPayment = 'payapp';
			this.isPayapp = true;
		}
	},
	mounted() {
		this.$nextTick(() => {
			// Vue.IMP().install(Vue,'imp09388674');
			Vue.IMP.load();
		});
	},
	methods: {
		detectmob() {
			if (
				navigator.userAgent.match(/Android/i) ||
				navigator.userAgent.match(/webOS/i) ||
				navigator.userAgent.match(/iPhone/i) ||
				navigator.userAgent.match(/iPad/i) ||
				navigator.userAgent.match(/iPod/i) ||
				navigator.userAgent.match(/BlackBerry/i) ||
				navigator.userAgent.match(/Windows Phone/i)
			) {
				return true;
			} else {
				return false;
			}
		},
		goPayment() {
			if (this.selectPayment === 'inicis') {
				const merchant_uid = 'merchant_' + new Date().getTime();

				Vue.IMP.request_pay(
					{
						pg: 'html5_inicis',
						pay_method: 'card',
						merchant_uid: merchant_uid,
						name: this.itemInfo.name,
						amount: this.itemInfo.sales_price,
						buyer_tel: this.mobile,
						buyer_email: this.userEmail,
						buyer_name: this.userEmail,
					},
					result_success => {
						//성공할 때 실행 될 콜백 함수
						axios({
							method: 'POST',
							url: '/api/payment/inicis/check',
							data: {
								status: 'ok',
								uid: result_success.imp_uid,
								merchant_uid: result_success.merchant_uid,
								paid_amount: result_success.paid_amount,
								apply_num: result_success.apply_num,
								user_no: this.userNo,
								item_no: this.itemInfo.no,
								pm_com_no: this.pmComNo,
							},
						})
							.then(({ data }) => {
								if (data.code === 200) {
									window.location.href = data.url;
								}
							})
							.catch(error => {
								let msg = '결제에 실패하였습니다.';
								msg += '에러내용 : ' + error.toString();
								closeLoading();
								alert(msg);
							});
					},
					result_failure => {
						let msg = '결제에 실패하였습니다.';
						msg += '에러내용 : ' + result_failure.error_msg;
						axios({
							method: 'POST',
							url: '/api/payment/inicis/check',
							data: {
								status: result_failure.error_msg,
								user_no: this.userNo,
								item_no: this.itemInfo.no,
								credit: this.creditCur,
							},
						})
							.then(({ data }) => {
								closeLoading();
							})
							.catch(error => {
								closeLoading();
							});
					},
				);
			} else {
				// let requestData = {
				//     pg_type: this.selectPayment,
				//     item_no: this.itemInfo.no,
				//     credit: this.creditCur,
				//     final_credit: this.finalCredit,
				// };
				//
				// requestData.phone = this.mobile;
				//
				// axios({
				//     method: 'POST',
				//     url: '/api/payment/item/request',
				//     data: requestData,
				// }).then(({data})) => {
				//     // console.log(response);
				//     if (requestData.pg_type === 'credit') {
				//         if (parseInt(data.credit_status) === 1) {
				//             window.location.href = data.previous_url;
				//         } else {
				//             closeLoading();
				//             this.title = '결제 오류';
				//             this.message = '에러가 발생했습니다. 잠시 후 다시 시도해주세요.<br>' +
				//                 '에러가 계속되면 고객센터로 문의해주세요.';
				//             this.buttonName = '';
				//             this.successCallback = '';
				//             this.showAlert = true;
				//         }
				//     } else {
				//         if (data.status === 200 || parseInt(data.state) === 1) {
				//             if (requestData.pg_type === 'toss') { //data.status 200
				//                 //checkoutPage로 보내면 '/payment/result'로 이동
				//                 window.location.href = data.checkoutPage;
				//             } else if(requestData.pg_type === 'payapp') { //data.state 1
				//                 window.location.href = data.payurl;
				//             }
				//         } else {
				//             closeLoading();
				//             this.title = '결제 오류';
				//             this.message = '에러가 발생했습니다. 잠시 후 다시 시도해주세요.<br>' +
				//                 '에러가 계속되면 고객센터로 문의해주세요.';
				//             this.buttonName = '';
				//             this.successCallback = '';
				//             this.showAlert = true;
				//         }
				//     }
				// }).catch(error => {
				//     console.log(error);
				//     closeLoading();
				// });
			}
		},
	},
};
</script>

<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
