<template>
	<div id="joinAd" class="login popup">
		<c-box :has-border="!isMobile" border-color="gray200" :paddings="[60, 38.5, 60, 38.5]" background-color="white">
			<c-typography
				type="headline3"
				color="gray850"
				font-weight="semi-bold"
				class="mb-40"
				style="line-height: 140%; font-size: 24px"
			>
				시작하는 사람들의<br />
				커리어 커뮤니티
			</c-typography>

			<div v-if="isAnotherLogin">
				<div class="register-buttons-container">
					<template v-for="name in loginTypes">
						<RegisterButtons
							:key="`loginType${name}`"
							full
							:type="name.toLowerCase()"
							@click="setGtm(name)"
						/>
					</template>
				</div>
				<c-typography element="p" type="caption1" color="gray400" class="agreement-wrapper mb-8" align="center">
					<CustomLink to="/agreement">
						<c-typography element="u" type="caption1" color="gray600"> 이용약관 </c-typography>
					</CustomLink>
					및
					<CustomLink to="/agreement">
						<c-typography element="u" type="caption1" color="gray600"> 개인정보 처리방침 </c-typography>
					</CustomLink>
					확인 후 동의합니다
				</c-typography>
			</div>
			<div v-else class="login_btn_wrapper clearfix">
				<template v-if="loginTypes">
					<RegisterButtons
						:type="loginTypes[0].toLowerCase()"
						:recent-login="recentLoginType"
						full
						class="mb-12"
						@click="handlePrimaryLogin(loginTypes[0])"
					/>
					<AnotherLoginButton @click="isAnotherLogin = true" />
				</template>
			</div>
		</c-box>
	</div>
</template>

<script>
import routeMixin from '../../mixins/routeMixin';
import RegisterButtons from './register/RegisterButtons.vue';
import AnotherLoginButton from '../user/login/AnotherLoginButton.vue';
import loginMixin from '../../mixins/auth/loginMixin';
import CustomLink from '../common/CustomLink.vue';
const DEFAULT_COLOR = 'primary';

export default {
	name: 'JoinAdPopupComponent',
	mixins: [routeMixin, loginMixin],
	props: {
		page: {
			type: String,
			default: '',
		},
		keyword: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		isAnotherLogin: false,
		login_keep: '',
		csrf: '', //추후 if문 처리
		recentLoginType: null,
		emailColor: DEFAULT_COLOR,
		passwordColor: DEFAULT_COLOR,
	}),
	computed: {
		loginTypes() {
			let loginTypes = ['kakao', 'naver', 'facebook', 'google'];
			if (this.$_getLoginSocialTypeInCookie()) {
				this.recentLoginType = this.$_getLoginSocialTypeInCookie();
				this.$_moveIndex(
					loginTypes,
					loginTypes.findIndex(type => type === this.recentLoginType),
					0,
				);
			}
			return loginTypes;
		},
		mobileMargin() {
			let margin = '';

			if (this.isMobile) margin = 'margin-bottom: 20px';

			return margin;
		},
	},
	mounted() {
		this.$nextTick(() => {
			$('.custom-feed-blinder').css('display', 'block');
			$('.header-container header').css('z-index', '1');
			$('#join_ad_popup').css('display', 'block');

			if (this.page === 'case' || this.page === 'jobcard') {
				//미가입자 스크롤 막기
				this.disableScroll();
			}
		});
	},
	methods: {
		closeJoinAdPopup() {
			$('#join_ad_popup').css('display', 'none');
			$('.custom-feed-blinder').css('display', 'none');
			$('.header-container header').css('z-index', '8001');
			$('#join_ad_footer').css('display', 'block');
		},
		disableScroll() {
			// 모달팝업 중 html,body의 scroll을 hidden시킴
			$('html, body').css({ overflow: 'hidden', height: '100%' });
			// 터치무브와 마우스휠 스크롤 방지
			$('.custom-feed-blinder').on('scroll touchmove mousewheel', event => {
				event.preventDefault();
				event.stopPropagation();
				return false;
			});
		},
		setGtm(signupType) {
			this.$_storeLoginSocialTypeInCookie(signupType);
			// GTM - registerButtonClick
			registerButtonClick(signupType, 'Register', this.comentoLocalStorageId);
			window.location.href = '/login/' + signupType.toLowerCase();
		},
		handlePrimaryLogin(socialType) {
			this.setGtm(socialType);
			const path = `${socialType.substring(0, 1).toLowerCase()}${socialType.substring(1)}`;
			this.$_routeMixin_href(`/login/${path}`);
		},
	},
	components: {
		CustomLink,
		AnotherLoginButton,
		RegisterButtons,
	},
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';
#joinAd {
	max-width: 343px;
	width: 100%;
	.register-buttons-container {
		width: 100%;
		@include flex-flow(column);
		margin-bottom: 24px;
	}
}
</style>
