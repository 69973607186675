<template>
	<div class="inputbox-group">
		<ValidationProvider v-slot="{ errors }" :name="label" :rules="rules">
			<label v-if="!hideLabel">
				<img v-if="labelImg" :src="`/images/${labelImg}`" class="inputbox-label-icon" alt="" />
				<b v-html="label"></b>
			</label>
			<p v-if="labelDescription" class="h7 mb-5" v-html="labelDescription"></p>
			<input
				v-model="sync_value"
				:type="type"
				:placeholder="placeholder"
				class="inputbox"
				:class="validStyle(errors)"
				:style="{ width: `${width}` }"
				:maxLength="maxLength"
				:readonly="readonly"
				:disabled="disabled"
				:name="name"
				:min="min"
				:max="max"
				v-on="listeners"
			/>
			<span
				class="error-text"
				v-html="errorMessage !== '' && errorMessage !== null ? errorMessage : errors[0]"
			></span>
		</ValidationProvider>
	</div>
</template>

<script>
export default {
	props: {
		label: String,
		labelImg: String,
		labelDescription: String,
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'text',
			validator: val => {
				return ['url', 'text', 'password', 'email', 'search', 'tel', 'number'].indexOf(val) !== -1;
			},
		},
		rules: {
			type: String,
			default: 'required',
		},
		value: [String, Number],
		placeholder: {
			type: String,
			default: '내용을 입력하세요',
		},
		width: String,
		maxLength: Number,
		name: String,
		errorMessage: {
			type: String,
			default: null,
		},
		min: Number,
		max: Number,
		hideLabel: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		sync_value: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('update:value', val);
			},
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.$emit('input', event.target.value),
			};
		},
	},
	watch: {
		sync_value() {
			if (this.type === 'tel') this.sync_value = this.$_phoneNumberFormat(this.sync_value);
		},
	},
	methods: {
		validStyle(errors) {
			const nicknameValidate =
				this.errorMessage !== null &&
				this.errorMessage !== '' &&
				!this.errorMessage.includes('사용 가능한 닉네임입니다');

			if (errors.length > 0 || nicknameValidate) return 'inputbox-error';
		},
	},
};
</script>

<style lang="less" scoped>
.inputbox {
	display: block;

	&-group {
		margin-bottom: 15px;
	}

	&-label-icon {
		height: 15px;
	}

	&:hover,
	&:focus,
	&:active {
		border-color: #2a7de1;
	}
}
</style>
