<template>
	<Typography type="headline7" class="text-truncate" color="gray900" element="h3" style="margin-bottom: 8px">
		{{ title }}
	</Typography>
</template>

<script>
export default {
	name: 'List',
	props: {
		//list에 들어가는 title
		title: {
			type: String,
			default: null,
		},
	},
};
</script>

<style scoped lang="less"></style>
