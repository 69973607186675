const date = {
	methods: {
		$_isBeforeToday(date) {
			let today = new Date();
			today.setHours(0, 0, 0, 0);
			return date < today;
		},
		$_isAfterNWeek(date, nWeek) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);

			return date < new Date(today.getTime() + 7 * nWeek * 24 * 3600 * 1000);
		},
	},
};

export default date;
