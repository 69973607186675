import { CASE_PATH } from '../../constants/paths';

const caseMixin = {
	methods: {
		$_isOfficialMentor(userNo) {
			return userNo === 542850;
		},
		$_getNickname(nickname, userType, userNo) {
			return nickname || this.$_getDefaultNickname(userType, userNo);
		},
		$_getDefaultNickname(userType, userNo) {
			return `${userType === 1 ? '멘티' : '멘토'}${userNo}`;
		},
		$_getCaseGTMCategory(category) {
			if (this.isEmptyData(category)) return null;
			return category.replace(/ /gi, '');
		},
		$_handleMakeUrl(company = '모든회사', position = '모든직무', title = '_', request_no = 0) {
			company = this.isEmptyData(company?.trim()) ? '모든회사' : company;
			position = this.isEmptyData(position?.trim()) ? '모든직무' : position;
			title = this.isEmptyData(title?.trim()) ? '_' : title;
			request_no = request_no ?? 0;

			company = company.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}\n‘’㈜]|\s+/gi, '');
			position = position.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}\n‘’㈜]|\s+/gi, '');
			title = title.replace(/\s+/g, '_');

			return `${CASE_PATH}/${company}/${position}/${title}-${request_no}`;
		},
	},
};

export default caseMixin;
