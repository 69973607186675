export const blog = {
	data() {
		return {
			blogMagazines: [],
		};
	},
	created() {
		this.getBlogMagazines();
	},
	methods: {
		async getBlogMagazines() {
			axios({
				method: 'GET',
				url: '/api/blog/category/list',
			})
				.then(response => {
					// console.log(response);
					const data = response.data;
					this.blogMagazines = data.list;
				})
				.catch(error => {
					console.log(error);
				});
		},
	},
};
