<template>
	<span :style="styles" class="skeleton" />
</template>

<script>
export default {
	name: 'BaseSkeleton',
	props: {
		maxWidth: {
			default: 100,
			type: Number,
		},
		minWidth: {
			default: 80,
			type: Number,
		},
		height: {
			default: '1em',
			type: String,
		},
		width: {
			default: null,
			type: String,
		},
		circle: {
			default: false,
			type: Boolean,
		},
		borderRadius: {
			type: [Number, String],
			default: '4px',
		},
	},
	computed: {
		styles() {
			return {
				height: this.height,
				width: this.computedWidth,
				'--border-radius': this.computedBorderRadius,
			};
		},
		computedWidth() {
			return this.width || `${Math.floor(Math.random() * (this.maxWidth - this.minWidth))}%;`;
		},
		computedBorderRadius() {
			return this.circle ? '50px' : this.borderRadius;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.skeleton {
	display: inline-block;
	vertical-align: middle;
	background-color: $gray200;
	position: relative;
	overflow: hidden;
	border-radius: var(--border-radius);

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba($gray000, 0) 0,
			rgba($gray000, 0.2) 20%,
			rgba($gray000, 0.5) 60%,
			rgba($gray000, 0)
		);
		animation: shimmer 2s infinite;
		content: '';
		border-radius: var(--border-radius);
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
}
</style>
