<template>
	<div>
		<biz-side-component num="2"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<biz-company-header-component
						main="급여내역 입력"
						sub1="채용공고에서 공개 가능한 수준의 급여내역을 입력하세요. 공개가 불가능한 경우 입력하지 않을 수 있지만 상세한 급여정보를 입력할 수록 딱 맞는 인재를 만날 가능성이 높아집니다."
					/>
					<div class="f-red font-caption mt-50">* 필수항목</div>
					<div class="bg-white border pd-30">
						<div>
							<span class="font-title f-semi-bold">급여 정책</span>
							<span class="f-red">*</span>
							<div class="text-caption f-light font-caption">본 채용 직무의 급여 정책을 선택하세요.</div>
						</div>
						<div class="mt-10 mb-50">
							<div>
								<input
									id="year"
									v-model="companySalary"
									class="com-radio"
									type="radio"
									value="연봉제"
								/><label class="font" for="year">연봉제</label>
							</div>
							<div>
								<input
									id="month"
									v-model="companySalary"
									class="com-radio"
									type="radio"
									value="월 급여제"
								/><label class="font" for="month">월 급여제</label>
							</div>
							<div>
								<input
									id="otherSalary"
									v-model="companySalary"
									class="com-radio"
									type="radio"
									value="기타"
									@click="salaryCheck"
								/>
								<label class="font" for="otherSalary">
									기타
									<input
										v-model="salary"
										class="com-inputbox-short font f-light pd-10 ml-20"
										type="text"
										placeholder="기타 급여 기준을 입력하세요."
									/>
								</label>
							</div>
						</div>

						<div v-if="companySalary === '연봉제'">
							<div>
								<span class="font-title f-semi-bold">급여 수준</span>
								<span class="f-red">*</span>
								<div class="text-caption f-light font-caption">
									구체적으로 입력할 수록 매칭 가능성이 높아집니다.
								</div>
							</div>
							<div class="mt-10 mb-50">
								<div>
									<input
										id="interview1"
										v-model="pay"
										class="com-radio"
										type="radio"
										value="면접 후 협의"
									/><label class="font" for="interview1">면접 후 협의</label>
								</div>
								<div>
									<input
										id="company1"
										v-model="pay"
										class="com-radio"
										type="radio"
										value="회사 내규에 따름"
									/><label class="font" for="company1">회사 내규에 따름</label>
								</div>
								<div>
									<input
										id="designation1"
										v-model="pay"
										class="com-radio"
										type="radio"
										value="지정"
									/>
									<label class="font" for="designation1">
										지정
										<input
											v-model="pay_etc"
											class="com-inputbox-short font f-light pd-10 ml-20"
											type="text"
											placeholder="급여 수준 (예: 2,500 ~ 2,800만원)"
										/>
									</label>
								</div>
							</div>
						</div>

						<div v-if="companySalary === '월 급여제'">
							<div>
								<span class="font-title f-semi-bold">급여 수준</span>
								<span class="f-red">*</span>
								<div class="text-caption f-light font-caption">
									구체적으로 입력할 수록 매칭 가능성이 높아집니다.
								</div>
							</div>
							<div class="mt-10 mb-50">
								<div>
									<input
										id="interview2"
										v-model="pay"
										class="com-radio"
										type="radio"
										value="면접 후 협의"
									/><label class="font" for="interview2">면접 후 협의</label>
								</div>
								<div>
									<input
										id="company2"
										v-model="pay"
										class="com-radio"
										type="radio"
										value="회사 내규에 따름"
									/><label class="font" for="company2">회사 내규에 따름</label>
								</div>
								<div>
									<input
										id="designation2"
										v-model="pay"
										class="com-radio"
										type="radio"
										value="지정"
									/>
									<label class="font" for="designation2">
										지정
										<input
											v-model="pay_etc"
											class="com-inputbox-short font f-light pd-10 ml-20"
											type="text"
											placeholder="급여 수준 (예: 150만원)"
										/>
									</label>
								</div>
							</div>
						</div>

						<div>
							<span class="h6">비급여 보상</span>
							<div class="text-caption f-light font-caption">
								임금 외에 제공되는 (또는 협의 후 제공 가능한) 비급여 보상을 입력하세요.
							</div>
						</div>
						<div class="mt-10 mb-50">
							<div>
								<input
									id="stockOption"
									v-model="benefit"
									class="com-checkbox"
									type="checkbox"
									value="스톡옵션"
								/><label class="font cursor-pointer" for="stockOption">스톡옵션</label>
							</div>
							<div>
								<input
									id="incentive"
									v-model="benefit"
									class="com-checkbox"
									type="checkbox"
									value="인센티브"
								/><label class="font cursor-pointer" for="incentive">인센티브</label>
							</div>
							<div>
								<input
									id="otherNonWage"
									v-model="benefit"
									class="com-checkbox"
									type="checkbox"
									value="기타"
								/>
								<label class="font cursor-pointer" for="otherNonWage">
									기타
									<input
										v-model="benefit_etc"
										class="com-inputbox-short font f-light pd-10 ml-20"
										type="text"
										placeholder="여기를 눌러 입력하세요."
									/>
								</label>
							</div>
						</div>
					</div>
					<div class="mt-20">
						<div class="float-left mt-20 mb-20 position-relative inline-block">
							<div class="progress-bar"></div>
							<div :class="progressBar" style="width: 270px"></div>
						</div>
						<div class="inline-block"><span class="h7 ml-10">3/3페이지</span></div>
						<div class="float-right text-left">
							<button class="cursor-pointer btn btn-close font mr-10" @click="tempSave">임시저장</button>
							<button class="cursor-pointer btn btn-outline font mr-10" @click="previous">이전</button>
							<button class="cursor-pointer btn-submit font" @click="submit">제출하기</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	props: ['metaInfo', 'recruitInfo'],
	data() {
		return {
			companySalary: '',
			salary: '',
			pay: '',
			pay_etc: '',
			benefit: [],
			benefit_etc: '',
			isWarn: false,
			warn: '',
			progressBar: '',
		};
	},
	watch: {
		salary() {
			if (this.salary !== '') {
				this.companySalary = '기타';
			}
		},
		pay_etc() {
			if (this.pay_etc !== '') {
				this.pay = '지정';
			}
		},
	},
	mounted() {
		let agent = navigator.userAgent.toLowerCase();
		if ((navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || agent.indexOf('msie') !== -1) {
			this.progressBar = 'ie-bar';
		} else {
			this.progressBar = 'point-bar';
		}

		if (this.metaInfo) {
			let benefitInfo = [];
			for (let i = 0; i < this.metaInfo.length; i++) {
				if (this.metaInfo[i].meta === 'pay_type') {
					if (this.metaInfo[i].content === '연봉제' || this.metaInfo[i].content === '월 급여제') {
						this.companySalary = this.metaInfo[i].content;
					} else if (this.metaInfo[i].content !== '') {
						this.companySalary = '기타';
						this.salary = this.metaInfo[i].content;
					}
				} else if (this.metaInfo[i].meta === 'pay') {
					if (
						this.metaInfo[i].content === '면접 후 협의' ||
						this.metaInfo[i].content === '회사 내규에 따름'
					) {
						this.pay = this.metaInfo[i].content;
					} else if (this.metaInfo[i].content !== '') {
						this.pay = '지정';
						this.pay_etc = this.metaInfo[i].content;
					}
				} else if (this.metaInfo[i].meta === 'benefit') {
					benefitInfo = this.metaInfo[i].content.split(',');

					for (let j = 0; j < benefitInfo.length; j++) {
						this.benefit.push(benefitInfo[j]);
						if (benefitInfo[j] === '기타') {
							this.benefit_etc = benefitInfo[j + 1];
							break;
						}
					}
				}
			}
		}
	},
	methods: {
		salaryCheck() {
			this.pay = '';
			this.pay_etc = '';
		},
		api(progress, no) {
			showLoading();
			axios({
				method: 'POST',
				url: '/api/company/recruit/final',
				data: {
					pay_type: this.companySalary,
					pay_type_etc: this.salary,
					pay: this.pay,
					pay_etc: this.pay_etc,
					benefit: this.benefit,
					benefit_etc: this.benefit_etc,
					com_progress: this.recruitInfo.progress,
					type: no,
				},
			})
				.then(response => {
					closeLoading();
					console.log(response.data);
					if (response.data.code === 200) {
						if (progress === 'go') {
							const first = parseInt(response.data.is_first);
							let url = '/company/finish';

							if (first === 1) {
								url = '/company/recruit';
							}

							location.href = url;
						} else if (progress === 'stay') {
							alert('임시저장 완료!');
							return false;
						}
					} else {
						alert('다시 확인해주세요!');
						return false;
					}
				})
				.catch(error => {
					closeLoading();
				});
		},
		tempSave() {
			this.api('stay', -1);
		},
		previous() {
			location.href = '/company/recruit/2?com_no=' + this.recruitInfo.no;
		},
		submit() {
			let flag = 0;
			if (this.companySalary === '') {
				alert('급여 정책을 확인해주세요!');
				return false;
			} else if (this.companySalary !== '') {
				if (this.companySalary === '기타') {
					if (this.salary === '') {
						alert('급여 정책 기타를 확인해주세요!');
						return false;
					} else {
						flag = 1;
					}
				} else {
					if (this.pay === '') {
						alert('급여 수준을 확인해주세요!');
						return false;
					} else if (this.pay === '지정' && this.pay_etc === '') {
						alert('급여 수준 기타를 확인해주세요!');
						return false;
					} else {
						flag = 1;
					}
				}
			}
			if (flag === 1) {
				this.api('go', 1);
			}
		},
	},
};
</script>

<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
