<template>
	<div>
		<p class="text-center"><br /></p>
		<p class="text-center">
			<img src="https://cdn.comento.kr/blog/2021-11-11/1636601290.png" width="714" alt="" />
		</p>
		<br />
		<h3 class="text-center">
			<CustomLink to="https://bit.ly/3ofUGJX" target="_blank" class="c-pointer" @click="handleGtm">
				<img :src="ctaButtonImage" width="652" alt="" />
			</CustomLink>
		</h3>
		<h3 class="text-center"><br /></h3>
		<h2>
			<br />
		</h2>
		<h4>데이터가 필요하지만,<br />데이터를 잘 다루는 게 어려운 우리.</h4>
		<br /><br />
		<div>
			<p>실무를 하다보면, 반드시 데이터가 필요한 순간이 있습니다.</p>
			<p>데이터를 뽑고, 활용하고, 분석하고, 성과를 측정하는 일이 중요한 업무 중 하나이기도 합니다.</p>
			<p>
				<strong>
					<u>그리고, 데이터가 필요할 때 우리는 이런 어려움도 겪습니다.</u>
				</strong>
			</p>
		</div>
		<br /><br />

		<div>
			<p>
				● 고객 대상으로 CRM 메시지를 보내야 하는데,
				<span style="color: red; margin-left: 2px">
					개발자한테 고객 데이터 뽑아달라 했더니 지금은 바빠서 안된다고...
				</span>
			</p>
			<br />
			<p>
				● 데이터팀에서 SQL로 간단한 문구만 작성하면 데이터를 빠르게 뽑을 수 있다고 해서
				<br class="only-pc" />
				<span style="color: red" :style="isMobile ? '' : 'margin-left: 20px'">
					VOD 강의를 들었는데, 혼자서 해보려니까 무슨 말인지 모르겠더라구요.
				</span>
			</p>
			<p><br /></p>
			<p>
				● SQL 강의도 몇번 들었는데,
				<span style="color: red">
					배운대로 해봐도 뽑고 싶은 데이터는 안 나오고 오류랑 엉뚱한 데이터만 나와서,
				</span>
				<br class="only-pc" />
				<span :style="isMobile ? '' : 'margin-left: 20px'">
					더 정확하게 효율적으로 하는 방법을 알고 싶은데 물어볼 데가 없네요.
				</span>
			</p>
		</div>

		<p><br /></p>
		<p><br /></p>
		<p><strong>데이터를 수시로 다뤄야하는 실무자에게, SQL로 데이터를 뽑는 일은 이제 필수가 되었습니다.</strong></p>
		<p>하지만 막상 SQL을 배워보려고 하면, 알 수 없는 영어들과 이해할 수 없는 말들로 가득 찬</p>
		<p>VOD 강의와 인터넷 자료들을 만날 뿐이죠.</p>
		<p><br /></p>
		<p><br /></p>
		<p><br /></p>
		<h4>SQL 실무 기출 워크북</h4>
		<p><br /></p>
		<p>
			<span style="color: rgb(0, 102, 204)">SQL 실무 기출 워크북</span
			><span style="color: rgb(73, 80, 87)">의 실무 기출 문제는, 말 그대로 </span
			><span style="color: rgb(0, 102, 204)">'실무 기출'</span><span style="color: rgb(73, 80, 87)">입니다.</span>
		</p>
		<p>
			<span style="color: rgb(0, 102, 204)">7년차 데이터 사이언스 현직자 멘토</span>
			<span style="color: rgb(73, 80, 87)">
				가 실무를 하면서 접한 SQL 실무 케이스와, 실무에서 매번 쓰는 SQL 스킬을
			</span>
		</p>
		<p><span style="color: rgb(73, 80, 87)">워크북으로 만들었습니다.</span></p>
		<p><br /></p>
		<p><span style="color: rgb(0, 102, 204)">SQL 실무 기출 워크북</span>의 문제를 풀어보면,</p>
		<p><br /></p>
		<div>
			<p>1. 어떤 실무 상황에서, 어떤 SQL 쿼리가 필요한지 알 수 있습니다.</p>
			<br />
			<p>2. 실무에서 자주 사용하는 쿼리 문법을 알 수 있습니다.</p>
			<br />
			<p>3. 실무에 필요한 데이터를 조회하는 방법을 알 수 있게 됩니다.</p>
			<br />
			<p>
				4. 개발자들이 만든 쿼리문을 해석할 수 있어, 스스로 쿼리문을 작성하고 데이터를 도출하는데 있어 도움이
				됩니다.
			</p>
		</div>
		<br />
		<p><br /></p>
		<p>
			혼자서도 워크북을 풀며 SQL을 공부할 수 있도록,
			<span style="color: rgb(0, 102, 204)">상황 예시와 문제, 답을 문제집에 함께 담았습니다 :)</span>
		</p>
		<p><br /></p>
		<p><br /></p>
		<p><br /></p>
		<p><br /></p>
		<p class="text-center">
			<CustomLink to="https://bit.ly/3ofUGJX" target="_blank" class="c-pointer" @click="handleGtm">
				<img :src="ctaButtonImage" width="726" alt="" />
			</CustomLink>
		</p>
		<p class="text-center"><br /></p>
		<p><br /></p>
		<p><br /></p>
		<h4>어려운게 당연합니다.</h4>
		<h4>어려운 문제, "라이브 웨비나"로 싹 풀어드릴게요.</h4>
		<p><br /></p>
		<p>워크북을 받고 열어봤는데, 온통 알 수 없는 말만 빼곡히 적혀 있어서 풀 마음이 싹 사라지셨다구요?</p>
		<p>그 마음 충분히 이해합니다. 처음 SQL을 접했거나, 배우는 중간에 포기했었던 경험이 있다면 분명 어렵거든요.</p>
		<p><br /></p>
		<p>
			그래서, 워크북을 직접 만드신 현직자 멘토님께서
			<span style="color: rgb(0, 102, 204)">문제 해설과 Q&A를 "라이브 웨비나"를 통해 진행</span
			><span style="color: rgb(73, 80, 87)">하여,</span>
		</p>
		<p><span style="color: rgb(73, 80, 87)">여러분의 어려움과 궁금증을 실시간으로 풀어드리고자 합니다👍</span></p>
		<p><br /></p>
		<h4>
			다만, SQL 워크북을 받은 분들이 <strong style="color: rgb(0, 102, 204)">100명이 넘을 경우</strong> 웨비나가
			진행됩니다!
		</h4>
		<p><br /></p>
		<p>
			<span style="color: rgb(73, 80, 87)">
				● 웨비나가 오픈될 경우, 워크북을 받기 위해 남겨주신 연락처로 웨비나 일정을 안내 드립니다.
			</span>
		</p>
		<p>
			<span style="color: rgb(73, 80, 87)">
				● 웨비나는 무료이며, 워크북을 받으신 분들이라면 누구든지 참여 가능합니다.
			</span>
		</p>
		<p><br /></p>
		<p><br /></p>
		<p><br /></p>
		<h6 class="text-center">
			<strong> "SQL 실무 기출 워크북"이 </strong>
			<strong style="color: rgb(73, 80, 87)">
				개발자 도움 없이도 데이터를 뽑고 싶은 분들께 유용하게 쓰이길 바랍니다!
			</strong>
		</h6>
		<p class="text-center"><br /></p>
		<p class="text-center"><br /></p>
		<p class="text-center"><br /></p>
	</div>
</template>

<script>
import { cdnBaseUrl } from '../../constants';
import CustomLink from '../common/CustomLink.vue';

export default {
	name: 'CbcPromotionLandingPost',
	data() {
		return {
			cdnBaseUrl,
			ctaButtonImage: `${cdnBaseUrl}/images/blog-cbc-cta-button.jpeg`,
		};
	},
	computed: {
		email() {
			return this.$store.state.sessionInfo ? this.$store.state.sessionInfo.id : null;
		},
	},
	methods: {
		handleGtm() {
			// GTM - clickLeadGen
			clickLeadGen(this.email);
		},
	},
	components: { CustomLink },
};
</script>

<style scoped></style>
