const AI_BOT_USER_NO = 91027;
const AI_BOT_EMPTY_CONTENT = '.';

const aiBotMixin = {
	methods: {
		$_aiBotMixin_isAIBotAnswer(userNo) {
			return userNo === AI_BOT_USER_NO;
		},
		$_aiBotMixin_isAIBotEmptyAnswer(answer) {
			return this.$_aiBotMixin_isAIBotAnswer(answer.user_no) && answer.comment === AI_BOT_EMPTY_CONTENT;
		},
		$_aiBotMixin_getProfileItem(profile, property, userNo, callback) {
			const aiBotUserList = {
				choice_rate: 999,
				grade_name: 'powered by OpenAI',
			};
			if (this.$_aiBotMixin_isAIBotAnswer(userNo) && Object.keys(aiBotUserList).includes(property)) {
				return aiBotUserList[property];
			}
			return callback();
		},
	},
};

export default aiBotMixin;
