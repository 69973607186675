<!--
    신규 이력서 보기 화면
-->

<template>
	<div class="wrapper2">
		<div class="resume-box write clearfix">
			<div class="info-section">
				<div class="mb-20">
					<div class="resume-input-group">
						<h2 v-if="display === 'mypage'" class="resume-input">{{ name }}</h2>
						<h2 v-else class="resume-input" v-text="bizApplyUserName"></h2>
						<!--<h1 class="resume-input" v-text="(display==='biz'&&!isFinishInterview) || !isShowInfo || display!=='mypage' || applyType===5? name.substring(0,1)+'00' : name"></h1>-->
					</div>
				</div>
				<div class="private-info mb-20">
					<div class="resume-input-group">
						<div v-if="display === 'mypage'">
							<p>{{ email }}</p>
							<p>{{ mobile | phoneNumberFormat }}</p>
						</div>
						<div v-else-if="applyType === 1">
							<p>{{ email }}</p>
							<p>{{ mobile | phoneNumberFormat }}</p>
						</div>
						<div v-else-if="pmCompanyUserType !== 8">
							<div v-if="!isFinishInterview">
								<p>*****@*****.***</p>
								<p>010-****-****</p>
							</div>
							<div v-else>
								<p>{{ email }}</p>
								<p>{{ mobile | phoneNumberFormat }}</p>
							</div>
						</div>
						<div v-else-if="pmCompanyUserType === 8">
							<div v-if="!isShowInfo">
								<p>*****@*****.***</p>
								<p>010-****-****</p>
							</div>
							<div v-else>
								<p>{{ email }}</p>
								<p>{{ mobile | phoneNumberFormat }}</p>
							</div>
						</div>
						<!--<div v-if="display==='biz'&&!isFinishInterview">-->
						<!--<p>지원자 정보는 면접 확정 후 공개됩니다.</p>-->
						<!--</div>-->
						<!--<div v-else-if="!isShowInfo">-->
						<!--<p>지원자 정보는 관심도 확인 후 공개됩니다.</p>-->
						<!--</div>-->
						<!--<div v-else>-->
						<!--<p>{{email}}</p>-->
						<!--<p>{{mobile | phoneNumberFormat}}</p>-->
						<!--</div>-->
					</div>
				</div>
				<div class="synthesis-box">
					<div class="resume-input-group">
						<p v-html="$options.filters.nl2br(summary)"></p>
					</div>
				</div>
			</div>
			<!-- end .info-section -->

			<div v-if="categoryOne > 0" class="resume-section clearfix">
				<div class="sub-heading">경력사항</div>
				<div v-for="(car, index) in career" v-if="parseInt(car.category) === 1">
					<div class="date write">
						<div class="resume-input-group inline">
							<span
								v-html="
									$options.filters.nl2br(
										car.s_date !== null || car.s_date !== '.' ? car.s_date : null,
									)
								"
							></span>
							<span>~</span>
							<span
								v-if="car.ing !== 1"
								v-html="
									$options.filters.nl2br(
										car.e_date !== null || car.e_date !== '.' ? car.e_date : null,
									)
								"
							></span>
						</div>
					</div>
					<div class="detail write mb-20">
						<div class="resume-input-group">
							<p
								class="item-title company"
								v-html="
									$options.filters.nl2br(
										car.company === null
											? ''
											: car.company + ' ' + (car.dep === null ? '' : car.dep),
									)
								"
							></p>
						</div>
						<div class="resume-input-group">
							<p class="item-sub-title" v-html="$options.filters.nl2br(car.position)"></p>
							<ul class="experience">
								<template v-for="episodeList in car.exp">
									<li v-for="episode in episodeList" v-if="episode !== null" class="clearfix">
										<p class="item-content" v-html="$options.filters.nl2br(episode)"></p>
									</li>
								</template>
							</ul>
						</div>
					</div>
				</div>
				<div class="clear"></div>
			</div>

			<div v-if="categoryTwo > 0" class="resume-section clearfix">
				<div class="sub-heading">교과 외 활동</div>
				<div v-for="(car, index) in career" v-if="parseInt(car.category) === 2">
					<div class="date write">
						<div class="resume-input-group inline">
							<span
								v-html="
									$options.filters.nl2br(
										car.s_date !== null || car.s_date !== '.' ? car.s_date : null,
									)
								"
							></span>
							<span>~</span>
							<span
								v-if="car.ing !== 1"
								v-html="
									$options.filters.nl2br(
										car.e_date !== null || car.e_date !== '.' ? car.e_date : null,
									)
								"
							></span>
						</div>
					</div>
					<div class="detail write mb-20">
						<div class="resume-input-group">
							<p class="item-title company" v-html="$options.filters.nl2br(car.company)"></p>
						</div>
						<div class="resume-input-group">
							<p class="item-sub-title" v-html="$options.filters.nl2br(car.position)"></p>
							<ul class="experience">
								<template v-for="episodeList in car.exp">
									<li v-for="episode in episodeList" v-if="episode !== null" class="clearfix">
										<p class="item-content" v-html="$options.filters.nl2br(episode)"></p>
									</li>
								</template>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div v-if="award.length > 0" class="resume-section clearfix">
				<div class="sub-heading">수상내역</div>
				<div v-for="(awd, index) in award">
					<div class="date write">
						<div class="resume-input-group inline">
							<span
								v-html="$options.filters.nl2br(awd.p_date === '' || null ? '&nbsp' : awd.p_date)"
							></span>
						</div>
					</div>
					<div class="detail write mb-20">
						<div class="resume-input-group">
							<p class="item-title company" v-html="$options.filters.nl2br(awd.company)"></p>
						</div>
						<div class="resume-input-group">
							<p class="item-sub-title" v-html="$options.filters.nl2br(awd.prize)"></p>
							<ul class="experience">
								<!--@foreach ($award->exp as $group_no=>$episode_list)-->
								<!--@foreach($episode_list as $episode)-->
								<!--<li v-for="(episode, index) in car.exp[1]">-->
								<!--<p class="item-content">{!! nl2br(e($episode)) !!}</p>-->
								<!--</li>-->
								<!--@endforeach-->
								<!--@endforeach-->
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div v-if="education.length > 0" class="resume-section clearfix">
				<div class="sub-heading">학력사항</div>
				<div v-for="(edu, index) in education">
					<div class="date write">
						<div class="resume-input-group inline">
							<span
								v-html="
									$options.filters.nl2br(
										edu.entrance !== null || edu.entrance !== '.' ? edu.entrance : ' ',
									)
								"
							></span>
							<span>~</span>
							<span
								v-if="edu.ing !== 1"
								v-html="
									$options.filters.nl2br(
										edu.graduate !== null || edu.graduate !== '.' ? edu.graduate : ' ',
									)
								"
							></span>
						</div>
					</div>
					<div class="detail write mb-20">
						<div class="resume-input-group">
							<p class="item-title" v-html="$options.filters.nl2br(edu.univ)"></p>
						</div>
						<div class="resume-input-group inline mb-10">
							<p class="item-sub-title item-ect m-0">
								{{ edu.major | nl2br }}
								<template v-if="edu.degree !== '고졸'">
									{{ edu.degree | nl2br }}
									<span>(</span>{{ edu.grade | nl2br }}
									<span v-if="edu.grade_st !== null"
										><span v-if="!~edu.grade.indexOf('/')">/</span> {{ edu.grade_st | nl2br }}</span
									><span>)</span>
								</template>
							</p>
						</div>
						<div class="resume-input-group">
							<p class="item-content" v-html="$options.filters.nl2br(edu.descrip)"></p>
						</div>
					</div>
				</div>
			</div>

			<div v-if="lang.length > 0 || cert.length > 0 || tool.length > 0" class="resume-section clearfix">
				<div class="sub-heading">기타</div>
				<div v-for="(lang, index) in lang">
					<div class="date write">
						<div class="resume-input-group inline">
							<span
								v-html="
									$options.filters.nl2br(
										lang.acquisition === '' || lang.acquisition === null
											? '&nbsp;'
											: lang.acquisition,
									)
								"
							></span>
						</div>
					</div>
					<div class="detail write">
						<div class="resume-input-group">
							<span
								v-if="lang.lang !== null"
								class="item-title row-line item-ect"
								v-html="$options.filters.nl2br(lang.lang)"
							></span>
							<span v-else>&nbsp;</span>
							<span
								v-if="lang.grade !== null"
								class="item-sub-title mb-0 item-ect"
								v-html="$options.filters.nl2br(lang.grade)"
							></span>
							<span v-else>&nbsp;</span>
						</div>
					</div>
				</div>
				<div v-for="(cert, index) in cert">
					<div class="date write">
						<div class="resume-input-group inline">
							<span
								v-html="
									$options.filters.nl2br(
										cert.acquisition === '' || cert.acquisition === null
											? '&nbsp;'
											: cert.acquisition,
									)
								"
							></span>
						</div>
					</div>
					<div class="detail write">
						<div class="resume-input-group">
							<span
								v-if="cert.publisher !== null"
								class="item-title row-line item-ect"
								v-html="$options.filters.nl2br(cert.certification)"
							></span>
							<span
								class="item-title item-ect"
								v-html="$options.filters.nl2br(cert.certification)"
							></span>
							<span
								v-if="cert.publisher"
								class="item-sub-title item-ect"
								v-html="$options.filters.nl2br(cert.publisher)"
							></span>
							<span
								v-if="cert.pass !== null"
								class="item-sub-title item-ect"
								v-html="$options.filters.nl2br('(' + cert.pass + ')')"
							></span>
						</div>
					</div>
				</div>
				<div v-for="(tool, index) in tool">
					<div class="date write" style="width: 170px"><br /></div>
					<div class="detail write">
						<div class="resume-input-group">
							<span
								v-if="tool.name !== null"
								class="item-title row-line item-ect"
								v-html="$options.filters.nl2br(tool.name)"
							></span>
							<span v-else>&nbsp;</span>
							<span
								v-if="tool.detail !== null"
								class="item-sub-title mb-0 item-ect"
								v-html="$options.filters.nl2br(tool.detail)"
							></span>
							<span v-else>&nbsp;</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['applyNo', 'display', 'isFinishInterview', 'isShowInfo', 'applyType', 'pmCompanyUserType', 'userInfo'],
	data() {
		return {
			name: '',
			email: '',
			mobile: '',
			summary: '',
			education: [],
			career: [],
			award: [],
			lang: [],
			cert: [],
			tool: [],
			categoryOne: 0,
			categoryTwo: 0,
		};
	},
	computed: {
		bizApplyUserName() {
			return this.userInfo.headTitle;
		},
	},
	mounted() {
		this.getResumeInfo();
	},
	methods: {
		getResumeInfo() {
			axios({
				method: 'GET',
				url: '/api/apply/detail',
				params: {
					apply_no: this.applyNo,
					location: 'resume',
				},
			})
				.then(response => {
					// console.log(response.data.list.resume);
					if (response.data.code === 200) {
						this.doPutResume(response.data.list.resume);
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		doPutResume(resume) {
			this.categoryOne = resume.category_one;
			this.categoryTwo = resume.category_two;
			this.name = resume.user_info.name;
			this.mobile = resume.user_info.mobile;
			this.email = resume.user_info.email;
			this.summary = resume.user_info.summary;
			this.education = resume.edu;
			this.career = resume.career;
			this.award = resume.award;
			this.cert = resume.cert;
			this.lang = resume.lang;
			this.tool = resume.tool;
		},
	},
};
</script>
