<template>
	<div id="FeedBack" class="mine-feedback-question">
		<!-- 답변하기 - 나의 답변창(PC.ver) -->
		<my-answer-component
			v-if="display === 'feedback' && !isMobile"
			:display="display"
			:answer.sync="answer"
			:user-type="userType"
			:user-belong="userBelong"
			:parent-user-no="parentUserNo"
			:user-no="userNo"
			:is-finished-answer-feedback="isFinishedAnswerFeedback"
			:user-profile-image="userProfileImage"
			:user-nickname="userNickname"
			:mentor-profile="mentorProfile"
			:credit-sum="question.credit + question.bonus"
			@paste="$_preventCopyAndPasteMixin_handlePreventPaste"
			@save="setAdditionalComment"
		/>
		<!--    채택률이 올라가는 답변 작성 Tip! && 나의 답변창 mobile ver   -->
		<template v-if="answerTipInfo && isMobile && parentUserNo !== userNo">
			<div style="margin-top: -20px">
				<c-box :paddings="display === 'result' ? [12, 32, 12, 32] : [12, 16, 12, 16]" background-color="white">
					<c-typography
						color="gray600"
						type="body2"
						class="flex justify-between align-items-center"
						@click="showTipToggle = !showTipToggle"
					>
						{{ answerTipInfo.title }}
						<c-icon name="IconTextArrowSmallLine" color="gray600" :rotate="showTipToggle ? -90 : 90" />
					</c-typography>
					<ul v-if="showTipToggle" class="mt-12">
						<li
							v-for="(tip, index) in answerTipInfo.item"
							:key="`feedback-tip-${index}`"
							class="flex flex-row mb-4"
						>
							<c-icon name="IconCheckXSmallLine" color="primary" class="mr-4" />
							<c-typography type="caption1" :font-weight="400" color="gray700" align="left">
								{{ tip }}
							</c-typography>
						</li>
					</ul>
				</c-box>

				<c-box
					:paddings="[22, 16, 22, 16]"
					background-color="white"
					class="flex flex-row align-center mt-12 my-answer-box"
				>
					<CommonUserInformation
						v-if="mentorProfile"
						type="full"
						:nickname="mentorProfile.belong.nickname"
						:company="userBelong"
						:avatar-user-no="parseInt(userNo)"
						:avatar-user-type="2"
						:choice-rate="mentorProfile.choice_rate"
						:grade-name="mentorProfile.grade.length > 0 ? mentorProfile.grade[0].name : ''"
						:user-profile-image="userProfileImage"
					/>

					<div id="expUserTextarea" class="expUserTextarea">
						<c-textarea
							ref="expUserTextarea"
							type="outline"
							:value.sync="answer"
							:placeholder="dynamicPlaceholder"
							@paste="$_preventCopyAndPasteMixin_handlePreventPaste"
						/>
					</div>

					<c-typography type="caption1" color="gray500">
						글자수
						<c-typography element="span" color="gray700">{{ answer.length }}</c-typography>
					</c-typography>
					<c-button size="large" full class="mt-24" @click="setAdditionalComment"> 답변하기 </c-button>
				</c-box>
			</div>
		</template>
		<div v-if="isGetFeedbackData">
			<c-empty-box v-if="isFeedbackEmpty" type="emphasized" external class="mt-20">
				<c-typography type="body2" color="gray700" :class="isMobile ? 'ml-16 mb-16' : 'mb-8'">
					답변
					<c-typography element="span" font-weight="medium" color="gray850">
						{{ feedbackList.length }}
					</c-typography>
				</c-typography>
				<!-- 답변이 없을 때 -->
				<template v-slot:title>등록된 답변이 없습니다.</template>
				<template v-slot:contents>
					<span v-html="emptyMessage" />
				</template>
			</c-empty-box>

			<template v-if="parentUserNo === userNo && !isFeedbackEmpty">
				<c-typography type="body2" color="gray700" :class="isMobile ? 'ml-16 mb-16' : 'mb-8'">
					답변
					<c-typography element="span" font-weight="medium" color="gray850">
						{{ feedbackList.length }}
					</c-typography>
				</c-typography>
			</template>
			<template>
				<!-- 현직자 답변 -->
				<div
					v-for="(feedback, index) in feedbackList"
					:id="`feedback-${index}`"
					:key="feedback.no"
					:no="feedback.no"
					:index="index"
					class="mt-8"
					:class="index === feedbackList.length - 1 ? 'mb-12' : 'mb-12'"
				>
					<c-box v-if="isShowFeedback(feedback.user_no)" class="feedback-box" :paddings="[20, 20, 20, 20]">
						<!--채택 여부 확인-->
						<span v-if="feedback.choose === 2" class="feedback_choose">
							채택 <i class="icon icon-check icon-check-blue" style="width: 10px; height: 10px"></i>
						</span>
						<CommonUserInformation
							type="full"
							:nickname="feedback.nickname"
							:company="feedback.user_belong"
							:avatar-user-no="feedback.user_no"
							:avatar-user-type="
								request !== 'qa' && userNo !== question.user_no ? 2 : feedback.feedback_user_type
							"
							:choice-rate="
								$_aiBotMixin_getProfileItem(
									feedback.profile,
									'choice_rate',
									feedback.user_no,
									() => feedback.profile.choice_rate,
								)
							"
							:grade-name="
								$_aiBotMixin_getProfileItem(feedback.profile, 'grade_name', feedback.user_no, () =>
									feedback.profile.grade.length > 0 ? feedback.profile.grade[0].name : '',
								)
							"
							:matching="feedback.profile.matcing_info"
							:user-profile-image="feedback.profile_img"
							class="mb-14"
						/>
						<contents v-if="isDisplayBuyMentoring">
							<!--isDisplayBuyMentoring ture 값이면서 현직자 인증 답변일 경우에만 보이도록-->
							<Typography type="body1" color="gray800">
								<div v-if="request === 'in'">
									<contents-body
										bodyDefinition=" 답변"
										class="f-semi-bold"
										:contentsBody="JSON.stringify(feedback.comment.q1.ques)"
									/>
									<contents-body
										bodyDefinition=" 답변"
										:contentsBody="JSON.stringify(feedback.comment.q1.answ)"
									/>
									<contents-body
										bodyDefinition=" 답변"
										class="f-semi-bold"
										:contentsBody="JSON.stringify(feedback.comment.q2.ques)"
									/>
									<contents-body
										bodyDefinition=" 답변"
										:contentsBody="JSON.stringify(feedback.comment.q2.answ)"
									/>
								</div>
								<contents-body v-else bodyDefinition=" 답변" :contentsBody="feedback.comment" />
							</Typography>
							<Typography type="body2" color="gray300" class="f-light">
								<contents-caption captionDefinition="작성날짜" :contentsCaption="feedback.time" />
							</Typography>
						</contents>
						<template v-else>
							<!--buyContent를 안 한 사람은 미인증 (feedback.type===1)만 보여준다-->
							<contents v-if="feedback.feedback_user_type === 1">
								<!--isDisplayBuyMentoring ture 값이면서 현직자 인증 답변일 경우에만 보이도록-->
								<Typography type="body1" color="gray800">
									<div v-if="request === 'in'">
										<contents-body
											bodyDefinition=" 답변"
											class="f-semi-bold"
											:contentsBody="JSON.stringify(feedback.comment.q1.ques)"
										/>
										<contents-body
											bodyDefinition=" 답변"
											:contentsBody="JSON.stringify(feedback.comment.q1.answ)"
										/>
										<contents-body
											bodyDefinition=" 답변"
											class="f-semi-bold"
											:contentsBody="JSON.stringify(feedback.comment.q2.ques)"
										/>
										<contents-body
											bodyDefinition=" 답변"
											:contentsBody="JSON.stringify(feedback.comment.q2.answ)"
										/>
									</div>
									<contents-body v-else bodyDefinition=" 답변" :contentsBody="feedback.comment" />
								</Typography>
								<Typography type="body2" color="gray300">
									<contents-caption captionDefinition="작성날짜" :contentsCaption="feedback.time" />
								</Typography>
							</contents>
						</template>
						<!--채택하기 버튼-->
						<div v-if="canAdopt(feedback.user_no) && feedback.type === 2" class="mt-20 clearfix">
							<AdoptButton
								:chosen="feedback.choose === 2"
								:disabled="feedback.choose === 2"
								class="ml-auto"
								@click="adopt(feedback.no, index)"
							/>
						</div>
						<div class="mt-20 clearfix">
							<c-button
								v-if="request === 'qa'"
								class="feedback_like float-left p-relative"
								:type="feedback.isLike === 1 ? 'fill' : 'text'"
								color="error"
								@click="clickLike(feedback.no, 'u', feedback.like, index)"
							>
								<template v-if="display === 'result'">
									<span class="mr-4">
										<span class="like-original">좋아요</span>
										<span class="like-test1" style="display: none">추천해요</span>
										<span class="like-test2" style="display: none">유용해요</span>
										<span class="like-test3" style="display: none">감사해요</span>
									</span>
								</template>
								<span v-else>좋아요</span>
								{{ feedback.like }}
							</c-button>
							<div class="feedback_reply ml-10">
								<c-typography
									class="flex"
									color="gray500"
									type="body2"
									style="height: 36px; align-items: center"
								>
									<c-icon name="IconCommentSmallLine" color="gray500" class="mr-4" />
									댓글 {{ feedback.reply ? feedback.reply.length : 0 }}
								</c-typography>
							</div>
							<c-button
								v-if="feedback.user_no !== userNo"
								class="float-right"
								type="text"
								color="info"
								@click="goReport(feedback.no, '답글')"
							>
								신고
							</c-button>
						</div>
						<template v-if="feedback.reply">
							<c-divider type="dash" color="gray200" style="margin: 16px -20px 0 -20px" />
							<div class="p-relative" style="margin-left: -16px; margin-right: -16px">
								<c-divider type="dash" vertical />
							</div>
						</template>

						<div>
							<!--  댓글 보기 -->
							<c-box
								v-for="(reply, index) in feedback.reply"
								:key="`reply-${index}`"
								:paddings="[20, 0, 20, 0]"
								background-color="white"
							>
								<c-reply
									type="default"
									:value="$options.filters.nl2br(reply.comment)"
									:date="reply.time.split('T')[0] | dateFormat"
									:show-report="reply.user_no !== userNo"
									@clickReportButton="goReport(reply.no)"
								>
									<template v-slot:user-information>
										<CommonUserInformation
											type="normal"
											:nickname="reply.nickname"
											:company="reply.belong"
											:avatar-user-no="reply.user_no"
											:avatar-user-type="reply.type"
											:user-profile-image="reply.profile_img"
											:is-questioner="reply.user_no === parentUserNo"
											class="mb-14"
										/>
									</template>
								</c-reply>
							</c-box>
							<!--댓글작성-->
							<div class="p-relative readcase_reply_container">
								<!--마이페이지에서 작성하는 댓글-->
								<div v-if="display === 'result'">
									<template v-if="comment[index].view">
										<c-textarea
											v-if="parentUserNo === userNo"
											:value.sync="comment[index].comment"
											class="mt-30"
											placeHolder="감사인사 또는 추가질문을 남겨보세요"
											type="reply"
											@submit="setReply(no, feedback.no, comment[index].comment, index)"
										/>
										<c-textarea
											v-else
											:value.sync="comment[index].comment"
											class="mt-30"
											placeHolder="멘토님의 답변은 질문 요청자뿐 아니라 취업에 어려움을 겪고 있는 수십만 명의 멘티님들이 함께 읽고 있습니다."
											type="reply"
											@submit="setReply(no, feedback.no, comment[index].comment, index)"
										/>
									</template>
									<c-textarea
										v-else
										:value.sync="comment[index].comment"
										class="mt-30"
										placeHolder="댓글을 입력하세요."
										type="reply"
										@submit="setReply(no, feedback.no, comment[index].comment, index)"
									/>
								</div>
								<div v-if="isShowAdditionalComment">
									<c-textarea
										:value.sync="userReply[index]"
										class="mt-30"
										placeHolder="추가 의견이 있으신가요?"
										type="reply"
										style="font-size: 14px"
										@submit="setReply(no, feedback.no, userReply[index], index)"
									/>
								</div>
							</div>
						</div>
					</c-box>
				</div>
			</template>
		</div>
		<c-box v-else background-color="white">
			<div class="flex flex-row mb-16">
				<BaseSkeleton circle width="48px" height="48px" class="mr-6" />
				<div class="flex flex-column">
					<BaseSkeleton width="110px" height="20px" />
					<BaseSkeleton width="100px" height="13px" class="mt-4" />
				</div>
			</div>
			<BaseSkeleton width="100%" height="250px" />
		</c-box>

		<!-- 신고모달 -->
		<ReportModal
			:show="showReportModal"
			:is-loading="reportModalLoading"
			is-mentoring
			@close="closeReportModal"
			@submitReport="handleSubmitReport"
		/>

		<c-alert-modal :show.sync="showAlert" :success-message="buttonName" @close="successCallback">
			<template v-slot:content>
				<strong v-if="title">{{ title }}</strong>
				<div v-html="message" />
			</template>
		</c-alert-modal>

		<c-alert-modal
			:show.sync="showCopyAndPasteAlertModal"
			success-message="확인"
			@close="showCopyAndPasteAlertModal = false"
		>
			<template v-slot:content>
				<strong v-if="copyAndPasteAlertModalInfo.title">{{ copyAndPasteAlertModalInfo.title }}</strong>
				<div v-html="copyAndPasteAlertModalInfo.message" />
			</template>
		</c-alert-modal>

		<c-permission-modal
			class="c-permission-modal"
			:show.sync="showPermissionModal"
			:success-callback="permissionCallback"
			:success-message="permissionButton"
			confirm-button-color="error"
		>
			<template slot="title">{{ permissionTitle }}</template>
			<template slot="content">{{ permissionContent }}</template>
		</c-permission-modal>
	</div>
</template>

<script>
import { datadogLogs } from '@datadog/browser-logs';
import ReportModal from '../_modal/ReportModal.vue';
import permissionModalMixin from '../../mixins/permissionModalMixin';
import CommonUserInformation from '../case/main/molecules/CommonUserInformation.vue';
import caseMixin from '../../mixins/case/caseMixin';
import { mapGetters, mapActions } from 'vuex';
import { CASE_CATEGORY_GTM_MAP } from '../../constants';
import answerMixin from '../../mixins/answerMixin';
import BaseSkeleton from '../common/BaseSkeleton.vue';
import AnswerCountSection from '../answer/AnswerCountSection.vue';
import AdoptButton from '../_common/AdoptButton.vue';
import aiBotMixin from '../../mixins/case/aiBotMixin';
import newbieMentorTutorialMixin from '../../mixins/community/newbieMentorTutorialMixin';
import preventCopyAndPasteMixin from '../../mixins/preventCopyAndPasteMixin';

export default {
	name: 'MineFeedbackQuestion',
	mixins: [
		permissionModalMixin,
		caseMixin,
		answerMixin,
		aiBotMixin,
		newbieMentorTutorialMixin,
		preventCopyAndPasteMixin,
	],
	props: [
		'no',
		'userType',
		'userNo',
		'userEmail',
		'userId',
		'userLevel',
		'request',
		'display',
		'parentUserNo',
		'profile',
		'question',
		'userBelong',
		'length',
		'univ',
		'infoDisplay',
		'isBuyMentoring',
		'mentorCount',
		'jobReviewCount',
		'company',
		'job',
		'isRobot',
		'mentorFirst',
		'activeUserTextarea',
		'isCareerChange',
		'answerTipInfo',
		'isRePull',
		'answerCorrespondence',
	],
	data() {
		return {
			isFinishedAnswerFeedback: false,
			feedbackList: [],
			isFeedbackEmpty: false,
			chooseNum: 0,
			answer: '', //댓글
			comment: [], //답글, 대댓글
			reportNo: '', //신고 글번호
			title: '',
			message: '',
			buttonName: '확인',
			successCallback: () => {},
			loading: true,
			showReportModal: false, //신고 모달
			showAlert: false, //alert 모달
			showMobileFeedback: false, //
			feedbackInfo: {},
			animationShakeStart: false,
			animationBubbleSpreadStart: false,
			adoptIndex: -1,
			isSE: false,
			isVisibleAnswerFeedback: false, //답변하기 답변창,
			isFolded: false, // 접기 버튼을 눌렀는지 flag
			isGetFeedbackData: false,
			userAnswer: null,
			userReply: [],
			closeTextArea: false,
			showConfirm: false,
			titleConfirm: '',
			messageConfirm: '',
			buttonNameConfirm: '',
			successCallbackConfirm: '',
			saveConfirm: '',
			isInfoTip: [],
			mentoOffcial: false,
			GTMInfo: null,
			reportModalType: '',
			reportModalLoading: false,
			mentorProfile: null,
			showTipToggle: false,
		};
	},
	computed: {
		...mapGetters(['userProfileImage', 'userNickname']),
		isDummyQuestion() {
			return this.question.original_type === 'qa_newbie';
		},
		dynamicPlaceholder() {
			if (this.display === 'feedback') {
				if (this.isFolded === true) {
					return '답변을 이어서 작성해 주세요';
				}
				return '현직자만 아는 정보를 알려주세요.';
			}
			return '여기를 눌러 추가 답변을 입력하세요.';
		},
		blurSelectSign() {
			if (this.display === 'case' && (this.isBuyMentoring === undefined || this.isBuyMentoring.length === 0)) {
				return 'z-index: 1 !important';
			}
			return '';
		},
		isMyPage() {
			return this.display === 'result';
		},
		isMyPageOrAnswer() {
			// 마이페이지거나 답변하기
			return this.isMyPage || this.display === 'feedback';
		},
		/*
		 * 답변 보여주기 (로그인 된 경우)
		 * 열람권이 있을 때
		 * 마이페이지거나 답변하기일 때
		 * 현직자가 직무카드를 썼을 때
		 * 카테고리가 '이직 고민'일 때 (21.07.09 추가)
		 * */
		isDisplayBuyMentoring() {
			const isGuest = this.isEmptyData(this.userType);
			if (isGuest) {
				return false;
			}

			const isWrittenJobCard = this.userType === 2 && this.jobReviewCount > 0;

			return (
				this.isMyPageOrAnswer ||
				!this.isEmptyData(this.isBuyMentoring) ||
				isWrittenJobCard ||
				this.isCareerChange
			);
		},
		/*
		 * 추가 답변창 보여주기 (로그인 된 경우)
		 * 열람권이 있을 때
		 * 마이페이지거나 답변하기가 아닐 때
		 * 카테고리가 '이직 고민'일 때 (21.07.09 추가)
		 * */
		isShowAdditionalComment() {
			return (!this.isEmptyData(this.isBuyMentoring) && !this.isMyPageOrAnswer) || this.isCareerChange;
		},
		checkURL() {
			const url = window.location.href;
			return !!url.match('/answer/question');
		},
		mentoringCategoryGroupId() {
			const question = Array.isArray(this.question) ? this.question[0] : this.question;
			return question.mentoring_category_group_id;
		},
		qaDetailUrl() {
			return this.goDetailUrl();
		},
		emptyMessage() {
			return this.display === 'feedback'
				? '첫 답변의 주인공이 되어주세요!'
				: `잠시 기다리시면 생생한 지식과 경험을 담은 ${
						this.isMobile ? '<br />' : ''
				  }답변을 받아보실 수 있어요!`;
		},
	},
	created() {
		this.getFeedback();
		this.getUserProfileImage();
		if (this.display === 'feedback') {
			this.fetchMentorProfile();
		}
		/* readCase 개인화 실험 */
		if (this.userType !== undefined && !this.isEmptyData(this.isBuyMentoring) && this.isBuyMentoring.length > 0) {
			this.getEduPersonalizeEvent();
		}
	},
	methods: {
		...mapActions(['getUserProfileImage']),
		isShowFeedback(feedbackUserNo) {
			// 답변자는 자신의 글만 볼 수 있게,
			// 자소서, 면접 작성자는 모두 볼 수 있음
			if (this.parentUserNo === this.userNo) return true;
			if (this.userNo === feedbackUserNo) return true;
			return false;
		},
		openMobileFeedback(feedback_no, feedback_user_no, index, belong) {
			//모바일 답변창
			this.feedbackInfo = {
				//컴포넌트에 props로 전달
				feedback_no,
				feedback_user_no,
				index,
				belong,
				question: this.question, //답변하기 질문 제목
			};
			this.showMobileFeedback = true;
		},
		clickLike(no, like_type, like_num, index) {
			//좋아요,싫어요 클릭

			if (this.userType === '' || this.userType === undefined) {
				window.location.href = '/register';
				return false;
			}
			const answer = this.feedbackList[index];
			let isLike = answer.isLike; //1은 좋아요, -1은 싫어요, 0은 기본
			let like = answer.like; //좋아요 개수
			let dislike = answer.dislike; //싫어요 개수

			axios({
				method: 'POST',
				url: '/api/case/detail/answerLike',
				data: {
					feedback_no: no,
				},
			}).then(({ status }) => {
				if (status === 200) {
					if (isLike === 0) {
						//기본
						if (like_type === 'u') {
							isLike = 1;
							like++;
						} else {
							isLike = -1;
							dislike++;
						}
					} else if (isLike === 1) {
						//이미 좋아요
						if (like_type === 'u') {
							isLike = 0;
							like--;
						} else {
							isLike = -1;
							dislike++;
							like--;
						}
					} else if (isLike === -1) {
						//이미 싫어요
						if (like_type === 'u') {
							isLike = 1;
							like++;
							dislike--;
						} else {
							isLike = 0;
							dislike--;
						}
					}
					this.feedbackList[index].isLike = isLike;
					this.feedbackList[index].like = like;
					this.feedbackList[index].dislike = dislike;

					if (isLike) {
						const category = CASE_CATEGORY_GTM_MAP[this.mentoringCategoryGroupId];
						const info = {
							user_no: this.userNo,
							user_type: this.userType,
							email: this.userEmail,
							req_no: this.no,
							company: this.company,
							job: this.job,
							where: 'Answer',
							category: this.$_getCaseGTMCategory(category),
							like_type: '질문',
						};

						// GTM - clickLike
						clickLike(info);

						if (this.userNo !== answer.profile?.belong.no) {
							// GTM - answerLiked
							answerLiked(
								this.profile?.belong.nickname,
								this.question.other_inf,
								window.location.href,
								this.feedbackList[index].profile?.belong.no,
								this.feedbackList[index].profile?.belong.id,
							);
						}
					}
				}
			});
		},
		/**
		 * 답댓글
		 * @param event
		 * @param feedback_no: 답변,댓글,답댓글의 고유번호
		 * @param feedback_user_no
		 * @param belong: 회사명
		 * @param index
		 **/
		reReply(event, feedback_no, feedback_user_no, belong, index) {
			//답댓글
			if (this.isMobile) {
				this.openMobileFeedback(feedback_no, feedback_user_no, index, belong);
			} else {
				this.comment[index].view = true;
			}
		},
		canAdopt(feedbackUserNo) {
			if (this.$_aiBotMixin_isAIBotAnswer(feedbackUserNo)) return false;
			return this.display === 'result' && this.userNo !== feedbackUserNo && this.parentUserNo === this.userNo;
		},
		adopt(feedback_no, index) {
			// 채택
			showLoading();
			const postData = {
				feedback_no,
				type: this.request,
				request_no: this.no,
				length: this.length !== undefined ? this.length : 0,
			};
			axios({
				method: 'POST',
				url: '/api/request/adopt',
				data: postData,
			})
				.then(({ data }) => {
					// console.log(data);
					if (data.code === 200) {
						const mentoInfo = this.feedbackList[index];

						// GTM - answerSelected (멘티가 멘토가 남긴 답변을 채택 시 발생 )
						answerSelected(
							this.goDetailUrl(),
							mentoInfo.user_no,
							mentoInfo.profile.email,
							this.question.other_inf,
							this.profile.belong.nickname,
							mentoInfo.profile.belong.nickname,
						);

						// GTM - selectAnswer
						const category = CASE_CATEGORY_GTM_MAP[this.mentoringCategoryGroupId];
						selectAnswer(
							this.userNo,
							this.no,
							this.request,
							this.$_getCaseGTMCategory(category),
							data.creditAmount,
						);
						closeLoading();
						this.adoptIndex = index;
						this.feedbackList[index].choose = 2;
						this.chooseNum++;
						this.changeChooseDisabledForSameMentor(index);
					}
					// 201은 choose 값이 2일 때 return
					else if (data.code === 201) {
						if (this.feedbackList[index].choose !== 2) {
							this.adoptIndex = index;
							this.feedbackList[index].choose = 2;
							this.chooseNum++;
						}
					}
					// 202는 auth 이슈
					else if (data.code === 202) {
						closeLoading();
						this.title = '';
						this.message = '코멘토 로그인 후 사용하실 수 있습니다.';
						this.buttonName = '확인';
						this.successCallback = () => (location.href = '/logout');
						this.showAlert = true;
						return false;
					}
					// 206는 동일 멘토 채택 이슈
					else if (data.code === 206) {
						closeLoading();
						this.title = '';
						this.message = '동일 멘토의 여러 답변을 채택할 수 없습니다.';
						this.buttonName = '확인';
						this.showAlert = true;
						return false;
					} else {
						closeLoading();
						this.title = '채택 오류';
						this.message = '채택하는데 오류가 발생했습니다. 오류가 계속되면 고객센터로 문의해주세요';
						this.buttonName = '확인';
						this.successCallback = this.closeAlertModal;
						this.showAlert = true;
						datadogLogs.logger.log(
							'Feedback 채택 오류',
							{ data: postData, err: data, userNo: this.userNo },
							'debug',
						);
					}
				})
				.catch(error => {
					console.log(error);
					closeLoading();
					this.title = '채택 오류';
					this.message = '채택하는데 오류가 발생했습니다. 오류가 계속되면 고객센터로 문의해주세요';
					this.buttonName = '확인';
					this.successCallback = this.closeAlertModal;
					this.showAlert = true;
					datadogLogs.logger.log(
						'Feedback 채택 오류',
						{ data: postData, err: data, userNo: this.userNo },
						'debug',
					);
				});
		},
		changeChooseDisabledForSameMentor(index) {
			// 동일 멘토에 대한 다중 채택 막기
			const mentorUserNo = this.feedbackList[index].user_no;
			const changeTargetList = this.feedbackList.filter(({ user_no, choose }) => {
				return user_no === mentorUserNo && choose === 0;
			});

			if (changeTargetList.length > 0) {
				changeTargetList.forEach(feedback => {
					feedback.choose = -1;
				});
			}
		},
		goReport(report_no, type) {
			if (this.userType === '' || this.userType === undefined) {
				window.location.href = '/register';
				return false;
			}
			if (type) {
				this.reportModalType = type;
			}
			this.reportNo = report_no;
			this.showReportModal = true;
		},
		closeReportModal() {
			this.showReportModal = false;
			this.reportModalType = '';
			return true;
		},
		handleSubmitReport({ content }) {
			this.$_openPermissionModal({
				title: '',
				content: '정말 신고하시겠습니까?',
				callback: () => {
					this.$_closePermissionModal();
					this.submitReport(content);
				},
			});
		},
		submitReport(content) {
			try {
				this.reportModalLoading = true;
				axios({
					method: 'POST',
					url: '/api/request/report',
					data: {
						feedback_no: this.reportNo,
						msg: content,
					},
				}).then(response => {
					const data = response.data;
					//console.log(data);
					if (data.code === 200) {
						this.closeReportModal();
						this.title = '신고 완료';
						this.message = '신고 접수가 완료되었습니다.';
						this.successCallback = this.closeAlertModal;
						this.buttonName = '확인';
						this.showAlert = true;
					}
				});
			} catch (error) {
				console.log(error);
			} finally {
				this.reportModalLoading = false;
			}
		},
		getFeedback() {
			axios({
				method: 'POST',
				url: '/api/answer/getFeedback',
				data: {
					request_no: this.no,
					request_type: this.request,
					display_type: this.display, //feedback, result
				},
			})
				.then(({ data }) => {
					if (data.code === 200) {
						// console.log(data);
						this.chooseNum = data.chooseNum;
						this.feedbackList.push(...data.result);
						this.isGetFeedbackData = true;
						this.userReply.length = this.feedbackList.length;
						this.isInfoTip = new Array(this.feedbackList.length);
						this.isInfoTip.fill(false);
						this.userReply.fill(null);
						this.$emit('feedbackListLength', this.feedbackList.length);
						// eslint-disable-next-line no-unused-vars
						data.result.forEach(element => {
							//관련성 관심직무
							this.comment.push({
								comment: '',
								view: false,
							});
						}, this);
						if (this.display === 'case') {
							this.$nextTick(() => {
								const scrollY = localStorage.getItem('requestId');
								if (scrollY !== null && this.isGetFeedbackData === true) {
									const isScrollY = document.getElementById(scrollY).offsetTop + 300;
									window.scrollTo(0, isScrollY);
								}
							});
						}
					}
					this.loading = false;
					//피드백이 1개 이상 달렸는데 채택을 안 했을 경우
					if (
						this.parentUserNo === this.userNo &&
						this.chooseNum === 0 &&
						data.result.length > 0 &&
						this.isMyPage
					) {
						this.title = `답변을 채택하세요`;
						this.message = `멘토님들의 소중한 시간에 대해 따뜻한 마음으로 보답해주세요.
                                        좋은 답변을 채택하면 멘토링 품질 향상에 도움이 됩니다.`;
						this.successCallback = this.closeAlertModal;
						this.buttonName = '확인';
						this.showAlert = true;
					}
					if (data.result.length === 0) {
						this.isFeedbackEmpty = true;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		async setReply(no, feedbackNo, reply, index) {
			/*no = 글번호, feedback = 댓글 번호 , reply = 댓글 내용, index = 댓글 index  */
			if (this.isEmptyData(reply)) {
				this.showAlert = true;
				this.message = '댓글 내용을 입력해주세요';
				this.successCallback = this.closeAlertModal;
				return false;
			}

			if (reply.length < 20) {
				this.showAlert = true;
				this.message = '최소 20자 이상 입력해 주세요';
				this.successCallback = this.closeAlertModal;
				return false;
			}

			if (this.isMyPage) {
				//마이페이지 결과화면
				this.handleSubmitFeedbackReply(no, feedbackNo, reply, index);
			} else {
				/*마이페이지가 아닌 곳에서 댓글 입력*/
				await this.handleSubmitAdditionalReply(no, feedbackNo, reply, index);
			}
		},
		async handleSubmitFeedbackReply(no, feedbackNo, reply, index) {
			try {
				const data = {
					request_no: this.no,
					request_type: this.request, //qa, ce, in
					parent_no: feedbackNo, //답변은 feedback_no, 댓글은 parent_no(-1)
					text: reply, //댓글, 답변
					display_type: 'result',
				};

				const {
					data: { code, msg },
				} = await this.$_fetchRequest({
					method: 'POST',
					url: '/api/answer/setFeedback',
					data,
					isLoading: true,
				});
				if (code === 403) {
					this.showErrorToast(msg);
					return;
				}

				const category = CASE_CATEGORY_GTM_MAP[this.mentoringCategoryGroupId];
				// GTM - leaveReply
				if (this.userNo === this.parentUserNo) {
					leaveReply(
						this.userNo,
						this.userType,
						this.no,
						this.request,
						'mine',
						this.$_getCaseGTMCategory(category),
					);
				} else {
					leaveReply(
						this.userNo,
						this.userType,
						this.no,
						this.request,
						'others',
						this.$_getCaseGTMCategory(category),
					);
				}

				if (this.userNo !== this.question.user_no) {
					//자신의 댓글에는 메일이 가지않도록
					// GTM - newReplytoAnswer 멘토든 멘티든 상관 없이 댓글을 받은 답변 작성자
					newReplytoAnswer(
						reply,
						this.goDetailUrl(),
						this.feedbackList[index].user_no,
						this.feedbackList[index].profile.email,
						this.question.other_inf,
						this.userNickname,
					);
				}

				this.showAlert = true;
				this.message = '댓글이 추가되었습니다';
				this.successCallback = this.closeAlertModal;

				const replies = await this.getReplies(feedbackNo);
				if (replies) {
					this.comment[index].comment = ''; // 초기화
					this.feedbackList[index].reply = replies;
					this.$forceUpdate();
				} else {
					location.reload();
				}
			} catch (error) {
				console.error(error);
				this.comment[index].comment = '';
				this.title = '댓글작성 오류';
				this.message = '새로고침 후에도 계속 에러가 발생한다면 고객센터로 문의해주세요.';
				this.successCallback = this.closeAlertModal;
				this.buttonName = '확인';
				this.showAlert = true;
			}
		},
		async handleSubmitAdditionalReply(no, feedbackNo, reply, index) {
			try {
				const data = {
					request_no: no,
					feedback_no: feedbackNo,
					comment: reply,
				};

				await this.$_fetchRequest({
					method: 'POST',
					url: '/api/request/additionalReply',
					data,
					isLoading: true,
				});

				this.userReply[index] = null;
				const category = CASE_CATEGORY_GTM_MAP[this.mentoringCategoryGroupId];
				// GTM - leaveReply
				if (this.userNo === this.parentUserNo) {
					leaveReply(
						this.userNo,
						this.userType,
						this.no,
						this.request,
						'mine',
						this.$_getCaseGTMCategory(category),
					);
				} else {
					leaveReply(
						this.userNo,
						this.userType,
						this.no,
						this.request,
						'others',
						this.$_getCaseGTMCategory(category),
					);
				}
				if (this.userNo !== this.feedbackList[index].user_no) {
					//자신의 댓글에는 메일이 가지않도록
					// GTM - newReplytoAnswer (멘토(답변자)의 답변에 멘티(질문자)가 댓글을 남겼을 때 발생) - 멘토링
					newReplytoAnswer(
						reply,
						this.goDetailUrl(),
						this.feedbackList[index].user_no,
						this.feedbackList[index].profile.email,
						this.question.other_inf,
						this.userNickname,
					);
				}

				this.showAlert = true;
				this.message = '댓글이 추가되었습니다';
				this.successCallback = this.closeAlertModal;
				localStorage.setItem('requestId', `feedback-${index}`);

				const replies = await this.getReplies(feedbackNo);
				if (replies) {
					this.feedbackList[index].reply = replies;
					this.$forceUpdate();
				} else {
					location.reload();
				}
			} catch (error) {
				console.log(error);
				closeLoading();
				this.title = '댓글작성 오류';
				this.message = '새로고침 후에도 계속 에러가 발생한다면 고객센터로 문의해주세요.';
				this.successCallback = this.closeAlertModal;
				this.buttonName = '확인';
				this.showAlert = true;
			}
		},
		setComment() {
			if (this.userAnswer === null || this.userAnswer.length < 20) {
				this.showAlert = true;
				this.message = '최소 20자 이상 입력해 주세요';
			} else {
				this.showConfirm = true;
				this.titleConfirm = '답변을 추가 하시겠습니까?';
			}
		},
		async setAdditionalComment() {
			if (this.display === 'feedback') {
				/**
				 * [답변하기] - answer
				 * display가 feedback으로 넘어오면 questionFeedback api를 사용함
				 **/
				if (!this.answer || this.answer.length <= 20) {
					this.title = '';
					this.message = '멘티를 위해 최소 20자 이상의<br/>피드백을 부탁드려요.';
					this.successCallback = this.closeAlertModal;
					this.buttonName = '확인';
					this.showAlert = true;
					return false;
				}

				showLoading();
				try {
					const { data } = await axios({
						method: 'POST',
						url: '/api/answer/questionFeedBack',
						data: {
							request_no: this.no,
							mentor_no: this.userNo,
							comment: this.answer,
						},
					});
					if (data.code === 200) {
						let cor = data.cor;
						const category = CASE_CATEGORY_GTM_MAP[this.mentoringCategoryGroupId];
						let info = {
							user_no: this.userNo,
							email: this.$store.state.sessionInfo.id,
							user_type: this.userType,
							user_level: this.userLevel,
							req_no: this.no,
							req_type: 'qa',
							cor_com: cor.cor_com,
							cor_in: cor.cor_in,
							cor_job: cor.cor_job,
							cor_interest: (await this.$_hasMatchingKeyword(this.question.job)) ? 'Y' : 'N',
							cor_univ: cor.cor_univ,
							where: this.isDummyQuestion ? 'ot' : '',
							category: this.$_getCaseGTMCategory(category),
							length: this.answer.length,
							re_mentoring: this.isRePull,
						};
						// GTM - leaveAnswer
						leaveAnswer(info);

						if (
							typeof this.answerCorrespondence === 'string' &&
							this.answerCorrespondence.toLowerCase() === 'true'
						) {
							// GTM - leaveAnswerWithCorrespondence
							leaveAnswerWithCorrespondence();
						}

						if (this.answer.length >= 390) {
							// GTM - leaveLongAnswer
							leaveLongAnswer({
								user_no: this.userNo,
								email: this.$store.state.sessionInfo.id,
								user_type: this.userType,
								request_no: this.no,
								request_type: 'qa',
								where: this.isDummyQuestion ? 'ot' : '',
								category: this.$_getCaseGTMCategory(category),
								length: this.answer.length,
								is_app: this.isApp,
							});
						}
						// 튜토리얼이면
						if (this.$_newbieMentorTutorialMixin_shouldInitTour) {
							return this.$_newbieMentorTutorialMixin_handleAfterSubmitTutorialQuestionFeedback();
						}
						this.$emit('submit-question-feedback', this.qaDetailUrl);
						// 리드멘토가 아닐 때 redirect하는 기능 제거
						// await this.$_goAfterAnswerPost();
						return;
					}
					if (data.code === 403) {
						this.showErrorToast(data.msg);
						return;
					}
					this.title = '답변하기 오류';
					this.message = '새로고침 후에도 에러가 계속 되면 고객센터로 문의해 주세요.';
					this.successCallback = this.closeAlertModal;
					this.buttonName = '확인';
					this.showAlert = true;
				} catch (error) {
					console.error(error);
					this.title = '답변하기 오류';
					this.message = '새로고침 후에도 에러가 계속 되면 고객센터로 문의해 주세요.';
					this.successCallback = this.closeAlertModal;
					this.buttonName = '확인';
					this.showAlert = true;
				} finally {
					closeLoading();
				}
			} else {
				showLoading();
				try {
					await axios({
						method: 'POST',
						url: '/api/request/additionalComment',
						data: {
							request_no: this.no,
							comment: this.userAnswer,
						},
					});
					const category = CASE_CATEGORY_GTM_MAP[this.mentoringCategoryGroupId];
					// GTM - leaveAnswer
					let info = {
						user_no: this.userNo,
						email: this.$store.state.sessionInfo.id,
						user_type: this.userType,
						user_level: this.userLevel,
						req_no: this.no,
						req_type: 'qa',
						where: this.isDummyQuestion ? 'ot' : 'add_answer',
						category: this.$_getCaseGTMCategory(category),
						length: this.userAnswer.length,
						re_mentoring: this.isRePull,
					};
					// GTM - leaveAnswer
					leaveAnswer(info);

					if (
						typeof this.answerCorrespondence === 'string' &&
						this.answerCorrespondence.toLowerCase() === 'true'
					) {
						// GTM - leaveAnswerWithCorrespondence
						leaveAnswerWithCorrespondence();
					}

					if (this.answer.length >= 390) {
						// GTM - leaveLongAnswer
						leaveLongAnswer({
							user_no: this.userNo,
							email: this.$store.state.sessionInfo.id,
							user_type: this.userType,
							request_no: this.no,
							request_type: 'qa',
							where: this.isDummyQuestion ? 'ot' : '',
							category: this.$_getCaseGTMCategory(category),
							length: this.answer.length,
							is_app: this.isApp,
						});
					}
					const feedbackLength = this.feedbackList.length;
					this.$store.state.requestId = `feedback-${feedbackLength}`;
					localStorage.setItem('requestId', `feedback-${feedbackLength}`);
					this.$emit('closeTextArea', this.closeTextArea);
					location.reload();
				} catch (error) {
					console.log(error);
				} finally {
					closeLoading();
				}
			}
		},
		clickCloseTextArea() {
			this.$emit('closeTextArea', this.closeTextArea);
		},
		goDetailUrl() {
			let company, position, req_no, o;
			if (this.question.constructor === Object) {
				company = this.question.company;
				position = this.question.job;
				req_no = this.question.no;
				o = this.question.other_inf;
			} else {
				company = this.question[0].company;
				position = this.question[0].job;
				req_no = this.question[0].no;
				o = this.question[0].other_inf;
			}

			this.makeUrl(company, position);
			const trans = o.replace(/\s+/g, '_');

			if (this.request === 'qa') {
				return `https://comento.kr/job-questions/${company}/${position}/${trans}-${req_no}`;
			} else {
				if (this.request === 'ce') {
					return `https://comento.kr/mine/essay?request_no=${req_no}`;
				} else if (this.request === 'in') {
					return `https://comento.kr/mine/interview?request_no=${req_no}`;
				}
			}
		},
		makeUrl(company, position) {
			company = company.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}]|\s+/gi, '');
			position = position.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}]|\s+/gi, '');

			company = company.replace('\n', '');
			company = company.replace('‘', '');
			company = company.replace('㈜', '');
			company = company.replace('’', '');

			position = position.replace('\n', '');
			position = position.replace('‘', '');
			position = position.replace('㈜', '');
			position = position.replace('’', '');

			if (company === '') company = '-';
			if (position === '') position = '-';
		},
		getEduPersonalizeEvent() {
			/* readCase 개인화 실험 */
			axios({
				method: 'GET',
				url: '/api/case/eduPersonalizeEvent',
			});
		},
		async getReplies(feedbackNo) {
			try {
				const params = {
					feedback_no: feedbackNo,
				};

				const { data } = await this.$_fetchRequest({
					method: 'get',
					url: '/api/case/replies',
					params,
				});
				return data;
			} catch (error) {
				console.log(error);
				return null;
			}
		},
		async fetchMentorProfile() {
			try {
				const {
					data: { profile },
				} = await this.$_fetchRequest({
					method: 'POST',
					url: '/api/answer/getMentorProfile',
					data: {
						user_no: this.userNo,
						belong: this.userBelong,
						request_no: this.no,
					},
				});
				this.mentorProfile = profile;
			} catch (error) {
				console.error(error);
			}
		},
		closeAlertModal() {
			this.showAlert = false;
		},
		emitHideAnswerTip() {
			this.$emit('hide-answer-tip');
		},
		showErrorToast(message) {
			Toast.show({
				message: message,
				type: 'error',
				position: this.isMobile ? 'bottom' : 'top',
			});
		},
	},
	components: {
		AdoptButton,
		CommonUserInformation,
		ReportModal,
		BaseSkeleton,
		'my-answer-component': {
			//내가 쓰는 답변창(PC.ver)
			template: `
				<div class='mb-60'>
				<c-box class='my-answer-box' :paddings='[20, 20, 20, 20]' background-color='white' has-border
					   border-color='gray200'>
					<CommonUserInformation
						v-if='mentorProfile'
						type='full'
						:nickname='mentorNickname'
						:company='userBelong'
						:avatar-user-no='parseInt(userNo)'
						:avatar-user-type='2'
						:choice-rate='mentorProfile.choice_rate'
						:grade-name="mentorProfile.grade.length > 0 ? mentorProfile.grade[0].name : ''"
						:user-profile-image='userProfileImage'
						class='mb-14'
					/>
					<div v-else class='flex flex-row'>
						<BaseSkeleton circle width='48px' height='48px' class='mr-6' />
						<div class='flex flex-column'>
							<BaseSkeleton width='110px' height='20px' />
							<BaseSkeleton width='100px' height='13px' class='mt-4' />
						</div>
					</div>
					<c-textarea
						v-autoresize
						type='basic'
						min-height='185px'
						:value.sync='sync_answer'
						id='mentoring_text'
						placeholder='멘토님의 답변은 질문 요청자뿐 아니라 취업에 어려움을 겪고 있는 수십만 명의 멘티님들이 함께 읽고 있습니다.'
						@paste="($event) => this.$emit('paste', $event)"
					/>
					<AnswerCountSection :count='sync_answer.length' class='mt-12' />
				</c-box>
				<c-button
					size='large'
					full
					class='mt-16'
					:disabled='isFinishedAnswerFeedback'
					@click='save()'
				>
						답변하기
				</c-button>
				<c-alert-modal :show.sync='showCopyAndPasteAlertModal' success-message='확인'
							   @close='showCopyAndPasteAlertModal = false'>
					<template v-slot:content><strong
						v-if='copyAndPasteAlertModalInfo.title'>{{ copyAndPasteAlertModalInfo.title }}</strong>
						<div v-html='copyAndPasteAlertModalInfo.message' />
					</template>
				</c-alert-modal>
				</div>
			`,
			props: [
				'userBelong',
				'answer',
				'userType',
				'display',
				'parentUserNo',
				'userNo',
				'mentorProfile',
				'isFinishedAnswerFeedback',
				'userProfileImage',
				'userNickname',
				'creditSum',
			],
			mixins: [preventCopyAndPasteMixin],
			data() {
				return {
					getAnswerCountWidth: '43px',
				};
			},
			computed: {
				sync_answer: {
					get() {
						return this.answer;
					},
					set(value) {
						this.$emit('update:answer' + '', value);
					},
				},
				mentorNickname() {
					return this.userNickname !== '' ? this.userNickname : `멘토${this.userNo}`;
				},
			},
			methods: {
				save() {
					if (this.isFinishedAnswerFeedback) {
						return false;
					}
					this.$emit('save');
				},
			},
			components: {
				CommonUserInformation,
				BaseSkeleton,
				AnswerCountSection,
			},
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

#FeedBack {
	.user_profile {
		margin-bottom: 16px;

		&_info {
			width: 80%;
			@include clearfix();

			> p {
				float: left;
				position: relative;
			}

			&_sub {
				float: left;
				width: 100%;
			}
		}
	}

	.tip-item {
		float: left;

		.alarm-box {
			top: 0;
			right: inherit;
			left: 118%;

			&::before {
				right: inherit;
				top: 5px;
				left: -12px;
				transform: rotate(-90deg);
			}

			.alarm-scroll {
				height: auto;
				overflow: hidden;
				width: 265px;
				padding: 8px 12px;
				border-radius: 4px;
				box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
				background: white;
			}
		}

		&.mentoOffcial {
			.alarm-box {
				top: 120%;
				right: inherit;
				left: 0;

				&:before {
					right: inherit;
					top: -11px;
					left: 50%;
					transform: rotate(0deg);
				}
			}
		}
	}

	.feedback_choose {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0 0 0 12px;
		overflow: hidden;
		background: #d6ebff;
		color: #1e90ff;
		font-size: 10px;
		padding: 0 10px;
	}

	.user_profile_info_sub {
		> span {
			margin-right: 8px;
		}

		&_list {
			margin-top: 2px;

			li {
				+ li {
					margin-left: 4px;
				}
			}

			&_last {
				margin-top: 1px;
			}
		}
	}

	.readcase_reply_container {
		.readcase_reply {
			position: absolute;
			bottom: 0;
			right: -10px;
			padding: 10px;
		}
	}

	.feedback_like {
		padding-left: 28px;

		&:before {
			content: '';
			width: 15px;
			height: 15px;
			display: block;
			float: left;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 6px;
			margin: auto;
			background-size: contain;
			background: url('/public/images/icon/icon-heart-line.svg') no-repeat center 0;
		}

		&.fill {
			&:hover,
			&:active {
				background-color: #cc2727 !important;

				&:before {
					background: url('/public/images/icon/icon-heart-fill.svg') no-repeat center 0;
				}
			}

			&:focus {
				display: none;
			}
		}
	}

	.feedback_reply {
		display: inline-block;
		height: 36px;
		line-height: 36px;
	}
}

@include mobile {
	.mobile-feedback-modal-button-wrapper {
		position: fixed;
		left: 0;
		bottom: 0;
		padding: 16px;
		background: $gray100;
		width: 100vw;
		z-index: 1;
	}
}

.expUserTextarea {
	::v-deep .c-textarea textarea {
		resize: none !important;
		overflow-y: scroll !important;
		height: 242px !important;
		margin: 10px 0 16px 0;
	}
}

.feedback-box {
	position: relative;
	background-color: white;
	@include pc {
		border: 1px solid $gray200;
		@include border-radius(2px);
	}
}

.mine-feedback-question {
	min-height: 80vh;
}
</style>
