<template>
	<modal-component id="essay-strength-modal" :show="show" @close="close">
		<div class="modal-body">
			<div class="modal-header">
				<h4>어떤 역량이 엿보이나요?</h4>
				<div>아래 역량 구분표를 참고해 가장 강조되는 역량을 선택하세요.</div>
			</div>
			<div class="scroll-body">
				<div v-for="(strength, index) in strengthDescriptionList" :key="`strength-${index}`" class="mb-20">
					<div class="mb-5">
						<strong
							class="border-right"
							:class="selectedStrength === strength.strength ? 'highlight-text' : ''"
						>
							{{ strength.strength }}
						</strong>
						<div class="text-primary c-default">{{ strength.keyword.join(', ') }}</div>
					</div>
					<p class="h7">{{ strength.description }}</p>
				</div>
			</div>
			<div class="one-edge-shadow"></div>
		</div>
	</modal-component>
</template>

<script>
export default {
	props: ['show', 'selectedStrength', 'strengthDescriptionList'],
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
@border-color: #e1e4e7;

#essay-strength-modal {
	.modal-header {
		padding-bottom: 15px;
		border-bottom: 1px solid @border-color;
	}
	.modal-body {
		position: relative;
		.scroll-body {
			-ms-overflow-y: scroll;
			overflow-y: scroll;
			padding: 15px;
			height: 395px;
		}
		.one-edge-shadow {
			-webkit-box-shadow: 0 0 20px 20px white;
			-moz-box-shadow: 0 0 20px 20px white;
			box-shadow: 0 0 20px 20px white;
			position: absolute;
			width: 100%;
		}
	}
}
</style>
