<template>
	<div>
		<IEAlert v-if="isShowIEAlert" @close="closeIEAlert" />
		<AlertLayout v-if="!isBlog && IS_SHOW_ALERT_LAYOUT" :global-notice-info="globalNoticeInfo" />
		<HeaderLayout
			:univ="univ"
			:major="major"
			:belong="belong"
			:buy-mentoring-paid="buyMentoringPaid"
			:buy-mentoring-tier-paid="buyMentoringTierPaid"
			:buy-mentoring-info="buyMentoringInfo"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import IEAlert from '../components/navigation/molecules/alert/IEAlert.vue';
import AlertLayout from './AlertLayout.vue';
import HeaderLayout from './HeaderLayout.vue';
import {
	IS_SHOW_ALERT_LAYOUT,
	SET_IS_SHOW_GLOBAL_NOTICE_ALERT,
	SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT,
} from '../store/modules/layoutStore';
import dayjs from 'dayjs';

export const GLOBAL_NOTICE_ALERT = `global-notice-alert`;
export const PT_FIRST_ANNIVERSARY_ALERT_KEY = 'pt-first-anniversary-alert';

export default {
	name: 'HeaderWrapper',
	props: {
		univ: String,
		major: String,
		belong: String,
		buyMentoringPaid: String,
		buyMentoringTierPaid: String,
		buyMentoringInfo: String,
	},
	data() {
		return {
			isShowIEAlert: false,
			isShowGlobalNoticeBanner: false,
			globalNoticeInfo: null,
		};
	},
	computed: {
		...mapGetters('layoutStore', [IS_SHOW_ALERT_LAYOUT]),
		isBlog() {
			return this.$route.path.startsWith('/blog');
		},
		isNovember() {
			return dayjs().isBefore(dayjs('2022-12-01'));
		},
	},
	created() {
		this.fetchNotice();
		this.getPtFirstAnniversaryStatus();
	},
	mounted() {
		const { userAgent } = window.navigator;
		this.isShowIEAlert = userAgent.includes('MSIE') || userAgent.includes('Trident/');
	},
	methods: {
		...mapMutations('layoutStore', [SET_IS_SHOW_GLOBAL_NOTICE_ALERT, SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]),
		closeIEAlert() {
			this.isShowIEAlert = false;
		},
		async fetchNotice() {
			try {
				const noticePlatform = this.isMobile ? 'mobile' : 'pc';
				const { data } = await this.$_fetchRequest({
					method: 'get',
					url: `/api/pull/banner`,
					params: {
						filter: noticePlatform,
					},
				});

				if (!this.isEmptyData(data) && data.code !== 500) {
					if (!this.$cookies.get(`${GLOBAL_NOTICE_ALERT}${data.id}`)) {
						this.globalNoticeInfo = data;
						this[SET_IS_SHOW_GLOBAL_NOTICE_ALERT](true);
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		getPtFirstAnniversaryStatus() {
			if (this.isNovember && !this.$cookies.get(PT_FIRST_ANNIVERSARY_ALERT_KEY)) {
				this[SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT](true);
			}
		},
	},
	components: {
		IEAlert,
		AlertLayout,
		HeaderLayout,
	},
};
</script>

<style scoped lang="scss">
::v-deep .c-header .v-popover {
	height: 0 !important;
	.c-tooltip {
		top: -14px !important;
	}
}
</style>
