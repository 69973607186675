<template>
	<div class="row">
		<div v-for="(card, index) in blogList" :key="index" class="col-md-4">
			<CustomLink class="blog-card" :to="goPost(card)" onclick="return false;">
				<div class="blog-card-image-container">
					<router-link :src="card.thumbnail" class="blog-thumbnail" :to="goPost(card)" tag="img" />
				</div>
				<router-link
					class="blog-card-category mb-5"
					:to="`/blog/list/${card.blog_magazine.description}/${card.blog_category.name}`"
					tag="p"
				>
					<u>{{ card.blog_category.name }}</u>
				</router-link>
				<router-link class="blog-card-title" :to="goPost(card)" tag="h5">
					{{ card.title }}
				</router-link>
			</CustomLink>
		</div>
	</div>
</template>

<script>
import CustomLink from '../common/CustomLink.vue';

export default {
	props: {
		blogList: Array,
		default: [],
	},
	methods: {
		goPost(card) {
			return `/blog/view/${card.blog_magazine.description}/${card.blog_category.name}-${card.no}`;
		},
	},
	components: { CustomLink },
};
</script>

<style lang="less" scoped>
.row {
	display: flex;
	flex-wrap: wrap;
}
@media (min-width: 768px) {
	.col-md-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
}
@media (max-width: 480px) {
	.col-md-4 {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		/*&:last-child {*/
		/*    .blog-card {*/
		/*        margin-bottom: 0;*/
		/*    }*/
		/*}*/
	}
}
</style>
