const DEFAULT_PERMISSION_BUTTON_TEXT = '확인';
const DEFAULT_PERMISSION_BUTTON_COLOR = 'error';

const permissionModalMixin = {
	data() {
		return {
			showPermissionModal: false,
			permissionTitle: '',
			permissionContent: '',
			permissionCallback: null,
			permissionButton: DEFAULT_PERMISSION_BUTTON_TEXT,
			permissionButtonColor: DEFAULT_PERMISSION_BUTTON_COLOR,
		};
	},
	mounted() {
		this.permissionCallback = () => {};
	},
	methods: {
		$_openPermissionModal({ title, content, button, buttonColor, callback }) {
			this.permissionTitle = title;
			this.permissionContent = content;
			this.permissionButton = button ?? DEFAULT_PERMISSION_BUTTON_TEXT;
			this.permissionButtonColor = buttonColor ?? DEFAULT_PERMISSION_BUTTON_COLOR;
			this.permissionCallback = callback;
			this.showPermissionModal = true;
		},
		$_closePermissionModal() {
			this.permissionModalTitle = '';
			this.permissionModalContent = '';
			this.permissionButton = DEFAULT_PERMISSION_BUTTON_TEXT;
			this.permissionButtonColor = DEFAULT_PERMISSION_BUTTON_COLOR;
			this.permissionCallback = () => {};
			this.showPermissionModal = false;
		},
	},
};

export default permissionModalMixin;
