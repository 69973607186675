<template>
	<div class="bg-gray min-height">
		<div class="share-popup margin-auto bg-white">
			<div class="border-bottom pt-10 pb-10">
				<img class="title-logo" src="/images/logo/logo-comento-typo-type.png" />
				<div class="text-caption font-caption">시작하는 사람들의 커리어 커뮤니티</div>
			</div>
			<div class="pt-20">
				<div class="font-login-title f-semi-bold">공동 평가자 인증</div>
				<div class="font">
					채용관리자 이재성 대표님이, ㈜코멘토 Front-end 개발 직무 지원자에 대한 평가를 요청하셨습니다.
					관리자에게 전달받은 PINCODE를 입력하면 지원자를 열람할 수 있습니다.
				</div>
				<div class="font-caption f-red">* 본 링크는 {{ dueDate }}까지만 유효합니다.</div>
			</div>
			<div class="pt-20">
				<span class="font text-caption pin-font">PINCODE 입력</span>
				<div class="inline-block">
					<input
						ref="pin0"
						v-model="pin0"
						class="border pin"
						type="text"
						maxlength="1"
						@keydown.enter="verification"
					/>
					<input
						ref="pin1"
						v-model="pin1"
						class="border pin"
						type="text"
						maxlength="1"
						@keydown.enter="verification"
					/>
					<input
						ref="pin2"
						v-model="pin2"
						class="border pin"
						type="text"
						maxlength="1"
						@keydown.enter="verification"
					/>
					<input
						ref="pin3"
						v-model="pin3"
						class="border pin"
						type="text"
						maxlength="1"
						@keydown.enter="verification"
					/>
				</div>
				<button class="btn pinBtn" @click="verification">인증</button>
			</div>
		</div>
		<alert-modal-component
			:show="show"
			:title="title"
			:message="message"
			type="simple"
			:buttonName="buttonName"
			:successCallback="successCallback"
			@close="show = false"
		></alert-modal-component>
	</div>
</template>

<script>
export default {
	props: ['code', 'dueDate'],
	data() {
		return {
			show: false,
			title: '',
			message: '',
			buttonName: '',
			successCallback: '',
			date: '',
			pin0: '',
			pin1: '',
			pin2: '',
			pin3: '',
			pin: '',
		};
	},
	watch: {
		pin0() {
			this.$refs.pin1.focus();
		},
		pin1() {
			this.$refs.pin2.focus();
		},
		pin2() {
			this.$refs.pin3.focus();
		},
	},
	methods: {
		verification() {
			if (this.pin0 !== '' && this.pin1 !== '' && this.pin2 !== '' && this.pin3 !== '') {
				this.pin = `${this.pin0}${this.pin1}${this.pin2}${this.pin3}`;
				let data = {
					pin_code: this.pin,
					code: this.code,
				};
				showLoading();
				axios({
					method: 'POST',
					url: '/api/apply/share/check',
					data: data,
				})
					.then(response => {
						closeLoading();
						const data = response.data;
						let result = JSON.parse(data.result);
						if (data.code === 200 && result.check === 1) {
							location.href = result.url;
						} else {
							this.show = true;
							this.title = '인증 오류';
							this.message = 'PIN CODE를 확인해주세요!';
						}
					})
					.catch(error => {
						closeLoading();
						console.log(error);
						this.show = true;
						this.title = '인증 오류';
						this.message = 'PIN CODE를 확인해주세요!';
					});
			} else {
				this.show = true;
				this.title = '인증 오류';
				this.message = 'PIN CODE 모두 입력해주세요!';
			}
		},
	},
};
</script>

<style lang="less" scoped>
@border-color: #e1e4e7;

.margin-auto {
	margin: 0 auto;
}

.min-height {
	min-height: 100vh;
}

.title-logo {
	width: 150px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.pin-font {
	position: relative;
	top: -5px;
}

.pin {
	margin-right: auto;
	margin-left: auto;
	border: 1px solid @border-color;
	width: 40px;
	height: 45px;
	line-height: 48px;
	text-align: center;
	font-size: 28px;
	margin-right: 3px;
}

.pinBtn {
	height: 45px;
	width: 70px;
	position: relative;
	top: -5px;
}
</style>
