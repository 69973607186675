<template>
	<transition name="modal">
		<div v-show="show" class="modal-mask" @click="close">
			<div class="modal-container" :style="modalWidth" @click.stop>
				<img class="box-remove icon-sm-md pointer" src="/images/gray_remove.svg" @click="close" />
				<slot></slot>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	props: ['show', 'width'],
	computed: {
		modalWidth() {
			//모달 width 사이즈
			if (this.width !== '') {
				return 'width:' + this.width + 'px';
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			document.addEventListener('keydown', e => {
				if (this.show && e.keyCode === 27) {
					this.close();
				}
			});
		});
	},
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
* {
	box-sizing: border-box;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.3s ease;
}

.modal-container {
	width: 460px;
	margin: 0 auto;
	padding: 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
@media (max-width: 480px) {
	.modal-container {
		width: 90% !important;
	}
}

.box-remove {
	width: 20px;
	float: right;
	position: relative;
	top: -15px;
	right: -15px;
}

.modal-header h4 {
	margin-top: 0;
	color: #42b983;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}
</style>
