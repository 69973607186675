<template id="alert-modal-template">
	<modal-component :show="show" :type="type" @close="close">
		<div v-if="imageSrc == '' || imageSrc === undefined" id="alert-component" class="modal-body text-center">
			<h4
				class="popup-title mb-20 text-center"
				:style="type === 'simple' ? '' : 'margin-left:25px'"
				v-html="title"
			></h4>
			<div class="text-heading mb-20 text-center">
				<p class="modal-message" v-html="$options.filters.nl2br(message)"></p>
			</div>
			<div class="mb-20" v-html="modalBox"></div>
			<button
				class="btn text-center pl-30 pr-30"
				style="height: 40px; line-height: 40px; padding: 0 50px !important"
				@click="save"
				v-html="button"
			></button>
		</div>
		<div v-else id="alert-component" class="modal-body text-center img-modal-container">
			<img class="box-remove icon-sm-md pointer" src="/images/gray_remove.svg" @click="close" />
			<img :src="'/images/popup/' + this.imageSrc" />
			<button
				v-if="btnActive === true"
				class="btn text-center"
				style="width: 130px"
				@click="completeUserMission()"
				v-html="button"
			></button>
		</div>
	</modal-component>
</template>

<script>
export default {
	props: [
		'show',
		'title',
		'message',
		'modalBox',
		'successCallback',
		'buttonName',
		'type',
		'mask',
		'window',
		'imageSrc',
		'btnActive',
		'function',
	],
	computed: {
		button() {
			let buttonResult = '';
			if (this.buttonName === '' || typeof this.buttonName === 'undefined') {
				buttonResult = '확인';
			} else {
				buttonResult = this.buttonName;
			}
			return buttonResult;
		},
	},
	methods: {
		close() {
			if (this.mask !== 'false') {
				this.$emit('close');
			}
		},
		save() {
			if (this.successCallback !== '' && this.successCallback !== undefined) {
				if (this.successCallback === 'reload') {
					location.reload();
				} else {
					if (this.window === '_blank') {
						window.open(this.successCallback, '_blank');
					} else {
						location.href = this.successCallback;
					}
				}
			} else if (this.function !== '' && typeof this.function !== 'undefined') {
				this.function();
			}
			this.$emit('save');
			this.$emit('close');
		},
		completeUserMission() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
.img-modal-container {
	margin: -35px;
	background: transparent !important;
	padding: 0 !important;

	.box-remove {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 25px;
		height: 25px;
	}

	button.btn {
		position: absolute;
		bottom: 35px;
		left: 0;
		right: 0;
		margin: auto;
		background: #fff;
		color: #1e90ff;
		border-color: #1e90ff;
	}
}
</style>
