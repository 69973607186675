<template>
	<c-side-drawer class="noti-side-drawer" mask-closable :closeable="false" v-bind="$attrs" v-on="$listeners">
		<template v-slot:title>알림</template>
		<template v-slot:right-content>
			<NotiReadAllButton @read-all="emitReadAll" />
		</template>
		<slot />
	</c-side-drawer>
</template>

<script>
import NotiReadAllButton from './NotiReadAllButton.vue';

export default {
	methods: {
		emitReadAll() {
			this.$emit('read-all');
		},
	},
	components: { NotiReadAllButton },
};
</script>

<style lang="scss" scoped>
.c-alarm-list-item {
	background: white;
}

.noti-side-drawer {
	z-index: 100;
}
</style>
