<template>
	<!--contents -->
	<article class="card_component">
		<slot />
	</article>
</template>

<script>
export default {
	name: 'Contents',
	props: {},
};
</script>
<style scoped lang="less">
@import '../../../../../less/base/index';
.card_component {
	position: relative;
}
</style>
