import _ from 'lodash';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { CLASS_VOD_LIST_PATH, SEARCH_PATH } from '../constants/paths';
import routeMixin from './routeMixin';
import { encodeKeyword, getRoutePath } from '../utils/util';
import { SEARCH_PRODUCT_TYPES, SEARCH_SORT_TYPES } from '../constants/search';

const searchMixin = {
	mixins: [routeMixin],
	data: () => ({
		searchKeyword: '',
		popularKeywordList: [],
	}),
	computed: {
		...mapGetters(['sessionInfo', 'keyword']),
		searchPlaceholder() {
			const path = this.$route.path;
			if (path.includes('answer')) {
				return '요청을 검색하세요.';
			} else if (path.includes('edu') || path.includes('vod')) {
				return '관심 직무를 입력하세요.';
			} else if (path.includes('mypage')) {
				if (this.sessionInfo && this.sessionInfo.type === 2) {
					return '두 단어 이상 입력하세요.';
				} else {
					return '직무명, 회사명, 취업고민';
				}
			} else {
				return '직무명, 회사명, 취업고민';
			}
		},
		popularKeywordLabel() {
			return this.isEduPage ? '인기캠프검색어' : '인기검색어';
		},
		isEduPage() {
			return this.$route.path.includes('/classroom') || this.$route.path.includes('/edu');
		},
		indexUrl() {
			if (this.isEduPage) {
				return `/edu`;
			}
			return `/`;
		},
	},
	async created() {
		this.searchKeyword = this.keyword;
	},
	methods: {
		$_handleBackward() {
			const historyBackUrl = {
				'/job-questions/': '/job-questions',
				'/edu/learn/': '/edu/list',
			};

			let hrefUrl = null;
			Object.keys(historyBackUrl).some(key => {
				if (location.pathname.indexOf(key) !== -1) {
					hrefUrl = historyBackUrl[key];
					return true;
				}
			});
			if (hrefUrl !== null) {
				if (this.sessionInfo) {
					this.$_routeMixin_go_back();
				} else {
					window.location.href = hrefUrl;
				}
			} else {
				window.history.back();
			}
		},
		$_removeSpecialChar(keyword) {
			// 특수문자 제거
			const regExp = /[\{\}\[\]\/|\)*~`+<>@%\\\(\'\"]/gi;
			return keyword.replace(regExp, '');
		},
		async $_handleSearch(searchType) {
			const keyword = this.$_removeSpecialChar(this.searchKeyword);
			// if (keyword === '') {
			// 	// 빈값 검색 막기
			// 	return false;
			// }
			await this.$_handleGTM(keyword);
			await this.$_handleDongjakSearch(keyword);
			await this.$_setRecentKeyword(keyword); // 최근 검색어에 등록하기
			await this.$_setSearchLog(keyword, searchType);
			await this.$_doSearch(keyword);
		},
		$_handleGTM(keyword, searchType) {
			// GTM - searchContent
			searchContent({
				search_keyword: keyword,
				type: searchType,
			});
		},
		$_handleDongjakSearch(keyword) {
			// 동작구청 입력시, 동작구청 신청페이지로 이동하기
			const dongjakKeywords = ['동작구청', '동작구', 'ehdwkrrncjd', 'ehdwkrrn'];
			if (dongjakKeywords.includes(keyword)) {
				window.open(`/edu/univ/dongjak`);
			}
		},
		$_setRecentKeyword(keyword) {
			const recentKeyword = JSON.parse(localStorage.getItem('search_keyword_list')) || [];

			//중복이면 이전 기록 삭제
			if (_.includes(_.map(recentKeyword, 'keyword'), keyword)) {
				const idx = _.indexOf(_.map(recentKeyword, 'keyword'), keyword);
				recentKeyword.splice(idx, 1);
			}
			recentKeyword.unshift({
				//배열 맨앞에 추가
				keyword,
				date: dayjs().format('MM DD YYYY, h:mm:ss a'),
			});
			localStorage.setItem('search_keyword_list', JSON.stringify(recentKeyword));
			if (JSON.parse(localStorage.getItem('search_keyword_list')).length >= 6) {
				recentKeyword.splice(6, JSON.parse(localStorage.getItem('search_keyword_list')).length);
				localStorage.setItem('search_keyword_list', JSON.stringify(recentKeyword));
			}
		},
		/**
		 * 검색어 로그 쌓기
		 * @param keyword
		 * @param searchType
		 */
		$_setSearchLog(keyword, searchType) {
			const data = {
				keyword: decodeURIComponent(keyword),
				search_type: searchType,
			};
			axios.post('/api/log/search', data).then().catch();
		},
		$_doSearch(keyword) {
			const searchURL = () => {
				const path = this.$route.path;
				if (path.includes('edu')) {
					return '/edu/list/';
				} else if (path.includes('job-wiki')) {
					return '/job-wiki/';
				} else if (path.includes('jobs')) {
					return keyword ? '/search/jobs/' : '/jobs';
				} else if (path.includes('answer')) {
					return keyword ? '/answer/search/' : '/answer';
				} else if (path.includes('vod')) {
					return keyword ? `${CLASS_VOD_LIST_PATH}?query=` : CLASS_VOD_LIST_PATH;
				} else {
					// return '/search/';
					return '/job-questions/';
				}
			};

			location.href = searchURL() + encodeURIComponent(keyword);
		},
		/**
		 * 검색 관련 이벤트들
		 * @param keyword
		 * @param searchType: typing, popular, recent
		 */
		async $_triggerSearch(keyword, searchType) {
			this.searchKeyword = keyword;
			this.$_handleGTM(keyword, searchType);
			this.$_handleDongjakSearch(keyword);
			await this.$_setSearchLog(keyword, searchType);
		},
		$_getSearchParams(product) {
			const getQueryParams = product => {
				if (this.$_isAnswer || product === SEARCH_PRODUCT_TYPES.ANSWER) {
					return {
						where: SEARCH_PRODUCT_TYPES.INDEX,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
						category: 0,
					};
				}
				if (this.$_isCommunityMenu || product === SEARCH_PRODUCT_TYPES.COMMUNITY) {
					return {
						where: SEARCH_PRODUCT_TYPES.COMMUNITY,
						sort: SEARCH_SORT_TYPES.RECENT,
						category: 0,
					};
				}
				if (
					this.$_isEduMenu ||
					this.$_isPathMenu ||
					this.$_isClassHome ||
					product === SEARCH_PRODUCT_TYPES.EDU
				) {
					// 진로캠, 클래스홈도 통함검색 직부캠으로 보여주기
					return {
						where: SEARCH_PRODUCT_TYPES.EDU,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
					};
				}
				if (this.$_isVodMenu || product === SEARCH_PRODUCT_TYPES.VOD) {
					return {
						where: SEARCH_PRODUCT_TYPES.VOD,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
					};
				}
				if (this.$_isPt || product === SEARCH_PRODUCT_TYPES.CLASS_PT) {
					return {
						where: SEARCH_PRODUCT_TYPES.CLASS_PT,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
					};
				}
				if (this.$_isCareerMenu || product === SEARCH_PRODUCT_TYPES.CAREER) {
					return {
						where: SEARCH_PRODUCT_TYPES.CAREER,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
					};
				}
				return {};
			};
			const { query, path } = this.$route;
			const isNotSearch = !path.startsWith('/search/');
			const isAppendFrom = this.isMobile && isNotSearch;
			const searchParams = {};

			if (isAppendFrom) {
				searchParams.from = path;
			} else if (Object.prototype.hasOwnProperty.call(query, 'from')) {
				searchParams.from = query.from;
			}

			if (isNotSearch && Object.prototype.hasOwnProperty.call(getQueryParams(product), 'where')) {
				searchParams.where = getQueryParams(product).where;
			} else if (Object.prototype.hasOwnProperty.call(getQueryParams(query), 'where')) {
				searchParams.where = query.where;
			}

			if (Object.prototype.hasOwnProperty.call(getQueryParams(product), 'sort')) {
				searchParams.sort = getQueryParams(product)?.sort;
			} else {
				delete searchParams.sort;
			}

			if (Object.prototype.hasOwnProperty.call(getQueryParams(product), 'category')) {
				searchParams.category = getQueryParams(product)?.category;
			} else if (Object.prototype.hasOwnProperty.call(query, 'category')) {
				delete searchParams.category;
			}

			return searchParams;
		},
		/**
		 * 통합검색 페이지 path + queryParams
		 * @param encodedKeyword
		 * @param product: index, answer...
		 * @returns {string}
		 */
		$_getSearchUrl(encodedKeyword, product) {
			return (
				`${SEARCH_PATH}/${product}/${encodedKeyword}?` +
				new URLSearchParams({ ...this.$_getSearchParams(product), legacy: true }).toString()
			);
		},
		/**
		 * 검색 페이지로 보내기(예외: 자유게시판)
		 * 모바일 검색일 때는 from 붙이기
		 * @param keyword
		 * @returns {Promise<Route>|void}
		 */
		$_goSearchPage(keyword) {
			const encodedKeyword = encodeKeyword(keyword);
			const { path } = this.$route;
			const product = () => {
				/**
				 * 답변하기, 채용은 바로 해당 검색페이지로 보내기
				 * 재검색은 해당 검색페이지로 보내기
				 */
				const isAnswer = path.startsWith('/answer') || path.startsWith('/search/answer/');
				const isCareer = path.startsWith('/career/externship') || path.startsWith('/search/career/');
				const isCommunitySearch = path.startsWith('/search/community/');
				const isEduSearch = path.startsWith('/search/edu/');
				const isPtSearch = path.startsWith('/search/pt/');
				const isVodSearch = path.startsWith('/search/vod/');
				if (isAnswer) return SEARCH_PRODUCT_TYPES.ANSWER;
				if (isCareer) return SEARCH_PRODUCT_TYPES.CAREER;
				if (isCommunitySearch) return SEARCH_PRODUCT_TYPES.COMMUNITY;
				if (isEduSearch) return SEARCH_PRODUCT_TYPES.EDU;
				if (isPtSearch) return SEARCH_PRODUCT_TYPES.CLASS_PT;
				if (isVodSearch) return SEARCH_PRODUCT_TYPES.VOD;
				return SEARCH_PRODUCT_TYPES.INDEX;
			};
			const searchUrl = this.$_getSearchUrl(encodedKeyword, product());
			this.$_routeMixin_href(getRoutePath(searchUrl, 'nuxt'));
		},
	},
};

export default searchMixin;
