<template>
	<swiper :options="options" v-bind="$attrs" v-on="$listeners">
		<swiper-slide v-for="(item, index) in Object.keys($slots).length" :key="`swiper-slide-${index}`">
			<slot :name="'item' + index" />
		</swiper-slide>
	</swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
	name: 'CustomSwiper',
	props: {
		options: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		Swiper,
		SwiperSlide,
	},
};
</script>
