<template><img :src="imageSrc" alt="자소서 튜토리얼 진행상황을 나타내는 캐릭터" /></template>

<script>
import { cdnImageBaseUrl } from '@/constants';

export default {
	name: 'EssayTutorialStepGraphic',
	props: {
		step: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		imageSrc() {
			return `${cdnImageBaseUrl}/community/essay-tutorial/essay-tutorial-progress-bar-graphic-character-step-${this.step}.svg`;
		},
	},
};
</script>

<style lang="scss" scoped></style>
