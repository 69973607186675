<template>
	<div class="bg-gray min-height">
		<div class="share-popup margin-auto bg-white">
			<div class="border-bottom pt-10 pb-10">
				<img class="title-logo" src="/images/logo/logo-comento-typo-type.png" />
				<div class="text-caption font-caption">시작하는 사람들의 커리어 커뮤니티</div>
			</div>
			<div v-if="info.remove_flag === 0 && info.fin_flag === 0">
				<div class="pt-20">
					<div class="font-login-title f-semi-bold">
						{{ info.com_info.name }} {{ info.com_info.job }} 면접
					</div>
					<div class="font">기업이 제안한 시간 중 가능한 시간을 선택후 확정버튼을 눌러주세요.</div>
				</div>
				<div class="font-caption text-caption pt-20 pb-10">
					<!--면접비 안내문구 추가-->
					<template v-if="parseInt(info.pm_company_user_type) === 9">
						<div class="f-brand"><b>KB굿잡 우수기업 취업박람회 현장면접 안내</b></div>
						<div>- 면접은 선택한 시간으로부터 30분 가량 진행됩니다.</div>
						<div>- 장소: {{ info.location }}에서 면접이 진행됩니다.</div>
						<div>- 전형 진행을 원하지 않는 경우 지원 취소 버튼을 눌러주세요.</div>
					</template>
					<template v-else>
						<div v-if="parseInt(info.pm_company_user_type) === 8" class="f-brand">
							<b>- 면접 참석시, 면접비 3만원을 드립니다. (만 34세 미만에 한함)</b>
						</div>
						<div>- 면접은 시작 시간으로부터 {{ info.interview_time }} 가량 진행됩니다.</div>
						<div>- 가능한 시간이 없는 경우 다른 면접일정 요청 버튼을 눌러주세요.</div>
						<div>- 전형 진행을 원하지 않는 경우 지원 취소 버튼을 눌러주세요.</div>
					</template>
				</div>
				<div v-for="(list, index) in info.time_list" class="text-center">
					<div class="cursor-pointer interviewDate font" :class="clickBack[index]" @click="check(index)">
						{{ list.offer_time }}
					</div>
				</div>
				<div class="pt-20">
					<button v-if="choose !== null" class="btn interview-btn mt-5" @click="assurance(choose)">
						면접일정 확정
					</button>
					<button v-else class="pay-btn-disabled mt-5 height-45">면접일정 확정</button>
					<button
						v-if="parseInt(info.pm_company_user_type) !== 9"
						class="btn interview-btn mt-5"
						@click="request"
					>
						다른 면접일정 요청
					</button>
					<button class="btn interview-btn mt-5" @click="cancel">지원 취소</button>
				</div>
			</div>
			<div v-if="info.remove_flag === 1 || info.fin_flag === 1">
				<div v-if="info.fin_flag === 1" class="progress-box mt-20 bg-white margin-auto text-center">
					<div class="font">면접이 확정되었습니다.</div>
					<div class="font-title f-semi-bold">{{ info.interview_date }}</div>
				</div>

				<div v-if="info.remove_flag === 1">
					<div v-if="info.confirm === -1" class="progress-box mt-20 bg-white margin-auto text-center">
						<div class="font">면접 일정을 재요청 하였습니다.</div>
						<div class="font-title f-semi-bold">
							기업이 새로운 일정을 확정하면 다시 안내됩니다. 상태가 지속되는 경우 고객센터로 문의하세요.
						</div>
					</div>

					<div v-if="info.confirm === -2" class="progress-box mt-20 bg-white margin-auto text-center">
						<div class="font">면접이 취소되었습니다.</div>
						<div class="font-title f-semi-bold">딱 맞는 일자리를 찾을 때까지 코멘토가 함께하겠습니다.</div>
					</div>
				</div>
			</div>
		</div>
		<confirm-callback-component
			:show="show"
			:title="title"
			:message="message"
			:buttonName="buttonName"
			:method="api"
			:type="type"
			:align="align"
			@close="show = false"
		></confirm-callback-component>
	</div>
</template>

<script>
export default {
	props: ['info'],
	data() {
		return {
			choose: null,
			show: false,
			title: '',
			message: '',
			buttonName: '',
			type: '',
			align: 'center',
			clickBack: [],
			base_url: '/api/interview/',
			btnFlag: null,
		};
	},
	methods: {
		api() {
			let data = {
				interview_no: this.info.interview_no,
			};

			if (this.btnFlag === 'confirm') {
				data.schedule_no = this.info.time_list[this.choose].no;
			}

			if (parseInt(this.info.pm_company_user_type) === 9) {
				this.base_url = '/api/jobfair/interview/';
			}
			showLoading();
			axios({
				method: 'POST',
				url: this.base_url + this.btnFlag,
				data: data,
			})
				.then(response => {
					const data = response.data;
					console.log(data);
					const code = parseInt(data.code);
					if (code === 200) {
						const item = data.result;

						// GTM - confirmSchedule
						confirmSchedule(item.user_no, item.company_no);

						setTimeout(() => {
							location.reload();
						}, 1000);
					}
					closeLoading();
				})
				.catch(error => {
					closeLoading();
					console.log(error);
				});
		},
		check(idx) {
			this.clickBack[idx] = 'bg-click';
			this.clickBack[this.choose] = '';

			this.choose = idx;
		},
		assurance(idx) {
			this.show = true;
			this.title = '면접일정 확정';
			this.message = this.info.time_list[idx].offer_time + '으로 면접일정을 확정합니다.<br>진행하시겠습니까?';
			this.btnFlag = 'confirm';
		},
		request() {
			this.show = true;
			this.title = '면접일정 변경요청';
			this.message = '채용담당자에게 다른 면접일정을 요청합니다. 진행하시겠습니까?';
			this.btnFlag = 'reschedule';
		},
		cancel() {
			this.show = true;
			this.title = '지원 취소';
			this.message = '제안된 면접을 거절하고 전형을 종료합니다. 진행하시겠습니까?';
			this.btnFlag = 'cancel';
		},
	},
};
</script>

<style lang="less" scoped>
.margin-auto {
	margin: 0 auto;
}
.min-height {
	min-height: 100vh;
}
.title-logo {
	width: 150px;
}

.bg-click {
	background-color: #e7faef;
}

.interviewDate {
	padding: 12px;
	text-align: center;
	border: 1px solid #949494;
	border-radius: 3px;
	cursor: pointer;
	margin-bottom: 5px;

	&:hover {
		background-color: #e7faef;
	}
}

.pay-btn-disabled {
	font-size: 14px;
}

.interview-btn {
	width: 100%;
	font-size: 14px;
	height: 45px;
}

.height-45 {
	height: 45px !important;
}
.progress-box {
	border: 1px solid #e6e6e6;
	padding: 20px;
}
</style>
