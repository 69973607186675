<template>
	<div class="c-pointer another-login-button" @click="emitClick">
		<c-icon color="gray300" name="IconBackwardLargeLine" :rotate="180" />
		<c-typography class="ml-8" type="body1" color="gray400" font-weight="regular">
			다른 방법으로 시작하기
		</c-typography>
	</div>
</template>

<script>
export default {
	name: 'AnotherLoginButton',
	methods: {
		emitClick() {
			this.$emit('click');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../scss/base/main';
.another-login-button {
	@include flexbox();
	@include justify-content(flex-start);
	@include align-items(center);
	border-radius: 4px;
	height: 48px;
	padding-left: 16px;
	background-color: $gray000;
	&:hover {
		background: $gray100;
	}
}
</style>
