<template>
	<c-basic-modal
		:show.sync="show_sync"
		show-action-button
		persistent
		success-message="답변 더 하기"
		align="center"
		:success-callback="() => $emit('success-callback')"
		@close="$emit('close')"
	>
		<template v-slot:title>
			<img :src="imageSrc" alt="" />
		</template>
		<template v-slot:content>
			<c-typography type="headline5" color="gray900" :font-weight="400" class="mb-16">
				멘티의 편지가 도착했어요.
			</c-typography>
			<div class="answer-detail-thanks-modal-content-wrapper">
				<c-typography type="body2" color="gray800">
					멘토님의 경험과 조언이 있었기에<br />취업에 성공할 수 있었습니다!!<br />정말 감사드려요!
				</c-typography>
			</div>
		</template>
	</c-basic-modal>
</template>

<script>
import { cdnBaseUrl } from '../../constants';
import { mapGetters } from 'vuex';

export default {
	name: 'AnswerDetailThanksModal',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters(['buyMentoring']),
		imageSrc() {
			return `${cdnBaseUrl}/images/community/heart-letter.svg`;
		},
		show_sync: {
			get() {
				return this.show;
			},
			set(newVal) {
				this.$emit('update:show', newVal);
			},
		},
	},
	watch: {
		show(newValue) {
			if (!newValue) {
				return;
			}

			const shouldActivateNewBuyMentoring = !this.buyMentoring.info;
			if (shouldActivateNewBuyMentoring) {
				Toast.show({
					message: '30일 열람권을 받았어요.',
					type: 'success',
					position: 'top',
					showIcon: true,
					icon: 'IconCouponLargeLine',
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';

::v-deep .c-modal--close-button {
	top: 12px !important;
	right: 12px !important;
}

.answer-detail-thanks-modal-content-wrapper {
	padding: 16px 8px;
	background-color: $gray050;
}
</style>
