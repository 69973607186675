<template>
	<div>
		<biz-side-component num="1"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<biz-company-header-component
						main="기업정보 등록"
						sub1="매칭된 지원자가 회사에 대해 잘 알수 있도록 기업을 소개해 주세요. 초대된 지원자가 채용공고에서 회사에 대한 충분한 정보를 얻을 수 있다면 매칭 성사율이 30%이상 높아집니다. 기업 정보는 한 번만 등록하면 채용공고에 공통적으로 적용됩니다."
					/>
					<!--                    <div class="f-red font-caption pt-50">* 필수항목</div>-->
					<div class="bg-white border pd-30">
						<form enctype="multipart/form-data">
							<!-- 기업명 -->
							<div>
								<span class="font-title f-semi-bold">기업명</span>
								<!--                            <span class="f-red">*</span>-->
							</div>
							<div>
								<input
									v-model="companyName"
									class="com-inputbox font f-light pd-10 mt-10 mb-50"
									type="text"
									placeholder="예) 주식회사 코멘토"
								/>
							</div>

							<!-- 회사소개 -->
							<div>
								<span class="font-title f-semi-bold">회사 소개</span>
								<!--                            <span class="f-red">*</span>-->
								<div class="text-caption f-light font-caption">
									기업의 철학, 제품/서비스 등 기업에 대해 상세한 소개를 입력하세요. 구직자가 기업에
									대해 쉽게 이해할 수 있다면 매칭 가능성이 높아집니다.
								</div>
							</div>
							<div>
								<textarea
									v-model="companyDetail"
									class="com-textbox font f-light pd-10 mt-10 mb-50"
									:placeholder="placeholderTxt(1)"
								/>
							</div>

							<!-- 홈페이지 주소 -->
							<div>
								<span class="font-title f-semi-bold">홈페이지 주소</span>
							</div>
							<div>
								<input
									v-model="companyUrl"
									class="com-inputbox font f-light pd-10 mt-10 mb-50"
									type="text"
									placeholder="예) https://comento.kr"
								/>
							</div>

							<!-- 근무지 -->
							<div>
								<span class="font-title f-semi-bold">근무지</span>
								<!--                            <span class="f-red">*</span>-->
							</div>
							<div class="mt-10 mb-50">
								<div>
									<input
										id="seoul"
										v-model="companyCity"
										class="com-radio"
										type="radio"
										value="서울"
									/>
									<label class="font cursor-pointer" for="seoul">서울</label>
								</div>
								<div>
									<input
										id="otherCity"
										v-model="companyCity"
										class="com-radio"
										type="radio"
										value="기타"
									/>
									<label class="font cursor-pointer" for="otherCity">
										기타
										<input
											v-model="city"
											class="com-inputbox-short font f-light pd-10 ml-20"
											type="text"
											placeholder="기업 소재 도시를 입력하세요."
										/>
									</label>
								</div>
							</div>

							<!-- 직원수 -->
							<div>
								<span class="font-title f-semi-bold">직원 수</span>
								<!--                                <span class="f-red">*</span>-->
							</div>
							<div class="mt-10 mb-50">
								<input id="1" v-model="employeeNum" class="com-radio" type="radio" value="0" /><label
									class="font cursor-pointer"
									for="1"
								>10명 미만</label
								>
								<input id="10" v-model="employeeNum" class="com-radio" type="radio" value="1" /><label
									class="font cursor-pointer"
									for="10"
								>10~30명</label
								>
								<input id="30" v-model="employeeNum" class="com-radio" type="radio" value="2" /><label
									class="font cursor-pointer"
									for="30"
								>30~50명</label
								>
								<input id="50" v-model="employeeNum" class="com-radio" type="radio" value="3" /><label
									class="font cursor-pointer"
									for="50"
								>50~100명</label
								><br />
								<input id="100" v-model="employeeNum" class="com-radio" type="radio" value="4" /><label
									class="font cursor-pointer"
									for="100"
								>100~500명</label
								>
								<input id="500" v-model="employeeNum" class="com-radio" type="radio" value="5" /><label
									class="font cursor-pointer"
									for="500"
								>500~1000명</label
								>
								<input id="1000" v-model="employeeNum" class="com-radio" type="radio" value="6" /><label
									class="font cursor-pointer"
									for="1000"
								>1000명 이상</label
								>
							</div>

							<!-- 기업구분 -->
							<div>
								<span class="font-title f-semi-bold">기업 구분</span>
								<!--                                <span class="f-red">*</span>-->
							</div>
							<div class="mt-10 mb-50">
								<input id="big" v-model="companyType" class="com-radio" type="radio" value="0" /><label
									class="font cursor-pointer"
									for="big"
								>대기업</label
								>
								<input id="mid" v-model="companyType" class="com-radio" type="radio" value="1" /><label
									class="font cursor-pointer"
									for="mid"
								>중견기업</label
								>
								<input
									id="small"
									v-model="companyType"
									class="com-radio"
									type="radio"
									value="2"
								/><label class="font cursor-pointer" for="small">중소기업</label>
								<input
									id="startup"
									v-model="companyType"
									class="com-radio"
									type="radio"
									value="3"
								/><label class="font cursor-pointer" for="startup">스타트업</label><br />
								<input
									id="social_venture"
									v-model="companyType"
									class="com-radio"
									type="radio"
									value="4"
								/><label class="font cursor-pointer" for="social_venture">소셜벤처</label>
								<input id="ngo" v-model="companyType" class="com-radio" type="radio" value="5" /><label
									class="font cursor-pointer"
									for="ngo"
								>비영리 단체</label
								>
								<input
									id="incorp"
									v-model="companyType"
									class="com-radio"
									type="radio"
									value="6"
								/><label class="font cursor-pointer" for="incorp">사단법인</label>
								<input id="etc" v-model="companyType" class="com-radio" type="radio" value="7" /><label
									class="font cursor-pointer"
									for="etc"
								>기타</label
								>
							</div>

							<!-- 근무시간 -->
							<div>
								<span class="font-title f-semi-bold">근무시간</span>
								<!--                            <span class="f-red">*</span>-->
							</div>
							<div class="pl-10 mt-10 mb-50">
								<div>
									<input
										id="9"
										v-model="companyTime"
										type="checkbox"
										class="com-checkbox"
										value="9AM~6PM"
									/><label class="font cursor-pointer" for="9">9AM~6PM</label>
								</div>
								<div>
									<input
										id="10"
										v-model="companyTime"
										type="checkbox"
										class="com-checkbox"
										value="10AM~7PM"
									/><label class="font cursor-pointer" for="10">10AM~7PM</label>
								</div>
								<div>
									<input
										id="free"
										v-model="companyTime"
										type="checkbox"
										class="com-checkbox"
										value="자율 출퇴근"
									/><label class="font cursor-pointer" for="free">자율 출퇴근</label>
								</div>
								<div>
									<input
										id="flex"
										v-model="companyTime"
										type="checkbox"
										class="com-checkbox"
										value="유연근무"
									/><label class="font cursor-pointer" for="flex">유연근무</label>
								</div>
								<div>
									<input
										id="home"
										v-model="companyTime"
										type="checkbox"
										class="com-checkbox"
										value="재택근무"
									/><label class="font cursor-pointer" for="home">재택근무</label>
								</div>
								<div>
									<input
										id="otherTime"
										v-model="companyTime"
										type="checkbox"
										class="com-checkbox"
										value="기타"
									/>
									<label class="font cursor-pointer" for="otherTime"
									>기타
										<input
											v-model="time"
											class="com-inputbox-short font f-light pd-10 ml-20"
											type="text"
											placeholder="여기를 눌러 입력하세요."
										/>
									</label>
								</div>
							</div>

							<!-- 의료보험 -->
							<div>
								<span class="font-title f-semi-bold">의료/보험</span>
								<!--                            <span class="f-red">*</span>-->
							</div>
							<div class="pl-10 mt-10 mb-50">
								<div>
									<input
										id="fourInsurance"
										v-model="companyInsurance"
										type="checkbox"
										class="com-checkbox"
										value="4대 보험"
									/>
									<label class="font cursor-pointer" for="fourInsurance">4대 보험</label>
								</div>
								<div>
									<input
										id="medical"
										v-model="companyInsurance"
										type="checkbox"
										class="com-checkbox"
										value="의료실비보험"
									/>
									<label class="font cursor-pointer" for="medical">의료실비보험</label>
								</div>
								<div>
									<input
										id="comprehensiveHealth"
										v-model="companyInsurance"
										type="checkbox"
										class="com-checkbox"
										value="종합건강검진"
									/>
									<label class="font cursor-pointer" for="comprehensiveHealth">종합건강검진</label>
								</div>
								<div>
									<input
										id="tributeFee"
										v-model="companyInsurance"
										type="checkbox"
										class="com-checkbox"
										value="경조사비"
									/>
									<label class="font cursor-pointer" for="tributeFee">경조사비</label>
								</div>
								<div>
									<input
										id="otherInsurance"
										v-model="companyInsurance"
										type="checkbox"
										class="com-checkbox"
										value="기타"
									/>
									<label class="font cursor-pointer" for="otherInsurance"
									>기타
										<input
											v-model="insurance"
											class="com-inputbox-short font f-light pd-10 ml-20"
											type="text"
											placeholder="여기를 눌러 입력하세요."
										/>
									</label>
								</div>
							</div>

							<!-- 식사간식 -->
							<div>
								<span class="font-title f-semi-bold">식사/간식</span>
							</div>
							<div class="pl-10 mt-10 mb-50">
								<div>
									<input
										id="breakfast"
										v-model="companyMeal"
										type="checkbox"
										class="com-checkbox"
										value="조식 제공"
									/><label class="font cursor-pointer" for="breakfast">조식 제공</label>
								</div>
								<div>
									<input
										id="lunch"
										v-model="companyMeal"
										type="checkbox"
										class="com-checkbox"
										value="중식 제공"
									/><label class="font cursor-pointer" for="lunch">중식 제공</label>
								</div>
								<div>
									<input
										id="dinner"
										v-model="companyMeal"
										type="checkbox"
										class="com-checkbox"
										value="석식 제공"
									/><label class="font cursor-pointer" for="dinner">석식 제공</label>
								</div>
								<div>
									<input
										id="overTime"
										v-model="companyMeal"
										type="checkbox"
										class="com-checkbox"
										value="야근 식대"
									/><label class="font cursor-pointer" for="overTime">야근 식대</label>
								</div>
								<div>
									<input
										id="snack"
										v-model="companyMeal"
										type="checkbox"
										class="com-checkbox"
										value="간식 제공"
									/><label class="font cursor-pointer" for="snack">간식 제공</label>
								</div>
								<div>
									<input
										id="otherFood"
										v-model="companyMeal"
										type="checkbox"
										class="com-checkbox"
										value="기타"
									/>
									<label class="font cursor-pointer" for="otherFood">
										기타
										<input
											v-model="meal"
											class="com-inputbox-short font f-light pd-10 ml-20"
											type="text"
											placeholder="여기를 눌러 입력하세요."
										/>
									</label>
								</div>
							</div>

							<!-- 기타 (장비/교육) -->
							<div>
								<span class="font-title f-semi-bold">기타 (장비/교육)</span>
								<div class="text-caption f-light font-caption">
									기타 우리 회사에서 운영하고 있는 복지 정책을 알려주세요.
								</div>
							</div>
							<div class="mt-10 mb-50">
								<input
									v-model="companyWelfare"
									class="com-inputbox font f-light pd-10"
									type="text"
									placeholder="예) 맥북 제공, 도서비 지원, 기숙사, 학자금지원, 여성휴게실"
								/>
							</div>

							<!-- 로고 이미지 -->
							<div>
								<span class="font-title f-semi-bold">CI/BI 로고이미지 등록</span>
								<!--                            <span class="f-red">*</span>-->
								<div class="text-caption f-light font-caption">
									최적 사이즈: 180 x 180 px | 사용 가능 이미지 형식: jpg, png, svg, gif
								</div>
							</div>
							<div class="mt-10 mb-50">
								<label class="cursor-pointer btn com-img-btn mb-20 font">
									+ 파일첨부
									<input
										ref="image"
										class="display-none"
										type="file"
										accept="image/jpeg,image/png,image/svg,image/gif"
										maxlength="1"
										@change="imageUpload"
										@click="imageCheck"
									/>
								</label>
								<br />
								<div v-if="check(logoSrc)" class="inline-block">
									<img ref="logo" class="main-img" :src="logoSrc" />
									<img class="sub-img cursor-pointer" src="/images/delete.svg" @click="imageDel" />
								</div>
							</div>

							<!-- 대표 이미지 -->
							<div>
								<span class="font-title f-semi-bold">대표 이미지 등록</span>
								<!--                                <span class="f-red">*</span>-->
								<div class="text-caption f-light font-caption">
									최적 사이즈: 900 x 720 px | 사용 가능 이미지 형식: jpg, png, svg, gif
								</div>
							</div>
							<div class="mt-10 mb-50">
								<label class="cursor-pointer btn com-img-btn mb-20 font">
									+ 파일첨부
									<input
										ref="images"
										class="display-none"
										type="file"
										accept="image/jpeg,image/png,image/svg,image/gif"
										maxlength="1"
										@change="imagesUpload"
										@click="imagesCheck"
									/>
								</label>
								<br />
								<div v-if="check(mainSrc)" class="inline-block">
									<img ref="main" class="main-img" :src="mainSrc" />
									<img class="sub-img cursor-pointer" src="/images/delete.svg" @click="imagesDel()" />
								</div>
							</div>
						</form>
					</div>

					<div class="mt-20">
						<div class="float-right text-left">
							<!--                            <button class="cursor-pointer btn btn-close font mr-10" @click="tempSave">임시저장</button>-->
							<button class="cursor-pointer btn font" @click="next">완료</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	props: ['comInfo', 'pmCompanyUserType'],
	data() {
		return {
			companyName: '',
			companyDetail: '',
			companyUrl: '',
			companyCity: '',
			city: '',
			employeeNum: '',
			employee: ['10명 미만', '10~30명', '30~50명', '50~100명', '100~500명', '500~1000명', '1000명 이상'],
			companyType: '',
			company: ['대기업', '중견기업', '중소기업', '스타트업', '소셜벤처', '비영리 단체', '사단법인', '기타'],
			companyTime: [],
			time: '',
			companyInsurance: [],
			insurance: '',
			companyMeal: [],
			meal: '',
			companyWelfare: '',
			logoSrc: '',
			selectLogo: '',
			mainSrc: '',
			mainLogo: '',
			// mainSrc: [], // 이미지
			// selectMain: [], // 새로 추가한 이미지
			// originMain: [], // 기존 이미지
			// imgCnt: 0,
			isWarn: false,
			progressBar: '',
		};
	},
	watch: {
		warn() {
			this.isWarn = true;
		},
		// city(type) {
		//     if (type !== '') {
		//         this.companyCity = '기타';
		//     }
		// }
	},

	mounted() {
		/* IE 일때 */
		/*
            let agent = navigator.userAgent.toLowerCase();
            if ((navigator.appName == 'Netscape' && agent.indexOf('trident') != -1) || (agent.indexOf("msie") != -1)) {
                this.progressBar = 'ie-bar'
            } else {
                this.progressBar = 'point-bar';
            }
            */

		if (this.comInfo) {
			this.companyName = this.comInfo.name;
			this.companyDetail = this.comInfo.detail;
			this.companyUrl = this.comInfo.url;
			if (this.comInfo.addr === '서울') {
				this.companyCity = '서울';
			} else if (this.comInfo.addr !== '서울' && this.comInfo.addr !== '') {
				this.companyCity = '기타';
			} else {
				this.companyCity = '';
			}
			this.city = this.comInfo.addr !== '서울' && this.comInfo.addr !== '' ? this.comInfo.addr : '';

			this.employeeNum = this.comInfo.employee_num ? this.comInfo.employee_num : '';
			this.companyType = this.comInfo.com_type ? this.comInfo.com_type : '';

			if (this.comInfo.img_logo) {
				this.logoSrc = this.comInfo.img_base_path + this.comInfo.img_logo;
			}
			// for (let i = 0; i < this.comInfo.img_back_size; i++) {
			//     this.originMain.push(this.comInfo.img_back[i]);
			//     this.mainSrc.push(this.comInfo.img_base_path + this.comInfo.img_back[i]);
			// }
			if (this.comInfo.img_back) {
				this.mainSrc = this.comInfo.img_base_path + this.comInfo.img_back;
			}
		}

		/* 직원수 value를 데이터베이스에 넣을값(index)으로 바꾸기 */
		for (let i = 0; i < this.employee.length; i++) {
			if (this.comInfo.employee_num === this.employee[i]) {
				this.employeeNum = i;
			}
		}
		/* 기업 구분 value를 데이터베이스에 넣을값(index)로 바꾸기 */
		for (let i = 0; i < this.company.length; i++) {
			if (this.comInfo.com_type === this.company[i]) {
				this.companyType = i;
			}
		}

		if (this.comInfo.officehour) {
			this.companyTime = this.comInfo.officehour;

			let timeFlag = 0;

			for (let i = 0; i < this.companyTime.length; i++) {
				if (this.companyTime[i] === '기타') {
					this.time = this.companyTime[this.companyTime.length - 1];
					timeFlag = 1;
					if (this.time !== '') {
						this.companyTime.splice(this.companyTime.length - 1, 1);
					}
				}
				if (this.companyTime[i] === '') {
					this.companyTime.splice(i, 1);
				}
			}
			if (timeFlag === 0) {
				this.time = '';
			}
		}

		if (this.comInfo.ins) {
			let insFlag = 0;

			for (let i = 0; i < this.companyInsurance.length; i++) {
				if (this.companyInsurance[i] === '기타') {
					this.insurance = this.companyInsurance[this.companyInsurance.length - 1];
					insFlag = 1;
					if (this.insurance !== '') {
						this.companyInsurance.splice(this.companyInsurance.length - 1, 1);
					}
				}
				if (this.companyInsurance[i] === '') {
					this.companyInsurance.splice(i, 1);
				}
			}
			if (insFlag === 0) {
				this.insurance = '';
			}
		}

		if (this.comInfo.meal) {
			let mealFlag = 0;

			for (let i = 0; i < this.companyMeal.length; i++) {
				if (this.companyMeal[i] === '기타') {
					this.meal = this.companyMeal[this.companyMeal.length - 1];
					mealFlag = 1;
					if (this.meal !== '') {
						this.companyMeal.splice(this.companyMeal.length - 1, 1);
					}
				}
				if (this.companyMeal[i] === '') {
					this.companyMeal.splice(i, 1);
				}
			}
			if (mealFlag === 0) {
				this.meal = '';
			}
		}

		if (this.comInfo.welfare) {
			this.companyWelfare = this.comInfo.welfare.join();
		}

		this.imgCnt = this.mainSrc.length;
	},
	methods: {
		placeholderTxt(idx) {
			if (idx === 1) {
				return (
					'예)' +
					'\n' +
					"'시작하는 사람들의 커리어 커뮤니티'" +
					'\n' +
					'코멘토가 판매하는 것은 ‘취업’이 아닙니다. 코멘토가 하는 일은 원하는 일자리를 얻는 방법을 알려주고, 일자리에 인재를 소개해주는 것이 아닙니다.' +
					'\n\n' +
					'우리가 하는 일은 ‘꿈을 연결하는 일입니다.' +
					'\n' +
					'한 개인은 직업을 통해서 꿈을 꾸고, 꿈을 완성합니다. 기업은 인재를 통해서 꿈을 꾸고 인재를 통해서 꿈을 완성합니다. 코멘토가 하는 일은 개인과 기업이 꿈을 꿀 수 있고, 꿈을 완성할 수 있도록 개인과 개인, 개인과 기업을 연결하는 일입니다.' +
					'\n\n' +
					' 우리는 우리의 사명을 달성하기 위해 멘토링 플랫폼 운영 및 Robo-recruiting 기술을 개발합니다.' +
					'\n\n' +
					'(1) 멘토링 플랫폼 운영: 현직자 취업 멘토링 플랫폼 운영을 통해 구직자의 사소한 고민부터 깊이있는 진로 고민, 자기소개서 멘토링까지 취업과정의 고민을 현직자가 함께 풀어갑니다.' +
					'\n\n' +
					'(2) Robo-recruiting 기술 개발 및 인재매칭 서비스: 멘토링 과정에서 수집되는 구직자의 빅데이터를 통해 이력, 관심사에 대한 정보를 분석하고 역량을 예측하여 적합한 인재와 기업을 인공지능이 매칭하는 Robo-recruiting 기술을 개발하고 있습니다.'
				);
			} else if (idx === 2) {
				return (
					'예)' +
					'\n' +
					'코멘토는 2015년 8월에 서비스를 시작한 이래, 국내 최대의 온라인 취업멘토링 서비스로 성장하고 있습니다. 누적 15만 건 이상의 멘토링이 제공되었으며, 연세대, 서강대, 성균관대, 이화여대, 숙명여대 등 전국 10개 이상의 대학/ 기관에서 코멘토를 이용하고 있습니다.' +
					'\n\n' +
					'코멘토는 멘토링 데이터를 활용해 Robo-recruiting 기술을 개발하고 있습니다. Airbnb, Wework 등 글로벌 스타트업과 에이프릴스킨, 샌드박스네트워크, 미팩토리, 다노, 8 Percent, 렌딧 등 국내 유수의 스타트업들이 코멘토의 Robo-recruiting을 통해 우수한 인재를 채용하고 있습니다.' +
					'\n\n' +
					'코멘토는 지금까지 Seed Round 투자 유치 이후 멘토링과 채용서비스 등 자체적인 수익모델을 통해 월 손익분기점을 돌파하는 등 안정적인 성장기반을 갖추어감과 동시에 취업 및 채용시장을 소셜네트워크와 기술로 혁신해 나가고 있습니다.'
				);
			} else if (idx === 3) {
				return (
					'예)' +
					'\n' +
					'코멘토 팀은 세 가지 핵심 가치를 실천하기 위해 노력하고 있습니다. 핵심가치는 코멘토가 중요하게 생각하는 본질적인 신조이자 코멘토 구성원의 의사결정 기준이며, 항상 실천하기 위해 노력합니다.' +
					'\n\n' +
					'- DREAM: 코멘토에서 가장 중요한 것은 코멘토 구성원 개개인의 꿈입니다. 조직의 꿈도 개인의 꿈보다 우선할 수 없으며, 개인의 꿈과 조직의 꿈은 항상 양립합니다. 따라서 코멘토의 구성원은 서로가 서로의 꿈을 이해하고 조직과 구성은 모두는 나의 꿈과 다른 사람의 꿈을 성취하는 것을 돕습니다.' +
					'\n\n' +
					'- LEAN: 코멘토의 구성원이 일하는 방식을 규정합니다. LEAN하다는 것은 업무 수행 및 협업의 과정에서 불필요한 비효율이 최소화된 상태를 뜻합니다. 우리는 문제의 본질에 도달하기 위해 모든 것을 실험하고 그 결과에 따라 행동함으로써 전체의 효율성을 달성하고 비효율적인 요소는 제거합니다.' +
					'\n\n' +
					'- ENTERTAIN: 코멘토에서 동료들과 함께 일하는 것뿐 아니라 조직의 모든 생활이 즐거움이 될 수 있도록 조직의 문화를 만들고 발전시켜 나갑니다.'
				);
			}
		},
		check(src) {
			let baseURL = this.comInfo.img_base_path;
			return src.replace(baseURL, '') !== '';
		},
		imageCheck() {
			if (this.logoSrc !== '') {
				alert('로고는 한개만 등록할 수 있습니다');
				return false;
			}
		},
		imagesCheck() {
			// this.imgCnt = this.mainSrc.length;
			// if (this.mainSrc.length >= 3) {
			//     alert('대표 이미지는 최대 3장까지 등록 가능합니다');
			//     return false;
			// }

			if (this.mainSrc !== '') {
				alert('대표 이미지는 한개만 등록할 수 있습니다');
				return false;
			}
		},
		imageUpload(event) {
			if (this.logoSrc === '') {
				let reader = new FileReader();
				reader.onload = e => {
					this.logoSrc = e.target.result;
				};
				reader.readAsDataURL(event.target.files[0]);
				this.selectLogo = event.target.files[0];
			}
		},
		imagesUpload(event) {
			// this.imgCnt = this.mainSrc.length;
			// for (let i = 0; i < event.target.files.length; i++) {
			//     if (this.imgCnt === 3) break;
			//     let reader = new FileReader();
			//     reader.readAsDataURL(event.target.files[i]);
			//     reader.onload = (e) => {
			//         this.mainSrc.push(e.target.result);
			//     }
			//     this.selectMain.push(event.target.files[i]);
			//     this.imgCnt++;
			// }

			if (this.mainSrc === '') {
				let reader = new FileReader();
				reader.onload = e => {
					this.mainSrc = e.target.result;
				};
				reader.readAsDataURL(event.target.files[0]);
				this.selectMain = event.target.files[0];
			}
		},
		imageDel() {
			this.$refs.image.value = '';
			this.logoSrc = '';
			this.selectLogo = '';
		},
		imagesDel(index) {
			// this.$refs.images.value = '';
			// this.mainSrc.splice(index, 1);
			// this.selectMain.splice(index, 1);
			// this.originMain.splice(index, 1);
			// this.imgCnt = this.mainSrc.length;

			this.$refs.images.value = '';
			this.mainSrc = '';
			this.selectMain = '';
		},
		api(progress) {
			showLoading();

			let addr = '';
			if (this.companyCity === '기타') {
				addr = this.city;
			} else {
				addr = this.companyCity;
			}

			const formData = new FormData();

			formData.append('name', this.companyName);
			formData.append('detail', this.companyDetail);
			formData.append('url', this.companyUrl);
			formData.append('addr', addr);
			formData.append('employee_num', this.employeeNum);
			formData.append('com_type', this.companyType);
			formData.append('time', this.companyTime);
			formData.append('time_etc', this.time);
			formData.append('ins', this.companyInsurance);
			formData.append('ins_etc', this.insurance);
			formData.append('meal', this.companyMeal);
			formData.append('meal_etc', this.meal);
			formData.append('welfare', this.companyWelfare);

			axios({
				method: 'POST',
				url: '/api/with/company/info',
				data: {
					name: this.companyName,
					detail: this.companyDetail,
					url: this.companyUrl,
					addr: addr,
					employee_num: this.employeeNum,
					com_type: this.companyType,
					time: this.companyTime,
					time_etc: this.time,
					ins: this.companyInsurance,
					ins_etc: this.insurance,
					meal: this.companyMeal,
					meal_etc: this.meal,
					welfare: this.companyWelfare,
				},
			})
				.then(response => {
					if (response.data.code === 200) {
						this.imageApi(progress);
					} else {
						// console.log(response.data);
						closeLoading();

						alert('다시 확인해주세요!');
						return false;
					}
				})
				.catch(error => {
					closeLoading();
				});
		},
		imageApi(progress) {
			const formData = new FormData();
			// 로고
			if (this.selectLogo) {
				formData.append('logo', this.selectLogo);
			}
			if (this.selectMain) {
				formData.append('main', this.selectMain);
			}

			if (this.logoSrc === '') {
				formData.append('l_logo', 'empty');
			} else {
				formData.append('l_logo', this.logoSrc);
			}

			if (this.mainSrc === '') {
				formData.append('l_main', 'empty');
			} else {
				formData.append('l_main', this.mainSrc);
			}

			axios({
				method: 'POST',
				url: '/api/with/company/info/image',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then(response => {
					closeLoading();

					if (response.data.code === 200) {
						if (progress === 'go') {
							alert('작성이 완료되었습니다!');
							location.href = '/with/company/recruit';
							// console.log(response)
						}
					} /*else {
                        closeLoading();
                        console.log(response)
                        alert('다시 확인해주세요!');
                        return false;
                    }*/
				})
				.catch(error => {
					closeLoading();
				});
		},
		tempSave() {
			this.api('stay');
		},
		validator(value, msg) {
			if ((value === '' || typeof value === 'undefined') && !this.isWarn) {
				this.isWarn = true;
				alert(msg + ' 확인해주세요!');
				return false;
			}
		},
		next() {
			// this.isWarn = false;
			// this.validator(this.companyName, '기업명을');
			// this.validator(this.simpleIntro, '한 줄 소개를');
			// this.validator(this.detailIntro, '상세 소개를');
			// this.validator(this.hookingPoint, 'hooking point를');
			// this.validator(this.growthInfo, '성장 정보를');
			// if (this.required.corporateCulture) this.validator(this.corporateCulture, '조직 문화를');
			// this.validator(this.companyCity, '기업 소재지를');
			// this.validator(this.employeeNum, '직원 수를');
			// this.validator(this.companyType, '기업 구분을');
			//
			// if (!this.isWarn) {
			//     this.api('go');
			// }

			this.api('go');
		},
	},
};
</script>

<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
