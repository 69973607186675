<template>
	<modal-component :show="show" mask="true" type="simple" @close="close">
		<div class="modal-body">
			<template v-if="!isFinish">
				<h3>이메일 변경</h3>
				<p class="date mb-15">주니어네이버 이메일은 사용이 불가합니다.</p>
				<input
					v-model="newEmail"
					type="text"
					class="inputbox d-block mb-20"
					placeholder="이메일 주소"
					style="width: 100%"
				/>
				<div v-if="errorMessage !== ''" class="h7 text-error mb-15">{{ errorMessage }}</div>
				<div class="btn float-right" @click="saveEmailChange()">변경하기</div>
			</template>
			<template v-else>
				<h3 class="mb-10">이메일 변경 완료</h3>
				<p class="mb-15">이메일이 변경되었습니다.</p>
				<div class="btn float-right" @click="closePopup()">확인</div>
			</template>
		</div>
	</modal-component>
</template>

<script>
export default {
	name: 'JrNaverEmailChangeModal',
	props: ['show', 'email'],
	data: () => ({
		newEmail: '',
		errorMessage: '',
		isFinish: false,
	}),
	watch: {
		show() {
			this.newEmail = this.email;
		},
	},
	methods: {
		close() {
			this.$emit('close');
		},
		saveEmailChange() {
			function isJrEmail(email) {
				return email !== '' && email.split('@')[1] === 'jr.naver.com';
			}

			if (isJrEmail(this.newEmail)) {
				this.errorMessage = '주니어네이버 이메일은 사용할 수 없습니다.';
			} else {
				axios({
					method: 'POST',
					url: '/api/job-questions/setEmailAtFeedInfo',
					data: {
						email: this.newEmail,
					},
				})
					.then(({ data }) => {
						if (data.code === 201) {
							this.errorMessage = '이미 존재하는 이메일입니다.';
						} else {
							this.errorMessage = '';
							this.isFinish = true;
						}
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		closePopup() {
			this.$emit('close');
			window.location.reload();
		},
	},
};
</script>

<style scoped></style>
