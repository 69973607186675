import axios from 'axios';

async function getJwtTokenFromCookie() {
	return await axios.get('/user/jwt/cookie');
}

async function issueJwtToken() {
	return await axios.get('/api/auth/jwt/refresh');
}

export { getJwtTokenFromCookie, issueJwtToken };
