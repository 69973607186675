<template>
	<section id="section" class="section section-top section-detail" :style="isUser" style="padding-bottom: 0">
		<div class="resume-builder">
			<div class="resume-landing-section">
				<div class="resume-landing-item">
					<div class="display1 mb-10">직무에 맞는 이력 정리</div>
					<div class="mb-30">
						1:1 전문가 가이드에 준하는 <br class="only-mobile" /><b>무료</b> 이력서 튜토리얼입니다.<br />
						올바른 취업준비를 위해 <br class="only-mobile" />가장 먼저 튜토리얼 진행을 추천 드립니다.
					</div>
					<router-link to="/resume/tutorial/1">
						<button class="btn btn-lg">튜토리얼 시작하기</button>
					</router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	props: ['userNo', 'userType', 'tutorialPage'],
	computed: {
		isUser() {
			//로그인 안했을 때 서브 메뉴 관련 style
			if (this.userType !== undefined && this.userType >= 1) {
				if (this.isMobile) {
					return 'padding-top: 42px !important;';
				} else {
					return '';
				}
			} else {
				return 'padding-bottom: 0';
			}
		},
	},
	created() {
		if (parseInt(this.tutorialPage) > 0) {
			this.$router
				.push({
					path: `/resume/tutorial/${this.tutorialPage}`,
				})
				.catch(() => {});
		} else {
			// GTM - beginTutorial
			beginTutorial(this.userNo, this.userType);
			// GTM - GAVirtualPageview
			GAVirtualPageview(this.$route.path);
		}
	},
	mounted() {
		this.setMeta({
			isDetail: true,
			title: '이력서 튜토리얼',
			fluid: true,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
	},
};
</script>
