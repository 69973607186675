<template>
	<div>
		<Lottie v-if="loadingSpinner" :options="lottieOptions" :height="50" :width="50" />
	</div>
</template>

<script>
import loadingSpinnerMixin from '../../mixins/loadingSpinnerMixin';

export default {
	name: 'LoadingSpinner',
	mixins: [loadingSpinnerMixin],
};
</script>
