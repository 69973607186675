<template>
	<!--contents-title-->
	<dl class="contents_title_component" :class="[computedAlign]">
		<!--dt 내용은 안보입니다.-->
		<dt>{{ titleDefinition }}</dt>
		<dd v-html="$options.filters.nl2br(contentsTitle)"></dd>
	</dl>
</template>

<script>
export default {
	name: 'ContentsTitle',
	props: {
		titleDefinition: {
			type: String,
			default: 'definition(설명,내용)',
		},
		//contents title 내용
		contentsTitle: {
			type: String,
			default: null,
		},
		//좌, 우 방향 설정
		titleAlign: {
			type: String,
			default: 'none',
		},
	},
	computed: {
		computedAlign() {
			return `contents_title_align_${this.titleAlign}`;
		},
	},
};
</script>
<style scoped lang="less">
@import '../../../../../less/base/index';
.contents_title_component {
	position: relative;
	margin-bottom: 8px;
	&.contents_title_align_left {
		float: left;
	}
	&.contents_title_align_right {
		float: right;
	}
	&.contents_title_align_none {
		float: none;
	}
	dt {
		display: none;
	}
	dd {
		.text-truncate();
		::v-deep em,
		::v-deep strong {
			color: @primary !important;
		}
	}
}
</style>
