import Vue from 'vue';

import Chart1 from './analyticsChart1/chart1';
import Chart2 from './analyticsChart1/chart2';
import Chart3 from './analyticsChart1/chart3';
import Chart4 from './analyticsChart1/chart4';
import Chart5 from './analyticsChart1/chart5';

import Line1 from './analyticsChart2/line1';
import Line2 from './analyticsChart2/line2';

import radar1 from './analyticsChart2/radar1';
import radar2 from './analyticsChart2/radar2';

Vue.component('chart1', Chart1);
Vue.component('chart2', Chart2);
Vue.component('chart3', Chart3);
Vue.component('chart4', Chart4);
Vue.component('chart5', Chart5);

Vue.component('line1', Line1);
Vue.component('line2', Line2);
Vue.component('radar1', radar1);
Vue.component('radar2', radar2);
