<template>
	<header class="header-bar border-bottom">
		<div v-if="isUser !== 1" class="wrapper mt-10">
			<div>
				<img class="logo cursor-pointer" :src="bizLogoSrc" onclick="location.href='/'" />
			</div>
			<div class="inline-block float-right header-text">
				<span class="text-caption cursor-pointer header-hover font" onclick="location.href='/register'">
					가입하기
				</span>
				<span class="text-caption cursor-pointer header-hover font" onclick="location.href='/logout'">
					로그인
				</span>
			</div>
		</div>
		<div v-else class="wrapper mt-10">
			<div class="pc-header">
				<img class="logo cursor-pointer" :src="bizLogoSrc" @click="home" />
			</div>
			<div class="mobile-header">
				<img
					class="logo cursor-pointer menu-hamburger-green"
					src="/images/menu_hamburger.svg?date=200731"
					@click="hamburger()"
				/>
			</div>
			<div v-if="guest === 0" class="inline-block float-right header-text">
				<div v-if="$store.state.keyword === ''">
					<span
						class="text-caption cursor-pointer header-hover font"
						onclick="location.href='https://comento.kr/jobs'"
					>
						채용공고
					</span>
					<span class="text-caption cursor-pointer header-hover font" onclick="location.href='/logout'">
						로그아웃
					</span>
				</div>
				<div v-else-if="$store.state.keyword === 'applicant'">
					<span class="text-caption cursor-pointer header-hover font" @click="applicant">지원자목록</span>
				</div>
				<div v-else-if="$store.state.keyword === 'chat'">
					<span class="text-caption cursor-pointer header-hover font">
						<img src="/images/gray_remove.svg" class="remove-btn" @click="historyBack()" />
					</span>
				</div>
			</div>

			<div v-else class="inline-block float-right header-text">
				<span class="text-caption cursor-pointer header-hover font" onclick="location.href='/register'">
					가입하기
				</span>
				<span class="text-caption cursor-pointer header-hover font" onclick="location.href='/logout'">
					로그인
				</span>
			</div>
		</div>
	</header>
</template>
<script>
export default {
	props: ['isUser', 'guest', 'pmCompanyUserType', 'comNo'],
	data() {
		return {
			// flag : -1
			bizLogoSrc: '/images/logo/logo-comento-typo-type.png',
		};
	},
	created() {
		if (this.pmCompanyUserType === 8) {
			this.bizLogoSrc = '/images/comento_logo_yhf.svg';
		}
	},
	methods: {
		applicant() {
			let url = '/applicant';
			if (parseInt(this.comNo) !== 0) {
				url += '/' + this.comNo;
			}
			location.href = url;
		},
		show() {
			$('nav.nav-bar').animate({ left: 0, opacity: 'show' }, 300);
			$('.blinder').animate({ opacity: 'show' }, 500).css({ 'z-index': '8' });
		},
		close() {
			$('nav.nav-bar').animate({ left: -250 }, 300);
			$('.blinder').animate({ opacity: 'hide' }, 500);
		},
		hamburger() {
			this.$root.bizMenuFlag = this.$root.bizMenuFlag * -1;

			if (this.$root.bizMenuFlag > 0) {
				this.show();
			} else {
				this.close();
			}
		},
		historyBack() {
			history.back();
		},
		home() {
			if (this.guest === 0) {
				location.href = '/dashboard';
			} else {
				return false;
			}
		},
	},
};
</script>

<style>
.remove-btn {
	width: 15px;
	vertical-align: middle;
}
.menu-hamburger-green {
	filter: invert(54%) sepia(83%) saturate(3091%) hue-rotate(111deg) brightness(101%) contrast(101%);
}
</style>
