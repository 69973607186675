//BIZ
import Vue from 'vue';

import BizHeaderComponent from '../biz/js/components/_layout/HeaderComponent.vue';
import BizFooterComponent from '../biz/js/components/_layout/FooterComponent.vue';
import BizSideComponent from '../biz/js/components/_layout/SideComponent.vue';
import BizIndexComponent from '../biz/js/components/index/IndexComponent.vue';
import BizLoginHeaderComponent from '../biz/js/components/user/LoginHeaderComponent.vue';
import BizLoginComponent from '../biz/js/components/user/LoginComponent.vue';
import BizRegisterComponent from '../biz/js/components/user/RegisterComponent.vue';
import BizFindComponent from '../biz/js/components/user/FindComponent.vue';
import ChangePWComponent from '../biz/js/components/user/ChangePWComponent.vue';

Vue.component('biz-header-component', BizHeaderComponent);
Vue.component('biz-footer-component', BizFooterComponent);
Vue.component('biz-side-component', BizSideComponent);

Vue.component('biz-index-component', BizIndexComponent);

Vue.component('biz-login-header-component', BizLoginHeaderComponent);
Vue.component('biz-login-component', BizLoginComponent);
Vue.component('biz-register-component', BizRegisterComponent);
Vue.component('biz-find-component', BizFindComponent);
Vue.component('biz-change-pw-component', ChangePWComponent);

import BizDashboardComponent from '../biz/js/components/dashboard/DashboardComponent.vue';
import BizCompanyHeaderComponent from '../biz/js/components/company/CompanyHeaderComponent.vue';
import BizCompanyComponent from '../biz/js/components/company/CompanyComponent.vue';
import BizCompanyInfoOneComponent from '../biz/js/components/company/CompanyInfoOneComponent.vue';
import BizCompanyInfoTwoComponent from '../biz/js/components/company/CompanyInfoTwoComponent.vue';
import BizCompanyInfoFinalComponent from '../biz/js/components/company/CompanyInfoFinalComponent.vue';
import BizCompanyRecruitOneComponent from '../biz/js/components/company/CompanyRecruitOneComponent.vue';
import BizCompanyRecruitTwoComponent from '../biz/js/components/company/CompanyRecruitTwoComponent.vue';
import BizCompanyRecruitFinalComponent from '../biz/js/components/company/CompanyRecruitFinalComponent.vue';
import BizCompanyFinishComponent from '../biz/js/components/company/CompanyFinishComponent.vue';
import BizCompanyRecruitComponent from '../biz/js/components/company/CompanyRecruitComponent.vue';

Vue.component('biz-dashboard-component', BizDashboardComponent);
Vue.component('biz-company-header-component', BizCompanyHeaderComponent);
Vue.component('biz-company-component', BizCompanyComponent);
Vue.component('biz-company-info-one-component', BizCompanyInfoOneComponent);
Vue.component('biz-company-info-two-component', BizCompanyInfoTwoComponent);
Vue.component('biz-company-info-final-component', BizCompanyInfoFinalComponent);
Vue.component('biz-company-recruit-one-component', BizCompanyRecruitOneComponent);
Vue.component('biz-company-recruit-two-component', BizCompanyRecruitTwoComponent);
Vue.component('biz-company-recruit-final-component', BizCompanyRecruitFinalComponent);
Vue.component('biz-company-finish-component', BizCompanyFinishComponent);
Vue.component('biz-company-recruit-component', BizCompanyRecruitComponent);

// 채용 MVP (사수와 함께)
import BizWithCompanyInfoComponent from '../biz/js/components/company/WithCompanyInfoComponent.vue';
Vue.component('biz-with-company-info-component', BizWithCompanyInfoComponent);

import BizWithCompanyRecruitComponent from '../biz/js/components/company/WithCompanyRecruitComponent.vue';
Vue.component('biz-with-company-recruit-component', BizWithCompanyRecruitComponent);

import BizFaqComponent from '../biz/js/components/faq/FaqComponent.vue';
Vue.component('biz-faq-component', BizFaqComponent);

import BizHelpComponent from '../biz/js/components/help/HelpComponent.vue';
Vue.component('biz-help-component', BizHelpComponent);

import BizPayComponent from '../biz/js/components/pay/PayComponent.vue';
Vue.component('biz-pay-component', BizPayComponent);

//[biz] 지원자 관리
import BizApplicantManagementComponent from '../biz/js/components/applicant/ApplicantManagementComponent.vue';
Vue.component('biz-applicant-management-component', BizApplicantManagementComponent);

import BizApplicantDetailComponent from '../biz/js/components/applicant/ApplicantDetailComponent.vue';
Vue.component('biz-applicant-detail-component', BizApplicantDetailComponent);

import BizApplicantMobileChatComponent from '../biz/js/components/applicant/ApplicantMobileChatComponent.vue';
Vue.component('biz-applicant-mobile-chat-component', BizApplicantMobileChatComponent);

import BizApplicantShareCheckComponent from '../biz/js/components/applicant/ApplicantShareCheckComponent.vue';
Vue.component('biz-applicant-share-check-component', BizApplicantShareCheckComponent);

//[biz] 지원자 관리 - 면접일정
import BizInterviewFormComponent from '../biz/js/components/applicant/interview/InterviewFormComponent.vue';
import BizInterviewScheduleComponent from '../biz/js/components/applicant/interview/InterviewScheduleComponent.vue';
import BizInterviewManualComponent from '../biz/js/components/applicant/interview/InterviewManualComponent.vue';

Vue.component('biz-interview-form-component', BizInterviewFormComponent);
Vue.component('biz-interview-schedule-component', BizInterviewScheduleComponent);
Vue.component('biz-interview-manual-component', BizInterviewManualComponent);

//[biz] 지원자 관리 - 입사일정
import BizWorkScheduleComponent from '../biz/js/components/applicant/work/WorkScheduleComponent.vue';
Vue.component('biz-work-schedule-component', BizWorkScheduleComponent);

//[biz] 모달
import BizModalComponent from '../biz/js/components/_modal/ModalComponent.vue';
import BizAlertComponent from '../biz/js/components/_modal/AlertComponent.vue';
import BizConfirmComponent from '../biz/js/components/_modal/ConfirmComponent.vue';
import BizInterviewHelpComponent from '../biz/js/components/_modal/interview/InterviewHelpComponent.vue';
import BizInterviewPreviewComponent from '../biz/js/components/_modal/interview/InterviewPreviewComponent.vue';
import BizPincodeComponent from '../biz/js/components/_modal/PincodeComponent.vue';
import BizTutorialComponent from '../biz/js/components/_modal/tutorial/TutorialComponent.vue';

Vue.component('biz-modal-component', BizModalComponent);
Vue.component('biz-alert-component', BizAlertComponent);
Vue.component('biz-confirm-component', BizConfirmComponent);
Vue.component('biz-interview-help-component', BizInterviewHelpComponent);
Vue.component('biz-interview-preview-component', BizInterviewPreviewComponent);
Vue.component('biz-pincode-component', BizPincodeComponent);
Vue.component('biz-tutorial-component', BizTutorialComponent);

//[biz] yhf 관리자 시스템
import YhfHeaderComponent from '../biz/js/components/yhf/HeaderComponent.vue';
import YhfLoginComponent from '../biz/js/components/yhf/LoginComponent.vue';
import YhfAdminComponent from '../biz/js/components/yhf/AdminComponent.vue';
import YhfCompanyComponent from '../biz/js/components/yhf/CompanyComponent.vue';
import YhfApplicantComponent from '../biz/js/components/yhf/ApplicantComponent.vue';
import YhfAccountComponent from '../biz/js/components/yhf/AccountComponent.vue';

Vue.component('yhf-header-component', YhfHeaderComponent);
Vue.component('yhf-login-component', YhfLoginComponent);
Vue.component('yhf-admin-component', YhfAdminComponent);
Vue.component('yhf-company-component', YhfCompanyComponent);
Vue.component('yhf-applicant-component', YhfApplicantComponent);
Vue.component('yhf-account-component', YhfAccountComponent);
