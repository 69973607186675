<template>
	<div id="c-footer-layout">
		<ComentoFooter v-if="isShowFooter" />
		<template v-if="isMobile">
			<BottomNav v-if="isShowBottomNav" />
			<BackwardButton v-else-if="isDetail && isLogoTopBar" />
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ComentoFooter, BottomNav } from '@comento/common-components';

export default {
	name: 'FooterLayout',
	computed: {
		...mapGetters('layoutStore', ['isDetail', 'isLogoTopBar', 'showFooter', 'showBottomNav']),
		isShowBottomNav() {
			if (!this.showBottomNav) {
				return false;
			}

			return !this.isDetail && !this.isApp;
		},
		isShowFooter() {
			return !this.isApp && !this.isMobile && this.showFooter;
		},
	},
	components: {
		ComentoFooter,
		BottomNav,
	},
};
</script>

<style scoped></style>
