<template>
	<div class="multi-step-progress-container">
		<div class="multi-step-progress-steps">
			<!-- EssayTutorialStepGraphic도 0단계처럼 보이게 하기 위한 빈 태그 -->
			<div>
				<EssayTutorialStepGraphic class="multi-step-progress-step-0" :step="step" />
			</div>
			<div v-for="item in 7" :key="`navigator-item-${item}`">
				<div
					class="multi-step-progress-circle"
					:class="[
						{ 'multi-step-progress-circle-current': checkIsCurrentStep(item) },
						{ 'multi-step-progress-circle-completed': checkIsCompletedStep(item) },
					]"
				>
					<c-typography
						v-if="checkIsCurrentStep(item) || checkIsCompletedStep(item)"
						:type="checkIsCurrentStep(item) ? 'caption1' : 'caption2'"
						:color="checkIsCurrentStep(item) ? 'gray800' : 'gray500'"
						:font-weight="checkIsCurrentStep(item) ? 600 : 400"
						class="multi-step-progress-step-title"
						v-html="navigatorTitle(item)"
					></c-typography>
				</div>
			</div>

			<div class="multi-step-progress-bar">
				<span ref="multi-step-progress-bar-indicator" class="multi-step-progress-bar-indicator" />
			</div>
		</div>
	</div>
	<!--	<div class="resume-navigator">-->
	<!--		<div class="line" />-->
	<!--		<ul>-->
	<!--			<li v-for="item in 7" :key="`navigator-item-${item}`" :class="circleClass(item)">-->
	<!--				<div class="circle" />-->
	<!--				<p v-html="navigatorTitle(item)" />-->
	<!--			</li>-->
	<!--		</ul>-->
	<!--	</div>-->
</template>

<script>
import EssayTutorialStepGraphic from '@/components/resume/essayTutorial/EssayTutorialStepGraphic.vue';

export default {
	name: 'EssayTutorialNavigatorNew',
	props: {
		step: {
			type: Number,
			default: 1,
		},
		descriptionStep: {
			type: Boolean,
			default: false,
		},
		finalStep: {
			type: Number,
			default: 1,
		},
		isCompletion: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		sync_step: {
			get() {
				return this.step;
			},
			set(val) {
				this.$emit('update:step', val);
			},
		},
		sync_descriptionStep: {
			get() {
				return this.descriptionStep;
			},
			set(val) {
				this.$emit('update:descriptionStep', val);
			},
		},
	},
	watch: {
		step: {
			handler() {
				this.$nextTick(() => {
					this.updateProgressBarWidth();
				});
			},
			immediate: true,
		},
	},
	created() {
		this.sync_step = parseInt(this.$route.params.no);
	},
	methods: {
		circleClass(no) {
			if (no === parseInt(this.step)) {
				if (no === 7 && this.isCompletion) {
					return 'circle-check-big';
				}
				return 'circle-outline-big';
			} else if (no <= parseInt(this.finalStep)) {
				return 'circle-check';
			} else {
				return 'circle-disabled';
			}
		},
		checkIsCurrentStep(no) {
			return no === parseInt(this.step);
		},
		checkIsCompletedStep(no) {
			return no <= parseInt(this.step);
		},
		navigatorTitle(no) {
			const title = {
				1: '직무 입력',
				2: '직무 이해',
				3: '우수자 역량',
				4: '내 강점',
				5: '강점 정의',
				6: `에피소드${this.step !== 6 ? '<br />' : ' '}입력`,
				// 7: `에피소드${this.step !== 7 ? '<br />' : ' '}분류`,
				7: '자가진단',
			};

			return title[no];
		},
		// goStep(step) {
		//     if(step > this.finalStep) {
		//         return false;
		//     } else {
		//         this.$router.push({ params: { no: step } });
		//         this.sync_step = step;
		//         this.sync_descriptionStep = false; //내비게이터로 이동할 때는 설명페이지 생략
		//     }
		// }
		updateProgressBarWidth() {
			const indicator = this.$refs['multi-step-progress-bar-indicator'];
			indicator.style.width = `${(this.step / 7) * 100}%`;
		},
	},
	components: { EssayTutorialStepGraphic },
};
</script>

<style lang="scss" scoped>
@import '../../../../scss/base/main';
$current-step-circle-size: 20px;
$current-step-circle-title-top: 10px;
$circle-size: 12px;
$circle-title-top: 6px;
.multi-step-progress {
	&-container {
		width: 100%;
		//background-color: grey;
	}
	&-steps {
		@include flexbox();
		width: 100%;
		@include align-items(center);
		@include justify-content(space-between);
		position: relative;
	}
	&-step-0 {
		position: absolute;
		top: -30px;
		left: -30px;
		z-index: 1;
	}
	&-step-title {
		position: absolute;
		top: calc($circle-size / 2 + 8px);
		width: max-content;
	}
	&-circle {
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		width: $circle-size;
		height: $circle-size;
		border: 3px solid $gray200;
		border-radius: 50%;
		background-color: $white;
		position: relative;
		&-current {
			width: $current-step-circle-size;
			height: $current-step-circle-size;
			.multi-step-progress-step-title {
				top: calc($current-step-circle-size / 2 + 8px);
			}
		}
		&-completed {
			border-color: $primary;
		}
	}
	&-bar {
		position: absolute;
		width: 100%;
		height: 4px;
		background: $gray200;
		z-index: -1;
		.multi-step-progress-bar-indicator {
			width: 100%;
			position: absolute;
			//width: 33%;
			height: 4px;
			background: $primary;
		}
	}
}
</style>
