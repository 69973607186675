import { cdnBaseUrl, IS_DEV } from '../constants';
import { NUXT_LOCAL_BASE_URL, NEXT_LOCAL_BASE_URL, QA_BASE_URL } from '../constants/paths';

export function getCdnImageSrc(path) {
	return `${cdnBaseUrl}${path}`;
}

/**
 * 로컬 서버 또는 QA의 주소를 포함한 경로를 반환하는 함수
 * @param {string} path - 라우팅할 경로
 * @param {'nuxt' | 'next'} [framework='nuxt'] - 사용할 프레임워크 ('nuxt' 또는 'next')
 * @returns {string} 환경에 따른 완성된 URL 경로
 */
export function getRoutePath(path, framework = 'nuxt') {
	const selectedFramework = framework || 'nuxt';

	if (IS_DEV) {
		return selectedFramework === 'nuxt' ? `${NUXT_LOCAL_BASE_URL}${path}` : `${NEXT_LOCAL_BASE_URL}${path}`;
	}

	if (location.href.startsWith(QA_BASE_URL)) {
		return `${QA_BASE_URL}${path}`;
	}

	return path;
}

/**
 * 한글, 영어, 숫자를 제외한 문자를 치환하는 함수
 * @param keyword
 * @returns {*}
 */
export function encodeKeyword(keyword) {
	const regex = /[^\wㄱ-힣()0-9]/gi;
	return keyword.replace(new RegExp(regex), match => encodeURIComponent(match));
}

export function debounce(func, delay) {
	let timeout;
	return function () {
		const context = this;
		const args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(context, args), delay);
	};
}

// base <= target 판별
export function compareVersion(base, target) {
	base = base.split('.');
	target = target.split('.');

	const length = Math.max(base.length, target.length);

	for (let i = 0; i < length; i++) {
		const b = base[i] ? parseInt(base[i], 10) : 0;
		const t = target[i] ? parseInt(target[i], 10) : 0;
		if (b !== t) {
			return b < t;
		}
	}
	// 똑같을 경우
	return true;
}

export function jsonToArray(json) {
	let result = [];
	const keys = Object.keys(json);
	keys.forEach(function (key) {
		result = [...result, json[key]];
	});
	return result;
}

export const validateBirthday = birthday => {
	// 입력이 숫자로만 구성되어 있는지 확인
	if (!/^\d{6}$/.test(birthday)) {
		return { valid: false, message: '입력은 6자리 숫자여야 합니다.' };
	}

	// 연, 월, 일 추출
	let yearPrefix = birthday.substring(0, 2);
	let month = parseInt(birthday.substring(2, 4), 10) - 1; // 자바스크립트는 월이 0부터 시작
	let day = parseInt(birthday.substring(4, 6), 10);

	// 2000년 이후 출생자와 1900년대 출생자 구분
	let year = parseInt(yearPrefix, 10) + (yearPrefix === '00' ? 2000 : 1900);

	// 날짜 객체 생성
	let date = new Date(year, month, day);

	// 검증: 추출한 년, 월, 일이 실제 생성된 Date 객체와 일치하는지 확인
	if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
		return { valid: false, message: '유효하지 않은 날짜입니다.' };
	}

	return { valid: true, message: '유효한 날짜입니다.' };
};
