<template>
	<section class="section section analytics loading text-center">
		<div class="resume-item-section m-0">
			<div class="resume-item text-center m-0">
				<div class="resume-main-title">
					<span class="highlight-underline" style="font-size: 20px; transform: translateY(50%)">
						당신의 소중한 경험을 이력서로 생성중입니다
					</span>
				</div>
			</div>
		</div>
		<div class="analytics-progress-box text-center">
			<vue-simple-progress size="huge" :val="prog_pct" class="analytics-progress-bar"></vue-simple-progress>
			<div class="analytics-progress-score">{{ prog_pct }}%</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'ResumeTutorialLoading',
	props: ['resumeNo'],
	data() {
		return {
			prog_pct: 0,
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.progressBar();
		});
	},
	updated() {
		this.$nextTick(() => {
			if (this.prog_pct === 100) {
				setTimeout(() => {
					window.location.replace('/resume/write?resume_no=' + this.resumeNo);
				}, 500);
			}
		});
	},
	methods: {
		progressBar() {
			let pct = 1;

			this.progInterval = setInterval(() => {
				if (this.prog_pct === 100) {
					return clearInterval(this.progInterval);
				}
				this.prog_pct = pct++;
			}, 50);
		},
	},
};
</script>
