export const SEARCH_PRODUCT_TYPES = {
	INDEX: 'index',
	COMMUNITY: 'community',
	EDU: 'edu',
	CLASS_PT: 'pt',
	VOD: 'vod',
	CAREER: 'career',
	ANSWER: 'answer',
};

export const SEARCH_SORT_TYPES = {
	RELEVANCE: 'relevance',
	RECENT: 'recent',
	POPULAR: 'popular',
	COMMENTS: 'comments',
	COMMENTS_FEW: 'comments_few',
};
