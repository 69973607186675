<template>
	<div class="flex flex-row align-center">
		<c-text-rating class="mr-6">
			<template v-slot:text-left>
				<c-typography type="caption1" :font-weight="400" color="gray500">글자수</c-typography>
			</template>
			<template v-slot:text-right>
				<c-typography type="caption1" :font-weight="400" color="gray700">
					{{ count | formatNumber }}
				</c-typography>
			</template>
		</c-text-rating>
	</div>
</template>

<script>
export default {
	name: 'AnswerCountSection',
	props: {
		count: {
			type: [Number, String],
			default: 0,
		},
	},
};
</script>

<style scoped></style>
