const alertModalMixin = {
	data() {
		return {
			showAlert: false,
			alertModalTitle: 'AlertModalTitle',
			alertModalMessage: 'AlertModalMessage',
			alertModalButtonName: 'AlertModalButtonName',
			alertSuccessCallback: () => {},
		};
	},
	methods: {
		$_handleShowAlertModal({ title, message, buttonName, successCallback }) {
			this.alertModalTitle = title;
			this.alertModalMessage = message;
			this.alertModalButtonName = buttonName;
			this.alertSuccessCallback = successCallback;
			this.showAlert = true;
		},
	},
};

export default alertModalMixin;
