<template>
	<transition name="slide-fade">
		<div class="mobile-feedback-container">
			<section class="mobile-feedback-header">
				<div style="color: #fff">닫기</div>
				<div>어떤 역량이 엿보이나요?</div>
				<div class="text-caption" @click="close">닫기</div>
			</section>
			<section v-if="show" class="mobile-feedback-body">
				<div class="container">
					<div v-for="strength in strengthDescriptionList" class="mb-20">
						<div class="mb-5">
							<strong
								class="border-right"
								:class="selectedStrength === strength.strength ? 'highlight-text' : ''"
							>
								{{ strength.strength }}
							</strong>
							<div class="text-primary c-default">{{ strength.keyword.join(', ') }}</div>
						</div>
						<p class="h7">{{ strength.description }}</p>
					</div>
				</div>
			</section>
		</div>
	</transition>
</template>

<script>
export default {
	props: ['show', 'strengthDescriptionList', 'selectedStrength'],
	data: () => ({}),
	watch: {
		show() {
			let top = '100%';
			if (this.show) {
				top = '0';
			} else {
				top = '100%';
			}
			document.querySelector('.mobile-feedback-container').style.top = top;
		},
	},
	created() {},
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
@normal-color: #495057;
@border-color: #dee2e6;

.mobile-feedback-body {
	-ms-overflow-y: scroll;
	overflow-y: scroll;
	.container {
		padding-bottom: 100px;
	}
}

.full-wrapper {
	width: 100%;
}

.package-icon {
	&-group {
		border-bottom: 1px solid @border-color;
		padding-bottom: 20px;
		margin-bottom: 20px;
		&:last-child {
			border-bottom: 0;
		}
		.date {
			color: @normal-color;
		}
		.word-break {
			-ms-word-break: keep-all;
			word-break: keep-all;
		}
		.flex-left {
			align-items: flex-start;
		}
	}
	width: 50px;
	height: 50px;
}
</style>
