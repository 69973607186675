/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;
import './bootstrap';
import './common';
import './shared';
import 'fg-loadcss';
import './input_style_changer';
import './utils';
import './registerGtmTrigger';
import './mixpanel';

import Vue from 'vue';
window.Vue = Vue;

import VueCarousel from 'vue-carousel';
window.VueCarousel = VueCarousel;

import moment from 'moment';
window.moment = moment;

// import 'expose-loader?$!expose-loader?jQuery!jquery';
import 'es6-promise/auto';
import router from './router/index.js';
import store from './store/index.js';
import './mixins';
import './plugins';
import './directives';
import './datadog';
// import './sentry';
import './bizComponents'; // biz components

import VueSimpleProgress from 'vue-simple-progress';
import ResumeTutorialExitButton from '@/components/resume/resumeTutorial/ResumeTutorialExitButton.vue';

Vue.component('vue-simple-progress', VueSimpleProgress);
Vue.component('resume-exit', ResumeTutorialExitButton); // blade에서 쓰이므로 지우면 안됨

//공통 컴포넌트
import DynamicSwiper from '@/components/_common/swiper/DynamicSwiper.vue';
import Avatar from './components/common/dataDisplay/Avatar.vue';
import Badge from './components/common/Badge.vue';
import Box from './components/common/dataDisplay/Box.vue';
import BaseSkeleton from './components/common/BaseSkeleton.vue';
import List from './components/common/dataDisplay/list/List.vue';
import Typography from './components/common/Typography.vue';
import Reply from './components/common/dataDisplay/Reply.vue';

//card
import Contents from './components/common/dataDisplay/contents/Contents.vue';
import ContentsOverline from './components/common/dataDisplay/contents/ContentsOverline.vue';
import ContentsTitle from './components/common/dataDisplay/contents/ContentsTitle.vue';
import ContentsBody from './components/common/dataDisplay/contents/ContentsBody.vue';
import ContentsCaption from './components/common/dataDisplay/contents/ContentsCaption.vue';

//list
import ListTitle from './components/common/dataDisplay/list/ListTitle.vue';
import ListBody from './components/common/dataDisplay/list/ListBody.vue';

//모달
import ModalComponent from './components/_modal/ModalComponent.vue';
import AlertComponent from './components/_modal/AlertComponent.vue';
import ConfirmComponent from './components/_modal/ConfirmComponent.vue';
import ConfirmCallbackComponent from '../biz/js/components/_modal/ConfirmCallbackComponent.vue';

//레이아웃
import HeaderWrapper from './layouts/HeaderWrapper.vue';
import MainLayout from './layouts/MainLayout.vue';
import FooterLayout from './layouts/FooterLayout.vue';

import { mapGetters } from 'vuex';

Vue.component('c-swiper-dynamic', DynamicSwiper);
Vue.component('avatar', Avatar);
Vue.component('badge', Badge);
Vue.component('box', Box);
Vue.component('base-skeleton', BaseSkeleton);
Vue.component('list', List);
Vue.component('Typography', Typography);
Vue.component('reply', Reply);
Vue.component('contents', Contents);
Vue.component('contents-overline', ContentsOverline);
Vue.component('contents-title', ContentsTitle);
Vue.component('contents-body', ContentsBody);
Vue.component('contents-caption', ContentsCaption);
Vue.component('list-title', ListTitle);
Vue.component('list-body', ListBody);
Vue.component('modal-component', ModalComponent);
Vue.component('alert-modal-component', AlertComponent);
Vue.component('confirm-modal-component', ConfirmComponent);
Vue.component('confirm-callback-component', ConfirmCallbackComponent);
Vue.component('header-wrapper', HeaderWrapper);
Vue.component('main-layout', MainLayout);
Vue.component('footer-layout', FooterLayout);

/*
 * views 컴포넌트 전역 등록
 * https://vitejs.dev/guide/assets.html#importing-asset-as-url
 */
const modules = import.meta.glob('./views/**/*.vue');

const components = Object.entries(modules).reduce((result, [filepath, importFunction]) => {
	const componentName = filepath
		.split('/')
		.pop()
		.replace(/\.\w+$/, '');
	// 연속 대문자는 그동안 사용한 케밥 케이스에 맞추기 위해 변경
	const componentNameConverted = componentName.replace(
		/([A-Z])([A-Z]+(?=[A-Z]))/g,
		(_, group1, group2) => group1 + group2.toLowerCase(),
	);
	result[componentNameConverted] = importFunction;
	return result;
}, {});

const $Vue = new Vue({
	el: '#app',
	router,
	store,
	data: {
		bizMenuFlag: -1,
	},
	computed: {
		...mapGetters(['isUser', 'userNo']),
	},
	created() {
		window.addEventListener('keydown', e => {
			if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'c') {
				axios({
					method: 'POST',
					url: '/api/log/ctrl',
					data: {
						key: e.key.toLowerCase(),
						page: location.href,
					},
				}).then(function (response) {});
			}
		});
	},
	mounted() {
		this.clearSessionStorage();
		this.initializeBraze();
	},
	methods: {
		bizMenuBlinder() {
			this.bizMenuFlag = this.bizMenuFlag * -1;
			$('nav.nav-bar').animate(
				{
					left: -250,
				},
				300,
			);
			$('.blinder').animate(
				{
					opacity: 'hide',
				},
				500,
			);
		},
		clearSessionStorage() {
			const pathName = window.location.pathname;
			const mypageAnswerList = sessionStorage.getItem('state_mypage_answer');
			const mypageRequestList = sessionStorage.getItem('state_mypage_request');
			const mypageAnalyticsList = sessionStorage.getItem('state_mypage_analytics');
			const eduCampList = sessionStorage.getItem('state_edu_camp');

			if (
				mypageAnswerList !== null &&
				!pathName.startsWith('/mypage/answer') &&
				!pathName.startsWith('/mine/question')
			) {
				sessionStorage.removeItem('state_mypage_answer');
			}
			if (
				mypageRequestList !== null &&
				!pathName.startsWith('/mypage/request') &&
				!pathName.startsWith('/mine/')
			) {
				sessionStorage.removeItem('state_mypage_request');
			}
			if (
				mypageAnalyticsList !== null &&
				!pathName.startsWith('/mypage/analytics') &&
				!pathName.startsWith('/analytics/report')
			) {
				sessionStorage.removeItem('state_mypage_analytics');
			}
			if (eduCampList !== null && !pathName.startsWith('/edu/list') && !pathName.startsWith('/edu/learn/')) {
				sessionStorage.removeItem('state_edu_camp');
			}
		},
		initializeBraze() {
			if (!import.meta.env.PROD) {
				return;
			}

			import('@braze/web-sdk')
				.then(({ initialize, getUser, changeUser }) => {
					initialize(import.meta.env.VITE_BRAZE_API_KEY, {
						baseUrl: import.meta.env.VITE_BRAZE_ENDPOINT,
						enableLogging: import.meta.env.DEV,
					});
					const brazeUserId = getUser().getUserId();
					const shouldChangeUser = this.isUser && brazeUserId !== this.userNo.toString();
					if (shouldChangeUser) {
						changeUser(this.userNo.toString());
					}
				})
				.catch(e => {
					console.error('Braze SDK error', e);
				});
		},
	},
	components,
}).$mount('#app');

window.$Vue = $Vue;
