<template>
	<section style="overflow-x: hidden">
		<BlogHeader class="only-pc" />
		<BlogMobileMenu page-name="list" :menus="blogMagazines" />
		<div class="container blog-container">
			<div class="only-pc blog-page-title-wrapper">
				<h2 class="main-copy display2 f-regular">
					{{ $route.params.magazine }}
				</h2>
			</div>
			<nav class="tab-list-nav text-center">
				<div class="tab-list-nav-wrapper">
					<ul class="tab-list">
						<router-link
							v-for="category in categories"
							:key="category.no"
							tag="li"
							:class="categoryNo === category.no ? 'selected-tab' : 'deselected-tab'"
							:to="goUrl(category.name)"
							@click.native="categoryNo = category.no"
						>
							{{ category.name }}
						</router-link>
					</ul>
				</div>
			</nav>
			<section class="content-container">
				<BlogCard :blog-list="blogList" />
				<infinite-loading
					ref="infiniteLoading"
					:identifier="infiniteId"
					force-use-infinite-wrapper="true"
					@infinite="infiniteHandler"
				>
					<span slot="no-more" />
					<span slot="no-results">
						<div class="blog-no-results">아직 작성된 글이 없어요 :(</div>
					</span>
				</infinite-loading>
				<CustomLink v-if="isComentoUser()" class="float-btn write" to="/blog/write" />
			</section>
		</div>
		<BlogFooter />
	</section>
</template>

<script>
import BlogHeader from '../../components/blog/HeaderComponent.vue';
import BlogCard from '../../components/blog/CardComponent.vue';
import BlogMobileMenu from '../../components/blog/MobileMenuComponent.vue';
import BlogFooter from '../../components/blog/FooterComponent.vue';
import { blog } from '../../mixins/blog';
import { mapMutations } from 'vuex';
import CustomLink from '../../components/common/CustomLink.vue';

export default {
	mixins: [blog],
	data: () => ({
		page: 1,
		paramsCategory: [],
		blogList: [],
		infiniteId: +new Date(),
		categoryNo: 0,
		magazineNo: 0,
		sessionInfo: null,
	}),
	computed: {
		categories() {
			let categoryMenu = [];

			categoryMenu.push({
				no: 0,
				name: '전체 보기',
			});

			this.blogMagazines.forEach(magazine => {
				if (magazine.description === this.$route.params.magazine) {
					const categories = magazine.blog_category;
					categoryMenu.push(...categories);
					if (this.$route.params.category !== undefined) {
						for (let i = 0; i < categories.length; i++) {
							if (this.$route.params.category === categories[i].name) {
								this.categoryNo = ++i;
							}
						}
					}
				}
			});
			return categoryMenu;
		},
	},
	watch: {
		$route: 'init',
	},
	mounted() {
		this.setMeta({
			isShowFooter: false,
			isShowHeader: false,
			isDetail: true,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		getBlogList($state) {
			let params = {
				page: this.page,
				magazine_no: this.magazineNo,
			};
			if (this.categoryNo > 0) params.category_no = this.categoryNo;

			setTimeout(() => {
				axios({
					method: 'GET',
					url: '/api/blog/list',
					params: params,
				})
					.then(response => {
						const data = response.data;
						try {
							if (data.code === 200) {
								if (Object.keys(data.list.data).length > 0) {
									this.blogList.push(...data.list.data);
									this.page += 1;
									$state.loaded();
								} else {
									$state.complete();
								}
							}
						} catch (error) {
							$state.complete();
						}
					})
					.catch(error => {
						console.log(error);
						$state.complete();
					});
			}, 500);
		},
		infiniteHandler($state) {
			if (this.page === 1) {
				axios
					.get('/api/blog/category/list')
					.then(response => {
						// console.log(response);
						const data = response.data;
						data.list.forEach(magazine => {
							if (magazine.description === this.$route.params.magazine) {
								this.magazineNo = magazine.no;
							}
						});
					})
					.catch(error => {
						console.log(error);
					})
					.finally(() => {
						this.getBlogList($state);
					});
			} else {
				this.getBlogList($state);
			}
		},
		init() {
			this.page = 1;
			this.blogList = [];
			this.categoryNo = 0;
			this.infiniteId += 1;
		},
		goUrl(category) {
			return `/blog/list/${this.$route.params.magazine}/${category}`;
		},
		isComentoUser() {
			return (
				this.$store.state.sessionInfo !== null &&
				this.$store.state.sessionInfo.id.split('@')[1] === 'comento.kr'
			);
		},
	},
	components: {
		CustomLink,
		BlogHeader,
		BlogCard,
		BlogMobileMenu,
		BlogFooter,
	},
};
</script>

<style lang="less" scoped>
@media (max-width: 480px) {
	.blog-container {
		padding: 0;
	}
}
.blog-no-results {
	background: white;
	padding: 30px 0;
}
</style>
