<template>
	<div class="resume-navigator">
		<div class="line"></div>
		<ul>
			<li v-for="(prog, index) in applyProgressList" :key="`circle-outline-${index}`" class="circle-outline-big">
				<div class="circle" :class="index <= selectedIndex ? 'selected' : ''"></div>
				<p>{{ prog }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: ['display', 'applyType', 'applyStatus'],
	data() {
		return {
			applyProgressList: [],
			selectedIndex: 0,
		};
	},
	mounted() {
		this.$nextTick(() => {
			if (this.applyType === 1) {
				//직접지원
				let applyName = ['지원완료', '서류합격', '면접확정', '최종합격', '전형종료'];
				if (this.display === 'biz') {
					applyName = ['서류평가', '면접대기', '면접진행', '면접평가', '입사확정'];
				}
				this.applyProgressList = applyName;
			} else {
				//자동지원

				let aiName = ['AI자동지원', '관심확인', '면접제안', '면접확정', '최종합격'];
				if (this.display === 'biz') {
					aiName = ['관심확인', '면접제안', '면접확정', '면접평가', '입사확정'];
				}
				this.applyProgressList = aiName;
				if (this.applyStatus === '자동지원') {
					this.selectedIndex = -1;
					return false;
				}
				if (this.applyStatus === '전형종료') {
					this.selectedIndex = 4;
					return false;
				}
			}

			for (let index in this.applyProgressList) {
				if (this.applyProgressList[index] === this.applyStatus) {
					this.selectedIndex = index;
				}
			}
		});
	},
	methods: {},
};
</script>

<style lang="less" scoped>
@yellow: #fff377;

.resume-navigator {
	position: relative;
	margin-bottom: 20px;
	.line {
		width: 80%;
		position: relative;
		left: 0;
		-webkit-transform: translate(0);
		-moz-transform: translate(0);
		-ms-transform: translate(0);
		-o-transform: translate(0);
		transform: translate(0);
		margin: 0 auto;
	}
	ul {
		width: 100%;
		li.circle-outline-big {
			margin: 0;
			.circle {
				border-width: 1px;
				&.selected {
					background-color: @yellow;
					border: none;
				}
			}
		}
	}
}
</style>
