<template>
	<div>
		<!-- 자소서 튜토리얼 - 자가진단 체크리스트 -->
		<c-typography type="body2" color="blue600" class="flex align-center">
			<c-icon name="IconCheckRoundSmallLine" color="blue600" class="mr-4" />
			작성 내용 자가 진단
		</c-typography>
		<c-divider type="dash" class="mt-12 mb-24" />
		<ol>
			<template v-for="(item, index) in checkListAnswer">
				<li v-if="index + 1 <= checkListStep" :id="`check-list-${index}`" :key="`check-list-${index}`">
					<div class="check-list-wrapper">
						<c-typography type="body2" color="gray800" v-html="checkListTitle(index)" />
						<div class="check-list-button-wrapper" :class="handleCheckStyle(item.value)">
							<div
								:id="`checked-${index}`"
								class="check-list-button checked-button"
								@click="handleAnswer(index, true)"
							>
								예
							</div>
							<div
								:id="`not-checked-${index}`"
								class="check-list-button not-checked-button"
								@click="handleAnswer(index, false)"
							>
								아니오
							</div>
						</div>
					</div>
					<div class="well mt-16 mb-52">
						<c-typography color="gray850" v-html="$options.filters.nl2br(checkListContent(index))" />
					</div>
				</li>
			</template>
			<li v-show="showCheckListResult">
				<div>
					<c-typography type="body2" color="gray800" font-weight="semi-bold" class="mb-16">
						자가 진단 결과 확인하기
					</c-typography>
					<c-typography type="body2" color="gray800" v-html="checkListResultTitle()" />
				</div>
				<div class="well mt-10 mb-60" v-html="checkListResultContent()" />
			</li>
		</ol>
		<div v-show="isAnswerNotNull" class="flex flex-center">
			<c-button
				:type="isAllChecked ? 'outline' : 'fill'"
				size="xlarge"
				:color="isAllChecked ? 'info' : 'primary'"
				class="resume-cta-button"
				:class="{ 'mx-auto': !isAllChecked }"
				@click="changeRoute()"
			>
				수정하기
			</c-button>
			<c-button v-if="isAllChecked" size="xlarge" class="resume-cta-button ml-10" @click="goResumeBegin()">
				저장하기
			</c-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'EssaySelfCheckList',
	props: {
		job: {
			type: String,
		},
		jobDetail: {
			type: String,
		},
		jobDetailExcellence: {
			type: String,
		},
		competencyName: {
			type: String,
		},
		competencyDetail: {
			type: String,
		},
	},
	data() {
		return {
			checkListStep: 1,
			checkListAnswer: [
				{
					step: 2,
					value: null,
				},
				{
					step: 3,
					value: null,
				},
				{
					step: 5,
					value: null,
				},
			],
			showCheckListResult: false,
			isAllChecked: false,
		};
	},
	computed: {
		isAnswerNotNull() {
			return this.checkListAnswer.every(({ value }) => {
				return value !== null;
			});
		},
	},
	methods: {
		checkListTitle(index) {
			const step = index + 1;
			const title = {
				1: `멘티님이 경험을 통해 강조하고 싶은 역량은 ‘${this.competencyName}’입니다.<br />
          ${this.job} 업무가 하는 일을 고려할 때 멘티님이 강조하고 싶은 역량이 ${this.competencyName}이 맞나요?`,
				2: `멘티님이 정의한 직무우수자의 모습에 ‘${this.competencyName}’ 이 포함되어 있나요?`,
				3: `채용 평가자 입장에서는 멘티님의 경험을 읽고 아래의 모습을 상상할 수 있어야 합니다.<br />
          위에 작성된 경험이 ‘${this.competencyName}’을 강조하는 경험이 맞나요?`,
			};

			return title[step];
		},
		checkListContent(index) {
			const step = index + 1;
			const content = {
				1: `${this.jobDetail} 하는 직무`,
				2: this.jobDetailExcellence,
				3: this.competencyDetail,
			};

			return content[step];
		},
		checkListResultTitle() {
			if (this.isAllChecked) {
				return `자가 진단을 통과하신 것을 축하드립니다.<br />
          자기소개서를 완성하고 최종 합격을 준비하기 위해 아래 사항을 검토해보세요.`;
			} else {
				const uncheckedCount = this.checkListAnswer.filter(({ value }) => {
					return value === false;
				}).length;
				return `<div class="mt-16">${uncheckedCount}개의 문항에 ‘아니오'를 답하셨네요.<br />
          더 좋은 자기소개서를 위해 아래 사항을 점검하고 내용을 수정해보세요.</div>`;
			}
		},
		checkListResultContent() {
			if (this.isAllChecked) {
				return `1. 정리한 에피소드를 바탕으로 AI 자기소개서 분석기를 통해 역량 진단을 받아보세요.<br />
          2. 정리한 에피소드를 바탕으로 자기소개서 작성 후 현직자 멘토링을 통해 피드백을 받아보세요.<br />
          3. 직무경험을 통해 합격 확률을 높이고 싶다면 <a href="/edu" target="_blank"><u style="color: #1E90FF">여기</u></a>를 눌러 직무부트캠프에 참여해보세요.`;
			} else {
				return `1. 직무가 하는 일을 올바르게 이해하고 있나요?<br />
          2. 직무 우수자가 어떤 모습인지, 왜 모습인지 이해하고 있나요?<br />
          3. 멘티님의 역량은 무엇인가요? 이 역량이 직무 우수자의 모습과 하나 이상 일치하나요?<br />
          4. 강조하고 싶은 역량을 올바르게 이해하고 있나요?<br />
          5. 내가 갖고 있는 경험이 위 역량을 표현하기에 적합하게 기술되어 있나요?<br />
          6. 직무 이해도가 낮거나 관련 경험이 없다면 <a href="/edu" target="_blank"><u style="color: #1E90FF">여기</u></a>를 눌러 직무부트캠프에 참여해보세요.`;
			}
		},
		handleAnswer(index, value) {
			const maxAnswerLength = this.checkListAnswer.length;
			this.checkListAnswer[index].value = value;
			const isLastAnswer = index + 1 === maxAnswerLength;
			const showNextCheckList = index + 1 === this.checkListStep && !isLastAnswer;
			if (showNextCheckList) {
				this.checkListStep += 1;
			}
			this.handleAllChecked();
			this.$forceUpdate();
			this.scrollTo(`check-list-${index}`, 500, -120);
			if (isLastAnswer) {
				this.showCheckListResult = true;
			}
		},
		handleCheckStyle(value) {
			return value === null ? '' : value ? 'checked' : 'not-checked';
		},
		handleAllChecked() {
			this.isAllChecked = this.checkListAnswer.every(({ value }) => {
				return value === true;
			});
		},
		changeRoute() {
			const uncheckedItem = this.checkListAnswer.filter(({ value }) => {
				return value === false;
			});
			const hasStep = this.$_checkOwnProperty(uncheckedItem[0], 'step');
			const step = hasStep ? uncheckedItem[0].step : 6;
			this.$emit('changeRoute', step);
		},
		goResumeBegin() {
			this.$emit('goResumeBegin');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../scss/base/main';

.check-list-wrapper {
	@include flexbox();
	@include justify-content(space-between);
	@include align-items(flex-end);
}

.check-list-button {
	@include body2();
	padding: 7px 0;
	width: 63px;
	text-align: center;
	height: 36px;
	cursor: pointer;

	&-wrapper {
		@include flexbox();
		&.checked {
			.checked-button {
				background: $blue600;
				color: white;
				&:hover {
					background: $blue800;
					border-color: $blue800;
				}
			}
			.not-checked-button {
				color: $gray200;
				border-color: $gray200;
				&:hover {
					background: $gray000;
				}
			}
		}
		&.not-checked {
			.not-checked-button {
				background: $gray400;
				color: white;
				&:hover {
					background: $gray500;
				}
			}
			.checked-button {
				color: $blue100;
				border-color: $blue100;
				border-right: 0;
				&:hover {
					background: $blue000;
				}
			}
		}
	}

	&.checked-button {
		border-radius: 4px 0 0 4px;
		color: $blue600;
		border: 1px solid $blue600;
		font-weight: bold;
		&:hover {
			background: $blue100;
		}
	}

	&.not-checked-button {
		border-radius: 0 4px 4px 0;
		color: $gray600;
		border: 1px solid $gray400;
		border-left: 0;
		font-weight: bold;
		&:hover {
			background: $gray100;
		}
	}
}

ol {
	list-style-type: decimal;
	font-size: 14px;
	color: $gray700;
	padding-left: 15px;

	li {
		margin-bottom: 8px;
		@include pc {
			margin-bottom: 12px;
		}
		&:last-child {
			margin-bottom: 0;
		}

		> div {
			margin-left: 6px;
		}
	}
}
</style>
