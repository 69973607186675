<template>
	<div>
		<biz-side-component num="4" />
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<section class="mb-10">
						<div class="font-title-big f-semi-bold mb-10">{{ pmInterviewUser.job }} 면접일정 발송</div>
						<div class="font-caption text-caption mb-10">
							면접 가능 일정을 입력하면 지원자에게 가능 일정이 발송됩니다.<br class="only-pc" />
							면접 가능 일정 중 가능한 시간을 면접대상자가 선택하면 일정이 최종확정 됩니다.<br
								class="only-pc"
							/>
							확정된 일정은 이메일로 발송되며 캘린더에 자동 등록됩니다.
						</div>
						<div class="font text-link cursor-pointer" @click="showInterviewHelp = true">상세 안내</div>
					</section>
					<div class="bg-white border pd-30">
						<div class="mb-30">
							<div class="font-title f-semi-bold">
								담당자<span class="font f-regular">(면접자가 누구에게 연락해야 하나요?)</span>
							</div>
							<input
								v-model="hrName"
								class="com-inputbox font f-light pd-10 mt-10"
								type="text"
								placeholder="담당자 이름"
							/>
						</div>
						<div class="mb-30">
							<div class="font-title f-semi-bold">담당자 이메일</div>
							<input
								v-model="hrEmail"
								class="com-inputbox font f-light pd-10 mt-10"
								type="text"
								placeholder="담당자 이메일"
							/>
						</div>
						<div class="mb-30">
							<div class="font-title f-semi-bold">담당자 전화번호</div>
							<input
								v-model="mobile"
								class="com-inputbox font f-light pd-10 mt-10"
								type="text"
								placeholder="담당자 전화번호"
							/>
						</div>
						<div class="mb-30">
							<div class="font-title f-semi-bold">
								면접 장소<span class="font f-regular">(주소)</span>
							</div>
							<input
								v-model="hrPlace"
								class="com-inputbox font f-light pd-10 mt-10"
								type="text"
								placeholder="면접 장소(주소)"
							/>
						</div>
						<div class="mb-30">
							<div class="font-title f-semi-bold">면접 시간</div>
							<div>
								<input
									id="interviewTime"
									v-model="interviewTime"
									class="com-radio mt-10 mb-30"
									type="radio"
									:value="interviewTime"
									checked
								/>
								<label class="font" for="interviewTime">{{ interviewTime }}</label>
							</div>
						</div>
						<div class="mb-30">
							<div class="font-title f-semi-bold">면접 가능 일자</div>
							<div class="text-caption f-light font-caption mb-10">
								날짜와 시간을 조합하여 옵션이 전달되며, 지원자가 일정을 선택하면 면접이 확정됩니다.<br />
								(우측 +버튼으로 여러 날짜 옵션을 추가할 수 있습니다.)
							</div>
							<div class="f-red f-light font-caption">
								* +3일내 일정은 [다른면접일자요청] 확률이 85%로 +4일 이후 일정을 추천드립니다.
							</div>
							<div v-for="(date, index) in cntDate" class="interview-date-group">
								<date-picker
									v-model="interviewDate[index]"
									class="datepicker font"
									type="date"
									placeholder="면접 날짜 선택"
									:format="datePickerFormat"
									:lang="datePickerLang"
									:disabled-date="$_isBeforeToday"
								></date-picker>
								<date-picker
									v-model="interviewStartTime[index]"
									class="datepicker font interview-time start"
									type="time"
									format="HH:mm"
									:time-picker-options="timePickerOption"
									placeholder="면접 시간 선택"
								/>
								~
								<date-picker
									v-model="interviewEndTime[index]"
									class="datepicker font interview-time"
									type="time"
									:format="timePickerFormat"
									:time-picker-options="timePickerOption"
									placeholder="면접 시간 선택"
								/>
								<img
									v-if="date === 1"
									src="/images/green_plus.svg"
									class="icon pointer add-edu"
									@click="addDate"
								/>
								<img
									v-if="date > 1"
									src="/images/reverse_minus.svg"
									class="icon pointer add-edu"
									@click="delDate(index)"
								/>
							</div>
						</div>
						<div class="mb-30">
							<div class="font-title f-semi-bold">면접 진행 후보자 선택</div>
							<div v-for="user in interviewUserList" class="inline-block">
								<input
									:id="user"
									v-model="interviewUser"
									class="com-checkbox"
									type="checkbox"
									:value="user"
								/>
								<label class="font cursor-pointer" style="margin-right: 15px" :for="user">{{
									user
								}}</label>
							</div>
						</div>
						<div class="mb-30">
							<div class="font-title f-semi-bold">면접 복장 안내</div>
							<div v-for="dress in interviewDressList" class="inline-block">
								<input
									:id="dress"
									v-model="cloth"
									class="com-radio mt-10 mb-30"
									type="radio"
									:value="dress"
								/>
								<label class="font" :for="dress">{{ dress }}</label>
							</div>
						</div>
						<div class="mb-30">
							<div class="font-title f-semi-bold">면접자에게 남기실 말</div>
							<textarea
								v-model="memo"
								placeholder="OO빌딩 1층에 오셔서 담당자 연락처로 전화주세요."
								class="com-textbox font f-light pd-10 mt-10 mb-30"
							></textarea>
						</div>
					</div>
					<div class="text-center mt-20">
						<button class="btn btn-close cursor-pointer mr-10" @click="showPreviewModal()">미리보기</button>
						<button class="btn cursor-pointer" @click="submitInterview()">보내기</button>
					</div>
				</div>
			</div>
		</section>
		<biz-interview-help-component
			:show="showInterviewHelp"
			width="650"
			@close="showInterviewHelp = false"
		></biz-interview-help-component>
		<biz-interview-preview-component
			:show="showInterviewPreview"
			:interviewList="interviewList"
			@close="showInterviewPreview = false"
		></biz-interview-preview-component>
		<alert-modal-component
			:show="showAlert"
			:title="title"
			:message="message"
			type="simple"
			:buttonName="buttonName"
			:successCallback="successCallback"
			@close="showAlert = false"
		></alert-modal-component>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import datepickerMixin from '../../../../../js/mixins/datepickerMixin';

export default {
	mixins: [datepickerMixin],
	props: ['pmUserInfo', 'pmInterviewUser', 'comNo'],
	data() {
		return {
			showInterviewHelp: false,
			showInterviewPreview: false,
			showAlert: false,
			title: '',
			message: '',
			buttonName: '',
			successCallback: '',
			company: '',
			position: '',
			hrName: '',
			hrEmail: '',
			mobile: '',
			hrPlace: '',
			interviewTime: '1시간',
			interviewUserList: [],
			interviewUserNo: [],
			interviewUser: [],
			interviewDressList: ['캐쥬얼', '비즈니스캐쥬얼', '정장', '자유복장'],
			cloth: '',
			interviewList: [],
			memo: '',
			datePickerLang: {
				days: ['일', '월', '화', '수', '목', '금', '토'],
				months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
				placeholder: {
					date: '날짜',
				},
			},
			timePickerOption: { start: '09:00', step: '01:00', end: '20:00' },
			datePickerFormat: 'YYYY-MM-DD',
			timePickerFormat: 'HH:mm',
			cntDate: 1,
			interviewDate: [],
			interviewStartTime: [],
			interviewEndTime: [],
			startDate: [],
			startTime: [],
			endDate: [],
			endTime: [],
		};
	},
	mounted() {
		this.company = this.pmUserInfo.company;
		this.position = this.pmUserInfo.position;
		this.hrName = this.pmUserInfo.name;
		this.hrEmail = this.pmUserInfo.email;
		this.mobile = this.pmUserInfo.mobile;

		for (let i = 0; i < this.pmInterviewUser.length; i++) {
			this.interviewUserList.push(this.pmInterviewUser[i].name);
			this.interviewUserNo.push(this.pmInterviewUser[i].no);
			this.interviewUser[i] = this.interviewUserList[i];
		}
	},
	methods: {
		showPreviewModal() {
			//미리보기 모달
			this.interviewList = []; //초기화
			this.interviewList.push({
				company: this.company,
				hrName: this.hrName,
				hrEmail: this.hrEmail,
				hrPlace: this.hrPlace,
				cloth: this.cloth,
				memo: this.memo,
			});
			this.showInterviewPreview = true;
		},
		isInterviewEmpty() {
			let bool = false;
			for (let i = 0; i < this.cntDate; i++) {
				if (
					this.interviewDate[i] === null ||
					this.interviewStartTime[i] === null ||
					this.interviewEndTime[i] === null
				) {
					bool = true;
				}
			}
			return bool;
		},
		submitInterview() {
			//면접 보내기
			if (this.hrName === '') {
				alert('담당자를 확인해주세요!');
				return false;
			} else if (this.hrEmail === '') {
				alert('담당자 이메일을 확인해주세요!');
				return false;
			} else if (this.mobile === '') {
				alert('담당자 전화번호를 확인해주세요!');
				return false;
			} else if (this.hrPlace === '') {
				alert('면접 장소를 확인해주세요!');
				return false;
			} else if (
				this.cntDate !== this.interviewDate.length ||
				this.cntDate !== this.interviewStartTime.length ||
				this.cntDate !== this.interviewEndTime.length
			) {
				alert('면접 가능 일자를 확인해주세요!');
				return false;
			} else if (this.isInterviewEmpty()) {
				alert('면접 가능 일자를 확인해주세요!');
				return false;
			} else if (this.interviewUser.length < 1) {
				alert('면접 진행 후보자를 선택해주세요!');
				return false;
			} else if (this.cloth === '') {
				alert('면접 복장을 확인해주세요!');
				return false;
			} else if (this.memo === '') {
				alert('면접자에게 남기실 말을 확인해주세요!');
				return false;
			} else {
				//면접 일자 시간, 초 분리
				for (let i = 0; i < this.cntDate; i++) {
					this.startDate.push(moment(this.interviewDate[i]).format('YYYY-MM-DD'));
					this.startTime.push(moment(this.interviewStartTime[i]).format('HH:mm'));
					this.endDate.push(moment(this.interviewDate[i]).format('YYYY-MM-DD'));
					this.endTime.push(moment(this.interviewEndTime[i]).format('HH:mm'));
				}

				//면접진행 후보의 아이디 정보
				let id = [];

				for (let i = 0; i < this.pmInterviewUser.length; i++) {
					for (let j = 0; j < this.interviewUser.length; j++) {
						if (this.pmInterviewUser[i].name === this.interviewUser[j]) {
							id.push(this.pmInterviewUser[i].no);
						}
					}
				}

				showLoading();

				axios({
					method: 'POST',
					url: '/api/interview/offer',
					data: {
						com_no: this.comNo,
						location: this.hrPlace,
						cloth: this.cloth,
						memo: this.memo,
						duration: 60,
						startDate: this.startDate,
						startTime: this.startTime,
						endDate: this.endDate,
						endTime: this.endTime,
						interviewer_name: this.hrName,
						interviewer_email: this.hrEmail,
						interviewer_mobile: this.mobile,
						interviewee_ids: id,
					},
				})
					.then(response => {
						const data = response.data;
						if (data.code === 200) {
							const user_no_list = data.result;

							for (let i = 0; i < user_no_list.length; i++) {
								// GTM - interviewSchedule
								interviewSchedule(user_no_list[i], this.comNo);
							}

							this.title = '전송 완료';
							this.message =
								'일정 옵션을 발송했습니다.\n' +
								'면접 대상자가 가능 일정을 선택하면 최종 확정되며, 메일 및 캘린더로 확정된 일정이 발송될 예정입니다.';
							this.buttonName = '';
							this.successCallback = '/applicant/' + this.comNo;
							this.showAlert = true;
						} else {
							this.title = '전송 실패';
							this.message = '새로고침을 다시 시도바랍니다.';
							this.buttonName = '';
							this.successCallback = '';
							this.showAlert = true;

							this.startDate = [];
							this.startTime = [];
							this.endDate = [];
							this.endTime = [];
						}

						closeLoading();
					})
					.catch(error => {
						this.startDate = [];
						this.startTime = [];
						this.endDate = [];
						this.endTime = [];

						closeLoading();
					});
			}
		},
		addDate() {
			this.cntDate = this.cntDate + 1;
		},
		delDate(idx) {
			this.cntDate = this.cntDate - 1;
			this.interviewDate.splice(idx, 1);
			this.interviewStartTime.splice(idx, 1);
			this.interviewEndTime.splice(idx, 1);
			this.startDate.splice(idx, 1);
			this.startTime.splice(idx, 1);
			this.endDate.splice(idx, 1);
			this.endTime.splice(idx, 1);
		},
	},
	components: {
		DatePicker,
	},
};
</script>

<style lang="less" scoped>
@border-color: #e1e4e7;

.add-edu {
	position: relative;
	top: 5px;
}
.interview-date-group {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.interview-time {
	width: 120px;
	margin: 0 10px;
	&.start {
		margin-left: 20px;
	}
}
.datepicker.between {
	position: relative;
	top: 15px;
	margin: 0 10px;
}
.mx-datepicker {
	.mx-input-wrapper {
		border: 0 !important;
		border-bottom: 1px solid @border-color !important;
	}
	.mx-input-append {
		display: none;
	}
	input[type='text'] {
		border: 0 !important;
		padding: 10px !important;
		margin-top: 10px !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mx-input-append {
		top: 5px !important;
	}
	.mx-shortcuts-wrapper {
		display: none !important;
	}
	@media (max-width: 480px) {
		width: 100% !important;
		.mx-range-wrapper {
			width: auto !important;
			.mx-calendar {
				float: initial !important;
			}
		}
	}
}
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
