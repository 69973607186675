<template>
	<div>
		<biz-side-component num="2"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-center">
				<div class="col-8 col-8-center">
					<biz-company-header-component main="저장완료!"></biz-company-header-component>
					<div class="bg-white border pd-30">
						<div class="mb-20">
							채용 공고가 저장되었습니다.<br />
							AI 매칭을 시작할까요?<br />
							<br /><br />
							지원서, 면접, 채용인원에 관계없이<br />
							공고당 <span class="f-red">월 50,000원</span>의 비용이 발생합니다.
						</div>
						<div>
							<!--채용공고관리-->
							<button class="cursor-pointer btn btn-outline" onclick="location.href='/company/recruit'">
								나중에
							</button>
							<!--결제페이지 이동-->
							<button class="cursor-pointer btn" onclick="location.href='/company/recruit'">
								시작하기
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	props: ['comNo'],
	methods: {
		payment() {
			// post로 보내기.
			let $form = $(document.createElement('form'))
				.css({ display: 'none' })
				.attr('method', 'POST')
				.attr('action', '/payment');
			let $input = $(document.createElement('input')).attr('name', 'pm_com_no').val(this.comNo);
			$form.append($input);
			$('body').append($form);
			$form.submit();
		},
	},
};
</script>

<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
