import Vue from 'vue';
import store from '../store';
import { installStore } from '@comento/common-components';

Vue.use(
	{
		install(_, { store }) {
			installStore(store);
		},
	},
	{ store },
);
