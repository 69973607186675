<template>
	<div>
		<div class="resume-exit" :class="resumePage" @click="viewResumeConfirm">
			<img src="/images/exit.svg" />
			<p>목록으로</p>
		</div>

		<ResumeConfirmModal
			:show="showResumeConfirm"
			:title="title"
			:message="message"
			:saveButtonName="saveButtonName"
			:closeButtonName="closeButtonName"
			@close="showResumeConfirm = false"
			@save="saveConfirm"
		></ResumeConfirmModal>
	</div>
</template>

<script>
import ResumeConfirmModal from '@/components/_modal/resume/ResumeConfirmModal.vue';
import resumePathMixin from '@/mixins/resumePathMixin';

export default {
	name: 'ResumeTutorialExitButton',
	mixins: [resumePathMixin],
	props: {
		page: {
			type: String,
		},
	},
	data() {
		return {
			showResumeConfirm: false,
			title: '',
			message: '',
			saveButtonName: '',
			closeButtonName: '',
		};
	},
	computed: {
		resumePage() {
			if (this.page === 'write') return 'resume-write';
			else if (this.page === 'view') return 'resume-view';
		},
	},
	methods: {
		viewResumeConfirm() {
			if (this.page === 'view') {
				window.location.href = this.$_resumeIndexPath;
				return false;
			} else {
				const pageName = this.page === 'essay-tutorial-builder' ? '자기소개서' : '이력서';
				this.title = ` ${pageName}가 완성되지 않았어요 :( `;
				this.message = '언제든지 다시 이어 작성할 수 있지만,<br>저장하지 않으면 내용이 삭제될 거예요.';
				this.saveButtonName = '저장하고 나가기';
				this.closeButtonName = '페이지 나가기';
				this.showResumeConfirm = true;
			}
		},
		saveConfirm() {
			this.$emit('saveResumeConfirm');
		},
	},
	components: {
		ResumeConfirmModal,
	},
};
</script>
