<template>
	<div>
		<biz-side-component num="6"></biz-side-component>
		<section class="section section-left">
			<div class="container text-left">
				<HelpFormComponent page="biz" :sid="company" :email="email" :mobile="mobile"></HelpFormComponent>
			</div>
		</section>
	</div>
</template>

<script>
import HelpFormComponent from '../../../../js/components/help/HelpFormComponent.vue';

export default {
	props: ['company', 'email', 'mobile'],
	components: {
		HelpFormComponent,
	},
};
</script>
