<template>
	<header class="kpc-header">
		<GridLayout>
			<c-new-col :col-lg="2" :col-sm="2">
				<a class="kpc-header-logo" href="https://www.kpc.or.kr/" target="_blank">
					<img src="/images/kpc/kpc-logo.png" alt="KPC 한국생산성본부" />
				</a>
			</c-new-col>
			<c-new-col :col-lg="2" :offset-lg="8" :col-sm="2" no-gutters>
				<c-narrow-button size="medium" color="gray500" class="kpc-header-button" @click="goKPCIndex()">
					제휴 홈페이지 <template v-if="!isMobile">바로가기</template>
				</c-narrow-button>
			</c-new-col>
		</GridLayout>
	</header>
</template>

<script>
import GridLayout from '../../../common/GridLayout.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'KPCHeader',
	computed: {
		...mapGetters('layoutStore', ['showSubHeader']),
	},
	mounted() {
		this.$nextTick(() => {
			if (!this.isMobile) {
				const $sider = document.querySelector('.sider');
				const siderTopOffset = this.showSubHeader ? 194 : 170;
				$sider.style.top = `${siderTopOffset}px`;
			}
		});
	},
	methods: {
		goKPCIndex() {
			location.href = '/kpc';
		},
	},
	components: { GridLayout },
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.kpc-header {
	display: block;
	width: 100%;
	height: $alert-height-pc;
	position: fixed;
	z-index: 8002; // 헤더가 8001이라서 +1 되어야 함
	top: 0;
	left: 0;
	right: 0;
	background-color: white;
	@include shadow1();

	.c-new-grid,
	.c-new-row,
	.c-new-col {
		height: 100%;
		@include flexbox();
	}
	.c-new-grid {
		@include flex-direction(column);
		@include justify-content(center);
	}
	.c-new-row,
	.c-new-col {
		@include align-items(center);
	}

	&-logo {
		@include flexbox();
	}
	&-logo,
	&-logo img {
		height: 26px;
		@include pc {
			height: 34px;
		}
	}

	&-button {
		margin-left: auto;
	}
}
</style>
