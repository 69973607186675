<template>
	<div>
		<NotiFullscreenModal v-if="isMobile" :show="show" @close="emitClose" @read-all="readAll">
			<div class="ad-banner-section">
				<NotiAdBanner :user-info="userInfo" @click-noti-ad-banner="emitClose" />
			</div>
			<NotiContent
				:noti-list="notiList"
				:is-error-infinite-loading="isErrorInfiniteLoading"
				:infinite-error-message="infiniteErrorMessage"
				:infinite-id="infiniteId"
				@go-noti-detail="goNotiDetail"
				@infinite-handler="infiniteHandler"
			/>
		</NotiFullscreenModal>
		<NotiSideDrawer v-else :show-drawer="show" @close="emitClose" @read-all="readAll">
			<div class="ad-banner-section">
				<NotiAdBanner :user-info="userInfo" @click-noti-ad-banner="emitClose" />
			</div>
			<NotiContent
				:noti-list="notiList"
				:is-error-infinite-loading="isErrorInfiniteLoading"
				:infinite-error-message="infiniteErrorMessage"
				:infinite-id="infiniteId"
				@go-noti-detail="goNotiDetail"
				@infinite-handler="infiniteHandler"
			/>
		</NotiSideDrawer>
	</div>
</template>

<script>
import NotiFullscreenModal from './NotiFullscreenModal.vue';
import NotiSideDrawer from './NotiSideDrawer.vue';
import NotiContent from './NotiContent.vue';
import { getRoutePath } from '@/utils/util';
import { MY_PAGE_COMMUNITY_PATH } from '@/constants/paths';
import { mapGetters } from 'vuex';
import NotiAdBanner from '@/components/_common/noti/NotiAdBanner.vue';

export default {
	name: 'NotiWrapper',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		notiCount: {
			type: Number,
			default: 0,
		},
		userInfo: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			notiList: [],
			notiPage: 1,
			infiniteId: +new Date(),
			infiniteErrorMessage: '',
			isErrorInfiniteLoading: false,
		};
	},
	computed: {
		...mapGetters(['userNo', 'userId', 'userType']),
	},
	methods: {
		emitClose() {
			this.$emit('close');
		},
		resetNotiCount() {
			this.$emit('reset-noti-count');
		},
		async readAll() {
			try {
				const {
					data: { code },
				} = await this.$_fetchRequest({
					method: 'PATCH',
					url: '/api/noti/read/all',
				});

				if (code === 200) {
					this.notiList.forEach(item => {
						if (item.confirm_style !== '') {
							item.confirm_style = '';
						}
					});

					if (this.notiCount > 0) {
						this.resetNotiCount();
					}
					this.$forceUpdate();
				}
			} catch (error) {
				// 후속 동작이 없으므로 사용자에게 에러 상황을 알려주지 않았음
				console.error(error);
			}
		},
		init() {
			this.notiPage = 1;
			this.infiniteId += 1;
			this.notiList = [];
		},
		async infiniteHandler($state) {
			try {
				const {
					data: { code, count, list },
				} = await this.$_fetchRequest({
					method: 'GET',
					url: '/api/noti/list',
					params: { page: this.notiPage },
				});

				if (code === 600) {
					// eslint-disable-next-line no-throw-literal
					throw '로그인이 되지 않았습니다.';
				}

				if (count > 0) {
					this.notiList.push(...list);
					this.notiPage++;
					$state.loaded();
				} else {
					$state.complete();
				}
			} catch (error) {
				console.error(error);
				this.isErrorInfiniteLoading = true;
				this.infiniteErrorMessage = '에러가 발생했습니다. :(';
				$state.error();
			}
		},
		async goNotiDetail({ noti_no, tp, url, confirm_style, index, alarm_url }) {
			//common.js - '.alarm-text' click
			if (confirm_style !== '' && confirm_style !== 'alarm-edu') {
				//읽음 표시
				await this.changeConfirmStyle(noti_no, index);
			}
			switch (tp) {
				case 'lo': //라운지
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = `/lounge/read/${url}`);
				case 'ap': //합격자 감사인사
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = '/mypage/thanks');
				case 'conect': //맞춤공고
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = url);
				case 'qa_feedback':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					let $form = $(document.createElement('form'))
						.css({ display: 'none' })
						.attr('method', 'POST')
						.attr('action', '/answer/question');
					let $input = $(document.createElement('input')).attr('name', 'request_no').val(url);
					$form.append($input);
					$('body').append($form);
					$form.submit();
					break;
				case 'snooze':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = '/mypage/apply');
				case 'credit':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = '/credit/list');
				case '99':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = url);
				case 'qa_history':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = url);
				case 'ce':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = `/mine/essay?request_no=${url}`);
				case 'qa':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					if (alarm_url === '0' || !alarm_url) {
						return (window.location.href = `/mine/question?request_no=${url}`);
					}
					return (window.location.href = url);
				case 'in':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = `/mine/interview?request_no=${url}`);
				case 'no':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = getRoutePath(MY_PAGE_COMMUNITY_PATH, 'nuxt'));
				case 'pm_message':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = url);
				case 'edu':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = url);
				case 'edu_new_camp':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId, 'newcamp');
					return (window.location.href = url);
				case 'edu_detail': //지난 주에 고민하신 00캠프 마감 임박!
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId, 'remind');
					return (window.location.href = url);
				case 'questionToMyCompany': // Drip
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = `/answer/search/${url}`);
				case 'return':
					// GTM - clickNotiList
					clickNotiList(this.userNo, this.userType, this.userId);
					return (window.location.href = '/credit/cash');
			}
		},
		async changeConfirmStyle(noti_no, index) {
			try {
				const { code } = await this.$_fetchRequest({
					method: 'PATCH',
					url: `/api/noti/${noti_no}`,
				});

				if (code === 200) {
					this.notiList[index].confirm_style = '';
				}
			} catch (error) {
				// 후속 동작이 없으므로 사용자에게 에러 상황을 알려주지 않았음
				console.error(error);
			}
		},
		clickNotiList(where) {
			// GTM - clickNotiList
			const args = {
				user_no: this.userNo,
				user_type: this.userType,
				email: this.userId,
				event: 'clickNotiList',
			};
			if (where) args.where = where;
			this.$_gtm(args);
		},
	},
	components: {
		NotiAdBanner,
		NotiContent,
		NotiSideDrawer,
		NotiFullscreenModal,
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../scss/base/main';

.ad-banner-section {
	padding: 16px;
	background: $gray100;
}
</style>
