export const BASE_URL = 'https://comento.kr';
export const NUXT_LOCAL_BASE_URL = 'http://localhost.comento.kr:8000';
export const NEXT_LOCAL_BASE_URL = 'http://localhost.comento.kr:3002';
export const QA_BASE_URL = 'https://qa.comenthor.com';

export const CASE_PATH = '/job-questions';
export const ANSWER_PATH = `/answer`;
export const LOUNGE_PATH = `/community/lounge`;
export const INSIGHT_PATH = `/community/insight`;
export const CAREER_PATH = `/career`;

export const MY_PAGE_PATH = `/mypage`;
export const MY_PAGE_COMMUNITY_PATH = `${MY_PAGE_PATH}/community`;
export const MY_PAGE_CLASSROOM_PATH = `${MY_PAGE_PATH}/classroom`;
export const MY_PAGE_CLASSROOM_OPENED_PATH = `${MY_PAGE_CLASSROOM_PATH}/opened`;
export const MY_PAGE_CLASSROOM_APPLIED_PATH = `${MY_PAGE_CLASSROOM_PATH}/applied`;
export const MY_PAGE_CLASS_OPEN_PATH = `${MY_PAGE_PATH}/class-open`;
export const MY_PAGE_ESSAY_PATH = `${MY_PAGE_PATH}/essay`;
export const MY_PAGE_CREDIT_SHOP_PATH = `${MY_PAGE_PATH}/shop/list`;
export const RESUME_INDEX_PATH = `/resume/begin`;

export const EDU_ALUMNI_PATH = `/edu/alumni`;

export const CLASS_PT_PATH = `/class/pt`;
export const CLASS_PT_LIST_PATH = `${CLASS_PT_PATH}/list`;

export const CLASS_PT_PATH_PREFIX = {
	OLD_DETAIL: '/class/pt/contents',
	DETAIL: '/class/pt/content',
};

export const EXTERNSHIP_PATH = `${CAREER_PATH}/externship`;

export const CLASS_VOD_PATH = `/class/vod`;
export const CLASS_VOD_LIST_PATH = `${CLASS_VOD_PATH}/list`;
export const CLASS_VOD_DETAIL_PATH = `${CLASS_VOD_PATH}/detail`;
export const CLASS_VOD_DETAIL_SAMPLE_PATH = `${CLASS_VOD_PATH}/detail/sample`;

export const CLASS_ON_AIR_PATH = `/class/on-air`;
export const CLASS_ON_AIR_LIST_PATH = `${CLASS_ON_AIR_PATH}/list`;
export const CLASS_ON_AIR_DETAIL_PATH = `${CLASS_ON_AIR_PATH}/detail`;

export const NOTICE_PATH = `/notice`;

export const CDN_BASE_URL = 'https://cdn.comento.kr';

export const VOD_PATH = '/vod';

export const BOOKMARK_PATH = '/bookmark';
export const COMMUNITY_BOOKMARK_PATH = `${BOOKMARK_PATH}/community`;

export const COUPON_BOOK_PATH = '/coupon/book';

export const SEARCH_PATH = '/search';
