<template>
	<div class="flex c-pointer" @click="handleClickNotiAdBanner">
		<img height="72" :width="isMobile ? 343 : 327" :src="imageSrc" alt="" />
	</div>
</template>

<script>
// props userInfo는 gtmCommunityAdMixin에서 사용
import layoutMixin from '@/mixins/layoutMixin';
import { GTM_COMMUNITY_AD_PARAMETERS } from '@/constants/gtm';
import { cdnImageBaseUrl } from '@/constants';
import { getRoutePath } from '@/utils/util';
import gtmCommunityAdMixin from '@/mixins/gtm/gtmCommunityAdMixin';
export default {
	name: 'NotiAdBanner',
	mixins: [
		layoutMixin,
		gtmCommunityAdMixin({
			where: GTM_COMMUNITY_AD_PARAMETERS.WHERE.NOTI_LIST,
			order: 1,
			type: GTM_COMMUNITY_AD_PARAMETERS.TYPE.BASIC,
		}),
	],
	computed: {
		imageSrc() {
			return `${cdnImageBaseUrl}/community/ad/noti-list-ad-banner-test.png?date=241211`;
		},
	},
	methods: {
		moveToAdPage() {
			window.location.href = getRoutePath(
				'/kdigital?utm_source=organic-internal&utm_medium=ad_baner&utm_campaign=내부알림-상단배너&utm_term=onboardEDULanding_KDC&utm_content=241210',
				'next',
			);
		},
		handleClickNotiAdBanner() {
			this.$_gtmCommunityAdMixin_triggerGTMClickAdBanner();
			this.$emit('click-noti-ad-banner');
			this.moveToAdPage();
		},
	},
};
</script>

<style scoped></style>
