<template>
	<c-new-col
		id="couponIndex"
		v-scroll="MobileScroll"
		class="couponIndex pb-100"
		:class="{ 'mt-20': isMobile }"
		no-gutters
	>
		<c-grid>
			<c-row>
				<!--banner-->
				<c-style-col :col-sm="12">
					<coupon-banner
						main-title="코멘토 혜택 안내"
						sub-title="코멘토가 제공하는 실시간 혜택을 놓치지 마세요."
					/>
				</c-style-col>
				<!--nav-->
				<c-style-col :col-sm="12">
					<nav ref="couponNav" class="couponIndex_nav">
						<ul class="clearfix">
							<li
								v-for="(nav, index) in couponTab"
								:key="`nav-${index}`"
								class="pointer"
								:class="nav.category === selectTab ? 'active' : ''"
								@click="setRouter(nav.title, nav.category)"
							>
								{{ nav.title }}
							</li>
						</ul>
					</nav>

					<ValidationObserver ref="validObserver">
						<form id="couponIndexForm" class="couponIndex_form" @submit.prevent="clickRegister">
							<div
								class="couponIndex_form_input float-left"
								:class="[
									{ couponIndex_form_input_active: couponInputActive },
									{ error: couponFormError },
								]"
							>
								<c-icon
									v-if="isMobile"
									name="IconCloseSmallLine"
									color="gray400"
									class="float-left"
									@click="couponInputActive = false"
								></c-icon>
								<ValidationProvider name="쿠폰 코드" rules="required" class="form_input">
									<c-text-field
										:value.sync="couponCode"
										placeholder="쿠폰 코드를 입력하세요"
										:error="couponFormError"
										@keyup.enter="clickRegister"
									/>
								</ValidationProvider>
							</div>
							<c-button
								type="outline"
								color="primary"
								form="couponIndexForm"
								class="float-right couponIndex_form_btn ml-16"
							>
								{{ isMobile ? '쿠폰 등록' : '등록' }}
							</c-button>
						</form>
					</ValidationObserver>
				</c-style-col>
				<!--list-->
				<c-style-col :col-sm="12">
					<section class="couponList">
						<!--skeleton-->
						<c-row v-if="skeletonShow">
							<c-style-col
								v-for="(list, index) in isMobile ? 3 : 6"
								:key="`list-${index}`"
								:colSm="12"
								:colLg="4"
							>
								<base-skeleton
									width="100%"
									height="182px"
									style="border-radius: 12px"
									:style="{ marginBottom: isMobile ? '20px' : '32px' }"
								/>
							</c-style-col>
						</c-row>
						<c-row v-else>
							<c-style-col
								v-for="(list, index) in couponList"
								:key="`list-${index}`"
								:col-sm="12"
								:col-lg="4"
							>
								<CouponTicket
									:coupon-info="list"
									:category="selectTab"
									@download-coupon="downloadCoupon(...arguments)"
								/>
							</c-style-col>

							<c-style-col v-if="hasNoCoupon" :col-sm="12">
								<c-empty-box
									type="emphasized"
									:external="!isUser"
									:to="getNoCouponCTALink"
									:class="isMobile ? 'mb-40' : 'mb-60'"
								>
									<template v-slot:title> 보유 중인 쿠폰이 없습니다. </template>
									<template v-slot:contents>
										아래를 눌러 즉시 적용 가능한 혜택을 확인해보세요!
									</template>
									<template v-slot:link> 적용 가능한 혜택 확인하기 </template>
								</c-empty-box>
							</c-style-col>
						</c-row>

						<!-- 하단 수업 섹션 -->
						<c-row>
							<c-style-col :col-sm="12">
								<!-- 직무부트캠프 -->
								<EduStartsInTwoWeeksList />
							</c-style-col>
						</c-row>
					</section>
				</c-style-col>
			</c-row>
		</c-grid>

		<c-permission-modal :show.sync="showPermissionModal" :success-callback="downloadCoupon">
			<template v-slot:title>
				<strong>{{ modalTitle }}</strong>
			</template>
			<template v-slot:content>
				<c-typography v-purify-html="modalContent" type="body2" color="gray700" element="p" align="center" />
			</template>
		</c-permission-modal>

		<c-basic-modal
			:show.sync="showBasicModal"
			show-action-button
			:success-callback="goAnalytics"
			:success-message="modalSuccessMessage"
			full
		>
			<template v-slot:title>
				{{ modalTitle }}
			</template>
			<template v-slot:content>
				<div v-html="modalContent" />
			</template>
		</c-basic-modal>

		<join-ad-popup-component v-if="showJoinAdPopup" :page="'coupon/' + selectTab" />
	</c-new-col>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CouponBanner from '../../components/coupon/CouponBanner.vue';
import CouponTicket from '../../components/coupon/CouponTicket.vue';
import JoinAdPopupComponent from '../../components/_common/JoinAdPopupComponent.vue';
import { datadogLogs } from '@datadog/browser-logs';
import { getRoutePath } from '../../utils/util';
import { CLASS_PT_LIST_PATH, COUPON_BOOK_PATH } from '../../constants/paths';
import EduStartsInTwoWeeksList from '../../components/coupon/EduStartsInTwoWeeksList.vue';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

export default {
	name: 'CouponIndexView',
	data: () => ({
		skeletonShow: true,
		couponList: null,
		couponTab: [
			{ title: '쿠폰북', category: 'book' },
			{ title: '내 쿠폰', category: 'mine' },
		],
		selectTab: 'book',
		couponCode: '',
		couponFormError: false,
		couponInputActive: false,
		showBasicModal: false,
		showPermissionModal: false,
		modalTitle: '',
		modalContent: '',
		modalSuccessMessage: '',
		couponDownloadInfo: {}, // 다운로드한 쿠폰 정보
		showJoinAdPopup: false,
	}),
	computed: {
		...mapGetters(['sessionInfo', 'sessionInfoExist', 'isUser', 'userNo', 'userType', 'userId']),
		hasNoCoupon() {
			if (!this.isUser) return true;
			return this.couponList?.length === 0 && this.selectTab === 'mine';
		},
		getNoCouponCTALink() {
			if (!this.isUser) {
				return `/login?redirect_url=${COUPON_BOOK_PATH}`;
			}
			return COUPON_BOOK_PATH;
		},
		isNovember() {
			return dayjs().isBefore(dayjs('2022-12-01'));
		},
	},
	watch: {
		$route: 'setInit',
	},
	mounted() {
		this.setInit();
		this.handleGtm();
		this.setMeta({
			title: '쿠폰',
			isDetail: true,
			isLogoTopBar: true,
			isShowFooter: true,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		setInit() {
			this.selectTab = this.$route.params.category;
			const tabItem = this.couponTab.filter(tab => tab.category === this.selectTab)[0];
			if (this.isUser) {
				this.getCouponList(tabItem.title, this.selectTab);
			} else {
				this.skeletonShow = false;
			}
			this.showJoinAdPopup = false;
			document.getElementsByClassName('custom-feed-blinder')[0].style.display = 'none';
		},
		handleGtm() {
			// GTM - onboardCouponBook
			onboardCouponBook(this.userNo, this.userType, this.userId, this.comentoLocalStorageId);
		},
		getCouponList(title, category) {
			/*쿠폰 리스트 가져오기*/
			this.skeletonShow = true;

			axios({
				method: 'post',
				url: category === 'book' ? `/api/coupon/list` : `/api/coupon/list/mine`,
			})
				.then(({ data }) => {
					// console.log(data);this.selectTab = category;

					this.couponList = data.list;
					this.skeletonShow = false;
					this.selectTab = category;

					if (category === 'mine') {
						// 카카오 알림톡으로 진입할 경우 utm 저장
						const { utm_source, utm_campaign, utm_term } = this.$_getUrlParams();
						const userAgent = window.navigator.userAgent;
						const isKakao = userAgent.indexOf('kakaotalk');

						const isKakaoNewCouponUser =
							utm_source === 'crm-kakao' &&
							utm_campaign === '신규가입안내_2' &&
							utm_term === '쿠폰 확인하기';

						sessionStorage.setItem('isKakaoNewCouponUser', isKakaoNewCouponUser);

						if (!this.sessionInfo && isKakaoNewCouponUser && isKakao === 0) {
							window.location.href = '/logout';
						} else {
							onboardMyCoupon(this.userNo, this.userType, this.userId, this.comentoLocalStorageId);
						}
					}
				})
				.catch(error => {
					console.log(error);
					datadogLogs.logger.log('쿠폰 리스트 가져오기 실패', { err: error, userNo: this.userNo }, 'debug');
				});
		},
		setRouter(title, category) {
			/*쿠폰북 / 내쿠폰 router 설정*/
			const currentPath = this.$route.path;
			const targetPath = `/coupon/${category}`;
			if (currentPath !== targetPath) {
				this.$router.push({
					name: 'coupon',
					path: targetPath,
					title: title + ' | 코멘토',
					params: { category: category },
				});
			}
		},
		clickRegister() {
			if (this.sessionInfo === null) {
				this.showJoinAdPopup = true;
			} else {
				if (this.isMobile) {
					if (!this.couponInputActive) {
						this.couponInputActive = true;
					} else {
						if (this.couponCode.trim().length > 0) {
							this.checkCode();
						} else {
							this.handleValidCheckCode();
						}
					}
				} else {
					if (this.couponCode.trim().length > 0) {
						this.checkCode();
					} else {
						this.handleValidCheckCode();
					}
				}
			}
		},
		handleValidCheckCode() {
			Toast.show({
				message: '쿠폰 코드를 입력하세요',
				type: 'error',
				position: 'top',
			});
			this.couponFormError = true;
			setTimeout(() => {
				this.couponFormError = false;
			}, 3000);
		},
		checkCode() {
			const data = {
				coupon_code: this.couponCode,
			};
			axios
				.post(`/api/coupon/check/code`, data)
				.then(({ data }) => {
					// console.log(data);
					if (data.status === 'success') {
						const couponAmountText =
							data.coupon.coupon_type_amount.toLocaleString() +
							(data.coupon.coupon_type === 1 ? '원' : '%');

						this.couponFormError = false;
						this.modalTitle = '쿠폰을 등록할까요?';
						this.modalContent = `
							<div class="text-truncate">
								${data.coupon.coupon_name}
							</div>
							${couponAmountText} 할인 쿠폰이 등록됩니다.`;
						this.couponDownloadInfo = data.coupon;
						this.couponDownloadInfo.where = 'couponCode';
						this.showPermissionModal = true;
					} else {
						if (data.status === 'reg-limit' || data.status === 'expired') {
							Toast.show({
								message: '등록 기한이 지난 쿠폰입니다',
								type: 'error',
								position: this.isMobile ? 'bottom' : 'top',
							});
						} else if (data.status === 'ai') {
							this.modalTitle = '등록할 수 없는 쿠폰입니다';
							this.modalContent = `
                <div>AI 자기소개서 분석기 쿠폰은 <strong>분석 결과보기 화면에서 등록하여 사용</strong>할 수 있습니다.</div>
                <div>아래 버튼을 눌러 AI 분석기로 이동하세요.</div>`;
							this.modalSuccessMessage = '이동하기';
							this.showBasicModal = true;
						} else if (data.status === 'already') {
							Toast.show({
								message: '이미 등록된 쿠폰입니다',
								type: 'error',
								position: this.isMobile ? 'bottom' : 'top',
							});
						} else if (data.status === 'error') {
							Toast.show({
								message: '시스템 에러, 고객센터로 문의해주세요',
								type: 'error',
								position: this.isMobile ? 'bottom' : 'top',
							});
						} else if (data.status === 'limit' || data.status === 'notactive') {
							Toast.show({
								message: '사용할 수 없는 쿠폰입니다',
								type: 'error',
								position: this.isMobile ? 'bottom' : 'top',
							});
						} else {
							Toast.show({
								message: '잘못된 쿠폰 코드입니다.',
								type: 'error',
								position: this.isMobile ? 'bottom' : 'top',
							});
						}
						this.couponFormError = true;

						setTimeout(() => {
							this.couponFormError = false;
						}, 3000);
					}
				})
				.catch(error => {
					console.log(error);
					datadogLogs.logger.log(
						'쿠폰정보확인 실패',
						{ coupon_code: data, err: error, userNo: this.userNo },
						'debug',
					);
				});
		},
		downloadCoupon: debounce(function ({ id, apply, where } = {}) {
			/*쿠폰 등록하기*/
			try {
				showLoading();
				if (this.sessionInfo === null) {
					this.showJoinAdPopup = true;
				} else {
					const isCouponBook = where === 'couponBook';
					const url = isCouponBook ? '/api/coupon/download' : '/api/coupon/download/code';
					const data = isCouponBook ? { coupon_id: id } : { coupon_code: this.couponCode };

					axios
						.post(url, data)
						.then(({ data }) => {
							// console.log(data);
							const couponApplyItem = {
								1: 'all', // 모든 상품
								2: 'edu', // 직무부트캠프
								3: 'contents', // 열람권
								4: 'vod', // vod
								7: 'CBC', // 실무PT
							};
							const couponApply = apply || this.couponDownloadInfo.coupon_apply;
							const couponApplyName = couponApplyItem[couponApply];
							const couponId = id || this.couponDownloadInfo.id;
							const couponWhere = where || this.couponDownloadInfo.where;

							// GTM - downloadCoupon
							downloadCoupon(
								this.userNo,
								this.userType,
								this.userId,
								couponId,
								couponApplyName,
								couponWhere,
							);
							this.handleEduCouponGTM();

							this.couponList.forEach((item, index) => {
								if (item.id === couponId) {
									this.couponList[index].user_downloaded = 1;
								}
							});

							this.couponFormError = false;
							this.showPermissionModal = false;
							if ([36, 37].includes(id)) {
								Toast.show({
									message: '쿠폰 받기 완료!',
									timeout: 4000,
									position: this.isMobile ? 'bottom' : 'top',
									clickEvent: () => (location.href = getRoutePath(CLASS_PT_LIST_PATH, 'nuxt')),
								});
							} else {
								Toast.show({
									message: '쿠폰이 다운로드 되었습니다',
									type: 'basic',
									position: this.isMobile ? 'bottom' : 'top',
								});
							}
							this.couponCode = '';
							if (this.selectTab === 'mine') {
								this.getCouponList('내 쿠폰', 'mine');
							}
							return data;
						})
						.catch(error => {
							console.error(error);
							Toast.show({
								message: '다운로드에 실패했습니다',
								type: 'error',
								position: this.isMobile ? 'bottom' : 'top',
							});
							datadogLogs.logger.log(
								'쿠폰 다운로드에 실패',
								{
									couponId: id,
									couponApply: apply,
									couponWhere: where,
									err: error,
									userNo: this.userNo,
								},
								'debug',
							);
						});
				}
			} catch (error) {
				console.error('downloadCoupon error', error);
			} finally {
				closeLoading();
			}
		}, 300),
		handleEduCouponGTM() {
			const affEduPartner = this.couponDownloadInfo.aff_edu_partner;
			if (affEduPartner) {
				const eduPartnerName = affEduPartner.name;
				const hasAffEduPartner = typeof affEduPartner === 'object';
				const isB2BEduCoupon = this.couponDownloadInfo && hasAffEduPartner;

				if (isB2BEduCoupon) {
					// GTM - registerB2BEduCoupon
					registerB2BEduCoupon(this.userNo, this.userType, this.userId, eduPartnerName);
				}
			}
		},
		MobileScroll() {
			if (this.isMobile) {
				const scrollY = window.pageYOffset;
				const branch = this.$refs.couponNav.offsetHeight * 2;
				if (scrollY > branch) {
					this.couponInputActive = false;
				}
			} else {
				return false;
			}
		},
		goAnalytics() {
			window.location.href = '/analytics';
		},
	},
	components: { EduStartsInTwoWeeksList, JoinAdPopupComponent, CouponTicket, CouponBanner },
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';

.couponIndex {
	&_nav {
		margin-bottom: 24px;
		float: left;
		li {
			float: left;
			font-size: 22px;
			line-height: 135%;
			font-weight: bold;
			color: $gray300;
			padding-bottom: 4px;
			&.active {
				color: $gray900;
				border-bottom: 2px solid $gray900;
			}
			+ li {
				margin-left: 16px;
			}
		}
	}
	&_form {
		float: right;
		&_input {
			position: absolute;
			opacity: 0;
			top: 0;
			left: calc(100% - 130px);
			width: calc(100% - 130px);
			z-index: -999;
			animation: fade-out 0.4s;
			animation-fill-mode: forwards;

			@include pc {
				width: auto;
			}
			&_active {
				display: block;
				background: $white;
				left: 15px;
				z-index: 1;
				animation: fade-in 0.25s;
				animation-fill-mode: forwards;
			}
			.c-icon {
				height: 36px;
				padding-right: 12px;
				box-sizing: content-box;
			}
			> .form_input {
				float: left;
				width: calc(100% - 38px);
				@include pc {
					width: 100%;
				}
			}
		}
	}

	@include pc {
		&_form {
			&_input {
				position: relative;
				display: block;
				left: 0;
				width: 212px;
				opacity: 1;
				z-index: 0;
				animation: none;
				animation-fill-mode: none;
				> div {
					float: left;
					width: 100%;
				}
			}
		}
		&::v-deep .swiper-pagination-bullet-active {
			background-color: #495057 !important;
		}
	}
}
@keyframes fade-in {
	from {
		left: calc(100% - 130px);
		opacity: 0;
	}
	to {
		left: 15px;
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		left: 15px;
		opacity: 1;
	}
	to {
		left: calc(100% - 130px);
		opacity: 0;
	}
}
</style>
