<template>
	<section class="section text-center position-relative bg-gray">
		<div class="container text-center">
			<biz-login-header-component msg="AI가 찾아주는 인재매칭 서비스"></biz-login-header-component>
			<div class="inline-block login-box bg-white">
				<div class="font-login-title f-semi-bold mb-10">채용관리자 로그인</div>
				<input
					v-model="compEmail"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="text"
					placeholder="이메일주소"
					name="compEmail"
					@keydown.enter="login"
				/>
				<input
					v-model="compPw"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="password"
					placeholder="비밀번호"
					name="compPw"
					@keydown.enter="login"
				/>

				<div v-if="isWarn" class="text-left f-red mb-10">{{ warn }}</div>

				<button class="btn btn-full f-light" @click="login">로그인</button>

				<div class="border-bottom mb-20 mt-20"></div>

				<div class="text-box mb-20 mt-20">
					<div class="clear-both">
						<span class="float-left font f-semi-bold">회원이 아니세요?</span>
						<span class="text-link float-right font" onclick="location.href='/register'"
							>채용관리자 가입</span
						>
					</div>
					<div class="clear-both">
						<span class="float-left font f-semi-bold pt-10">비밀번호를 잊으셨나요?</span>
						<span class="text-link float-right font pt-10" onclick="location.href='/user/pw/find'"
							>비밀번호 찾기</span
						>
					</div>
				</div>
			</div>
		</div>
		<biz-footer-component></biz-footer-component>
	</section>
</template>

<script>
export default {
	data() {
		return {
			compEmail: '',
			compPw: '',
			isWarn: false,
			warn: '',
		};
	},
	methods: {
		login() {
			showLoading();
			axios({
				method: 'POST',
				url: 'api/user/login',
				data: {
					email: this.compEmail,
					pw: this.compPw,
				},
			})
				.then(response => {
					/*
                    code 0 : 아이디&비밀번호 X
                    code 502 : 아이디 O, 비밀번호 X
                   */
					if (parseInt(response.data.code) === 200) {
						this.isWarn = false;
						window.location.href = '/dashboard';
					} else {
						this.isWarn = true;
						this.warn = '아이디 혹은 비밀번호가 틀렸습니다!';
					}
					closeLoading();
				})
				.catch(error => {
					closeLoading();
					//console.log(error);
				});
		},
	},
};
</script>
