<template>
	<div v-aos class="width-100">
		<section class="bg-landing" style="min-height: 555px">
			<div class="landing-img">
				<div class="container landing-layout landing-content">
					<!--<div class="pb-50 landing-title-big landing-message aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-duration="400">-->
					<!--<div class="display white">AI가 찾아주는</div>-->
					<!--<div class="display noto-bold white">딱 맞는 <br class="only-mobile">신입, 인턴 채용</div>-->
					<!--</div>-->
					<div
						class="pb-50 landing-title-big"
						data-aos="fade-up"
						data-aos-easing="linear"
						data-aos-anchor-placement="top-bottom"
						data-aos-duration="400"
					>
						<div class="text-white f-regular">AI가 찾아주는</div>
						<div class="text-white f-semi-bold">딱 맞는 <br class="only-mobile" />신입, 인턴 채용</div>
					</div>
					<input
						v-model="comEmail"
						type="text"
						class="inputbox inputbox-comp"
						placeholder="업무용 이메일을 입력하세요"
					/>
					<button class="btn btn-lg com-btn-free" @click="trialEmail">30일 무료체험신청</button>
				</div>
			</div>
			<div class="text-center partner-section">
				<div class="container landing-layout">
					<div
						class="landing-font-title f-semi-bold f-black mb-30 only-mobile"
						data-aos="fade-up"
						data-aos-duration="1300"
					>
						<!-- 모든 이용자 숫자 가져오기 -->
						우수한 기업들은 이미<br />코멘토로 채용중입니다.
					</div>
					<div class="mt-30 mb-30 pt-50 pb-50 bg-white">
						<div
							class="landing-font-title f-semi-bold f-black mb-30 only-pc"
							data-aos="fade-up"
							data-aos-duration="1300"
						>
							우수한 기업들은 이미<br />코멘토로 채용하고 있습니다.
						</div>
						<div>
							<img src="/images/company/partners_logo_pc.png" class="only-pc" />
							<img src="/images/company/partners_logo_m.png" class="only-mobile" />
						</div>
					</div>
				</div>
				<div>
					<button class="btn btn-empty">
						<a class="text-caption" href="https://brunch.co.kr/magazine/customers" target="_blank">
							고객사례 보기<img src="/images/company/intro-arrow.svg" class="intro-arrow" />
						</a>
					</button>
				</div>
			</div>
		</section>
		<section class="text-center pt-100">
			<div class="bg-white">
				<div class="landing-font-title f-semi-bold f-black" data-aos="fade-up" data-aos-duration="1300">
					<!-- 모든 이용자 숫자 가져오기 -->
					<span>멘토링 플랫폼을 통해 </span><br class="only-mobile" />
					<span
					>{{ menteeCount | numberFormat }}명<br />우수한 구직자 풀<span class="only-pc"
					>을 보유하고 있습니다</span
					><span class="only-mobile"> 보유</span></span
					>
				</div>
				<div>
					<button class="btn btn-empty mt-30 mb-20">
						<a class="text-caption" href="https://comento.kr" target="_blank">
							코멘토 서비스 보기 <img src="/images/company/intro-arrow.svg" class="intro-arrow" />
						</a>
					</button>
				</div>
				<div>
					<img src="/images/company/mockup_bg_pc.jpg" class="only-pc vertical-top" />
					<img src="/images/company/mockup_bg_m.jpg" class="only-mobile vertical-top" />
				</div>
			</div>
		</section>
		<section class="bg-landing pt-100 pb-100 text-center" style="min-height: 555px">
			<div class="container landing-layout">
				<div class="landing-font-title f-black f-semi-bold" data-aos="fade-up" data-aos-duration="1300">
					<span class="only-pc">AI 기술로 추가 업무부담 없이<br />딱 맞는 인재를 찾아드립니다.</span>
					<span class="only-mobile">AI 기술로<br />추가 업무부담 없이<br />딱 맞는 인재 채용</span>
				</div>
				<div class="grid width-100 mt-35">
					<div class="grid-one text-center landing-divide-first">
						<div>
							<img src="/images/company/icon_ad.svg" class="img-landing" />
							<div class="landing-title landing-title-content">
								AI가 적합한 인재를 찾아<br />자동으로 타겟 채용광고 송출
							</div>
						</div>
						<div class="text-left only-pc">
							<div class="font-title text-left">코신입님을 원하는 기업이 있습니다.</div>
							<img class="text-left" src="/images/company/recruit_ad.png" />
							<p class="h7 text-caption">* 홈페이지 맞춤공고, 이메일, 문자메세지 등으로 송출됩니다.</p>
						</div>
					</div>
					<div class="grid-two text-center landing-divide-second">
						<div>
							<img src="/images/company/icon_search.svg" class="img-landing" />
							<div class="landing-title landing-title-content">
								AI가 적합한 인재를<br />검색하여 자동으로 추천
							</div>
						</div>
						<div class="text-left only-pc">
							<div class="font-title text-left">AI 인재검색 결과 : 12명</div>
							<img class="text-left" src="/images/company/recruit_search.png" />
						</div>
					</div>
				</div>
				<div>
					<button class="btn btn-empty mt-30 mb-20">
						<a class="text-caption" href="https://comento.kr/jobs" target="_blank">
							채용공고 보기 <img src="/images/company/intro-arrow.svg" class="intro-arrow" />
						</a>
					</button>
				</div>
			</div>
		</section>
		<section class="bg-white pt-100 pb-100 text-center" style="min-height: 555px">
			<div class="landing-font-title f-black f-semi-bold mb-30" data-aos="fade-up" data-aos-duration="1300">
				제휴대학 포함 <br class="only-mobile" />전국 200여개 대학에<br />공고 등록 공문<span class="only-pc"
				>을 자동 발송합니다.</span
				>
				<span class="only-mobile"> 자동 발송</span>
			</div>
			<div>
				<img src="/images/company/univ_logo_pc.png" class="only-pc" />
				<img src="/images/company/univ_logo_m.png" class="only-mobile" />
			</div>
		</section>
		<section class="bg-landing pt-100 pb-100 text-center margin" style="min-height: 555px">
			<div class="container landing-layout">
				<div class="landing-font-title f-black f-semi-bold" data-aos="fade-up" data-aos-duration="1300">
					합리적인 가격<span class="only-pc">에 </span><span class="only-mobile">으로<br /></span> 우수
					신입인재를<br />채용할 수 있습니다.
				</div>
				<div class="com-pay-box">
					<div class="font f-black">지원서 개수, 채용인원에 관계없이 공고 당</div>
					<div>
						<span class="landing-font-title f-semi-bold f-black">50,000원</span
						><span class="font-big-sub f-semi-bold f-black"> / 30일</span>
					</div>
					<div class="text-caption font mt-20">제공 혜택</div>
					<div class="border benefit grid">
						<div class="grid-one">
							AI 타겟 광고<br />
							AI 검색인재 추천<br />
							전국 대학교 공고등록 공문<br />
							제휴서비스 공고등록
						</div>
						<div class="grid-two">
							채용 대시보드<br />
							자동 불합격 통보<br />
							면접일정 관리 시스템<br />
							지원자 공동관리 시스템
						</div>
					</div>
				</div>
				<div class="btn btn-lg com-btn" onclick="location.href='/register'">30일 무료체험 신청</div>
			</div>
		</section>
		<biz-footer-component style="display: block"></biz-footer-component>
	</div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
	props: ['menteeCount'],
	data() {
		return {
			comEmail: '',
		};
	},
	created() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
	methods: {
		trial() {
			window.open('/register', '_blank');
		},
		trialEmail() {
			if (this.comEmail === '') {
				alert('업무용 이메일을 입력해 주세요.');
				return false;
			} else {
				window.open('/register?email=' + this.comEmail, '_blank');
			}
		},
	},
};
</script>

<style lang="less" scoped>
.f-black {
	color: #212529;
}
.margin {
	margin-bottom: 200px;
	@media (max-width: 480px) {
		margin-bottom: 0;
	}
}
</style>
