<template>
	<modal-component :show="show" @close="close">
		<div class="modal-body text-left">
			<ValidationObserver ref="validObserver">
				<form @submit.prevent="getBrunchContents">
					<FormInput
						id="edu-adm-campName"
						ref="campName"
						rules="required|url"
						:value.sync="brunchURL"
						label="brunch URL"
						placeholder="https://brunch.co.kr/@comento/number"
						type="url"
						width="100%"
					/>
					<button type="submit" class="btn btn-full mt-10" value="불러오기">불러오기</button>
				</form>
			</ValidationObserver>
		</div>
	</modal-component>
</template>

<script>
import FormInput from '../../common/form/FormInput.vue';

export default {
	name: 'BlogBrunchImportModal',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		brunchURL: null,
	}),
	mounted() {
		document.addEventListener('keydown', e => {
			if (this.show && e.keyCode == 27) {
				this.close();
			}
		});
	},
	methods: {
		close() {
			if (typeof this.mask === undefined || !this.mask) {
				this.$emit('close');
			}
		},
		getBrunchContents() {
			axios({
				method: 'GET',
				url: `https://mlqmi331k9.execute-api.ap-northeast-2.amazonaws.com/20200612/get?url=${this.brunchURL}`,
			})
				.then(response => {
					const text = response.data.text;
					this.close();
					this.$emit('update:contents', text);
					// console.log(response);
				})
				.catch(error => {
					console.log(error);
					alert('오류 발생 :(');
				});
		},
	},
	components: {
		FormInput,
	},
};
</script>

<style scoped></style>
