// state
export const SPINNER_OFFSET_Y = 'SPINNER_OFFSET_Y';

// getters
export const GET_SPINNER_OFFSET_Y = 'GET_SPINNER_OFFSET_Y';

// mutations
export const SET_SPINNER_OFFSET_Y = 'SET_SPINNER_OFFSET_Y';

const pullToRefreshStore = {
	namespaced: true,
	state: {
		[SPINNER_OFFSET_Y]: 0,
	},
	getters: {
		[GET_SPINNER_OFFSET_Y]: state => {
			return state[SPINNER_OFFSET_Y];
		},
	},
	mutations: {
		[SET_SPINNER_OFFSET_Y]: (state, payload) => {
			state[SPINNER_OFFSET_Y] = payload;
		},
	},
};

export default pullToRefreshStore;
