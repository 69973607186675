import Vue from 'vue';
import VueShepherd from 'vue-shepherd';
import { cdnBaseUrl } from '../../constants';

Vue.use(VueShepherd);

const newbieMentorTutorialMixin = {
	props: ['userNo', 'userType', 'userId'],
	data() {
		return {
			isNewbieMentor: false,
		};
	},
	async mounted() {
		await this.$_newbieMentorTutorialMixin_checkNewChangeUser();
		this.$nextTick(() => {
			if (this.$_newbieMentorTutorialMixin_shouldInitTour) {
				this.$_newbieMentorTutorialMixin_initTour();
			}
		});
	},
	beforeDestroy() {
		this.$_newbieMentorTutorialMixin_allowBodyScroll();
	},
	computed: {
		// 튜토리얼을 표시할지
		// PC에서만 튜토리얼을 보여준다.
		// 또한, 이미 튜토리얼을 본 유저는 다시 보여주지 않는다.
		// 신규 멘토(최초 인증한지 1주일 이내라고 가정)인 경우에만 튜토리얼을 보여준다.
		// 튜토리얼 전용 더미 질문에서만 보여준다.
		$_newbieMentorTutorialMixin_shouldInitTour() {
			const regex = /\/answer\/question\/([^/]+)/;
			const match = window.location.pathname.match(regex);
			const isTutorialRequest = match[1] === 'NE8zL5BxCgqAgPSxXUBZ1Q==';
			return (
				this.isNewbieMentor &&
				isTutorialRequest &&
				this.$_getSessionStorage('NEWBIE_MENTOR_MENTORING_TUTORIAL_STEP_1_COMPLETE')
			);
		},
	},
	methods: {
		$_newbieMentorTutorialMixin_blockBodyScroll() {
			document.querySelector('html').classList.add('not-scroll');
		},
		$_newbieMentorTutorialMixin_allowBodyScroll() {
			document.querySelector('html').classList.add('not-scroll');
		},
		$_newbieMentorTutorialMixin_addRemoveBodyScrollBlockListener(tour) {
			['complete', 'cancel'].forEach(event => {
				tour.on(event, () => {
					document.querySelector('html').classList.remove('not-scroll');
				});
			});
			tour.on('complete', () => {
				this.$_setLocalStorage('NEWBIE_MENTOR_TUTORIAL_DONE', true);
			});
		},
		$_newbieMentorTutorialMixin_initTour() {
			this.$nextTick(() => {
				const step2PopperOptions = {
					modifiers: [{ name: 'offset', options: { offset: [0, this.isMobile ? 8 : 24] } }],
				};
				const tour = this.$shepherd({
					useModalOverlay: true,
				});
				tour.addStep({
					id: 'step1',
					title: `<img src='${this.$_newbieMentorTutorialMixin_getCdnEmojiUrl(
						'right-pointing-magnifying-glass',
					)}' class='mr-4'/> 질문 내용 꼼꼼히 읽어보기`,
					text: `멘티가 고민하는 부분이 무엇인지 정확하게 파악하면,${
						this.isMobile ? '' : '<br />'
					}도움이 되는 답변을 쓰기가 쉬워져요.`,
					attachTo: {
						element: '#answer-detail-question',
						on: 'bottom',
					},
					canClickTarget: false,
					popperOptions: {
						modifiers: [
							{
								name: 'offset',
								options: { offset: [0, this.isMobile ? 6 : 24] },
							},
						],
					},
					when: {
						show: () => {
							mentorTutorial4({ user_no: this.userNo, user_type: this.userType, email: this.userId });
						},
					},
					buttons: [
						{
							text: '다음',
							action: () => {
								tour.next();
								if (this.isMobile) {
									this.scrollTo('FeedBack');
								}
							},
						},
					],
					highlightClass: 'shepherd-target-highlight',
				});
				tour.addStep({
					id: 'step2',
					title: `<img src='${this.$_newbieMentorTutorialMixin_getCdnEmojiUrl(
						'pencil',
					)}' class='mr-4'/> 구체적인 답변 작성하기`,
					text: '멘토님만의 지식과 경험, 노하우를 담은 답변을 남겨주세요.<br/>내용이 구체적이고 상세할수록 채택될 확률이 높아져요.',
					attachTo: {
						element: this.isMobile ? '.expUserTextarea' : '.my-answer-box',
						on: this.isMobile ? 'bottom' : 'top-start',
					},
					canClickTarget: false,
					popperOptions: step2PopperOptions,
					when: {
						show: () => {
							mentorTutorial5({ user_no: this.userNo, user_type: this.userType, email: this.userId });
						},
					},
					buttons: [
						{
							text: '답변 작성하기',
							action() {
								tour.next();
							},
						},
					],
					highlightClass: this.isMobile ? '' : 'shepherd-target-highlight',
				});
				tour.start();
				this.$_newbieMentorTutorialMixin_addRemoveBodyScrollBlockListener(tour);
			});
		},
		$_newbieMentorTutorialMixin_getCdnEmojiUrl(emojiName) {
			return `${cdnBaseUrl}/images/emojis/emoji-${emojiName}.svg`;
		},
		/**
		 * 신규 멘토 튜토리얼 대상자인지 체크
		 * 신규 멘토인지 체크 (최초 인증이 일주일 이내) && 튜토리얼 완료 여부가 없는지
		 * 대상자 { msg: 'not complete', code: 204 }
		 * 대상자가 아님 { msg: 'success', code: 200 }
		 * @returns {Promise<{msg: string, code: number}>}
		 */
		$_newbieMentorTutorialMixin_fetchCheckNewChangeUser() {
			return axios.get(`/api/check/newChangeUser`);
		},
		async $_newbieMentorTutorialMixin_checkNewChangeUser() {
			try {
				const {
					data: { code },
				} = await this.$_newbieMentorTutorialMixin_fetchCheckNewChangeUser();
				if (code === 204) {
					this.isNewbieMentor = true;
				}
			} catch (error) {
				console.error(error);
			}
		},
		$_newbieMentorTutorialMixin_handleAfterSubmitTutorialQuestionFeedback() {
			this.$_removeSessionStorage('NEWBIE_MENTOR_MENTORING_TUTORIAL_STEP_1_COMPLETE');
			this.$_setSessionStorage('NEWBIE_MENTOR_MENTORING_TUTORIAL_STEP_2_COMPLETE', true);
			window.location.href = '/answer';
		},
	},
};

export default newbieMentorTutorialMixin;
