<template>
	<biz-modal-component :show="show" :width="width" @close="close">
		<div class="modal-body">
			<div class="mb-20">
				<div class="font-title f-semi-bold text-primary">1. 이 페이지는 무엇인가요?</div>
				<div class="text-caption f-light font-caption">
					본 페이지는 채용 기업과 후보자가 면접 일정을 보다 쉽게 선택할 수 있도록 마련되었습니다.<br />
					아래 정보를 입력하여 미리보기 후 보내기를 누르시면 매칭 후보자에게 면접 일정 조정 메일, 문자가
					발송됩니다.
				</div>
			</div>
			<div class="mb-20">
				<div class="font-title f-semi-bold text-primary">2. 면접 가능 일자는 어떻게 선택하나요?</div>
				<div class="text-caption f-light font-caption">
					가능 일자를 여러 개 설정하실 수 있습니다.<br />
					예) 2016-10-30 14:00 ~ 2016-10-30 15:00<br />
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2016-10-31 14:00 ~ 2016-10-31 15:00<br />
					<div style="height: 10px">&nbsp;</div>
					혹은 같은 날 여러 시간이 될 경우, 기간만 입력하면 됩니다.<br />
					예) 10월 16일 오후 2시부터 4시까지 가능합니다.<br />
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2016-10-16 14:00 ~ 2016.10.16 16:00 입력, 한 시간 간격으로 자동
					선택지가 생성됩니다.
				</div>
			</div>
			<div class="mb-20">
				<div class="font-title f-semi-bold text-primary">3. 후보자는 어떤 연락을 받게 되나요?</div>
				<div class="text-caption f-light font-caption">
					처음으로 면접 일정을 선택하는 연락을 받은 뒤, 확정 연락을 받습니다.<br /><br />
					<div class="only-pc">
						<img src="/images/biz/interview_help_1.png" style="width: 45%; margin-right: 10px" />
						<img src="/images/biz/interview_help_2.png" style="width: 300px" align="top" />
					</div>
				</div>
			</div>
		</div>
	</biz-modal-component>
</template>

<script>
export default {
	props: ['show', 'width'],
	data() {
		return {};
	},
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="less" scoped>
.modal-container {
	/*width: 650px !important;*/
}
</style>
