import appCommandReceiveMixin from './appCommandReceiveMixin';

const layoutMixin = {
	mixins: {
		appCommandReceiveMixin,
	},
	computed: {
		isIosApp() {
			const os = this.$_getAppOS(window.navigator.userAgent);
			return os === 'ios' || os === 'ipados';
		},
		isAndroidApp() {
			return this.$_getAppOS(window.navigator.userAgent) === 'android';
		},
		appVersion() {
			return this.$_getAppVersion(window.navigator.userAgent);
		},
		isHideHeader() {
			return this.isApp && this.appVersion >= '1.3.0';
		},
	},
};

export default layoutMixin;
