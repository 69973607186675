<template>
	<c-with-form-modal
		:show="show"
		persistent
		align="left"
		scroll
		show-action-button
		:disabled="isSubmitDisabled"
		:loading="isLoading"
		success-message="신고 접수"
		:success-callback="handleSubmitReport"
		@close="handleClose"
	>
		<template v-slot:title> 신고하기 </template>
		<template v-slot:content>
			<c-radio-button
				v-for="(item, index) in reportOptions"
				:key="`report-${index}`"
				:value="index === reportOptionIndex"
				font-color="gray500"
				name="report-radio"
				@click="clickRadioButton(index)"
			>
				{{ item }}
			</c-radio-button>
			<c-text-field
				v-if="isCheckedEtc"
				:value.sync="content"
				outline
				placeholder="신고 내용을 작성해주세요."
				class="my-12"
			/>
		</template>
	</c-with-form-modal>
</template>

<script>
export default {
	props: {
		show: {
			type: Boolean,
		},
		isLoading: {
			type: Boolean,
		},
		isMentoring: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			content: '',
			reportOptionIndex: -1,
		};
	},
	computed: {
		isSubmitDisabled() {
			return this.reportOptionIndex < 0;
		},
		isCheckedEtc() {
			return this.reportOptionIndex === this.reportOptions.length - 1;
		},
		reportOptions() {
			let reportOptions = [
				'비방/욕설',
				'허위사실',
				'개인정보노출',
				'음란성',
				'부적절한 홍보',
				'게시글 도배',
				'기타',
			];
			if (this.isMentoring) {
				reportOptions = ['채택 요구', '다른 작성자의 답변 표절', ...reportOptions];
			}
			return reportOptions;
		},
	},
	watch: {
		show() {
			if (!this.show) {
				this.resetValue();
			}
		},
	},
	methods: {
		handleSubmitReport() {
			const reportType = this.reportOptions[this.reportOptionIndex];
			const reportContent = this.isCheckedEtc ? this.content : reportType;
			this.$emit('submitReport', { content: reportContent });
		},
		handleClose() {
			this.$emit('close');
		},
		resetValue() {
			this.reportOptionIndex = -1;
			this.content = '';
		},
		clickRadioButton(index) {
			this.reportOptionIndex = index;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';

.c-modal--content-wrapper {
	.c-radio-button {
		margin-bottom: 12px;
		@include pc {
			margin-bottom: 16px;
		}
	}
}
</style>
