<template>
	<header ref="blogHeader" v-scroll="blogHeaderHandler" class="blog-header trans">
		<div class="container">
			<nav class="blog-header-wrapper flex flex-row flex-between flex-v-middle">
				<a v-if="isDetail && isMobile" onclick="history.back()">
					<img class="history_back" src="/images/mobile_menu_back.svg" alt="" />
				</a>
				<router-link v-else to="/blog">
					<img src="/images/logo/logo_blog.svg" class="header-logo" alt="코멘토 코멘터리" />
				</router-link>

				<ul class="nav-group">
					<li
						v-for="magazine in blogMagazines"
						:key="magazine.description"
						class="nav-item only-pc"
						:class="magazine.description === $route.params.magazine ? 'active' : ''"
					>
						<router-link :to="goUrl(magazine.description)">{{ magazine.description }}</router-link>
					</li>
					<!--                    <li class="nav-item nav-search">-->
					<!--                        <img :src="searchBarIcon"-->
					<!--                             class="icon-search"-->
					<!--                             @click="clickSearch()"-->
					<!--                        />-->
					<!--                        <div class="blog-search-bar"-->
					<!--                             ref="blogSearchBar"-->
					<!--                             v-show="isShowSearchBar"-->
					<!--                        >-->
					<!--                            <input type="text"-->
					<!--                                   class="blog-search-input"-->
					<!--                                   v-model="blogKeyword"-->
					<!--                                   placeholder="검색어를 입력하세요"-->
					<!--                            />-->
					<!--                            <img src="/images/search_green.svg"-->
					<!--                                 class="icon-search"-->
					<!--                                 @click="searchKeyword"-->
					<!--                            />-->
					<!--                        </div>-->
					<!--                    </li>-->
				</ul>
			</nav>
		</div>
	</header>
</template>

<script>
import { blog } from '../../mixins/blog';

export default {
	mixins: [blog],
	props: {
		isDetail: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		blogKeyword: null,
		isShowSearchBar: false,
	}),
	computed: {
		searchBarIcon() {
			let icon = 'search_green';
			if (this.isShowSearchBar) icon = 'gray_dark_remove';
			return `/images/${icon}.svg`;
		},
	},
	watch: {
		isShowSearchBar() {
			if (this.isMobile) {
				let bgColor = 'transparent';
				if (this.isShowSearchBar) bgColor = 'white';
				this.$refs.blogHeader.style.background = bgColor;
			}
		},
	},
	methods: {
		blogHeaderHandler(evt, el) {
			if (window.pageYOffset > 10) {
				el.classList.add('full');
			} else {
				el.classList.remove('full');
			}
		},
		goUrl(magazine) {
			return `/blog/list/${encodeURI(magazine)}`;
		},
		clickSearch() {
			this.isShowSearchBar = !this.isShowSearchBar;
		},
		searchKeyword() {},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';

.container {
	width: 100%;
	margin: 0 auto;
	@media (min-width: 576px) {
		max-width: 540px;
	}
	@media (min-width: 768px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
}

.blog-header {
	width: 100%;
	height: 80px;
	background: transparent;
	box-shadow: none;
	position: fixed;
	top: 0;
	z-index: 15;
	transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
	-moz-transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
	-o-transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
	-webkit-transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
	&.full {
		background: white;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
		.blog-search-bar {
			margin-top: 37px !important;
		}
	}
	.container,
	&-wrapper {
		height: 100%;
	}
	.header-logo {
		display: block;
		height: 25px;
	}
	.nav-group {
		.nav-item {
			display: inline-block;
			margin-left: 35px;
			position: relative;
			a {
				color: #282c30;
			}
			&.active,
			&:hover,
			&:focus {
				a {
					color: $primary;
				}
			}
			&.nav-search {
				padding: 10px 0 10px 10px;
				margin-left: 25px;
			}
		}
	}
	@media (max-width: 480px) {
		height: 50px;
		.header-logo {
			height: 20px;
		}
		.blog-search-bar {
			z-index: 3;
			margin-top: 11px;
		}
	}
}
</style>
