<template>
	<div>
		<biz-side-component num="3"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<biz-company-header-component
					main="채용공고 관리"
					sub1="채용공고의 수정 및 진행 상태를 변경할 수 있습니다. 기업정보 수정은 기업정보관리 메뉴를 이용하세요."
				/>
				<div class="h7 mt-30 mb-20">
					<a class="pr-5" :class="ing" @click="recruitManagement(true)">진행중</a>
					<a :class="all" @click="recruitManagement(false)">전체보기</a>
				</div>
				<!--종료된 공고만 있을때-->
				<div v-if="management === true && isEnd" class="border width-100 text-center bg-white pd-50">
					<img class="com-logo" src="/images/co_sorry.svg" />
					<div class="text-caption">진행중인 공고가 없어요.</div>
					<div class="text-caption f-light font-caption">
						<div>공고를 등록하면 Robo-recruiter가</div>
						<div>딱 맞는 인재를 찾아드려요.</div>
					</div>
					<button class="btn mt-10 mb-10" onclick="location.href='/with/company/recruit'">
						새로운 채용공고 등록하기</button
					><br />
					<div class="btn btn-close cursor-pointer" @click="recruitManagement(false)">
						종료된 채용공고 확인하기
					</div>
				</div>

				<table v-else-if="list.length > 0" class="bg-white text-center apply-info apply-info-management">
					<tr class="font f-semi-bold bg-dark-gray">
						<td>등록일자</td>
						<td>직무</td>
						<td>상태</td>
						<td>설명</td>
					</tr>
					<tr v-for="item in list" v-if="management === true && parseInt(item.progress) !== 1" class="font">
						<td>{{ item.date | dateFormat }}</td>
						<td>
							<a :href="item.job_url" class="text-link" target="_blank">{{ item.job }}</a>
						</td>
						<td>{{ txt[item.progress] }}</td>
						<td class="text-left pd-10">
							<div>{{ explain[item.progress] }}</div>
							<div v-if="item.expiration_date && parseInt(item.progress) === 0">
								(운영기간:{{ item.expiration_date | dateFormat }} 까지)
							</div>
							<div v-for="(items, index) in btnTxt[item.progress]" class="inline-block mt-5">
								<button
									v-if="item.version >= 4"
									class="btn btn-outline recruit font mr-5"
									@click="action(item.progress, index, item.no)"
								>
									{{ items }}
								</button>
							</div>
						</td>
					</tr>
					<tr v-for="item in list" v-if="management === false" class="font">
						<td>{{ item.date | dateFormat }}</td>
						<td>
							<a :href="item.job_url" class="text-link" target="_blank">{{ item.job }}</a>
						</td>
						<td>{{ txt[item.progress] }}</td>
						<td class="text-left pd-10">
							<div>{{ explain[item.progress] }}</div>
							<div v-if="item.expiration_date && parseInt(item.progress) === 0">
								(운영기간:{{ item.expiration_date | dateFormat }} 까지)
							</div>
							<div v-for="(items, index) in btnTxt[item.progress]" class="inline-block mt-5">
								<button
									v-if="item.version >= 4"
									class="btn btn-outline recruit font mr-5"
									@click="action(item.progress, index, item.no)"
								>
									{{ items }}
								</button>
							</div>
						</td>
					</tr>
				</table>
				<div v-else class="border width-100 text-center bg-white pd-50">
					<img class="com-logo" src="/images/co_sorry.svg" />
					<div class="text-caption">등록된 공고가 없어요.</div>
					<div class="text-caption f-light font-caption">
						<div>공고를 등록하면 Robo-recruiter가</div>
						<div>딱 맞는 인재를 찾아드려요.</div>
					</div>
					<button class="btn mt-10 mb-10" onclick="location.href='/wiht/company/recruit'">
						새로운 채용공고 등록하기
					</button>
				</div>
			</div>
		</section>
		<alert-modal-component
			:show="showAlert"
			:title="title"
			:message="message"
			type="simple"
			:buttonName="buttonName"
			:successCallback="successCallback"
			@close="showAlert = false"
		></alert-modal-component>
	</div>
</template>
<script>
export default {
	data() {
		return {
			list: [],
			management: true,
			ing: 'f-brand text-decoration-underline',
			all: 'text-caption',
			txt: ['진행중', '종료', '비공개 진행중', '관리자 검토중', '결제 대기중'],
			explain: [
				'매칭광고가 진행 중입니다.',
				'매칭이 종료되었습니다. 아래를 눌러 다시 매칭을 시작하거나 공고를 수정할 수 있습니다.',
				'비공개 진행중',
				'관리자가 공고를 검토 중이며 승인되면, 승인일로부터 30일간 매칭이 진행됩니다. 검토는 보통 24시간 소요됩니다.',
				'매칭이 시작되지 않았습니다. 시작하기 버튼을 눌러 매칭을 시작하세요.',
			],
			btnTxt: {
				'-1': ['작성하기'],
				0: ['연장하기', '수정하기', '종료'],
				1: ['시작하기', '수정하기'],
				2: ['종료'],
				3: ['수정하기'],
				4: ['시작하기', '수정하기'],
			},
			showAlert: false,
			title: '',
			message: '',
			buttonName: '',
			successCallback: '',
			isEnd: false, //종료된 공고만 있는지
		};
	},
	mounted() {
		axios({
			method: 'GET',
			url: '/api/company/recruit/list',
		})
			.then(response => {
				this.list = response.data.list;
			})
			.catch(error => {
				//console.log(error);
			});
		this.txt[-1] = '작성중';
		this.explain[-1] = '공고를 작성 중입니다. 공고 등록을 완료하고 딱 맞는 인재를 만나보세요.';
		this.action[-1] = '작성하기';
	},
	updated() {
		let flag = 0;
		for (let i = 0; i < this.list.length; i++) {
			if (parseInt(this.list[i].progress) === 1) {
				flag++;
			}
		}
		if (this.list.length === flag) {
			this.isEnd = true;
		}
	},
	methods: {
		action(progress, idx, companyNo) {
			switch (this.btnTxt[progress][idx]) {
				case '작성하기':
					this.write(companyNo);
					break;

				case '연장하기':
					this.goPayment(companyNo);
					break;

				case '수정하기':
					this.write(companyNo);
					break;

				case '종료':
					this.finish(companyNo);
					break;

				case '시작하기':
					this.start(companyNo);
					break;
			}
		},
		write(companyNo) {
			location.href = '/with/company/recruit?com_no=' + companyNo;
		},
		start(companyNo) {
			axios({
				method: 'GET',
				url: '/api/company/recruit/check',
				params: {
					pm_com_no: companyNo,
				},
			})
				.then(response => {
					const data = response.data;
					if (data.code === 200) {
						if (data.check === 1) {
							location.reload();
						} else {
							this.goPayment(companyNo);
						}
					} else {
						this.title = '실패';
						this.message = '관리자에게 연락바랍니다.';
						this.buttonName = '';
						this.successCallback = '';
						this.showAlert = true;
					}
				})
				.catch(error => {
					//console.log(error);
				});
		},
		goPayment(companyNo) {
			let $form = $(document.createElement('form'))
				.css({ display: 'none' })
				.attr('method', 'POST')
				.attr('action', '/payment');
			let $input = $(document.createElement('input')).attr('name', 'pm_com_no').val(companyNo);
			$form.append($input);
			$('body').append($form);
			$form.submit();
		},
		finish(companyNo) {
			showLoading();
			axios({
				method: 'POST',
				url: '/api/company/recruit/finish',
				data: {
					pm_com_no: companyNo,
				},
			})
				.then(response => {
					const data = response.data;
					if (data.code === 200) {
						this.title = '종료 성공!';
						this.message = '공고가 종료되었습니다.';
						this.buttonName = '';
						this.successCallback = '/company/recruit';
						this.showAlert = true;
					} else {
						this.title = '종료 실패';
						this.message = '관리자에게 연락바랍니다.';
						this.buttonName = '';
						this.successCallback = '';
						this.showAlert = true;
					}
					closeLoading();
				})
				.catch(error => {
					//console.log(error);
				});
		},
		recruitManagement(bool) {
			this.management = bool;
			if (bool === true) {
				this.ing = 'f-brand text-decoration-underline';
				this.all = 'text-caption';
			} else {
				this.ing = 'text-caption';
				this.all = 'f-brand text-decoration-underline';
			}
		},
	},
};
</script>

<style lang="less" scoped>
.btn-close {
	border-color: #adb5bd;
	color: #adb5bd;
}
</style>
