String.prototype.stripSlashes = function () {
	return this.replace(/\\(.)/gm, '$1');
};
//
// function empty(v) {
// 	let type = typeof v;
// 	if (type === 'undefined') {
// 		return true;
// 	}
// 	if (type === 'boolean') {
// 		return !v;
// 	}
// 	if (v === null) {
// 		return true;
// 	}
// 	if (v === undefined) {
// 		return true;
// 	}
// 	if (v instanceof Array) {
// 		if (v.length < 1) {
// 			return true;
// 		}
// 	} else if (type === 'string') {
// 		if (v.length < 1) {
// 			return true;
// 		}
// 		if (v === '0') {
// 			return true;
// 		}
// 	} else if (type === 'object') {
// 		if (Object.keys(v).length < 1) {
// 			return true;
// 		}
// 	} else if (type === 'number') {
// 		if (v === 0) {
// 			return true;
// 		}
// 	}
// 	return false;
// }
// /* 새로운 얼럿창 및 컨펌창 */
// function show_alert(title, message, success_callback) {
// 	$('#dialog').dialog({
// 		modal: true, //모달대화상자
// 		resizable: false, //크기 조절 못하게
// 		title: title,
// 		width: 300,
// 		buttons: {
// 			확인: function () {
// 				if (success_callback == undefined) {
// 					$(this).dialog('close');
// 				} else {
// 					window.location.href = success_callback;
// 				}
// 			},
// 		},
// 	});
// 	$('.message').html(message);
// }
//
// function show_alert_error(title, message) {
// 	$('#dialog').dialog({
// 		modal: true, //모달대화상자
// 		resizable: false, //크기 조절 못하게
// 		title: title,
// 		width: 300,
// 		buttons: {
// 			확인: function () {
// 				$(this).dialog('close');
// 			},
// 		},
// 	});
// 	$('.message').html(message);
// }
// function show_alert_msg(title, message) {
// 	$('#dialog').dialog({
// 		modal: true, //모달대화상자
// 		resizable: false, //크기 조절 못하게
// 		title: title,
// 		width: 300,
// 		buttons: {
// 			확인: function () {
// 				$(this).dialog('close');
// 			},
// 		},
// 	});
// 	$('.message').html(message);
// }
// function show_confirm(title, message, success_callback) {
// 	$('#dialog').dialog({
// 		modal: true, //모달대화상자
// 		resizable: false, //크기 조절 못하게
// 		title: title,
// 		width: 300,
// 		buttons: {
// 			확인: function () {
// 				if (success_callback == undefined) {
// 					$(this).dialog('close');
// 				} else {
// 					location.href = success_callback;
// 				}
// 			},
// 			취소: function () {
// 				$(this).dialog('close');
// 			},
// 		},
// 	});
// 	$('.message').html(message);
// }
// function show_already_user(title, message, callback) {
// 	$('#dialog').dialog({
// 		modal: true, //모달대화상자
// 		resizable: false, //크기 조절 못하게
// 		title: title,
// 		width: 300,
// 		buttons: {
// 			확인: function () {
// 				callback();
// 			},
// 			취소: function () {
// 				$(this).dialog('close');
// 			},
// 		},
// 	});
// 	$('.message').html(message);
// }
// function show_interview_confirm(title, message) {
// 	$('#dialog').dialog({
// 		modal: true, //모달대화상자
// 		resizable: false, //크기 조절 못하게
// 		title: title,
// 		width: 300,
// 		buttons: {
// 			확인: function () {
// 				$(this).dialog('close');
// 			},
// 			취소: function () {
// 				$(this).dialog('close');
// 			},
// 		},
// 	});
// 	$('.message').html(message);
// }
//
// function show_feedback_confirm(title, message, success_callback) {
// 	$('#dialog').dialog({
// 		modal: true, //모달대화상자
// 		resizable: false, //크기 조절 못하게
// 		title: title,
// 		width: 300,
// 		buttons: {
// 			다음에: function () {
// 				$(this).dialog('close');
// 			},
// 			남기기: function () {
// 				if (success_callback == undefined) {
// 					$(this).dialog('close');
// 				} else {
// 					location.href = success_callback;
// 				}
// 			},
// 		},
// 	});
// 	$('.message').html(message);
// }
