<template>
	<modal-component :show="show" :type="type" @close="close">
		<div id="confirm-component" class="modal-body text-center">
			<h4 class="popup-title mb-20 text-center" :style="type === 'simple' ? '' : 'margin-left:25px'">
				{{ title }}
			</h4>
			<div class="text-heading mb-20" :class="align === 'center' ? 'text-center' : 'text-left'">
				<p
					class="font"
					style="color: #212529; word-break: break-word"
					v-html="$options.filters.nl2br(message)"
				></p>
			</div>
			<div class="mt-20" :class="align === 'center' ? 'text-center' : 'text-right'">
				<button class="btn" @click="save">{{ button }}</button>
				<button class="btn btn-empty text-caption" @click="close">취소</button>
			</div>
		</div>
	</modal-component>
</template>

<script>
export default {
	props: {
		show: { type: Boolean },
		title: { type: String },
		message: { type: String },
		buttonName: { type: String },
		method: { type: Function },
		type: { type: String },
		align: { type: String },
	},
	data() {
		return {
			button: '',
		};
	},
	created() {
		if (this.buttonName === '' || this.buttonName === undefined) {
			this.button = '확인';
		} else {
			this.button = this.buttonName;
		}
	},
	updated() {
		if (this.buttonName === '' || this.buttonName === undefined) {
			this.button = '확인';
		} else {
			this.button = this.buttonName;
		}
	},
	methods: {
		close() {
			this.$emit('close');
		},
		save() {
			this.method();
			this.$emit('save');
			this.$emit('close');
			location.reload();
		},
	},
};
</script>

<style lang="less" scoped>
/*.modal-container {*/
/*width: auto;*/
/*}*/
/*.modal-body {*/
/*width: 300px;*/
/*@media (max-width: 480px) {*/
/*width: 100%;*/
/*}*/
/*}*/
</style>
