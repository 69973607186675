import { CLASS_VOD_PATH, CASE_PATH, ANSWER_PATH, VOD_PATH, CLASS_PT_PATH_PREFIX } from '../constants/paths';
import { mapGetters } from 'vuex';
import { IS_TRANSPARENT_TYPE } from '../store/modules/layoutStore';
import { getRoutePath } from '../utils/util';

export const TRANSPARENT_TYPE_COLOR_MAP = {
	logo: { color: 'white', opacity: 100 },
	searchIcon: { color: 'white', opacity: 60 },
	gnb: { color: 'white', opacity: 60 },
	menu: { color: 'white', opacity: 100 },
	activeMenu: { color: 'white', opacity: 90 },
	divider: { color: 'white', opacity: 0 },
};

export const WHITE_TYPE_COLOR_MAP = {
	logo: { color: 'primary', opacity: 100 },
	searchIcon: { color: 'gray600', opacity: 100 },
	gnb: { color: 'gray500', opacity: 100 },
	menu: { color: 'gray700', opacity: 100 },
	activeMenu: { color: 'gray900', opacity: 100 },
	divider: { color: 'gray200', opacity: 100 },
};

const navigationMixin = {
	computed: {
		...mapGetters('layoutStore', [IS_TRANSPARENT_TYPE]),
		$_isEduMenu() {
			const { path } = this.$route;
			return path.includes('/edu');
		},
		$_isEduDetail() {
			const { path } = this.$route;
			return path.includes('/edu/learn');
		},
		$_isClassMenu() {
			const { path } = this.$route;
			return path.includes('/class') && !path.includes('classroom') && !path.includes(CLASS_VOD_PATH);
		},
		$_isCommunityMenu() {
			const { path } = this.$route;
			return path.includes(CASE_PATH) || path.includes(ANSWER_PATH);
		},
		$_isAnswer() {
			const { path } = this.$route;
			return path.startsWith(ANSWER_PATH);
		},
		$_isIndex() {
			const { path } = this.$route;
			return path === '/';
		},
		$_isPt() {
			const { path } = this.$route;
			return path.startsWith('/class/pt');
		},
		$_isPtDetail() {
			const { path } = this.$route;
			return path.includes(CLASS_PT_PATH_PREFIX.OLD_DETAIL) || path.includes(CLASS_PT_PATH_PREFIX.DETAIL);
		},
		$_isAllMenu() {
			const { path } = this.$route;
			return path.includes('/all-menu');
		},
		$_colorMap() {
			return this[IS_TRANSPARENT_TYPE] ? TRANSPARENT_TYPE_COLOR_MAP : WHITE_TYPE_COLOR_MAP;
		},
		$_isPathMenu() {
			const { path } = this.$route;
			return path.startsWith('/edu/path');
		},
		$_isClassHome() {
			return this.$route.path === '/class';
		},
		$_isVodMenu() {
			const { path } = this.$route;
			return path.startsWith(VOD_PATH) || path.includes(CLASS_VOD_PATH);
		},
		$_isVodDetail() {
			const { path } = this.$route;
			return path.includes(`${VOD_PATH}/lecture`);
		},
		$_isClassVodDetail() {
			const { path } = this.$route;
			return path.includes(`${CLASS_VOD_PATH}/detail`);
		},
		$_isCareerMenu() {
			const { path } = this.$route;
			return path.startsWith('/career/externship');
		},
		$_getClickLogoUrl() {
			if (this.$_isEduDetail) {
				return getRoutePath('/edu', 'next');
			}
			if (this.$_isPtDetail) {
				return getRoutePath('/class/pt');
			}
			if (this.$_isVodDetail || this.$_isClassVodDetail) {
				return getRoutePath('/vod');
			}
			return getRoutePath('/?index');
		},
	},
	methods: {
		$_getNavigationColor(property) {
			return this.$_getNavigationColorOrOpacity(property, 'color');
		},
		$_getNavigationOpacity(property) {
			return {
				opacity: this.$_getNavigationColorOrOpacity(property, 'opacity') / 100,
			};
		},
		$_getNavigationColorOrOpacity(property, type) {
			return this.$_colorMap[property][type];
		},
	},
};

export default navigationMixin;
