<template>
	<section class="section section-editor">
		<div class="container blog-container">
			<div class="blog-wrapper">
				<section class="editor-header-wrapper">
					<section class="blog-editor-header">
						<div class="container blog-container">
							<div class="flex flex-row flex-v-middle flex-between">
								<div class="flex flex-v-middle">
									<a class="editor-header-logo mr-20" @click="$router.go(-1)">
										<img src="/images/icon/icon_arrow_back.svg" />
									</a>
									<button class="btn btn-empty mr-10" @click="isShowPopup = true">불러오기</button>
									<button class="btn btn-empty" @click="isShowBrunchModal = !isShowBrunchModal">
										brunch<span v-show="!isMobile"> 불러오기</span>
									</button>
								</div>
								<div>
									<button v-if="blogStatus === 0" class="btn btn-outline mr-5" @click="submit(0)">
										임시저장
									</button>
									<button class="btn" @click="submit(1)">
										발행<span v-show="!isMobile">하기</span>
									</button>
								</div>
							</div>
						</div>
					</section>
				</section>
				<!-- end .editor-header-wrapper -->
				<section>
					<section>
						<div class="text-center">
							<div class="flex flex-row flex-center">
								<v-select
									v-model="magazine"
									:options="blogMagazines"
									label="description"
									:searchable="false"
									class="selectbox-border-sm mb-30"
								>
									<span slot="no-options">없음 :(</span>
								</v-select>
								<v-select
									v-model="category"
									:options="magazine.blog_category"
									label="name"
									:searchable="false"
									class="selectbox-border-sm mb-30"
								>
									<span slot="no-options">매거진을 선택하세요</span>
								</v-select>
							</div>
							<textarea
								v-model="title"
								class="blog-title mb-10"
								placeholder="제목을 입력하세요"
								maxlength="40"
								autocomplete="nope"
							/>
							<input
								v-model="subTitle"
								type="text"
								class="blog-sub-title"
								placeholder="소제목을 입력하세요"
								maxlength="40"
								autocomplete="nope"
							/>
							<hr class="mb-0" />
						</div>
					</section>
					<section>
						<WysiwygEditor v-scroll="editorHandler" class="quill-editor-group" :content.sync="contents" />
					</section>
					<div class="blog-upload-wrapper">
						<hr class="mt-20 mb-20" />
						<div class="blog-thumbnail-upload-wrapper mb-20">
							<div class="mb-10">
								<span class="h7">썸네일</span>
								<span class="filebox">
									<label
										class="btn btn-sm"
										:class="{ disabled: $route.params.postNo === undefined }"
										for="thumbnailFile"
									>
										<span>{{ thumbnailFileName === null ? '등록하기' : '변경하기' }}</span>
									</label>
									<input
										id="thumbnailFile"
										ref="thumbnailFile"
										type="file"
										name="thumbnailFile"
										accept="image/jpeg,image/png,image/jpg,image/bmp,image/gif,image/svg"
										maxlength="1"
										:disabled="$route.params.postNo === undefined"
										@change="handleFileUpload()"
									/>
								</span>
							</div>
							<div>
								<img v-if="thumbnailFileName !== null" :src="thumbnailFileName" class="thumbnail-img" />
							</div>
						</div>
						<div class="blog-hashtag-upload-wrapper">
							<div class="mb-10">
								<span class="h7">해시태그</span>
								<button class="btn btn-sm" @click="submitHashTag()">저장하기</button>
							</div>
							<div class="mb-10">
								<input
									v-model="hashTag"
									type="text"
									placeholder="#태그 입력"
									class="input-hashtag mr-5"
									@keyup.enter="saveHashTag()"
								/>
								<button
									class="btn btn-sm btn-outline"
									:class="{ disabled: $route.params.postNo === undefined }"
									@click="saveHashTag()"
								>
									등록
								</button>
							</div>
							<ul>
								<li
									v-for="(hashTag, index) in hashTagList"
									:key="`hash-tag-${index}`"
									class="badge blog-hashtag"
								>
									{{ hashTag.tag }}
									<img
										src="/images/gray_dark_remove.svg"
										class="icon-remove pointer"
										@click="removeHashTag(index)"
									/>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</div>
		</div>
		<blog-popup :show="isShowPopup" @close="isShowPopup = false" />
		<BlogBrunchImportModal :show="isShowBrunchModal" :contents.sync="contents" @close="isShowBrunchModal = false" />
	</section>
</template>

<script>
// import BlogFooter from './FooterComponent.vue';
import { blog } from '../../mixins/blog';
import { mapMutations } from 'vuex';
import WysiwygEditor from '../../components/common/WysiwygEditor.vue';
import BlogBrunchImportModal from '../../components/_modal/blog/BlogBrunchImportModal.vue';

export default {
	mixins: [blog],
	data: () => ({
		magazine: '매거진',
		category: '카테고리',
		title: '',
		subTitle: '',
		contents: '',
		categories: [],
		thumbnailFile: [],
		thumbnailFileName: null,
		hashTagList: [],
		hashTag: '',
		isShowPopup: false,
		blogStatus: 0, //0: 임시 글, 1: 발행된 글
		buttons: [
			// ['fontfamily'],
			['formatting'],
			['fontsize'],
			['foreColor', 'backColor'],
			['strong', 'em', 'underline', 'del'],
			// ['superscript', 'subscript'],
			['link'],
			['image'], // Our fresh created dropdown
			['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
			['unorderedList', 'orderedList'],
			['horizontalRule'],
			['removeformat'],
			['viewHTML'],
		],
		isShowBrunchModal: false,
	}),
	watch: {
		title() {
			if (this.title.includes('\n')) {
				//줄바꿈은 최대 한번만 가능
				let brCount = this.title.match(/\n/g).length;
				if (brCount >= 2) {
					let newTitle = this.title.split('\n');
					newTitle.pop();
					this.title = `${newTitle[0]}\n${newTitle[1]}`;
				}
			}
		},
		$route() {
			this.init();
			this.getBlogDetail();
		},
	},
	created() {
		this.getBlogDetail();
	},
	mounted() {
		this.setMeta({
			isShowFooter: false,
			isShowHeader: false,
			isDetail: true,
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		init() {
			this.magazine = '매거진';
			this.category = '카테고리';
			this.title = '';
			this.subTitle = '';
			this.contents = '';
			this.categories = [];
			this.thumbnailFile = [];
			this.thumbnailFileName = null;
			this.hashTagList = [];
			this.hashTag = '';
			this.isShowPopup = false;
		},
		getBlogDetail() {
			axios({
				method: 'GET',
				url: '/api/blog/read',
				params: {
					post_no: this.$route.params.postNo,
				},
			})
				.then(response => {
					console.log(response);
					if (response.data.code === 200) {
						const post = response.data.post;
						this.magazine = post.blog_magazine;
						this.category = post.blog_category;
						this.title = post.title;
						this.subTitle = post.sub_title;
						this.contents = post.contents;
						this.thumbnailFileName = post.thumbnail;
						this.blogStatus = post.status;
						post.blog_hash_tag.forEach(hashtag => {
							this.hashTagList.push({ tag: hashtag.hash_tag.tag });
						});
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		submit(type) {
			showLoading();

			axios({
				method: 'POST',
				url: '/api/blog/write',
				data: {
					magazine: this.magazine.no || null,
					category: this.category.no || null,
					title: this.title || null,
					sub_title: this.subTitle || null,
					detail: this.contents || null,
					post_no: parseInt(this.$route.params.postNo, 10) || 0,
					user_no: this.$store.state.sessionInfo.no,
					type, //0: 임시저장, 1: 저장
				},
			})
				.then(response => {
					console.log(response);
					const data = response.data;
					if (data.code === 200) {
						if (type === 0) {
							if (this.$route.params.postNo === undefined) {
								this.$router.replace(`/blog/write/${data.no}`);
							}
							alert('임시저장 완료');
						} else {
							location.href = data.url;
						}
					} else {
						alert(`[ 저장 실패 ]\n${data.msg}`);
					}
					closeLoading();
				})
				.catch(error => {
					closeLoading();
					console.log(error);
				});
		},
		handleFileUpload() {
			let uploadFile = this.$refs.thumbnailFile.files[0];
			this.thumbnailFile.push(uploadFile);
			this.thumbnailUpload();
		},
		thumbnailUpload() {
			//저장
			let formData = new FormData();

			showLoading();
			if (this.thumbnailFile.length !== 0) {
				for (let i = 0; i < this.thumbnailFile.length; i++) {
					formData.append('image', this.thumbnailFile[i]);
					// console.log(this.thumbnailFile[i]);
				}
			}
			formData.append('post_no', this.$route.params.postNo);

			axios({
				method: 'POST',
				url: '/api/blog/thumbnail',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then(response => {
					console.log(response);
					const data = response.data;
					if (data.code === 200) {
						this.thumbnailFileName = data.url;
						this.thumbnailFile = [];
					} else {
						console.log('파일 업로드 오류');
					}
					closeLoading();
				})
				.catch(error => {
					console.log(error);
					closeLoading();
				});
		},
		submitHashTag() {
			if (this.$route.params.postNo !== undefined) {
				showLoading();
				axios({
					method: 'POST',
					url: '/api/blog/hashTag',
					data: {
						tag_list: this.hashTagList,
						post_no: this.$route.params.postNo,
					},
				})
					// eslint-disable-next-line no-unused-vars
					.then(response => {
						closeLoading();
						// console.log(response);
					})
					.catch(error => {
						closeLoading();
						console.log(error);
					});
			}
		},
		removeHashTag(index) {
			if (this.hashTagList.length === 1) {
				this.hashTagList = [];
			} else {
				this.hashTagList.splice(index, 1);
			}
		},
		saveHashTag() {
			this.hashTagList.push({ tag: this.hashTag });
			this.hashTag = '';
		},
		editorHandler(evt, el) {
			const offset = !this.isMobile ? 435 : 360;
			if (window.pageYOffset > offset) {
				el.classList.add('is-sticky');
			} else {
				el.classList.remove('is-sticky');
			}
		},
	},
	components: {
		BlogBrunchImportModal,
		WysiwygEditor,
		// BlogFooter,
		'blog-popup': {
			template: `
        <modal-component :show="show" @close="close">
            <div class="modal-body text-center">
                <h4 class="mb-20" style="margin-left:25px">임시저장 목록</h4>
                <div class="text-center">
                    <table style="width: 100%" v-if="tempList.length > 0">
                        <tr v-for="temp in tempList" class="d-block mb-10" style="width: 100%">
                            <td @click="goUrl(temp.no)"
                                class="text-left pointer"
                            >
                                <p class="text-truncate" :style="{'width': dynamicTitleWidth}">
                                    {{temp.title}} <span class="h7 text-caption">{{temp.created_at}}</span>
                                </p>
                            </td>
                            <th style="width: 50px">
                                <button class="btn btn-sm btn-outline"
                                        @click="deleteTempList(temp.no)"
                                        style="width: 50px"
                                >
                                    삭제
                                </button>
                            </th>
                        </tr>
                    </table>
                    <div v-else>
                        <p>임시저장한 목록이 없습니다</p>
                    </div>
                </div>
            </div>
        </modal-component>
      `,
			data: () => ({
				tempList: [],
			}),
			props: {
				show: {
					type: Boolean,
					default: false,
				},
			},
			created() {
				this.getTempList();
			},
			methods: {
				getTempList() {
					axios({
						method: 'GET',
						url: '/api/blog/temp/list',
					})
						.then(response => {
							// console.log(response);
							if (response.data.code === 200) {
								const list = response.data.list;
								if (Object.keys(list).length > 0) {
									this.tempList = list;
								}
							}
						})
						.catch(error => {
							console.log(error);
						});
				},
				deleteTempList(no) {
					const deleteConfirm = confirm('정말 삭제하실 건가요?');
					if (deleteConfirm) {
						showLoading();
						axios({
							method: 'DELETE',
							url: '/api/blog/delete',
							data: {
								post_no: no,
							},
						})
							.then(response => {
								// console.log(response);
								if (response.data.code === 200) {
									//수정 중인데 삭제했으면 기본 작성페이지로 이동
									if (parseInt(this.$route.params.postNo) === no) {
										this.$router.replace('/blog/write');
									}
									closeLoading();
									alert('삭제 완료');
								} else {
									alert('[삭제 오류] 성실님께 문의하세요.');
									closeLoading();
								}
							})
							.catch(error => {
								closeLoading();
								console.log(error);
							});
					}
				},
				goUrl(postNo) {
					if (parseInt(this.$route.params.postNo) === postNo) {
						this.close();
					} else {
						this.$router.replace(`/blog/write/${postNo}`);
					}
				},
				close() {
					if (typeof this.mask === undefined || !this.mask) {
						this.$emit('close');
					}
				},
			},
			mounted() {
				document.addEventListener('keydown', e => {
					if (this.show && e.keyCode == 27) {
						this.close();
					}
				});
			},
			computed: {
				dynamicTitleWidth() {
					let width = '100%';
					if (this.isMobile) {
						width = 'calc(100vw - 170px)';
					}
					return `${width}`;
				},
			},
			watch: {
				$route: 'getTempList',
			},
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../scss/base/main';

.selectbox-border-sm.v-select {
	width: 200px;
	&:first-child {
		margin-right: 10px;
	}
	@include mobile {
		width: 50%;
	}
}
.thumbnail-img {
	width: 500px;
	@include mobile {
		width: 100%;
	}
}

.editor-header-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: white;
	padding: 20px 0;
	z-index: 2;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
}

.blog {
	&-title,
	&-sub-title {
		border: 0;
		text-align: center;
		@include pc {
			width: 750px;
		}
	}
	&-title {
		height: 134px;
		font-size: 42px;
	}
	&-sub-title {
		color: $gray400;
	}
}
</style>
