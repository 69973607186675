<template>
	<c-alert
		type="image"
		:background-image="imgSrc"
		closable
		class="pt-first-anniversary-alert"
		:class="{ 'c-pointer': !isCouponBookPage }"
		@click.native="goCouponBookPage"
		@close="emitClose"
	>
		<template v-if="!isMobile">
			<img :src="imgSrc" alt="" class="pc-alert-background" />
			<c-typography color="white" align="center" :font-weight="500" class="pt-alert-content">
				{{ CLASS_PT }} 1주년 기념
				<c-typography element="span" style="color: #93ffc4">50,000원 쿠폰</c-typography>
				증정 이벤트
			</c-typography>
		</template>
	</c-alert>
</template>

<script>
import { CLASS_PT } from '../../../../constants';
import { getCdnImageSrc } from '../../../../utils/util';
import routeMixin from '../../../../mixins/routeMixin';
import { COUPON_BOOK_PATH } from '../../../../constants/paths';

export default {
	name: 'PtFirstAnniversaryAlert',
	mixins: [routeMixin],
	data() {
		return {
			CLASS_PT,
		};
	},
	computed: {
		imgSrc() {
			return getCdnImageSrc(`/images/banner/pt-first-anniversary-alert${this.isMobile ? '-mo.svg' : '.jpg'}`);
		},
		isCouponBookPage() {
			return this.$route.path.startsWith(COUPON_BOOK_PATH);
		},
	},
	methods: {
		goCouponBookPage() {
			if (!this.isCouponBookPage) this.$_routeMixin_href(COUPON_BOOK_PATH);
		},
		emitClose() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.pt-first-anniversary-alert::v-deep {
	@include mobile {
		background-size: cover !important;
	}
	@include pc {
		background: black !important;
		padding: 0 !important;
		.pc-alert-background {
			position: absolute;
			top: 0;
			width: 1440px;
			max-width: none;
			left: -166px;
		}
		.container {
			height: 100%;
			@include flexbox();
		}

		.c-alert--row {
			width: 100%;
		}
		.c-alert--content {
			width: 100%;
		}
		.c-alert--close-button,
		.pt-alert-content {
			position: relative;
		}
		.pt-alert-content {
			right: -30px;
		}
		.c-alert--close-button {
			right: -42px;
		}
	}
}
</style>
