<template>
	<footer class="blog-footer">
		<div class="container">
			<c-logo name="LogoComentoTypoType" color="gray600" class="mb-10" />
			<p class="h7 text-sub">Copyright by (주)코멘토. All right reserved.</p>
		</div>
	</footer>
</template>

<script>
export default {
	data: () => ({
		activeMenu: 0,
		menus: [
			{
				menu: '직무부트캠프',
				url: '/blog/edu',
			},
			{
				menu: '코멘토이야기',
				url: '/comento',
			},
			{
				menu: '메뉴3',
				url: '/test',
			},
		],
	}),
};
</script>

<style lang="less" scoped>
@bg-color-gray: #fafafa;

.blog-footer {
	width: 100%;
	height: 150px;
	background: @bg-color-gray;
	padding: 50px;
	position: absolute;
	bottom: 0;
	.footer-logo {
		height: 22px;
	}
	@media (max-width: 480px) {
		display: none;
	}
}
</style>
