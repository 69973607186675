export const cdnBaseUrl = 'https://cdn.comento.kr';

export const cdnImageBaseUrl = `${cdnBaseUrl}/images`;

export const DEEP_LINK_ROOT_URL = 'https://comento.page.link/app';

export const freeScheduleMap = {
	free_schedule: '자율일정',
};
export const isFreeSchedule = type => !!freeScheduleMap[type];

export const CASE_CATEGORY_JOB_SEEKING = '취업 고민';
export const CASE_CATEGORY_CAREER_CHANGE = '이직 고민';
export const CASE_CATEGORY_ONLINE_MENTOR = '랜선 사수';
export const CASE_CATEGORY_UNIV_STUDENT_SEEKING = '대학생 고민';
export const CASE_CATEGORY_WORKING_LEVEL_SKILL = '실무스킬';
export const CASE_CATEGORY_GTM_MAP = {
	0: null,
	1: 'JobSeeking',
	2: 'CareerChange',
	3: CASE_CATEGORY_ONLINE_MENTOR,
	4: CASE_CATEGORY_UNIV_STUDENT_SEEKING,
	5: CASE_CATEGORY_WORKING_LEVEL_SKILL,
};

export const CLASS_ON_AIR_PATH = '/class/onair';
export const CLASS_ON_AIR_API_PATH = `/api${CLASS_ON_AIR_PATH}`;
export const CLASS_PT = '실무PT';
export const CLASS_CATEGORY_ON_AIR = '온에어';
export const CLASS_CATEGORY_VOD = 'VOD';

export const IS_DEV = import.meta.env.DEV;
export const IS_PRODUCTION = import.meta.env.PROD;

export const CLICKED_PROFILE_IMAGE_POPOVER_CLOSE_BUTTON = 'clickedProfileImagePopoverCloseButton';

export const LOGIN_TOAST_KEY = 'COMENTO_LOGIN_TOAST';

export const HR_CONVERT_CON = 'hr_convert_con';
