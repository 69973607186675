import Vue from 'vue';

import dateFormat from './filters/dateFormat';
import dateFormatWithTime from './filters/dateFormatWithTime';
import dateFormatJobs from './filters/dateFormatJobs';
import htmlDecode from './filters/htmlDecode';
import nl2br from './filters/nl2br';
import numberFormat from './filters/numberFormat';
import phoneNumberFormat from './filters/phoneNumberFormat';
import randomImg from './filters/randomImg';
import removeHtmlTag from './filters/removeHtmlTag';
import subStr from './filters/subStr';

Vue.filter('dateFormat', dateFormat);
Vue.filter('dateFormatWithTime', dateFormatWithTime);
Vue.filter('dateFormatJobs', dateFormatJobs);
Vue.filter('htmlDecode', htmlDecode);
Vue.filter('nl2br', nl2br);
Vue.filter('numberFormat', numberFormat);
Vue.filter('phoneNumberFormat', phoneNumberFormat);
Vue.filter('randomImg', randomImg);
Vue.filter('removeHtmlTag', removeHtmlTag);
Vue.filter('substr', subStr);
