<template>
	<div data-aos-easing="ease" data-aos-duration="400" data-aos-delay="0">
		<biz-side-component num="0"></biz-side-component>
		<section class="section bg-gray text-center">
			<div class="container dashboard-layout text-center">
				<div class="inline-block todos float-left">
					<div class="font-title f-regular text-left">To-do's</div>
					<div class="bg-white scroll-y first border mt-10 pd-15">
						<div v-for="todo in todoList" class="todos-list">
							<li style="list-style: none">
								<div>
									<a
										class="font float-left text-left f-regular word-wrap todos-width"
										:href="todo.link"
									>{{ todo.title }}</a
									>
								</div>
							</li>
							<div
								class="inline-block f-regular text-caption font-caption float-right word-wrap text-right"
							>
								{{ todo.transtime }}
							</div>
							<div class="clear-both"></div>
						</div>
					</div>
				</div>
				<div class="inline-block apply-center float-right">
					<div class="font-title f-regular text-left">파트너 지원센터</div>
					<div class="bg-white first border mt-10">
						<div class="height-40">
							<div class="cursor-pointer border-bottom pd-10 text-left" onclick="location.href='/faq'">
								<span class="text-caption font">자주하는 질문</span>
								<img class="arrow-logo float-right" src="/images/menu_arrow.svg" />
							</div>
							<div class="cursor-pointer border-bottom pd-10 text-left" onclick="location.href='/help'">
								<span class="f-brand font f-semi-bold">1:1 문의하기</span>
								<img class="arrow-logo float-right" src="/images/menu_arrow.svg" />
							</div>
						</div>
						<div class="height-60 pd-15">
							<div class="font f-semi-bold text-left pt-5">파트너 지원센터 연락처</div>
							<div class="f-red h4 text-left">1544-9893</div>
							<ul class="text-caption f-light font-caption text-left" style="list-style-type: disc">
								<li class="ml-15">업무시간:09:30 ~ 18:30</li>
								<li class="ml-15">점심시간:12:30 ~ 14:00</li>
								<li class="ml-15">주말 및 공휴일 휴무</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="inline-block count float-left mt-20">
					<div class="text-left">
						<div class="inline-block font-title f-regular">채용진행 현황</div>
						<span class="text-caption f-light sub_title font-caption"
						>* 시스템을 통하지 않은 숫자는 나타나지 않습니다.</span
						>
					</div>
					<div class="bg-white second border pt-10 pb-10 mt-10 text-center">
						<div class="inline-block border-right count-size">
							<div class="f-regular font-caption">누적공고</div>
							<div class="f-brand font-impact f-regular">
								{{ dashboardInfo.jobs_count ? dashboardInfo.jobs_count : 0 }}
							</div>
						</div>
						<div class="inline-block border-right count-size">
							<div class="f-regular font-caption">매칭</div>
							<div class="f-brand font-impact f-regular">
								{{ dashboardInfo.auto_count ? dashboardInfo.auto_count : 0 }}
							</div>
						</div>
						<div class="inline-block border-right count-size">
							<div class="f-regular font-caption">면접</div>
							<div class="f-brand font-impact f-regular">
								{{ dashboardInfo.interview_count ? dashboardInfo.interview_count : 0 }}
							</div>
						</div>
						<div class="inline-block count-size">
							<div class="f-regular font-caption">합격</div>
							<div class="f-brand font-impact f-regular">
								{{ dashboardInfo.pass_count ? dashboardInfo.pass_count : 0 }}
							</div>
						</div>
					</div>
				</div>
				<div class="inline-block position float-right mt-20">
					<div class="text-left">
						<div class="inline-block font-title f-regular">진행중인 채용</div>
						<span class="float-right cursor-pointer" onclick="location.href='/company/recruit'"
						>전체보기</span
						>
					</div>
					<div
						v-if="dashboardInfo.jobs_ing.length > 0"
						class="bg-white scroll-y second border width-100 pd-10 mt-10"
					>
						<div v-for="job in dashboardInfo.jobs_ing" class="h7 f-regular border-bottom pd-10">
							<div class="inline-block content-position text-left">{{ job.job }}</div>
							<div class="inline-block content-type text-center">{{ job.position }}</div>
							<div
								v-if="job.progress === '1'"
								class="inline-block content-progress text-center font text-caption"
							>
								{{ changeProgress(parseInt(job.progress)) }}
							</div>
							<div
								v-else-if="job.progress === '3'"
								class="inline-block content-progress text-center font f-red"
							>
								{{ changeProgress(parseInt(job.progress)) }}
							</div>
							<div v-else class="inline-block content-progress text-center font">
								{{ changeProgress(parseInt(job.progress)) }}
							</div>
							<div class="inline-block content-link cursor-pointer h7">
								<a class="text-link" :href="'/company/recruit/1?com_no=' + job.no">수정</a>
							</div>
							<div class="inline-block content-link cursor-pointer h7 text-link">
								<a class="text-link" :href="job.url" target="_blank">조회</a>
							</div>
						</div>
					</div>
					<div v-else class="border bg-white second mt-10 pd-10">
						<img class="com-logo" src="/images/co_sorry.svg" />
						<div class="text-caption">등록된 공고가 없어요.</div>
						<div class="text-caption f-light font-caption">
							<div>공고를 등록하면 Robo-recruiter가</div>
							<div>딱 맞는 인재를 찾아드려요.</div>
						</div>
						<div class="f-semi-bold cursor-pointer">
							<a href="/company/recruit/1" class="text-link">채용공고 등록</a>
						</div>
					</div>
				</div>
				<!--
                임시로 없앰 (Flow. #3921)
                <div class="float-left applier mt-20 text-center">
                    <div class="font-title f-regular text-left">지원자</div>
                    <div class="text-caption f-light sub_title text-left font-caption">* 코멘토 매칭을 통해 직접 지원한 지원자입니다. 채용 전형 진행
                        부탁드립니다.
                    </div>
                    <div class="text-center" v-if="companyApplier.length>0">
                        <table class="bg-white apply-info mt-20">
                            <tr class="font f-semi-bold bg-dark-gray">
                                <td>날짜</td>
                                <td>직무</td>
                                <td>이름</td>
                                <td class="only-pc">학교</td>
                                <td class="only-pc">전공</td>
                                <td>전형단계</td>
                            </tr>
                            <tr class="f-regular h7" v-for="applyItem in companyApplier">
                                <td>{{applyItem.transtime}}</td>
                                <td>{{applyItem.job}}</td>
                                <td>{{applyItem.name}}</td>
                                <td class="only-pc">{{applyItem.univ}}</td>
                                <td class="only-pc">{{applyItem.major}}</td>
                                <td>
                                    {{applyItem.state_summary}}
                                    <template v-if="parseInt(applyItem.state) >= 5">
                                        <template v-if="parseInt(applyItem.interview_state) === 1">
                                            <div class='font-caption' style='margin-top:-5px'>({{applyItem.interview_date}})</div>
                                        </template>

                                        <template v-else>
                                            <template v-if="parseInt(applyItem.interview_manual) === 1">
                                                <div class='font-caption f-red' style='margin-top:-5px; color: #0000FF'>(개별연락)</div>
                                            </template>

                                            <template v-else>
                                                <div class='font-caption f-red' style='margin-top:-5px;'>(일정 선택)</div>
                                            </template>
                                        </template>
                                    </template>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else-if="dashboardInfo.jobs_ing.length<1" class="mt-20">
                        <div class="bg-white border pd-20">
                            <img class="com-logo" src="/images/gray_waiting.svg"/>
                            <div class="text-caption">채용 진행을 기다리는 중</div>
                            <div class="text-caption f-light font-caption">새로운 공고를 등록하세요.</div>
                        </div>
                    </div>
                    <div v-else-if="dashboardInfo.jobs_ing.length>0" class="mt-20">
                        <div class="bg-white border pd-20">
                            <img class="com-logo" src="/images/robo_recruiter.svg"/>
                            <div class="text-caption">매칭을 진행 중입니다.</div>
                            <div class="text-caption f-light font-caption">
                                <div>기업이 찾고있는 딱 맞는 인재를</div>
                                <div>곧 만나실 수 있습니다.</div>
                            </div>
                        </div>
                    </div>

                </div>
                -->
				<!--
                임시로 없앰 (Flow. #3921)
                <div class=" float-left applier mt-20 text-center">
                    <div class="font-title f-regular text-left">AI 추천후보</div>
                    <div class="text-caption f-light sub_title text-left font-caption">* 코멘토 AI가 추천한 후보자입니다. 직접 지원자는 아니지만 기업에서 먼저
                        면접을 제안할 수 있습니다. 서류 대기상태인 경우 매칭 후 2주가 지나면 목록에서
                        사라집니다.
                    </div>
                    <div class="text-center" v-if="autoApplier.length>0">
                        <table class="bg-white text-center apply-info mt-20">
                            <tr class="font f-semi-bold bg-dark-gray">
                                <td>날짜</td>
                                <td>직무</td>
                                <td>이름</td>
                                <td class="only-pc">학교</td>
                                <td class="only-pc">전공</td>
                                <td>전형단계</td>
                            </tr>
                            <tr class="f-regular h7" v-for="autoItem in autoApplier">
                                <td>{{autoItem.transtime}}</td>
                                <td>{{autoItem.job}}</td>
                                <td>{{autoItem.name}}</td>
                                <td class="only-pc">{{autoItem.univ}}</td>
                                <td class="only-pc">{{autoItem.major}}</td>
                                <td>
                                    {{autoItem.state_summary}}
                                    <template v-if="parseInt(autoItem.state) >= 5">
                                        <template v-if="parseInt(autoItem.interview_state) === 1">
                                            <div class='font-caption' style='margin-top:-5px'>({{autoItem.interview_date}})</div>
                                        </template>

                                        <template v-else>
                                            <template v-if="parseInt(autoItem.interview_manual) === 1">
                                                <div class='font-caption f-red' style='margin-top:-5px; color: #0000FF'>(개별연락)</div>
                                            </template>

                                            <template v-else>
                                                <div class='font-caption f-red' style='margin-top:-5px;'>(일정 선택)</div>
                                            </template>
                                        </template>
                                    </template>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else-if="dashboardInfo.jobs_ing.length<1" class="mt-20">
                        <div class="bg-white border pd-20">
                            <img class="com-logo" src="/images/gray_waiting.svg"/>
                            <div class="text-caption">채용 진행을 기다리는 중</div>
                            <div class="text-caption f-light font-caption">새로운 공고를 등록하세요.</div>
                        </div>
                    </div>
                    <div v-else-if="dashboardInfo.jobs_ing.length>0" class="mt-20">
                        <div class="bg-white border pd-20">
                            <img class="com-logo" src="/images/robo_recruiter.svg"/>
                            <div class="text-caption">매칭을 진행 중입니다.</div>
                            <div class="text-caption f-light font-caption">
                                <div>기업이 찾고있는 딱 맞는 인재를</div>
                                <div>곧 만나실 수 있습니다.</div>
                            </div>
                        </div>
                    </div>
                </div>
                -->
				<div class="clear-both">
					<button class="mt-20 btn btn-lg" onclick="location.href='/applicant'">지원자 관리</button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	props: ['dashboardInfo'],
	data() {
		return {
			//companyNo: this.dashboardInfo.todos[0].pm_company_user_no,
			todoList: this.dashboardInfo.todos,
			txt: ['진행중', '종료', '비공개', '승인대기', '결제대기'],
			color: '',
			companyApplier: [],
			autoApplier: [],
			companyApplierNum: '',
		};
	},
	mounted() {
		axios({
			method: 'GET',
			url: '/api/dashboard/apply/list',
		})
			.then(response => {
				//console.log(response);
				this.companyApplier = response.data.list.comento;
				this.autoApplier = response.data.list.auto;
			})
			.catch(error => {
				//console.log(error);
			});
	},
	methods: {
		changeProgress(progress) {
			return this.txt[progress];
		},
	},
};
</script>
<style lang="less" scoped>
.arrow-logo {
	height: 18px;
	position: relative;
	top: 5px;
}
.pd-15 {
	padding: 15px !important;
}
</style>
