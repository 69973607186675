<template>
	<c-button type="outline" color="primary" v-bind="$attrs" :loading="loading" :disabled="disabled" v-on="$listeners">
		<template v-slot:left-icon>
			<c-icon name="IconCheckSmallLine" color="primary" />
		</template>
		{{ buttonText }}
	</c-button>
</template>

<script>
export default {
	name: 'AdoptButton',
	props: {
		chosen: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		buttonText() {
			return this.chosen ? '채택완료' : '채택하기';
		},
	},
};
</script>

<style scoped></style>
