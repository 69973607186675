<template>
	<HackleProvider :instance="instance" :user="hackleUserInfo">
		<c-new-grid class="main-grid-component" :fluid="fluid" :style="computedStyle">
			<c-new-row>
				<slot />
			</c-new-row>
		</c-new-grid>
	</HackleProvider>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { colors } from '../components/common/colors';
import { IS_SHOW_ALERT_LAYOUT, SET_IS_SHOW_GLOBAL_NOTICE_ALERT } from '../store/modules/layoutStore';
import { HackleProvider, createInstance } from '@comento/hackle-sdk';
import { IS_PRODUCTION } from '@/constants';

const instance = createInstance(
	IS_PRODUCTION ? import.meta.env.VITE_HACKLE_API_KEY_PROD : import.meta.env.VITE_HACKLE_API_KEY_DEV,
	{ debug: !IS_PRODUCTION },
);

export default {
	name: 'MainLayout',
	computed: {
		...mapGetters(['userNo']),
		...mapGetters('layoutStore', ['fluid', 'bgColor', IS_SHOW_ALERT_LAYOUT]),
		computedBgColor() {
			const bgColor = this.bgColor;
			if (Array.isArray(bgColor)) {
				return !this.isMobile ? this.bgColor[0] : this.bgColor[1];
			}
			return bgColor;
		},
		computedStyle() {
			const colorValue =
				Object.keys(colors).indexOf(this.computedBgColor) !== -1
					? colors[this.computedBgColor]
					: this.computedBgColor;

			return {
				'background-color': colorValue,
				'min-height': 'calc(100vh - 324px)',
				'padding-top': '0 !important',
			};
		},
		instance() {
			return instance;
		},
		hackleUserInfo() {
			return {
				userId: this.userNo,
			};
		},
	},
	methods: {
		...mapMutations('layoutStore', [SET_IS_SHOW_GLOBAL_NOTICE_ALERT]),
	},
	components: {
		HackleProvider,
	},
};
</script>
