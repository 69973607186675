const date = {
	data: () => ({}),
	methods: {
		/**
		 * 2020.07.20 식으로 날짜를 반환
		 * @param date: 날짜
		 **/
		$_dateWithDot(date) {
			let replacedDate = String(date).replace(/-/gi, '.');
			return replacedDate.split('T')[0];
		},
	},
};

export default date;
