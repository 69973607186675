<template>
	<c-button color="info" type="outline" size="small" @click="emitReadAll">모두 읽음</c-button>
</template>

<script>
export default {
	name: 'NotiReadAllButton',
	methods: {
		emitReadAll() {
			this.$emit('read-all');
		},
	},
};
</script>
