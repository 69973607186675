const paymentStore = {
	namespaced: true,
	state: {
		page: null,
		extraData: null,
		isNineWeekCamp: false,
		isLoading: false,
		currentCoupon: null,
		bestCoupon: null,
		isFreeSchedule: false,
	},
	mutations: {
		setPaymentPage(state, data) {
			state.page = data;
		},
		setPaymentExtraData(state, data) {
			state.extraData = data;
		},
		setIsNikeWeekCamp(state, data) {
			state.isNineWeekCamp = data;
		},
		setIsLoading(state, data) {
			state.isLoading = data;
		},
		setCurrentCoupon(state, data) {
			state.currentCoupon = data;
		},
		setBestCoupon(state, data) {
			state.bestCoupon = data;
		},
		setFreeSchedule(state, payload) {
			state.isFreeSchedule = payload;
		},
	},
	getters: {
		paymentPage: state => {
			return state.page;
		},
		paymentExtraData: state => {
			return state.extraData;
		},
		isNineWeekCamp: state => {
			return state.isNineWeekCamp;
		},
		isLoading: state => {
			return state.isLoading;
		},
		currentCoupon: state => state.currentCoupon,
		bestCoupon: state => state.bestCoupon,
		isFreeSchedule: state => state.isFreeSchedule,
	},
};

export default paymentStore;
