<template>
	<div v-if="isShow" class="mb-30">
		<section class="content apply mb-0">
			<div v-for="state in applyStateList" :class="applyStateGroup">
				<div class="flex flex-row width-100">
					<div class="flex-index">
						<img src="/images/custom-feed-check-full.svg" class="check-icon mr-10" />
					</div>
					<div class="flex-index flex flex-column">
						<h6>{{ state.title }}</h6>
						<div v-if="state.message === ''" class="f-red date">
							<countdown :time="time" :transform="transform">
								<template slot-scope="props">
									남은 시간: {{ props.days }}일 {{ props.hours }}시간 {{ props.minutes }}분
									{{ props.seconds }}초
								</template>
							</countdown>
						</div>
						<div v-else class="font-msg text-caption">{{ state.message }}</div>
						<div class="only-mobile">
							<div class="flex flex-row">
								<div v-for="(button, index) in state.button" class="btn-group">
									<button
										v-if="button !== '지원자 제외하기'"
										class="btn btn-outline cursor-pointer mr-10"
										@click="progressBtn(button)"
									>
										{{ button }}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						class="flex-index-btn flex flex-row only-pc"
						:class="state.button !== '지원취소' ? 'cancel-btn' : ''"
					>
						<div v-for="(button, index) in state.button" class="btn-group">
							<button
								v-if="button !== '지원자 제외하기'"
								class="btn btn-outline cursor-pointer"
								:class="index + 1 === state.button.length || 'mr-10'"
								@click="progressBtn(button)"
							>
								{{ button }}
							</button>
						</div>
					</div>
				</div>
				<!-- todo: 청년재단 제외 버튼(state 3 && applyAutoInterest === null) -->
			</div>
		</section>
		<div
			v-for="state in applyStateList"
			v-if="display !== 'mypage' && state.message === ''"
			class="date text-right"
		>
			*기한이 지나면 전형이 자동으로 종료되며 복구되지 않습니다.
		</div>

		<div v-for="state in applyStateList">
			<div v-for="(button, index) in state.button" class="btn-group">
				<button
					v-if="button === '지원자 제외하기'"
					class="btn btn-outline cursor-pointer width-100 mt-10"
					@click="progressBtn(button)"
				>
					{{ button }}
				</button>
			</div>
		</div>

		<confirm-callback-component
			:show="show"
			:title="title"
			:message="message"
			buttonName=""
			:method="progress"
			type=""
			align="center"
			@close="show = false"
		></confirm-callback-component>
	</div>
</template>

<script>
export default {
	props: [
		'display',
		'applyState',
		'viewResume',
		'interview',
		'applyType',
		'applyNo',
		'applyDate',
		'applyAutoInterest',
		'expireDate',
		'applyInfo',
		'autoExpireDate',
		'pmCompanyUserType',
		'stateChangedDate',
	],
	data() {
		return {
			applyStateList: [],
			show: false,
			title: '',
			message: '',
			index: null,
			progressNo: null,
			isShow: true,
			time: this.expireDate,
			//yhfAutoFlag: false,
		};
	},
	computed: {
		applyStateGroup() {
			if (this.isMobile) {
				return 'flex flex-column';
			} else {
				return 'flex flex-row flex-between';
			}
		},
	},
	created() {
		this.$nextTick(() => {
			//지원자
			if (this.display === 'mypage') {
				//지원자 - 일반지원
				if (this.applyInfo.user_type === 1) {
					//일반지원
					if (this.applyState === 3) {
						//지원완료
						this.applyStateList.push({
							title: '기업에서 서류평가를 진행하고 있어요.',
							message: '서류평가 기한이 초과하면 자동으로 전형이 종료됩니다.',
							button: ['지원취소'],
						});
					} else if (
						this.applyState === 3 &&
						moment(this.stateChangedDate).add(15, 'days').isAfter(moment(this.stateChangedDate))
					) {
						//서류평가기한 초과
						if (this.pmCompanyUserType === 8) {
						} else if (this.pmCompanyUserType === 9) {
						} else {
							this.applyStateList.push({
								title: '평가 기한초과로 전형이 종료되었어요.',
								message: '전형이 종료되었습니다. 전형에 참여해주셔서 감사합니다.',
							});
						}
					} else if (this.applyState === 3 && this.viewResume > 0) {
						//기업담당자 지원서열람
						this.applyStateList.push({
							title: '기업에서 서류평가를 진행하고 있어요.',
							message: '서류평가 기한이 초과하면 자동으로 전형이 종료됩니다.',
							button: ['지원취소'],
						});
					} else if (this.applyState === 5 && !this.interview) {
						//서류합격
						this.applyStateList.push({
							title: '서류합격',
							message: '서류 합격을 축하드립니다. 면접 일정은 별도로 안내됩니다.',
							button: ['지원취소'],
						});
					} else if (this.applyState === 4) {
						//서류불합격
						this.applyStateList.push({
							title: '전형이 종료되었어요.',
							message: '전형이 종료되었습니다. 전형에 참여해주셔서 감사합니다.',
						});
					} else if (this.applyState === 13 && this.interview.manual === 1) {
						//면접 일정 - 기업이 직접 연락
						this.applyStateList.push({
							title: '면접일정 협의가 예정되어있어요.',
							message: '기업 인사담당자가 면접일정 확정을 위해 별도로 연락드릴 예정입니다.',
							button: ['지원취소'],
						});
					} else if (
						(this.applyState === 5 || this.applyState === 12 || this.applyState === 13) &&
						this.interview.confirm === 1 &&
						!moment().isAfter(moment(this.interview.startdate))
					) {
						//면접일정확정
						this.applyStateList.push({
							title: '면접이 확정되었어요.',
							message: '면접 예정일: ' + moment(this.interview.startdate).format('YYYY년 MM월 DD일 HH시'),
							button: ['지원취소'],
						});
					} else if (
						(this.applyState === 5 || this.applyState === 12 || this.applyState === 13) &&
						this.interview.confirm === 1 &&
						moment().isAfter(moment(this.interview.startdate))
					) {
						//면접결과입력
						this.applyStateList.push({
							title: '면접 결과를 기다리고 있어요.',
							message: '기업에서 면접 평가를 진행 중입니다.',
							button: ['지원취소'],
						});
					} else if (
						(this.applyState === 5 || this.applyState === 12 || this.applyState === 13) &&
						this.interview.confirm === 0
					) {
						//면접일정제안
						this.applyStateList.push({
							title: '면접일정을 확정하세요.',
							message: '제안된 일정 중 가능한 시간을 선택하면 면접이 확정됩니다.',
							button: ['면접일정확정'], //(구)면접일정확인
						});
					} else if (this.applyState === 10) {
						//면접불합격
						this.applyStateList.push({
							title: '전형이 종료되었어요',
							message: '전형이 종료되었습니다. 전형에 참여해주셔서 감사합니다.',
						});
					} else if (this.applyState === 12) {
						//추가면접
						this.applyStateList.push({
							title: '추가 면접을 진행합니다.',
							message: '면접 일정은 별도로 안내됩니다.',
							// button: ['면접일정확정']
						});
					} else if (this.applyState === 7) {
						//최종합격
						this.applyStateList.push({
							title: '최종합격을 축하드려요!',
							message: '입사 안내는 기업에서 별도로 연락드릴 예정입니다. 새로운 시작을 응원합니다.',
							// button: ['지원취소']
						});
					} else if (this.applyState === 8 || this.applyState === 11) {
						this.applyStateList.push({
							title: '전형이 종료되었어요.',
							message: '전형이 종료되었습니다. 전형에 참여해주셔서 감사합니다.',
						});
					}
				} else {
					//자동지원
					//지원자 - 자동지원
					if (
						this.applyState === 3 &&
						(!this.applyAutoInterest || typeof this.applyAutoInterest === 'undefined')
					) {
						//매칭완료
						//없음
					} else if (this.applyState === 3 && this.applyAutoInterest.interest === 0) {
						//관심도확인 발송
						this.applyStateList.push({
							title: '기업이 전형진행 의사가 있는지 궁금해해요.',
							message: '지원자의 전형진행의사를 확인중입니다.',
							button: ['관심있어요'], //,거절하기
						});
					} else if (this.applyState === 3 && this.applyAutoInterest.interest === 1) {
						//관심도확인 승낙
						this.applyStateList.push({
							title: '기업에게 후속 전형의사를 표시했어요.',
							message: '이후 전형은 기업에서 다시 안내할 예정이니 잠시만 기다려주세요.',
						});
					} else if (this.applyState === 8 && this.applyAutoInterest.interest === -1) {
						//관심도확인 거절
						this.applyStateList.push({
							title: '기업에게 후속 전형의사를 표시했어요.',
							message: '이후 전형은 기업에서 다시 안내할 예정이니 잠시만 기다려주세요.',
						});
					} else if (this.applyState === 5) {
						//바로면접제안
						this.applyStateList.push({
							title: '기업이 면접을 진행하고 싶어해요.',
							message: '기업이 제안한 면접 일정을 확인하고 골라주세요.',
							button: ['면접일정확정'], //(구)면접일정확인
						});
					}
				}
			}
			//기업(biz)
			else {
				// 기업 - 일반지원
				if (this.applyInfo.user_type === 1) {
					//일반지원

					if (this.applyState === 3) {
						//지원완료
						this.applyStateList.push({
							title: '서류평가 후 결과를 입력하세요.',
							message: '',
							button: ['서류합격', '서류불합격'],
						});
					} else if (
						this.applyState === 3 &&
						moment(this.stateChangedDate).add(15, 'days').isAfter(moment(this.stateChangedDate))
					) {
						//서류평가기한 초과
						// 없음
						if (this.pmCompanyUserType === 8) {
							this.applyStateList.push({
								title: '서류평가 후 결과를 입력하세요.',
								message: '',
								button: ['서류합격', '서류불합격'],
							});
						} else if (this.pmCompanyUserType === 9) {
							this.applyStateList.push({
								title: '서류평가 후 결과를 입력하세요.',
								message: '',
								button: ['서류합격', '서류불합격'],
							});
						}
					} else if (this.applyState === 3 && this.viewResume > 0) {
						//기업담당자 지원서열람
						this.applyStateList.push({
							title: '서류평가 후 결과를 입력하세요.',
							message: '',
							button: ['서류합격', '서류불합격'],
						});
					} else if (this.applyState === 4) {
						//서류불합격
						//없음
						this.isShow = false;
					} else if (this.applyState === 5 || this.applyState === 12 || this.applyState === 13) {
						if (this.interview) {
							// 면접결과 입력
							if (this.interview.confirm === 1 && moment().isAfter(moment(this.interview.startdate))) {
								this.applyStateList.push({
									title: '면접 결과를 입력하세요.',
									message: '면접 평가 결과를 입력하세요.',
									//button: ['면접불합격', '추가면접', '최종합격', '면접불참'],
									button: ['최종합격', '면접불합격'],
								});
							}
							// 면접일정 확정
							else if (
								this.interview.confirm === 1 &&
								!moment().isAfter(moment(this.interview.startdate))
							) {
								this.applyStateList.push({
									title: '면접이 확정되었어요.',
									message:
										'면접 예정일: ' +
										moment(this.interview.startdate).format('YYYY년 MM월 DD일 HH시'),
								});
							}
							// 면접 일정 - 기업이 직접 연락
							else if (this.applyState === 13 && this.interview.manual === 1) {
								this.applyStateList.push({
									title: '면접을 확정하세요.',
									message: '지원자와 협의한 면접일정을 아래 버튼을 눌러 입력할 수 있습니다.',
									button: ['면접일정입력'],
								});
							}
							// 면접일정 제안
							else if (this.interview.confirm === 0) {
								this.applyStateList.push({
									title: '지원자가 면접일정 검토중이에요.',
									message: '지원자가 제안받은 일정 중 한 가지를 선택하면 면접이 확정됩니다.',
									button: ['면접일정재발송'],
								});
							}
						} else {
							this.applyStateList.push({
								title: '면접을 제안해주세요.',
								message: '버튼을 눌러 지원자에게 면접 일정을 제안하세요.',
								button: ['면접일정잡기', '직접연락'],
							});
						}
					} else if (this.applyState === 10) {
						/*
                        else if (this.applyState === 5 &&
                            (!this.interview || this.interview.confirm === -1)) { //서류합격
                            this.applyStateList.push({
                                title: '면접을 제안하세요.',
                                message: '버튼을 눌러 지원자에게 면접 일정을 제안하세요.',
                                button: ['면접일정잡기', '직접연락']
                            });
                        } else if ((this.applyState === 5 || this.applyState === 12 || this.applyState === 13)
                            && this.interview.confirm === 0) { //면접일정제안
                            this.applyStateList.push({
                                title: '지원자가 면접일정 검토중이에요.',
                                message: '지원자가 제안받은 일정 중 한 가지를 선택하면 면접이 확정됩니다.',
                                button: ['면접일정재발송']
                            });
                        } else if ((this.applyState === 5 || this.applyState === 12 || this.applyState === 13)
                            && this.interview.confirm === 1 && !moment().isAfter(moment(this.interview.startdate))) { //면접일정확정
                            this.applyStateList.push({
                                title: '면접이 확정되었어요.',
                                message: '면접 예정일: ' + moment(this.interview.startdate).format('YYYY년 MM월 DD일 HH시')
                            });
                        } else if (this.applyState === 13 && this.interview.manual === 1) { //면접 일정 - 기업이 직접 연락
                            this.applyStateList.push({
                                title: '면접을 확정하세요.',
                                message: '지원자와 협의한 면접일정을 아래 버튼을 눌러 입력할 수 있습니다.',
                                button: ['면접일정입력']
                            });
                        } else if ((this.applyState === 5 || this.applyState === 12 || this.applyState === 13)
                            && this.interview.confirm === 1 && moment().isAfter(moment(this.interview.startdate))) { //면접결과입력
                            this.applyStateList.push({
                                title: '면접 결과를 입력하세요.',
                                message: '면접 평가 결과를 입력하세요.',
                                button: ['면접불합격', '추가면접', '최종합격', '면접불참']
                            });
                        }
                        */
						//면접불합격
						//없음
						this.isShow = false;
					} else if (this.applyState === 12) {
						//추가면접
						this.applyStateList.push({
							title: '지원자가 면접일정을 확정중이에요.',
							message: '지원자가 제안 받은 일정 중 한 가지를 선택하면 면접이 확정됩니다.',
							button: ['면접일정재발송'],
						});
					} else if (this.applyState === 7 && !this.applyInfo.pass_date) {
						//최종합격
						this.applyStateList.push({
							title: '최종합격이 확정되었습니다.',
							message: '딱 맞는 인재 채용을 축하드립니다. 입사 여부 및 입사일 협의를 진행하세요.',
							//title: '입사일을 확정하세요.',
							//message: '딱 맞는 인재 채용을 축하드립니다. 입사 여부 및 입사일 협의를 진행하세요.',
							//button: ['입사일입력']
						});
					} else if (
						this.applyState === 8 ||
						this.applyState === 11 ||
						(this.applyState === 7 && this.applyInfo.pass_date)
					) {
						this.applyStateList.push({
							title: '전형이 종료되었습니다.',
							message: '전형이 종료되었습니다. 전형에 참여해주셔서 감사합니다.',
						});
					}
				} else {
					//기업 - 자동지원
					if (
						this.applyState === 3 &&
						(!this.applyAutoInterest || typeof this.applyAutoInterest === 'undefined')
					) {
						//매칭완료

						let button = ['관심도확인', '서류합격', '지원자 제외하기'];

						this.applyStateList.push({
							title: '검색된 인재에게 면접을 제안할 수 있어요.',
							message: '지원자가 마음에 든다면 주저하지 말고 바로 면접을 제안해보세요!',
							button: button,
						});
					} else if (this.applyState === 3 && this.applyAutoInterest.interest === 0) {
						//관심도확인 발송

						let button = ['서류합격', '지원자 제외하기'];

						this.applyStateList.push({
							title: '관심도 확인이 요청되었어요.',
							message: ' ',
							button: button,
						});
						this.time = this.autoExpireDate;
					} else if (this.applyState === 3 && this.applyAutoInterest.interest === 1) {
						//관심도확인 승낙

						let button = ['서류합격', '지원자 제외하기'];

						this.applyStateList.push({
							title: '지원자가 기업에 관심을 표현했어요.',
							message: '바로 면접을 제안하면 면접 성사 가능성이 높아요.',
							button: button,
						});
					} else if (this.applyState === 8 && this.applyAutoInterest.interest === 2) {
						//관심도확인 거절
						//없음
						this.isShow = false;
					} else if (this.applyState === 4) {
						/*
                        else if (this.applyState === 5) { //바로면접제안
                            this.applyStateList.push({
                                title: '지원자가 면접일정을 확정 중이에요.',
                                message: '지원자가 제안 받은 일정 중 한 가지를 선택하면 면접이 확정됩니다.',
                                button: ['면접일정재발송']
                            });
                        }
                        */
						//서류불합격
						//없음
						this.isShow = false;
					} else if (this.applyState === 5 || this.applyState === 12 || this.applyState === 13) {
						// 면접결과 입력
						if (this.interview.confirm === 1 && moment().isAfter(moment(this.interview.startdate))) {
							//let button =  ['면접불합격', '추가면접', '최종합격', '면접불참','지원자 제외하기'];

							let button = ['면접불합격', '최종합격', '지원자 제외하기'];

							this.applyStateList.push({
								title: '면접 결과를 입력하세요.',
								message: '면접 평가 결과를 입력하세요.',
								button: button,
							});
						}
						// 면접일정 확정
						else if (this.interview.confirm === 1 && !moment().isAfter(moment(this.interview.startdate))) {
							this.applyStateList.push({
								title: '면접이 확정되었어요.',
								message:
									'면접 예정일: ' + moment(this.interview.startdate).format('YYYY년 MM월 DD일 HH시'),
							});
						}
						// 면접일정 제안
						else if (this.interview.confirm === 0) {
							let button = ['면접일정재발송', '지원자 제외하기'];

							this.applyStateList.push({
								title: '지원자가 면접일정 검토중이에요.',
								message: '지원자가 제안받은 일정 중 한 가지를 선택하면 면접이 확정됩니다.',
								button: button,
							});
						} else {
							let button = ['면접일정잡기', '직접연락', '지원자 제외하기'];

							this.applyStateList.push({
								title: '면접을 제안해주세요.',
								message: '버튼을 눌러 지원자에게 면접 일정을 제안하세요.',
								button: button,
							});
						}
					} else if (this.applyState === 10) {
						/*
                        else if (this.applyState === 5 &&
                            (!this.interview || this.interview.confirm === -1)) { //서류합격
                            this.applyStateList.push({
                                title: '면접을 제안해주세요.',
                                message: '버튼을 눌러 지원자에게 면접 일정을 제안하세요.',
                                button: ['면접일정잡기', '직접연락']
                            });
                        } else if ((this.applyState === 5 || this.applyState === 12 || this.applyState === 13)
                            && this.interview.confirm === 0) { //면접일정제안
                            this.applyStateList.push({
                                title: '지원자가 면접일정 검토중이에요.',
                                message: '지원자가 제안받은 일정 중 한 가지를 선택하면 면접이 확정됩니다.',
                                button: ['면접일정재발송']
                            });
                        } else if ((this.applyState === 5 || this.applyState === 12 || this.applyState === 13)
                            && this.interview.confirm === 1 && !moment().isAfter(moment(this.interview.startdate))) { //면접일정확정
                            this.applyStateList.push({
                                title: '면접이 확정되었어요.',
                                message: '면접 예정일: ' + moment(this.interview.startdate).format('YYYY년 MM월 DD일 HH시')
                            });
                        } else if (this.applyState === 13 && this.interview.manual === 1) { //면접 일정 - 기업이 직접 연락
                            this.applyStateList.push({
                                title: '면접을 확정하세요.',
                                message: '지원자와 협의한 면접일정을 아래 버튼을 눌러 입력할 수 있습니다.',
                                button: ['면접일정입력']
                            });
                        } else if ((this.applyState === 5 || this.applyState === 12 || this.applyState === 13)
                            && this.interview.confirm === 1 && moment().isAfter(moment(this.interview.startdate))) { //면접결과입력
                            this.applyStateList.push({
                                title: '면접 결과를 입력하세요.',
                                message: '면접 평가 결과를 입력하세요.',
                                button: ['면접불합격', '추가면접', '최종합격', '면접불참']
                            });
                        }
                        */
						//면접불합격
						//없음
						this.isShow = false;
					} else if (this.applyState === 12) {
						//추가면접

						let button = ['면접일정재발송', '지원자 제외하기'];

						this.applyStateList.push({
							title: '지원자가 면접일정을 확정중이에요.',
							message: '지원자가 제안 받은 일정 중 한 가지를 선택하면 면접이 확정됩니다.',
							button: button,
						});
					} else if (this.applyState === 7 && !this.applyInfo.pass_date) {
						//최종합격

						//let button =  ['입사일입력','지원자 제외하기'];
						let button = ['지원자 제외하기'];

						this.applyStateList.push({
							title: '입사일을 확정하세요.',
							message: '딱 맞는 인재 채용을 축하드립니다. 입사 여부 및 입사일 협의를 진행하세요.',
							button: button,
						});
					} else if (
						this.applyState === 8 ||
						this.applyState === 11 ||
						(this.applyState === 7 && this.applyInfo.pass_date)
					) {
						this.applyStateList.push({
							title: '전형이 종료되었습니다.',
							message: '전형이 종료되었습니다. 전형에 참여해주셔서 감사합니다.',
						});
					}
				}
			}
		});
	},
	methods: {
		transform(props) {
			Object.entries(props).forEach(([key, value]) => {
				const digits = value < 10 ? `0${value}` : value;
				props[key] = `${digits}`;
			});

			return props;
		},
		progressBtn(name) {
			switch (name) {
				case '지원취소':
					this.show = true;
					this.title = '지원 취소';
					this.message = '지원 취소 시 전형이 종료되고 다시 지원할 수 없습니다.<br>진행하시겠습니까?';
					this.index = 0;
					//this.method=progress;8
					break;
				case '서류합격':
					this.show = true;
					this.title = '서류 합격';
					this.message = '서류평가결과를 합격으로 변경합니다.<br>진행하시겠습니까?';
					this.index = 1;
					//this.method=progress;3
					break;
				case '서류불합격':
					this.show = true;
					this.title = '서류 불합격';
					this.message =
						'서류평가결과를 불합격으로 변경합니다.<br>결과는 추후 변경할 수 없으며 불합격 통보는 코멘토에서 자동 발송됩니다.<br> 진행하시겠습니까?';
					this.index = 2;
					//his.method=progress;4
					break;
				case '면접불합격':
					this.show = true;
					this.title = '면접 불합격';
					this.message =
						'면접결과를 불합격으로 변경합니다. 결과는 추후 변경할 수 없습니다. <br> 진행하시겠습니까?';
					this.index = 3;
					//this.method=progress;6
					break;
				case '면접불참':
					this.show = true;
					this.title = '면접 불참';
					this.message = '면접 예정자의 면접 불참으로 면접이 취소되었습니다.<br>결과입력을 진행하시겠습니까?';
					this.index = 4;
					//this.method=progress;11
					break;
				case '면접일정잡기':
					this.show = true;
					this.title = '면접일정잡기';
					this.message =
						'시스템을 통해 지원자와 편리하게 면접일정을 확정합니다. <br>면접일정제안 화면으로 이동할까요?';
					this.index = 5;
					//this.method=progress;// /interview/form/'+this.applyNo;
					break;
				case '직접연락':
					this.show = true;
					this.title = '직접 연락';
					this.message =
						'지원자와 직접 연락하여 면접일정을 조율합니다.<br>코멘토의 면접일정잡기를 이용하면 시스템을 통해 면접일정을 편리하게 확정할 수 있습니다.<br>그래도 진행하시겠습니까?';
					this.index = 6;
					//this.method=progress;// /interview/manual/'+this.applyNo;
					break;
				case '면접일정재발송':
					this.show = true;
					this.title = '면접일정 확정';
					this.message =
						'지원자와 면접일정 조율 방법을 선택하세요.<br>시스템을 이용하면 전화나 메일 작성 없이 편리하게 일정을 잡을 수 있습니다.';
					this.index = 7;
					//this.method=progress;///interview/form/'+this.applyNo;
					break;
				case '추가면접':
					this.show = true;
					this.title = '추가면접 진행';
					this.message = '지원자에게 추가면접을 제안합니다. <br> 진행하시겠습니까?';
					this.index = 8;
					//this.method=progress;///interview/form/'+this.applyNo;12
					break;
				case '최종합격':
					this.show = true;
					this.title = '최종합격';
					this.message =
						'전형결과를 최종합격으로 변경합니다.<br>최종 입사여부 및 일정은 기업과 지원자가 별도 조율 후 일정 입력을 부탁드립니다.<br> 진행하시겠습니까?';
					this.index = 9;
					//this.method=progress;///interview/form/'+this.applyNo;7
					break;
				case '입사일입력':
					this.show = true;
					this.title = '입사일 입력';
					this.message = '최종합격자의 입사일을 입력합니다. <br> 진행하시겠습니까?';
					this.index = 10;
					//this.method=progress;///work/'+this.applyNo;
					break;
				case '관심도확인':
					this.show = true;
					this.title = '관심도 확인';
					this.message =
						'검색된 인재가 우리 회사에 관심이 있는지 확인합니다.<br>관심을 보이면 메일로 안내드립니다. <br> 진행하시겠습니까?';
					this.index = 11;
					//this.method=progress;
					break;
				case '바로면접제안':
					this.show = true;
					this.title = '면접제안';
					this.message =
						'면접을 제안합니다.<br>기업의 면접 제안을 수락하면 면접이 확정됩니다.<br>확인을 누르면 면접 제안 발송화면으로 이동합니다.';
					this.index = 12;
					//this.method=progress;///interview/form/'+this.applyNo;
					break;
				case '지원자 제외하기':
					this.show = true;
					this.title = '지원자 제외';
					this.message =
						'이 지원자를 다시 보지 않습니다.<br>제외된 지원자는 복구가 불가능합니다. <br>진행하시겠습니까?';
					this.index = 13;
					break;
				case '면접일정입력':
					this.show = true;
					this.title = '면접일정입력';
					this.message = '지원자와 협의한 면접일정을 입력해주세요.';
					this.index = 14;
					break;
				case '면접일정확정': //(구)면접일정확인
					this.show = true;
					this.title = '면접일정확정';
					this.message = '기업이 제안한 면접 일정을 확인하고 골라주세요.';
					this.index = 15;
					break;
				case '관심있어요':
					this.show = true;
					this.title = '관심있어요';
					this.message = '관심있어요를 누르면, 채용담당자에게 이름과 연락처가 공개됩니다.';
					this.index = 16;
					break;
			}
		},
		progress() {
			let method = 'POST';
			//let url = '/api/apply/status/change';
			let url = '/api/apply/status/refact';
			let data = {
				apply_no: this.applyNo,
				interview_date: new Date().getTime(),
			};

			switch (this.index) {
				case 0: //지원취소
					data.status = 8;
					break;
				case 1: //서류합격
					data.status = 5;
					break;
				case 2: //서류불합격
					data.status = 4;
					break;
				case 3: //면접불합격
					data.status = 6;
					break;
				case 4: //면접 불참
					data.status = 11;
					break;
				case 6: //직접연락
					method = 'PATCH';
					url = '/api/interview/manual/offer';
					break;
				case 11: //관심도확인
					url = '/api/auto/interest';
					break;
				case 5:
				case 7:
				case 8:
				case 12: //면접일정잡기 면접일정재발송 추가면접 바로면접제안
					if (parseInt(this.pmCompanyUserType) !== 9) {
						url = this.applyInfo.com_no;

						if (this.applyInfo.user_type === 5) {
							url += '?apply_no=' + this.applyInfo.no;
						}
						// console.log(this.applyInfo);
						// console.log(url);
						location.href = '/interview/form/' + url;
					} else {
						url = '/api/jobfair/interview/offer';
						break;
					}
					break;
				case 9: //최종합격
					data.status = 7;
					break;
				case 10: //입사일입력
					location.href = '/work/' + this.applyInfo.no;
					break;
				case 14:
					location.href = '/interview/manual/' + this.applyInfo.no;
					break;
				case 15:
					location.href = this.interview.purpose;
					break;
				case 16:
					location.href =
						'/auto/interest/' + this.applyAutoInterest.code + '?redirect_url=' + window.location.href;
					break;
				case 13:
					data.status = 4;
					break;
			}

			if (data.apply_no) {
				showLoading();
				axios({
					method: method,
					url: url,
					data: data,
				})
					.then(response => {
						const data = response.data;
						console.log(data);
						let time = 1000;
						// if (data.code === 200) {
						//     if (this.index === 1) {
						//         const item = data.result;
						//         passResume(item.user_no, item.company_no);
						//     } else if (this.index === 8){
						//         const item = data.result;
						//         addInterview(item.user_no, item.company_no);
						//
						//     } else if (this.index === 9){
						//         const item = data.result;
						//         passInterview(item.user_no, item.company_no);
						//
						//     } else if (this.index === 11) {
						//         const item = data.result;
						//         time = 2000;
						//         proposal(item.user_no, item.user_mail, item.user_name, item.link, item.invited, item.company_no, item.com_name, item.com_job);
						//
						//     }else if (this.index === 13) {
						//         history.go(-1);
						//     }
						//     setTimeout(() => {
						//         location.reload();
						//     }, time);
						//
						// } else {
						//     closeLoading();
						// }
						if (this.index === 13) {
							history.go(-1);
						} else {
							location.reload();
						}
					})
					.catch(error => {
						closeLoading();
						console.log(error);
					});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@caption-color: #9ea5ab;
@bg-btn-color-gray: #f4f5f7;
@primary-color: #2a7de1;
.width-100 {
	width: 100%;
}
.font-msg {
	font-size: 13px;
	line-height: 1.69;
	letter-spacing: normal;
	text-align: left;
}
.flex-index {
	flex: none;
	@media (max-width: 480px) {
		flex: 0 auto;
	}
}
.flex-index-btn {
	margin-left: auto;
}

.check-icon {
	width: 18px;
	height: 18px;
	position: relative;
	top: 3px;
}

.apply-state-group {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.cancel-btn {
	position: relative;
}
@media (max-width: 480px) {
	.apply-state-group {
		flex-direction: column;
		justify-content: normal;
	}

	.btn-group {
		display: block;
		//margin-left: 30px;
		text-align: center;

		.btn {
			margin-top: 10px;
		}
	}
}
</style>
