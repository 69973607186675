<template>
	<section class="section text-center position-relative bg-gray">
		<div class="container text-center mb-30">
			<biz-login-header-component
				msg="Robo-recruiter와 함께,<br>딱 맞는 인재를 만날 수 있습니다."
			></biz-login-header-component>
			<div class="inline-block login-box bg-white">
				<input
					v-model="compName"
					class="inputbox compName float-left f-light font"
					type="text"
					placeholder="이름 (예:홍길동)"
					name="comp_name"
				/>
				<input
					v-model="compPosition"
					class="inputbox compPosition float-right f-light mb-10 font"
					type="text"
					placeholder="직책 (예:대리)"
					name="comp_position"
				/>
				<input
					v-model="compCompany"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="text"
					placeholder="소속 기업/단체명 (예:코멘토)"
					name="comp_company"
				/>
				<input
					v-model="compPhone"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="text"
					placeholder="전화번호 (02-0000-0000)"
					name="comp_phone"
				/>
				<input
					v-model="compMobile"
					class="inputbox inputbox-lg f-light font"
					type="text"
					placeholder="휴대폰 번호 (010-0000-0000)"
					name="comp_mobile"
				/>
				<div class="border-bottom mb-20 mt-20"></div>
				<input
					v-model="compEmail"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="text"
					placeholder="채용관리자 이메일 (예:job@comento.kr)"
					name="comp_email"
				/>
				<input
					v-model="compPw"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="password"
					placeholder="비밀번호 (숫자와 영문자 조합 6자리 이상)"
					name="comp_pw"
				/>
				<input
					v-model="compPw2"
					class="inputbox inputbox-lg mb-10 f-light font"
					type="password"
					placeholder="비밀번호 확인"
					name="comp_pw2"
				/>
				<div v-if="isWarn" class="text-left f-red mb-10">{{ warn }}</div>
				<button class="btn btn-full f-light" @click="register">관리자 계정 만들기</button>
				<div class="text-left mt-10">
					<input id="agree" v-model="check" class="login-setting" type="checkbox" />
					<label class="cursor-pointer f-light h7" for="agree">
						<a class="text-link f-light" href="/agreement.php">이용약관</a> 및
						<a class="text-link f-light" href="/privacy_agree.php">개인정보 처리방침</a>에 동의합니다.
					</label>
				</div>
				<div class="border-bottom mb-20 mt-20"></div>
				<div class="text-right font">
					<span>이미 가입되어 있으신 경우</span>
					<span class="text-link mb-20" onclick="location.href='/logout'"> 로그인</span>
				</div>
			</div>
		</div>
		<biz-footer-component class="position-relative"></biz-footer-component>
	</section>
</template>

<script>
export default {
	props: ['email'],
	data() {
		return {
			compName: '',
			compPosition: '',
			compCompany: '',
			compPhone: '',
			compMobile: '',
			compEmail: this.email,
			compPw: '',
			compPw2: '',
			check: false,
			isWarn: false,
			warn: '',
		};
	},
	methods: {
		register() {
			let emailText = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
			let pwText1 = /[A-Za-z]+[0-9]/;
			let pwText2 = /[0-9]+[A-Za-z]/;

			if (this.compName.length < 1) {
				this.isWarn = true;
				this.warn = '이름을 확인해주세요!';
			} else if (this.compPosition.length < 1) {
				this.isWarn = true;
				this.warn = '직책을 확인해주세요!';
			} else if (this.compCompany.length < 1) {
				this.isWarn = true;
				this.warn = '소속 기업/단체명을 확인해주세요!';
			} else if (this.compPhone.length < 1) {
				this.isWarn = true;
				this.warn = '전화번호를 확인해주세요!';
			} else if (this.compMobile.length < 1) {
				this.isWarn = true;
				this.warn = '휴대폰 번호를 확인해주세요!';
			} else if (emailText.test(this.compEmail) === false) {
				this.isWarn = true;
				this.warn = '채용관리자 이메일을 확인해주세요!';
			} else if ((pwText1.test(this.compPw) || pwText2.test(this.compPw)) === false || this.compPw.length < 6) {
				this.isWarn = true;
				this.warn = '비밀번호는 6자리 이상(숫자+영문자)이어야 합니다!';
			} else if (this.compPw !== this.compPw2) {
				this.isWarn = true;
				this.warn = '비밀번호를 확인해주세요!';
			} else if (this.check === false) {
				this.isWarn = true;
				this.warn = '이용약관 및 개인정보 처리방침을 동의해주세요!';
			} else {
				showLoading();
				axios({
					method: 'POST',
					url: '/api/user/register',
					data: {
						name: this.compName,
						position: this.compPosition,
						company: this.compCompany,
						phone: this.compPhone,
						mobile: this.compMobile,
						email: this.compEmail,
						password: this.compPw,
						type: 'comento',
					},
				})
					.then(response => {
						console.log(response.data);
						let code = parseInt(response.data.code);
						if (code === 200) {
							this.isWarn = false;
							window.location.href = '/dashboard';
						} else if (code === 201) {
							closeLoading();
							this.isWarn = true;
							this.warn = '유효하지 않은 도메인입니다!';
						} else {
							closeLoading();
							if (response.data.errors.email) {
								this.isWarn = true;
								this.warn = '동일한 이메일이 있습니다!';
							} else {
								this.isWarn = true;
								this.warn = '다시 확인해주세요!';
							}
						}
					})
					.catch(error => {
						closeLoading();
						console.log(error);
					});
			}
		},
	},
};
</script>
