//filter/phoneNumberFormat.js

function phoneNumberFormat(value = '') {
	//특수문자 제거
	let regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
	if (regExp.test(value)) value = value.replace(regExp, '');

	if (value.length === 11) {
		return value.substr(0, 3) + '-' + value.substr(3, 4) + '-' + value.substr(7, 4);
	} else if (value.length === 10) {
		return value.substr(0, 3) + '-' + value.substr(3, 3) + '-' + value.substr(6, 4);
	}
}

export default phoneNumberFormat;
