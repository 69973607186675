<template>
	<section class="blog-menu-wrapper" :class="pageName">
		<v-select
			ref="blogMenuFilter"
			:value="magazine"
			:options="menus"
			label="description"
			:searchable="false"
			class="v-select-filter"
			@input="setSelected"
		></v-select>
		<!--        <template v-if="pageName === 'list'">-->
		<!--            <img :src="searchBarIcon"-->
		<!--                 class="icon-search"-->
		<!--                 @click="clickSearch()"-->
		<!--            />-->
		<!--            <div class="blog-search-bar"-->
		<!--                 ref="blogSearchBar"-->
		<!--                 v-show="isShowSearchBar"-->
		<!--            >-->
		<!--                <input type="text"-->
		<!--                       class="blog-search-input"-->
		<!--                       v-model="blogKeyword"-->
		<!--                       placeholder="검색어를 입력하세요"-->
		<!--                />-->
		<!--                <img src="/images/search_green.svg"-->
		<!--                     class="icon-search"-->
		<!--                     @click="searchKeyword"-->
		<!--                />-->
		<!--            </div>-->
		<!--        </template>-->
	</section>
</template>

<script>
export default {
	props: {
		pageName: {
			type: String,
			default: null,
		},
		menus: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data: () => ({
		magazine: '전체 글',
		blogMagazines: [],
		blogKeyword: null,
		isShowSearchBar: false,
	}),
	computed: {
		searchBarIcon() {
			let icon = 'search_green';
			if (this.isShowSearchBar) icon = 'gray_dark_remove';
			return `/images/${icon}.svg`;
		},
	},
	watch: {
		menus: 'selectedMagazine',
	},
	methods: {
		setSelected(value) {
			this.$nextTick(() => {
				let filterText = value;
				if (value !== '전체 글') filterText = value.description;

				//width 사이즈 조정
				const blogMenuFilter = this.$refs.blogMenuFilter;
				const selectedTag = blogMenuFilter.$el.children[0].children[0].children[0];
				blogMenuFilter.$el.style.width = `${selectedTag.offsetWidth + 25}px`;

				if (value !== '전체 글') {
					if (this.$route.name === 'blog-index') {
						this.$router.push(`/blog/list/${value.description}`);
					} else if (this.$route.name === 'blog-list') {
						if (value.description !== this.$route.params.magazine) {
							this.$router.push(`/blog/list/${value.description}`);
						}
					}
				}
			});
		},
		selectedMagazine() {
			this.menus.forEach(magazine => {
				if (magazine.description === this.$route.params.magazine) {
					this.magazine = magazine;
				}
			});
		},
		clickSearch() {
			this.isShowSearchBar = !this.isShowSearchBar;
		},
	},
};
</script>
