import Vue from 'vue';
import stickybits from 'stickybits';

Vue.directive('stickybits', {
	inserted(el, options) {
		stickybits(el, {
			scrollEl: window,
			parentClass: 'js-stickybit-parent',
			stickyClass: 'js-is-sticky',
			stuckClass: 'js-is-stuck',
			useFixed: true,
			stickyBitStickyOffset: options.value.offset || 5,
			verticalPosition: 'top',
		});
	},
});
