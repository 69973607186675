<template>
	<div>
		<c-box :paddings="[16, 16, 16, 16]" background-color="white">
			<BaseSkeleton width="173px" height="24px" class="mb-8" />
			<div class="flex flex-row align-items-center mb-20">
				<BaseSkeleton width="24px" height="24px" circle />
				<BaseSkeleton width="32px" height="16px" class="mx-6" />
				<BaseSkeleton width="32px" height="16px" />
			</div>
			<div class="content-wrapper">
				<BaseSkeleton width="100%" height="16px" />
				<BaseSkeleton width="100%" height="16px" />
				<BaseSkeleton width="100%" height="16px" />
				<BaseSkeleton width="100%" height="16px" />
				<BaseSkeleton width="80%" height="16px" />
			</div>
		</c-box>
		<section class="answer-section">
			<c-box
				v-for="item in 2"
				:key="`answer-${item}`"
				:paddings="[item === 1 ? 16 : 8, 16, 16, 16]"
				background-color="white"
			>
				<div class="flex flex-row mb-20">
					<BaseSkeleton width="40px" height="40px" circle />
					<div class="flex flex-column ml-8">
						<BaseSkeleton width="88px" height="16px" class="mb-8" />
						<BaseSkeleton width="88px" height="16px" />
					</div>
				</div>
				<BaseSkeleton width="100%" :height="item === 1 ? '112px' : '44px'" />
			</c-box>
		</section>
	</div>
</template>

<script>
import BaseSkeleton from '../common/BaseSkeleton.vue';

export default {
	name: 'CommunityDetailSkeleton',
	components: { BaseSkeleton },
};
</script>

<style lang="scss" scoped>
@import 'resources/assets/scss/base/main';

.content-wrapper {
	.skeleton {
		margin-bottom: 8px;
	}
}
.answer-section {
	background: $gray100;
	padding-top: 24px;
	padding-bottom: calc(100vh - 630px);
}
</style>
