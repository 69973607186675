<template>
	<div>
		<div class="font-title-big f-semi-bold mb-10">{{ main }}</div>
		<div class="font-title">{{ sub1 }}</div>
		<div v-if="sub2" class="font text-caption f-light sub_title mb-20">{{ sub2 }}</div>
	</div>
</template>

<script>
export default {
	props: ['main', 'sub1', 'sub2'],
};
</script>
