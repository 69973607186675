<template>
	<div>
		<quill-editor
			ref="quillEditor"
			v-model="sync_content"
			class="editor"
			:style="computedBorderColor"
			:options="editorOption"
			v-on="$listeners"
		/>
	</div>
</template>

<script>
import { Quill } from 'vue-quill-editor';
import { ImageExtend, QuillWatch } from 'quill-image-extend-module';
import ImageResize from '@taoqf/quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';
import { colors } from './colors';

Quill.register('modules/ImageExtend', ImageExtend);
Quill.register('modules/ImageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

export default {
	name: 'WysiwygEditor',
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		content: String,
		toolbarOption: Array,
		borderColor: String,
	},
	data: () => ({
		colors: [
			'rgb(0, 0, 0)',
			'rgb(230, 0, 0)',
			'rgb(255, 153, 0)',
			'rgb(255, 255, 0)',
			'rgb(0, 138, 0)',
			'rgb(0, 102, 204)',
			'rgb(153, 51, 255)',
			'rgb(255, 255, 255)',
			'rgb(250, 204, 204)',
			'rgb(255, 235, 204)',
			'rgb(255, 255, 204)',
			'rgb(204, 232, 204)',
			'rgb(204, 224, 245)',
			'rgb(235, 214, 255)',
			'rgb(187, 187, 187)',
			'rgb(240, 102, 102)',
			'rgb(255, 194, 102)',
			'rgb(255, 255, 102)',
			'rgb(102, 185, 102)',
			'rgb(102, 163, 224)',
			'rgb(194, 133, 255)',
		],
	}),
	computed: {
		computedBorderColor() {
			return { borderColor: colors[this.borderColor] };
		},
		aligns() {
			const pcAlign = [{ align: '' }, { align: 'center' }, { align: 'right' }];
			const mobileAlign = [{ align: ['', 'center'] }];
			return this.isMobile ? mobileAlign : pcAlign;
		},
		editorOption() {
			const defaultToolbarOption = [
				[{ header: [1, 2, 3, false, 5, 6] }],
				['bold', 'italic', 'underline', 'strike'],
				this.aligns,
				[{ list: 'ordered' }, { list: 'bullet' }],
				[{ color: [] }, { background: [] }],
				['link', 'image', 'video'],
				// ["clean"],
				// ["blockquote", "divider", "code-block"],
			];
			const editorOption = {
				modules: {
					toolbar: {
						container: this.toolbarOption || defaultToolbarOption,
						handlers: {
							image() {
								QuillWatch.emit(this.quill.id);
							},
						},
					},
					ImageExtend: {
						loading: true,
						name: 'image',
						action: '/api/blog/imageUpload',
						response: res => {
							console.log(res);
							return res.url;
						},
					},
					ImageResize: {
						modules: ['Resize', 'DisplaySize'],
					},
				},
				placeholder: this.placeholder || '내용을 입력하세요',
			};

			return editorOption;
		},
		sync_content: {
			get() {
				return this.content;
			},
			set(value) {
				this.$emit('update:content', value);
			},
		},
	},
};
</script>

<style lang="less" scoped>
::v-deep.ql-tooltip[data-mode='video'] {
	/* video modal */
	left: 50% !important;
	margin-left: -30px !important;

	@media (max-width: 480px) {
		margin-left: 0 !important;
		transform: translateX(-50%);
		top: 10px !important;
	}
}

::v-deep.ql-snow .ql-picker.ql-header {
	.ql-picker-item:before,
	.ql-picker-label:before {
		content: '본문1';
		position: relative;
		top: -2px;
	}
	.ql-picker-item[data-value='1']:before,
	.ql-picker-label[data-value='1']:before {
		content: '제목1';
	}
	.ql-picker-item[data-value='2']:before,
	.ql-picker-label[data-value='2']:before {
		content: '제목2';
	}
	.ql-picker-item[data-value='3']:before,
	.ql-picker-label[data-value='3']:before {
		content: '제목3';
	}
	.ql-picker-item[data-value='5']:before,
	.ql-picker-label[data-value='5']:before {
		content: '본문2';
	}
	.ql-picker-item[data-value='6']:before,
	.ql-picker-label[data-value='6']:before {
		content: '캡션';
	}
}

::v-deep.ql-editor.ql-blank::before {
	content: none;
}

::v-deep.ql-editor.ql-blank::after {
	color: #adb5bd;
	content: attr(data-placeholder);
	display: block;
	margin-top: -1.42em;
	min-height: 1em;
	pointer-events: none;
}
</style>
