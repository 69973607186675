/**
 * 쿼리 파라미터로 이미지를 리사이징하는 함수
 * @param width: 너비 (필수)
 * @param height: 높이 (필수)
 // * @param format: 파일 확장자('jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'tiff') (선택)
 // * @param type: 이미지 타입 ('crop', 'cover', 'contain', 'fill', 'inside', 'outside') (선택)
 // * @param quality: 화질 (선택)
 * @return String
 */
function imageResize(width, height) {
	const hasWidth = width && typeof width === 'number';
	const hasHeight = height && typeof height === 'number';
	const hasSize = hasWidth && hasHeight;
	let sizeParam = '';

	if (hasSize) {
		sizeParam = `s=${width}x${height}`;
	}
	const isIos13 =
		navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); /* iPad OS 13 */

	/*ie 11 이하거나  ios13 이하인 경우에는 jpeg로 보이도록 반영*/
	if (document.documentMode <= 11 || isIos13) {
		return `?${sizeParam}&f=jpeg`;
	}
	return `?${sizeParam}`;
}

export { imageResize };
