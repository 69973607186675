<template>
	<header v-if="!isMobile" class="couponBanner">
		<img class="couponBanner_img" src="/images/banner/banner_couponBook.svg" :alt="mainTitle + subTitle" />
		<div class="couponBanner_text">
			<c-typography type="headline1" color="gray900" element="h2" align="center">
				{{ mainTitle }}
				<c-typography type="headline7" color="gray700" element="p" align="center" class="mt-8 f-regular">
					{{ subTitle }}
				</c-typography>
			</c-typography>
		</div>
	</header>
</template>

<script>
export default {
	name: 'CouponBanner',
	props: {
		mainTitle: {
			type: String,
			default: '',
		},
		subTitle: {
			type: String,
			default: '',
		},
	},
};
</script>

<style scoped lang="less">
@import '../../../less/base/index';
.couponBanner {
	margin: 32px 0 40px 0;
	position: relative;
	&_img {
		display: block;
		vertical-align: top;
		width: 100%;
		border-radius: 20px;
	}
	&_text {
		position: absolute;
		top: 50%;
		left: 50%;
		.translate(-50%,-50%);
	}
}
</style>
