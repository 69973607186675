<template>
	<modal-component :show="show" @close="close">
		<div class="hidden-box-remove"></div>
		<div id="confirm-component" class="modal-body text-center">
			<div class="mb-15">
				<span class="popup-title text-center h4 highlight-underline-big" style="margin-left: 25px">{{
					title
				}}</span>
			</div>
			<div class="text-heading mb-20">
				<p style="color: #212529; word-break: break-word" v-html="$options.filters.nl2br(message)"></p>
			</div>
			<div class="mt-20 flex flex-row">
				<button class="btn btn-full mr-5" @click="save">{{ saveButtonName }}</button>
				<button class="btn btn-empty btn-full" @click="out">{{ closeButtonName }}</button>
			</div>
		</div>
	</modal-component>
</template>

<script>
// eslint-disable-next-line import/default
import resumePathMixin from '../../../mixins/resumePathMixin';

export default {
	name: 'ResumeConfirmComponent',
	mixins: [resumePathMixin],
	props: ['show', 'title', 'message', 'saveButtonName', 'closeButtonName'],
	methods: {
		close() {
			this.$emit('close');
		},
		out() {
			this.$emit('close');
			this.$emit('out');
			window.location.href = this.$_resumeIndexPath;
		},
		save() {
			this.$emit('save');
			this.$emit('close');
		},
	},
};
</script>
