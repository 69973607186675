<template>
	<c-new-col id="section" class="pb-0" :class="{ 'pt-50': !isMobile }">
		<!--v-if="userType !== undefined && parseInt(userType) >= 1"-->
		<div class="resume-builder">
			<div class="resume-tutorial-section" :class="tutorialImage">
				<div class="container">
					<div class="underline-black"></div>
					<p class="tutorial-main-message" v-text="tutorialTitle"></p>
					<p class="tutorial-title inline-block" v-html="tutorialMainTitle"></p>
					<div :style="contentStyle">
						<div v-html="tutorialContent"></div>
						<div class="mobile-item" :style="mobileStyle">
							<img v-if="tutorialStep === 1" src="/images/resume/resume-graph-m.jpg" />
							<img v-if="tutorialStep === 2" src="/images/resume/what_is_resume.jpg" />
							<img
								v-if="tutorialStep === 3"
								id="tutorial-flow"
								src="/images/resume/resume-tutorial-flow.png"
							/>
						</div>
						<div v-if="tutorialStep === 3" class="flex tutorial-step only-pc">
							<div v-for="(item, index) in tutorialItem" class="flex">
								<div
									class="flex flex-column flex-v-middle"
									:style="index === 3 ? 'margin-left: -10px' : ''"
								>
									<div class="flex flex-row flex-v-middle">
										<div class="text-center feature-item">
											<img :src="'/images/' + item.image + '.svg'" />
										</div>
										<img v-if="index < 3" src="/images/arrow-right-gray.svg" class="arrow" />
									</div>
									<span :style="'margin-left:' + item.marginLeft">{{ item.message }}</span>
								</div>
							</div>
						</div>
						<div v-if="tutorialStep === 4" class="text-primary mt-10">
							<span class="pointer" onclick="location.href='/resume/sample'">
								<u>어떤 이력서를 얻게 되나요? (예시)</u>
							</span>
						</div>
						<div :style="tutorialButton">
							<c-button
								type="fill"
								:size="isMobile ? 'medium' : 'large'"
								color="primary"
								:full="isMobile"
								class="mb-10"
								:style="isMobile ? '' : 'width: 120px'"
								@click="nextTutorialStep"
								v-text="tutorialStep < 4 ? '다음' : '시작하기'"
							>
								>
							</c-button>
							<c-narrow-button
								v-if="tutorialStep < 4"
								size="medium"
								:class="{ 'mx-auto': isMobile }"
								@click="skipTutorialStep"
							>
								SKIP >>
							</c-narrow-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<JoinAdPopupComponent v-if="showJoinAdPopup" page="resume" />
		<alert-modal-component
			:show="showAlert"
			type="simple"
			:title="title"
			:message="message"
			:buttonName="buttonName"
			:successCallback="successCallback"
			@close="showAlert = false"
		></alert-modal-component>
	</c-new-col>
</template>

<script>
import { mapMutations } from 'vuex';
import JoinAdPopupComponent from '../../../components/_common/JoinAdPopupComponent.vue';

export default {
	props: ['userType', 'userNo'],
	data() {
		return {
			tutorialStep: 1,
			tutorialItem: [
				{ image: 'suitcase', message: '직무가 하는 일', marginLeft: '-80px' },
				{ image: 'diamond', message: '직무 우수자의 모습', marginLeft: '-80px' },
				{ image: 'mine', message: '나의 강점', marginLeft: '-80px' },
				{ image: 'mentoring', message: '강점관련 경험', marginLeft: '0' },
			],
			showAlert: false,
			title: '',
			message: '',
			buttonName: '',
			successCallback: '',
			showJoinAdPopup: false,
		};
	},
	computed: {
		tutorialImage() {
			let image = '';
			if (this.tutorialStep === 1) {
				image = 'resume-graph';
			} else if (this.tutorialStep === 2) {
				image = 'resume-not-timeline';
			} else if (this.tutorialStep === 4) {
				image = 'resume-tutorial-mockup';
			}
			return image;
		},
		tutorialButton() {
			if (this.isMobile) return 'margin: 40px 0';

			if (this.tutorialStep === 1) {
				return 'margin: 110px 20px 180px 0';
			}
			return 'margin: 50px 20px 180px 0';
		},
		tutorialTitle() {
			let title = '';

			if (this.tutorialStep === 1) {
				title = '이력서가 정말 중요할까?';
			} else if (this.tutorialStep === 2) {
				title = '이력서란 무엇인가?';
			} else if (this.tutorialStep === 3) {
				title = '좋은 이력서란?';
			} else if (this.tutorialStep === 4) {
				title = '코멘토 이력서 튜토리얼';
			}
			return title;
		},
		tutorialMainTitle() {
			let title = '';

			if (this.tutorialStep === 1) {
				title = '평가자는 이력서를 <br class="only-mobile">가장 먼저 읽는다!';
			} else if (this.tutorialStep === 2) {
				title = '이력서는 연대표가 아니다!';
			} else if (this.tutorialStep === 3) {
				title = '요구역량과 내 경험이<br>잘 연결된 이력서';
			} else if (this.tutorialStep === 4) {
				title =
					'1:1 전문 이력서 코칭<span class="only-pc">을 받은 듯</span>' +
					'<span class="only-mobile">처럼</span><br>높은 품질의 이력서 완성';
			}

			return title;
		},
		tutorialContent() {
			let content = '';

			if (this.tutorialStep === 1) {
				content =
					'많은 기업이 채용 시스템을 통해 이력서를 접수 <br class="only-mobile">받기 때문에, <br class="only-pc">' +
					'국내에서는 구직자들이 이력서를 <br class="only-mobile">중요하게 생각하지 않는 경향이 있으며 <br class="only-pc">' +
					'일반적으로 <br class="only-mobile">이력서에 가장 적은 시간을 투자합니다.<br><br>' +
					'하지만, 평가자는 지원자가 가장 소홀하게 준비한 <br class="only-mobile">이력서를 첫번째로 평가하며,<br>' +
					'<span class="highlight-text">이력서를 통해 지원자가 인재상에 적합한지 <br class="only-mobile">' +
					'1차적으로 판단</span>합니다.<br><br>' +
					'이 단계를 통과하기 못한다면 정성스럽게 작성한 <br class="only-mobile">자기소개서는 읽힐 기회조차 없을 수도 있습니다.<br>' +
					'따라서 <span class="highlight-text">좋은 이력서를 작성하는 것은 채용 관점에서 ' +
					'<br class="only-mobile">아주 중요</span>합니다.';
			} else if (this.tutorialStep === 2) {
				content =
					'연대표와 이력서. 두 자료 모두 어떤 활동을 언제부터 <br class="only-mobile">' +
					'실행했는지를 기술하고 있지만, <br class="only-pc">' +
					'왼쪽 자료는 <br class="only-mobile">이력서라고 부르지 않고 연대표라 부릅니다.<br><br>' +
					'이력서는 지원자의 역사를 기록하는 것이 아니라<br>' +
					'<span class="highlight-text">경험의 내용을 통해 앞으로 어떤 일을 할 수 있는 ' +
					'<br class="only-mobile">인재인지 표현하는 것</span>' +
					'이기 때문입니다.<br><br>' +
					'쉽게 말해 이력서는 자기소개서의 요약본이라고 <br class="only-mobile">할 수 있습니다.';
			} else if (this.tutorialStep === 3) {
				content =
					'좋은 이력서는 좋은 경험에서 시작합니다.<br>' +
					'대부분의 구직자들은 본인이 좋은 경험이 없다고 <br class="only-mobile">생각하지만, <br class="only-pc">' +
					'<span class="only-pc">사실은 </span>이미 좋은 경험을 가지고 있습니다.<br>' +
					'그렇다면 그 경험들을 이력서에 옮겨 담지 못하는 <br class="only-mobile">이유는 무엇일까요?<br><br>' +
					'첫째, 본인의 훌륭한 경험을 과소 평가하여 이력서에 <br class="only-mobile">적을 수 없는 사소한 내용으로 생각하거나<br>' +
					'둘째, 본인의 경험이 직무와 어떻게 연결되는지를 <br class="only-mobile">찾지 못하여 제외시키기 때문입니다.<br><br>' +
					'따라서 좋은 이력서를 쓰기 위해서는 지원하고자 <br class="only-mobile">하는 <span class="highlight-text">' +
					'직무의 업무를 이해하고, <br class="only-pc">그 업무가 요구하는 <br class="only-mobile">역량과 나의 강점. ' +
					'그리고 그 강점을 뒷받침하는 <br class="only-mobile">경험을 일관성 있게 <br class="only-pc">' +
					'연결</span>할 수 있어야 합니다.<br><br>' +
					'본 튜토리얼을 마치면 직무 업무와 역량, 나의 강점과 <br class="only-mobile">경험의 연결 고리를 발견하고 ' +
					'<br class="only-pc">' +
					'좋은 이력서를 작성하기 <br class="only-mobile">위한 초안을 완성하게 될 것입니다.';
			} else if (this.tutorialStep === 4) {
				content =
					'본 이력서 작성 튜토리얼은 <span class="highlight-text">실제 오프라인에서 ' +
					'<br class="only-mobile">진행되는 1:1 이력서 코칭 과정과 동일</span>합니다.<br>' +
					'이력서에 대한 전문 지식이 없는 사람도 전문가와 <br class="only-mobile">' +
					'함께 작성하는 것처럼 높은 품질의 이력서를 만들 수 <br class="only-mobile">있을 것입니다.<br><br>' +
					'다만, 튜토리얼 가이드의 내용은 절대적인 것이 <br class="only-mobile">아니며,<br class="only-pc">' +
					'좋은 이력서 작성의 초석이 되는 내용을 <br class="only-mobile">' +
					'함께 고민하고 한 개인이 갖고 있는 경험을  <br class="only-mobile">' +
					'최대한 끌어내는 데<br class="only-pc"> 도움을 주도록 설계되었습니다.';
			}

			return content;
		},
		mobileStyle() {
			if (this.isMobile) {
				if (this.tutorialStep === 3) {
					return 'width: 100vw;' + 'margin-left: calc(-50vw + 50%);';
				} else {
					return 'display: block';
				}
			} else {
				return 'display: none';
			}
		},
		contentStyle() {
			if (this.tutorialStep === 3) {
				if (!this.isMobile) {
					return 'margin-top: 120px; float: right';
				}
			}
		},
	},
	created() {
		this.tutorialStep = parseInt(this.$route.params.no);
	},
	mounted() {
		this.setMeta({
			title: '이력서 튜토리얼',
			isDetail: true,
			isShowFooter: false,
			fluid: !this.isMobile,
		});

		this.$nextTick(() => {
			if (this.isMobile && this.tutorialStep === 3) {
				if (document.querySelector('#tutorial-flow').clientHeight > 0) {
					document.querySelector('.mobile-item').style.height =
						document.querySelector('#answer_tutorial-flow').clientHeight;
				}
			}
		});
	},
	beforeRouteUpdate(to, from, next) {
		this.tutorialStep = parseInt(to.params.no);
		next();
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		nextTutorialStep() {
			if (this.tutorialStep < 4) {
				this.tutorialStep += 1;
				if (window.innerWidth > 800) {
					this.$router
						.push({
							params: { no: this.tutorialStep },
						})
						.catch(() => {});
				} else {
					this.$router
						.replace({
							params: { no: this.tutorialStep },
						})
						.catch(() => {});
				}
				this.scrollTo('section', 500, -120);
			} else {
				//GTM - finishTutorial
				finishTutorial(this.userNo, this.userType);
				// GTM - GAVirtualPageview
				GAVirtualPageview(this.$route.path);

				if (this.userType !== undefined && this.userType >= 1) {
					if (window.innerWidth < 800) {
						this.title = 'PC에서만 가능';
						this.message = '코멘토와 작성하기는<br>PC에서만 진행하실 수 있습니다.';
						this.buttonName = '';
						this.successCallback = '';
						this.showAlert = true;
						return false;
					} else {
						window.location.href = '/resume/builder';
					}
				} else {
					this.showJoinAdPopup = true;
					return false;

					// this.title = '로그인 필요';
					// this.message = '소셜계정으로 <span class="text-error">1초만에 가입</span>하고<br>' +
					//     '높은 품질의 이력서를 만들어 보세요!';
					// this.buttonName = '가입하기';
					// this.successCallback = '/resume/builder';
					// this.showAlert = true;
				}
			}
		},
		skipTutorialStep() {
			this.$router
				.push({
					params: { no: 4 },
				})
				.catch(() => {});
			this.scrollTo('section', 500, -120);
		},
	},
	components: { JoinAdPopupComponent },
};
</script>
