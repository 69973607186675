<template>
	<c-new-col id="section" style="min-height: 540px" :class="{ 'pt-50': !isMobile }">
		<div v-if="isVisible" class="resume-builder">
			<GridLayout>
				<c-new-col>
					<GridLayout>
						<c-new-col :col-lg="6" :offset-lg="3">
							<EssayTutorialNavigatorNew
								:step.sync="step"
								:final-step="parseInt(finalStep)"
								:description-step.sync="descriptionStep"
								:is-completion="isCompletion"
								class="mb-60"
							/>
						</c-new-col>
					</GridLayout>

					<div class="resume-builder-section">
						<!-- 목록으로 -->
						<resume-exit page="essay-tutorial-builder" @saveResumeConfirm="saveConfirm" />
						<div v-if="step >= 3 && step !== 5 && step !== 7 && step !== 8" id="description-section">
							<div class="text-left mb-10" v-text="descriptionTitle"></div>
							<div
								class="well text-left"
								:class="{ 'mb-50': descriptionStep }"
								v-html="$options.filters.nl2br(descriptionMessage)"
							/>
						</div>
						<div v-else-if="step === 7" class="mb-50">
							<!-- 자가진단 체크리스트 -->
							<p class="mb-20">
								{{ job }} 직무 지원을 위한 ‘{{ competencyName }}’ 관련 경험 정리가 완료되었습니다.
							</p>
							<div class="mb-20">
								<div
									v-for="(experience, index) in experienceList"
									:key="`experience-${index}`"
									class="btn btn-square inline-block mr-5 mb-10"
									:class="nowEpisodeExperienceNo === index ? 'btn-outline' : 'btn-empty'"
									@click="nowEpisodeExperienceNo = index"
								>
									{{ index + 1 }}
								</div>
								<div class="well">
									<c-typography
										type="headline5"
										color="gray850"
										font-weight="semi-bold"
										v-html="experienceList[nowEpisodeExperienceNo].title"
									/>
									<c-typography
										color="gray850"
										v-html="`• ${experienceList[nowEpisodeExperienceNo].experience[0].contents}`"
									/>
								</div>
							</div>
							<EssaySelfCheckList
								:job="job"
								:job-detail="jobDetail"
								:job-detail-excellence="jobDetailExcellence"
								:competency-name="competencyName"
								:competency-detail="competencyDetail"
								class="mt-60"
								@changeRoute="changeRoute(...arguments)"
								@goResumeBegin="handleSaveEssay"
							/>
						</div>

						<div
							v-if="!descriptionStep && step <= 6"
							id="resumeItem"
							class="resume-item-section"
							:style="isError ? 'margin-bottom: 20px' : ''"
						>
							<div class="resume-item text-center">
								<div class="resume-main-title">
									<span v-html="resumeMainTitle" />
								</div>
								<div v-if="step === 1">
									<div class="resume-item-group">
										<div class="h3 inline-block">저는</div>
										<div class="inline-block">
											<input
												ref="job"
												v-model="job"
												type="text"
												class="job"
												placeholder="예) 영업관리"
											/>
										</div>
										<div class="h3 inline-block">직무에 지원하려고 해요.</div>
									</div>
									<p class="attention-note text-center">
										* 희망 직무가 여러 가지이더라도 반드시 하나만 정해주세요.<br />
										영업/영업관리인 경우에도 둘 중 하나를 선택해야하며, 직무 입력은 구체적일수록
										좋습니다.
									</p>
								</div>
								<div v-if="step === 2">
									<div class="resume-item-group">
										<div class="h3 text-left">제 생각에 {{ job }}은(는)</div>
										<textarea
											ref="jobDetail"
											v-model="jobDetail"
											:placeholder="
												'판매 활동을 직접 하는 것은 아니고, 실제로 판매하는 영업 사원분들이나 ' +
												'대리점 사장님들이 실제로 물건을 더 많이 더 잘 팔 수 있도록 도와주는 일을 한다. 그러기 위해서는 ' +
												'얼마나 팔지 목표도 설정해야 하고, 더 잘 팔 수 있는 프로모션 정책도 만들고 또 판매 과정 중에 ' +
												'문제가 생기는 것들을 듣고 이런 문제들을 해결하도록 여러 부서에 협조를 얻어서 문제도 해결하는 등의 역할을'
											"
										>
										</textarea>
										<div class="h3 text-right">하는 직무인 것 같아요.</div>
									</div>
								</div>
								<div v-if="step === 3">
									<div class="resume-item-group">
										<div class="h3 text-left">제 생각에 {{ job }}직무는</div>
										<textarea
											ref="jobDetailExcellence"
											v-model="jobDetailExcellence"
											:placeholder="
												'1. (이유) 판매를 더 잘할 수 있도록 프로모션이나 가격 정책 등을 잘 수립해야 하기 때문에 (강점) 기획력이 있는 사람\n' +
												'2. (이유) 판매 과정에서 발생하는 문제를 해결하기 위해 다른 부서와 업무 협의를 하고 협조를 이끌어 내야하기 때문에 (강점) 좋은 소통능력이 있는 사람'
											"
										>
										</textarea>
										<div class="h3 text-right">이 잘 수행할 것 같아요.</div>
									</div>
								</div>
								<div v-if="step === 4" class="text-left">
									<div class="resume-item-group">
										<div class="competency-box">
											<div
												v-for="competency in competencyArr"
												:key="competency.no"
												class="competency"
											>
												<input
													type="checkbox"
													class="chk-big"
													:value="competency.name"
													:checked="competency.no === competencyNo"
													@click="
														competencyNo = competency.no;
														competencyName = competency.name;
													"
												/>
												<label
													:for="competency.name"
													class="chk-big"
													@click="
														competencyNo = competency.no;
														competencyName = competency.name;
													"
													@mouseover="viewCompetencyTip(competency.no)"
													@mouseout="competencyTipNo = 0"
												>
													<span v-text="competency.name"></span>
												</label>
												<div v-show="competencyTipNo === competency.no" class="tip-item">
													<div class="alarm-box">
														<div class="alarm-scroll" v-html="resumeCompetencyTip"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-if="step === 5">
									<div class="resume-item-group">
										<div class="h3 text-left">저는 {{ competencyName }}이(가) 뛰어나다는 것을</div>
										<textarea
											ref="competencyDetail"
											v-model="competencyDetail"
											:placeholder="
												'의사소통능력 / 단순히 내가 하고싶은 말을 상대방에게 잘 전달하는 것이 아니라, ' +
												'상대가 공동의 문제에 공감하게 하고 협력을 이끌어 낼 수 있는 것'
											"
										>
										</textarea>
										<div class="h3 text-right">이라고 생각해요.</div>
									</div>
								</div>
								<div v-if="step === 6">
									<div class="resume-item-group text-center">
										<div class="inline-block">
											<input
												ref="experienceTitle"
												v-model="experienceTitle"
												type="text"
												placeholder="스노우보드 동아리"
												class="experience"
											/>
										</div>
										<div class="h3 inline-block">을(를) 할 때</div>
									</div>
									<div class="resume-main-title column">
										<span
											class="highlight-underline"
											v-html="`${competencyName}을(를) 발휘한 에피소드를 자세히 알려주세요.`"
										></span>
									</div>
									<div class="border-box b-primary">
										<p class="text-left mb-10">
											에피소드는 (상황)-(행동)-(결과)를 포함하여 최대한 구체적으로 작성하세요.
										</p>
										<p class="text-left" v-html="resumeExperienceMessage" />
									</div>
									<div class="resume-item-group">
										<textarea
											ref="experienceContents1"
											v-model="experienceContents1"
											style="height: 315px !important"
											class="mb-10"
											placeholder="(상황) 동아리 연말 나눔행사의 예산 부족 문제 해결을 학교 주변 가게 후원으로 해결하기 위해 (행동) 총 47개 매장의 사장님을 만나 나눔행사의 취지를 설명하고, 동아리 단톡방, 페이스북 및 안내 팜플릿 홍보를 약속하여 (결과) 4개 매장의 후원을 이끌어 냄"
										>
										</textarea>
									</div>
								</div>
							</div>
							<div v-if="step < 7 && !descriptionStep">
								<div class="tip-item mb-10">
									<div class="alarm-box">
										<div class="alarm-scroll">
											<div class="tip-title flex align-items-center">
												<c-icon name="IconExclamationSmallLine" color="blue600" class="mr-4" />
												<span v-html="resumeTipTitle" />
											</div>
											<div class="tip-message" v-html="resumeTipMessage" />
											<c-button
												v-if="step === 2"
												type="outline"
												size="large"
												color="primary"
												full
												class="mt-30"
												@click="$_routeMixin_href('/edu')"
											>
												직무부트캠프 보기
											</c-button>
										</div>
									</div>
								</div>
								<div v-if="step === 6">
									<div class="experience-box mb-10">
										<div class="experience-title">나의 경험</div>
										<template v-for="(experience, index) in experienceList">
											<div
												v-if="experienceList"
												:key="`experience-${index}`"
												class="experience-content"
											>
												<span class="mr-5">• </span>
												<p
													class="mr-5 text-truncate"
													@click="
														nowExperienceNo = index;
														experienceTitle = experience.title;
														experienceContents1 = experience.experience[0].contents;
													"
												>
													{{ experience.title }}
												</p>
												<span class="box-remove" @click="removeConfirm(experience.no, index)" />
											</div>
										</template>
										<div
											v-if="(!experienceList || experienceList.length < 1) && !experienceTitle"
											class="text-center"
										>
											저장된 경험이 없어요 :(
										</div>
										<div v-else class="experience-content">
											<!-- 저장 전 -->
											<span class="mr-5">• </span>
											<p
												class="mr-5 text-truncate"
												:class="
													newExperienceTitle === '새로운 경험 작성하기'
														? 'text-caption'
														: 'text-border'
												"
												@click="
													nowExperienceNo = -1;
													experienceTitle = '';
													experienceContents1 = '';
												"
												v-text="newExperienceTitle"
											/>
											<span
												v-show="newExperienceTitle !== '새로운 경험 작성하기'"
												class="box-remove"
												@click="removeConfirm()"
											></span>
										</div>
									</div>
									<c-button size="large" full :disabled="isNextButtonDisabled" @click="nextStep()">
										저장 후 다음단계
									</c-button>
								</div>
							</div>
						</div>

						<div v-if="step < 7" class="resume-button-section">
							<p v-if="isError" class="text-error mb-30" v-text="errorMessage" />
							<div class="flex flex-center">
								<template v-if="step < 7">
									<c-button
										type="outline"
										color="info"
										size="large"
										class="resume-cta-button mr-10"
										@click="beforeStep"
										v-text="descriptionStep ? '수정하기' : '이전단계'"
									/>
									<c-button
										v-if="step !== 6 || (step === 6 && descriptionStep)"
										id="nextButton"
										ref="nextButton"
										size="large"
										:disabled="isNextButtonDisabled"
										class="resume-cta-button"
										@click="nextStep()"
										v-text="nextButtonName"
									/>
								</template>

								<c-button
									v-if="step === 6 && !descriptionStep"
									type="outline"
									size="large"
									color="primary"
									class="resume-cta-button"
									@click="saveExperience"
									v-text="'경험 저장'"
								>
								</c-button>
							</div>
						</div>
					</div>
				</c-new-col>
			</GridLayout>
		</div>
		<div v-else>
			<div class="text-center">
				<h4>[비정상적인 접근] 이전페이지로 돌아갑니다 :(</h4>
			</div>
		</div>

		<confirm-modal-component
			:show="showConfirm"
			:title="title"
			:message="message"
			align="center"
			:buttonName="buttonName"
			:successCallback="successCallback"
			@close="showConfirm = false"
			@save="deleteExperience(removeExperienceNo, removeExperienceIndex)"
		/>
	</c-new-col>
</template>

<script>
import { mapMutations } from 'vuex';
import { MY_PAGE_ESSAY_PATH } from '../../../constants/paths';
import routeMixin from '../../../mixins/routeMixin';
import EssaySelfCheckList from '../../../components/resume/essayTutorial/EssaySelfCheckList.vue';
import { getRoutePath } from '../../../utils/util';
import GridLayout from '../../../components/common/GridLayout.vue';
import EssayTutorialNavigatorNew from '@/components/resume/essayTutorial/EssayTutorialNavigatorNew.vue';
export default {
	mixins: [routeMixin],
	props: ['userType', 'resume', 'exp_list', 'competency_list', 'exp_category', 'final', 'userEmail', 'userNo'],
	data() {
		return {
			showConfirm: false,
			title: '',
			message: '',
			buttonName: '',
			successCallback: '',
			resumeNo: this.resume.no,
			errorMessage: '',
			isError: false,
			name: '',
			competencyNo: this.resume.job_competency,
			competencyName: '',
			competencyArr: this.competency_list,
			competencyTipNo: 0,
			competencyDetail: this.resume.job_competency_detail,
			step: 1,
			finalStep: parseInt(this.final),
			isCompletion: false,
			descriptionStep: false,
			job: this.resume.job,
			jobDetail: this.resume.job_detail,
			jobDetailExcellence: this.resume.job_detail_excellence,
			experienceTitle: '',
			experienceContents1: '',
			nowExperienceNo: -1, //새로운 경험
			nowEpisodeExperienceNo: 0,
			experienceList: [],
			isVisible: true,
			removeExperienceNo: '', //에피소드 삭제 번호
			removeExperienceIndex: '', //에피소드 삭제 인덱스
			isValidSelfCheckList: false,
			descriptionStepItems: [3, 4, 6],
		};
	},
	computed: {
		resumeMainTitle() {
			switch (this.step) {
				case 1:
					return `<span class=" highlight-underline">지원하실 직무를 알려주세요</span>`;
				case 2:
					return `<span class=" highlight-underline">${this.job}직무는 어떤 일을 수행하는 직무인가요?</span>`;
				case 3:
					return `<span class=" highlight-underline">어떤 사람이 ${this.job}직무를 잘 수행할까요? 그 이유는 뭔가요?</span>`;
				case 4:
					return `<span class=" highlight-underline">작성한 우수자의 모습 중 한 가지와 관련된 나의 강점을 하나 선택하세요.</span>`;
				case 5:
					return `<span class=" highlight-underline">${this.competencyName}이(가) 뛰어나다는 것은 어떤 의미인가요?</span>`;
				case 6:
					if (!this.experienceList || this.experienceList.length >= 1) {
						return `<span class=" highlight-underline">${this.competencyName}을(를) 발휘한 또 다른 경험이 있나요?</span>`;
					}
					return `<span class=" highlight-underline">언제 ${this.competencyName}을(를) 뛰어나게 발휘했나요?</span>`;
				case 7:
					return `<span class=" highlight-underline">입력한 경험을 분류해주세요. 이력서 초안이 완성됩니다.</span>`;
				case 8:
					return `<span class=" highlight-underline">이력서 초안이 완성되었습니다!</span><br>
            <span class=" highlight-underline">샘플을 참고하여 문장을 다듬고 신상정보를 입력해보세요</span>`;
			}
			return '';
		},
		resumeTipTitle() {
			const title = {
				1: '좋은 자기소개서란?',
				2: '작성이 어렵나요?',
				3: '정해진 답은 없어요!',
				4: '내 강점을 모르겠나요?',
				5: '강점 정의가 중요한 이유',
				6: '어떤 경험을 적어야 하나요?',
			};

			return title[this.step];
		},
		resumeTipMessage() {
			const message = {
				1: `<p>자기소개서는 과거 경험을 통해 앞으로 어떤 일을 할 수 있는 인재인지 표현하는 문서입니다.</p>
            <p>따라서 지원하려는 직무에 적합한 과거 경험을 담은 글이 좋은 자기소개서라고 할 수 있습니다.</p>
            <p>어떤 직무에 지원할 예정인지 알려주시면 좋은 자기소개서 작성을 도와드릴게요.</p>`,
				2: `<p>좋은 자기소개서를 작성하기 위해서는 먼저 지원하려는 직무가 어떤 일을 하는지 명확하게 이해해야 합니다.</p>
            <p>중학교에 다니는 동생도 이해할 수 있도록 구체적이고 쉬운 용어로 직무가 하는 일을 설명해보세요.</p>
            <p>만약 직무가 하는 일을 올바르게 이해하고 있지 않다면 직무부트캠프에 참여해보세요.</p>`,
				3: `<p>특정 직무 우수자는 매우 다양한 모습일 수 있습니다.</p>
            <p>영업을 잘하는 사람 중에는 뛰어난 대인관계를 통해서 성과를 내는 사람도 있고,
            분석력을 바탕으로 고객의 니즈를 잘 파악해 성과를 내는 사람도 있는 것처럼 말이죠.</p>
            <p>직무 우수자의 모습과 이유를 가능한 많이 상상해 보세요. 나의 강점과의 연결고리를 찾는 초식이 됩니다.</p>`,
				4: `<p>강점이란 다른 사람과 비교하여 뛰어난 점을 찾는 것이 아니라
            내가 가지고 있는 여러 가지의 역량 중 가장 뛰어난 점을 찾는 것입니다.</p>
            <p>앞서 작성한 우수자의 여러가지 모습 중에 나의 강점과 연결지을 수 있는 모습이 있다면
            더 좋은 자기소개서를 작성할 수 있을 거예요.</p>
            <p>직무 우수자의 모습 중 한가지와 연결되는 나의 강점이 없다면,
            이전 단계로 돌아가 직무 우수자의 모습을 더 많이 작성해 보세요.</p>`,
				5: `<p>본인은 선택한 강점을 어떻게 정의하고 있나요? 특정 강점이 있다고 주장하려면
            그 강점이 뛰어나다는 것이 어떤 뜻인지 알고 있어야 합니다.</p>
            <p>강점에 대한 정의는 사람마다 다를 수 있습니다. 때문에 강점이 뛰어나다는 것을 어떻게 정의하느냐에 따라
            이 강점을 드러내기 위한 경험이 달라지게 됩니다.</p>`,
				6: `<p>아르바이트나 동아리, 대외활동, 인턴, 공모전, 수상경력, 개인 프로젝트, 수업 프로젝트, 여행 등
            어떠한 내용도 좋으니 강점과 관련된 경험이 있다면 모두 작성해보세요.</p>`,
			};

			return message[this.step];
		},
		resumeCompetencyTip() {
			//강점 설명
			for (let i = 0; i < this.resumeCompetencyArr.length; i++) {
				if (this.resumeCompetencyArr[i].no === parseInt(this.competencyTipNo)) {
					return this.resumeCompetencyArr[i].tip;
				}
			}
		},
		resumeExperienceMessage() {
			//강점별 에피소드 작성법
			for (let i = 0; i < this.resumeCompetencyArr.length; i++) {
				if (this.resumeCompetencyArr[i].no === parseInt(this.competencyNo)) {
					return this.resumeCompetencyArr[i].experience;
				}
			}
		},
		isNextButtonDisabled() {
			return this.step === 6 && !this.descriptionStep && (!this.experienceList || this.experienceList.length < 1);
		},
		descriptionTitle() {
			if (this.step === 3) {
				return `당신은 ${this.job} 직무를 아래와 같이 이해하고 있습니다.`;
			} else if (this.step === 4) {
				return `당신은 ${this.job} 직무를 아래와 같은 사람이 잘 수행할 거라 생각합니다.`;
			} else if (this.step === 6) {
				return `당신은 ${this.competencyName} 이(가) 뛰어나다는 것을 아래와 같이 정의하고 있습니다.`;
			}
			return '';
		},
		descriptionMessage() {
			if (this.step === 3) {
				return `${this.jobDetail} 하는 직무`;
			} else if (this.step === 4) {
				return this.jobDetailExcellence;
			} else if (this.step === 6) {
				return this.competencyDetail;
			}
			return '';
		},
		totalExperience() {
			//step 7
			let result = '';
			if (this.experienceList.length >= 1) {
				const experienceList = this.experienceList[this.nowEpisodeExperienceNo];
				result = `<h4>${experienceList.title}</h4>`;
				if (experienceList.experience[0].contents) {
					result += `<p>• ${experienceList.experience[0].contents}</p>`;
				}
			}

			return result;
		},
		nextButtonName() {
			if (this.descriptionStep) {
				return '다음단계';
			}
			return this.step === 7 ? '저장하기' : '저장 후 다음단계';
		},
		newExperienceTitle() {
			if (
				(this.nowExperienceNo === -1 && this.isEmptyData(this.experienceTitle)) ||
				this.nowExperienceNo !== -1
			) {
				return '새로운 경험 작성하기';
			} else {
				return this.experienceTitle;
			}
		},
		resumeCompetencyArr() {
			return [
				{
					no: 1,
					name: '책임감',
					tip: `- 맡은 일을 끝까지 완수한다.<br>
          - 완수하지 못하거나 실패하더라도 책임을 회피하지 않고, 실수나 과오를 인정한다.<br>
          - 규정과 절차를 준수하고 마감기한을 맞추기 위해 최선을 다한다.`,
					experience: `<span class="label mr-5">상황</span>규정과 절차에 따라 기한을 준수하거나 목표한 수준을 달성하지 못하도록
          방해하는 제약조건은 무엇이었고
          - <span class="label mr-5">행동</span>이를 해결하기 위한 본인의 선택, 행동, 방법은 무엇이었고
          - <span class="label mr-5">결과</span>이에 따라 어떤 결과를 얻었는지 작성`,
				},
				{
					no: 5,
					name: '글로벌 마인드',
					tip: `- 문화에 따른 차이를 이해하고 이를 성과 창출에 활용한다.<br>
          - 현지 시장 및 문화적 상황 등에 대한 이해가 높고, 다양한 문화의 이슈, 관점을 이해할 수 있다.`,
					experience: `<span class="label mr-5">상황</span>국내 또는 해외에서 해결해야 하는 문제는 무엇이었고
          - <span class="label mr-5">행동</span>이 문제를 해결하기 위해 다양한 문화적 차이 또는 해당 지역/국가의 지역적 문화적 특성을 어떻게 활용하였는지
          - <span class="label mr-5">결과</span>그래서 원래 풀려고 하였던 문제가 어떻게 해결되었는지 작성
          <p class="attention-note">* Tip: 단순히 위치가 해외이거나, 외국인을 대상으로한 문제 또는 외국어를 활용했던 경험은 지양</p>`,
				},
				{
					no: 13,
					name: '문제해결능력',
					tip: `- 문제의 본질과 배경을 신속히 파악한다.<br>
          - 복잡한 상황의 문제도 여러 가지 방법(과거 경험, 방법론, 전략 등)을 활용해 합리적으로 해결한다.`,
					experience: `<span class="label mr-5">상황</span>처음 풀어보거나 풀기 어려운 복잡한 문제의 상황을 정의하고
          - <span class="label mr-5">행동</span>이를 어떤 논리적인 흐름에서 어떤 대안을 도출하여 어떤 결정을 내렸는지
          - <span class="label mr-5">결과</span>그리고 그 결정이 만들어 낸 성과를 작성`,
				},
				{
					no: 16,
					name: '창의력',
					tip: `- 기존 지식을 응용하거나, 다른 분야의 개념과의 연결 등을 통해 문제를 해결한다.<br>
          - 아이디어를 실현하기 위한 구체적 계획을 수립하고 적용한다.`,
					experience: `<span class="label mr-5">상황</span>기존에 알던 문제 또는 새로운 문제를 풀기 위해
          - <span class="label mr-5">행동</span>기존에 하던 방식과 다른 방법으로 시도하거나 문제에 접근하여
          - <span class="label mr-5">결과</span>위의 행동으로 인해 성과를 창출했던 경험을 작성
          <p class="attention-note">* Tip: 창의적인 것이 꼭 획기적인 아이디어일 필요는 없음.
          기존의 방법이 무엇이었고 어떤 새로운 방법으로 접근했는지가 드러나게 작성</p>`,
				},
				{
					no: 24,
					name: '계획/조직화',
					tip: `- 목표를 달성하기 위한 프로세스 및 필요 요소들을 구체적으로 계획한다.<br>
          - 새로운 일을 추진할 때, 목표달성에 문제가 될 수 있는 사항들을 미리 파악하고 업무가 지연되지 않도록 일정을 계획한다.`,
					experience: `<span class="label mr-5">상황</span>달성하고자 하였던 특정한 문제 또는 목표가 무엇이었는지
          - <span class="label mr-5">행동</span>필요한 프로세스나 요소들을 단계별로 어떻게 계획하고 어떤 방법으로 우선순위를 설정하였는지
          - <span class="label mr-5">결과</span>그리고 이 계획이 성과 달성에 어떤 도움을 주었는지 작성`,
				},
				{
					no: 2,
					name: '도전정신',
					tip: `- 스스로 도전적인 목표를 설정하고, 새롭고 혁신적인 일에는 과감히 도전한다.<br>
          - 개선이 필요한 관습적 업무가 있다면 적극적으로 개선을 주장하고 실천한다.`,
					experience: `<span class="label mr-5">상황</span>불확실한 상황이나 예상되는 위험이 무엇이었는지
          - <span class="label mr-5">행동</span>이를 어떻게 감수하고 문제 해결 또는 목표 달성을 위해 어떤 것을 시도했는지
          - <span class="label mr-5">결과</span>그리고 그 시도가 문제 해결이나 목표 달성에 어떤 도움을 주었는지 작성`,
				},
				{
					no: 6,
					name: '능동성',
					tip: `- 누가 시키기 전에 주도적으로 목표 달성을 위해 행동한다.<br>
          - 목표 달성을 위해 주어진 역할과 책임 범위를 스스로 확장하고 실행한다.`,
					experience: `<span class="label mr-5">상황</span>본인에게 원래 주어진 역할이나 조직, 팀이 기대한 역할이 무엇이었는지
          - <span class="label mr-5">행동</span>본인이 어떻게 스스로 역할을 확장하여
          - <span class="label mr-5">결과</span>위의 행동으로 팀이나 조직에 기여하였는지 작성`,
				},
				{
					no: 14,
					name: '분석력',
					tip: `- 복잡한 과제나 자료 등을 분류, 세분화하여 관련성을 파악하고 핵심사항을 도출한다.<br>
					  - 문제 해결을 위해 필요한 정보나 자료를 파악해 체계적으로 수집하고 분석한다.<br>
					  - 상황이나 정보의 함축적인 의미를 분석하고 파악한다.`,
					experience: `<span class="label mr-5">상황</span>해결하고자 하는 문제 상황 또는 달성하고자 하는 목표가 무엇이었는지
            - <span class="label mr-5">행동</span>필요한 정보나 자료를 파악해 체계적으로 수집하고 분석하여
            - <span class="label mr-5">결과</span>문제 해결에 어떤 도움이 되었는지 작성
            <p class="attention-note">* Tip: 꼭 숫자 분석을 의미하는 것은 아님. 정성적인 정보를 해석하거나 어떤 상황이나
            사실을 단편적으로 받아들이지 않고 핵심사항이나 함축적 의미를 도출하는 등의 경험을 포괄</p>`,
				},
				{
					no: 18,
					name: '꼼꼼함',
					tip: `- 결과물에 영향을 미치는 모든 영역을 철저하게 검토하고 확인한다.<br>
            - 높은 품질을 유지하기 위한 방법이나 프로세스를 고안하고 실행한다.`,
					experience: `<span class="label mr-5">상황</span>일의 마무리나 원래 기대했던 품질보다 높은 수준을 달성하기 위해
            - <span class="label mr-5">행동</span>지속적으로 진행 상황과 품질을 점검하거나 이 수준이 계속 유지될 수 있도록 방법이나 프로세스를 만들어
            - <span class="label mr-5">결과</span>실제로 결과에 어떤 긍정적인 영향을 끼쳤는지 작성
            <p class="attention-note">* Tip: 점검을 한 내용을 기술하는 경우 얼마나 자주 또는 얼마나 많이 또는 평균보다 얼마나 더 노력했는지 표현</p>`,
				},
				{
					no: 20,
					name: '근성(열정)',
					tip: `- 남들이 꺼리는 업무나 어려운 과제도 주도적으로 나서서 해결하려고 노력한다.<br>
            - 업무 범위에서 벗어난 일도 팀 목표를 위해서는 기꺼이 맡아서 수행한다.`,
					experience: `<span class="label mr-5">상황</span>본인이 맡은 업무나 책임의 완수 또는 문제 해결 과정에서 어려움 또는 장애물에 대해
            - <span class="label mr-5">행동</span>포기하지 않고 끝까지 완수 또는 문제를 극복한 경험과
            - <span class="label mr-5">결과</span>그 성과를 작성`,
				},
				{
					no: 3,
					name: '성과지향',
					tip: `- 목표 달성을 위해 가능한 자원을 최대한 동원한다.<br>
            - 팀이 목표를 달성하도록 동료들을 독려하고 이끈다.`,
					experience: `<span class="label mr-5">상황</span>기대되는 수준이나 보통의 수준보다 더 높은 목표를 설정하고
            - <span class="label mr-5">행동</span>이를 달성하기 위해서 행동했던 노력과
            - <span class="label mr-5">결과</span>실제 달성한 성과를 작성
            - <p class="attention-note">* Tip: 성과 달성을 위해 개인적으로 노력한 내용뿐 아니라 외부의 자원이나 리소스를 활용한 경험을 포함</p>`,
				},
				{
					no: 9,
					name: '팀워크지향',
					tip: `- 팀을 우선적으로 생각하고, 팀 목표 달성 및 시너지 형성을 위해 적극적으로 팀원들과 협업한다.<br>
            - 팀 내부에 갈등을 해결하거나 조정하고, 좋은 분위기가 형성되도록 한다.`,
					experience: `<span class="label mr-5">상황</span>팀 또는 조직의 성과를 저해하는 요소 또는 상황과
            - <span class="label mr-5">행동</span>이를 개선하거나 바람직한 팀 분위기 또는 시너지 형성을 위해 기여했던 경험과
            - <span class="label mr-5">결과</span>이 행동이 팀 또는 조직 성과에 어떤 영향을 미쳤는지 작성`,
				},
				{
					no: 15,
					name: '학습능력',
					tip: `- 문제 해결을 위해 새로운 지식, 개념, 방식을 주도적으로 학습하고 적용한다.<br>
            - 목표를 위해 지속적으로 시도해보고 적용해보는 과정을 통해 학습한다.`,
					experience: `<span class="label mr-5">상황</span>모르는 문제 또는 경험해 보지 못한 문제를 풀거나 평소보다 더 높은 성과를 만들기 위해
            - <span class="label mr-5">행동</span>주도적으로 어떤 지식이나 스킬을 필요하다고 판단하고 어떻게 효율적으로 학습하여
            - <span class="label mr-5">결과</span>성과 창출에 기여하였는지 작성
            <p class="attention-note">* Tip: 또는 조직이나 팀의 성과를 지속적으로 유지하기 위해 본인의 경험을 지식화 하거나 체계화 한 경험</p>`,
				},
				{
					no: 19,
					name: '의사소통능력',
					tip: `- 의도하는 바를 적절한 의사소통 기법을 활용해 효과적으로 상대방에게 전달한다.<br>
            - 원활한 의사소통을 바탕으로 상호 협의를 이끌어내거나, 갈등을 중재한다.`,
					experience: `<span class="label mr-5">상황</span>문제를 해결하거나 서로 간의 협의를 이끌어 내거나 상대방의 협력을 이끌어 내기 위해
            - <span class="label mr-5">행동</span>상대에게 의사를 전달하거나 공감, 설득하였던 구체적인 방법이
            - <span class="label mr-5">결과</span>어떻게 행동의 변화나 팀 성과에 영향을 주었는지 작성`,
				},
				{
					no: 23,
					name: '대인관계(소통,친화력)',
					tip: `- 타인에 대한 이해를 바탕으로 상대방과 쉽게 친밀한 관계를 형성하고 대화를 나눈다.<br>
            - 목표 달성을 위해 원만하게 관계를 형성하고 서로 도움을 주고 받는다.<br>
            - 상대방의 요구 및 관점을 잘 파악하여 효과적으로 반응한다.`,
					experience: `<span class="label mr-5">상황</span>목표한 바를 이루기 위해 또는 목표와 관계 없이
            - <span class="label mr-5">행동</span>사람들에 대한 이해와 배려를 바탕으로 관계를 형성하고 유지하여
            - <span class="label mr-5">결과</span>이 관계가 궁극적으로 성과에 영향을 준 경험을 작성`,
				},
			];
		},
	},
	watch: {
		$route(to, from) {
			this.step = parseInt(to.params.no);
			if (to.params.no < from.params.no) {
				if (this.descriptionStep) this.descriptionStep = false;
			} else {
				this.handleDescriptionStep(this.step);
			}
			this.scrollTo('section', 500, -120);
		},
	},
	created() {
		this.step = parseInt(this.$route.params.no);

		// GTM - GAVirtualPageview
		GAVirtualPageview(this.$route.path);
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.isVisible = false;
			if (parseInt(to.params.no) > parseInt(vm.finalStep) + 1) {
				vm.$router.go(-1);
			} else {
				vm.isVisible = true;
				vm.step = parseInt(to.params.no);
				vm.scrollTo('section', 500, -120);
			}
		});
	},
	mounted() {
		this.setMeta({
			isShowFooter: false,
			title: '자기소개서 튜토리얼',
			isDetail: true,
			fluid: !this.isMobile,
		});

		if (!this.isEmptyData(this.competencyNo)) {
			for (let i = 0; i < this.competencyArr.length; i++) {
				if (this.competencyNo === this.competencyArr[i].no) {
					this.competencyName = this.competencyArr[i].name;
				}
			}
		}

		//경험 리스트
		if (!this.exp_list || this.exp_list.length >= 2) {
			for (let i = 0; i < this.exp_list.length; i++) {
				if (i % 2 === 1) {
					if (this.exp_list[i - 1].group_no === this.exp_list[i].group_no) {
						this.experienceList.push({
							no: this.exp_list[i].group_no,
							title: this.exp_list[i - 1].title,
							experience: [
								{ no: this.exp_list[i - 1].no, contents: this.exp_list[i - 1].contents },
								{ no: this.exp_list[i].no, contents: this.exp_list[i].contents },
							],
							categoryNo: this.exp_list[i].category_no,
						});
					}
				}
			}
			this.nowExperienceNo = -1; //새로운 경험은 -1
		}
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		handleDescriptionStep(step) {
			this.descriptionStep = this.descriptionStepItems.includes(step);
		},
		goResumeBegin() {
			this.$_routeMixin_href(getRoutePath(MY_PAGE_ESSAY_PATH, 'nuxt'));
		},
		showLoading() {
			$('.blinder2').css('display', 'block');
			$('.loading').css('display', 'block');
		},
		changeRoute(step) {
			this.$router.push({
				name: 'essay-tutorial-builder',
				params: { no: step },
			});
		},
		beforeStep() {
			//이전단계
			if (this.step === 1) {
				this.$router.push({
					path: '/essay/tutorial',
				});
			} else if (this.step === 7) {
				this.changeRoute(6);
			} else {
				this.saveStep(this.step, 'before');
			}
		},
		nextStep() {
			//다음단계
			if (this.descriptionStep) {
				this.descriptionStep = false;
				this.scrollTo('section', 500, -120);
				return false;
			}
			if (this.step === 1) {
				//직무 입력
				if (!this.job) {
					this.$refs.job.focus();
					this.errorMessage = '내용을 입력해주세요.';
					this.isError = true;
					return false;
				} else {
					this.saveStep(1);
				}
			} else if (this.step === 2) {
				//직무 이해
				if (!this.jobDetail) {
					this.$refs.jobDetail.focus();
					this.errorMessage = '내용을 입력해주세요.';
					this.isError = true;
					return false;
				} else {
					// GTM - describeJob
					describeJob(this.userNo, this.userType);

					this.saveStep(2);
				}
			} else if (this.step === 3) {
				//우수자 역량
				if (!this.jobDetailExcellence) {
					this.$refs.jobDetailExcellence.focus();
					this.errorMessage = '내용을 입력해주세요.';
					this.isError = true;
					return false;
				} else {
					// GTM - describeRequiredStrength
					describeRequiredStrength(this.userNo, this.userType);

					this.saveStep(3);
				}
			} else if (this.step === 4) {
				//내 강점
				if (this.isEmptyData(this.competencyNo) && this.isEmptyData(this.competencyName)) {
					this.errorMessage = '강점을 1개 선택해주세요.';
					this.isError = true;
					return false;
				} else {
					// GTM - describeMyStrength
					describeMyStrength(this.userNo, this.userType);

					this.saveStep(4);
				}
			} else if (this.step === 5) {
				if (!this.competencyDetail) {
					this.$refs.competencyDetail.focus();
					this.errorMessage = '내용을 입력해주세요.';
					this.isError = true;
					return false;
				} else {
					// GTM - defineStrength
					defineStrength(this.userNo, this.userType);

					this.saveStep(5);
				}
			} else if (this.step === 6) {
				// GTM - describeRelevantEpisode
				describeRelevantEpisode(this.userNo, this.userType, this.userEmail);
				this.saveStep(6);
			} else if (this.step === 7) {
				this.goResumeBegin();
			}
		},
		removeConfirm(no, index) {
			//삭제 confirm
			if (this.isEmptyData(index) && this.isEmptyData(this.experienceTitle)) return false;

			this.removeExperienceNo = no;
			this.removeExperienceIndex = index;
			this.title = '에피소드 삭제';
			if (!this.isEmptyData(index)) {
				this.message = `'${this.experienceList[index].title}'을(를) 삭제하시겠습니까?`;
			} else {
				this.message = `'${this.experienceTitle}'을(를) 삭제하시겠습니까?`;
			}
			this.buttonName = '';
			this.successCallback = '';
			this.showConfirm = true;
		},
		saveStep(step, type, no, index) {
			let method = '',
				url = '',
				params = {};

			showLoading();
			if (step === 1) {
				method = 'POST';
				url = 'job';
				params = { job: this.job, step: this.step };
				if (this.resumeNo >= 1) params = { job: this.job, step: this.step, resume_no: this.resumeNo };
			} else if (step === 2) {
				method = 'PATCH';
				url = 'job/detail';
				params = { job_detail: this.jobDetail, resume_no: this.resumeNo, step: this.step };
			} else if (step === 3) {
				method = 'PATCH';
				url = 'job/detail/excellence';
				params = {
					job_detail_excellence: this.jobDetailExcellence,
					resume_no: this.resumeNo,
					step: this.step,
				};
			} else if (step === 4) {
				method = 'PATCH';
				url = 'competency';
				params = {
					resume_competency: this.competencyNo,
					resume_no: this.resumeNo,
					step: this.step,
				};
			} else if (step === 5) {
				method = 'PATCH';
				url = 'competency/detail';
				params = {
					resume_competency_detail: this.competencyDetail,
					resume_no: this.resumeNo,
					step: this.step,
				};
			} else if (step === 6) {
				if (type === 'insert') {
					//경험저장 - 생성
					method = 'POST';
					url = 'exp';
					params = {
						title: this.experienceTitle,
						contents: [this.experienceContents1, this.experienceContents2],
						resume_no: this.resumeNo,
						step: this.step,
					};
				} else if (type === 'update') {
					//경험저장 - 업데이트
					method = 'PATCH';
					url = 'exp/update';
					params = {
						title: this.experienceTitle,
						updates: [
							{
								no: this.experienceList[this.nowExperienceNo].experience[0].no,
								contents: this.experienceContents1,
							},
							{
								no: this.experienceList[this.nowExperienceNo].experience[1].no,
								contents: this.experienceContents2,
							},
						],
					};
				} else if (type === 'delete') {
					method = 'DELETE';
					url = 'exp';
					params = {
						resume_no: this.resumeNo,
						group_no: parseInt(no),
					};
				} else {
					//제출하기
					method = 'PATCH';
					url = 'exp/submit';
					params = {
						resume_no: this.resumeNo,
						step: this.step,
					};
				}
			} else if (step === 7) {
				if (type === 'exit') {
					this.goResumeBegin();
				} else {
					let updatesArr = [];
					for (let i = 0; i < this.experienceList.length; i++) {
						for (let j = 0; j < this.experienceList[i].experience.length; j++) {
							updatesArr.push({
								no: this.experienceList[i].experience[j].no,
								category_no: this.experienceList[i].categoryNo,
							});
						}
					}
					method = 'PATCH';
					url = 'exp/category';
					params = {
						updates: updatesArr,
						resume_no: this.resumeNo,
						step: this.step,
					};
				}
			}

			axios({
				method: method,
				url: `/api/resume/${url}`,
				data: params,
			})
				.then(({ data }) => {
					if (data.code === 200) {
						if (this.step === 1) this.resumeNo = data.resume_no;
						this.isError = false;
						let step = this.step;

						if (!type) {
							//다음단계로 라우터 이동
							if (this.step > this.finalStep) this.finalStep = this.step; //다음버튼 눌렀을때만 finalStep 증가
							step += 1;
							this.changeRoute(step);
						} else if (type === 'exit') {
							this.goResumeBegin();
						} else if (type === 'before') {
							//이전단계
							step -= 1;
							this.changeRoute(step);
						} else {
							if (type === 'insert') {
								this.experienceList.push({
									no: -1,
									title: this.experienceTitle,
									experience: [
										{ no: data.exp_no_list[0], contents: this.experienceContents1 },
										{ no: data.exp_no_list[1], contents: this.experienceContents2 },
									],
									categoryNo: '',
								});
								this.scrollTo('description-section', 500, -120);
							} else if (type === 'update') {
								for (let i = 0; i < this.experienceList.length; i++) {
									if (i === this.nowExperienceNo) {
										this.experienceList[i].title = this.experienceTitle;
										this.experienceList[i].experience[0].contents = this.experienceContents1;
										this.experienceList[i].experience[1].contents = this.experienceContents2;
									}
								}
								//스크롤 맨위로, 저장 강조
								this.scrollTo('description-section', 500, -120);
							} else if (type === 'delete') {
								if (parseInt(no) === -1 || parseInt(no) >= 1) {
									//경험리스트 배열 삭제
									this.experienceList.splice(index, 1); //배열에서 삭제
								}
							}
							this.nowExperienceNo = -1;
							this.experienceTitle = '';
							this.experienceContents1 = '';
							this.experienceContents2 = '';
						}
						closeLoading();
					} else {
						closeLoading();
					}
				})
				.catch(error => {
					console.log(error);
					closeLoading();
				});
		},
		viewCompetencyTip(competencyNo) {
			this.competencyTipNo = competencyNo;
		},
		saveExperience() {
			if (!this.experienceTitle || !this.experienceContents1) {
				this.errorMessage = '에피소드를 입력해주세요';
				this.isError = true;
				if (!this.experienceTitle) {
					this.$refs.experienceTitle.focus();
				} else if (!this.experienceContents1) {
					this.$refs.experienceContents1.focus();
				}
				return false;
			} else {
				if (this.nowExperienceNo === -1) {
					this.saveStep(6, 'insert');
				} else {
					this.saveStep(6, 'update');
				}
			}
		},
		deleteExperience(no, index) {
			//경험 삭제
			if (!this.experienceList || this.experienceList.length < 1) {
				//경험이 없을 때
				this.experienceTitle = '';
				this.experienceContents1 = '';
				this.experienceContents2 = '';
			} else {
				//경험이 1개 이상일 때
				this.saveStep(6, 'delete', no, index);
			}
		},
		changeCategory(index, categoryNo) {
			this.experienceList[index].categoryNo = categoryNo;
		},
		saveConfirm() {
			//이력서 저장 컨펌창
			this.saveStep(this.step, 'exit');
		},
		handleSaveEssay() {
			// GTM - completeCoverLetter
			completeCoverLetter(this.userNo, this.userType, this.userEmail);
			this.goResumeBegin();
		},
	},
	components: {
		GridLayout,
		EssaySelfCheckList,
		EssayTutorialNavigatorNew,
	},
};
</script>

<style lang="scss" scoped>
.resume-cta-button,
::v-deep .resume-cta-button {
	width: 200px !important;
}
</style>
