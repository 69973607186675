<template>
	<div>
		<biz-side-component num="1"></biz-side-component>
		<section class="section section-left bg-gray">
			<div class="container text-left">
				<div class="col-8 col-8-center">
					<biz-company-header-component
						main="근태 및 복지정보 입력"
						sub1="적합한 복지 정책은 우수한 인재가 기업에 관심을 갖도록 만드는 중요한 요소입니다. 여러 복지 정책과 내용 중 우리회사에 해당되는 모든 내용을 선택하세요. 해당되지 않는 경우 비워두시면 됩니다."
					/>
					<div class="f-red font-caption pt-50">* 필수항목</div>
					<div class="bg-white border pd-30">
						<div>
							<span class="font-title f-semi-bold">근무시간</span>
							<span class="f-red">*</span>
						</div>
						<div class="pl-10 mt-10 mb-50">
							<div>
								<input
									id="9"
									v-model="companyTime"
									type="checkbox"
									class="com-checkbox"
									value="9AM~6PM"
								/><label class="font cursor-pointer" for="9">9AM~6PM</label>
							</div>
							<div>
								<input
									id="10"
									v-model="companyTime"
									type="checkbox"
									class="com-checkbox"
									value="10AM~7PM"
								/><label class="font cursor-pointer" for="10">10AM~7PM</label>
							</div>
							<div>
								<input
									id="free"
									v-model="companyTime"
									type="checkbox"
									class="com-checkbox"
									value="자율 출퇴근"
								/><label class="font cursor-pointer" for="free">자율 출퇴근</label>
							</div>
							<div>
								<input
									id="flex"
									v-model="companyTime"
									type="checkbox"
									class="com-checkbox"
									value="유연근무"
								/><label class="font cursor-pointer" for="flex">유연근무</label>
							</div>
							<div>
								<input
									id="home"
									v-model="companyTime"
									type="checkbox"
									class="com-checkbox"
									value="재택근무"
								/><label class="font cursor-pointer" for="home">재택근무</label>
							</div>
							<div>
								<input
									id="otherTime"
									v-model="companyTime"
									type="checkbox"
									class="com-checkbox"
									value="기타"
								/>
								<label class="font cursor-pointer" for="otherTime">
									기타
									<input
										v-model="time"
										class="com-inputbox-short font f-light pd-10 ml-20"
										type="text"
										placeholder="여기를 눌러 입력하세요."
									/>
								</label>
							</div>
						</div>
						<div>
							<span class="font-title f-semi-bold">의료/보험</span>
							<span class="f-red">*</span>
						</div>
						<div class="pl-10 mt-10 mb-50">
							<div>
								<input
									id="fourInsurance"
									v-model="companyInsurance"
									type="checkbox"
									class="com-checkbox"
									value="4대 보험"
								/><label class="font cursor-pointer" for="fourInsurance">4대 보험</label>
							</div>
							<div>
								<input
									id="medical"
									v-model="companyInsurance"
									type="checkbox"
									class="com-checkbox"
									value="의료실비보험"
								/><label class="font cursor-pointer" for="medical">의료실비보험</label>
							</div>
							<div>
								<input
									id="comprehensiveHealth"
									v-model="companyInsurance"
									type="checkbox"
									class="com-checkbox"
									value="종합건강검진"
								/><label class="font cursor-pointer" for="comprehensiveHealth">종합건강검진</label>
							</div>
							<div>
								<input
									id="tributeFee"
									v-model="companyInsurance"
									type="checkbox"
									class="com-checkbox"
									value="경조사비"
								/><label class="font cursor-pointer" for="tributeFee">경조사비</label>
							</div>
							<div>
								<input
									id="otherInsurance"
									v-model="companyInsurance"
									type="checkbox"
									class="com-checkbox"
									value="기타"
								/>
								<label class="font cursor-pointer" for="otherInsurance">
									기타
									<input
										v-model="insurance"
										class="com-inputbox-short font f-light pd-10 ml-20"
										type="text"
										placeholder="여기를 눌러 입력하세요."
									/>
								</label>
							</div>
						</div>
						<div>
							<span class="font-title f-semi-bold">연차/휴가</span>
							<span class="f-red">*</span>
						</div>
						<div class="pl-10 mt-10 mb-50">
							<div>
								<input
									id="courtHoliday"
									v-model="companyHoliday"
									type="checkbox"
									class="com-checkbox"
									value="법정휴가"
								/><label class="font cursor-pointer" for="courtHoliday">법정휴가</label>
							</div>
							<div>
								<input
									id="maternityLeave"
									v-model="companyHoliday"
									type="checkbox"
									class="com-checkbox"
									value="출산휴가"
								/><label class="font cursor-pointer" for="maternityLeave">출산휴가</label>
							</div>
							<div>
								<input
									id="menstruationVacation"
									v-model="companyHoliday"
									type="checkbox"
									class="com-checkbox"
									value="생리휴가"
								/><label class="font cursor-pointer" for="menstruationVacation">생리휴가</label>
							</div>
							<div>
								<input
									id="otherHoliday"
									v-model="companyHoliday"
									type="checkbox"
									class="com-checkbox"
									value="기타"
								/>
								<label class="font cursor-pointer" for="otherHoliday">
									기타
									<input
										v-model="holiday"
										class="com-inputbox-short font f-light pd-10 ml-20"
										type="text"
										placeholder="여기를 눌러 입력하세요."
									/>
								</label>
							</div>
						</div>
						<div>
							<span class="font-title f-semi-bold">식사/간식</span>
						</div>
						<div class="pl-10 mt-10 mb-50">
							<div>
								<input
									id="breakfast"
									v-model="companyMeal"
									type="checkbox"
									class="com-checkbox"
									value="조식 제공"
								/><label class="font cursor-pointer" for="breakfast">조식 제공</label>
							</div>
							<div>
								<input
									id="lunch"
									v-model="companyMeal"
									type="checkbox"
									class="com-checkbox"
									value="중식 제공"
								/><label class="font cursor-pointer" for="lunch">중식 제공</label>
							</div>
							<div>
								<input
									id="dinner"
									v-model="companyMeal"
									type="checkbox"
									class="com-checkbox"
									value="석식 제공"
								/><label class="font cursor-pointer" for="dinner">석식 제공</label>
							</div>
							<div>
								<input
									id="overTime"
									v-model="companyMeal"
									type="checkbox"
									class="com-checkbox"
									value="야근 식대"
								/><label class="font cursor-pointer" for="overTime">야근 식대</label>
							</div>
							<div>
								<input
									id="snack"
									v-model="companyMeal"
									type="checkbox"
									class="com-checkbox"
									value="간식 제공"
								/><label class="font cursor-pointer" for="snack">간식 제공</label>
							</div>
							<div>
								<input
									id="otherFood"
									v-model="companyMeal"
									type="checkbox"
									class="com-checkbox"
									value="기타"
								/>
								<label class="font cursor-pointer" for="otherFood">
									기타
									<input
										v-model="meal"
										class="com-inputbox-short font f-light pd-10 ml-20"
										type="text"
										placeholder="여기를 눌러 입력하세요."
									/>
								</label>
							</div>
						</div>
						<div>
							<span class="font-title f-semi-bold">출퇴근/교통</span>
						</div>
						<div class="pl-10 mt-10 mb-50">
							<div>
								<input
									id="publicTransport"
									v-model="companyTransportation"
									type="checkbox"
									class="com-checkbox"
									value="대중교통비 지원"
								/><label class="font cursor-pointer" for="publicTransport">대중교통비 지원</label>
							</div>
							<div>
								<input
									id="fuelCost"
									v-model="companyTransportation"
									type="checkbox"
									class="com-checkbox"
									value="유류비 지원"
								/><label class="font cursor-pointer" for="fuelCost">유류비 지원</label>
							</div>
							<div>
								<input
									id="commuterBus"
									v-model="companyTransportation"
									type="checkbox"
									class="com-checkbox"
									value="통근버스 제공"
								/><label class="font cursor-pointer" for="commuterBus">통근버스 제공</label>
							</div>
							<div>
								<input
									id="otherTrans"
									v-model="companyTransportation"
									type="checkbox"
									class="com-checkbox"
									value="기타"
								/>
								<label class="font cursor-pointer" for="otherTrans">
									기타
									<input
										v-model="transportation"
										class="com-inputbox-short font f-light pd-10 ml-20"
										type="text"
										placeholder="여기를 눌러 입력하세요."
									/>
								</label>
							</div>
						</div>

						<div>
							<span class="font-title f-semi-bold">업무용 장비지원</span>
						</div>
						<div class="mt-10 mb-50">
							<input
								v-model="companyEquipment"
								class="com-inputbox font f-light pd-10"
								type="text"
								placeholder="예) 맥북 제공, 노트북 제공, 개인 모니터 제공, 필요 개인장비 제공 등"
							/>
						</div>

						<div>
							<span class="font-title f-semi-bold">교육/자기계발 지원</span>
							<div class="text-caption f-light font-caption">
								팀원의 성장을 위해 어떤 제도를 운영하고 있는지 작성하세요. (여러 개인 경우 콤마로 구분)
							</div>
						</div>
						<div class="mt-10 mb-50">
							<input
								v-model="companyEducation"
								class="com-inputbox font f-light pd-10"
								type="text"
								placeholder="예) 도서비 지원, 세미나 참가비 지원, 외국어 교육비 지원 등"
							/>
						</div>

						<div>
							<span class="font-title f-semi-bold">기타</span>
							<div class="text-caption f-light font-caption">
								기타 우리 회사에서 운영하고 있는 복지 정책을 알려주세요.
							</div>
						</div>
						<div class="mt-10">
							<input
								v-model="companyWelfare"
								class="com-inputbox font f-light pd-10"
								type="text"
								placeholder="예) 기숙사, 학자금지원, 여성휴게실, 주택자금지원"
							/>
						</div>
						<div v-if="isWarn" class="f-red">{{ warn }}</div>
					</div>

					<div class="mt-20">
						<div class="float-left mt-20 mb-20 position-relative inline-block">
							<div class="progress-bar"></div>
							<div :class="progressBar" style="width: 180px"></div>
						</div>
						<div class="inline-block"><span class="h7 ml-10">2/3페이지</span></div>
						<div class="float-right text-left">
							<button class="cursor-pointer btn btn-close font mr-10" @click="tempSave">임시저장</button>
							<button
								class="cursor-pointer btn btn-outline font mr-10"
								onclick="location.href = '/company/info/1'"
							>
								이전
							</button>
							<button type="submit" class="cursor-pointer btn font" @click="next">다음</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	props: ['comInfo'],
	data() {
		return {
			companyTime: [],
			time: '',
			companyInsurance: [],
			insurance: '',
			companyHoliday: [],
			holiday: '',
			companyMeal: [],
			meal: '',
			companyTransportation: [],
			transportation: '',
			companyEquipment: [],
			companyEducation: [],
			companyWelfare: [],
			isWarn: false,
			warn: '',
			progressBar: '',
		};
	},
	mounted() {
		let agent = navigator.userAgent.toLowerCase();
		if ((navigator.appName == 'Netscape' && agent.indexOf('trident') != -1) || agent.indexOf('msie') != -1) {
			this.progressBar = 'ie-bar';
		} else {
			this.progressBar = 'point-bar';
		}

		if (this.comInfo) {
			this.companyTime = this.comInfo.officehour;
			this.companyInsurance = this.comInfo.ins;
			this.companyHoliday = this.comInfo.holiday;
			this.companyMeal = this.comInfo.meal;
			this.companyTransportation = this.comInfo.trans;
			this.companyEquipment = this.comInfo.equip.join();
			this.companyEducation = this.comInfo.edu.join();
			this.companyWelfare = this.comInfo.welfare.join();

			let timeFlag = 0;
			let insFlag = 0;
			let holidayFlag = 0;
			let mealFlag = 0;
			let transFlag = 0;

			for (let i = 0; i < this.companyTime.length; i++) {
				if (this.companyTime[i] === '기타') {
					this.time = this.companyTime[this.companyTime.length - 1];
					timeFlag = 1;
					if (this.time !== '') {
						this.companyTime.splice(this.companyTime.length - 1, 1);
					}
				}
				if (this.companyTime[i] === '') {
					this.companyTime.splice(i, 1);
				}
			}
			if (timeFlag === 0) {
				this.time = '';
			}
			for (let i = 0; i < this.companyInsurance.length; i++) {
				if (this.companyInsurance[i] === '기타') {
					this.insurance = this.companyInsurance[this.companyInsurance.length - 1];
					insFlag = 1;
					if (this.insurance !== '') {
						this.companyInsurance.splice(this.companyInsurance.length - 1, 1);
					}
				}
				if (this.companyInsurance[i] === '') {
					this.companyInsurance.splice(i, 1);
				}
			}
			if (insFlag === 0) {
				this.insurance = '';
			}

			for (let i = 0; i < this.companyHoliday.length; i++) {
				if (this.companyHoliday[i] === '기타') {
					this.holiday = this.companyHoliday[this.companyHoliday.length - 1];
					holidayFlag = 1;
					if (this.holiday !== '') {
						this.companyHoliday.splice(this.companyHoliday.length - 1, 1);
					}
				}
				if (this.companyHoliday[i] === '') {
					this.companyHoliday.splice(i, 1);
				}
			}
			if (holidayFlag === 0) {
				this.holiday = '';
			}

			for (let i = 0; i < this.companyMeal.length; i++) {
				if (this.companyMeal[i] === '기타') {
					this.meal = this.companyMeal[this.companyMeal.length - 1];
					mealFlag = 1;
					if (this.meal !== '') {
						this.companyMeal.splice(this.companyMeal.length - 1, 1);
					}
				}
				if (this.companyMeal[i] === '') {
					this.companyMeal.splice(i, 1);
				}
			}
			if (mealFlag === 0) {
				this.meal = '';
			}

			for (let i = 0; i < this.companyTransportation.length; i++) {
				if (this.companyTransportation[i] === '기타') {
					this.transportation = this.companyTransportation[this.companyTransportation.length - 1];
					transFlag = 1;
					if (this.transportation !== '') {
						this.companyTransportation.splice(this.companyTransportation.length - 1, 1);
					}
				}
				if (this.companyTransportation[i] === '') {
					this.companyTransportation.splice(i, 1);
				}
			}
			if (transFlag === 0) {
				this.transportation = '';
			}
		}
	},
	methods: {
		api(progress) {
			showLoading();
			axios({
				method: 'POST',
				url: '/api/company/info/two',
				data: {
					time: this.companyTime,
					time_etc: this.time,
					ins: this.companyInsurance,
					ins_etc: this.insurance,
					holiday: this.companyHoliday,
					holiday_etc: this.holiday,
					meal: this.companyMeal,
					meal_etc: this.meal,
					trans: this.companyTransportation,
					trans_etc: this.transportation,
					edu: this.companyEducation,
					equip: this.companyEquipment,
					other_welfare: this.companyWelfare,
				},
			})
				.then(response => {
					closeLoading();

					//console.log(response);
					if (response.data.code === 200) {
						if (progress === 'go') {
							location.href = '/company/info/3';
						} else if (progress === 'stay') {
							alert('임시저장 완료!');
							return false;
						}
					} else {
						alert('다시 확인해주세요!');
						return false;
					}
				})
				.catch(error => {
					closeLoading();
				});
		},
		tempSave() {
			this.api('stay');
		},
		next() {
			if (this.companyTime.length < 1) {
				alert('근무시간을 체크해주세요!');
				return false;
			} else if (this.companyTime.indexOf('기타') > -1 && this.time === '') {
				alert('기타 근무시간을 입력해주세요!');
				return false;
			} else if (this.companyInsurance.length < 1) {
				alert('의료/보험을 체크해주세요!');
				return false;
			} else if (this.companyInsurance.indexOf('기타') > -1 && this.insurance === '') {
				alert('기타 의료/보험을 입력해주세요!');
				return false;
			} else if (this.companyHoliday.length < 1) {
				alert('연차/휴가를 체크해주세요!');
				return false;
			} else if (this.companyHoliday.indexOf('기타') > -1 && this.holiday === '') {
				alert('기타 연차/휴가를 입력해주세요!');
				return false;
			} else if (this.companyMeal.indexOf('기타') > -1 && this.meal === '') {
				alert('기타 식사/간식를 입력해주세요!');
				return false;
			} else if (this.companyTransportation.indexOf('기타') > -1 && this.transportation === '') {
				alert('기타 출퇴근/교통를 입력해주세요!');
				return false;
			} else {
				this.api('go');
			}
		},
	},
};
</script>
<style lang="less" scoped>
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px;
	}
}
</style>
