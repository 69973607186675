//filter/randomImg.js

function randomImg(min, max, type) {
	let src = '/images/';
	let randNum = Math.floor(Math.random() * (max - min) + min);
	let isMobile = false;

	if (window.innerWidth <= 480) isMobile = true;

	if (type === 'profile') {
		src = src + 'my_anonymous_' + randNum + '.svg';
	} else if (type === 'analytics') {
		if (isMobile) {
			src = src + 'm_ai_com_banner_' + randNum + '.svg';
		} else {
			src = src + 'ai_com_banner_' + randNum + '.svg';
		}
	}

	return src;
}

export default randomImg;
