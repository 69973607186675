<template>
	<GridLayout :grid-fluid="isMobile">
		<c-new-col>
			<section :class="{ 'pt-50': !isMobile }">
				<GridLayout :grid-fluid="!isMobile">
					<c-new-col
						:col-lg="questionColumn"
						class="question-container answer-interaction"
						:class="{ 'bg-white': isMobile }"
					>
						<div
							v-for="(detail, index) in detailList"
							:key="`detail-list-${index}`"
							class="box essay-box body"
							:class="{ 'px-8': isMobile }"
						>
							<div class="feedback-info content c-default">
								<div class="info-wrapper border mb-10">
									<span
										class="text-primary border-right two-line-option"
										v-html="detail.company"
									></span>
									<span v-html="detail.job"></span>
								</div>
								<!--  end .info-wrapper -->
								<div class="body-wrapper">
									<p
										class="mb-10"
										v-html="
											detail.other_inf === ''
												? '&quot요청사항이 없습니다.&quot'
												: detail.other_inf
										"
									>
										"{{ detail.other_inf }}"
									</p>
									<div class="flex flex-row flex-v-middle mt-10">
										<div class="flex flex-row flex-v-middle border-right two-line-option">
											<img
												class="anonymous profile answer-profile"
												src="/images/my_anonymous_20.svg"
											/>
											<!-- 0: 비공개, 1: 공개 -->
											<p v-if="parseInt(detail.info_display) === 1" class="indent-profile h7">
												<span>{{ detail.univ }} {{ detail.major }}</span>
											</p>
											<p v-else class="indent-profile h7">
												<span>비공개 학교 {{ detail.major }}</span>
											</p>
										</div>
										<div class="h7 text-caption ml-5">{{ detail.time | dateFormat }}</div>
									</div>
								</div>
							</div>
							<div
								class="content c-default feedback-content essay"
								:class="{ 'py-20': !isMobile }"
								oncopy="return false;"
								@copy="protect"
								@dragstart="protect"
								@contextmenu="protect"
							>
								<div class="watermark">{{ email }}</div>
								<div class="info-wrapper border mb-10">
									<h6>{{ detail.question }}</h6>
								</div>
								<div
									v-linkified:options="{ className: 'text-link' }"
									class="answer mb-20"
									v-html="$options.filters.nl2br(detail.answer)"
								/>
								<p class="small">{{ detail.length | numberFormat }}자</p>
							</div>
						</div>
					</c-new-col>
					<c-new-col :col-lg="answerColumn" class="answer-interaction">
						<c-box :paddings="[20, 20, 20, 20]" class="default-box text-center mb-20">
							<c-typography type="headline7" :font-weight="500" color="gray900" class="mb-20">
								1. 첫 번째 예상 질문은 무엇인가요?
							</c-typography>
							<c-textarea
								v-autoresize
								type="outline"
								:value.sync="question1"
								class="textbox interview-question mb-10"
								name="q1"
								placeholder="첫 번째 예상 질문을 입력하세요"
								min-height="55px"
								@focus="focusInterview"
								@blur="blurInterview"
								@paste="protect"
							/>
							<c-textarea
								v-autoresize
								type="outline"
								:value.sync="answer1"
								class="textbox interview-answer"
								name="reason1"
								placeholder="첫 번째 질문을 예상하는 이유는 무엇인가요?"
								min-height="160px"
								@focus="focusInterview"
								@blur="blurInterview"
								@paste="protect"
							/>
						</c-box>
						<c-box :paddings="[20, 20, 20, 20]" class="default-box text-center mb-30">
							<c-typography type="headline7" :font-weight="500" color="gray900" class="mb-20">
								1. 두 번째 예상 질문은 무엇인가요?
							</c-typography>
							<c-textarea
								v-autoresize
								type="outline"
								:value.sync="question2"
								class="textbox interview-question body-margin mb-10"
								name="q1"
								placeholder="두 번째 예상 질문을 입력하세요"
								min-height="55px"
								@focus="focusInterview"
								@blur="blurInterview"
								@paste="protect"
							/>
							<c-textarea
								v-autoresize
								type="outline"
								:value.sync="answer2"
								class="textbox interview-answer body-margin"
								name="reason1"
								placeholder="두 번째 질문을 예상하는 이유는 무엇인가요?"
								min-height="160px"
								@focus="focusInterview"
								@blur="blurInterview"
								@paste="protect"
							/>
						</c-box>
						<c-button
							size="large"
							full
							:style="{ 'margin-bottom': isMobile ? '20px' : '110px' }"
							@click="saveFeedback"
						>
							답변하기
						</c-button>
					</c-new-col>
				</GridLayout>

				<c-alert-modal :show.sync="showAlert" success-message="확인" @close="showAlert = false">
					<template v-slot:content>
						<strong v-if="title">{{ title }}</strong>
						<div v-html="message" />
					</template>
				</c-alert-modal>
			</section>
		</c-new-col>
		<AnswerDetailThanksModal
			:show.sync="answerShowThanksModal"
			@success-callback="$_goRequestList"
			@close="$_closeThanksModal"
		/>
	</GridLayout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import caseMixin from '../../../mixins/case/caseMixin';
import { CASE_CATEGORY_GTM_MAP } from '../../../constants';
import answerMixin from '../../../mixins/answerMixin';
import GridLayout from '@/components/common/GridLayout.vue';
import AnswerDetailThanksModal from '@/components/answer/AnswerDetailThanksModal.vue';

export default {
	mixins: [caseMixin, answerMixin],
	props: ['no', 'type', 'userNo', 'userType', 'email', 'userLevel', 'userBelong', 'userDep'],
	data: () => ({
		detailList: [],
		question1: '',
		question2: '',
		answer1: '',
		answer2: '',
		feedbackCount: 0,
		finishFlag: false,
		showAlert: false,
		title: '',
		message: '',
		isContinue: false,
		questionColumn: 8,
		answerColumn: 4,
		answerCorrespondence: false,
	}),
	computed: {
		...mapGetters(['userNickname']),
		interviewDetailUrl() {
			return `/mine/interview?request_no=${this.no}`;
		},
	},
	created() {
		window.scrollTo(0, 0);
	},
	mounted() {
		this.setMeta({
			fluid: true,
			bgColor: ['white', 'white'],
			isShowBottomNav: false,
		});
		this.$nextTick(() => {
			this.getFeedback();
			this.getContents();

			if (this.finishFlag) {
				this.title = '종료된 요청';
				this.message =
					'본 요청은 최대답변개수 또는 마감시간이 초과되어 종료되었습니다. 멘토링 목록에서 다른 요청에 참여부탁드립니다.';
				this.showAlert = true;
			}
		});
	},
	methods: {
		...mapMutations('layoutStore', ['setMeta']),
		async saveFeedback() {
			if (!this.question1 || !this.answer1 || !this.question2 || !this.answer2) {
				this.title = '';
				this.message = '면접 질문과 근거를 모두 입력해 주세요.';
				this.showAlert = true;
				return;
			} else if (this.answer1.length <= 50 || this.answer2.length <= 50) {
				this.title = '';
				this.message = '면접 질문 도출 근거를 최소 50자이상 적어주세요';
				this.showAlert = true;
				return;
			}
			try {
				showLoading();
				const { data } = await axios({
					method: 'POST',
					url: '/api/answer/interviewFeedBack',
					data: {
						request_no: this.no,
						mentor_no: this.userNo,
						q1: this.question1,
						reason1: this.answer1,
						q2: this.question2,
						reason2: this.answer2,
					},
				});

				// 커뮤니티 차단
				if (data.code === 403) {
					Toast.show({
						message: data.msg,
						type: 'error',
						position: this.isMobile ? 'bottom' : 'top',
					});
					return;
				}
				const isPostSuccess = data.code === 200;
				if (isPostSuccess) {
					let cor = data.cor;
					const category = CASE_CATEGORY_GTM_MAP[this.detailList[0].mentoring_category_group_id];
					let info = {
						user_no: this.userNo,
						user_id: this.email,
						user_type: this.userType,
						user_level: this.userLevel,
						req_no: this.no,
						req_type: 'in',
						cor_com: cor.cor_com,
						cor_in: cor.cor_in,
						cor_job: cor.cor_job,
						cor_univ: cor.cor_univ,
						cor_interest: this.$_hasMatchingKeyword(this.detailList[0].job) ? 'Y' : 'N',
						where: '',
						category: this.$_getCaseGTMCategory(category),
					};
					// GTM - leaveAnswer
					leaveAnswer(info);
					if (
						typeof this.answerCorrespondence === 'string' &&
						this.answerCorrespondence.toLowerCase() === 'true'
					) {
						// GTM - leaveAnswerWithCorrespondence
						leaveAnswerWithCorrespondence();
					}
					this.answerShowThanksModal = true;
					this.$emit('submit-interview-feedback', this.goDetailUrl());
				} else {
					this.title = '';
					this.message = '에러입니다. 고객센터로 문의주세요.';
					this.showAlert = true;
				}
			} catch (error) {
				console.log(error);
				this.title = '';
				this.message = '에러입니다. 고객센터로 문의주세요.';
				this.showAlert = true;
			} finally {
				closeLoading();
			}
		},
		protect(e) {
			this.title = '';
			this.message = '복사/ 붙여넣기 방지를 위해 Ctrl Key 사용을 제한합니다';
			this.showAlert = true;
			e.preventDefault();
		},
		focusInterview() {
			this.$nextTick(() => {
				if (!this.isMobile) {
					this.questionColumn = 4;
					this.answerColumn = 8;
				}
			});
		},
		blurInterview() {
			this.$nextTick(() => {
				if (!this.isMobile) {
					this.questionColumn = 8;
					this.answerColumn = 4;
				}
			});
		},
		getFeedback() {
			axios({
				method: 'POST',
				url: '/api/answer/getFeedback',
				data: {
					request_no: this.no,
					request_type: 'in',
					display_type: 'feedback',
				},
			})
				.then(({ data }) => {
					//console.log(data);
					if (data.code === 200) {
						this.feedbackCount = data.result.length;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		getContents() {
			axios({
				//자소서 내용
				method: 'POST',
				url: '/api/answer/getContents',
				data: {
					request_no: this.no,
					rj_no: '', //직무소개
				},
			})
				.then(({ data }) => {
					//console.log(data);
					if (data.code === 200) {
						this.detailList.push({
							...data.result,
							mentoring_category_group_id: data.mentoring_category_group_id,
						});
						if (data.result.free_flag === 0) {
							//회사명 일치여부와 상관없이 무료 면접은 답변 1개 이상이면 답변 불가
							if (this.feedbackCount >= 1) this.finishFlag = true;
						} else if (data.result.free_flag === 1) {
							//회사명과 지원회사가 다르고 이미 답변이 5개 이상이면 못하게
							if (this.userBelong !== data.result.company) {
								if (this.feedbackCount >= 5) this.finishFlag = true;
							} else {
								if (this.feedbackCount >= 5) this.finishFlag = true;
							}
						}
						if (!this.finishFlag) {
							let cor = data.cor;
							let info = {
								user_no: this.userNo,
								user_type: this.userType,
								email: this.email,
								user_level: this.userLevel,
								req_no: this.no,
								req_type: this.type,
								cor_com: cor.cor_com,
								cor_in: cor.cor_in,
								cor_job: cor.cor_job,
								cor_univ: cor.cor_univ,
								where: '',
							};
							// GTM - viewRequest
							viewRequest(info);
							// GTM - GAVirtualPageview
							GAVirtualPageview(this.$route.path);
							this.getMatchingInfo();
						}
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		goDetailUrl() {
			let company, position, req_no, o;

			company = this.detailList[0].company;
			position = this.detailList[0].job;
			req_no = this.detailList[0].no;
			o = this.detailList[0].other_inf;

			this.makeUrl(company, position, req_no, o);

			return `https://comento.kr/mine/interview?request_no=${req_no}`;
		},
		makeUrl(company, position) {
			company = company.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}]|\s+/gi, '');
			position = position.replace(/[#&+\-%@=\/\\:;,.'"^`~_|!?*$<>()\[\]{}]|\s+/gi, '');

			company = company.replace('\n', '');
			company = company.replace('‘', '');
			company = company.replace('㈜', '');
			company = company.replace('’', '');

			position = position.replace('\n', '');
			position = position.replace('‘', '');
			position = position.replace('㈜', '');
			position = position.replace('’', '');

			if (company === '') company = '-';
			if (position === '') position = '-';
		},
		getMatchingInfo() {
			this.answerCorrespondence = this.$cookies.get('correspondence');
			if (typeof this.answerCorrespondence === 'string' && this.answerCorrespondence.toLowerCase() === 'true') {
				// GTM - viewRequestWithCorrespondence
				viewRequestWithCorrespondence();
			}
		},
	},
	components: {
		AnswerDetailThanksModal,
		GridLayout,
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../scss/base/main';

.body-wrapper {
	margin-bottom: 20px;
}
.answer {
	word-break: break-word;
}

.question-container {
	@include mobile {
		background: white;
		padding-top: 20px;
		width: 100vw !important;
		margin-left: -8px !important;
		margin-right: -8px !important;
		margin-bottom: 20px !important;
	}
}

.answer-interaction {
	@include transition(all 0.3s ease);
}

.width-30 {
	width: 33.33333%;
}
.bg-gray .text-primary {
	margin: auto;
}
.content {
	background: #fff;
}
.interview-question {
	min-height: 55px;
}
.default-box {
	@include border-radius(2px);
	border: 1px solid $gray250 !important;
}

@media (max-width: 480px) {
	.body-wrapper {
		margin-bottom: 10px;
	}
	.width-30 {
		width: 100%;
		//padding: 0 15px;
		float: none;
	}
	.col-8 {
		background: #fff;
		top: -15px;
		padding: 15px;
		margin-bottom: 20px;
	}
}
.pd-30 {
	padding: 30px;
	@media (max-width: 480px) {
		padding: 20px 0;
	}
}
</style>
