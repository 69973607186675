<template>
	<article class="list_component" :class="[computedHover]">
		<slot />
	</article>
</template>

<script>
export default {
	name: 'List',
	props: {
		hoverAction: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedHover() {
			if (this.hoverAction === true) {
				return `list_component_hoverAction`;
			}
		},
	},
};
</script>

<style scoped lang="less">
@import '../../../../../less/base/index';
.list_component {
	position: relative;
	display: block;
	padding: 16px;
	&_hoverAction {
		&:hover {
			background: @green000;
		}
	}
}
</style>
