import { getJwtTokenFromCookie } from '../../api/auth';

const authStore = {
	namespaced: true,
	state: {
		jwt: null,
	},
	mutations: {
		setJwt(state, payload) {
			state.jwt = payload;
			// setAxiosToken(payload);
		},
	},
	getters: {
		jwt: state => {
			return state.jwt;
		},
		hasJwt: state => {
			return state.jwt !== 'fail';
		},
	},
	actions: {
		getJwtToken({ commit }) {
			getJwtTokenFromCookie()
				.then(({ data }) => {
					commit('setJwt', data.token);
				})
				.catch(err => {
					console.log(err);
				});
		},
	},
};

export default authStore;
