<template>
	<div class="container mb-10vh">
		<div class="flex flex-row">
			<div class="admin-box mr-50" onclick="location.href='/yhf/admin/company'">
				<div class="admin-img-box mb-20"><img class="admin-img" :src="img[0]" alt="" /></div>
				<h5>참여기업 리스트</h5>
				<div>청년 열린채용에 신청한 참여기업과 주요 접속정보를 확인할 수 있습니다.</div>
			</div>
			<div class="admin-box mr-50" onclick="location.href='/yhf/admin/applicant'">
				<div class="admin-img-box mb-20"><img class="admin-img" :src="img[1]" alt="" /></div>
				<h5>지원자 성과</h5>
				<div>청년 열린채용에 지원한 지원자 성과를 확인할 수 있습니다.</div>
			</div>
			<div class="admin-box" onclick="location.href='/yhf/admin/account'">
				<div class="admin-img-box mb-20"><img class="admin-img" :src="img[2]" alt="" /></div>
				<h5>결산</h5>
				<div>청년 열린채용을 이용하는 월별 채용공고 현황과 비용을 확인할 수 있습니다.</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			img: [
				'http://comento.biz/assets/resource/images/disco_users.svg',
				'http://comento.biz/assets/resource/images/disco_list.svg',
				'http://comento.biz/assets/resource/images/disco_checked.svg',
			],
		};
	},
};
</script>

<style lang="less" scoped>
.mb-10vh {
	margin-bottom: 10vh;
}
.flex-row {
	@media (max-width: 480px) {
		flex-direction: column !important;
	}
}

.admin {
	&-box {
		width: 400px;
		height: auto;
		border: 1px solid #dee2e6;
		border-radius: 5px;
		padding: 20px;
		cursor: pointer;
		box-shadow: 5px 5px 12px #dee2e6;
		&:hover {
			box-shadow: 8px 8px 8px #dee2e6;
			@media (max-width: 480px) {
				box-shadow: none;
			}
		}
		@media (max-width: 480px) {
			width: 100%;
			box-shadow: none;
			margin-bottom: 30px;
		}
	}
	&-img-box {
		background-color: #f1f1f1;
		border-radius: 5px;
		padding: 20px;
	}
	&-img {
		width: 100%;
		height: 150px;
		max-height: 150px;
	}
}
</style>
