<template>
	<component :is="componentName" v-bind="$attrs" v-on="$listeners" @touchStart="touchStart" @touchEnd="touchEnd">
		<template v-for="(item, index) in Object.keys($slots).length" :slot="'item' + index">
			<slot :name="'item' + index" />
		</template>
	</component>
</template>

<script>
import CustomSwiper from './CustomSwiper.vue';
import appCommandMixin from '@/mixins/appCommandMixin';

export default {
	name: 'DynamicSwiper',
	mixins: [appCommandMixin],
	props: {
		isCustomizable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		componentName() {
			return this.isCustomizable ? CustomSwiper : 'CSwiper';
		},
	},
	methods: {
		touchStart() {
			this.$_app_setSwipe(false);
		},
		touchEnd() {
			this.$_app_setSwipe(true);
		},
	},
	components: {
		CustomSwiper,
	},
};
</script>
